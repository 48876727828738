import React from 'react';
import {Row, Col, Divider} from 'antd';
import Text from '../../../components/Text/Text';
import {backgroundStyle} from '../../../utils/staticStrings';
import useApi from '../../../../src/hooks/useApi';
import VideoThumbnail from '../../../common/components/VideoThumbnail';
import {
  AthleteCard,
  CheckoutCard,
  AthleteProfileImageContainer,
  PriceSummaryCard,
  MobileLayoutViewView,
  DeskTopLayoutView,
} from './style';

import CheckoutCardUI from '../components/payment/CheckoutCard';

const ConfirmandPay = () => {
  let ListingId = localStorage.getItem('listingId');
  const [listingData] = useApi(`/athlete/listing/${ListingId}`);
  let cardListProduct =
    listingData?.data?.type === 'Experiences'
      ? listingData?.data?.listing_experience
      : listingData?.data?.listing_product;

  let paymentPayload = JSON.parse(localStorage.getItem('paymentPayload'));

  const currencyFormat = (num) => {
    if (num !== undefined) {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  };

  return (
    <div className="container purchaser-margin">
      {/* <SemiCircleCheckout /> */}
      <Row width="100%" gutter="20">
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 17}}>
          <AthleteCard style={{border: 'none'}} className="px-3">
            <Text
              display="flex"
              weight="700"
              size="36"
              className="mb-4"
              lineHeight="55"
              textColor="#042237">
              Checkout
            </Text>
            <MobileLayoutViewView>
              <AthleteCard>
                <AthleteProfileImageContainer>
                  {listingData?.data?.attachments[0]?.type === 'video' ? (
                    <div className="video-background">
                      {/* <div className="video-foreground">
                        <iframe
                          className="sm"
                          src={`https://www.youtube.com/embed/${listingData?.data?.attachments[0]?.url}?controls=0`}
                          width="100%"
                          frameBorder="0"
                          allowFullScreen
                          title="Video"
                        />
                      </div> */}
                      <div className="video-thumbnail-md">
                        <VideoThumbnail
                          embedId={listingData?.data?.attachments[0]?.url}
                        />
                      </div>
                    </div>
                  ) : (
                    <img
                      src={listingData?.data?.attachments[0]?.url}
                      className="img-fluid object-fit-contain purchases-list-imag sm "
                      alt="Card"
                    />
                  )}

                  <div className="d-flex flex-column">
                    <Text
                      display="flex"
                      weight="700"
                      size="16"
                      mrLeft="10"
                      className="mb-2 line-ellipse-2 "
                      lineHeight="22"
                      textColor="#042237">
                      {cardListProduct?.title}
                    </Text>
                    <Text
                      display="flex"
                      weight="700"
                      size="14"
                      className="line-ellipse-1"
                      mrLeft="10"
                      lineHeight="20"
                      textColor="#435969">
                      {cardListProduct?.description}
                    </Text>
                  </div>
                </AthleteProfileImageContainer>
                <MobileLayoutViewView>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="d-flex align-items-center">
                      <Text
                        display="flex"
                        weight="500"
                        size="13"
                        className="d-flex "
                        lineHeight="17"
                        mrRight="4"
                        textColor="#435969">
                        Quantity
                      </Text>
                      <Text
                        display="flex"
                        weight="600"
                        size="16"
                        className="d-flex "
                        lineHeight="32"
                        textColor="#042237">
                        {paymentPayload?.quantity}
                      </Text>
                    </div>
                    <div className="d-flex align-items-center">
                      <Text
                        display="flex"
                        weight="500"
                        size="13"
                        mrRight="4"
                        className="d-flex "
                        lineHeight="17"
                        textColor="#435969">
                        Price
                      </Text>
                      <Text
                        display="flex"
                        weight="600"
                        size="16"
                        className="d-flex "
                        lineHeight="32"
                        textColor="#042237">
                        {currencyFormat(cardListProduct?.price)}
                      </Text>
                    </div>
                  </div>
                </MobileLayoutViewView>
              </AthleteCard>
            </MobileLayoutViewView>
            <DeskTopLayoutView>
              <table border="0" className="table checkout-table">
                <thead className="th" style={{height: '60px'}}>
                  <tr>
                    <th>Item</th>
                    <th className="text-center">Quantity</th>
                    <th className="text-center">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td width="60%">
                      <div className="d-flex cool">
                        {listingData?.data?.attachments[0]?.type === 'video' ? (
                          // <div className="video-background">
                          //   <div className="video-foreground">
                          //     <iframe
                          //       className="sm"
                          //       src={`https://www.youtube.com/embed/${listingData?.data?.attachments[0]?.url}?controls=0`}
                          //       width="100%"
                          //       frameBorder="0"
                          //       allowFullScreen
                          //       title="Video"
                          //     />
                          //   </div>
                          // </div>
                          <div className="video-thumbnail-sm">
                            <VideoThumbnail
                              embedId={listingData?.data?.attachments[0]?.url}
                            />
                          </div>
                        ) : (
                          <img
                            src={listingData?.data?.attachments[0]?.url}
                            className="img-fluid object-fit-contain purchases-list-imag sm"
                            alt="list"
                          />
                        )}

                        <div className="d-flex flex-column justify-content-center">
                          <Text
                            display="flex"
                            weight="700"
                            size="16"
                            mrLeft="20"
                            className="line-ellipse-1"
                            lineHeight="22"
                            textColor="#042237">
                            {cardListProduct?.title}
                          </Text>
                          <Text
                            display="flex"
                            weight="700"
                            size="14"
                            mrLeft="20"
                            className="line-ellipse-1"
                            lineHeight="20"
                            textColor="#435969">
                            {cardListProduct?.description}
                          </Text>
                        </div>
                      </div>
                    </td>
                    <td className="text-center title">
                      {paymentPayload?.quantity}
                    </td>
                    <td className="text-center title">
                      {currencyFormat(cardListProduct?.price)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </DeskTopLayoutView>
            {((paymentPayload?.shipping_address &&
              cardListProduct?.category_id === 4) ||
              paymentPayload?.inscription) && (
              <CheckoutCard>
                {paymentPayload?.inscription && (
                  <>
                    <div className="d-flex flex-column mb-0">
                      <Text
                        display="flex"
                        weight="700"
                        size="16"
                        lineHeight="26"
                        textColor="#042237">
                        {cardListProduct?.purchaser_requirements
                          ? cardListProduct?.purchaser_requirements
                          : ' Please provide custom text for inscription'}
                      </Text>
                      <Text
                        display="flex"
                        weight="400"
                        size="16"
                        lineHeight="26"
                        // className="text-capitalize"
                        textColor="#042237">
                        {paymentPayload?.inscription}
                      </Text>
                    </div>
                  </>
                )}
                {paymentPayload?.shipping_address &&
                  cardListProduct?.category_id === 4 && (
                    <>
                      <div className="d-flex flex-column mt-4">
                        <Text
                          display="flex"
                          weight="700"
                          size="16"
                          lineHeight="26"
                          textColor="#042237">
                          Shipping address
                        </Text>
                        <Text
                          display="flex"
                          weight="400"
                          size="16"
                          lineHeight="26"
                          textColor="#042237">
                          {paymentPayload?.shipping_address?.purchaser_name}{' '}
                          <br />
                          {paymentPayload?.shipping_address?.street1} &nbsp;
                          <br />
                          {paymentPayload?.shipping_address?.street2 && (
                            <>
                              {paymentPayload?.shipping_address?.street2} &nbsp;
                              <br />
                            </>
                          )}
                          {paymentPayload?.shipping_address?.city && (
                            <>
                              {paymentPayload?.shipping_address?.city}
                              ,&nbsp;
                            </>
                          )}
                          {paymentPayload?.shipping_address?.state}&nbsp;
                          {paymentPayload?.shipping_address?.zip}
                          <br />
                          {paymentPayload?.shipping_address?.country}&nbsp;
                        </Text>
                      </div>
                    </>
                  )}
              </CheckoutCard>
            )}
          </AthleteCard>
        </Col>
        <Col
          className="mb-5 px-4"
          xs={{span: 24}}
          sm={{span: 24}}
          md={{span: 24}}
          lg={{span: 7}}>
          <PriceSummaryCard>
            <Text
              display="flex"
              weight="700"
              size="18"
              className="mb-3"
              lineHeight="25"
              textColor="#042237">
              Price Summary
            </Text>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between mb-3">
                <Text
                  weight="500"
                  size="16"
                  lineHeight="22"
                  textColor="#042237">
                  Subtotal
                </Text>
                <Text
                  weight="500"
                  size="16"
                  lineHeight="22"
                  textColor="#042237">
                  {currencyFormat(paymentPayload?.shippingData?.subtotal)}
                </Text>
              </div>
              <>
                <div className="d-flex justify-content-between mb-3">
                  <Text
                    weight="500"
                    size="16"
                    lineHeight="22"
                    textColor="#042237">
                    Sales tax
                  </Text>
                  <Text
                    weight="500"
                    size="16"
                    lineHeight="22"
                    textColor="#042237">
                    {currencyFormat(paymentPayload?.shippingData?.sales_tax)}
                  </Text>
                </div>
                {cardListProduct?.category_id === 4 && (
                  <div className="d-flex justify-content-between mb-3">
                    <Text
                      weight="500"
                      size="16"
                      lineHeight="22"
                      textColor="#042237">
                      Shipping
                    </Text>
                    <Text
                      weight="500"
                      size="16"
                      lineHeight="22"
                      textColor="#042237">
                      ${paymentPayload?.shippingData?.shipping_price}
                    </Text>
                  </div>
                )}
              </>
            </div>
            <Divider />
            <div className="d-flex justify-content-end ">
              <Text weight="700" size="18" lineHeight="24" textColor="#042237">
                {currencyFormat(paymentPayload?.shippingData?.total_price)}
              </Text>
            </div>
            <Divider />
            <CheckoutCardUI paymentData={paymentPayload} />
            <div className="d-flex justify-content-center align-items-center mt-3">
              <img src={backgroundStyle?.privacyIcon} alt="Privacy" />
              <Text
                display="flex"
                weight="400"
                size="14"
                mrLeft="4"
                lineHeight="24"
                textColor="#435969">
                All payments are secured
              </Text>
            </div>
          </PriceSummaryCard>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmandPay;
