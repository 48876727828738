import React from 'react';
import CustomCarouselThumbnail from '../../../../../common/components/CustomCarouselThumbnail';
import {currencyFormat} from '../../../../../helpers/user';

const Review = (props) => {
  const {listingData, prev, createListAction, categoryName, subCategoryName} =
    props;

  return (
    <div className="listing-review-section">
      <h1 className="fw-bold text-center">Review</h1>
      <p className="mb-5 color-muted text-center title">
        Take a final review of the details before saving the listing to your
        shop.
      </p>
      <div className="mt-5 mb-3 review-holder mx-auto">
        <div className="">
          <div className="d-md-flex ">
            <div className="review-left-panel">
              <div className="assets-holder">
                <CustomCarouselThumbnail
                  slidesData={listingData.media}></CustomCarouselThumbnail>
              </div>
            </div>
            <div className="review-right-panel text-muted mt-5 mt-md-0">
              <div className="d-flex mb-4">
                <div className="badge-light me-2">{categoryName}</div>
                <div className="badge-light me-2">{subCategoryName}</div>
                {listingData?.is_coaching && (
                  <div className="badge-light me-2">Coaching/Lessons</div>
                )}
              </div>
              <h2 className="font-xl fw-bold mb-3 color-dark text-break">
                {listingData.title}
              </h2>
              <h5 className="fw-bold font-30 color-dark mb-4 mb-md-3">
                {currencyFormat(listingData.price)}
              </h5>
              {listingData.description && (
                <>
                  <div className="font-22 text-dark fw-bold mt-4">
                    About the listing
                  </div>
                  <p className="fw-500 font-m lh-lg">
                    {listingData.description}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="steps-action d-flex flex-md-column justify-content-center align-items-center pt-4">
        <div
          className="text-muted fw-bold align-items-center d-flex cursor-pointer mx-md-0 mx-3"
          onClick={() => {
            prev();
          }}>
          BACK
        </div>
        <button
          className="btn btn-primary btn-lg my-5 "
          onClick={createListAction}>
          save listing to shop
        </button>
      </div>
    </div>
  );
};

export default Review;
