import React, {useState, useEffect} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {toastMessage} from '../../../../helpers/toastMessage';
import http from '../../../../helpers/http';
import {Button} from 'antd';

const UpdatePayment = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setCardName] = useState('');
  const [errorMessage, setErrorNameMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const {closeEditPM} = props;

  const number = elements.getElement(CardNumberElement) || null;
  const expiryElement = elements.getElement(CardExpiryElement) || null;
  const cvc = elements.getElement(CardCvcElement) || null;

  if (number !== null) {
    number.on('change', function (event) {
      var displayError = document.getElementById('card-errors-number');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  if (expiryElement !== null) {
    expiryElement.on('change', function (event) {
      var displayError = document.getElementById('card-errors-expiry');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  if (cvc !== null) {
    cvc.on('change', function (event) {
      var displayError = document.getElementById('card-errors-cvc');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  const pay = async (event) => {
    event.preventDefault();
    setErrorNameMessage(true);
    const {paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: number,
    });

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    if (paymentMethod?.id) {
      setLoading(true);
      try {
        let response = await http.put(
          `/stripe/payment-method/${paymentMethod?.id}/attach`,
        );
        const createPMInfo = response.data;
        if (createPMInfo?.success) {
          toastMessage('Card details saved successfully');
          setLoading(false);
          closeEditPM();
        }
      } catch (error) {
        console.log(error?.response?.data?.message?.raw?.message);
        setLoading(false);
        toastMessage(error?.response?.data?.message?.raw?.message);
      }
    }
  };

  return (
    <div className="checkout-wrapper p-3 p-md-4">
      {/* {!cardLoading && (
        <>
          <div className="fw-700 title mb-2 mt-4 color-dark">
            Payment Method
          </div>
          <div className="card-options radio-card-payment">
            <Radio.Group defaultValue="useCard" onChange={onCardChange}>
              <Radio value="useCard" className="mb-3">
                {defPM?.card?.brand &&
                  `${toTitleCase(defPM?.card?.brand)} ending in ${
                    defPM?.card?.last4
                  } 
            expiring ${defPM?.card?.exp_month}/${defPM?.card?.exp_year}`}
              </Radio>
              <Radio value="new">Add new card</Radio>
            </Radio.Group>
          </div>
        </>
      )} */}
      <div className="brand-form mb-3 ">
        <span className="form-label d-block mb-1">Name on card</span>
        <input
          className="form-control checkout-payment-input-stripe"
          value={name}
          type="text"
          name="name"
          onChange={(e) => setCardName(e.target.value)}
          autoComplete="false"
        />
        {name.length === 0 && errorMessage && (
          <div className="text-danger fw-bold">Card name required</div>
        )}
      </div>
      <div className="checkout-payment-form-item-cardelement mb-3">
        <span className="form-label d-block mb-1">Card number</span>
        <CardNumberElement
          options={{
            classes: {
              base: 'checkout-payment-input-stripe',
            },
          }}
        />
        <div id="card-errors-number" className="text-danger fw-bold"></div>
      </div>
      <div className="row">
        <div className="col mb-4">
          <span className="form-label d-block mb-1">Expiration</span>
          <CardExpiryElement
            options={{
              classes: {
                base: 'checkout-payment-input-stripe',
              },
            }}
          />
          <div id="card-errors-expiry" className="text-danger fw-bold"></div>
        </div>
        <div className="col mb-4">
          <span className="form-label d-block mb-1">CVC</span>
          <CardCvcElement
            options={{
              classes: {
                base: 'checkout-payment-input-stripe',
              },
            }}
          />
          <div id="card-errors-cvc" className="text-danger fw-bold"></div>
        </div>
      </div>

      <div className="checkout-payment-form-button-container">
        <Button
          type="primary"
          className="btn btn-save-var2 btn-lg"
          onClick={pay}
          loading={loading}>
          SAVE
        </Button>
      </div>
    </div>
  );
};

const UpdatePaymentWrapper = (props) => {
  // const {defPM, cardLoading} = props;
  const [stripe, setStripe] = useState(null);
  const [stripeKEy, setStripeKey] = useState(null);

  useEffect(() => {
    let isComponentMounted = true;
    const loadStripeHandler = async () => {
      try {
        if (stripeKEy && isComponentMounted) {
          const stripePromise = await loadStripe(stripeKEy);
          setStripe(stripePromise);
        }
      } catch (err) {
        console.log(err);
      }
    };
    loadStripeHandler();
    return () => {
      isComponentMounted = false;
    };
  }, [stripeKEy]);

  useEffect(() => {
    let getKey = true;
    const getData = async () => {
      const data = await http.get('/stripe/client_secret');
      if (getKey) {
        setStripeKey(data.data.data.key);
      }
    };
    getData();

    return () => {
      getKey = false;
    };
  }, []);

  // If no Stripe object, do not render the Stripe Element.
  if (!stripe) {
    return <p>Loading...</p>;
  }

  return (
    <Elements stripe={stripe}>
      <UpdatePayment {...props} />
    </Elements>
  );
};
export default UpdatePaymentWrapper;
