import React from 'react';
import '../features.scss';

export default function index(props) {
  return (
    <div className="section-howit-works" id="how-it-works">
      <div className="row center">
        <div className="col heading">How it works</div>
      </div>

      <div className="row center mw-1167 mx-auto">
        <div className="col-12  col-md-6  col-xl-3 col-xxl-3 mb-5">
          <div className="imgRow">
            <img src={props.data.img1} width="60" height="50" alt="athlete1" />
          </div>
          <div className="center title title-text">{props.data.title1}</div>
          <div className="center texts">{props.data.text1}</div>
        </div>
        <div className="col-12  col-md-6  col-xl-3 col-xxl-3 mb-5">
          <div className="imgRow">
            <img src={props.data.img2} width="60" height="50" alt="athlete2" />
          </div>
          <div className="center title title-text">{props.data.title2}</div>
          <div className="center texts">{props.data.text2}</div>
        </div>
        <div className="col-12  col-md-6  col-xl-3 col-xxl-3 mb-5">
          <div className="imgRow">
            <img src={props.data.img3} width="60" height="50" alt="athlete3" />
          </div>
          <div className="center title title-text">{props.data.title3}</div>
          <div className="center texts">{props.data.text3}</div>
        </div>
        <div className="col-12  col-md-6  col-xl-3 col-xxl-3 mb-5">
          <div className="imgRow">
            <img src={props.data.img4} width="60" height="50" alt="athlete4" />
          </div>
          <div className="center title title-text">{props.data.title4}</div>
          <div className="center texts">{props.data.text4}</div>
        </div>
      </div>
    </div>
  );
}
