import React, {useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setHeader} from '../../../../../store/athleteflow/action';
import AppLayout from '../../../../../common/AppLayout';
import Orders from './Orders';
import StatsData from '../../../../../common/components/Stats';
import {Tabs} from 'antd';
import ProfileSettings from '../../../../../common/components/ProfileSettings';
import {
  fetchPurchaserData,
  handleSidebarTab,
} from '../../../../../store/users/actions';
import {fetchOrders} from '../../../../../store/brandflow/action';
import {CustomTabs, LayoutDesktopView, LayoutMobileView} from '../../style';
import Text from '../../../../../components/Text/Text';
import useApi from '../../../../../hooks/useApi';
import {useSelector} from 'react-redux';
import OrderTable from '../../../../../common/components/OrderTable';
import useDeviceSize from '../../../../../hooks/useDeviceSize';
import {useInterval} from '../../../../../hooks/intervalHook';
import useTabActive from '../../../../../hooks/useTabActive';

const {TabPane} = Tabs;

const Dashboard = () => {
  const size = useDeviceSize();
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabactive] = useTabActive();
  const {orderData, orderCount, isLoading} = useSelector(
    (state) => state.BrandModuleReducer,
  );

  const {purchaserDetails, brandProfileCreation} = useSelector(
    (state) => state.UsersReducer,
  );
  const [orderPage, setPagevalue] = useState({
    pageSize: 12,
    page: 1,
    status: '',
    user: 'purchaser',
  });
  const [loadPath, setLoad] = useState(false);
  const [statsData] = useApi('/purchaser/order/stats');
  let id = localStorage.getItem('id');
  // console.log(profileData);
  const callbackOrder = useCallback(async () => {
    try {
      dispatch(fetchOrders(orderPage));
      if (history.location.pathname === '/purchaser/dashboard') {
        setLoad(true);
      } else {
        setLoad(false);
      }
    } catch (err) {
      // dispatch(hideModal());
      console.log(err, 'Fetch api error');
    }
  }, [orderPage, dispatch, history.location.pathname]);

  useInterval(callbackOrder, loadPath === true && tabactive ? 40000 : null);

  useEffect(() => {
    callbackOrder();
  }, [callbackOrder]);

  useEffect(() => {
    const purchaserLoc = localStorage.getItem('prev');
    if (purchaserLoc && purchaserLoc !== null && purchaserLoc !== undefined) {
      localStorage.removeItem('prev');
      history.push(purchaserLoc);
    }
  }, [history]);

  useEffect(() => {
    if (brandProfileCreation) {
      dispatch(fetchPurchaserData(id));
    }
  }, [brandProfileCreation, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id !== null && Object.keys(purchaserDetails).length === 0) {
      dispatch(fetchPurchaserData(id));
    }
  }, [dispatch, purchaserDetails, id]);

  useEffect(() => {
    if (history.location.pathname === '/purchaser/dashboard') {
      dispatch(setHeader(false));
      dispatch(handleSidebarTab('dashboard'));
    }
  }, [history.location.pathname, dispatch]);

  const callback = (key) => {
    // console.log(key);
    if (key !== 'all') {
      setPagevalue({...orderPage, page: 1, status: key, pageSize: 12});
    } else {
      setPagevalue({page: 1, pageSize: 12, user: 'purchaser'});
    }
  };

  const pageHandler = (page) => {
    // console.log(page);
    if (page.current) {
      setPagevalue({...orderPage, page: page.current});
    }
  };

  const loadMore = () => {
    if (orderCount > orderPage.pageSize) {
      setPagevalue((prev) => {
        return {
          ...orderPage,
          pageSize: prev.pageSize + 12,
        };
      });
    }
  };

  return (
    <AppLayout>
      <div className="bubble-bg"></div>
      <div className="content-wrapper">
        <div className="d-flex align-items-center justify-content-between">
          <div className="header-section">
            <Text
              display="flex"
              weight="700"
              size="55"
              lineHeight="64"
              smSize="36"
              mrBottom="5"
              smLineHeight="46"
              textColor="#042237">
              My purchases
            </Text>
            <Text
              display="flex"
              weight="600"
              size="20"
              lineHeight="28"
              smSize="16"
              smLineHeight="26"
              textColor="#435969">
              View and track every order from purchase to fulfillment.
            </Text>
          </div>
          <div className="d-none d-md-block">
            <ProfileSettings myPurchase={true} />
          </div>
        </div>
        {statsData?.data && (
          <StatsData
            title="Your purchases"
            cardLabel1="In progress"
            cardLabel2="Total completed"
            cardLabel3="Spent on purchases this month"
            cardLabel4="Total spent on purchases"
            label1Data={statsData?.data?.orders?.active}
            label2Data={statsData?.data?.orders?.completed}
            label3Data={statsData?.data?.spent_this_month}
            label4Data={statsData?.data?.spent_total}
            labelNewCount1={statsData?.data?.orders_new?.active}
            labelNewCount2={statsData?.data?.orders_new?.completed}
          />
        )}
        {size?.width > 767 ? (
          <LayoutDesktopView>
            <CustomTabs defaultActiveKey="all" size="large" onChange={callback}>
              <TabPane tab="ALL ORDERS" key="all">
                <OrderTable
                  item="purchaser"
                  loading={isLoading}
                  orderData={orderData}
                  onChange={pageHandler}
                  current={orderPage.page}
                  count={orderCount}
                />
              </TabPane>
              <TabPane tab="IN PROGRESS" key="active">
                <OrderTable
                  item="purchaser"
                  loading={isLoading}
                  orderData={orderData}
                  onChange={pageHandler}
                  current={orderPage.page}
                  count={orderCount}
                />
              </TabPane>
              <TabPane tab="COMPLETED" key="completed">
                <OrderTable
                  item="purchaser"
                  loading={isLoading}
                  orderData={orderData}
                  onChange={pageHandler}
                  current={orderPage.page}
                  count={orderCount}
                />
              </TabPane>
              <TabPane tab="DISPUTED" key="disputed">
                <OrderTable
                  item="purchaser"
                  loading={isLoading}
                  orderData={orderData}
                  onChange={pageHandler}
                  current={orderPage.page}
                  count={orderCount}
                />
              </TabPane>
              <TabPane tab="CANCELLED" key="declined">
                <OrderTable
                  item="purchaser"
                  loading={isLoading}
                  orderData={orderData}
                  onChange={pageHandler}
                  current={orderPage.page}
                  count={orderCount}
                />
              </TabPane>
            </CustomTabs>
          </LayoutDesktopView>
        ) : (
          <LayoutMobileView>
            <CustomTabs defaultActiveKey="all" size="large" onChange={callback}>
              <TabPane tab="ALL ORDERS" key="all">
                <>
                  {orderCount !== 0 ? (
                    <Orders
                      item="myPurchase"
                      orderData={orderData}
                      orderCount={orderCount}
                    />
                  ) : (
                    <h5 className="text-muted text-center py-5">
                      No orders to display
                    </h5>
                  )}
                  {orderCount > orderPage.pageSize && (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary  view-more"
                      onClick={loadMore}>
                      view more
                    </button>
                  )}
                </>
              </TabPane>
              <TabPane tab="IN PROGRESS" key="active">
                <>
                  {orderCount !== 0 ? (
                    <Orders
                      item="myPurchase"
                      orderData={orderData}
                      orderCount={orderCount}
                    />
                  ) : (
                    <h5 className="text-muted text-center py-5">
                      No orders to display
                    </h5>
                  )}
                  {orderCount > orderPage.pageSize && (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary  view-more"
                      onClick={loadMore}>
                      view more
                    </button>
                  )}
                </>
              </TabPane>
              <TabPane tab="COMPLETED" key="completed">
                <>
                  {orderCount !== 0 ? (
                    <Orders
                      item="myPurchase"
                      orderData={orderData}
                      orderCount={orderCount}
                    />
                  ) : (
                    <h5 className="text-muted text-center py-5">
                      No orders to display
                    </h5>
                  )}
                  {orderCount > orderPage.pageSize && (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary  view-more"
                      onClick={loadMore}>
                      view more
                    </button>
                  )}
                </>
              </TabPane>
              <TabPane tab="DISPUTED" key="disputed">
                <>
                  {orderCount !== 0 ? (
                    <Orders
                      item="myPurchase"
                      orderData={orderData}
                      orderCount={orderCount}
                    />
                  ) : (
                    <h5 className="text-muted text-center py-5">
                      No orders to display
                    </h5>
                  )}
                  {orderCount > orderPage.pageSize && (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary  view-more"
                      onClick={loadMore}>
                      view more
                    </button>
                  )}
                </>
              </TabPane>
              <TabPane tab="CANCELLED" key="declined">
                <>
                  {orderCount !== 0 ? (
                    <Orders
                      item="myPurchase"
                      orderData={orderData}
                      orderCount={orderCount}
                    />
                  ) : (
                    <h5 className="text-muted text-center py-5">
                      No orders to display
                    </h5>
                  )}
                  {orderCount > orderPage.pageSize && (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary  view-more"
                      onClick={loadMore}>
                      view more
                    </button>
                  )}
                </>
              </TabPane>
            </CustomTabs>
          </LayoutMobileView>
        )}
      </div>
    </AppLayout>
  );
};

export default Dashboard;
