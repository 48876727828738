import React, {useState, useEffect} from 'react';
import {List} from 'antd';
import {backgroundStyle} from '../../utils/staticStrings';
import moment from 'moment/moment';

const ConversationsList = (props) => {
  const {
    channelList,
    userId,
    channelId,
    selectedConversationSid,
    setSelectedCoversationSid,
    selectedChannelDetails,
    width,
    client,
    type,
    channelPrivate,
    messageRead,
  } = props;
  const [messageCount, setMessageCount] = useState(0);
  const [unconsumedCount, setUnconsumedCount] = useState(0);
  const [listSelectChannel, setListChannel] = useState({});
  const [messageUpdated, setMessageUpdate] = useState(true);
  // const [messageUpdatedId, setMessageUpdatedId] = useState();

  let messageType = channelList?.order ? 'order' : 'deal';
  let orderId;
  let messageDetais;
  let profileDetails;
  if (channelList?.order) {
    orderId = channelList?.order?.id;
    messageDetais = channelList?.order;
    profileDetails = channelList?.order?.user;
  } else {
    orderId = channelList?.deal?.id;
    messageDetais = channelList?.deal;
    profileDetails = channelList?.deal?.purchaser;
  }
  let profileUser = channelList.users.find((item) => item.id !== userId);

  let profileImage;

  if (profileUser?.purchaser?.logo) {
    profileImage = profileUser?.purchaser?.logo;
  } else if (profileUser?.image_url !== null) {
    profileImage = profileUser?.image_url;
  } else {
    profileImage = backgroundStyle.defaultProfilePic;
  }
  useEffect(() => {
    if (
      channelPrivate !== null &&
      (messageRead === channelId || selectedConversationSid === channelId)
    ) {
      channelPrivate.getUnconsumedMessagesCount().then((res) => {
        setUnconsumedCount(0);
      });
    }
  }, [
    channelPrivate,
    messageRead,
    channelId,
    selectedConversationSid,
  ]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    const messageReadCalculation = () => {
      client.getChannelBySid(channelId).then((listChannel) => {
        setListChannel(listChannel);
        listChannel.getMessagesCount().then((index) => {
          setMessageCount(index);
        });

        listChannel.getUnconsumedMessagesCount().then((res) => {
          setUnconsumedCount(res);
        });
      });
      setMessageUpdate(false);
    };
    messageReadCalculation();
  }, [
    channelId,
    messageUpdated,
    client,
  ]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    let initateUnCosumed = true;
    if (initateUnCosumed) {
      if (Object.keys(listSelectChannel).length > 0) {
        listSelectChannel.on('messageAdded', function (updatedMessageChannel) {
          if (updatedMessageChannel.channel.sid === channelId) {
            setMessageUpdate(true);
          }
        });
      }
    }
    return () => {
      initateUnCosumed = false;
    };
  }, [listSelectChannel, channelId]);

  return (
    <List
      onClick={() => {
        setSelectedCoversationSid(channelId);
        selectedChannelDetails(messageDetais, profileDetails, messageType);
        setMessageUpdate(true);
      }}
      className={`${selectedConversationSid === channelId ? 'active' : ''}
      ${
        messageCount > unconsumedCount && unconsumedCount !== 0
          ? 'unread'
          : 'read'
      }`}>
      <div className="d-flex py-3 ps-2 pe-0 pe-md-2 cp">
        <img
          width="50px"
          src={profileImage}
          className="proile-message radius-m me-3"
          alt="starIcon"
        />
        <div className="w-100">
          <div className="d-flex justify-content-between">
            <h5 className="font-m fw-700 mb-2 text-truncate">
              {profileDetails?.purchaser?.company !== null &&
              type === 'athlete' ? (
                <>
                  <span className="text-capitalize">
                    {profileDetails?.purchaser?.company}
                  </span>
                  {profileDetails?.purchaser?.company === '' ? (
                    <>{profileDetails?.name}</>
                  ) : (
                    <>({profileDetails?.name})</>
                  )}
                </>
              ) : (
                <> {profileUser.name}</>
              )}
            </h5>
            {width > '991' ? (
              <div className="fw-500 font-xs text-muted">
                {moment(channelList?.last_sent_message[0]?.dateUpdated).format(
                  'HH:mm',
                )}
              </div>
            ) : (
              <div className="fw-500 font-xxs text-muted">
                {moment(channelList?.last_sent_message[0]?.dateUpdated).format(
                  'h:mm a',
                )}
              </div>
            )}
          </div>
          <div className="text-capitalize fw-600 text-sm">
            {messageType}# {orderId}
          </div>
        </div>
      </div>
    </List>
  );
};

export default ConversationsList;
