import React, {useState} from 'react';

const ListingType = (props) => {
  const {selectedCard, setCardType, setListingData, next} = props;
  const [showMore, setShowMore] = useState(false);
  const [showMoreProducts, setShowMoreProducts] = useState(false);
  const selectType = (value) => {
    if (value === 'experience') {
      setListingData({type: 'Experiences'});
    } else {
      setListingData({type: 'Physical or digital products'});
    }
    setCardType(value);
  };

  return (
    <div className="text-center">
      <h1 className="fw-bold">Listing type</h1>
      <p className="mb-5 color-muted text-center title">
        Choose whether you would like to create a listing to sell an Experience
        or Product.
      </p>
      <div className="mt-5 mb-3">
        <div
          className={`list-card mb-3 ${
            selectedCard === 'experience' ? 'active' : ''
          }`}
          onClick={() => selectType('experience')}>
          <div className="bg-white">
            <div className="card-bg d-flex">
              <img
                src="/images/VirtualMeetSmall.png"
                alt="Experience logo"
                className="card-img-m d-block d-md-none"
              />
              <img
                src="/images/experience.png"
                alt="Experience logo"
                className="card-img-m d-none d-md-block"
              />
              <div className="ms-3">
                <h3 className="font-22 fw-bold">Experiences</h3>
                <p className="color-muted fw-500 d-none d-md-block">
                  Video shoutouts • Live video calls • Meet & greets • Lessons,
                  coaching, or mentorships • Play a video game with a fan •
                  Social media interactions • +more
                </p>
                <div className="color-muted fw-500 d-block d-md-none list-set">
                  Video shoutouts • Live video calls • Meet & greets • Lessons,
                  coaching, or mentorships
                  {showMore && (
                    <>
                      • Play a video game with a fan • Social media interactions
                    </>
                  )}
                  {!showMore ? (
                    <div
                      className="text-purple"
                      onClick={() => setShowMore(true)}>
                      +more
                    </div>
                  ) : (
                    <div
                      className="text-purple"
                      onClick={() => setShowMore(false)}>
                      -less
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`list-card mb-3 ${
            selectedCard === 'product' ? 'active' : ''
          }`}
          onClick={() => selectType('product')}>
          <div className="bg-white">
            <div className="card-bg d-flex">
              <img
                src="/images/physicalData.png"
                alt="physicalData"
                className="card-img-l"
              />
              <div className="ms-3">
                <h3 className="font-22 fw-bold">Physical & Digital Products</h3>
                <p className="color-muted fw-500 d-none d-md-block">
                  Merch • Autographed memorabilia • Set up a drop of your
                  authentic team-issued gear • Downloadable content (e.g.,
                  how-to guides, workout & nutritional plans, etc.) such as
                  PDFs, videos, or audio files • +more
                </p>
                <div className="color-muted fw-500 d-block d-md-none list-set">
                  Merch • Autographed memorabilia • Set up a drop of your
                  authentic team-issued gear
                  {showMoreProducts && (
                    <>
                      • Downloadable content (e.g., how-to guides, workout &
                      nutritional plans, etc.) such as PDFs, videos, or audio
                      files
                    </>
                  )}
                  {!showMoreProducts ? (
                    <div
                      className="text-purple"
                      onClick={() => setShowMoreProducts(true)}>
                      +more
                    </div>
                  ) : (
                    <div
                      className="text-purple"
                      onClick={() => setShowMoreProducts(false)}>
                      -less
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom mt-5 mw-570 mx-auto d-none d-md-block"></div>
        <div className="steps-action d-flex flex-row">
          <button
            className="btn btn-primary listing-btn mt-4 ms-auto"
            onClick={next}>
            Next
            <i className="icon icon-right ms-3"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListingType;
