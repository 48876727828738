import { useEffect, useState } from 'react';
import https from "../helpers/http"

export default function useFetch(url) {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchPost = async (url) => {
    try {
      const res = await https.get(url);
      setResponse(res.data)
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchPost(url);
  }, [url]);

  return [response, loading, error];
}
