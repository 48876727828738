import React from 'react';
import {ImageContainer} from './style';
import {backgroundStyle} from '../../../utils/staticStrings';

const ProfileImage = ({
  width,
  maxWidth,
  height,
  src,
  className,
  onClick,
  desktopImgWidth,
  mobileImgWidth,
  imgContainerClassName,
  children,
  zIndex,
  smWidth,
  smHeight,
  smMaxHeight,
  borderRadius,
}) => {
  let bg = src ? src : backgroundStyle.defaultProfilePic;
  let widthLg = desktopImgWidth ? desktopImgWidth : '32px';
  let widthsm = mobileImgWidth ? mobileImgWidth : '32px';
  let borderR = borderRadius ? borderRadius : '7px';
  return (
    <ImageContainer
      className={imgContainerClassName}
      width={width}
      maxWidth={maxWidth}
      smWidth={smWidth}
      smMaxHeight={smMaxHeight}
      smHeight={smHeight}
      height={height}>
      <div className="d-none d-lg-block">
        <div
          className={className}
          onClick={onClick}
          style={{
            backgroundImage: `url(${bg})`,
            width: widthLg,
            height: widthLg,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: `${zIndex}`,
            borderRadius: `${borderR}`,
          }}></div>
      </div>
      <div className="d-block d-lg-none" style={{zIndex: `${zIndex}`}}>
        <div
          className={className}
          onClick={onClick}
          style={{
            backgroundImage: `url(${bg})`,
            width: widthsm,
            height: widthsm,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: `${zIndex}`,
            borderRadius: `${borderR}`,
          }}></div>
      </div>
      {children}
    </ImageContainer>
  );
};

export default ProfileImage;
