import React, {useRef} from 'react';
import {Formik} from 'formik';
import {Input, Radio} from 'antd';
import EditGallery from './EditGallery';
import ShippingBox from './ShippingBox';
import DigitalProductUi from './DigitalProductUi';
import * as yup from 'yup';

const {TextArea} = Input;
const EditListing = (props) => {
  const {
    editList,
    deleteListing,
    mediaList,
    setMediaList,
    embedCode,
    editListAction,
    uspsBoxType,
    deleteListMedia,
    setEmbedCode,
    cardListProduct,
    setUrlValid,
    countries,
    stateFromPostalCode,
    getStateList,
    stateList,
  } = props;
  // const [media, setMedia] = useState(mediaList);

  let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

  let schema = yup.object().shape({
    price: yup
      .number()
      .test(
        'is-decimal',
        'The amount should be a decimal with maximum two digits after comma',
        (val) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        },
      )
      .min(0.5)
      .max(999999999)
      // .positive('Must be more than 0')
      .required('Price is required'),
  });
  const inputRef = useRef(null);
  const plainOptions = [
    {label: 'Unlimited quantity ', value: false},
    {label: 'Limited quantity available', value: true},
  ];

  const initialValues = {
    title: cardListProduct?.title,
    price: cardListProduct?.price,
    description: cardListProduct?.description,
    purchaser_requirements: cardListProduct?.purchaser_requirements,
    category_id: cardListProduct?.category_id,
    subcategory_id: cardListProduct?.subcategory_id,

    type: cardListProduct?.type,
    is_limited: cardListProduct?.is_limited,
    ...(cardListProduct?.is_limited && {
      limited_quantity: cardListProduct?.limited_quantity,
    }),

    ...(((cardListProduct.subcategory_id === 1 &&
      cardListProduct.category_id === 1) ||
      (cardListProduct.subcategory_id === 2 &&
        cardListProduct.category_id === 1)) && {
      is_coaching: cardListProduct?.is_coaching,
    }),

    ...(cardListProduct?.category_id === 4 && {
      package_type: cardListProduct?.shipping_detail?.package_type,
      weight: cardListProduct?.shipping_detail?.weight,
      weight_units: cardListProduct?.shipping_detail?.weight_units,
      is_shipaddress_needed: cardListProduct?.is_shipaddress_needed,
      from_address: {
        zip: editList?.from_address.zip,
        city: editList?.from_address.city,
        street1: editList?.from_address.street1,
        street2: editList?.from_address.street2,
        state: editList?.from_address.state,
        country: editList?.from_address.country,
      },

      dimensions: {
        length: cardListProduct?.shipping_detail?.dimensions?.length
          ? cardListProduct?.shipping_detail?.dimensions?.length
          : '',
        width: cardListProduct?.shipping_detail?.dimensions?.width
          ? cardListProduct?.shipping_detail?.dimensions?.width
          : '',
        height: cardListProduct?.shipping_detail?.dimensions?.height
          ? cardListProduct?.shipping_detail?.dimensions?.height
          : '',
      },

      usps_box_type: cardListProduct?.shipping_detail?.usps_box_type
        ? cardListProduct?.shipping_detail?.usps_box_type
        : '',
      // ...(cardListProduct?.shipping_detail?.package_type ===
      //   'USPS Flat Rate Packaging' && {
      //   usps_box_type: cardListProduct?.shipping_detail?.usps_box_type,
      // }),
      // ...(cardListProduct?.shipping_detail?.package_type !==
      //   'USPS Flat Rate Packaging' && {
      //   dimensions: cardListProduct?.shipping_detail?.dimensions,
      // }),
    }),
    ...(cardListProduct?.category_id === 5 && {
      message: cardListProduct?.message,
      digital_product_urls: cardListProduct?.digital_product_urls,
    }),
  };

  return (
    <div className="container-fluid">
      <Formik
        enableReinitialize
        initialValues={{...initialValues}}
        validationSchema={schema}
        onSubmit={(values, {setSubmitting}) => {
          const {purchaser_requirements, ...withoutPR} = values;
          let editListPayload;
          if (purchaser_requirements === '') {
            editListPayload = {...withoutPR, purchaser_requirements: null};
          } else {
            editListPayload = values;
          }
          editListAction({...editListPayload, media: mediaList});
        }}>
        {({
          values,
          onChange,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit} className="p-0 px-md-4">
              <h2 className="font-l fw-800 mb-20">Overview</h2>
              <div className="form-group mb-4">
                <span className="form-label d-block">
                  Title of the Experience
                </span>
                <input
                  type="text"
                  name="title"
                  className="form-control input-lg"
                  value={values.title}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-4">
                <span className="form-label d-block">Category</span>
                <input
                  type="text"
                  className="form-control input-lg"
                  value={cardListProduct?.category?.name}
                  disabled
                />
              </div>
              <div className="form-group mb-5">
                <span className="form-label d-block">Subcategory</span>
                <input
                  type="text"
                  className="form-control input-lg"
                  value={cardListProduct?.subcategory?.name}
                  disabled
                />
              </div>

              {((cardListProduct.subcategory_id === 1 &&
                cardListProduct.category_id === 1) ||
                (cardListProduct.subcategory_id === 2 &&
                  cardListProduct.category_id === 1)) && (
                <>
                  <h6 className="title">
                    Tag this Live video call as related to coaching/lessons ?
                  </h6>
                  <div className="mb-4 overview-card d-inline-flex align-items-start">
                    <div
                      name="category_id"
                      onClick={() => {
                        setFieldValue('is_coaching', true);
                      }}
                      className={`list-card custom-list me-2 ${
                        values.is_coaching === true ? 'active' : ''
                      }`}
                      style={{
                        width: '120px',
                      }}>
                      <div className="bg-white ">
                        <div className="card-bg color-muted opaque-7 d-flex justify-content-center">
                          Yes
                        </div>
                      </div>
                    </div>
                    <div
                      name="category_id"
                      onClick={() => {
                        setFieldValue('is_coaching', false);
                      }}
                      className={`list-card custom-list ${
                        values.is_coaching === false ? 'active' : ''
                      }`}
                      style={{
                        width: '120px',
                      }}>
                      <div className="bg-white">
                        <div className="card-bg color-muted opaque-7 d-flex justify-content-center">
                          No
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <h2 className="font-l fw-bold mt-5 mb-20 fw-800">Price</h2>

              <div className="form-group mb-5 input-custom-var2 text-left">
                <span className="form-label d-block">Price</span>
                <Input
                  addonBefore="$"
                  type="number"
                  name="price"
                  onChange={handleChange}
                  className="input-lg text-left"
                  value={values.price}
                />

                <div className="text-danger text-sm mt-3 fw-bold">
                  {errors.price && touched.price && errors.price}
                </div>

                {values?.category_id === 4 && (
                  <ShippingBox
                    setFieldValue={setFieldValue}
                    shipping_detail={cardListProduct?.shipping_detail}
                    from_address={editList?.from_address}
                    uspsBoxType={uspsBoxType}
                    onChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    countries={countries}
                    stateFromPostalCode={stateFromPostalCode}
                    getStateList={getStateList}
                    stateList={stateList}
                  />
                )}
              </div>
              <h2 className="font-l fw-bold mb-20 fw-800">Description</h2>
              <div className="form-group mb-3">
                <span className="form-label d-block">Description</span>
                <TextArea
                  rows="3"
                  name="description"
                  className="form-control input-lg"
                  value={values.description}
                  onChange={handleChange}
                />

                <div className="d-lg-flex antd-radio-inline align-items-center custom-radio-inline mt-3">
                  <Radio.Group
                    options={plainOptions}
                    value={values.is_limited}
                    defaultValue="limit"
                    name="is_limited"
                    onChange={(e) => {
                      setFieldValue('is_limited', e.target.value);
                    }}
                  />
                  {values.is_limited && (
                    <>
                      <Input
                        addonBefore="Qty"
                        placeholder="0"
                        type="number"
                        ref={inputRef}
                        onKeyDown={(evt) =>
                          evt.key === 'e' && evt.preventDefault()
                        }
                        onWheel={(e) => {
                          if (
                            inputRef &&
                            inputRef.current &&
                            inputRef.current.blur
                          ) {
                            inputRef.current.blur();
                          }
                          // e.preventDefault();
                        }}
                        min={0}
                        value={values.limited_quantity}
                        name="limited_quantity"
                        onChange={handleChange}
                        className="input-custom-var2 ms-lg-auto ms-4"
                      />
                    </>
                  )}
                </div>
                {cardListProduct?.digital_product_urls && (
                  <DigitalProductUi
                    setFieldValue={setFieldValue}
                    digital_product_urls={cardListProduct?.digital_product_urls}
                    message={values?.message}
                  />
                )}
              </div>
              <h2 className="font-l fw-bold mt-5 mb-20 fw-800">Requirements</h2>
              <div className="form-group mb-5">
                <span className="form-label d-block">
                  What do you need to get started?
                </span>
                <TextArea
                  rows="3"
                  name="purchaser_requirements"
                  maxLength={300}
                  className="form-control input-lg"
                  value={values.purchaser_requirements}
                  onChange={(e) => {
                    setFieldValue('purchaser_requirements', e.target.value);
                  }}
                />
              </div>
              <h2 className="font-l fw-800 mt-5 mb-2">Gallery</h2>

              <div className=" mx-auto">
                <EditGallery
                  media={mediaList}
                  embedCode={embedCode}
                  setMediaList={setMediaList}
                  deleteListMedia={deleteListMedia}
                  setFieldValue={setFieldValue}
                  setEmbedCode={setEmbedCode}
                  setUrlValid={setUrlValid}
                />
              </div>

              <div className="bottom-pos-btn-container mt-5">
                {/* check button enable physical product for zip code  */}
                {values?.category_id === 4 ? (
                  <button
                    type="submit"
                    className={`btn btn-primary ${
                      (embedCode !== undefined || mediaList.length > 0) &&
                      stateFromPostalCode !== ''
                        ? ''
                        : 'disabled'
                    }`}>
                    Save
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={`btn btn-primary ${
                      embedCode !== undefined || mediaList.length > 0
                        ? ''
                        : 'disabled'
                    }`}>
                    Save
                  </button>
                )}
                <div
                  type="submit"
                  className="text-center w-100 btn mt-4 fw-800 font-18 color-red"
                  onClick={() => deleteListing(editList.id)}>
                  DELETE LISTING
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditListing;
