import {NormailBox, Envelop, FlatBox} from './PricingIcon';

export const shippingData = [
  {
    key: 'Custom Package',
    icon: <NormailBox />,
  },
  {
    key: 'USPS Flat Rate Packaging',
    icon: <FlatBox />,
  },
  {
    key: 'Soft Pack Envelope',
    icon: <Envelop />,
  },
];

export const overViewExpData = [
  {
    key: '0',
    url: '/images/virtial-experience.png  ',
    title: 'Virtual Experience',
  },
  {
    key: '1',
    url: '/images/in-person.png',
    title: 'In- person Experience',
  },
  {
    key: '2',
    url: '/images/social-media.png',
    title: 'Social Media Interaction',
  },
];

export const overviewPhysicalData = [
  {
    key: '0',
    url: '/images/PhysiclaProduct.png',
    title: 'Physical Products',
  },
  {
    key: '1',
    url: '/images/DigitalProduct.png  ',
    title: 'Digital Products',
  },
];

export const subcatExpList = [
  {
    key: 'subCat1',
    text: 'Personalized video message',
  },
  {
    key: 'subCat2',
    text: 'Live video call',
  },
  {
    key: 'subCat3',
    text: 'Live play of a videogame',
  },
  {
    key: 'subCat4',
    text: 'Direct messaging',
  },
];

export const subcatPhyList = [
  {
    key: 'subCat1',
    text: 'Autographed memorabilia',
  },
  {
    key: 'subCat2',
    text: 'Merch',
  },
  {
    key: 'subCat3',
    text: 'Authentic team-issued gear drop',
  },
];
