import styled from 'styled-components'

const Hr = styled.div`
  border-bottom: ${({ size }) => size}px solid ${({ color }) => color};
`

const Styled = {
  Hr,
}

export default Styled
