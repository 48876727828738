import './login.scss';
import LoginMain from '../../Athlete/components/features/LoginMain';
import {loginSection} from '../../../utils/staticStrings';
import {useLocation} from 'react-router';

function Login() {
  const location = useLocation();
  const {state} = location;

  // let dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getPostDetails(params.id));
  // }, [params.id]);

  return (
    <div className="login-main-bg">
      <LoginMain data={loginSection} state={state?.from} />
    </div>
  );
}
export default Login;
