import React, {useRef, useEffect} from 'react';
import {useIntersectionObserver} from '../../../../../hooks/useIntersectionObserver';
import '../../../pages/pages.scss';
import {Table} from 'antd';
import {useHistory} from 'react-router-dom';

export const TableWithoutPagination = ({columns, item, rows}) => {
  const ref = useRef(null);
  const interSecting = useIntersectionObserver({ref});
  const history = useHistory();

  useEffect(() => {
    if (interSecting) {
      // loadMore()
    }
  }, [interSecting]);

  return (
    <Table
      className="custom-table border-none"
      style={{height: '500px', overflowY: 'scroll'}}
      columns={columns}
      dataSource={rows}
      rowKey={(record) => record.id}
      onRow={(record) => ({
        onClick: () => {
          if (record.id) {
            if (item === 'deal') {
              history.push(`/purchaser/deals/${record.id}`);
            }
          }
        },
      })}
      pagination={false}></Table>
  );
};
