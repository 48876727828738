export const formatPrice = (number) => {
  if (number)
    if (number === 0) {
      return '0';
    }
  // let string = '' + number;
  return number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatPriceCommas = (number) => {
  if (number)
    if (number === 0) {
      return '0';
    }
  // let string = '' + number;
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const currencyFormat = (num) => {
  if (num !== undefined) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
};

export const formatSocialCount = (num) => {
  if (num)
    if (num < 1000) {
      return (num / 1000).toFixed(1) + 'k';
    } else if (num < 999999) {
      return (num / 1000).toFixed(0) + 'k'; // convert to K for number from > 1000 < 1 million
    } else if (num >= 999999) {
      return (num / 1000000).toFixed(1) + 'm'; // convert to M for number from > 1 million
    }
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
