import React, {Fragment, useEffect, useState} from 'react';
import Text from '../../../../../components/Text/Text';
import moment from 'moment';
import ModalHeader from './ModalHeader';
import PayModalContainer from './PayModalContainer';
import {CloseOutlined} from '@ant-design/icons';
import {
  OfferDetails,
  CardDetails,
  AcceptOrDecline,
  CounterOffer,
  AcceptOffer,
  Decline,
  AcceptOfferMobile,
  AcceptOrDeclinePurchaser,
  Cancel,
  ModifyOffer,
  PayModal,
} from '../../style';
import useApi from '../../../../../hooks/useApi';
import {CreateCampaign} from '../Campaigns/style';
import CounterOfferModal from './CounterOfferModal';
import ConfirmCancelDeal from '../../../../Athlete/components/features/Deals/ConfirmCancelOffer';
import ConfirmCancelCounterOffer from '../../../../Athlete/components/features/Deals/ConfirmCancelCounterOffer';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import DealsTag from './DealsTag';

const DescriptionFooter = ({
  dealData,
  cancelOffer,
  createCounterOffer,
  cancelCounterOffer,
  acceptCounterOffer,
}) => {
  const [isPayModalVisible, setIsPayModalVisible] = useState(false);
  const [isCounterOfferVisible, setIsCounterOfferVisible] = useState(false);
  const [isDealCancelModalVisible, setIsDealCancelModalVisible] =
    useState(false);
  const [
    isCounterOfferCancelModalVisible,
    setIsCounterOfferCancelModalVisible,
  ] = useState(false);
  const [totalCash, setTotalCash] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [PMs] = useApi('/stripe/payment-methods');

  // console.log("PMs", PMs);

  useEffect(() => {
    const tc = checkForCashCompensation(dealData);
    setTotalCash(tc);
  }, [dealData]);

  useEffect(() => {
    if (PMs && PMs?.success && PMs?.data && PMs?.data[0]) {
      setCardNumber(PMs?.data[0]?.card?.last4);
    }
  }, [PMs]);

  const showPayModal = () => {
    setIsPayModalVisible(true);
  };

  const handlePayCancel = () => {
    setIsPayModalVisible(false);
  };

  const checkForCashCompensation = (values) => {
    let deliverables;
    let total_cash = 0;
    if (values?.counter_offers && values?.counter_offers.length > 0) {
      deliverables = values?.counter_offers[0]?.deliverables;
    } else {
      deliverables = values?.deliverables;
    }
    if (deliverables.length === 1) {
      total_cash = deliverables[0].compensation_cash;
    } else {
      deliverables.forEach((deliverable) => {
        total_cash = total_cash + deliverable.compensation_cash;
      });
    }
    return total_cash;
  };

  const openCounterOffer = () => {
    setIsCounterOfferVisible(true);
    setShowPayment(false);
  };

  const closeCounterOffer = () => {
    setIsCounterOfferVisible(false);
  };

  const showExpirationDate = () => {
    if (dealData?.expiration_date) {
      return moment(dealData?.expiration_date).format('MMM DD, YYYY');
    } else {
      return moment(dealData?.createdAt)
        .add(dealData?.expiration_period, 'days')
        .format('MMM DD, YYYY');
    }
  };

  const handleAccept = () => {
    if (totalCash === 0) {
      acceptCounterOffer();
    } else {
      showPayModal();
    }
  };

  return (
    <Fragment>
      {/* Modal to accept deal & pay */}
      <PayModal
        style={{top: 100}}
        zIndex={2500}
        title={<ModalHeader />}
        visible={isPayModalVisible}
        onCancel={handlePayCancel}
        footer={null}>
        <PayModalContainer dealData={dealData} />
      </PayModal>

      {/* Modal to create counter offer */}
      <CreateCampaign
        style={{top: 100}}
        width={1040}
        zIndex={800}
        closeIcon={<CloseOutlined />}
        title={
          <div
            className="d-flex justify-content-center align-items-center text-center my-3"
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '30px',
              color: '#042237',
            }}>
            Create counter offer
          </div>
        }
        visible={isCounterOfferVisible}
        onCancel={closeCounterOffer}
        footer={null}>
        <CounterOfferModal
          dealData={dealData}
          closeCounterOffer={closeCounterOffer}
          showPayment={showPayment}
          setShowPayment={setShowPayment}
          createCounterOffer={createCounterOffer}
        />
      </CreateCampaign>

      {/* Modal to confirm offer cancellation  */}
      {isDealCancelModalVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span style={{textTransform: 'capitalize'}}>
                Cancel deal #{dealData?.id}
              </span>
            </div>
          }
          visible={isDealCancelModalVisible}
          onCancel={() => {
            setIsDealCancelModalVisible(false);
          }}
          footer={null}>
          <ConfirmCancelDeal cancelOffer={cancelOffer} />
        </CreateCampaign>
      )}

      {/* Modal to confirm counter offer cancellation  */}
      {isCounterOfferCancelModalVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span style={{textTransform: 'capitalize'}}>
                {dealData?.campaign_id !== null &&
                dealData?.counter_offers &&
                dealData?.counter_offers.length === 1 &&
                dealData?.counter_offers[0].athlete_status === 'accepted'
                  ? `Decline Application #${dealData?.id}`
                  : `Cancel counter offer #${dealData?.id}`}
              </span>
            </div>
          }
          visible={isCounterOfferCancelModalVisible}
          onCancel={() => {
            setIsCounterOfferCancelModalVisible(false);
          }}
          footer={null}>
          <ConfirmCancelCounterOffer
            dealData={dealData}
            cancelCounterOffer={cancelCounterOffer}
          />
        </CreateCampaign>
      )}

      <OfferDetails>
        <CardDetails>
          <Text
            display="flex"
            weight="400"
            size="15"
            mrRight="20"
            lineHeight="26"
            textColor="#435969">
            {dealData?.status === 'completed' ||
            dealData?.status === 'in progress' ||
            dealData?.status === 'disputed' ? (
              <>
                Deal agreement date:&nbsp;
                {moment(dealData?.deal_agreement_date).format('MMM DD, YYYY')}
              </>
            ) : (
              <>Offer expiration date : {showExpirationDate()}</>
            )}
          </Text>
          {((dealData?.purchaser_status === 'pending' &&
            dealData?.counter_offers[0]?.purchaser_status === 'accepted') ||
            dealData?.purchaser_status === 'accepted' ||
            dealData?.purchaser_status === 'completed') && (
            <>
              {dealData?.deliverables[0]?.compensation_cash > 0 && (
                <DealsTag
                  src={backgroundStyle?.creditCard}
                  text={`Visa ***${cardNumber}`}
                />
              )}
            </>
          )}
        </CardDetails>
        {dealData?.status === 'application/offer' && (
          <>
            {dealData?.counter_offers.length > 0 && (
              <>
                {dealData?.counter_offers[0].athlete_status === 'pending' &&
                  dealData?.counter_offers[0].purchaser_status ===
                    'accepted' && (
                    <AcceptOrDeclinePurchaser>
                      <Cancel
                        onClick={() => {
                          setIsCounterOfferCancelModalVisible(true);
                        }}>
                        Cancel offer
                      </Cancel>
                      <ModifyOffer onClick={openCounterOffer}>
                        Modify offer
                      </ModifyOffer>
                    </AcceptOrDeclinePurchaser>
                  )}
                {dealData?.counter_offers[0].athlete_status === 'accepted' &&
                  dealData?.counter_offers[0].purchaser_status ===
                    'pending' && (
                    <>
                      <AcceptOfferMobile onClick={handleAccept}>
                        {totalCash === 0 ? 'Accept' : 'Accept and pay'}
                      </AcceptOfferMobile>
                      <AcceptOrDecline>
                        <Decline
                          onClick={() => {
                            setIsCounterOfferCancelModalVisible(true);
                          }}>
                          Decline
                        </Decline>
                        <CounterOffer onClick={openCounterOffer}>
                          Counter offer
                        </CounterOffer>
                        <AcceptOffer onClick={handleAccept}>
                          {totalCash === 0 ? 'Accept' : 'Accept and pay'}
                        </AcceptOffer>
                      </AcceptOrDecline>
                    </>
                  )}
              </>
            )}
            {dealData?.counter_offers.length === 0 && (
              <>
                {dealData?.athlete_status === 'pending' &&
                  dealData?.is_custom_offer && (
                    <AcceptOrDeclinePurchaser>
                      <Cancel
                        onClick={() => {
                          setIsDealCancelModalVisible(true);
                        }}>
                        Cancel offer
                      </Cancel>
                      <ModifyOffer onClick={openCounterOffer}>
                        Modify offer
                      </ModifyOffer>
                    </AcceptOrDeclinePurchaser>
                  )}
                {dealData?.athlete_status === 'accepted' &&
                  dealData?.purchaser_status === 'pending' && (
                    <>
                      <AcceptOfferMobile onClick={handleAccept}>
                        {totalCash === 0 ? 'Accept' : 'Accept and pay'}
                      </AcceptOfferMobile>
                      <AcceptOrDecline>
                        <Decline
                          onClick={() => {
                            setIsDealCancelModalVisible(true);
                          }}>
                          Decline
                        </Decline>
                        <CounterOffer onClick={openCounterOffer}>
                          Counter offer
                        </CounterOffer>
                        <AcceptOffer onClick={handleAccept}>
                          {totalCash === 0 ? 'Accept' : 'Accept and pay'}
                        </AcceptOffer>
                      </AcceptOrDecline>
                    </>
                  )}
              </>
            )}
          </>
        )}
      </OfferDetails>
    </Fragment>
  );
};

export default DescriptionFooter;
