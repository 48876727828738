import {put, call, takeLatest, delay} from 'redux-saga/effects';
import {
  CREATE_CAMPAIGN,
  EDIT_CAMPAIGN,
  GET_CAMPAIGNS,
  GET_CAMPAIGN_DATA,
  HANDLE_BRAND_REGISTER,
  HANDLE_PURCHASER_PROFILE_SUBMIT,
  GET_SHIPPING_COST,
  INVITE_ATHLETE_TO_CAMPAIGN,
  ACTIVATE_OR_PAUSE_CAMPAIGN,
  FETCH_ORDERS,
  CREATE_CUSTOM_OFFER,
  GET_DEALS,
  GET_DEAL_DATA,
  TAG_DEAL_TO_CAMPAIGN,
  UPDATE_DEAL_STATUS_BRAND,
  GET_PAYMENT_METHOD,
  UPDATE_DEAL_COUNTER_OFFER_STATUS_BRAND,
  COUNTER_OFFER_BRAND,
  MARK_DEAL_DISPUTE,
  STATE_LIST,
} from './actionTypes';
import {
  handleBrandMessage,
  handleCampaignStatusChanged,
  handleCreateCampaignStatus,
  handleEditCampaignStatus,
  handleError,
  handleInviteCampaignStatus,
  handlerRegisterSuccess,
  purchaserProfileStatus,
  setCampaignData,
  setCampaigns,
  setLoader,
  setShippingCartData,
  handleOrderdData,
  handleCreateOfferStatus,
  setDeals,
  setDealData,
  handleTagToCampaignStatus,
  appendCampaigns,
  handleDealStatusChanged,
  setPaymentMethod,
  handleCounterOfferCreated,
  shipAddressValid,
  appendDeals,
  handleStateList,
  setDealsLoader,
} from './action';
import {triggerBrandRegister} from '../../modules/Athlete/helpers/apiController';
import {
  triggerCreateCampaign,
  triggerEditCampaignData,
  triggerGetCampaignData,
  triggerGetCampaigns,
  triggerPurchaserProfile,
  triggerGetShippingCost,
  triggerInviteToCampaign,
  getAllOrder,
  triggerCreateCustomOffer,
  triggerGetDealData,
  triggerTagDealToCampaign,
  triggerDealStatusUpdate,
  triggerGetPaymentMethod,
  triggerDealCounterOfferStatusUpdate,
  triggerCreateCounterOffer,
  triggerMarkDealDispute,
  triggerPurchaserGetDeals,
  triggerGetStateList,
} from '../../modules/Purchaser/helpers/apiController';

function* onBrandRegister(payload) {
  try {
    const response = yield call(triggerBrandRegister, payload.payload);
    // console.log(response, 'Brand response');
    localStorage.setItem('athletUp-token', response.data.data.token);
    localStorage.setItem('id', response.data.data.id);
    localStorage.setItem('userRole', response.data.data.role);
    localStorage.setItem(
      'athletup-refresh-token',
      response.data.data.refresh_token,
    );
    yield put(handlerRegisterSuccess(true));
  } catch (error) {
    // console.log(error.response.data, 'lll');
    yield put(handleError(error.response.data));
    yield put(handlerRegisterSuccess(false));
  }
}

function* onPurchaserSubmitProfile(payload) {
  try {
    yield call(triggerPurchaserProfile, payload);
    yield put(purchaserProfileStatus(true));
  } catch (error) {
    console.log(error);
    yield put(purchaserProfileStatus(false));
    yield put(handleError(error));
  }
}

function* onCreateCampaign(payload) {
  try {
    yield call(triggerCreateCampaign, payload.payload);
    yield put(handleError());
    yield put(handleCreateCampaignStatus(true));
    yield delay(2000);
    yield put(handleCreateCampaignStatus(false));
  } catch (error) {
    console.log(error);
    yield put(handleError(error));
    yield put(handleCreateCampaignStatus(false));
  }
}

function* onGetCampaigns(payload) {
  try {
    const response = yield call(triggerGetCampaigns, payload.payload);
    if (payload.payload.page > 1) {
      yield put(appendCampaigns(response?.data?.data));
    } else {
      yield put(setCampaigns(response?.data?.data));
    }
    yield put(handleError(''));
  } catch (error) {
    console.log(error);
    yield put(handleError(error));
    yield put(setLoader(false));
  }
}

function* onGetCampaignData(payload) {
  try {
    const response = yield call(triggerGetCampaignData, payload.payload);
    yield put(setCampaignData(response.data.data));
    yield put(handleError(''));
  } catch (error) {
    console.log(error);
    yield put(handleError(error));
    yield put(setLoader(false));
  }
}

function* onGetShippingCost(payload) {
  try {
    const response = yield call(triggerGetShippingCost, payload.payload);
    // console.log(response)
    yield put(setShippingCartData(response.data.data));
    yield put(shipAddressValid({status: true, msg: ''}));
    // yield put(addressError());
  } catch (error) {
    // yield put(addressError(error.response.data));
    yield put(
      shipAddressValid({status: false, msg: error?.response?.data?.message}),
    );
  }
}

function* onActivateOrPauseCampaign(payload) {
  try {
    yield call(triggerEditCampaignData, payload.payload);
    yield put(handleError(''));
    yield put(handleCampaignStatusChanged(true));
  } catch (error) {
    console.log(error);
    yield put(handleCampaignStatusChanged(false));
    yield put(handleError(error));
  }
}

function* onEditCampaignData(payload) {
  try {
    yield call(triggerEditCampaignData, payload.payload);
    yield put(handleError(''));
    yield put(handleEditCampaignStatus(true));
    yield delay(2000);
    yield put(handleEditCampaignStatus(false));
  } catch (error) {
    console.log(error);
    yield put(handleEditCampaignStatus(false));
    yield put(handleError(error));
  }
}

function* onInviteToCampaign(payload) {
  try {
    yield call(triggerInviteToCampaign, payload);
    yield put(handleError(''));
    yield put(handleBrandMessage({}));
    yield put(handleInviteCampaignStatus(true));
  } catch (error) {
    console.log(error);
    yield put(handleInviteCampaignStatus(false));
    // yield put(handleError(error));
    yield put(handleBrandMessage(error?.response?.data));
  }
}
function* onFetchOrders(payload) {
  try {
    yield put(setLoader(true));
    const respones = yield call(getAllOrder, payload.payload);
    // console.log(respones.data.data, "Response fetch order")
    yield put(handleOrderdData(respones.data.data));
    yield put(setLoader(false));
  } catch (error) {
    console.log(error);
    yield put(handleError(error));
    yield put(setLoader(false));
  }
}

function* onCreateCustomOffer(payload) {
  try {
    yield call(triggerCreateCustomOffer, payload.payload);
    yield put(handleError(''));
    yield put(handleCreateOfferStatus(true));
  } catch (error) {
    console.log(error?.response?.data);
    yield put(handleCreateOfferStatus(false));
    yield put(handleError(error?.response?.data));
  }
}

function* onGetDeals(payload) {
  try {
    yield put(setDealsLoader(true));
    const response = yield call(triggerPurchaserGetDeals, payload.payload);
    if (payload.payload.page > 1) {
      yield put(appendDeals(response?.data?.data));
    } else {
      yield put(setDeals(response?.data?.data));
    }
    yield put(handleError(''));
    yield put(setDealsLoader(false));
  } catch (error) {
    console.log(error);
    yield put(handleError(error));
    yield put(setDealsLoader(false));
  }
}

function* onGetDealData(payload) {
  try {
    yield put(setLoader(true));
    const response = yield call(triggerGetDealData, payload.payload);
    yield put(setDealData(response?.data?.data));
    yield put(handleError(''));
    yield put(setLoader(false));
  } catch (error) {
    console.log(error);
    yield put(handleError(error));
    yield put(setDealData({}));
    yield put(setLoader(false));
  }
}

function* onTagDealToCampaign(payload) {
  try {
    yield call(triggerTagDealToCampaign, payload.payload);
    yield put(handleError(''));
    yield put(handleBrandMessage({}));
    yield put(handleTagToCampaignStatus(true));
    yield delay(2000);
    yield put(handleTagToCampaignStatus(false));
  } catch (error) {
    console.log(error);
    yield put(handleInviteCampaignStatus(false));
    yield put(handleError(error));
    yield put(handleBrandMessage(error?.response?.data));
  }
}

function* onGetPaymentMethod() {
  try {
    const response = yield call(triggerGetPaymentMethod);
    yield put(setPaymentMethod(response.data.data));
    yield put(handleError(''));
  } catch (error) {
    console.log(error);
    yield put(handleError(error));
  }
}

function* onUpdateDealStatus(payload) {
  try {
    yield call(triggerDealStatusUpdate, payload);
    yield put(handleError(''));
    yield put(handleDealStatusChanged(payload.payload.status));
    yield delay(2000);
    yield put(handleDealStatusChanged(null));
  } catch (error) {
    yield put(handleError(error?.response?.data));
    yield put(handleDealStatusChanged(null));
  }
}

function* onUpdateDealCounterOfferStatus(payload) {
  try {
    yield call(triggerDealCounterOfferStatusUpdate, payload);
    yield put(handleError(''));
    yield put(handleDealStatusChanged(payload.payload.status));
    yield delay(2000);
    yield put(handleDealStatusChanged(null));
  } catch (error) {
    console.log(error);
    yield put(handleError(error));
    yield put(handleDealStatusChanged(null));
  }
}

function* onCreateCounterOffer(payload) {
  try {
    yield call(triggerCreateCounterOffer, payload);
    yield put(handleError(''));
    yield put(handleCounterOfferCreated(true));
    yield delay(2000);
    yield put(handleCounterOfferCreated(false));
  } catch (error) {
    console.log(error);
    yield put(handleError(error));
    yield put(handleCounterOfferCreated(false));
  }
}

function* onMarkDealDispute(payload) {
  try {
    yield call(triggerMarkDealDispute, payload);
    yield put(handleError(''));
    yield put(handleDealStatusChanged('disputed'));
    yield delay(2000);
    yield put(handleDealStatusChanged(null));
  } catch (error) {
    console.log(error);
    yield put(handleError(error));
    yield put(handleDealStatusChanged(null));
  }
}

function* onGetStateList(payload) {
  try {
    const res = yield call(triggerGetStateList, payload.payload);
    yield put(handleStateList(res.data?.data?.rows));
  } catch (error) {
    console.log(error.respones);
  }
}

function* BrandModuleSaga() {
  yield takeLatest(HANDLE_BRAND_REGISTER, onBrandRegister);
  yield takeLatest(HANDLE_PURCHASER_PROFILE_SUBMIT, onPurchaserSubmitProfile);
  yield takeLatest(CREATE_CAMPAIGN, onCreateCampaign);
  yield takeLatest(GET_CAMPAIGNS, onGetCampaigns);
  yield takeLatest(GET_CAMPAIGN_DATA, onGetCampaignData);
  yield takeLatest(GET_SHIPPING_COST, onGetShippingCost);
  yield takeLatest(EDIT_CAMPAIGN, onEditCampaignData);
  yield takeLatest(INVITE_ATHLETE_TO_CAMPAIGN, onInviteToCampaign);
  yield takeLatest(FETCH_ORDERS, onFetchOrders);
  yield takeLatest(ACTIVATE_OR_PAUSE_CAMPAIGN, onActivateOrPauseCampaign);
  yield takeLatest(CREATE_CUSTOM_OFFER, onCreateCustomOffer);
  yield takeLatest(GET_DEALS, onGetDeals);
  yield takeLatest(GET_DEAL_DATA, onGetDealData);
  yield takeLatest(TAG_DEAL_TO_CAMPAIGN, onTagDealToCampaign);
  yield takeLatest(GET_PAYMENT_METHOD, onGetPaymentMethod);
  yield takeLatest(UPDATE_DEAL_STATUS_BRAND, onUpdateDealStatus);
  yield takeLatest(
    UPDATE_DEAL_COUNTER_OFFER_STATUS_BRAND,
    onUpdateDealCounterOfferStatus,
  );
  yield takeLatest(COUNTER_OFFER_BRAND, onCreateCounterOffer);
  yield takeLatest(MARK_DEAL_DISPUTE, onMarkDealDispute);
  yield takeLatest(STATE_LIST, onGetStateList);
}

export default BrandModuleSaga;
