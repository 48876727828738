import React, {useState, useEffect, useCallback} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {BackArrow, Clock, Dollar, GreenTick, Tag, Tick} from '../../Icons';
import {
  CampaignDetailsCard,
  BackSection,
  ActionGreyButton,
  ActionBlackButton,
  ActionGreenButton,
} from '../../style';
import CustomModal from '../../../../../common/components/CustomModal';
import CreateCounterOffer from './CreateCounterOffer';
import {CompletedButton, ConfirmDealInfo, MarkCompleteButton} from './style';
import {useDispatch, useSelector} from 'react-redux';
import {CloseOutlined} from '@ant-design/icons';
import {
  createCounterOffer,
  getAthleteDealData,
  updateDealCounterOfferStatus,
  updateDealStatus,
} from '../../../../../store/athleteflow/action';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import {toastMessage} from '../../../../../helpers/toastMessage';
import {MessageCard} from '../../../../Purchaser/pages/style';
import CompleteDeal from './CompleteDeal';
import {CreateCampaign} from '../../../../Purchaser/components/features/Campaigns/style';
import http from '../../../../../helpers/http';
import CustomMessageChannel from '../../../../../../src/common/Messages/CustomMessageChannel';
import ConfirmAthDeclineDeal from './ConfirmAthDeclineDeal';
import ConfirmAthDeclineCounterOffer from './ConfirmAthDeclineCounterOffer';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import {formatPriceCommas} from '../../../../../helpers/user';
const Chat = require('twilio-chat');

const DealDetails = () => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  const [createdDate, setCreatedDate] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [messagesList, setMessage] = useState([]);
  const [channelPrivate, setChanel] = useState(null);
  const [loader, setLoader] = useState(true);
  const [participantsDetails, setParticipantDetails] = useState([]);
  const [dealCompletd, setDealComplete] = useState(false);
  const [tokenTwilio, setToken] = useState('');
  const [disputeLastDate, setDisputeLastDate] = useState('');
  const [isConfirmCancelDealVisible, setIsConfirmCancelDealVisible] =
    useState(false);
  const [
    isConfirmCancelCounterOfferVisible,
    setIsConfirmCancelCounterOfferVisible,
  ] = useState(false);
  // const [noOfDaysLeft, setNoOfDaysLeft] = useState(null);
  const {dealData, isLoading, dealStatusChanged, counterOfferCreated} =
    useSelector((state) => state.AthleteModuleReducer);
  const {athleteDetails} = useSelector((state) => state.UsersReducer);
  const dispatch = useDispatch();
  const {id} = useParams();

  const markReadDeal = useCallback(
    async (data) => {
      try {
        let url = `/deal/${dealData?.id}/mark-read`;
        await http.put(url);
      } catch (err) {
        console.log(err);
      }
    },
    [dealData?.id],
  );

  useEffect(() => {
    dispatch(getAthleteDealData({id: id}));
  }, [id, dispatch]);

  useEffect(() => {
    if (dealData && dealData?.id) {
      markReadDeal(dealData?.id);
    }
  }, [dealData, markReadDeal]);

  useEffect(() => {
    if (dealData?.status === 'completed') {
      let bannerShowTillDate = moment(dealData?.completed_on).add(4, 'days');
      if (
        !moment().isBetween(moment(dealData?.completed_on), bannerShowTillDate)
      ) {
        setDealComplete(true);
      }
    } else {
      setDealComplete(false);
    }
  }, [dealData]);

  useEffect(() => {
    if (dealStatusChanged === 'accepted') {
      toastMessage('Deal accepted');
      dispatch(getAthleteDealData({id: id}));
    } else if (dealStatusChanged === 'declined') {
      toastMessage('Deal declined');
      dispatch(getAthleteDealData({id: id}));
    } else if (dealStatusChanged === 'completed') {
      toastMessage('Deal marked completed');
      dispatch(getAthleteDealData({id: id}));
    }
  }, [id, dispatch, dealStatusChanged]);

  const getToken = async () => {
    const response = await http.get(
      `/twilio/token?username=user${athleteDetails?.id}`,
    );
    return response.data.data.token;
  };

  const createChannelFn = async () => {
    let token = '';
    setLoader(true);
    try {
      token = await getToken();
      setToken(token);
    } catch {
      throw new Error('unable to get token, please reload this page');
    }

    const client = await Chat.Client.create(token);

    client.on('tokenAboutToExpire', async () => {
      const token = await getToken();
      client.updateToken(token);
    });

    client.on('tokenExpired', async () => {
      const token = await getToken();
      client.updateToken(token);
    });

    try {
      const channel = await client.getChannelBySid(dealData?.channel_id);
      await http.put(`twilio/conversation/${dealData?.channel_id}/mark-read`);

      if (participantsDetails.length === 0) {
        let particpatantChannel = await http.get(
          `/twilio/conversation/${dealData?.channel_id}/participants`,
        );
        setParticipantDetails(particpatantChannel?.data?.data);
      }
      await joinChannel(channel);
      setChanel(channel);
      getMessages(channel);
      channel.on('messageAdded', function (message) {
        getMessages(channel);
        // setMessage([...messagesFromChannel?.items, message]);
      });
      setLoader(false);
    } catch {
      throw new Error('unable to create channel, please reload this page');
    }
  };

  const joinChannel = async (channel) => {
    if (channel.channelState.status !== 'joined') {
      await channel.join();
    }
  };

  const getMessages = async (channel) => {
    const messages = await channel.getMessages();
    setMessage(messages.items);
  };

  useEffect(() => {
    let initiateToken = true;
    if (
      initiateToken &&
      Object.keys(dealData).length > 0 &&
      dealData?.channel_id !== null &&
      athleteDetails?.id !== undefined
    ) {
      createChannelFn(); // run it, run it
    }
    return () => {
      initiateToken = false;
    };
    /* eslint-disable-next-line */
  }, [dealData, athleteDetails]);

  const sendMessageAction = (text) => {
    if (text && String(text).trim()) {
      channelPrivate && channelPrivate.sendMessage(text);
    }
  };

  useEffect(() => {
    if (counterOfferCreated === true) {
      toastMessage('Counter offer created');
      dispatch(getAthleteDealData({id: id}));
    }
  }, [id, dispatch, counterOfferCreated]);

  useEffect(() => {
    let createdAtDate = moment(dealData?.createdAt).format('MMM DD, YYYY');
    setCreatedDate(createdAtDate);
    // If there is expiration date
    if (dealData?.expiration_date) {
      setExpirationDate(
        moment(dealData?.expiration_date).format('MMM DD, YYYY'),
      );
    }
    // If not, calculate by taking created date and expiration period
    else {
      var expiration_date = moment(dealData?.createdAt)
        .add(dealData?.expiration_period, 'days')
        .format('MMM DD, YYYY');
      setExpirationDate(expiration_date);
    }
  }, [
    dealData?.createdAt,
    dealData?.expiration_period,
    dealData?.expiration_date,
  ]);

  useEffect(() => {
    if (dealData?.completed_on !== null) {
      let completedLastDate = moment(dealData?.completed_on)
        .add(4, 'days')
        .format('MMM Do');
      setDisputeLastDate(completedLastDate);
      // const timeLeft = moment(completedLastDate, 'YYYYMMDD').fromNow();
      // if (timeLeft.includes('days')) {
      //   const daysLeft = timeLeft.split(' ')[1];
      //   setNoOfDaysLeft(daysLeft);
      // }
    }
  }, [dealData?.completed_on]);

  const openCounterOfferModal = () => {
    setModalVisible(true);
  };

  const closeCompleteModal = () => {
    setCompleteModalVisible(false);
  };

  const statusType = (value) => {
    if (value === 'completed') {
      return 'status-success ';
    } else if (value === 'in progress') {
      return 'status-progress';
    } else if (value === 'declined') {
      return 'cancel';
    } else if (value === 'application/offer') {
      return 'offer';
    } else if (value === 'disputed') {
      return 'disputed';
    }
  };

  const deliverOn = (deliverable) => {
    const fulFillType = deliverable.fulfillment_type;
    if (fulFillType === 'Before Date') {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD, YYYY',
      );
      return `Deliver before: ${fulFillDate}`;
    } else if (fulFillType === 'TBD') {
      return 'TBD';
    } else if (fulFillType === 'Specific Date') {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD, YYYY',
      );
      return `Deliver on: ${fulFillDate}`;
    } else if (fulFillType === 'Within X Days after product received') {
      return `Within ${deliverable.fulfillment_days} days after product received`;
    } else {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD, YYYY',
      );
      return `Deliver on: ${fulFillDate}`;
    }
  };

  const acceptDeal = () => {
    const payload = {
      status: 'accepted',
    };
    dispatch(updateDealStatus(payload, dealData.id));
  };

  const declineDeal = () => {
    const payload = {
      status: 'declined',
    };
    dispatch(updateDealStatus(payload, dealData.id));
    setIsConfirmCancelDealVisible(false);
  };

  const markDealComplete = () => {
    const payload = {
      status: 'completed',
    };
    dispatch(updateDealStatus(payload, dealData.id));
    closeCompleteModal();
  };

  const declineCounterOffer = () => {
    const payload = {
      status: 'declined',
    };
    dispatch(updateDealCounterOfferStatus(payload, dealData.id));
    setIsConfirmCancelCounterOfferVisible(false);
  };

  const acceptCounterOffer = () => {
    const payload = {
      status: 'accepted',
    };
    dispatch(updateDealCounterOfferStatus(payload, dealData.id));
  };

  const createAthCounterOffer = (values) => {
    dispatch(createCounterOffer(values, dealData.id));
  };

  const startVideoCall = () => {
    if (tokenTwilio === '') {
      return;
    }

    channelPrivate.sendMessage(
      'in inviting you to join a video call happening now.',
      `/purchaser/video-call/${dealData?.channel_id}`,
    );

    window.open(`/athlete/video-call/${dealData?.channel_id}`);
  };

  return (
    <>
      <BackSection
        onClick={() => {
          if (history.length > 2) {
            history.goBack();
          } else {
            history.push(`/athlete/deals`);
          }
        }}>
        <BackArrow />
        <span className="ms-2">Back</span>
      </BackSection>
      {isLoading && (
        <div className="loader">
          <Loader type="Bars" color="#00BFFF" height={50} width={50} />
        </div>
      )}
      {!isLoading && Object.keys(dealData)?.length > 0 && (
        <>
          {dealData?.status === 'disputed' && (
            <div className="disputed my-3">
              Successful deal completion is currently disputed. Admin will be
              reaching out to both parties individually to discuss the details.
            </div>
          )}
          <CampaignDetailsCard>
            <div className="cam-header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div>Deal #{dealData.id} </div>
                <div
                  className={`${statusType(
                    dealData?.status,
                  )} titlecase font-s ms-2`}>
                  {dealData?.status}
                </div>
                {dealData?.athlete_status === 'completed' &&
                  dealData?.purchaser_status !== 'completed' &&
                  dealData?.status !== 'disputed' && (
                    <div className="offer font-s ms-2">
                      Marked complete by you
                    </div>
                  )}
              </div>
              <div>
                {dealData.status === 'in progress' && (
                  <MarkCompleteButton
                    className="d-none d-lg-block"
                    onClick={() => {
                      setCompleteModalVisible(true);
                    }}>
                    <Tick />
                    <span className="ms-2">Mark as complete</span>
                  </MarkCompleteButton>
                )}
                {dealData?.status === 'completed' && (
                  <CompletedButton className="d-none d-lg-block">
                    <GreenTick />
                    <span className="ms-3">Completed</span>
                  </CompletedButton>
                )}
              </div>
            </div>
            <div className="cam-sub-title">
              <img
                src={
                  dealData?.purchaser?.purchaser?.logo
                    ? dealData?.purchaser?.purchaser?.logo
                    : dealData?.purchaser?.image_url
                    ? dealData?.purchaser?.image_url
                    : backgroundStyle?.defaultProfilePic
                }
                alt="Deal icon"
                width="32"
                height="32"
              />
              <div className="d-none d-lg-flex">
                <div className="mx-2 fw-500">{`Deal by ${
                  dealData?.purchaser?.purchaser?.company
                    ? dealData?.purchaser?.purchaser?.company +
                      ' (' +
                      dealData?.purchaser?.name +
                      ' )'
                    : dealData?.purchaser?.name
                }`}</div>
                | <div className="mx-2">Created {createdDate}</div>
              </div>
              <div className="d-block d-lg-none mx-2 font-15 fw-500">
                <div>{`Deal by ${
                  dealData?.purchaser?.purchaser?.company
                    ? dealData?.purchaser?.purchaser?.company +
                      ' (' +
                      dealData?.purchaser?.name +
                      ' )'
                    : dealData?.purchaser?.name
                }`}</div>
                <div>Created {createdDate}</div>
              </div>
            </div>
            {dealData?.athlete_status === 'completed' &&
              dealData?.purchaser_status !== 'completed' &&
              dealData?.athlete_status === 'completed' &&
              dealData?.status !== 'disputed' && (
                <ConfirmDealInfo className="d-none d-lg-block">
                  The Counterparty has 4 days (until {disputeLastDate}) to
                  confirm or dispute deal completion.
                </ConfirmDealInfo>
              )}
            <div className="d-block d-lg-none">
              {dealData?.status === 'in progress' && (
                <MarkCompleteButton
                  onClick={() => {
                    setCompleteModalVisible(true);
                  }}>
                  <Tick />
                  <span className="ms-2">Mark as complete</span>
                </MarkCompleteButton>
              )}
            </div>
            <div className="d-block d-lg-none">
              {dealData?.status === 'completed' && (
                <CompletedButton>
                  <GreenTick />
                  <span className="ms-3">Completed</span>
                </CompletedButton>
              )}
            </div>
            {dealData?.athlete_status === 'completed' &&
              dealData?.purchaser_status !== 'completed' &&
              dealData?.athlete_status === 'completed' &&
              dealData?.status !== 'disputed' && (
                <ConfirmDealInfo className="d-block d-lg-none">
                  The Counterparty has 4 days (until {disputeLastDate}) to
                  confirm or dispute deal completion.
                </ConfirmDealInfo>
              )}
            <div className="cam-desc-card">
              <div className="cam-title">
                {dealData?.title}
                <div className="float-right d-none d-lg-block">
                  $
                  {dealData?.counter_offers.length > 0
                    ? formatPriceCommas(dealData?.counter_offers[0].total_price)
                    : formatPriceCommas(dealData?.total_price)}
                </div>
                <div className="d-flex d-lg-none mt-2">
                  $
                  {dealData?.counter_offers.length > 0
                    ? formatPriceCommas(dealData?.counter_offers[0].total_price)
                    : formatPriceCommas(dealData?.total_price)}
                </div>
              </div>
              <div className="cam-desc">
                <div className="fw-700 font-m my-1">Description</div>
                <div>
                  {dealData?.counter_offers.length > 0
                    ? dealData?.counter_offers[0].description
                    : dealData?.description}
                </div>
                <div className="fw-700 mt-3">Deliverables</div>
                {dealData?.counter_offers &&
                  dealData?.counter_offers.length > 0 &&
                  dealData?.counter_offers[0]?.deliverables.map(
                    (deliverable, i) => (
                      <div className="d-flex cam-deliverable mt-1" key={i}>
                        <div className="me-4 d-flex">
                          <div className="me-2">
                            <Tag />
                          </div>
                          <div>{deliverable.type}</div>
                        </div>
                        <div className="me-4 d-flex">
                          <div className="me-2">
                            <Clock />
                          </div>
                          <div>{deliverOn(deliverable)}</div>
                        </div>
                        <div className="me-4 d-flex">
                          <div className="me-2">
                            <Dollar />
                          </div>
                          <div>{`Cash ($${formatPriceCommas(
                            deliverable?.compensation_cash,
                          )}) + Products ($${formatPriceCommas(
                            deliverable?.compensation_product,
                          )})`}</div>
                        </div>
                      </div>
                    ),
                  )}
                {dealData?.counter_offers.length === 0 &&
                  dealData?.deliverables &&
                  dealData?.deliverables.map((deliverable, i) => (
                    <div className="d-flex cam-deliverable mt-1" key={i}>
                      <div className="me-4 d-flex">
                        <div className="me-2">
                          <Tag />
                        </div>
                        <div>{deliverable.type}</div>
                      </div>
                      <div className="me-4 d-flex">
                        <div className="me-2">
                          <Clock />
                        </div>
                        <div>{deliverOn(deliverable)}</div>
                      </div>
                      <div className="me-4 d-flex">
                        <div className="me-2">
                          <Dollar />
                        </div>
                        <div>{`Cash ($${formatPriceCommas(
                          deliverable?.compensation_cash,
                        )}) + Products ($${formatPriceCommas(
                          deliverable?.compensation_product,
                        )})`}</div>
                      </div>
                    </div>
                  ))}

                <div className="cam-details-footer">
                  {dealData?.status === 'in progress' ||
                  dealData?.status === 'completed' ||
                  dealData?.status === 'disputed' ? (
                    <>
                      Deal agreement date:&nbsp;
                      {moment(dealData?.deal_agreement_date).format(
                        'MMM DD, YYYY',
                      )}
                    </>
                  ) : (
                    <>Offer expiration date: {expirationDate}</>
                  )}

                  {dealData.status === 'application/offer' && (
                    <>
                      {dealData?.counter_offers.length > 0 && (
                        <>
                          {dealData?.counter_offers[0].purchaser_status ===
                            'pending' &&
                            dealData?.counter_offers[0].athlete_status ===
                              'accepted' && (
                              <div className="d-none d-lg-flex">
                                <ActionGreyButton
                                  // className="fw-600"
                                  onClick={() => {
                                    setIsConfirmCancelDealVisible(true);
                                  }}>
                                  Cancel offer
                                </ActionGreyButton>
                                <ActionBlackButton
                                  // className="fw-600"
                                  onClick={openCounterOfferModal}>
                                  Modify offer
                                </ActionBlackButton>
                              </div>
                            )}
                          {dealData?.counter_offers[0].athlete_status ===
                            'pending' &&
                            dealData?.counter_offers[0].purchaser_status ===
                              'accepted' && (
                              <div className="d-none d-lg-flex">
                                <ActionGreyButton
                                  // className="fw-600"
                                  onClick={() => {
                                    setIsConfirmCancelCounterOfferVisible(true);
                                  }}>
                                  Decline
                                </ActionGreyButton>
                                <ActionBlackButton
                                  // className="fw-600"
                                  onClick={openCounterOfferModal}>
                                  Counter offer
                                </ActionBlackButton>
                                <ActionGreenButton onClick={acceptCounterOffer}>
                                  Accept
                                </ActionGreenButton>
                              </div>
                            )}
                        </>
                      )}
                      {dealData?.counter_offers.length === 0 && (
                        <>
                          {dealData?.purchaser_status === 'pending' &&
                            dealData?.athlete_status === 'accepted' && (
                              <div className="d-none d-lg-flex">
                                <ActionGreyButton
                                  // className="fw-600"
                                  onClick={() => {
                                    setIsConfirmCancelDealVisible(true);
                                  }}>
                                  Cancel offer
                                </ActionGreyButton>
                                <ActionBlackButton
                                  // className="fw-600"
                                  onClick={openCounterOfferModal}>
                                  Modify offer
                                </ActionBlackButton>
                              </div>
                            )}
                          {dealData?.athlete_status === 'pending' &&
                            dealData?.purchaser_status === 'accepted' && (
                              <div className="d-none d-lg-flex">
                                <ActionGreyButton
                                  // className="fw-600"
                                  onClick={() => {
                                    setIsConfirmCancelDealVisible(true);
                                  }}>
                                  Decline
                                </ActionGreyButton>
                                <ActionBlackButton
                                  // className="fw-600"
                                  onClick={openCounterOfferModal}>
                                  Counter offer
                                </ActionBlackButton>
                                <ActionGreenButton onClick={acceptDeal}>
                                  Accept
                                </ActionGreenButton>
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="d-block d-lg-none">
                  {dealData?.status === 'application/offer' && (
                    <>
                      {dealData?.counter_offers.length > 0 && (
                        <>
                          {dealData?.counter_offers[0].purchaser_status ===
                            'pending' &&
                            dealData?.counter_offers[0].athlete_status ===
                              'accepted' && (
                              <>
                                <ActionBlackButton
                                  className="fw-700 mt-3"
                                  onClick={openCounterOfferModal}>
                                  Modify offer
                                </ActionBlackButton>
                                <ActionGreyButton
                                  className="fw-700 mt-3"
                                  onClick={() => {
                                    setIsConfirmCancelDealVisible(true);
                                  }}>
                                  Cancel offer
                                </ActionGreyButton>
                              </>
                            )}
                          {dealData?.counter_offers[0].athlete_status ===
                            'pending' &&
                            dealData?.counter_offers[0].purchaser_status ===
                              'accepted' && (
                              <>
                                <ActionGreenButton onClick={acceptCounterOffer}>
                                  Accept
                                </ActionGreenButton>
                                <div className="d-flex justify-content-between">
                                  <ActionBlackButton
                                    className="mt-3"
                                    onClick={openCounterOfferModal}>
                                    Counter offer
                                  </ActionBlackButton>
                                  <ActionGreyButton
                                    className="mt-3"
                                    onClick={() => {
                                      setIsConfirmCancelCounterOfferVisible(
                                        true,
                                      );
                                    }}>
                                    Decline
                                  </ActionGreyButton>
                                </div>
                              </>
                            )}
                        </>
                      )}
                      {dealData?.counter_offers.length === 0 && (
                        <>
                          {dealData?.purchaser_status === 'pending' &&
                            dealData?.athlete_status === 'accepted' && (
                              <>
                                <ActionBlackButton
                                  className="fw-700 mt-3"
                                  onClick={openCounterOfferModal}>
                                  Modify offer
                                </ActionBlackButton>
                                <ActionGreyButton
                                  className="fw-700 mt-3"
                                  onClick={() => {
                                    setIsConfirmCancelDealVisible(true);
                                  }}>
                                  Cancel offer
                                </ActionGreyButton>
                              </>
                            )}
                          {dealData?.athlete_status === 'pending' &&
                            dealData?.purchaser_status === 'accepted' && (
                              <div>
                                <ActionGreenButton onClick={acceptDeal}>
                                  Accept
                                </ActionGreenButton>
                                <div className="d-flex justify-content-between">
                                  <ActionBlackButton
                                    className="mt-3"
                                    onClick={openCounterOfferModal}>
                                    Counter offer
                                  </ActionBlackButton>
                                  <ActionGreyButton
                                    className="mt-3"
                                    onClick={() => {
                                      setIsConfirmCancelDealVisible(true);
                                    }}>
                                    Decline
                                  </ActionGreyButton>
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </CampaignDetailsCard>
          {dealData !== undefined && dealData?.channel_id !== null && (
            <MessageCard>
              {Object.keys(athleteDetails).length > 0 && (
                <CustomMessageChannel
                  messages={messagesList}
                  loader={loader}
                  participantsDetails={participantsDetails}
                  channelProxy={channelPrivate}
                  enableAttachMent={true}
                  enableVideo={true}
                  startVideoCall={startVideoCall}
                  sendMessageAction={sendMessageAction}
                  loadingChannel={false}
                  messageStatus={
                    dealData?.status === 'declined' ||
                    dealCompletd ||
                    dealData?.purchaser_status === 'completed'
                    /* dealData?.marked_dispute === true */
                  }
                />
              )}
            </MessageCard>
          )}
        </>
      )}

      <CustomModal
        title="Create counter offer"
        visible={modalVisible}
        width="710px"
        isLoading={false}
        onClickCancel={() => setModalVisible(false)}
        className="deal-counter-offer">
        <CreateCounterOffer
          dealData={dealData}
          createAthCounterOffer={createAthCounterOffer}
          closeCounterOffer={() => setModalVisible(false)}
        />
      </CustomModal>
      {completeModalVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span style={{textTransform: 'capitalize'}}>
                Mark deal #{dealData?.id} as complete
              </span>
            </div>
          }
          visible={completeModalVisible}
          onCancel={closeCompleteModal}
          footer={null}>
          <CompleteDeal markDealComplete={markDealComplete} />
        </CreateCampaign>
      )}

      {/* Modal to confirm deal cancellation  */}
      {isConfirmCancelDealVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span style={{textTransform: 'capitalize'}}>
                Cancel deal #{dealData?.id}
              </span>
            </div>
          }
          visible={isConfirmCancelDealVisible}
          onCancel={() => {
            setIsConfirmCancelDealVisible(false);
          }}
          footer={null}>
          <ConfirmAthDeclineDeal declineDeal={declineDeal} />
        </CreateCampaign>
      )}

      {/* Modal to confirm counter offer cancellation  */}
      {isConfirmCancelCounterOfferVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span style={{textTransform: 'capitalize'}}>
                Cancel counter offer #{dealData?.id}
              </span>
            </div>
          }
          visible={isConfirmCancelCounterOfferVisible}
          onCancel={() => {
            setIsConfirmCancelCounterOfferVisible(false);
          }}
          footer={null}>
          <ConfirmAthDeclineCounterOffer
            declineCounterOffer={declineCounterOffer}
          />
        </CreateCampaign>
      )}
    </>
  );
};

export default DealDetails;
