import React from 'react';
import CampaignInvite from './CampaignInvite';

const InviteContainer = ({type = '', showSubmitOfferModal, athleteId, closeCampaignInvite, changeTaggedStatus}) => {
  return (
    <CampaignInvite 
      type={type} 
      athleteId={athleteId}
      closeCampaignInvite={closeCampaignInvite}
      changeTaggedStatus={changeTaggedStatus}
      showSubmitOfferModal={showSubmitOfferModal} />
  );
};

export default InviteContainer;
