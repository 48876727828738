import React, {useState, useEffect, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import AppLayout from '../../../../../common/AppLayout';
import Orders from '../Dashboard/Orders';
import {toastMessage} from '../../../../../helpers/toastMessage';
import {setHeader} from '../../../../../store/athleteflow/action';
import {CloseOutlined} from '@ant-design/icons';
import {Tabs} from 'antd';
import ProfileSettings from '../../../../../common/components/ProfileSettings';
import {handleSidebarTab} from '../../../../../store/users/actions';
import {TableWithInfiniteScroll} from '../Dashboard/TableWithInfiniteScroll';
import {CustomTabs, LayoutDesktopView, LayoutMobileView} from '../../style';
import Text from '../../../../../components/Text/Text';
import {getPurchaserDeals} from '../../../../../store/brandflow/action';
import moment from 'moment';
import ProfileImage from '../../ProfileImage';
import useApi from '../../../../../hooks/useApi';
import Loader from 'react-loader-spinner';
import './style.scss';
import {CustomCampaign} from '../Athletes/styled';
import TagtoCampaign from './TagToCampaign';
import StatsData from '../../../../../common/components/Stats';
import {useInterval} from '../../../../../hooks/intervalHook';
import {formatPriceCommas} from '../../../../../helpers/user';
import useTabActive from '../../../../../hooks/useTabActive';

const {TabPane} = Tabs;

const statusType = (value) => {
  if (value === 'completed') {
    return 'status-success ';
  } else if (value === 'in progress') {
    return 'status-progress';
  } else if (value === 'declined') {
    return 'cancel';
  } else if (value === 'application/offer') {
    return 'offer';
  } else if (value === 'disputed') {
    return 'disputed';
  }
};

const Deals = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabActive] = useTabActive();
  const {
    deals,
    isLoading,
    dealsCount,
    tagToCampaignStatus,
    currentDeals,
    dealsLoader,
  } = useSelector((state) => state.BrandModuleReducer);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(1);
  const [role, setRole] = useState('');
  const [currentDealId, setCurrentDealId] = useState(null);
  const [stats] = useApi('/deal/stats');
  const [isTagModalVisible, setIsTagModalVisible] = useState(false);
  const [loadPath, setLoad] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem('userRole');
    setRole(userRole);
  }, []);

  let dealColumns = [];
  if (role === 'brand') {
    dealColumns = [
      {
        title: 'Deal ID',
        dataIndex: 'id',
        key: 'id',
        // width: "120px",
        render: (text) => (
          <span className="fw-700 color-dark font-15">#{text}</span>
        ),
      },
      {
        title: 'Tagged Campaign',
        dataIndex: 'campaign_id',
        key: 'campaign_id',
        width: '75px',
        onCell: (record) => ({
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (record.id && record.campaign_id) {
              goToCampaign(record.campaign_id);
            } else {
              setCurrentDealId(record.id);
              openTagModal();
            }
          },
        }),
        render: (text) =>
          text ? (
            <button className="btn btn-tarnparant-purple d-none d-xl-block fw-800 text-nowrap">
              <span className="tagged-campaign-text">{`#${text}`}</span>
            </button>
          ) : (
            <button className="btn btn-tarnparant-purple d-none d-xl-block fw-800 text-nowrap">
              + Tag
            </button>
          ),
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => <span>{moment(text).format('MMM DD,YYYY')}</span>,
      },
      {
        title: 'Athlete',
        dataIndex: 'athlete',
        key: 'athlete',
        render: (text) => (
          <div className="d-flex align-items-center">
            <ProfileImage
              width={32}
              imgWidth={32}
              desktopImgWidth={32}
              imgContainerClassName="purchaser radius-m"
              height={32}
              src={text?.image_url}
            />
            <span className="text-start">{text?.name}</span>
          </div>
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (text) => <div style={{width: '300px'}}>{text}</div>,
      },
      {
        title: 'Amount',
        key: 'total_price',
        render: (_, record) => (
          <span>
            {record?.counter_offers && record?.counter_offers.length > 0
              ? '$' + formatPriceCommas(record?.counter_offers[0]?.total_price)
              : '$' + formatPriceCommas(record?.total_price)}
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
          return (
            <span className={`${statusType(text)} text-capitalize`}>
              {role === 'fan' && text === 'application/offer' ? 'offer' : text}
            </span>
          );
        },
      },
    ];
  } else if (role === 'fan') {
    dealColumns = [
      {
        title: 'Deal ID',
        dataIndex: 'id',
        key: 'id',
        // width: "120px",
        render: (text) => (
          <span className="fw-700 color-dark font-15">#{text}</span>
        ),
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => <span>{moment(text).format('MMM DD,YYYY')}</span>,
      },
      {
        title: 'Athlete',
        dataIndex: 'athlete',
        key: 'athlete',
        render: (text) => (
          <div className="d-flex align-items-center">
            <ProfileImage
              width={32}
              imgWidth={32}
              desktopImgWidth={32}
              imgContainerClassName="purchaser radius-m"
              height={32}
              src={text?.image_url}
            />
            <span className="text-start">{text?.name}</span>
          </div>
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (text) => <div style={{width: '300px:'}}>{text}</div>,
      },
      {
        title: 'Amount',
        key: 'total_price',
        render: (_, record) => (
          <span>
            {record?.counter_offers && record?.counter_offers.length > 0
              ? '$' + formatPriceCommas(record?.counter_offers[0]?.total_price)
              : '$' + formatPriceCommas(record?.total_price)}

            {/* {record?.counter_offers && record?.counter_offers.length > 0
              ? record?.counter_offers[record?.counter_offers.length - 1]
                  ?.total_price
              : record?.total_price} */}
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
          return (
            <span className={`${statusType(text)} text-capitalize`}>
              {text === 'application/offer' ? 'Offer' : text}
            </span>
          );
        },
      },
    ];
  }

  const goToCampaign = (id) => {
    history.push(`/purchaser/campaigns/details`, {id: id});
  };

  function callback(key) {
    setPage(1);
    if (key === 'all') {
      setStatus(null);
    } else {
      setStatus(key);
    }
  }

  const callbackDeal = useCallback(async () => {
    try {
      dispatch(
        getPurchaserDeals({
          page: page,
          pageSize: 5,
          status: status,
        }),
      );
      if (history.location.pathname === '/purchaser/deals') {
        setLoad(true);
      } else {
        setLoad(false);
      }
    } catch (err) {
      // dispatch(hideModal());
      console.log(err, 'Fetch api error');
    }
  }, [dispatch, status, page, history.location.pathname]);

  useInterval(callbackDeal, loadPath === true && tabActive ? 40000 : null);

  useEffect(() => {
    callbackDeal();
  }, [callbackDeal]);

  // useEffect(() => {
  //   dispatch(
  //     getPurchaserDeals({
  //       page: page,
  //       pageSize: 5,
  //       status: status,
  //     }),
  //   );
  // }, [dispatch, status, page]);

  useEffect(() => {
    if (history.location.pathname === '/purchaser/deals') {
      dispatch(setHeader(false));
      dispatch(handleSidebarTab('deals'));
      // triggerModal('success', 'Login success');
    }
  }, [history.location.pathname, dispatch]);

  useEffect(() => {
    if (tagToCampaignStatus === true) {
      toastMessage('Deal tagged to campaign');
      dispatch(
        getPurchaserDeals({
          page: page,
          pageSize: 5,
          status: status,
        }),
      );
    }
  }, [tagToCampaignStatus, dispatch, page, status]);

  const pageHandler = (pageData) => {
    if (pageData.current) {
      setPage(pageData.current);
    }
  };

  const loadMore = () => {
    if (dealsCount > 5) {
      setPage((prev) => {
        return prev + 1;
      });
    }
  };

  const renderDeals = () => {
    if (dealsLoader) {
      return (
        <div className="loader-contain">
          <div className="loader">
            <Loader type="Bars" color="#00BFFF" height={50} width={50} />
          </div>
        </div>
      );
    } else {
      if (deals && deals.length > 0) {
        return (
          <div className="table-responsive">
            <TableWithInfiniteScroll
              item="deal"
              columns={dealColumns}
              rows={currentDeals}
              loading={isLoading}
              count={dealsCount}
              current={page}
              onChange={pageHandler}
            />
          </div>
        );
      } else if (deals.length === 0 && !dealsLoader) {
        return (
          <div className="fw-700 d-flex font-18 justify-content-center my-4 pt-5">
            No deals to display
          </div>
        );
      }
    }
  };

  const renderDealsMobile = () => {
    return <Orders item="deals" status={status} page={page} />;
  };

  const openTagModal = () => {
    setIsTagModalVisible(true);
  };

  const closeTagModal = () => {
    setIsTagModalVisible(false);
  };

  return (
    <AppLayout>
      {/* Modal for campaign tagging */}
      <CustomCampaign
        style={{top: 100}}
        width={1040}
        zIndex={2500}
        closeIcon={<CloseOutlined />}
        title={
          <div
            className="d-flex justify-content-center align-items-center text-center"
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '30px',
              color: '#042237',
            }}>
            Select a Campaign to tag the deal #{currentDealId} to
          </div>
        }
        visible={isTagModalVisible}
        onCancel={closeTagModal}
        footer={null}>
        <TagtoCampaign dealId={currentDealId} closeModal={closeTagModal} />
      </CustomCampaign>
      <div className="bubble-bg"></div>
      <div className="content-wrapper">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Text
              display="flex"
              weight="700"
              size="55"
              lineHeight="64"
              mrRight="15"
              smSize="36"
              smLineHeight="46"
              textColor="#042237">
              Deals
            </Text>
            <Text
              display="flex"
              weight="600"
              size="20"
              lineHeight="28"
              smSize="16"
              smLineHeight="26"
              textColor="#435969">
              {`Manage each deal from ${
                role === 'brand' ? 'application' : 'initial offer'
              } through final completion.`}
            </Text>
          </div>
          <div className="d-none d-md-block">
            <ProfileSettings myPurchase={true} />
          </div>
        </div>
        <StatsData
          title="YOUR DEALS"
          cardLabel1="Active deals"
          cardLabel2="Total completed deals"
          cardLabel3="Deal payments this month"
          cardLabel4="Deal payments all time"
          label1Data={stats?.data?.active_deals}
          label2Data={stats?.data?.completed_deals}
          label3Data={stats?.data?.spent_this_month}
          label4Data={stats?.data?.spent_total}
          labelNewCount1={stats?.data?.active_new_deals}
          // labelNewCount2={statsData?.data?.orders_new?.completed}
        />

        <LayoutDesktopView>
          <CustomTabs defaultActiveKey="all" size="large" onChange={callback}>
            <TabPane tab="ALL DEALS" key="all" style={{overflowY: 'scroll'}}>
              {renderDeals()}
            </TabPane>
            <TabPane
              tab={role === 'brand' ? 'APPLICATIONS/OFFERS' : 'CUSTOM OFFERS'}
              key="applications"
              style={{overflowY: 'scroll'}}>
              {renderDeals()}
            </TabPane>
            <TabPane
              tab="DEALS IN PROGRESS"
              key="in progress"
              style={{overflowY: 'scroll'}}>
              {renderDeals()}
            </TabPane>
            <TabPane
              tab="COMPLETED"
              key="completed"
              style={{overflowY: 'scroll'}}>
              {renderDeals()}
            </TabPane>
            <TabPane
              tab="DECLINED"
              key="declined"
              style={{overflowY: 'scroll'}}>
              {renderDeals()}
            </TabPane>
            <TabPane
              tab="DISPUTED"
              key="disputed"
              style={{overflowY: 'scroll'}}>
              {renderDeals()}
            </TabPane>
          </CustomTabs>
        </LayoutDesktopView>
        <LayoutMobileView>
          <CustomTabs defaultActiveKey="all" size="large" onChange={callback}>
            <TabPane tab="ALL DEALS" key="all" style={{overflowY: 'scroll'}}>
              {renderDealsMobile()}
            </TabPane>
            <TabPane
              tab={role === 'brand' ? 'APPLICATIONS/OFFERS' : 'CUSTOM OFFERS'}
              key="applications"
              style={{overflowY: 'scroll'}}>
              {renderDealsMobile()}
            </TabPane>
            <TabPane
              tab="IN PROGRESS"
              key="in progress"
              style={{overflowY: 'scroll'}}>
              {renderDealsMobile()}
            </TabPane>
            <TabPane
              tab="COMPLETED"
              key="completed"
              style={{overflowY: 'scroll'}}>
              {renderDealsMobile()}
            </TabPane>
            <TabPane
              tab="CANCELLED"
              key="declined"
              style={{overflowY: 'scroll'}}>
              {renderDealsMobile()}
            </TabPane>
            <TabPane
              tab="DISPUTED"
              key="disputed"
              style={{overflowY: 'scroll'}}>
              {renderDealsMobile()}
            </TabPane>
          </CustomTabs>
          {dealsCount > 5 * page && (
            <div className="row center">
              <div className="col">
                <button
                  type="button"
                  className="btn clickbtnblue view-more d-flex view-more mx-auto align-items-center text-center  justify-content-center"
                  onClick={loadMore}>
                  {isLoading && (
                    <Loader
                      type="Bars"
                      color="#00BFFF"
                      width={20}
                      className="me-3"
                    />
                  )}
                  view more
                </button>
              </div>
            </div>
          )}
        </LayoutMobileView>
      </div>
    </AppLayout>
  );
};

export default Deals;
