import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setHeader, getShopListing} from '../../../../../store/athleteflow/action';
import {useHistory} from 'react-router-dom';
import {headerSection} from '../../../../../utils/staticStrings';
import CustomModal from '../../../../../common/components/CustomModal';
import ConfirmModal from '../../../../../common/components/ConfirmModal';

// import useApi from '../../../../../hooks/useApi';
import {
  browserBack,
  hanldeError,
} from '../../../../../store/athleteflow/action';
import {getState} from '../../../../../store/brandflow/action';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';
import TemplateListCardUI from './TemplateListCardUI';

const BACKGROUNDS = ["#ede4fc", "#00e3de", "#b9fcaa", "#affce4", "#ede4fc", "#00e3de", "#b9fcaa", "#affce4"];

const CreateTemplateListing = () => {
  const dispatch = useDispatch();
  const {createStatus, error, listBackButton} = useSelector(
    (state) => state.AthleteModuleReducer,
  );
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const {
    shopListing: templates,
    isLoading,
  } = useSelector((state) => state.AthleteModuleReducer);

  useEffect(() => {
    if (error?.message?.statusCode === 400) {
      errorToastMessage(error.message.message);
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error?.message?.success === false) {
      if (error?.message?.message[0]?.text) {
        errorToastMessage(error?.message?.message[0]?.text);
      } else {
        errorToastMessage(error?.message?.message);
      }
    }
  }, [error]);

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (!listBackButton) {
        if (
          window.confirm(
            'Are you sure you want to navigate away from this screen and lose your progress on this listing? \n If you only wish to go back to the previous screen of the listing creation, click "Cancel", then use the gray "Back" button at bottom of this page instead.',
          )
        ) {
          // your logic
          history.push('/athlete/my-shop');
          dispatch(browserBack(true));
        } else {
          window.history.pushState(null, null, window.location.pathname);
          dispatch(browserBack(false));
        }
      }
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [listBackButton, history, dispatch]);

  useEffect(() => {
    if (createStatus === true) {
      toastMessage('List created successfully');
      history.replace('/athlete/my-shop');
    }
  }, [createStatus, history]);

  useEffect(() => {
    dispatch(setHeader(false));
    dispatch(browserBack(false));
    dispatch(hanldeError(''));
    dispatch(getState({country: 'US'}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getShopListing({ pageSize: 500, page: 1, type: 'template' }));
  }, [dispatch]);

  const handleSelect = useCallback(() => {

  }, []);

  const templatesByGroup = useMemo(() => {
    if(isLoading) return {};

    return templates.reduce((acc, template) => {
      // This occurs when there are already listings in the reducer.
      if(!template.groups?.length){
        return acc;
      }
      for(let i = 0; i < template.groups.length; i += 1){
        if (acc[template.groups[i].title]) {
          acc[template.groups[i].title].push(template);
        } else {
          acc[template.groups[i].title] = [template];
        }
      }
      
      return acc;
    }, {});

  }, [templates, isLoading]);

  return (
    <>
      <div className="list-holder">
        <div className="header-style header-light header-fixed athlete-bg">
          <div className="athlete-page-bg border-bottom">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center py-3">
                <a href="/">
                  <img
                    alt="headerlogo"
                    src={headerSection.headerLogoBlack}
                    className="d-inline-block align-top"
                  />
                </a>
                <div
                  className="text-purple cursor-pointer"
                  onClick={() => setVisible(true)}>
                  Exit <i className="icon-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          visible={visible}
          width="630px"
          isLoading={false}
          className="create-list-modal">
          <ConfirmModal
            onCancel={() => setVisible(false)}
            title="Are you sure you want to exit"
            text="Fusce bibendum nisl eu luctus tempus. Donec pellentesque lorem ipsum dolor sit amet adipiscing turpis sagittis"
            link="/athlete/my-shop"
          />
        </CustomModal>

        <div className="steps-content pt-3 pb-5 ms-4 me-4 m d-none d-md-block">
          <div className="text-center">
            <h1 className="fw-bold">Quick-Add Listings</h1>
            <p className="mb-5 color-muted text-center title">
              Quickly add a listing to your Shop from a selection of common Experiences and Products. Just set your price, and modify any details for what you want to offer. 
            </p>
          </div>
          {Object.keys(templatesByGroup).map((templateType, idx) => (
            <TemplateListCardUI 
              key={idx + 1}
              onSelect={handleSelect}
              templates={templatesByGroup[templateType]}
              backgroundColor={BACKGROUNDS[idx]}
              templateType={templateType}
            />
          ))}
        </div>
        
        <div className="steps-content pt-3 pb-5 m d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
          <div className="text-center">
            <h1 className="fw-bold">Quick-Add Listings</h1>
            <p className="mb-5 color-muted text-center title">
              Quickly add a listing to your Shop from a selection of common Experiences and Products. Just set your price, and modify any details for what you want to offer. 
            </p>
          </div>
          {Object.keys(templatesByGroup).map((templateType, idx) => (
            <TemplateListCardUI 
              key={idx + 1}
              onSelect={handleSelect}
              templates={templatesByGroup[templateType]}
              backgroundColor={BACKGROUNDS[idx]}
              templateType={templateType}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default CreateTemplateListing;
