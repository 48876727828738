import {
  HANDLE_BRAND_REGISTER,
  HANDLE_BRAND_ERROR,
  HANDLE_BRAND_REGISTER_SUCCESS,
  HANDLE_PURCHASER_PROFILE_STATUS,
  CREATE_CAMPAIGN_STATUS,
  SET_CAMPAIGNS,
  SET_CAMPAIGN_DATA,
  SET_SHIPPING_COST,
  EDIT_CAMPAIGN_STATUS,
  GET_CAMPAIGNS,
  GET_CAMPAIGN_DATA,
  INVITE_CAMPAIGN_STATUS,
  LOADER,
  SET_ORDERS,
  HANDLE_BRAND_MESSAGE,
  CAMPAIGN_STATUS_CHANGED,
  CREATE_OFFER_STATUS,
  SET_DEALS,
  SET_DEAL_DATA,
  TAG_TO_CAMPAIGN_STATUS,
  APPEND_CAMPAIGNS,
  SET_PAYMENT_METHOD,
  DEAL_STATUS_CHANGED_BRAND,
  COUNTER_OFFER_CREATED_BRAND,
  ADRRESS_VALIDATION,
  APPEND_DEALS,
  SET_STATE_LIST,
  DEALS_LOADER,
} from './actionTypes';

// Reducer common for purchaser type (for both brand and fan)

const initialState = {
  isLoading: false,
  profileSubmitStatus: false,
  registerSuccess: 'null',
  createCampaignStatus: false,
  editCampaignStatus: false,
  inviteCampaignStatus: false,
  campaignStatusChanged: false,
  createOfferStatus: false,
  purchaser: {
    id: '',
    company: '',
    userId: '',
    updatedAt: '',
    createdAt: '',
    industryId: '',
    website: 'null',
  },
  error: {
    type: '',
    message: '',
  },
  message: {
    type: '',
    text: '',
  },
  campaigns: [],
  campaignsCount: 0,
  campaignData: {},
  shippingData: {},
  orderData: [],
  orderListData: [],
  orderCount: 0,
  currentDeals: [],
  deals: [],
  dealsCount: 0,
  dealData: {},
  tagToCampaignStatus: false,
  paymentMethod: null,
  dealStatusChanged: null,
  counterOfferCreated: false,
  validAddress: true,
  stateList: [],
  vaidationMessage: '',
  dealsLoader: true,
};

const BrandModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADER:
      state = {
        ...state,
        isLoading: action.payload,
      };
      break;
    case HANDLE_BRAND_ERROR:
      state = {
        ...state,
        error: {
          type: action.type,
          message: action.payload,
        },
      };
      break;
    case HANDLE_BRAND_MESSAGE:
      state = {
        ...state,
        message: {
          type: action.type,
          text: action.payload,
        },
      };
      break;
    case HANDLE_BRAND_REGISTER:
      state = {
        ...state,
        purchaser: action.payload,
      };
      break;
    case HANDLE_BRAND_REGISTER_SUCCESS:
      state = {
        ...state,
        registerSuccess: action.payload,
      };
      break;
    case HANDLE_PURCHASER_PROFILE_STATUS:
      state = {
        ...state,
        profileSubmitStatus: action.payload,
      };
      break;
    case CREATE_CAMPAIGN_STATUS:
      state = {
        ...state,
        createCampaignStatus: action.payload,
      };
      break;
    case CREATE_OFFER_STATUS:
      state = {
        ...state,
        createOfferStatus: action.payload,
      };
      break;
    case INVITE_CAMPAIGN_STATUS:
      state = {
        ...state,
        inviteCampaignStatus: action.payload,
      };
      break;
    case GET_CAMPAIGNS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case SET_CAMPAIGNS:
      state = {
        ...state,
        campaigns: action.payload.rows,
        campaignsCount: action.payload.count,
        isLoading: false,
      };
      break;
    case APPEND_CAMPAIGNS:
      state = {
        ...state,
        campaigns: [...state.campaigns, ...action.payload.rows],
        campaignsCount: action.payload.count,
        isLoading: false,
      };
      break;
    case GET_CAMPAIGN_DATA:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case SET_CAMPAIGN_DATA:
      state = {
        ...state,
        campaignData: action.payload,
        isLoading: false,
      };
      break;
    case EDIT_CAMPAIGN_STATUS:
      state = {
        ...state,
        editCampaignStatus: action.payload,
      };
      break;
    case CAMPAIGN_STATUS_CHANGED:
      state = {
        ...state,
        campaignStatusChanged: action.payload,
      };
      break;
    case SET_SHIPPING_COST:
      state = {
        ...state,
        shippingData: action.payload,
      };
      break;

    case SET_ORDERS:
      state = {
        ...state,
        orderData: action.payload.rows,
        orderCount: action.payload.count,
      };
      break;
    case SET_DEALS:
      state = {
        ...state,
        deals: action.payload.rows,
        currentDeals: action.payload.rows,
        dealsCount: action.payload.count,
      };
      break;
    case APPEND_DEALS:
      state = {
        ...state,
        deals: [...state.deals, ...action.payload.rows],
        currentDeals: action.payload.rows,
        dealsCount: action.payload.count,
      };
      break;
    case SET_DEAL_DATA:
      state = {
        ...state,
        dealData: action.payload,
      };
      break;
    case TAG_TO_CAMPAIGN_STATUS:
      state = {
        ...state,
        tagToCampaignStatus: action.payload,
      };
      break;
    case SET_PAYMENT_METHOD:
      state = {
        ...state,
        paymentMethod: action.payload,
      };
      break;
    case DEAL_STATUS_CHANGED_BRAND:
      state = {
        ...state,
        dealStatusChanged: action.payload,
      };
      break;
    case COUNTER_OFFER_CREATED_BRAND:
      state = {
        ...state,
        counterOfferCreated: action.payload,
      };
      break;
    case ADRRESS_VALIDATION:
      state = {
        ...state,
        validAddress: action.payload.status,
        vaidationMessage: action.payload.msg,
      };
      break;

    case SET_STATE_LIST:
      state = {
        ...state,
        stateList: action.payload,
      };
      break;
    case DEALS_LOADER:
      state = {
        ...state,
        dealsLoader: action.payload,
      };
      break;
    default:
      state = {...state};
      break;
  }
  return state;
};

export default BrandModuleReducer;
