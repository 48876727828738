export const Twitter = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 2C15.4 2.3 14.8 2.4 14.1 2.5C14.8 2.1 15.3 1.5 15.5 0.7C14.9 1.1 14.2 1.3 13.4 1.5C12.8 0.9 11.9 0.5 11 0.5C9.3 0.5 7.8 2 7.8 3.8C7.8 4.1 7.8 4.3 7.9 4.5C5.2 4.4 2.7 3.1 1.1 1.1C0.8 1.6 0.7 2.1 0.7 2.8C0.7 3.9 1.3 4.9 2.2 5.5C1.7 5.5 1.2 5.3 0.7 5.1C0.7 6.7 1.8 8 3.3 8.3C3 8.4 2.7 8.4 2.4 8.4C2.2 8.4 2 8.4 1.8 8.3C2.2 9.6 3.4 10.6 4.9 10.6C3.8 11.5 2.4 12 0.8 12C0.5 12 0.3 12 0 12C1.5 12.9 3.2 13.5 5 13.5C11 13.5 14.3 8.5 14.3 4.2C14.3 4.1 14.3 3.9 14.3 3.8C15 3.3 15.6 2.7 16 2Z"
      fill="#B9FCAA"
    />
  </svg>
);

export const Instagram = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.75">
      <path
        d="M12.1436 4.85181C12.6738 4.85181 13.1036 4.42199 13.1036 3.89178C13.1036 3.36158 12.6738 2.93176 12.1436 2.93176C11.6134 2.93176 11.1836 3.36158 11.1836 3.89178C11.1836 4.42199 11.6134 4.85181 12.1436 4.85181Z"
        fill="#00E3DE"
      />
      <path
        d="M8.00009 12.0001C5.79404 12.0001 4 10.2061 4 8.00003C4 5.79398 5.79404 3.99994 8.00009 3.99994C10.2061 3.99994 12.0002 5.79398 12.0002 8.00003C12.0002 10.2061 10.2061 12.0001 8.00009 12.0001ZM8.00009 5.99998C6.89707 5.99998 6.00005 6.89701 6.00005 8.00003C6.00005 9.10306 6.89707 10.0001 8.00009 10.0001C9.10312 10.0001 10.0001 9.10306 10.0001 8.00003C10.0001 6.89701 9.10312 5.99998 8.00009 5.99998Z"
        fill="#00E3DE"
      />
      <path
        d="M12.0003 16.0004H4.00009C1.94404 16.0004 0 14.0563 0 12.0003V4.00009C0 1.94404 1.94404 0 4.00009 0H12.0003C14.0563 0 16.0004 1.94404 16.0004 4.00009V12.0003C16.0004 14.0563 14.0563 16.0004 12.0003 16.0004ZM4.00009 2.00005C3.06507 2.00005 2.00005 3.06507 2.00005 4.00009V12.0003C2.00005 12.9533 3.04707 14.0003 4.00009 14.0003H12.0003C12.9353 14.0003 14.0003 12.9353 14.0003 12.0003V4.00009C14.0003 3.06507 12.9353 2.00005 12.0003 2.00005H4.00009Z"
        fill="#00E3DE"
      />
    </g>
  </svg>
);

export const Facebook = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.75"
      d="M17.0001 8.50192C17.0005 6.87743 16.5353 5.28687 15.6597 3.91855C14.7841 2.55024 13.5348 1.46149 12.0596 0.781208C10.5844 0.100931 8.94518 -0.142379 7.336 0.0800895C5.72682 0.302558 4.2151 0.981486 2.97981 2.03648C1.74453 3.09148 0.837431 4.47836 0.365923 6.03292C-0.105585 7.58747 -0.121754 9.24457 0.319331 10.808C0.760415 12.3715 1.64028 13.7758 2.85474 14.8547C4.0692 15.9336 5.5674 16.6419 7.17193 16.8957V10.9584H5.01397V8.50192H7.17193V6.62871C7.12546 6.19144 7.17575 5.74929 7.31922 5.33362C7.46269 4.91794 7.69583 4.53891 8.00213 4.22339C8.30842 3.90787 8.68037 3.66358 9.09161 3.50784C9.50284 3.3521 9.9433 3.28872 10.3818 3.32219C11.0193 3.33057 11.6553 3.38597 12.2847 3.48794V5.58214H11.2137C11.0313 5.55829 10.8458 5.57576 10.671 5.63327C10.4962 5.69078 10.3366 5.78685 10.204 5.91438C10.0714 6.04191 9.96912 6.19763 9.9048 6.37001C9.84049 6.5424 9.81576 6.72704 9.83245 6.91028V8.50404H12.1901L11.813 10.9606H9.8282V16.901C11.8281 16.5847 13.6494 15.5648 14.9642 14.025C16.279 12.4852 17.0009 10.5267 17.0001 8.50192Z"
      fill="#895CF8"
    />
  </svg>
);

export const Music = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.79369 5.958V8.706C5.4476 8.61473 5.08544 8.60229 4.73391 8.6696C4.38237 8.73691 4.05042 8.88225 3.76252 9.09491C3.47463 9.30757 3.23812 9.58212 3.07045 9.89834C2.90277 10.2146 2.8082 10.5644 2.79369 10.922C2.77334 11.2303 2.81929 11.5395 2.92845 11.8285C3.0376 12.1176 3.20744 12.38 3.42651 12.5979C3.64558 12.8158 3.9088 12.9843 4.19845 13.092C4.48811 13.1996 4.79746 13.244 5.10569 13.222C5.4165 13.2456 5.72874 13.2015 6.02086 13.0928C6.31298 12.984 6.57803 12.8132 6.79773 12.5921C7.01742 12.371 7.18653 12.1048 7.2934 11.812C7.40026 11.5192 7.44232 11.2067 7.41669 10.896V0H10.1357C10.5987 2.876 12.0357 3.544 13.9627 3.852V6.608C12.628 6.49893 11.3404 6.06424 10.2127 5.342V10.787C10.2107 13.25 8.75069 16 5.12569 16C4.44575 15.9969 3.77327 15.8581 3.14764 15.5918C2.52201 15.3255 1.95583 14.937 1.48226 14.4491C1.00868 13.9612 0.637249 13.3836 0.389713 12.7503C0.142178 12.1171 0.0235203 11.4407 0.0406925 10.761C0.0620729 10.0531 0.233276 9.35787 0.543012 8.72102C0.852748 8.08418 1.29398 7.52023 1.8376 7.06637C2.38123 6.61252 3.01489 6.27907 3.69681 6.08802C4.37873 5.89697 5.0934 5.85265 5.79369 5.958Z"
      fill="#1E2BA2"
    />
  </svg>
);

export const Youtube = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8 2.8C15.6 1.5 15 0.6 13.6 0.4C11.4 8.9407e-08 8 0 8 0C8 0 4.6 8.9407e-08 2.4 0.4C1 0.6 0.3 1.5 0.2 2.8C0 4.1 0 6 0 6C0 6 0 7.9 0.2 9.2C0.4 10.5 1 11.4 2.4 11.6C4.6 12 8 12 8 12C8 12 11.4 12 13.6 11.6C15 11.3 15.6 10.5 15.8 9.2C16 7.9 16 6 16 6C16 6 16 4.1 15.8 2.8ZM6 9V3L11 6L6 9Z"
      fill="#80F1C6"
    />
  </svg>
);

export const SocialPostIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 6.5L11.5 1.5V4.5C8.344 4.5 4.5 6.354 4.5 11.5C6.059 8.977 9 8.5 11.5 8.5V11.5L16.5 6.5Z"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 11.5V15.5C16.5 16.052 16.052 16.5 15.5 16.5H2.5C1.948 16.5 1.5 16.052 1.5 15.5V4.5C1.5 3.948 1.948 3.5 2.5 3.5H4.5"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PhotosAndVideos = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.125 5.375H1.875V19.125H18.125V5.375Z"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 1.625H14.375"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 16.625L11.875 10.375L15.625 16.625H4.375Z"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 11C6.94036 11 7.5 10.4404 7.5 9.75C7.5 9.05964 6.94036 8.5 6.25 8.5C5.55964 8.5 5 9.05964 5 9.75C5 10.4404 5.55964 11 6.25 11Z"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Podcast = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.875 1.625H8.125C7.43464 1.625 6.875 2.18464 6.875 2.875V14.125C6.875 14.8154 7.43464 15.375 8.125 15.375H11.875C12.5654 15.375 13.125 14.8154 13.125 14.125V2.875C13.125 2.18464 12.5654 1.625 11.875 1.625Z"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 6.625H8.125"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 10.375H8.125"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 6.625H13.125"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 10.375H13.125"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 15.375V20.3775"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 15.375V20.3775"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.70617 4.70502C3.12562 5.2854 2.66511 5.97447 2.35091 6.73286C2.03672 7.49126 1.875 8.30412 1.875 9.12502C1.875 9.94592 2.03672 10.7588 2.35091 11.5172C2.66511 12.2756 3.12562 12.9646 3.70617 13.545"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2969 4.70874C17.469 5.88084 18.1274 7.47053 18.1274 9.12812C18.1274 10.7857 17.469 12.3754 16.2969 13.5475"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Location = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 7C13.5 11.5 8 16.5 8 16.5C8 16.5 2.5 11.5 2.5 7C2.5 5.54131 3.07946 4.14236 4.11091 3.11091C5.14236 2.07946 6.54131 1.5 8 1.5C9.45869 1.5 10.8576 2.07946 11.8891 3.11091C12.9205 4.14236 13.5 5.54131 13.5 7V7Z"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9.5C9.38071 9.5 10.5 8.38071 10.5 7C10.5 5.61929 9.38071 4.5 8 4.5C6.61929 4.5 5.5 5.61929 5.5 7C5.5 8.38071 6.61929 9.5 8 9.5Z"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const VoiceRecord = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4987 17.3334H0.832031V15.4059C0.832031 14.395 1.4362 13.4834 2.37036 13.0959C3.3162 12.7042 4.7237 12.3334 6.66536 12.3334C8.60703 12.3334 10.0145 12.7042 10.9604 13.0967C11.8945 13.4834 12.4987 14.395 12.4987 15.4059V17.3334Z"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66536 9.83342C8.50631 9.83342 9.9987 8.34103 9.9987 6.50008C9.9987 4.65913 8.50631 3.16675 6.66536 3.16675C4.82442 3.16675 3.33203 4.65913 3.33203 6.50008C3.33203 8.34103 4.82442 9.83342 6.66536 9.83342Z"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1649 5.2959C14.6832 5.8834 14.9974 6.65506 14.9974 7.50006C14.9974 8.34506 14.6824 9.11673 14.1641 9.70506"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.668 1.91089C18.2021 3.28422 19.1671 5.27922 19.1671 7.50006C19.1671 9.72089 18.2021 11.7167 16.668 13.0901"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Signature = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 16.6666L7.96583 11.1558"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3792 2.87915L4.16667 5.83332L2.5 16.6667L13.3333 15L16.2875 8.78748"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3441 11.1784C10.995 10.5276 10.995 9.47231 10.3441 8.82144C9.69323 8.17057 8.63796 8.17057 7.98709 8.82144C7.33623 9.47231 7.33623 10.5276 7.98709 11.1784C8.63796 11.8293 9.69323 11.8293 10.3441 11.1784Z"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.832031 19.1666H19.1654"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16406 1.66663L17.4974 9.99996"
      stroke="#435969"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FooterTwitterIcon = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.75"
      d="M18 2.375C17.325 2.7125 16.65 2.825 15.8625 2.9375C16.65 2.4875 17.2125 1.8125 17.4375 0.9125C16.7625 1.3625 15.975 1.5875 15.075 1.8125C14.4 1.1375 13.3875 0.6875 12.375 0.6875C10.4625 0.6875 8.775 2.375 8.775 4.4C8.775 4.7375 8.775 4.9625 8.8875 5.1875C5.85 5.075 3.0375 3.6125 1.2375 1.3625C0.9 1.925 0.7875 2.4875 0.7875 3.275C0.7875 4.5125 1.4625 5.6375 2.475 6.3125C1.9125 6.3125 1.35 6.0875 0.7875 5.8625C0.7875 7.6625 2.025 9.125 3.7125 9.4625C3.375 9.575 3.0375 9.575 2.7 9.575C2.475 9.575 2.25 9.575 2.025 9.4625C2.475 10.925 3.825 12.05 5.5125 12.05C4.275 13.0625 2.7 13.625 0.9 13.625C0.5625 13.625 0.3375 13.625 0 13.625C1.6875 14.6375 3.6 15.3125 5.625 15.3125C12.375 15.3125 16.0875 9.6875 16.0875 4.85C16.0875 4.7375 16.0875 4.5125 16.0875 4.4C16.875 3.8375 17.55 3.1625 18 2.375Z"
      fill="white"
    />
  </svg>
);

export const FooterFacebookIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.75"
      d="M17.0001 8.53634C17.0005 6.91186 16.5353 5.32129 15.6597 3.95298C14.7841 2.58466 13.5348 1.49591 12.0596 0.815632C10.5844 0.135354 8.94518 -0.107955 7.336 0.114513C5.72682 0.336982 4.2151 1.01591 2.97981 2.07091C1.74453 3.12591 0.837431 4.51279 0.365923 6.06734C-0.105585 7.62189 -0.121754 9.279 0.319331 10.8425C0.760415 12.4059 1.64028 13.8102 2.85474 14.8891C4.0692 15.968 5.5674 16.6763 7.17193 16.9302V10.9929H5.01397V8.53634H7.17193V6.66314C7.12546 6.22586 7.17575 5.78372 7.31922 5.36804C7.46269 4.95237 7.69583 4.57334 8.00213 4.25782C8.30842 3.94229 8.68037 3.69801 9.09161 3.54227C9.50284 3.38652 9.9433 3.32314 10.3818 3.35661C11.0193 3.36499 11.6553 3.42039 12.2847 3.52237V5.61657H11.2137C11.0313 5.59271 10.8458 5.61018 10.671 5.66769C10.4962 5.7252 10.3366 5.82127 10.204 5.9488C10.0714 6.07633 9.96912 6.23205 9.9048 6.40444C9.84049 6.57682 9.81576 6.76147 9.83245 6.9447V8.53846H12.1901L11.813 10.995H9.8282V16.9355C11.8281 16.6191 13.6494 15.5992 14.9642 14.0594C16.279 12.5197 17.0009 10.5611 17.0001 8.53634Z"
      fill="white"
    />
  </svg>
);

export const FooterInstaIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.75">
      <path
        d="M12.1456 4.85181C12.6758 4.85181 13.1056 4.42199 13.1056 3.89178C13.1056 3.36158 12.6758 2.93176 12.1456 2.93176C11.6154 2.93176 11.1855 3.36158 11.1855 3.89178C11.1855 4.42199 11.6154 4.85181 12.1456 4.85181Z"
        fill="white"
      />
      <path
        d="M8.00009 12.0002C5.79404 12.0002 4 10.2061 4 8.00009C4 5.79404 5.79404 4 8.00009 4C10.2061 4 12.0002 5.79404 12.0002 8.00009C12.0002 10.2061 10.2061 12.0002 8.00009 12.0002ZM8.00009 6.00005C6.89707 6.00005 6.00005 6.89707 6.00005 8.00009C6.00005 9.10312 6.89707 10.0001 8.00009 10.0001C9.10312 10.0001 10.0001 9.10312 10.0001 8.00009C10.0001 6.89707 9.10312 6.00005 8.00009 6.00005Z"
        fill="white"
      />
      <path
        d="M12.0003 16.0004H4.00009C1.94404 16.0004 0 14.0563 0 12.0003V4.00009C0 1.94404 1.94404 0 4.00009 0H12.0003C14.0563 0 16.0004 1.94404 16.0004 4.00009V12.0003C16.0004 14.0563 14.0563 16.0004 12.0003 16.0004ZM4.00009 2.00005C3.06507 2.00005 2.00005 3.06507 2.00005 4.00009V12.0003C2.00005 12.9533 3.04707 14.0003 4.00009 14.0003H12.0003C12.9353 14.0003 14.0003 12.9353 14.0003 12.0003V4.00009C14.0003 3.06507 12.9353 2.00005 12.0003 2.00005H4.00009Z"
        fill="white"
      />
    </g>
  </svg>
);

export const SportsDemo = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="15" height="15" viewBox="0 0 440 440"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0,440) scale(0.1,-0.1)"
      fill="#435969" stroke="none">
      <path d="M1987 4339 c-919 -97 -1654 -732 -1881 -1624 -178 -699 23 -1459 526
    -1990 340 -359 792 -588 1298 -656 134 -18 409 -16 554 5 449 64 861 263 1185
    572 812 773 894 2035 188 2909 -83 103 -259 274 -365 354 -426 323 -991 484
    -1505 430z m467 -119 c9 -7 16 -15 16 -19 0 -4 -24 -25 -52 -46 -29 -22 -114
    -100 -189 -172 l-136 -133 -138 -31 c-121 -27 -250 -63 -460 -127 -59 -18 -59
    -18 -115 5 -75 30 -235 69 -340 83 -47 7 -87 14 -90 16 -10 11 20 42 86 87
    238 166 534 285 824 331 146 24 152 24 372 22 151 -2 211 -6 222 -16z m394
    -135 c240 -52 256 -57 341 -105 55 -31 106 -69 132 -98 72 -81 216 -309 290
    -458 l72 -143 -51 -58 c-28 -32 -88 -95 -134 -140 l-83 -81 -110 44 c-157 64
    -437 159 -600 205 -86 24 -145 46 -153 57 -7 9 -43 68 -80 130 -37 62 -111
    172 -166 245 l-98 131 113 111 c110 106 243 215 264 215 6 0 124 -25 263 -55z
    m-1788 -416 c87 -12 219 -44 268 -65 33 -13 34 -16 28 -52 -30 -171 -37 -255
    -37 -415 l0 -179 -157 -156 c-164 -163 -271 -285 -380 -435 -46 -62 -69 -86
    -81 -83 -9 3 -70 21 -136 40 -213 62 -198 53 -213 127 -21 100 -20 410 1 549
    32 209 80 282 335 512 l193 175 52 -4 c29 -2 86 -8 127 -14z m2712 -178 c93
    -115 176 -244 245 -381 70 -137 80 -176 31 -115 -18 22 -79 88 -136 146 -98
    101 -106 112 -152 218 -43 100 -82 175 -143 281 -18 32 -15 30 37 -20 32 -30
    85 -88 118 -129z m-1027 -361 c172 -52 466 -156 590 -208 l70 -30 39 -104 c43
    -116 118 -406 137 -526 12 -77 11 -81 -11 -117 -97 -157 -344 -418 -533 -564
    l-57 -44 -198 53 c-236 62 -383 108 -398 123 -7 7 -69 227 -140 489 l-127 477
    26 38 c97 138 389 463 417 463 11 0 94 -23 185 -50z m-1110 -334 c121 -41 256
    -89 299 -108 l80 -33 128 -475 c70 -261 128 -482 128 -490 0 -24 -139 -178
    -299 -332 l-143 -138 -42 6 c-196 28 -495 120 -682 209 -65 30 -119 57 -121
    59 -11 10 -68 176 -103 297 -45 159 -93 395 -83 412 22 38 183 248 244 319
    102 118 339 348 358 348 9 0 115 -33 236 -74z m-1404 -203 c4 -65 15 -151 24
    -191 l15 -74 -39 -161 c-22 -89 -43 -184 -47 -212 -6 -45 -8 -47 -17 -25 -15
    37 -15 469 1 560 19 117 44 231 50 226 2 -3 8 -58 13 -123z m3995 -443 c-5
    -185 -26 -362 -39 -330 -3 8 -8 29 -12 45 -3 17 -17 71 -31 120 l-25 90 46
    172 c25 95 45 176 46 180 1 5 5 -12 11 -37 5 -25 7 -131 4 -240z m-377 -96
    c91 -26 171 -51 178 -58 13 -13 49 -154 63 -251 5 -38 10 -116 10 -173 0 -101
    -1 -104 -46 -204 -113 -247 -251 -443 -446 -635 l-73 -73 -95 15 c-153 23
    -300 62 -393 106 -26 12 -28 17 -23 49 25 141 36 268 36 440 l0 195 77 60
    c154 119 437 421 509 544 10 17 23 31 28 31 5 0 84 -21 175 -46z m-3259 -952
    c0 -12 15 -72 34 -134 l34 -113 -37 41 c-72 81 -171 231 -236 355 -36 70 -65
    131 -65 135 0 5 61 -52 135 -127 85 -86 135 -144 135 -157z m474 233 c191 -90
    616 -215 729 -215 21 0 35 -17 93 -111 65 -104 182 -267 267 -371 l37 -45 -46
    -64 c-58 -79 -197 -219 -282 -283 l-64 -48 -61 12 c-171 36 -422 137 -596 242
    -168 101 -241 161 -284 235 -55 95 -111 232 -139 335 l-23 88 135 135 c74 74
    135 135 136 135 1 0 44 -20 98 -45z m1978 -663 c53 -22 145 -52 205 -67 59
    -14 122 -29 138 -33 29 -8 28 -9 -70 -74 -370 -243 -822 -363 -1257 -333
    l-107 7 123 121 c67 67 143 154 170 194 l49 72 71 11 c118 19 351 79 449 115
    51 19 101 32 112 30 11 -2 63 -21 117 -43z"/>
    </g>
  </svg>
)

export const VideoShoutout = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="17" height="17" viewBox="0 0 440 440"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0,440) scale(0.1,-0.1)"
      fill="#00000" stroke="none">
      <path d="M710 4385 c-158 -33 -266 -91 -375 -200 -82 -82 -135 -169 -172 -280
    -24 -71 -27 -95 -27 -215 1 -119 4 -145 27 -215 36 -110 89 -196 173 -281 l71
    -73 -148 -3 c-163 -3 -180 -9 -227 -78 l-22 -33 2 -1013 c3 -1012 3 -1013 24
    -1041 12 -15 36 -37 55 -48 33 -19 55 -20 623 -23 l590 -3 -381 -382 c-235
    -234 -384 -392 -388 -407 -4 -18 0 -34 15 -53 46 -58 40 -62 504 395 l428 421
    421 -421 c456 -456 452 -452 496 -396 12 15 21 36 21 48 0 14 -125 146 -387
    408 l-387 387 590 3 c573 3 590 4 624 24 19 11 45 35 57 54 22 32 23 40 23
    291 0 180 3 259 11 259 6 0 223 -101 483 -225 260 -124 482 -225 492 -225 11
    0 30 7 42 16 l22 15 0 908 c0 867 -1 909 -18 924 -10 10 -30 17 -44 17 -14 0
    -239 -101 -498 -225 -260 -124 -477 -225 -481 -225 -5 0 -9 115 -9 256 0 245
    -1 258 -22 295 -36 65 -79 79 -241 79 l-136 0 72 73 c84 84 137 170 174 282
    24 71 27 95 27 215 0 125 -2 141 -31 223 -56 158 -146 278 -276 365 -88 58
    -230 108 -337 117 -91 9 -232 -14 -321 -49 -134 -55 -274 -175 -340 -291 -29
    -51 -34 -56 -43 -40 -126 215 -307 342 -533 375 -88 12 -143 11 -223 -5z m317
    -157 c229 -81 377 -292 377 -538 1 -121 -25 -209 -92 -311 -74 -114 -166 -184
    -302 -231 -87 -30 -257 -32 -340 -4 -194 65 -336 214 -385 402 -24 92 -20 228
    10 321 57 180 218 332 401 378 90 22 241 14 331 -17z m1222 17 c87 -21 194
    -79 257 -140 275 -269 223 -711 -106 -905 -166 -98 -369 -105 -538 -19 -101
    50 -164 107 -224 197 -216 332 -42 773 342 867 74 18 191 18 269 0z m-721
    -945 c26 -41 74 -99 106 -127 l58 -53 -215 0 -215 0 73 73 c40 39 87 98 105
    130 18 32 34 57 35 55 2 -2 26 -37 53 -78z m1277 -1300 l0 -975 -1324 -3
    c-1053 -2 -1326 0 -1333 10 -9 16 -11 1932 -1 1941 3 4 603 6 1332 5 l1326 -3
    0 -975z m1055 -1 c0 -720 -1 -760 -17 -755 -10 3 -216 100 -458 215 l-440 209
    0 333 0 333 445 212 c245 117 451 213 458 213 9 1 12 -157 12 -760z"/>
      <path d="M382 2754 c-29 -20 -30 -74 -1 -100 20 -18 41 -19 394 -19 353 0 374
    1 394 19 27 24 27 70 1 96 -19 19 -33 20 -393 20 -321 0 -376 -2 -395 -16z"/>
      <path d="M382 2544 c-29 -20 -30 -74 -1 -100 19 -17 38 -19 219 -19 181 0 200
    2 219 19 27 24 27 70 1 96 -19 19 -33 20 -218 20 -163 0 -201 -3 -220 -16z"/>
    </g>
  </svg>
)

export const NILLicensing = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="18" height="18" viewBox="0 0 440 440"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0,440) scale(0.1,-0.1)"
      fill="#000000" stroke="none">
      <path d="M3810 4379 c-14 -5 -431 -416 -927 -912 l-903 -902 -397 396 c-219
    219 -408 402 -420 408 -31 16 -64 14 -100 -6 -17 -10 -133 -122 -257 -250
    -244 -250 -247 -255 -212 -321 8 -15 311 -322 673 -684 688 -686 684 -682 745
    -659 37 14 2355 2329 2368 2365 22 61 9 79 -228 318 -253 255 -277 272 -342
    247z m185 -389 l140 -140 -1078 -1077 -1077 -1078 -573 573 -572 572 143 142
    142 143 408 -408 407 -407 45 0 45 0 910 910 c500 500 912 910 915 910 3 0 68
    -63 145 -140z"/>
      <path d="M60 4012 c-25 -12 -39 -28 -48 -56 -10 -30 -12 -385 -10 -1713 l3
    -1675 33 -29 32 -29 1696 0 1696 0 29 29 29 29 0 977 c0 864 -2 978 -15 999
    -35 53 -121 58 -166 10 l-24 -26 -3 -904 -2 -904 -1550 0 -1550 0 2 1547 3
    1548 1337 5 1336 5 26 24 c54 51 38 138 -29 167 -29 12 -244 14 -1412 14
    -1292 0 -1380 -1 -1413 -18z"/>
    </g>
  </svg>
)

export const BrandAmbassador = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="18" height="18" viewBox="0 0 440 440"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0,440) scale(0.1,-0.1)"
      fill="#000000" stroke="none">
      <path d="M3619 4375 c-312 -49 -607 -180 -874 -391 -86 -67 -310 -290 -446
    -444 -116 -131 -214 -253 -375 -468 -1 -2 -47 4 -101 14 -131 24 -371 23 -508
    -1 -139 -24 -317 -84 -445 -151 -130 -68 -293 -188 -379 -279 -122 -131 -142
    -176 -102 -226 19 -24 22 -24 173 -21 278 6 541 -46 773 -150 38 -18 72 -33
    73 -34 2 -2 -8 -32 -22 -68 -24 -60 -25 -68 -13 -98 8 -18 21 -39 30 -47 24
    -19 22 -25 -21 -54 -59 -39 -151 -122 -205 -185 -123 -145 -206 -402 -207
    -640 0 -75 2 -85 25 -107 22 -23 32 -25 110 -25 233 0 478 76 627 194 57 45
    175 177 206 229 20 33 32 34 62 3 35 -38 69 -41 135 -11 32 14 59 24 60 23 2
    -2 18 -39 38 -83 101 -233 150 -480 147 -745 -2 -175 -3 -166 22 -190 39 -36
    79 -27 151 35 251 212 425 512 498 855 36 168 32 451 -7 616 -5 18 9 33 78 85
    340 257 661 548 811 734 259 321 392 641 427 1025 18 186 -13 482 -55 547 -41
    62 -442 96 -686 58z m442 -156 c46 -6 91 -15 100 -20 45 -23 51 -432 10 -614
    -25 -106 -50 -185 -59 -185 -12 0 -746 739 -740 745 12 12 246 66 322 74 120
    12 272 12 367 0z m-46 -1059 c-85 -169 -249 -377 -432 -548 -447 -417 -937
    -768 -1357 -973 l-141 -69 -272 272 -271 271 75 151 c243 485 666 1056 1081
    1457 136 132 254 221 404 307 l93 52 427 -427 427 -426 -34 -67z m-2215 -236
    c8 -8 -15 -51 -78 -147 -49 -75 -122 -197 -163 -272 -41 -74 -78 -133 -84
    -130 -303 129 -477 175 -723 191 l-103 7 38 39 c51 53 211 159 307 203 98 46
    239 91 341 110 95 17 448 16 465 -1z m39 -1341 c6 -4 -68 -112 -113 -166 -91
    -106 -227 -187 -371 -221 -87 -21 -208 -36 -217 -27 -9 8 7 135 28 220 8 36
    36 107 60 158 38 77 58 104 128 171 45 43 107 96 137 116 l54 36 145 -142 c79
    -79 146 -144 149 -145z m1068 190 c4 -38 7 -126 6 -198 -2 -209 -53 -410 -151
    -598 -47 -89 -195 -297 -213 -297 -5 0 -9 28 -9 63 -1 200 -82 533 -177 720
    l-24 48 28 15 c139 75 298 169 398 235 66 43 123 79 127 79 3 0 10 -30 15 -67z"/>
      <path d="M2874 3439 c-111 -38 -210 -134 -256 -249 -17 -43 -22 -77 -22 -155
    -1 -92 1 -105 31 -167 45 -95 110 -161 202 -205 167 -81 361 -46 491 88 83 86
    126 223 108 351 -21 159 -147 303 -300 344 -76 20 -181 17 -254 -7z m253 -167
    c52 -27 116 -100 131 -150 37 -124 -17 -254 -133 -315 -30 -16 -58 -22 -116
    -22 -67 0 -81 3 -124 30 -131 81 -168 228 -91 359 66 114 216 157 333 98z"/>
      <path d="M935 2160 c-11 -4 -165 -154 -342 -332 -306 -306 -323 -326 -323
    -360 0 -44 36 -78 83 -78 28 0 70 39 350 318 175 174 325 329 333 344 33 64
    -35 137 -101 108z"/>
      <path d="M368 1093 c-165 -164 -314 -316 -329 -337 -34 -46 -36 -74 -8 -110
    16 -21 29 -26 62 -26 41 0 44 3 369 328 289 288 328 331 328 358 0 49 -34 84
    -82 84 -39 0 -47 -7 -340 -297z"/>
      <path d="M1727 713 c-185 -186 -330 -339 -334 -352 -14 -55 53 -113 106 -91
    15 6 172 156 349 333 301 302 322 325 322 358 0 46 -34 79 -80 79 -32 0 -60
    -26 -363 -327z"/>
      <path d="M388 533 c-364 -364 -366 -367 -310 -423 60 -59 52 -64 419 303 313
    312 333 335 333 368 0 46 -33 79 -80 79 -32 0 -60 -26 -362 -327z"/>
      <path d="M1275 771 c-16 -10 -171 -160 -343 -333 -274 -275 -312 -317 -312
    -345 0 -46 34 -83 77 -83 33 0 57 21 349 306 173 169 323 321 334 339 26 42
    25 68 -2 99 -32 35 -66 41 -103 17z"/>
    </g>
  </svg>
)

export const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 2L2 10" stroke="#042237" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M10 10L2 2" stroke="#042237" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
)

export const QuestionMarkIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18Z" fill="#D3DCE1" />
    <path d="M8.448 11.654H9.78C9.78 11.41 9.788 11.216 9.804 11.072C9.82 10.924 9.882 10.758 9.99 10.574C10.102 10.386 10.258 10.214 10.458 10.058C10.662 9.898 10.862 9.714 11.058 9.506C11.31 9.242 11.516 8.948 11.676 8.624C11.84 8.3 11.922 7.946 11.922 7.562C11.922 7.278 11.874 7.004 11.778 6.74C11.686 6.472 11.548 6.234 11.364 6.026C11.112 5.73 10.796 5.516 10.416 5.384C10.04 5.252 9.64 5.186 9.216 5.186C8.92 5.186 8.624 5.232 8.328 5.324C8.032 5.412 7.768 5.548 7.536 5.732C7.264 5.936 7.048 6.204 6.888 6.536C6.728 6.864 6.632 7.214 6.6 7.586H7.92C7.916 7.406 7.942 7.24 7.998 7.088C8.058 6.932 8.15 6.802 8.274 6.698C8.39 6.59 8.534 6.51 8.706 6.458C8.878 6.406 9.062 6.38 9.258 6.38C9.47 6.38 9.674 6.412 9.87 6.476C10.066 6.54 10.228 6.654 10.356 6.818C10.512 7.01 10.59 7.266 10.59 7.586C10.59 7.794 10.562 7.988 10.506 8.168C10.454 8.348 10.348 8.526 10.188 8.702C9.996 8.906 9.812 9.074 9.636 9.206C9.464 9.334 9.304 9.466 9.156 9.602C9.012 9.734 8.886 9.908 8.778 10.124C8.65 10.352 8.562 10.592 8.514 10.844C8.47 11.096 8.448 11.366 8.448 11.654ZM8.454 14H9.78V12.548H8.454V14Z" fill="#435969" />
  </svg>
)