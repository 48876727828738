import React, {useState, useRef} from 'react';

const SearchLocationInput = (props) => {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);
  let autoComplete;

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    // updateQuery(query);
    setQuery(query);
    props.onChange(query, addressObject);
    // console.log(query, query);
  }

  const handleScriptLoad = (value, id) => {
    // console.log(value, id)
    const input = document.getElementById(id);
    autoComplete = new window.google.maps.places.Autocomplete(input, {
      types: ['(cities)'],
      strictbounds: true,
    });
    autoComplete.setFields(['address_components', 'formatted_address']);
    autoComplete.addListener('place_changed', () => handlePlaceSelect());
  };

  return (
    <div className="search-location-input">
      <input
        ref={autoCompleteRef}
        onChange={(event) => {
          setQuery(event.target.value);
          handleScriptLoad(event.target.value, props.id);
        }}
        placeholder={props.placeholder}
        value={query}
        name={props.name}
        id={props.id}
        autoComplete="off"
        className="pro-input input-lg"
      />
    </div>
  );
};

export default SearchLocationInput;
