import styled from 'styled-components';
import {
  Card,
  Tag,
  Button,
  Divider,
  Input,
  DatePicker,
  Checkbox,
  Radio,
  Select,
} from 'antd';
import img from '../../../assets/images/infoImg.png';

import {SearchOutlined} from '@ant-design/icons';
const {TextArea} = Input;

export const AthleteCard = styled(Card)`
  width: 100%;
  // margin-top: 24px;
  margin-bottom: 24px;
  background: #ffffff;
  box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
  position: relative;
  z-index: 1;
  padding: 20px;
  border-radius: 20px; /*shop list in purchaser mobile issue */
  text-transform: none;
  @media (min-width: 768px) {
    padding: 30px 30px 18px;
    border-radius: 20px;
  }
  .ant-card-body {
    padding: 0px;
  }

  @media screen and (max-width: 767.98px) {
    box-shadow: none;
    border: solid 1px #d3dce1;
  }
`;

export const MessageCard = styled(Card)`
  width: 100%;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  padding: 25px 0px 25px 0px;

  .ant-card-body {
    padding: 0px;
  }

  @media screen and (max-width: 767.98px) {
    box-shadow: none;
    border-radius: 0px;
    padding: none;
    border: none;
  }
`;

export const CheckoutCard = styled(Card)`
  width: 100%;
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #d3dce1;
  /* box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07); */
  border-radius: 8px;
  padding: 25px 30px 25px 30px;

  .ant-card-body {
    padding: 0px;
  }
`;

export const CheckoutInfoCard = styled(Card)`
  // width: 100%;
  // margin-top: 24px;
  // background: #d3dce1;
  // border-radius: 8px;
  // padding: 20px;
  // margin-bottom: 20px;
  border: none;
  // @media (min-width: 768px) {
  //   border-radius: 20px;
  //   padding: 30px;
  //   margin-bottom: 50px;
  // }
  .ant-card-body {
    padding: 0px;
  }
`;

export const PriceSummaryCard = styled(Card)`
  margin-top: 24px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
  border: none;
  @media (max-width: 767.98px) {
    border: solid 1px #d3dce1;
    border-radius: 8px;
    margin-top: 0;
    margin-bottom: 30px;
  }
`;

export const AthleteProfileImageContainer = styled.div`
  display: flex;
  flex-basis: 20%;
  max-width: 144px;
  // height: 144px;
  // flex: 0 0 144px;
  // flex-shrink: 1;
  border-radius: 14px;
  @media screen and (max-width: 991.98px) {
    display: flex;
    max-width: 100%;
    align-items: center;
    flex: 0 0 66px;
    .video-thumbnail-md .purchases-list-imag.sm {
      flex: 0 0 66px;
      height: 66px;
      border-radius: 14px;
      width: 66px;
    }
  }
`;

export const AthleteProductDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 2;
  padding: 0px 30px;

  @media screen and (max-width: 992px) {
    padding: 0px;
    margin-top: 10px;
  }
`;

export const AthleteProductPrice = styled.div`
  display: flex;
  // justify-content: ${({content}) => content || 'center'};
  flex-direction: column;
  align-items: top;
  max-width: 100px;
  @media screen and (max-width: 767.98px) {
    display: none;
  }
`;

export const AthleteProductMobilePrice = styled.div`
  display: flex;
  justify-content: start;
  margin: 14px 0px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const TagContainer = styled.div`
  @media (max-width: 767.98px) {
    overflow-y: scroll;
    white-space: nowrap;
  }
`;

export const CustomAthleteTag = styled(Tag)`
  background-color: #f2f5f7;
  font-family: Manrope;
  color: #7741fe;
  font-weight: 600;
  font-size: 13px;
  line-height: 17.76px;
  padding: 7px;
  border-radius: 6px;
  border: none !important;
  margin-bottom: 5px;
`;

export const OfferButton = styled(Button)`
  background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
  border-radius: 8px;
  max-width: 354px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  position: relative;
  &:hover {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }

  &:active {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }

  &:focus {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const OfferDropDownButton = styled(Select)`
  max-width: 325px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  color: #ffffff;
  background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
  border-radius: 8px;
  .ant-select-selector {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
    box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
    border-radius: 8px;
    max-width: 325px;
    width: 100%;
    height: 60px !important;
    display: flex;

    .ant-select-selection-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-select-arrow {
    color: #ffffff;
  }

  /* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 60px !important;
  } */
  /* background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
  border-radius: 8px;
  max-width: 325px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  &:hover {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }

  &:active {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }

  &:focus {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }
  @media screen and (max-width: 767.98px) {
    display: none;
  } */
`;

export const OfferButtonMobile = styled(Button)`
  background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
  border-radius: 8px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  &:hover {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }

  &:active {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }

  &:focus {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }
  @media screen and (min-width: 992px) {
    display: none;
  }
`;
export const DeskTopLayoutView = styled.div`
  @media screen and (max-width: 767.98px) {
    display: none !important;
  }
`;

export const MobileLayoutViewView = styled.div`
  @media screen and (min-width: 768px) {
    display: none !important;
  }
`;

export const CheckoutButton = styled.button`
  background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
  border-radius: 8px;
  width: 100%;
  height: 60px;
  display: flex;
  color: FFFFFF;
  align-items: center;
  white-space: normal !important;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
  @media screen and (max-width: 992px) {
    width: -webkit-fill-available;
    width: -moz-available;
    margin-bottom: 0px;
  }

  &:hover {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }

  &:active {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }

  &:focus {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }
`;

export const CustomDivider = styled(Divider)`
  width: 100%;
  border-top: 1px solid #d3dce1;
  min-width: none !important;
`;

export const List = styled.ul`
  padding: 0px 0px 0px 13px;
  padding-left: 13px;
`;

export const AthleteCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

export const ModalOfferButton = styled(Button)`
  background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
  border-radius: 8px;
  max-width: 255px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  &:hover {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }

  &:active {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }

  &:focus {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
  }
  @media screen and (max-width: 500px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 576px) {
    margin-top: 8px;
    margin-bottom: 20px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 145px;
  background: ${({theme}) => theme.gray_lighter} !important;
  justify-content: center;
  align-items: center;
  height: 22px;
`;

export const CustomInput = styled(Input)`
  border: 1px solid #d3dce1;
  height: 64px;
  color: #042237;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  border-radius: 8px;
`;

export const CustomDatePicker = styled(DatePicker)`
  border: 1px solid #d3dce1;
  height: 64px;
  width: 100%;
  color: #042237;
  font-weight: 500;
  size: 18px;
  line-height: 24px;
  border-radius: 8px;

  .ant-picker-suffix {
    display: none;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 16px;
  }
`;

export const SaveCardLaterCheckbox = styled(Checkbox)`
  background-color: #ffffff;
  display: flex;
  width: auto;
  height: auto;
`;

export const AthleteMobileContainer = styled.div`
  display: none;

  @media screen and (max-width: 991.98px) {
    margin-top: 30px;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }
`;

export const AthleteDesktopContainer = styled.div`
  display: flex;
  position: relative;

  @media screen and (max-width: 991.98px) {
    margin-top: 30px;
    justify-content: center;
    display: none;
  }
`;

export const BackgroundImage = styled.img`
  z-index: 10;
  bottom: 20px;
  left: 20px;
  max-width: 310px;
  /* min-width: 325px; */
  max-height: 310px;
  width: 100%;
  position: absolute;
  z-index: 5;
  @media screen and (max-width: 1400px) {
    bottom: 14px;
    left: 26px;
  }
  @media screen and (max-width: 1300px) {
    max-width: 198px;
    bottom: 22px;
    left: 26px;
  }
  @media screen and (max-width: 1200px) {
    bottom: 29px;
    left: 35px;
  }
  @media screen and (max-width: 991.98px) {
    max-width: 189px;
    bottom: 18px;
    left: 26px;
  }
`;

export const AboutHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 767.98px) {
    justify-content: flex-start;
  }
`;

export const CustomSearchOutlined = styled(SearchOutlined)`
  color: #435969;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  left: 20px;
`;

export const CustomAthleteSearchOutlined = styled(SearchOutlined)`
  color: #435969;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  left: 20px;
  top: 18px;
`;

export const CheckoutTextArea = styled(TextArea)`
  border-radius: 8px;
  &::placeholder {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #435969;

    @media screen and (max-width: 767.98px) {
      font-size: 17px;
      line-height: 23px;
    }
  }
`;

export const CheckoutInfoAlert = styled.div`
  position: relative;
  padding: 18px 40px;
  background: url(${img}) left top no-repeat;
  background-size: cover;
  min-height: 63px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  @media screen and (max-width: 992px) {
    min-height: 66px;
    height: 100%;
    padding: 18px 0px;
  }
`;

export const ResendEmailButton = styled.div`
  background-color: #042237;
  display: block;
  // min-width: 116px;
  padding: 7px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
  border-radius: 7px;
  color: #ffffff;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    font-size: 13px;
    // min-width: 96px;
    margin-left: 10px;
  }
`;

export const AboutSectionButton = styled.div`
  @media screen and (min-width: 768px) and (max-width: 991.98px) {
    padding: 20px;
  }
  @media screen and (max-width: 766px) {
    background: #ffffff;
    box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
    border: 1px solid #f0f0f0;
    border-radius: 20px;
    padding: 20px 15px;
    position: relative;
  }
`;

export const SemiCircle = styled.div`
  background: linear-gradient(196.93deg, #dbe6ea 10.64%, #ffffff 87.03%);
  opacity: 0.3;
  width: 1000px;
  height: 1000px;
  transform: rotate(-165deg);
  border-radius: 50%;
  position: absolute;
  bottom: -98%;
  right: -41%;

  @media screen and (max-width: 700px) {
    top: 345px;
    left: 90px;
  }
`;

export const SemiCircleCheckout = styled.div`
  background: linear-gradient(196.93deg, #dbe6ea 10.64%, #ffffff 87.03%);
  opacity: 0.3;
  width: 1000px;
  height: 1000px;
  transform: rotate(-165deg);
  border-radius: 50%;
  position: absolute;
  top: 1036px;
  left: 310px;

  @media screen and (max-width: 700px) {
    top: 345px;
    left: 90px;
  }
`;

export const SemiCircleHome = styled.div`
  background: linear-gradient(196.93deg, #dbe6ea 10.64%, #ffffff 87.03%);
  opacity: 0.3;
  width: 960px;
  height: 960px;
  transform: rotate(-165deg);
  border-radius: 50%;
  position: absolute;
  top: 1176px;
  left: 920px;

  @media screen and (max-width: 700px) {
    top: 1452px;
    left: 220px;
  }
`;

export const NewlyJoinedCards = styled.div`
  display: flex;
  // width: 100%;
  flex-direction: row;
  padding-top: 0;
  overflow-x: auto;
  flex-direction: row;

  // overflow-x: scroll;
  // position: relative;
  // margin-bottom: 20px;
`;

export const FilterCheckbox = styled(Checkbox)`
  background-color: #ffffff;
  display: flex;
  width: auto;
  height: auto;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 180px;
  font-weight: 500;
  padding: 15px 20px;
  text-tranform: capitalize;
  .ant-checkbox-wrapper {
    color: #435969;

    &.ant-checkbox-wrapper-checked {
      span {
        color: #042237;
      }
    }
  }
`;

export const FiltersGrid = styled.div`
  display: grid;
  min-width: 180px;
  grid-template-columns: 10fr 10fr 10fr;
  padding: 15px;
`;

export const FilterRadioSort = styled(Radio)`
  background-color: #ffffff;
  display: flex;
  width: auto;
  height: auto;
  text-transform: capitalize;
`;

export const MobileDivContainer = styled.div`
  margin: 20px 0px;
  padding: 0px 32px 20px 20px;
  box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
  position: relative;
  background-color: #ffffff;
`;

export const MobileDetailsContainer = styled.div`
  @media screen and (max-width: 767.98px) {
    padding: 0px 20px 0px 20px;
    position: relative;
    background-color: #ffffff;
  }
`;

export const MobileDetailsContainerSecond = styled.div`
  /* padding: 0px 20px 0px 20px; */
  position: relative;
  background-color: #ffffff;
  border-radius: 20px;
  @media screen and (max-width: 767.98px) {
    position: relative;
    background-color: #ffffff;
    border-radius: 20px;
  }
`;

export const CustomDropDownOption = styled.div`
  background: #042237;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
