import React, {useState, useEffect, useCallback} from 'react';
import {headerSection} from '../../utils/staticStrings';
import {useHistory} from 'react-router-dom';
import {Layout, Menu, Collapse} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {getStripeStatus} from '../../store/athleteflow/action';
import {getNewSidebarCount} from '../../store/users/actions';
import {
  Home,
  MyShop,
  Order,
  Deals,
  Messages,
  Earnings,
  Athletes,
  Purchaser,
  Campaign,
  CampainPurchase,
  MyProfileIcon,
} from './SidebarIcons';
import {CustomMenu, EligibleCam} from './styled';
import {useInterval} from '../../hooks/intervalHook';
import useTabActive from '../../hooks/useTabActive';

const {Panel} = Collapse;
const {Item} = Menu;

const Sidebar = (props) => {
  const {Sider} = Layout;
  const [current, setCurrent] = useState('fan');
  const [userId, setUserId] = useState('fan');
  const [registered, setRegistered] = useState(false);
  //This status use for membership cancelled flow
  const {page, newCount} = useSelector((state) => state.UsersReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabActive] = useTabActive();

  const {stripeStatus, campaignsCount, membeShipExpired, disableMenu} =
    useSelector((state) => state.AthleteModuleReducer);
  //   const {user} = useSelector((state) => state.AthleteModuleReducer);

  useEffect(() => {
    const userRole = localStorage.getItem('userRole');
    setUserId(localStorage.getItem('id'));
    setCurrent(userRole);

    return () => {

    };
  }, []);

  const callbackCount = useCallback(async () => {
    try {
      const userRole = localStorage.getItem('userRole');
      let type = userRole === 'athlete' ? 'athlete' : 'purchaser';
      dispatch(getNewSidebarCount(type));
    } catch (err) {
      // dispatch(hideModal());
      console.log(err, 'Fetch api error');
    }
  }, [dispatch]);

  useInterval(callbackCount, tabActive ? 40000 : null);

  useEffect(() => {
    callbackCount();
  }, [callbackCount]);

  useEffect(() => {
    if (current === 'athlete') {
      dispatch(getStripeStatus());
    }
  }, [dispatch, current]);

  useEffect(() => {
    if (Object.keys(stripeStatus).length > 0) {
      if (
        stripeStatus?.subscription_valid === true &&
        stripeStatus?.stripe_account_valid === true
      ) {
        setRegistered(true);
      } else {
        setRegistered(false);
      }
    }
  }, [stripeStatus]);

  const goToHome = () => {
    history.push('/');
  };

  function callback(key) {
    // console.log(key);
  }

  return (
    <Sider
      width={props.width}
      collapsedWidth="0"
      trigger={null}
      className={`sidebar-bg ${props.className}`}>
      <div className="logo-sidebar">
        <div onClick={goToHome} className="cursor-pointer">
          <img alt="headerlogo" src={headerSection.headerLogoBlack} />
        </div>
      </div>
      <CustomMenu
        theme="light"
        mode="inline"
        selectedKeys={[current]}
        className={
          disableMenu && !membeShipExpired && current === 'athlete'
            ? 'disableMenu'
            : ''
        }>
        {current === 'athlete' && (
          <Item
            active="true"
            key="dashboard"
            icon={<Home />}
            className={`${page === `dashboard` ? 'active' : ''} ${
              membeShipExpired ? 'menu-disable' : ''
            }`}>
            <a href={`/${current}/dashboard`}>Home</a>
          </Item>
        )}
        {(current === 'brand' || current === 'fan') && (
          <Item
            className={`${page === `dashboard` ? 'active' : ''}`}
            key="dashboard-fb"
            icon={<Purchaser />}>
            <a href={`/purchaser/dashboard`}>
              {current === 'brand' ? 'MY PURCHASES' : 'PURCHASES'}
              {newCount?.order_count > 0 && (
                <span className="badge badge-purple ms-2">
                  {newCount?.order_count}
                </span>
              )}
            </a>
          </Item>
        )}

        {current === 'athlete' && (
          <>
            <Collapse
              accordion
              activeKey="1"
              onChange={callback}
              className="athlete-sidebar">
              <Panel
                showArrow={false}
                header={
                  <div className="d-flex align-items-center">
                    <img
                      alt="headerlogo"
                      className="header-logo"
                      src={headerSection.headerShopLogo}
                    />
                  </div>
                }
                key="1">
                <Item
                  key="view-profile"
                  className={`${page === `athletes` ? 'active' : ''} ${
                    membeShipExpired ? 'menu-disable' : ''
                  }`}
                  icon={<MyProfileIcon />}>
                  <a href={`/${current}/view-profile/${userId}`}>
                    VIEW PUBLIC PROFILE
                  </a>
                </Item>
                <Item
                  key="my-shop"
                  className={`${page === `my-shop` ? 'active' : ''}   ${
                    disableMenu ? 'menu-enable' : ''
                  }`}
                  icon={<MyShop />}>
                  <a href={`/${current}/my-shop`}>My LISTINGS</a>
                </Item>
                <Item
                  key="orders"
                  icon={<Order />}
                  className={`${page === `orders` ? 'active' : ''}`}>
                  <a href={`/${current}/orders`}>
                    MANAGE ORDERS
                    {newCount?.order_count > 0 && (
                      <span className="badge badge-purple ms-2">
                        {newCount?.order_count}
                      </span>
                    )}
                  </a>
                </Item>
              </Panel>
            </Collapse>
            <Collapse
              activeKey="2"
              accordion
              onChange={callback}
              className="athlete-sidebar">
              <Panel
                showArrow={false}
                header={
                  <div className="d-flex align-items-center">
                    <img
                      alt="headerlogo"
                      className="header-logo"
                      src={headerSection.headerDealLogo}
                    />
                  </div>
                }
                key="2">
                <Item
                  key="campaigns"
                  className={`${page === `campaigns` ? 'active' : ''}  ${
                    disableMenu ? 'menu-enable' : ''
                  }`}
                  icon={<Campaign />}>
                  <a href={`/${current}/campaigns`}>DISCOVER CAMPAIGNS</a>
                  {!registered && page === 'campaigns' && (
                    <EligibleCam>
                      {campaignsCount} Eligible Campaigns
                    </EligibleCam>
                  )}
                </Item>
                <Item
                  key="/deals"
                  className={`${page === `deals` ? 'active' : ''}`}
                  icon={<Deals />}>
                  <a href={`/${current}/deals`}>
                    MANAGE DEALS
                    {newCount?.deal_count > 0 && (
                      <span className="badge badge-purple ms-2">
                        {newCount?.deal_count}
                      </span>
                    )}
                  </a>
                </Item>
              </Panel>
            </Collapse>
          </>
        )}

        {(current === 'brand' || current === 'fan') && (
          <Item
            key="/deals"
            className={`${page === `deals` ? 'active' : ''}`}
            icon={<Deals />}>
            <a href={`/purchaser/deals`}>
              {current === 'brand' ? 'MANAGE DEALS' : 'DEALS'}
              {newCount?.deal_count > 0 && (
                <span className="badge badge-purple ms-2">
                  {newCount?.deal_count}
                </span>
              )}
            </a>
          </Item>
        )}

        {current === 'brand' && (
          <Item
            key="campaigns"
            className={`${page === `campaigns` ? 'active' : ''}`}
            icon={<CampainPurchase />}>
            <a href={`/purchaser/campaigns`}>CAMPAIGNS</a>
          </Item>
        )}

        <Item
          key="messages"
          className={`${page === `messages` ? 'active' : ''}`}
          icon={<Messages />}>
          {current === 'athlete' && (
            <a href={`/athlete/messages`}>
              Messages{' '}
              {newCount?.new_message_flag && (
                <span className="badge-purple-small ms-2"></span>
              )}
            </a>
          )}
          {(current === 'brand' || current === 'fan') && (
            <a href={`/purchaser/messages`}>
              Messages
              {newCount?.new_message_flag && (
                <span className="badge-purple-small ms-2"></span>
              )}
            </a>
          )}
        </Item>

        {(current === 'brand' || current === 'fan') && (
          <Item
            key="athletes"
            className={`${page === `athletes` ? 'active' : ''}`}
            icon={<Athletes />}>
            <a href={`/purchaser/athletes`}>ATHLETES</a>
          </Item>
        )}
        {current === 'athlete' && (
          <Item
            key="earnings"
            className={`${page === `earnings` ? 'active' : ''}`}
            icon={<Earnings />}>
            <a href={`/athlete/earnings`}>Earnings</a>
          </Item>
        )}
      </CustomMenu>
    </Sider>
  );
};

export default Sidebar;
