import React, {useEffect, useState, useRef} from 'react';
import {Row, Col, Tabs} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import './pages.scss';
import moment from 'moment';
import useDeviceSize from '../../../hooks/useDeviceSize';
// import { backgroundStyle } from '../../../utils/staticStrings';
import {useDispatch, useSelector} from 'react-redux';
import {Show} from '../../../components/ShowAndHide';
import Text from '../../../components/Text/Text';
import AthleteInfo from '../components/AthleteInfo';
import AthleteInfoCard from '../components/AthleteInfoCard';
import AboutSection from '../../../common/AthleteProfile/AboutSection';
import {useParams} from 'react-router-dom';
import {fetchAthleteData} from '../../../store/users/actions';
import {CustomCampaign} from '../components/features/Athletes/styled';
import InviteContainer from '../components/features/Athletes/InviteContainer';
import {setHeader} from '../../../store/athleteflow/action';
import AppLayout from '../../../common/AppLayout';
import {errorToastMessage, toastMessage} from '../../../helpers/toastMessage';
import {
  createCustomOffer,
  handleCreateOfferStatus,
  handleError,
  handleInviteCampaignStatus,
} from '../../../store/brandflow/action';
import {handleSidebarTab} from '../../../store/users/actions';
import useApi from '../../../hooks/useApi';
import CampaignDetails from '../components/features/Campaigns/CampaignDetails';
import {CreateCampaign} from '../components/features/Campaigns/style';
import Loader from 'react-loader-spinner';
import {useHistory} from 'react-router-dom';
import {CardTheme} from '../../Athlete/components/style';
import {backgroundStyle} from '../../../utils/staticStrings';

const {TabPane} = Tabs;

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const size = useDeviceSize();
  const formRef = useRef();
  const {profileId} = useParams();
  const [states] = useApi('/athlete/states');
  const [athleteState, setAthleteState] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [campaignData, setCampaignData] = useState({});
  const [isCampaignInviteVisible, setIsCampaignInviteVisible] = useState(false);
  const [isCustomOfferVisible, setIsCustomOfferVisible] = useState(false);
  const [isSubmitOfferVisible, setIsSubmitOfferVisible] = useState(false);
  const [taggedToCampaign, setTaggedToCampaign] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const {athleteDetails, isLoading, purchaserDetails, atheleteNotfound} =
    useSelector((state) => state.UsersReducer);
  const {inviteCampaignStatus, message, createOfferStatus, error} = useSelector(
    (state) => state.BrandModuleReducer,
  );
  const {stripeStatus} = useSelector((state) => state.AthleteModuleReducer);
  let userRole = localStorage.getItem('userRole');

  useEffect(() => {
    dispatch(fetchAthleteData(profileId));
    window.scrollTo(0, 0);
  }, [profileId, dispatch]);

  useEffect(() => {
    if (athleteDetails && athleteDetails?.address?.state) {
      const state = athleteDetails?.address?.state;
      setAthleteState(state);
    }
  }, [states, athleteDetails]);

  useEffect(() => {
    const access_token = localStorage.getItem('athletUp-token');
    if (access_token !== undefined && access_token !== null) {
      // For logged in user
      dispatch(setHeader(false));
      setShowSidebar(true);
    } else {
      dispatch(setHeader(true));
      setShowSidebar(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (history.location.pathname === `/athlete/view-profile/${profileId}`) {
      dispatch(handleSidebarTab('athletes'));
    }
  }, [history.location.pathname, dispatch, profileId]);

  useEffect(() => {
    if (inviteCampaignStatus === true) {
      toastMessage('Athlete invited successfully');
      dispatch(handleInviteCampaignStatus(false));
    }
  }, [inviteCampaignStatus, dispatch]);

  useEffect(() => {
    if (createOfferStatus === true) {
      toastMessage('Offer created successfully');
      dispatch(handleCreateOfferStatus(false));
    }
  }, [createOfferStatus, dispatch]);

  useEffect(() => {
    if (!createOfferStatus && error?.message?.success === false) {
      errorToastMessage(error?.message?.message);
      dispatch(handleError(''));
    }
  }, [error, createOfferStatus, dispatch]);

  useEffect(() => {
    if (message?.text?.success === false) {
      errorToastMessage(message.text.message);
    }
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  const openCampaignInvite = () => {
    setIsCampaignInviteVisible(true);
  };

  const closeCampaignInvite = () => {
    setIsCampaignInviteVisible(false);
  };

  const openCustomOffer = () => {
    setIsCustomOfferVisible(true);
  };

  const closeCustomOffer = () => {
    setIsCustomOfferVisible(false);
  };

  const openSubmitOffer = (campaignData) => {
    setIsSubmitOfferVisible(true);
    setShowPayment(false);
    if (Object.keys(campaignData).length > 0) {
      campaignData?.deliverables.forEach((deliverableData) => {
        if (deliverableData?.fulfillment_date) {
          deliverableData.fulfillment_date = moment(
            deliverableData.fulfillment_date,
          ).format('MM-DD-YYYY');
        }
        if (deliverableData?.fulfillment_days === null) {
          delete deliverableData.fulfillment_days;
        }
      });
    }
    setCampaignData(campaignData);
  };

  const closeSubmitOffer = () => {
    formRef.current.resetForm();
    setIsSubmitOfferVisible(false);
  };

  const showSubmitOfferModal = (campaignData = {}) => {
    closeCustomOffer();
    openSubmitOffer(campaignData);
  };

  const submitOffer = (values, campaignId = '') => {
    delete values?.image_url;
    let payload = {};
    if (taggedToCampaign) {
      values.deliverables.forEach((deliverable) => {
        delete deliverable.id;
      });
      payload = {
        ...values,
        expiration_unit: 'day',
        athlete_user_id: athleteDetails?.id,
        campaign_id: campaignId,
      };
    } else {
      payload = {
        ...values,
        expiration_unit: 'day',
        athlete_user_id: athleteDetails?.id,
      };
    }
    dispatch(createCustomOffer(payload));
    closeSubmitOffer();
  };

  const callback = (key) => {
    console.log(key);
  };

  const mainContent = () => {
    return (
      <>
        {/* Modal for campaign invite */}
        <CustomCampaign
          style={{top: 100}}
          width={1040}
          zIndex={2500}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center text-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
              }}>
              Select a Campaign to invite {athleteDetails?.name}
            </div>
          }
          visible={isCampaignInviteVisible}
          onCancel={closeCampaignInvite}
          footer={null}>
          <InviteContainer
            type="invite"
            athleteId={athleteDetails.id}
            closeCampaignInvite={closeCampaignInvite}
          />
        </CustomCampaign>

        {/* Modal for custom offer  */}
        <CustomCampaign
          style={{top: 100}}
          width={1040}
          zIndex={2500}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center text-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
              }}>
              Custom deal offer for {athleteDetails?.name}
            </div>
          }
          visible={isCustomOfferVisible}
          onCancel={closeCustomOffer}
          footer={null}>
          <InviteContainer
            type="offer"
            changeTaggedStatus={(taggedStatus) => {
              setTaggedToCampaign(taggedStatus);
            }}
            showSubmitOfferModal={showSubmitOfferModal}
          />
        </CustomCampaign>

        {/* Modal to submit custom offer  */}
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center text-center my-3"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
              }}>
              {purchaserDetails.role === 'brand' &&
                `Create custom deal offer ${
                  taggedToCampaign
                    ? `(campaign #${campaignData?.id})`
                    : '(no campaign tagged)'
                }`}
              {purchaserDetails.role === 'fan' && 'Create custom deal offer'}
            </div>
          }
          visible={isSubmitOfferVisible}
          onCancel={closeSubmitOffer}
          footer={null}>
          <CampaignDetails
            type="offer"
            campaignForm1={campaignData}
            taggedToCampaign={taggedToCampaign}
            athleteDetails={athleteDetails}
            submitOffer={submitOffer}
            showPayment={showPayment}
            setShowPayment={setShowPayment}
            formRef={formRef}
          />
        </CreateCampaign>
        {isLoading ? (
          <div className="d-flex mh-100-vh align-items-center justify-content-center">
            <Loader type="Bars" color="#00BFFF" height={50} width={100} />
          </div>
        ) : (
          <div
            className={`content ${
              !showSidebar ? 'purchaser-home-margin' : ''
            } `}>
            <div
              className={`container pt-0 pt-md-3 p-2 px-md-3 p-sm-0 container-purchaser-profile ${
                purchaserDetails.role === 'brand' ||
                athleteDetails.role === 'athlete'
                  ? 'px-0'
                  : 'px-3'
              }`}>
              <div className="row">
                {/* show only for athlete layouts */}
                {userRole === 'athlete' && (
                  <>
                    {Object.keys(stripeStatus).length > 0 && (
                      <>
                        {stripeStatus?.subscription_valid === false &&
                          stripeStatus.stripe_account_valid === false && (
                            <CardTheme
                              bgUrl={backgroundStyle.themeBg}
                              bgUrlMob={backgroundStyle.themeBgMob}>
                              <div className="ct-left">
                                <div className="ct-title">
                                  Publish your listings
                                </div>
                                <div className="ct-content">
                                  Your shop is not published to the public yet.
                                  Start your free trial today to publish your
                                  listings and start engaging with fans and
                                  getting paid!
                                </div>
                              </div>
                              <div className="ct-btn-contain">
                                <button
                                  className="btn btn-primary ms-auto ct-button"
                                  onClick={() => {
                                    history.push('/athlete/price-details');
                                  }}
                                  type="primary">
                                  Publish & Start Trial
                                </button>
                              </div>
                            </CardTheme>
                          )}

                        {stripeStatus?.subscription_valid === true &&
                          athleteDetails.role === 'athlete' &&
                          stripeStatus.stripe_account_valid === false && (
                            <CardTheme
                              bgUrl={backgroundStyle.themeBg}
                              bgUrlMob={backgroundStyle.themeBgMob}>
                              <div className="ct-left">
                                <div className="ct-title">
                                  Link your bank account
                                </div>
                                <div className="ct-content">
                                  Your subscription has been set up. Link your
                                  bank account to complete the process and
                                  receive payments.
                                </div>
                              </div>
                              <div className="ct-btn-contain">
                                <button
                                  className="btn btn-primary ms-auto ct-button"
                                  onClick={() => {
                                    history.push(
                                      '/athlete/bank-detail-confirmation',
                                    );
                                  }}
                                  type="primary">
                                  Link your bank account
                                </button>
                              </div>
                            </CardTheme>
                          )}
                      </>
                    )}
                  </>
                )}
                <div
                  className={`d-none d-lg-block col-12 col-lg-4  ${
                    showSidebar ? 'col-md-12' : 'col-md-4'
                  }`}>
                  <AboutSection
                    athleteDetails={athleteDetails}
                    openCampaignInvite={openCampaignInvite}
                    openCustomOffer={openCustomOffer}
                    openSubmitOffer={openSubmitOffer}
                    size={size}
                  />
                </div>
                <div
                  className={`col-12 col-lg-8  px-4  pe-md-0 px-lg-5 pe-lg-0 ${
                    showSidebar ? 'col-md-12 ps-md-0' : 'col-md-12 ps-md-0'
                  }`}>
                  <Show show={size?.width > 992}>
                    <AthleteInfo
                      athleteDetails={athleteDetails}
                      athleteState={athleteState}
                    />
                    <Row width="100%" className="mt-5">
                      <Col>
                        <Text
                          display="flex"
                          weight="700"
                          size="28"
                          lineHeight="32"
                          className="mb-3"
                          mrRight="11"
                          textColor="#042237">
                          {athleteDetails?.name && (
                            <>{athleteDetails?.name.split(' ')[0]}'s</>
                          )}
                          &nbsp;Shop
                          <span className="text-muted fw-300 ms-2 letter-spacing-n">
                            ({athleteDetails?.listings?.length})
                          </span>
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      {/*check for quantity */}
                      {athleteDetails?.listings && (
                        <>
                          {athleteDetails?.listings.map((data, i) => {
                            return (
                              <React.Fragment key={i}>
                                <AthleteInfoCard
                                  athleteListDetail={data}
                                  athletProfileDetail={athleteDetails}
                                  key={data.id}
                                />
                              </React.Fragment>
                            );
                          })}
                        </>
                      )}
                    </Row>
                  </Show>
                  <Show show={size?.width <= 991.98}>
                    <AthleteInfo
                      athleteDetails={athleteDetails}
                      type="athlete"
                      openCampaignInvite={openCampaignInvite}
                      openCustomOffer={openCustomOffer}
                      openSubmitOffer={openSubmitOffer}
                      athleteState={athleteState}
                    />

                    <Tabs
                      defaultActiveKey="1"
                      className="mt-0 mt-md-4 custom-tabs tabs-text-uppercase"
                      size="large"
                      onChange={callback}
                      // tabBarExtraContent={operations}
                    >
                      <TabPane
                        tab={`${athleteDetails?.name?.split(' ')[0]}'S Shop`}
                        key="1"
                        className="text-uppercase"
                        style={{fontWeight: '700'}}>
                        <Text
                          display="flex"
                          weight="700"
                          size="28"
                          lineHeight="32"
                          textColor="#042237">
                          {/*check for quantity */}
                          <Row>
                            {athleteDetails?.listings && (
                              <>
                                {athleteDetails?.listings.map((data, i) => {
                                  // let OrderLeft =
                                  //   cardListProduct.limited_quantity -
                                  //   data.order_count;

                                  return (
                                    <React.Fragment key={i}>
                                      <AthleteInfoCard
                                        athleteListDetail={data}
                                        athletProfileDetail={athleteDetails}
                                        key={data.id}
                                      />
                                    </React.Fragment>
                                  );
                                })}
                              </>
                            )}
                          </Row>
                        </Text>
                      </TabPane>
                      <TabPane tab="ABOUT" key="2">
                        <AboutSection
                          athleteDetails={athleteDetails}
                          openCampaignInvite={openCampaignInvite}
                          openCustomOffer={openCustomOffer}
                          openSubmitOffer={openSubmitOffer}
                          size={size}
                        />
                      </TabPane>
                    </Tabs>
                  </Show>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {Object.keys(athleteDetails).length > 0 ? (
        <>
          {showSidebar ? (
            <AppLayout contentClassname="purchaser-profile-holder">
              {mainContent()}
            </AppLayout>
          ) : (
            <>{mainContent()}</>
          )}
        </>
      ) : (
        <>
          {atheleteNotfound && (
            <div className="purchaseContainer discover-athletes-margin">
              <h1
                className="coverSub text-center d-flex align-items-center justify-content-center"
                style={{height: '80vh'}}>
                User not found please go back to&nbsp;
                <a href="/" className="color-purple ">
                  home page
                </a>
              </h1>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Profile;
