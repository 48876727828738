import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  headerSection,
  icons,
  profileSection,
} from '../../../../../utils/staticStrings';
import {setHeader} from '../../../../../store/athleteflow/action';
import * as yup from 'yup';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';
import useApi from '../../../../../hooks/useApi';
import {Formik} from 'formik';
import ImgCrop from 'antd-img-crop';
import {Select, Upload} from 'antd';
import Loader from 'react-loader-spinner';
import {
  purchaserProfileStatus,
  purchaserProfileSubmit,
} from '../../../../../store/brandflow/action';
import TextArea from 'antd/lib/input/TextArea';
import {
  fetchPurchaserData,
  logoutData,
} from '../../../../../store/users/actions';
import {UploadAssets} from '../../../../../hooks/UploadImage';

let brandSchema = yup.object().shape({
  company: yup.string().required('Please select Company Name'),
  industry_id: yup.string().required('Please select Industry'),
});

const BrandDetails = (props) => {
  let {profileData, mode, tabKey} = props;
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadimgImageStatus, setLoadingImageStatus] = useState(false);
  const dispatch = useDispatch();
  const {profileSubmitStatus} = useSelector(
    (state) => state.BrandModuleReducer,
  );
  const history = useHistory();
  const {Option} = Select;
  const [industries] = useApi('/athlete/industries');

  let brandInitialValues = {
    company: profileData?.purchaser?.company
      ? profileData?.purchaser?.company
      : '',
    bio: profileData?.bio ? profileData?.bio : '',
    industry_id: profileData?.purchaser?.industry_id
      ? profileData?.purchaser?.industry_id
      : '',
    website: profileData?.purchaser?.website
      ? profileData?.purchaser?.website
      : '',
    urls: {
      Facebook: profileData?.social?.urls?.Facebook
        ? profileData?.social?.urls?.Facebook
        : '',
      Instagram: profileData?.social?.urls?.Instagram
        ? profileData?.social?.urls?.Instagram
        : '',
      Linkedin: profileData?.social?.urls?.Linkedin
        ? profileData?.social?.urls?.Linkedin
        : '',
      Twitter: profileData?.social?.urls?.Twitter
        ? profileData?.social?.urls?.Twitter
        : '',
    },
    logo: profileData?.purchaser?.logo ? profileData?.purchaser?.logo : '',
  };

  useEffect(() => {
    dispatch(setHeader(false));
  }, [dispatch]);

  const showToastAndResetStatus = () => {
    if (mode === 'create') {
      toastMessage('Profile uploaded successfully');
    } else {
      toastMessage('Profile saved successfully');
    }
    setShowSuccess(true);

    dispatch(purchaserProfileStatus(false));
  };

  useEffect(() => {
    if (profileSubmitStatus === true) {
      if (mode === 'create' && tabKey === '-1') {
        showToastAndResetStatus();
        setIsLoading(true);
        setTimeout(() => {
          history.push('/purchaser/dashboard');
        }, 3000);
      }
      if (mode === 'edit' && tabKey === '1') {
        showToastAndResetStatus();
        dispatch(fetchPurchaserData(profileData.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSubmitStatus]);

  const getChangedValues = (values, initialValues) => {
    return Object.entries(values).reduce((acc, [key, value]) => {
      if (!Array.isArray(value)) {
        const hasChanged = initialValues[key] !== value;
        if (hasChanged) {
          acc[key] = value;
        }
      } else if (value.length > 0) {
        acc[key] = value;
      }

      return acc;
    }, {});
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toastMessage('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 12;
    if (!isLt2M) {
      errorToastMessage('Image must smaller than 12MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadFile = (fileObj, setValue) => {
    const handleUpload = (data) => {
      setValue('logo', data);
    };
    UploadAssets(fileObj, handleUpload, setLoadingImageStatus)();
  };

  const logout = () => {
    dispatch(logoutData());
    history.push('/login');
  };

  return (
    <div className="athlete-page-bg">
      <div className="profile-container">
        {isLoading && (
          <div className="loader text-center">
            <Loader type="Bars" color="#00BFFF" height={50} width={100} />
          </div>
        )}
        {!isLoading && (
          <>
            <div className="header-style header-light header-fixed athlete-bg">
              <div className="container-fluid px-4 page-contain athlete-page-bg">
                <div className="d-flex justify-content-between align-items-center py-3 profile-wrapper">
                  <a href="/">
                    <img
                      alt="headerlogo"
                      src={headerSection.headerLogoBlack}
                      className="d-inline-block align-top"
                    />
                  </a>
                  {!showSuccess && mode === 'create' && (
                    <div className="text-dark d-flex fw-600 d-none d-md-flex">
                      Signed in as {profileData?.name}.
                      <div
                        onClick={logout}
                        className=" ms-2 text-purple cursor-pointer text-link">
                        Sign out
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={mode === 'create' ? 'profile-main' : ''}>
              {mode === 'create' && (
                <>
                  <div className="profile-text mb-2">
                    {profileSection.text1}
                  </div>
                  <div className="profile-title">{profileSection.title}</div>
                </>
              )}
              <div className="profilepart-1 mt-5 mb-5">
                <Formik
                  enableReinitialize={true}
                  initialValues={brandInitialValues}
                  validationSchema={brandSchema}
                  onSubmit={(values) => {
                    console.log('Values --> ', values);
                    let changedValues = getChangedValues(
                      values,
                      brandInitialValues,
                    );
                    dispatch(
                      purchaserProfileSubmit(changedValues, profileData.id),
                    );
                  }}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isValid,
                    isSubmitting,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="container-fluid">
                          <div className="d-md-flex align-items-center">
                            <div className="profile-pic-upload flex-shrink-0 mb-5  mx-auto m-none">
                              <div style={{position: 'relative'}}>
                                <ImgCrop rotate>
                                  <Upload
                                    className={`profile-input  ${
                                      values.logo ? 'profile-image-holder' : ''
                                    }`}
                                    beforeUpload={beforeUpload}
                                    customRequest={(fileList) =>
                                      uploadFile(fileList, setFieldValue)
                                    }
                                    showUploadList={false}
                                    name="logo"
                                    maxCount={1}>
                                    <div className="">
                                      {values.logo ? (
                                        <div className="position-relative">
                                          <img
                                            src={values.logo}
                                            alt="profilePic"
                                            className="profile-img-holder"
                                          />
                                          <p className="text-purple mt-3 fw-bold text-center change-image-text">
                                            Change photo
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="d-flex align-items-center  flex-column w-100 h-100 ">
                                          <img
                                            src={icons.cameraIcon}
                                            alt="camera Icon"
                                          />
                                          <p className="text-purple mt-3 fw-bold">
                                            Choose Brand Image
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </Upload>
                                </ImgCrop>
                                {loadimgImageStatus && (
                                  <div className="loader text-center">
                                    <Loader
                                      type="Bars"
                                      color="#00BFFF"
                                      height={50}
                                      width={100}
                                    />
                                  </div>
                                )}
                                <div className="text-danger fw-500 text-sm text-center mt-1">
                                  {touched.logo && errors.logo && errors.logo}
                                </div>
                              </div>
                            </div>
                            <div className="ms-0 ms-md-4 flex-grow-1 mb-3 md-md-5">
                              <div className="mb-4">
                                <label className="text" htmlFor="company">
                                  Company Name
                                  <span className="text-danger"> *</span>
                                </label>
                                <input
                                  className="pro-input mt-1"
                                  name="company"
                                  id="company"
                                  placeholder="Company Name"
                                  onChange={(e) => {
                                    setFieldValue('company', e.target.value);
                                  }}
                                  value={values.company}
                                />
                                <div className="text-danger text-sm fw-500">
                                  {touched.company &&
                                    errors.company &&
                                    errors.company}
                                </div>
                              </div>
                              <div className="mb-3">
                                <label className="text" htmlFor="industry">
                                  Industry
                                  <span className="text-danger"> *</span>
                                </label>
                                <Select
                                  className="custom-select custom-select-light"
                                  value={
                                    values.industry_id
                                      ? values.industry_id
                                      : 'Select Industry'
                                  }
                                  onChange={(value) =>
                                    setFieldValue('industry_id', value)
                                  }
                                  name="industry_id">
                                  {industries?.data?.rows.map((option) => (
                                    <Option value={option.id} key={option.id}>
                                      {option.name}
                                    </Option>
                                  ))}
                                </Select>
                                <div className="text-danger text-sm fw-500">
                                  {touched.industry_id &&
                                    errors.industry_id &&
                                    errors.industry_id}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mb-4 textarea-custom">
                            <label className="text" htmlFor="bio">
                              Bio
                            </label>
                            <TextArea
                              name="bio"
                              showCount
                              className="mt-1 custom-text-count"
                              placeholder="Add bio details"
                              maxLength={1000}
                              onChange={(e) =>
                                setFieldValue('bio', e.target.value)
                              }
                              value={values.bio}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="text" htmlFor="website">
                              Company Website
                            </label>
                            <input
                              className="pro-input mt-1"
                              name="website"
                              id="website"
                              placeholder="Company Website"
                              onChange={(e) =>
                                setFieldValue('website', e.target.value)
                              }
                              value={values.website}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="text" htmlFor="linkedin">
                              Linkedin
                            </label>
                            <input
                              className="pro-input mt-1"
                              name="linkedin"
                              id="linkedin"
                              placeholder="Linkedin"
                              onChange={(e) =>
                                setFieldValue('urls.Linkedin', e.target.value)
                              }
                              value={values.urls?.Linkedin}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="text" htmlFor="facebookAcc">
                              Facebook
                            </label>

                            <input
                              className="pro-input mt-1"
                              name="facebook"
                              id="facebookAcc"
                              placeholder="Facebook"
                              onChange={(e) =>
                                setFieldValue('urls.Facebook', e.target.value)
                              }
                              value={values.urls?.Facebook}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="text" htmlFor="twitterAcc">
                              Twitter
                            </label>

                            <input
                              className="pro-input mt-1"
                              name="twitter"
                              id="twitterAcc"
                              placeholder="Twitter"
                              type="text"
                              onChange={(e) =>
                                setFieldValue('urls.Twitter', e.target.value)
                              }
                              value={values.urls?.Twitter}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="text" htmlFor="instagramAcc">
                              Instagram
                            </label>
                            <input
                              className="pro-input mt-1"
                              name="instagram"
                              id="instagramAcc"
                              placeholder="Instagram"
                              onChange={(e) =>
                                setFieldValue('urls.Instagram', e.target.value)
                              }
                              value={values.urls?.Instagram}
                            />
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary mw-570">
                              SAVE
                            </button>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BrandDetails;
