// import {backgroundStyle} from '../../../utils/staticStrings';
import {
  SocialPostIcon,
  PhotosAndVideos,
  Podcast,
  Location,
  VoiceRecord,
  Signature,
  SportsDemo,
  VideoShoutout,
  NILLicensing,
  BrandAmbassador,
} from '../../../utils/SocialIcons';
export const dropdownType = ['EdSmith', 'Ruud Hedwig', 'Steve Jonhson'];
export const sortOption = ['Alphabetic A-Z', 'Alphabetic Z-A', 'Date'];
export const athleteData = [
  {src: <SocialPostIcon />, name: 'Social media post'},
  {src: <PhotosAndVideos />, name: 'Photos/videos'},
  {src: <Podcast />, name: 'Podcast interview'},
  {src: <Location />, name: 'Appearance'},
  {src: <BrandAmbassador />, name: 'Brand ambassador'},
  {src: <Signature />, name: 'Autograph signing'},
  {src: <NILLicensing />, name: 'NIL licensing'},
  {src: <VideoShoutout />, name: 'Video shoutout'},
  {src: <VoiceRecord />, name: 'Guest speaking'},
  {src: <SportsDemo />, name: 'Sports demo'},
];



