import React, {Fragment} from 'react';
import {Dropdown} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import Text from '../../../components/Text/Text';
import ProfileImage from './ProfileImage';
import {Hr} from '../../../components/Hr/index';
import {backgroundStyle} from '../../../utils/staticStrings';
import moment from 'moment';
import {
  AthleteInfoContainer,
  Icon,
  AthleteName,
  ShareDetails,
  AthleteSocialContainer,
  CustomText,
  SportsList,
  AthleteTagsContainer,
  SocialContainer,
  LocationMobile,
  ShareDetailsMobile,
} from './style';
import {
  AthleteMobileContainer,
  BackgroundImage,
  OfferButtonMobile,
  CustomAthleteTag,
  TagContainer,
  CustomDropDownOption,
} from '../pages/style';
import StackedChart from './StackedChart';
import {EditProfileButton} from '../../../common/AthleteProfile/style';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  Instagram,
  Twitter,
  Facebook,
  Music,
  Youtube,
} from '../../../utils/SocialIcons';
import {formatSocialCount} from '../../../helpers/user';
import useDeviceSize from '../../../hooks/useDeviceSize';
import {Tooltip} from 'antd';

const AthleteInfo = ({
  openCampaignInvite,
  openCustomOffer,
  flow,
  athleteDetails,
  athleteState,
  openSubmitOffer,
}) => {
  const history = useHistory();
  const {purchaserDetails} = useSelector((state) => state.UsersReducer);
  const size = useDeviceSize();
  const parseCount = (str) => (str ? parseInt(str, 10) : 0);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  let totalReach =
    parseCount(athleteDetails?.social?.facebook_followers) +
    parseCount(athleteDetails?.social?.instagram_followers) +
    parseCount(athleteDetails?.social?.tiktok_followers) +
    parseCount(athleteDetails?.social?.twitter_followers) +
    parseCount(athleteDetails?.social?.youtube_followers);

  let fb_percentage = athleteDetails?.social?.facebook_followers
    ? Number((athleteDetails?.social?.facebook_followers / totalReach) * 100)
    : 0;
  let insta_percentage = athleteDetails?.social?.instagram_followers
    ? Number((athleteDetails?.social?.instagram_followers / totalReach) * 100)
    : 0;
  let tiktokPercentage = athleteDetails?.social?.tiktok_followers
    ? Number((athleteDetails?.social?.tiktok_followers / totalReach) * 100)
    : 0;

  let twitterPercentage = athleteDetails?.social?.twitter_followers
    ? Number((athleteDetails?.social?.twitter_followers / totalReach) * 100)
    : 0;

  let youTubePercentage = athleteDetails?.social?.youtube_followers
    ? Number((athleteDetails?.social?.youtube_followers / totalReach) * 100)
    : 0;

  let progressBarData = [
    {
      name: 'facebook',
      value: fb_percentage,
      url: athleteDetails?.social?.urls?.facebook_url,
      icon: <Facebook />,
      count: athleteDetails?.social?.facebook_followers,
    },
    {
      name: 'instagram',
      value: insta_percentage,
      url: athleteDetails?.social?.urls?.instagram_url,
      icon: <Instagram />,
      count: athleteDetails?.social?.instagram_followers,
    },
    {
      name: 'music',
      value: tiktokPercentage,
      url: athleteDetails?.social?.urls?.tiktok_url,
      icon: <Music />,
      count: athleteDetails?.social?.tiktok_followers,
    },
    {
      name: 'twitter',
      value: twitterPercentage,
      url: athleteDetails?.social?.urls?.twitter_url,
      icon: <Twitter />,
      count: athleteDetails?.social?.twitter_followers,
    },
    {
      name: 'youtube',
      value: youTubePercentage,
      url: athleteDetails?.social?.urls?.youtube_url,
      icon: <Youtube />,
      count: athleteDetails?.social?.youtube_followers,
    },
  ];

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard

    copyTextToClipboard(window.location.href)
      .then(() => {
        // If successful, update the isCopied state value
        // setIsCopied(true);
        // setTimeout(() => {
        //   setIsCopied(false);
        // }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const menu = () => (
    <CustomDropDownOption>
      <div onClick={openCustomOffer}>
        <Text
          display="flex"
          weight="800"
          size="17"
          className="py-3 align-items-center justify-content-center"
          lineHeight="23"
          textColor="#FFFFFF"
          cursor="pointer">
          CUSTOM OFFERS
        </Text>
      </div>
      <Hr mrBottom="0px" mrTop="0px" />
      <div onClick={openCampaignInvite}>
        <Text
          display="flex"
          weight="800"
          size="17"
          className="py-3 align-items-center justify-content-center"
          lineHeight="23"
          textColor="#FFFFFF"
          cursor="pointer">
          INVITE TO APPLY
        </Text>
      </div>
    </CustomDropDownOption>
  );

  const editProfile = () => {
    history.push(`/athlete/account-details`);
  };

  return (
    <Fragment>
      <AthleteInfoContainer>
        <div className="d-block d-lg-none">
          <AthleteMobileContainer>
            <ProfileImage
              width="189"
              mobileImgWidth={190}
              className="img-fluid rounded-m"
              zIndex="10"
              imgContainerClassName={`position-relative`}
              src={athleteDetails?.image_url}
              borderRadius={15}>
              <BackgroundImage
                className="img-fluid"
                src={backgroundStyle?.bgPic}
                alt="background"
              />
            </ProfileImage>
          </AthleteMobileContainer>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <AthleteName>
            <CustomText
              display="flex"
              weight="800"
              size="50"
              mdSize="45"
              smSize="32"
              lineHeight="64"
              smLineHeight="40"
              mrRight="11"
              textColor="#042237">
              {athleteDetails?.name}
            </CustomText>

            <Icon src={backgroundStyle.verifiedIcon} alt="verification" />
          </AthleteName>
          <ShareDetails>
            <Tooltip
              title="Copied!"
              color="#895cf8"
              overlayClassName="tootlipCopy"
              trigger={['click']}
              // mouseLeaveDelay={0}
            >
              <Icon
                width="18"
                height="18"
                onClick={handleCopyClick}
                mrRight="16"
                src={backgroundStyle.linkIcon}
                alt="verification"
              />
            </Tooltip>
          </ShareDetails>
        </div>
        <h2
          className={`font-l mb-2  ${
            size?.width <= 767 ? 'fw-600' : 'fw-800'
          } `}>
          {athleteDetails?.athlete?.headliner}
        </h2>
        <SportsList>
          {athleteDetails?.gender && (
            <li className="text-capitalize">{athleteDetails?.gender}</li>
          )}
          <li>
            Age &nbsp;
            <>
              {athleteDetails?.dob && (
                <>
                  {moment(athleteDetails?.dob, 'YYYYMMDD')
                    .fromNow()
                    .split(' ')[0] > 0 ? (
                    <>
                      {
                        moment(athleteDetails?.dob, 'YYYYMMDD')
                          .fromNow()
                          .split(' ')[0]
                      }
                    </>
                  ) : (
                    <>{moment(athleteDetails?.dob, 'YYYYMMDD').fromNow()}</>
                  )}

                  {/* {
                    moment(athleteDetails?.dob, 'YYYY-DD-MM')
                      .fromNow()
                      .split(' ')[0]
                  } */}
                </>
              )}
            </>
          </li>
          <li>
            {athleteDetails?.address && (
              <>
                {size?.width > 767 ? (
                  <>
                    📍
                    <Text
                      display="flex"
                      weight="500"
                      size="16"
                      smLineHeight="22"
                      smSize="14"
                      lineHeight="28"
                      textColor="#435969">
                      Currently in &nbsp;
                      {`${athleteDetails?.address?.city}, ${athleteDetails?.address?.state}`}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      display="flex"
                      weight="500"
                      size="16"
                      smLineHeight="22"
                      smSize="14"
                      lineHeight="28"
                      textColor="#435969">
                      📍
                      {`${athleteDetails?.address?.city}, ${athleteDetails?.address?.state}`}
                    </Text>
                    <div className="d-flex ms-3">
                      <ShareDetailsMobile>
                        <Tooltip
                          title="Copied!"
                          color="#895cf8"
                          overlayClassName="tootlipCopy"
                          trigger={['click']}>
                          <Icon
                            width="18"
                            height="18"
                            onClick={handleCopyClick}
                            mrRight="16"
                            src={backgroundStyle.linkIcon}
                            alt="verification"
                          />
                        </Tooltip>
                      </ShareDetailsMobile>
                    </div>
                  </>
                )}
              </>
            )}
          </li>
        </SportsList>
        <LocationMobile>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center ">
              {athleteDetails?.location && (
                <>
                  <Icon
                    width="16"
                    height="16"
                    mrRight="10"
                    src={backgroundStyle.locationIcon}
                    alt="location"
                  />
                  <Text
                    display="flex"
                    weight="500"
                    size="16"
                    smLineHeight="22"
                    smSize="14"
                    lineHeight="28"
                    textColor="#435969">
                    {athleteDetails?.location}
                  </Text>
                </>
              )}
            </div>
          </div>
        </LocationMobile>
        <AthleteTagsContainer>
          <Text
            display="flex"
            weight="700"
            size="16"
            lineHeight="20"
            mrRight="20"
            textColor="#435969">
            Tags
          </Text>
          <TagContainer>
            <>
              <CustomAthleteTag className="titlecase">{`${athleteDetails?.athlete?.type} athlete`}</CustomAthleteTag>
              <CustomAthleteTag className="titlecase">
                {athleteState}
              </CustomAthleteTag>
              {athleteDetails?.athlete?.sports.map((item) => (
                <CustomAthleteTag key={item.id}>{item.name}</CustomAthleteTag>
              ))}
              {athleteDetails?.athlete?.teams.map((item) => (
                <CustomAthleteTag key={item.id}>{item.name}</CustomAthleteTag>
              ))}
              {athleteDetails?.athlete?.colleges.map((item) => (
                <CustomAthleteTag key={item.id}>{item.name}</CustomAthleteTag>
              ))}
            </>
          </TagContainer>
        </AthleteTagsContainer>
        {totalReach > 0 && (
          <AthleteSocialContainer>
            <div className="d-flex my-2 my-md-3 jn">
              <h3 className="text-nowrap font-m color-muted fw-700 me-2">
                Total Reach
              </h3>

              <Text
                display="flex"
                weight="700"
                size="16"
                lineHeight="20"
                mrRight="20"
                textColor="#7741fe">
                {formatSocialCount(totalReach)}
              </Text>
            </div>

            <SocialContainer>
              <StackedChart socialData={progressBarData} />
            </SocialContainer>
          </AthleteSocialContainer>
        )}
        {flow === 'athlete' ? (
          <>
            <EditProfileButton onClick={editProfile}>
              <i className="icon-edit me-2"></i>
              EDIT PROFILE INFO
            </EditProfileButton>
          </>
        ) : purchaserDetails && Object.keys(purchaserDetails).length > 0 ? (
          (purchaserDetails?.role === 'brand' ||
            purchaserDetails?.role === 'fan') && (
            <>
              {purchaserDetails?.role === 'fan' && (
                <div
                  onClick={() => {
                    openSubmitOffer({});
                  }}>
                  <OfferButtonMobile>
                    <Text
                      display="flex"
                      weight="800"
                      size="17"
                      cursor="pointer"
                      lineHeight="23"
                      textColor="#FFFFFF">
                      CUSTOM OFFER
                    </Text>
                  </OfferButtonMobile>
                </div>
              )}
              {purchaserDetails?.role === 'brand' && (
                <Dropdown overlay={menu} trigger={['click']}>
                  <OfferButtonMobile>
                    <Text
                      display="flex"
                      weight="800"
                      size="17"
                      lineHeight="23"
                      textColor="#FFFFFF">
                      WORK TOGETHER
                    </Text>
                    <div
                      className="d-flex align-items-center"
                      style={{position: 'absolute', right: '20px'}}>
                      <DownOutlined
                        style={{fontSize: '12px', color: 'white'}}
                      />
                    </div>
                  </OfferButtonMobile>
                </Dropdown>
              )}
            </>
          )
        ) : (
          <OfferButtonMobile className="mt-1 mb-2">
            <div
              onClick={() => {
                history.push('/login');
              }}>
              <Text
                display="flex"
                weight="800"
                size="17"
                cursor="pointer"
                lineHeight="23"
                textColor="#FFFFFF">
                CUSTOM OFFER
              </Text>
            </div>
          </OfferButtonMobile>
        )}
      </AthleteInfoContainer>
    </Fragment>
  );
};

export default AthleteInfo;
