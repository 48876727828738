import React from 'react';
import {icons} from '../../../../../utils/staticStrings';
import {formatPriceCommas} from '../../../../../helpers/user';

const WithdrawEarningModal = (props) => {
  const {
    setModalVisible,
    income,
    stripeAccount,
    withdrawBalance,
    successFundsMsg,
    closeWithdrawModal,
  } = props;

  return (
    <div className="p-0 ">
      {successFundsMsg ? (
        <>
          <div className="font-24 fw-700 text-center border-bottom p-3">
            Withdraw funds
          </div>
          <div className="p-4 p-md-5">
            <div className="border p-3 rounded-s">
              <div className="d-flex justify-content-between border-bottom align-items-center pb-4 pt-3">
                <h6 className="fw-700 font-18 m-0">Transfer to bank account</h6>

                {!Array.isArray(stripeAccount) &&
                  Object.keys(stripeAccount).length > 0 && (
                    <div className="font-18 color-muted">
                      *********{stripeAccount?.data[0]?.last4} on{' '}
                      {stripeAccount?.data[0]?.bank_name}
                    </div>
                  )}
              </div>
              <div className="d-flex justify-content-between align-items-center pt-3">
                <h6 className="fw-700 font-18 m-0">Total Amount</h6>
                <p className="m-0 font-l fw-800">{formatPriceCommas(income)}</p>
              </div>
            </div>

            <div className="row g-3 mt-4">
              <div className="col-12 col-md-6">
                <button
                  className="btn btn-light"
                  onClick={() => setModalVisible(false)}>
                  Cancel
                </button>
              </div>
              <div className="col-12 col-md-6">
                <button
                  className="btn btn-primary w-100"
                  onClick={
                    withdrawBalance
                    // setSuccesMessageHolder(false)
                  }>
                  withdraw
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="p-4 p-md-5">
          <div className="text-center">
            <img className="msgicon" alt="msgicon" src={icons.tickColorIcon} />
            <h1 className="font-l fw-700 mt-4 mb-3">
              {income} transferred to your account!
            </h1>
            <p className="color-dark mw-500 mx-auto mb-5">
              The transfer typically takes 2 to 3 business days before arriving
              in your account.
            </p>
            <div className="btn btn-primary" onClick={closeWithdrawModal}>
              Great!
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default WithdrawEarningModal;
