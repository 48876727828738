import React, {useEffect} from 'react';
import ImageButtonLinkSection from '../components/features/banner-details-section';
import HowItWorksSection from '../components/features/how-it-works';
import GetStartedSection from '../components/features/get-started';
import RightImgDetailSection from '../components/features/right-img-details-section';
import LeftImageDetailSection from '../components/features/left-img-details-section';
import WhyAthleteUpSection from '../components/features/why-athlete-up';
import Loader from 'react-loader-spinner';
import MaximizeEarningsSection from '../components/features/maximize-earnings';
import MeetOurStars from '../components/features/meet-our-stars';
import {useDispatch, useSelector} from 'react-redux';
import {
  runYourShopSection,
  likeAnAgentSection,
  athleteUpDealsSection,
  athleteUpShopSection,
  drivingGrowthSection,
  howItWorksSectionHome,
} from '../../../utils/staticStrings';
import {setHeader} from '../../../store/athleteflow/action';
import {toastMessage} from '../../../helpers/toastMessage';
import {changeLoggedOutStatus} from '../../../store/users/actions';
import useDeviceSize from '../../../hooks/useDeviceSize';
import useApi from '../../../hooks/useApi';

export default function Home() {
  const loadingPosts = false;
  const dispatch = useDispatch();
  const size = useDeviceSize();
  const [meetStars] = useApi('/athlete?page=1&pageSize=8&sort=recommended');
  const {loggedOutStatus} = useSelector((state) => state.UsersReducer);

  const onClickLearnMore = () => {
    const id = 'how-it-works';
    const yOffset = -100;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    document.querySelector('body').scrollTo({top: y, behavior: 'smooth'});
  };

  useEffect(() => {
    document.querySelector('body').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    dispatch(setHeader(true));
  }, [dispatch]);

  useEffect(() => {
    if (loggedOutStatus === true) {
      toastMessage('Logged out successfully');
      dispatch(changeLoggedOutStatus(false));
    }
  }, [dispatch, loggedOutStatus]);

  return (
    <div className="home-style main-content">
      {loadingPosts ? (
        <div className="loader">
          <Loader
            type="Bars"
            color="#00BFFF"
            height={50}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      ) : (
        <>
          <div className="page-contain">
            <div className="padding-main">
              <ImageButtonLinkSection
                onLearnMoreClick={onClickLearnMore}
                showBrands={false}
                deviceSize={size}
              />
            </div>
            <div className="padding-main">
              <HowItWorksSection data={howItWorksSectionHome} />
            </div>
            <div className="padding-main">
              <LeftImageDetailSection data={athleteUpShopSection} />
            </div>
            <div className="padding-main">
              <RightImgDetailSection data={runYourShopSection} />
            </div>
            <div className="padding-main">
              <LeftImageDetailSection data={athleteUpDealsSection} />
            </div>
            <div className="padding-main">
              <RightImgDetailSection data={likeAnAgentSection} />
            </div>
          </div>
          <MeetOurStars meetStars={meetStars?.data} />
          <MaximizeEarningsSection />
          <div className="page-contain">
            <div className="padding-main">
              <WhyAthleteUpSection />
            </div>
            <div className="padding-main">
              <LeftImageDetailSection data={drivingGrowthSection} />
            </div>
          </div>
          <GetStartedSection brand={false} />
        </>
      )}
    </div>
  );
}
