import React, {useState, useEffect} from 'react';
import '../features.scss';
import AddSocialAccount from './AddSocialAccount';
import AddListing from './AddListing';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  profileSubmit,
  profileSubmitSuccess,
} from '../../../../../store/athleteflow/action';
import {logoutData, handleMessage} from '../../../../../store/users/actions';
import {headerSection} from '../../../../../utils/staticStrings';
import {setHeader} from '../../../../../store/athleteflow/action';
import {toastMessage} from '../../../../../helpers/toastMessage';
import http from '../../../../../helpers/http';

const AddSocialAccountDetails = () => {
  const [showForm2, setShowForm2] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const dispatch = useDispatch();
  const {profileSubmitStatus} = useSelector(
    (state) => state.AthleteModuleReducer,
  );
  // const {message} = useSelector((state) => state.UsersReducer);
  const history = useHistory();

  useEffect(() => {
    dispatch(setHeader(false));
    dispatch(handleMessage(''));
    dispatch(profileSubmitSuccess(false));
  }, [dispatch]);

  useEffect(() => {
    if (profileSubmitStatus === true) {
      toastMessage('Profile created successfully');
    }
  }, [profileSubmitStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (profileSubmitStatus === false) {
  //     errorToastMessage(message?.text?.message);
  //   }
  // }, [profileSubmitStatus]);

  // useEffect(() => {
  //   if (message?.text?.success === true) {
  //     toastMessage(message?.text?.data);
  //   }
  //   if (message?.text?.success === false) {
  //     errorToastMessage(message?.text?.message);
  //   }
  // }, [message]);

  const addListing = () => {
    history.push('/athlete/create-listing');
  };

  const goToDashboard = () => {
    http.get('/account/account-validity').then((res) => {
      if (res && res?.data && res?.data?.data) {
        const stripeActValid = res?.data?.data?.stripe_account_valid;
        const subscriptionValid = res?.data?.data?.subscription_valid;

        if (stripeActValid && subscriptionValid) {
          history.push('/athlete/dashboard');
        } else {
          history.push('/athlete/my-shop');
        }
      }
    });
  };

  const profileSocialFormSubmit = (value) => {
    let payload = {...value, name: localStorage.getItem('name')};
    setShowForm2(false);

    setShowSuccess(true);
    dispatch(profileSubmit(payload, localStorage.getItem('id')));
  };

  const logout = () => {
    dispatch(logoutData());
  };

  const goToHome = () => {
    history.push('/');
  };

  return (
    <div className="athlete-page-bg">
      <div className="profile-container">
        <div className="header-style header-light header-fixed athlete-bg">
          <div className="container-fluid px-4 page-contain athlete-page-bg">
            <div className="d-flex justify-content-between align-items-center py-3 profile-wrapper">
              <div onClick={goToHome} className="cursor-pointer">
                <img
                  alt="headerlogo"
                  src={headerSection.headerLogoBlack}
                  className="d-inline-block align-top"
                />
              </div>
              {!showSuccess ? (
                <div className="text-dark d-flex fw-600 d-none d-md-flex">
                  Signed in as {localStorage.getItem('name')}
                  <a
                    href="/login"
                    onClick={logout}
                    className=" ms-2 text-purple cursor-pointer text-link">
                    Sign out
                  </a>
                </div>
              ) : (
                <>
                  <div
                    className="text-purple cursor-pointer fw-600"
                    onClick={() => goToDashboard()}>
                    Go to Dashboard <i className="icon-right"></i>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="profile-main">
          <div className="profile-section">
            {showForm2 && (
              <AddSocialAccount
                profileSocialFormSubmit={profileSocialFormSubmit}
              />
            )}
            {showSuccess && <AddListing addListing={addListing} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSocialAccountDetails;
