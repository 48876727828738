import React from 'react';

export default function index(props) {
  const {img, titleImg, text, page} = props.data;
  return (
    <div
      className={
        'right-img-detail-section ' +
        (page === 'brands' ? 'discover-match' : '')
      }>
      <div className="row right-section">
        <div className="col-12 col-sm-10 col-md-6 d-md-none">
          <div className="row">
            <img src={img} alt="imga" />
          </div>
        </div>
        <div className="col-11 col-sm-10 col-md-6 col-lg-5 col-xl-5 col-xxl-5 mt-4 mt-lg-0 text-section">
          <div className="row">
            <div className="col-12 col-lg-11 col-xl-11 col-xxl-10">
              {titleImg ? (
                <img className="shopiconimg" src={titleImg} alt="imgleft" />
              ) : (
                <h2 className="title">{props.data.title} </h2>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12 col-xxl-11">
              <p className="text"> {text}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-xl-7 col-md-6 col-xxl-6 ms-xxl-2 d-none d-md-block px-3">
          <div className="row">
            <img src={img} alt="imga" />
          </div>
        </div>
      </div>
    </div>
  );
}
