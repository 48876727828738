import React from 'react';
import { icons } from '../../../../../utils/staticStrings';
import {useHistory} from 'react-router-dom';

const AddListing = (props) => {
  const history = useHistory();
  return (
    <>
      <div className="container-fluid text-center">
        <img src={icons.tickColorIcon} alt="tickIcon" className="mt-3 mb-5" />
        <div className="profile-title">You’re all set!</div>
        <div className="mw-770 mx-auto">
          <div className="profile-text1 mt-2 mb-5">
            You can now create Listings and start selling Experiences and
            Physical & Digital products directly on your personal Shop page
          </div>
          <div className="card-light card-list-spacing">
            <h6 className="font-l fw-bold">Add your first Listing</h6>
            <p className="color-muted font-15 mb-4 fw-500">
              Start the simple guided process to sell an off-the-shelf product
              or experience.
            </p>
            <div className="">
              <button
                type="submit"
                className="btn clickbtn text-uppercase fw-800"
                onClick={() => {
                  history.push('/athlete/choose-listing-method');
                  // props.addListing();
                }}>
                Add listing
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddListing;
