import React, {useEffect, useState} from 'react';
import {Menu, Dropdown} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {logoutData} from '../../store/users/actions';
import {useHistory} from 'react-router-dom';
import {backgroundStyle} from '../../utils/staticStrings';

const ProfileSettings = (props) => {
  const {myPurchase} = props;
  const [role, setRole] = useState('');
  const [profileImg, setProfileImg] = useState('');
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {athleteDetails, clearData, purchaserDetails} = useSelector(
    (state) => state.UsersReducer,
  );
  const {membeShipExpired} = useSelector((state) => state.AthleteModuleReducer);
  const activeEditTab = () => {
    localStorage.setItem('activeAccountTab', '2');
  };

  const activeAccountTab = () => {
    localStorage.setItem('activeAccountTab', '1');
  };

  const activeBillingTab = () => {
    if (role === 'athlete') {
      localStorage.setItem('activeAccountTab', '4');
    } else {
      localStorage.setItem('activeAccountTab', '3');
    }
  };

  useEffect(() => {
    const userRole = localStorage.getItem('userRole');
    setRole(userRole);
  }, []);

  useEffect(() => {
    if (clearData) {
      push('/');
    }
  }, [clearData, push]);

  useEffect(() => {
    if (role === 'athlete' && athleteDetails?.image_url) {
      setProfileImg('url(' + athleteDetails?.image_url + ' )');
    } else if (role === 'brand' && purchaserDetails?.purchaser?.logo) {
      setProfileImg('url(' + purchaserDetails?.purchaser?.logo + ' )');
    } else if (role === 'fan' && purchaserDetails?.image_url) {
      setProfileImg('url(' + purchaserDetails?.image_url + ' )');
    } else {
      setProfileImg('url(' + backgroundStyle.defaultProfilePic + ' )');
    }
  }, [athleteDetails?.image_url, purchaserDetails, role]);

  const logout = () => {
    dispatch(logoutData());
  };

  const menu = (
    <Menu className="custom-menu-dropdown">
      {!myPurchase && (
        <>
          <Menu.Item key="0">
            {membeShipExpired ? (
              <div className="fw-bold cursor-notallowed ">
                View Public Profile
              </div>
            ) : (
              <a
                href={`view-profile/${athleteDetails?.id}`}
                className="fw-bold">
                View Public Profile
              </a>
            )}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="1" onClick={activeEditTab}>
            <a href="/athlete/account-details">Edit Profile</a>
          </Menu.Item>
          <Menu.Item key="2" onClick={activeAccountTab}>
            <a href="/athlete/account-details">Account Details</a>
          </Menu.Item>
          <Menu.Item key="3" onClick={activeBillingTab}>
            <a href="/athlete/account-details">Membership</a>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="4" onClick={logout}>
            <div className="info-text fw-700 color-dark">Log Out</div>
          </Menu.Item>
        </>
      )}
      {myPurchase && (
        <>
          {role === 'brand' && (
            <Menu.Item key="5" onClick={activeAccountTab}>
              <a href="account-details">Brand Details</a>
            </Menu.Item>
          )}
          <Menu.Item key="6" onClick={activeEditTab}>
            <a href="account-details">Account Details</a>
          </Menu.Item>
          <Menu.Item key="7" onClick={activeBillingTab}>
            <a href="account-details">Billing</a>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="8" onClick={logout}>
            <div
              className="info-text fw-700 color-dark"
              onClick={(e) => e.preventDefault()}>
              Log Out
            </div>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
  return (
    <>
      <div
        className="profile-lg rounded-m"
        style={{
          backgroundImage: profileImg,
        }}></div>
      <Dropdown overlay={menu} trigger={['click']}>
        <a
          href="/#"
          className="profile-dropdown text-purple text-uppercase"
          onClick={(e) => e.preventDefault()}>
          Profile
        </a>
      </Dropdown>
    </>
  );
};

export default ProfileSettings;
