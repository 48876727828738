import React, {useState, useEffect} from 'react';
import {Button, Checkbox, Radio} from 'antd';
import {loadStripe} from '@stripe/stripe-js';
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {errorToastMessage} from '../../../../helpers/toastMessage';
import http from '../../../../helpers/http';
import Text from '../../../../components/Text/Text';
import {useDispatch} from 'react-redux';
import {getPaymentMethod} from '../../../../store/brandflow/action';
import useApi from '../../../../hooks/useApi';
import {toTitleCase} from '../../../../helpers/user';

const CheckoutCard = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const {campaignForm1, values, submitOffer} = props;
  const [loading, setLoading] = useState(false);
  const [name, setCardName] = useState('');
  const [saveCard, setSaveCard] = useState(true);
  const [defPM, setDefPM] = useState(null);
  const [enterCard, setEnterCard] = useState(null);
  const [cardLoading, setCardloading] = useState(true);
  const [errorMessage, setErrorNameMessage] = useState(false);
  const [PMs] = useApi('/stripe/payment-methods');

  useEffect(() => {
    if (PMs && PMs?.success) {
      if (PMs?.data && PMs?.data[0]) {
        setDefPM(PMs?.data[0]);
        setEnterCard(false);
        setTimeout(() => {
          setCardloading(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setCardloading(false);
          setEnterCard(true);
        }, 3000);
      }
    }
  }, [PMs]);

  const number = elements.getElement(CardNumberElement) || null;
  const expiryElement = elements.getElement(CardExpiryElement) || null;
  const cvc = elements.getElement(CardCvcElement) || null;
  if (number !== null) {
    number.on('change', function (event) {
      var displayError = document.getElementById('card-errors-number');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  if (expiryElement !== null) {
    expiryElement.on('change', function (event) {
      var displayError = document.getElementById('card-errors-expiry');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  if (cvc !== null) {
    cvc.on('change', function (event) {
      var displayError = document.getElementById('card-errors-cvc');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  // useEffect(() => {
  //   let tp = 0;
  //   values?.deliverables.forEach((deliverable) => {
  //     tp = tp + deliverable.compensation_cash;
  //   });
  //   setTotalPrice(tp);
  // }, [values]);

  useEffect(() => {
    dispatch(getPaymentMethod());
  }, [dispatch]);

  const onChange = (e) => {
    setSaveCard(e.target.checked);
  };

  const onCardChange = (e) => {
    if (e.target.value === 2) {
      setEnterCard(true);
    } else {
      setEnterCard(false);
    }
  };

  const pay = async (event) => {
    event.preventDefault();
    if (enterCard) {
      setErrorNameMessage(true);
      const number = elements.getElement(CardNumberElement);
      const {paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: number,
      });

      if (paymentMethod?.id) {
        setLoading(true);
        try {
          let response = await http.get(
            `/stripe/setup_intent?save_card=` + saveCard,
          );
          if (defPM === null) {
            await http.put(
              `/stripe/payment-method/${paymentMethod?.id}/attach`,
            );
          }
          const intentInfo = response.data;
          if (
            intentInfo?.success &&
            intentInfo?.data?.setupIntentClientSecret
          ) {
            submitOffer(values, campaignForm1?.id);
          }
        } catch (error) {
          errorToastMessage(error);
        }
      }
    } else {
      setLoading(true);
      try {
        let response = await http.get(
          `/stripe/setup_intent?save_card=` + saveCard,
        );

        const intentInfo = response.data;
        if (intentInfo?.success && intentInfo?.data?.setupIntentClientSecret) {
          submitOffer(values, campaignForm1?.id);
        }
      } catch (error) {
        errorToastMessage(error);
      }
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      setTimeout(() => {
        setLoading(false);
      }, 10000);
      return;
    }
  };

  return (
    <div className="checkout-wrapper">
      {cardLoading && (
        <div className="fw-700 title mb-2 mt-4 color-dark">
          Card details loading...
        </div>
      )}

      {!cardLoading && defPM && (
        <>
          <div className="card-options">
            <Radio.Group onChange={onCardChange} defaultValue={1}>
              <Radio value={1}>
                {defPM?.card?.brand &&
                  `${toTitleCase(defPM?.card?.brand)} ending in ${
                    defPM?.card?.last4
                  } 
            expiring ${defPM?.card?.exp_month}/${defPM?.card?.exp_year}`}
              </Radio>
              <Radio value={2}>Add new card</Radio>
            </Radio.Group>
          </div>
        </>
      )}
      {enterCard && !cardLoading && (
        <>
          <div className="brand-form mb-3">
            <span className="form-label d-block mb-1">Name on card</span>
            <input
              className="form-control checkout-payment-input-stripe"
              value={name}
              type="text"
              name="name"
              onChange={(e) => setCardName(e.target.value)}
              autoComplete="false"
            />
            {name.length === 0 && errorMessage && (
              <div className="text-danger fw-bold">Card name required</div>
            )}
          </div>
          {/*<div className="checkout-payment-form-item-cardelement">
        <CardElement
            options={{
              classes: {
                base: "checkout-payment-input-stripe",
              },
            }}
          /> 
        </div>*/}
          <div className="checkout-payment-form-item-cardelement mb-3">
            <span className="form-label d-block mb-1">Card number</span>
            <CardNumberElement
              options={{
                classes: {
                  base: 'checkout-payment-input-stripe',
                },
              }}
            />
            <div id="card-errors-number" className="text-danger fw-bold"></div>
          </div>
          <div className="row">
            <div className="col mb-4">
              <span className="form-label d-block mb-1">Expiration</span>
              <CardExpiryElement
                options={{
                  classes: {
                    base: 'checkout-payment-input-stripe',
                  },
                }}
              />
              <div
                id="card-errors-expiry"
                className="text-danger fw-bold"></div>
            </div>
            <div className="col mb-4">
              <span className="form-label d-block mb-1">CVC</span>
              <CardCvcElement
                options={{
                  classes: {
                    base: 'checkout-payment-input-stripe',
                  },
                }}
              />
              <div id="card-errors-cvc" className="text-danger fw-bold"></div>
            </div>
          </div>
          <div className="d-flex  text-center align-items-center">
            <Checkbox onChange={onChange} checked={saveCard}>
              Save card for later
            </Checkbox>
          </div>
        </>
      )}
      <div
        className="d-flex justify-content-center align-items-center text-center w-100"
        style={{padding: '0px 30px 20px'}}>
        <Text
          opacity="0.85"
          weight="500"
          size="15"
          lineHeight="20"
          textColor="#042237">
          Your credit card will not be charged until the offer is officially
          accepted. Once both parties accept, payments will be safeguarded in
          escrow and then released to athletes when deals have been fully
          completed.
        </Text>
      </div>
      <div className="checkout-payment-form-button-container">
        <Button
          type="primary"
          className="btn btn-save-var2 btn-lg mb-4"
          onClick={pay}
          loading={loading}
          // disabled={!cardValid}
        >
          CONFIRM AND SUBMIT OFFER
        </Button>
      </div>
    </div>
  );
};

const CheckoutCardWrapperOffer = (props) => {
  const [stripe, setStripe] = useState(null);
  const [stripeKEy, setStripeKey] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const data = await http.get('/stripe/client_secret');
      setStripeKey(data.data.data.key);
    };
    getData();
  }, []);

  useEffect(() => {
    const loadStripeHandler = async () => {
      if (stripeKEy) {
        const stripePromise = await loadStripe(stripeKEy);
        setStripe(stripePromise);
      }
    };
    loadStripeHandler();
  }, [stripeKEy]);

  // If no Stripe object, do not render the Stripe Element.
  if (!stripe) {
    return <p>Loading...</p>;
  }

  return (
    <Elements stripe={stripe}>
      <CheckoutCard {...props} />
    </Elements>
  );
};
export default CheckoutCardWrapperOffer;
