import React from 'react';
import {whyAthleteUpSection} from '../../../../../utils/staticStrings';
import '../features.scss';

export default function index() {
  const renderLists = () => {
    return whyAthleteUpSection.lists.map((ele, i) => {
      return (
        <div
          className="col-sm-24 col-md-20 col-sm-6 col-lg-4 col-xl-4 col-xxl-4 lists-item"
          key={i}>
          <div className="row ">
            <div className="col-2">
              <img src={ele.icon} className="imgIcon" alt="imgicon" />
            </div>
            <div className="col athlete-up-content">
              <div className="row sub-title">
                <div className="col">{ele.title}</div>
              </div>
              <div className="row texts mb-3">
                <div className="col">{ele.text}</div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="why-athlete-up">
      <div className="row center">
        <div className="col title">{whyAthleteUpSection.title}</div>
      </div>

      <div className="row lists">{renderLists()}</div>
    </div>
  );
}
