import React from 'react';
import Text from '../../../../../components/Text/Text';

const DealsTag = ({src, text}) => {
  return (
    <div className="d-flex align-items-center" style={{width: '300px'}}>
      <img src={src} width={20} height={20} alt="thumbnail" style={{marginRight: '10px'}} />
      <Text
        display="flex"
        weight="400"
        size="14"
        lineHeight="26"
        textColor="#435969">
        {text}
      </Text>
    </div>
  );
};

export default DealsTag;
