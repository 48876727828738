import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setHeader} from '../../../../../store/athleteflow/action';
import AppLayout from '../../../../../common/AppLayout';
import Stats from '../../../../../common/components/Stats';
import {List, Avatar} from 'antd';
import AppHeader from '../../../../../common/components/AppHeader';
import useApi from '../../../../../hooks/useApi';
import VideoThumbnail from '../../../../../common/components/VideoThumbnail';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import {handleSidebarTab} from '../../../../../store/users/actions';

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [statsData] = useApi(`/athlete/listing/dashboard/stats`);
  const [orderDataPayload] = useApi(`/athlete/order?page=1&pageSize=20`);
  const [topListing] = useApi(`/athlete/listing/top`);
  const {athleteDetails} = useSelector((state) => state.UsersReducer);
  let orderData = orderDataPayload?.data?.new_orders?.rows;

  useEffect(() => {
    if (history.location.pathname === '/athlete/dashboard') {
      dispatch(setHeader(false));
      dispatch(handleSidebarTab('dashboard'));
    }
  }, [history.location.pathname, dispatch]);

  // console.log(orderData, 'orderData');

  return (
    <AppLayout>
      <div className="bubble-bg"></div>
      {Object.keys(athleteDetails).length > 0 ? (
        <div className="content-wrapper">
          <AppHeader
            title={`Welcome ${athleteDetails?.name}!`}
            subTitle={
              <p className="font-20 color-muted mb-5 fw-600">
                You have
                <span className="text-purple mx-1">
                  {statsData?.data?.new_orders}
                </span>
                new orders and
                <span className="text-purple mx-1">
                  {statsData?.data?.custom_deals}
                </span>
                deals to review.
              </p>
            }
          />
          {statsData?.data && (
            <Stats
              title="Your stats"
              cardLabel1="Orders"
              cardLabel2="Custom deals"
              cardLabel4="Earnings this month"
              cardLabel3="Earnings all time"
              swap={true}
              label1Data={statsData?.data?.all_orders}
              label2Data={statsData?.data?.all_custom_deals}
              label3Data={statsData?.data?.all_earnings}
              label4Data={statsData?.data?.earnings_month}
              labelNewCount1={statsData?.data?.new_orders}
              labelNewCount2={statsData?.data?.custom_deals}
            />
          )}
          <div className="container-fluid my-5">
            <div className="row">
              <div className="col-xl-6 col-12 mb-4">
                <h6 className="font-m fw-bold color-muted text-uppercase ls-m mb-3 ">
                  New orders
                </h6>
                <div
                  id="scrollableDiv"
                  className="rouded custom-order-listing custom-listing  px-4 py-2"
                  style={{
                    height: 400,
                    overflow: 'auto',
                    padding: '0 16px',
                    borderRadius: '20px',
                    boxShadow: ' 0px 27px 90px rgba(0, 0, 0, 0.07)',
                  }}>
                  <List
                    dataSource={orderData}
                    renderItem={(item) => {
                      let cardListProduct =
                        item?.listing?.type === 'Experiences'
                          ? item?.listing?.listing_experience
                          : item?.listing?.listing_product;
                      return (
                        <List.Item key={item.id}>
                          <List.Item.Meta
                            avatar={
                              <Avatar
                                src={
                                  item?.user?.image_url
                                    ? item?.user?.image_url
                                    : backgroundStyle.defaultProfilePic
                                }
                              />
                            }
                            title={
                              <div className="d-flex align-items-center">
                                <a href="orders">{item?.user?.name}</a>
                                <span className="circle-dot mx-2"></span>
                                <div className="title">${item?.subtotal}</div>
                              </div>
                            }
                            description={cardListProduct?.title}
                          />
                          <div className="text-muted card-coloumn-right ps-0 ps-md-3">
                            {moment(item?.createdAt).fromNow(true)} ago
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <h6 className="font-m fw-bold color-muted text-uppercase ls-m mb-3">
                  Top Listings
                </h6>

                <div
                  id="scrollableDiv"
                  className="rouded px-4 py-2 custom-listing top-listing"
                  style={{
                    height: 400,
                    overflow: 'auto',
                    padding: '0 16px',
                    borderRadius: '20px',
                    boxShadow: ' 0px 27px 90px rgba(0, 0, 0, 0.07)',
                  }}>
                  <List
                    header={
                      <div className="d-flex  font-m">
                        <div>Listing</div>
                        <div className="ms-3">Description</div>
                        <div className="ms-auto">Orders</div>
                      </div>
                    }
                    dataSource={topListing?.data?.rows}
                    renderItem={(item) => {
                      let cardListProduct =
                        item?.type === 'Experiences'
                          ? item?.listing_experience
                          : item?.listing_product;

                      return (
                        <List.Item key={item.id}>
                          <List.Item.Meta
                            avatar={
                              <>
                                {item?.attachments[0]?.type === 'video' ? (
                                  <VideoThumbnail
                                    embedId={item?.attachments[0]?.url}
                                  />
                                ) : (
                                  <Avatar
                                    src={item?.attachments[0]?.url}
                                    alt="card-img"
                                    className="img-fluid object-fit-contain purchases-list-imag sm"
                                  />
                                )}
                              </>
                            }
                            //
                            title={
                              <a
                                href="my-shop"
                                className="text-truncate d-block">
                                {cardListProduct?.title}
                              </a>
                            }
                            description={cardListProduct?.description}
                          />
                          <div className="text-muted card-coloumn-right ps-0 ps-md-5">
                            {item?.order_count}
                            <span className="d-inline-block d-md-none ms-1">
                              Orders
                            </span>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mh-100-vh d-flex align-items-center justify-content-center">
          <Loader type="Bars" color="#00BFFF" height={50} width={100} />
        </div>
      )}
    </AppLayout>
  );
};

export default Dashboard;
