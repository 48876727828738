import React, {useState, useEffect} from 'react';

const InputCounter = (props) => {
  const {setQuantity, limted, orderLeft} = props;
  const [num, setNum] = useState(1);

  let MAxCount = limted === true ? orderLeft : 10;

  let orderCount = orderLeft - num;

  useEffect(() => {
    setQuantity(num);
  }, [num, setQuantity]);

  const incNum = () => {
    if (num < MAxCount) {
      setNum(Number(num) + 1);
    }
  };
  const decNum = () => {
    if (num > 1) {
      setNum(num - 1);
    }
  };

  const handleChange = (e) => {
    let value = Math.round(e.target.value);
    if (value === 0) {
      setNum(1);
    } else {
      setNum(value);
    }
  };
  return (
    <React.Fragment>
      {limted && (
        <>
          {(orderLeft <= 5 || orderCount <= 5) && (
            <span className="text-danger fw-700 font-sm text-end">
              Only {orderLeft} left
            </span>
          )}
        </>
      )}
      <div className="input-group custom-input-increment mt-auto">
        <div className="input-group-prepend">
          <button className="btn" type="button" onClick={decNum}>
            -
          </button>
        </div>
        <input
          type="number"
          className="form-control"
          value={num}
          onChange={handleChange}
        />
        <div className="input-group-prepend">
          <button className="btn" type="button" onClick={incNum}>
            +
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InputCounter;
