import React, {useCallback, useMemo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  setHeader,
  handleCreateListStatus,
  getShopListing,
  getStripeStatus,
  editStatusListing,
  editListDetails,
  editListDetailsStatus,
} from '../../../../../store/athleteflow/action';
import {getState} from '../../../../../store/brandflow/action';
import AppLayout from '../../../../../common/AppLayout';
import {Tabs} from 'antd';
import AppHeader from '../../../../../common/components/AppHeader';
import {useLocation} from 'react-router-dom';
import CustomModal from '../../../../../common/components/CustomModal';
import EditListing from './EditListing';
import {useHistory} from 'react-router-dom';
import {CardTheme} from '../../style';
import {backgroundStyle} from '../../../../../utils/staticStrings';
// import useApi from '../../../../../hooks/useApi';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';
import ListCardUI from './ListCardUI';
import useApi from '../../../../../hooks/useApi';
import http from '../../../../../helpers/http';
import {
  handleMessage,
  handleSidebarTab,
} from '../../../../../store/users/actions';

const MyShop = () => {
  const [visible, setVisible] = useState(false);
  const [editList, setEditList] = useState({});
  const [listType, setListType] = useState();
  const [embedCode, setEmbedCode] = useState('');
  const [mediaList, setMediaList] = useState();
  const [activeTab, setActiveTab] = useState('');
  const [urlValid, setUrlValid] = useState(true);
  const [deleteList, setDeleteList] = useState(false);
  const [cardListProduct, setCardListProduct] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [uspsBoxType] = useApi(`/athlete/listing/usps-box-types`);
  const [countries] = useApi('/assets/countries');
  const {
    shopListing,
    stripeStatus,
    isLoading,
    editLstStatus,
    message,
    membeShipExpired,
  } = useSelector((state) => state.AthleteModuleReducer);

  const {stateList} = useSelector((state) => state.BrandModuleReducer);
  const [listPayload, setListPayload] = useState({
    page: 1,
    pageSize: 10,
    status: '',
  });
  const callback = (key) => {
    if (key === 'listing') {
      setActiveTab('');
      setListPayload({...listPayload, status: ''});
    } else {
      setActiveTab(key);
      setListPayload({...listPayload, status: key});
    }
  };
  useEffect(() => {
    dispatch(getShopListing({...listPayload}));
  }, [listPayload, dispatch, deleteList]);

  useEffect(() => {
    if (typeof message?.text !== 'undefined' && message?.text) {
      if (message?.text.length > 0 && message?.text !== undefined) {
        toastMessage(message?.text);
      } else {
        toastMessage(message?.text[0]?.text);
      }
    }
  }, [message]);

  // useEffect(() => {
  //   if (error?.message?.success === false) {
  //     errorToastMessage(error?.message?.message[0]?.text);
  //   }
  // }, [error]);

  const setListingRenderPayload = (data) => {
    if (data === '') {
      setListPayload({...listPayload, status: ''});
    } else {
      setListPayload({...listPayload, status: activeTab});
    }
  };

  useEffect(() => {
    if (editLstStatus === true) {
      setVisible(false);
      toastMessage('Edited list successfully');
      setListingRenderPayload(activeTab);
    }
    /* eslint-disable-next-line */
  }, [editLstStatus, activeTab]);

  useEffect(() => {
    if (location.pathname === '/athlete/my-shop') {
      dispatch(setHeader(false));
      dispatch(handleCreateListStatus(false));
      dispatch(handleSidebarTab('my-shop'));
      dispatch(getState({country: 'US'}));
      dispatch(handleMessage(''));
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    dispatch(getStripeStatus());
  }, [dispatch]);

  const editListing = useCallback((id, status) => {
    dispatch(editStatusListing({id, status, listPayload}));
  }, [dispatch, listPayload]);

  const editListAction = (data) => {
    let payloadData = data;

    if (embedCode !== undefined && embedCode !== '') {
      payloadData = {...data, media: [...data.media, embedCode]};
    }
    // console.log(payloadData, 'payloadData');
    dispatch(
      editListDetails({
        payload: payloadData,
        type: listType,
        id: editList.id,
      }),
    );
  };

  const showModal = useCallback((data) => {
    setVisible(true);
    setEditList(data);
    dispatch(editListDetailsStatus(false));
    if (data.type === 'Experiences') {
      setListType('experience');
      setCardListProduct(data.listing_experience);
    } else {
      setListType('product');
      setCardListProduct(data.listing_product);
    }
    let result = data?.attachments.map((attachment) => {
      return attachment.type === 'image'
        ? attachment.url
        : setEmbedCode(attachment?.url);
    });

    const mediaArray = result.filter((element) => {
      return element !== undefined;
    });
    setMediaList(mediaArray);
  }, [dispatch, setMediaList, setEmbedCode, setVisible, setEditList, setListType, setCardListProduct]);

  const closeModal = useCallback(() => {
    setVisible(false);
    setMediaList([]);
    setEmbedCode();
    setEditList({});
  }, [setVisible, setMediaList, setEmbedCode, setEditList]);

  const deleteListMedia = (item) => {
    const deletedListMedia = mediaList.filter((list) => list !== item);
    setMediaList(deletedListMedia);
  };

  const getStateList = (val) => {
    if (val === 'US' || val === 'CA') {
      dispatch(getState({country: val}));
    }
  };

  const deleteListing = useCallback(async (id) => {
    http
      .delete(`/athlete/listing/${id}/`)
      .then((res) => {
        toastMessage(res.data.data);
        setDeleteList(true);
        closeModal();
      })
      .catch((err) => {
        setDeleteList(false);
        // message.error(err.response.data.detail);
        errorToastMessage(err.response.data.message);
      });
  }, [closeModal, setDeleteList]);

  const tabItems = useMemo(() => [
    {
      key: 'listing',
      label: 'ALL LISTINGS',
      children: (<ListCardUI
        isLoading={isLoading}
        shopListing={shopListing}
        showModal={showModal}
        editListing={editListing}
        listPayload={listPayload}
        setListPayload={setListPayload}
        activeTab={activeTab}
        stripeStatus={stripeStatus}
        deleteListing={deleteListing}
        membeShipExpired={membeShipExpired}
      />)
    },
    {
      key: 'active',
      label: 'ACTIVE',
      children: (<ListCardUI
        isLoading={isLoading}
        shopListing={shopListing}
        showModal={showModal}
        editListing={editListing}
        listPayload={listPayload}
        setListPayload={setListPayload}
        activeTab={activeTab}
        stripeStatus={stripeStatus}
        deleteListing={deleteListing}
        membeShipExpired={membeShipExpired}
      />)
    },
    {
      key: 'inactive',
      label: 'PAUSED',
      children: (<ListCardUI
        isLoading={isLoading}
        shopListing={shopListing}
        showModal={showModal}
        editListing={editListing}
        listPayload={listPayload}
        setListPayload={setListPayload}
        activeTab={activeTab}
        stripeStatus={stripeStatus}
        deleteListing={deleteListing}
        membeShipExpired={membeShipExpired}
      />)
    }
  ], [isLoading, shopListing, showModal, editListing, listPayload, setListPayload, activeTab, stripeStatus, deleteListing, membeShipExpired]);

  return (
    <AppLayout>
      <div className="bubble-bg"></div>
      <div className="content-wrapper">
        <AppHeader
          title="My shop"
          subTitle={
            <p className="font-20 color-muted mb-5 fw-600">
              View and edit all of your active shop listings.
            </p>
          }
        />

        {Object.keys(stripeStatus).length > 0 && (
          <>
            {((stripeStatus?.subscription_valid === false &&
              stripeStatus.stripe_account_valid === false) ||
              (stripeStatus?.subscription_valid === false &&
                stripeStatus.stripe_account_valid === true)) && (
              <CardTheme
                bgUrl={backgroundStyle.themeBg}
                bgUrlMob={backgroundStyle.themeBgMob}>
                <div className="ct-left">
                  <div className="ct-title">Publish your listings</div>
                  <div className="ct-content">
                    {stripeStatus?.subscription_valid === false &&
                    stripeStatus.stripe_account_valid === true ? (
                      <>
                        Your account is deactivated and your Shop and profile
                        are not visible to the public. Reactivate your account
                        today to publish your Shop and start and getting paid!
                      </>
                    ) : (
                      <>
                        Your shop is not published to the public yet. Start your
                        free trial today to publish your listings and start
                        engaging with fans and getting paid!
                      </>
                    )}
                  </div>
                </div>
                <div className="ct-btn-contain">
                  <button
                    className="btn btn-primary ms-auto ct-button text-nowrap"
                    onClick={() => {
                      history.push('/athlete/price-details');
                    }}
                    type="primary">
                    {stripeStatus?.subscription_valid === false &&
                    stripeStatus.subscription_activated_on !== null ? (
                      <>REACTIVATE MEMBERSHIP</>
                    ) : (
                      <> Publish & Start Trial</>
                    )}
                  </button>
                </div>
              </CardTheme>
            )}

            {stripeStatus?.subscription_valid === true &&
              stripeStatus.stripe_account_valid === false && (
                <CardTheme
                  bgUrl={backgroundStyle.themeBg}
                  bgUrlMob={backgroundStyle.themeBgMob}>
                  <div className="ct-left">
                    <div className="ct-title">Link your bank account</div>
                    <div className="ct-content">
                      Your subscription has been set up. Link your bank account
                      to complete the process and receive payments.
                    </div>
                  </div>
                  <div className="ct-btn-contain">
                    <button
                      className="btn btn-primary ms-auto ct-button"
                      onClick={() => {
                        history.push('/athlete/bank-detail-confirmation');
                      }}
                      type="primary">
                      Link your bank account
                    </button>
                  </div>
                </CardTheme>
              )}
          </>
        )}
        <button
          className="btn btn-tarnparant-purple d-lg-none d-block fw-800 mobile-add-listing"
          onClick={() => {
            history.push('/athlete/choose-listing-method');
          }}>
          + ADD LISTING
        </button>
        <div className="tab-shop">
          <button
            className="btn btn-tarnparant-purple d-none d-lg-block btn-wide fw-800"
            onClick={() => {
              history.push('/athlete/choose-listing-method');
            }}>
            + ADD NEW LISTING
          </button>
          <Tabs
            defaultActiveKey="listing"
            onChange={callback}
            className="custom-tabs"
            items={tabItems} />
        </div>
        <CustomModal
          title="Edit listing"
          visible={visible}
          width="710px"
          isLoading={false}
          className="edit-list-modal"
          onClickCancel={() => closeModal()}>
          <EditListing
            editList={editList}
            editListAction={editListAction}
            deleteListing={deleteListing}
            deleteListMedia={deleteListMedia}
            cardListProduct={cardListProduct}
            mediaList={mediaList}
            setMediaList={setMediaList}
            embedCode={embedCode}
            uspsBoxType={uspsBoxType?.data}
            setEmbedCode={setEmbedCode}
            urlValid={urlValid}
            setUrlValid={setUrlValid}
            countries={countries.data}
            getStateList={getStateList}
            stateList={stateList}
          />
        </CustomModal>
      </div>
    </AppLayout>
  );
};

export default MyShop;
