import React, {useEffect, useState, useCallback} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import './style.scss';
import {CloseOutlined} from '@ant-design/icons';
import Text from '../../../../../components/Text/Text';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import ProfileImage from '../../ProfileImage';
import DealsTag from './DealsTag';
import {setHeader} from '../../../../../store/athleteflow/action';
import {handleSidebarTab} from '../../../../../store/users/actions';
import AppLayout from '../../../../../common/AppLayout';
import Hr from '../../../../../components/Hr/index';
import DescriptionFooter from './DealDescription';
import Loader from 'react-loader-spinner';
import {
  AthleteCard,
  MobileLayoutViewView,
  DeskTopLayoutView,
  MobileDetailsContainer,
  MessageCard,
} from '../../../pages/style';
import moment from 'moment';
import {BackArrow, Tick} from '../../../../Athlete/components/Icons';
import {
  DealsContainer,
  MediaFlex,
  Deliverables,
  BackSection,
  ConfirmOrder,
  ConfirmDisputeOrder,
  ButtonsContainer,
  OrderContainer,
  DisputeOrder,
} from '../../style';
import {
  createCounterOfferBrand,
  getDealData,
  markDealDispute,
  updateDealCounterOfferStatus,
  updateDealStatus,
} from '../../../../../store/brandflow/action';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';
import CustomMessageChannel from '../../../../../../src/common/Messages/CustomMessageChannel';
import {CreateCampaign} from '../Campaigns/style';
import CompleteDeal from './CompleteDeal';
import http from '../../../../../helpers/http';
import DisputeDeal from './DisputeDeal';
import {MarkCompleteButton} from '../../../../Athlete/components/features/Deals/style';
import {formatPriceCommas} from '../../../../../helpers/user';
const Chat = require('twilio-chat');

const DealDetails = () => {
  const {dealId} = useParams();
  const {dealData, isLoading, dealStatusChanged, counterOfferCreated, error} =
    useSelector((state) => state.BrandModuleReducer);
  const {purchaserDetails} = useSelector((state) => state.UsersReducer);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  const [disputeModalVisible, setDisputeModalVisible] = useState(false);
  const [messagesList, setMessage] = useState([]);
  const [channelPrivate, setChanel] = useState(null);
  const [noOfDaysLeft, setNoOfDaysLeft] = useState('');
  const [loader, setLoader] = useState(true);
  const [dealCompletd, setDealComplete] = useState(false);
  const [participantsDetails, setParticipantDetails] = useState([]);
  const [tokenTwilio, setToken] = useState('');
  const [role, setRole] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDealData({
        id: dealId,
      }),
    );
  }, [dealId, dispatch]);

  const markReadDeal = useCallback(
    async (data) => {
      try {
        let url = `/deal/${dealData?.id}/mark-read`;
        await http.put(url);
      } catch (err) {
        console.log(err);
      }
    },
    [dealData?.id],
  );

  useEffect(() => {
    if (error?.message?.success === false) {
      errorToastMessage(error?.message?.message);
    }
  }, [error]);
  useEffect(() => {
    if (dealData && dealData?.id) {
      markReadDeal(dealData?.id);
    }
  }, [dealData, markReadDeal]);

  useEffect(() => {
    const userRole = localStorage.getItem('userRole');
    setRole(userRole);
  }, []);

  useEffect(() => {
    if (history.location.pathname === `/purchaser/deals/${dealId}`) {
      dispatch(setHeader(false));
      dispatch(handleSidebarTab('deals'));
      // triggerModal('success', 'Login success');
    }
  }, [history.location.pathname, dispatch, dealId]);

  const getToken = async () => {
    const response = await http.get(
      `/twilio/token?username=user${purchaserDetails?.id}`,
    );
    return response.data.data.token;
  };

  const createChannelFn = async () => {
    let token = '';
    setLoader(true);
    try {
      token = await getToken();
      setToken(token);
    } catch {
      throw new Error('unable to get token, please reload this page');
    }

    const client = await Chat.Client.create(token);

    client.on('tokenAboutToExpire', async () => {
      const token = await getToken();
      client.updateToken(token);
    });

    client.on('tokenExpired', async () => {
      const token = await getToken();
      client.updateToken(token);
    });

    try {
      const channel = await client.getChannelBySid(dealData?.channel_id);
      await http.put(`twilio/conversation/${dealData?.channel_id}/mark-read`);
      if (participantsDetails.length === 0) {
        let particpatantChannel = await http.get(
          `/twilio/conversation/${dealData?.channel_id}/participants`,
        );
        setParticipantDetails(particpatantChannel?.data?.data);
      }
      await joinChannel(channel);

      setChanel(channel);
      getMessages(channel);
      channel.on('messageAdded', function (message) {
        getMessages(channel);
        // setMessage([...messagesFromChannel?.items, message]);
      });
      setLoader(false);
    } catch {
      throw new Error('unable to create channel, please reload this page');
    }
  };

  const joinChannel = async (channel) => {
    if (channel.channelState.status !== 'joined') {
      await channel.join();
    }
  };
  const getMessages = async (channel) => {
    const messages = await channel.getMessages();
    setMessage(messages.items);
  };

  useEffect(() => {
    let initiateToken = true;
    if (
      initiateToken &&
      Object.keys(dealData).length > 0 &&
      dealData?.channel_id !== null &&
      purchaserDetails?.id !== undefined
    ) {
      createChannelFn(); // run it, run it
    }
    return () => {
      initiateToken = false;
    };
    /* eslint-disable-next-line */
  }, [dealData, purchaserDetails]);

  const sendMessageAction = (text) => {
    if (text && String(text).trim()) {
      channelPrivate && channelPrivate.sendMessage(text);
    }
  };

  useEffect(() => {
    if (dealStatusChanged === 'declined') {
      toastMessage('Deal cancelled');
      dispatch(getDealData({id: dealId}));
    } else if (dealStatusChanged === 'accepted') {
      toastMessage('Deal accepted');
      dispatch(getDealData({id: dealId}));
    } else if (dealStatusChanged === 'completed') {
      toastMessage('Deal marked complete');
      dispatch(getDealData({id: dealId}));
    } else if (dealStatusChanged === 'disputed') {
      toastMessage('Deal marked as dispute');
      dispatch(getDealData({id: dealId}));
    }
  }, [dealId, dispatch, dealStatusChanged]);

  useEffect(() => {
    if (counterOfferCreated === true) {
      toastMessage('Counter offer created');
      dispatch(getDealData({id: dealId}));
    }
  }, [dealId, dispatch, counterOfferCreated]);

  /* check order date between 4th day from completd data */
  useEffect(() => {
    if (dealData?.completed_on !== null) {
      let completedLastDate = moment(dealData?.completed_on)
        .add(4, 'days')
        .format('MMM Do');

      setNoOfDaysLeft(completedLastDate);
    }
  }, [dealData?.completed_on]);

  useEffect(() => {
    if (dealData?.status === 'completed') {
      let bannerShowTillDate = moment(dealData?.completed_on).add(4, 'days');
      if (
        !moment().isBetween(moment(dealData?.completed_on), bannerShowTillDate)
      ) {
        setDealComplete(true);
      }
    } else {
      setDealComplete(false);
    }
  }, [dealData]);

  const statusType = (value) => {
    if (value === 'completed') {
      return 'status-success ';
    } else if (value === 'in progress') {
      return 'status-progress';
    } else if (value === 'declined') {
      return 'cancel';
    } else if (value === 'application/offer') {
      return 'offer';
    } else if (value === 'disputed') {
      return 'disputed';
    }
  };

  const formatDate = (dateObj) => {
    return moment(dateObj).format('MMM DD,YYYY');
  };

  const deliverOn = (deliverable) => {
    const fulFillType = deliverable.fulfillment_type;
    if (fulFillType === 'Before Date') {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD YYYY',
      );
      return `Deliver before: ${fulFillDate}`;
    } else if (fulFillType === 'TBD') {
      return 'Delivery Date TBD';
    } else if (fulFillType === 'Specific Date') {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD YYYY',
      );
      return `Deliver on: ${fulFillDate}`;
    } else if (fulFillType === 'Within X Days after product received') {
      return `Within ${deliverable.fulfillment_days} days after product received`;
    } else {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD YYYY',
      );
      return `Deliver on: ${fulFillDate}`;
    }
  };

  const cancelOffer = () => {
    const payload = {
      status: 'declined',
    };
    dispatch(updateDealStatus(payload, dealData.id));
  };

  const markDealComplete = () => {
    setCompleteModalVisible(false);
    const payload = {
      status: 'completed',
    };
    dispatch(updateDealStatus(payload, dealData.id));
  };

  const markDealDisputed = () => {
    setDisputeModalVisible(false);
    dispatch(markDealDispute(dealData.id));
  };

  const createCounterOffer = (values) => {
    dispatch(createCounterOfferBrand(values, dealData.id));
  };

  const cancelCounterOffer = () => {
    const payload = {
      status: 'declined',
    };
    dispatch(updateDealCounterOfferStatus(payload, dealData.id));
  };

  const acceptCounterOffer = () => {
    const payload = {
      status: 'accepted',
    };
    dispatch(updateDealCounterOfferStatus(payload, dealData.id));
  };

  const startVideoCall = () => {
    if (tokenTwilio === '') {
      return;
    }

    channelPrivate.sendMessage(
      'in inviting you to join a video call happening now.',
      `/purchaser/video-call/${dealData?.channel_id}`,
    );

    window.open(`/purchaser/video-call/${dealData?.channel_id}`);
  };

  const displayDeliverables = (deliverables) => {
    return deliverables.map((deliverable, i) => (
      <MediaFlex key={i}>
        <DealsTag src={backgroundStyle?.mark} text={deliverable.type} />
        <DealsTag src={backgroundStyle?.clock} text={deliverOn(deliverable)} />
        <DealsTag
          src={backgroundStyle?.roundDollar}
          text={`Cash ($${formatPriceCommas(
            deliverable?.compensation_cash,
          )}) + Products ($${formatPriceCommas(
            deliverable?.compensation_product,
          )})`}
        />
      </MediaFlex>
    ));
  };

  return (
    <AppLayout contentClassname="applayout-mobile-view ">
      <div className="bubble-bg"></div>
      <BackSection
        mrLeft="18"
        onClick={() => {
          if (history.length > 2) {
            history.goBack();
          } else {
            history.push(`/purchaser/deals`);
          }
        }}>
        <BackArrow />
        <span className="ms-2">Back</span>
      </BackSection>
      {isLoading && (
        <div className="loader">
          <Loader type="Bars" color="#00BFFF" height={50} width={50} />
        </div>
      )}

      {!isLoading && Object.keys(dealData)?.length > 0 && (
        <>
          <MobileLayoutViewView>
            {dealData?.status === 'disputed' && (
              <div className="disputed my-3">
                Successful deal completion is currently disputed. Admin will be
                reaching out to both parties individually to discuss the
                details.
              </div>
            )}
            <MobileDetailsContainer>
              <div className="d-flex align-items-center mt-3">
                <Text
                  display="flex"
                  weight="700"
                  size="36"
                  lineHeight="55"
                  smSize="30"
                  smLineHeight="40"
                  mrRight="15"
                  textColor="#042237">
                  Deal #{dealData?.id}
                </Text>
                <span className={`${statusType(dealData?.status)} titlecase`}>
                  {role === 'fan' && dealData?.status === 'application/offer'
                    ? 'offer'
                    : dealData?.status}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <ProfileImage
                  width={32}
                  imgWidth={32}
                  imgContainerClassName="purchaser"
                  height={32}
                  src={dealData?.athlete?.image_url}
                />
                <Text
                  display="block"
                  weight="500"
                  size="15"
                  mrLeft="10"
                  mrTop="10"
                  lineHeight="20"
                  smLineHeight="20"
                  textColor="#435969">
                  <div>{`Deal for ${dealData?.athlete?.name}`}</div>
                  <div>Created {formatDate(dealData?.createdAt)}</div>
                </Text>
              </div>
              <div className="mt-3">
                {dealData.status === 'in progress' && (
                  <MarkCompleteButton
                    className="d-block d-lg-none"
                    onClick={() => {
                      setCompleteModalVisible(true);
                    }}>
                    <Tick />
                    <span className="ms-2">Mark as complete</span>
                  </MarkCompleteButton>
                )}
              </div>
            </MobileDetailsContainer>

            {/* Used when deal marked completed by athlete */}
            {dealData?.athlete_status === 'completed' &&
              dealData?.status !== 'completed' &&
              dealData?.status !== 'disputed' && (
                <ConfirmOrder>
                  <OrderContainer>
                    <Text
                      display="flex"
                      weight="600"
                      size="16"
                      lineHeight="26"
                      textColor="#ffffff">
                      Athlete marked deal as completed.
                    </Text>
                    <Text
                      display="flex"
                      weight="400"
                      size="12"
                      lineHeight="20"
                      textColor="#ffffff">
                      You have 4 days (until {noOfDaysLeft}) to confirm or
                      dispute this deal
                    </Text>
                  </OrderContainer>
                  <ButtonsContainer>
                    <DisputeOrder
                      onClick={() => {
                        setDisputeModalVisible(true);
                      }}>
                      Dispute deal
                    </DisputeOrder>
                    <ConfirmDisputeOrder
                      onClick={() => {
                        setCompleteModalVisible(true);
                      }}>
                      Confirm deal completed
                    </ConfirmDisputeOrder>
                  </ButtonsContainer>
                </ConfirmOrder>
              )}

            <MobileDetailsContainer>
              <DealsContainer>
                <MediaFlex>
                  <Text
                    display="flex"
                    weight="700"
                    size="20"
                    lineHeight="27"
                    textColor="#042237">
                    {dealData?.title}
                  </Text>
                  <Text
                    display="flex"
                    weight="800"
                    size="20"
                    className="mt-2 mb-2"
                    lineHeight="27"
                    textColor="#042237">
                    $
                    {dealData?.counter_offers.length > 0
                      ? formatPriceCommas(
                          dealData?.counter_offers[0].total_price,
                        )
                      : formatPriceCommas(dealData?.total_price)}
                  </Text>
                </MediaFlex>
                <Hr mrBottom="20px" mrTop="20px" />
                <div className="d-flex flex-column">
                  <Text
                    display="flex"
                    weight="700"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    Description
                  </Text>
                  <Text
                    display="flex"
                    weight="400"
                    size="16"
                    lineHeight="26"
                    className="white-pre-wrap word-break"
                    textColor="#042237">
                    {dealData?.counter_offers.length > 0
                      ? dealData?.counter_offers[0].description
                      : dealData?.description}
                  </Text>
                </div>
                <Deliverables>
                  <Text
                    display="flex"
                    weight="700"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    Deliverables
                  </Text>
                  {dealData?.counter_offers.length > 0 &&
                    displayDeliverables(
                      dealData?.counter_offers[0].deliverables,
                    )}
                  {dealData?.counter_offers.length === 0 &&
                    displayDeliverables(dealData?.deliverables)}
                </Deliverables>
                <Hr mrBottom="20px" mrTop="20px" />
                <DescriptionFooter
                  dealData={dealData}
                  cancelOffer={cancelOffer}
                  acceptCounterOffer={acceptCounterOffer}
                  createCounterOffer={createCounterOffer}
                  cancelCounterOffer={cancelCounterOffer}
                />
              </DealsContainer>
            </MobileDetailsContainer>
          </MobileLayoutViewView>
          <DeskTopLayoutView>
            {dealData?.status === 'disputed' && (
              <div className="disputed my-3">
                Successful deal completion is currently disputed. Admin will be
                reaching out to both parties individually to discuss the
                details.
              </div>
            )}
            <AthleteCard>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Text
                    display="flex"
                    weight="700"
                    size="36"
                    lineHeight="55"
                    smSize="30"
                    smLineHeight="40"
                    mrRight="15"
                    textColor="#042237">
                    Deal #{dealData?.id}
                  </Text>
                  <span className={`${statusType(dealData?.status)} titlecase`}>
                    {role === 'fan' && dealData?.status === 'application/offer'
                      ? 'offer'
                      : dealData?.status}
                  </span>
                </div>
                <div>
                  {dealData.status === 'in progress' && (
                    <MarkCompleteButton
                      className="d-none d-lg-block"
                      onClick={() => {
                        setCompleteModalVisible(true);
                      }}>
                      <Tick />
                      <span className="ms-2">Mark as complete</span>
                    </MarkCompleteButton>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <ProfileImage
                  width={32}
                  imgWidth={32}
                  imgContainerClassName="purchaser"
                  height={32}
                  borderRadius="7px"
                  src={dealData?.athlete?.image_url}
                />
                <Text
                  display="flex"
                  weight="500"
                  size="14"
                  mrLeft="5"
                  textColor="#435969">
                  <span className="me-2">{`Deal for ${dealData?.athlete?.name}`}</span>
                  |
                  <span className="ms-2">
                    Created&nbsp;
                    {formatDate(dealData?.createdAt)}
                  </span>
                </Text>
              </div>

              {/* Athlete marks deal as complete */}
              {dealData?.athlete_status === 'completed' &&
                dealData?.status !== 'completed' &&
                dealData?.status !== 'disputed' && (
                  <ConfirmOrder>
                    <OrderContainer>
                      <Text
                        display="flex"
                        weight="600"
                        size="16"
                        lineHeight="26"
                        textColor="#ffffff">
                        Athlete marked deal as completed.
                      </Text>
                      <Text
                        display="flex"
                        weight="400"
                        size="12"
                        lineHeight="20"
                        textColor="#ffffff">
                        You have 4 days (until {noOfDaysLeft}) to confirm or
                        dispute this deal
                      </Text>
                    </OrderContainer>
                    <ButtonsContainer>
                      <DisputeOrder
                        onClick={() => {
                          setDisputeModalVisible(true);
                        }}>
                        Dispute deal
                      </DisputeOrder>
                      <ConfirmDisputeOrder
                        onClick={() => {
                          setCompleteModalVisible(true);
                        }}>
                        Confirm deal completed
                      </ConfirmDisputeOrder>
                    </ButtonsContainer>
                  </ConfirmOrder>
                )}

              <DealsContainer>
                <MediaFlex
                  style={{justifyContent: 'space-between', padding: '0 30px'}}>
                  <Text
                    display="flex"
                    weight="700"
                    size="20"
                    lineHeight="27"
                    textColor="#042237">
                    {dealData?.title}
                  </Text>
                  <Text
                    display="flex"
                    weight="800"
                    size="20"
                    className="mt-2 mb-2"
                    lineHeight="27"
                    textColor="#042237">
                    $
                    {dealData?.counter_offers.length > 0
                      ? formatPriceCommas(
                          dealData?.counter_offers[0].total_price,
                        )
                      : formatPriceCommas(dealData?.total_price)}
                  </Text>
                </MediaFlex>
                <Hr mrBottom="10px" mrTop="10px" />
                <div className="d-flex flex-column" style={{padding: '0 30px'}}>
                  <Text
                    display="flex"
                    weight="700"
                    size="16"
                    lineHeight="26"
                    className="mb-2"
                    textColor="#042237">
                    Description
                  </Text>
                  <Text
                    display="flex"
                    weight="400"
                    size="16"
                    lineHeight="26"
                    className="white-pre-wrap"
                    textColor="#042237">
                    {dealData?.counter_offers.length > 0
                      ? dealData?.counter_offers[0].description
                      : dealData?.description}
                  </Text>
                </div>
                <Deliverables>
                  <Text
                    display="flex"
                    weight="700"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    Deliverables
                  </Text>
                  {dealData?.counter_offers.length > 0 &&
                    displayDeliverables(
                      dealData?.counter_offers[0]?.deliverables,
                    )}
                  {dealData?.counter_offers.length === 0 &&
                    displayDeliverables(dealData?.deliverables)}
                </Deliverables>
                <Hr mrBottom="20px" mrTop="20px" />
                <DescriptionFooter
                  dealData={dealData}
                  cancelOffer={cancelOffer}
                  createCounterOffer={createCounterOffer}
                  acceptCounterOffer={acceptCounterOffer}
                  cancelCounterOffer={cancelCounterOffer}
                />
              </DealsContainer>
            </AthleteCard>
          </DeskTopLayoutView>
          {dealData !== undefined && dealData.channel_id !== null && (
            <div className="px-4 px-md-0">
              <MessageCard>
                <>
                  {Object.keys(purchaserDetails).length > 0 && (
                    <CustomMessageChannel
                      messages={messagesList}
                      loader={loader}
                      participantsDetails={participantsDetails}
                      channelProxy={channelPrivate}
                      enableAttachMent={true}
                      enableVideo={true}
                      startVideoCall={startVideoCall}
                      sendMessageAction={sendMessageAction}
                      loadingChannel={false}
                      messageStatus={
                        dealCompletd ||
                        dealData?.status === 'declined' ||
                        dealData?.purchaser_status === 'completed'
                      }
                    />
                  )}
                </>
              </MessageCard>
            </div>
          )}
        </>
      )}

      {completeModalVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span style={{textTransform: 'capitalize'}}>
                Mark deal #{dealData?.id} as complete
              </span>
            </div>
          }
          visible={completeModalVisible}
          onCancel={() => {
            setCompleteModalVisible(false);
          }}
          footer={null}>
          <CompleteDeal markDealComplete={markDealComplete} />
        </CreateCampaign>
      )}

      {disputeModalVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span style={{textTransform: 'capitalize'}}>
                Mark deal #{dealData?.id} as disputed
              </span>
            </div>
          }
          visible={disputeModalVisible}
          onCancel={() => {
            setDisputeModalVisible(false);
          }}
          footer={null}>
          <DisputeDeal markDisputed={markDealDisputed} orderType="deal" />
        </CreateCampaign>
      )}
    </AppLayout>
  );
};

export default DealDetails;
