import React from 'react';
import {Lock, OpenCampaign} from './CampaignIcons';
import '../../../../../assets/scss/_campaign.scss';
import {RequiredCriteriaOpen} from './style';
import {useHistory} from 'react-router-dom';
import {Clock, Dollar, Tag} from '../../Icons';
import moment from 'moment';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import {formatPriceCommas} from '../../../../../helpers/user';

const CampaignCard = ({campaignDetails, invited, applied}) => {
  const history = useHistory();
  const windowSize = window.innerWidth;

  const deliverOn = (deliverable) => {
    const fulFillType = deliverable.fulfillment_type;
    if (fulFillType === 'Before Date') {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD YYYY',
      );
      return `Deliver before: ${fulFillDate}`;
    } else if (fulFillType === 'TBD') {
      return 'Delivery Date TBD';
    } else if (fulFillType === 'Specific Date') {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD YYYY',
      );
      return `Deliver on: ${fulFillDate}`;
    } else if (fulFillType === 'Within X Days after product received') {
      return `Within ${deliverable.fulfillment_days} days after product received`;
    }
  };

  const showDeliverables = (campaignDetails) => {
    if (
      campaignDetails?.deliverables &&
      campaignDetails?.deliverables.length > 0
    ) {
      return campaignDetails?.deliverables.map((deliverable, idx) => (
        <div key={idx} className="d-xl-flex cam-deliverable">
          <div className="me-2 deliverable-item">
            <span className="me-2">
              <Tag />
            </span>
            {deliverable?.type}
          </div>
          <div className="me-2 deliverable-item">
            <span className="me-2">
              <Clock />
            </span>
            {deliverOn(deliverable)}
          </div>
          <div className="me-2 deliverable-item">
            <span className="me-2">
              <Dollar />
            </span>
            {`Cash ($${formatPriceCommas(
              deliverable?.compensation_cash,
            )}) + Products ($${formatPriceCommas(
              deliverable?.compensation_product,
            )})`}
          </div>
        </div>
      ));
    }
  };

  const getSports = (campaignData) => {
    let allowedSports = campaignData.allowed_sports;
    let sportsList = '';
    allowedSports.forEach((sportObj, idx) => {
      if (idx !== allowedSports.length - 1) {
        sportsList = sportsList + ' ' + sportObj?.name + ' ,';
      } else sportsList = sportsList + ' ' + sportObj.name;
    });
    return sportsList;
  };

  const showCriteria = (campaignData) => {
    if (campaignData?.type === 'private') {
      return (
        <div className="d-flex">
          <span className="me-2 titlecase d-none d-lg-flex fw-800">
            Required Criteria:{' '}
          </span>
          <span className="fw-500">
            Private Campaign - only personally invited Athletes are allowed to
            apply
          </span>
        </div>
      );
    } else if (campaignData?.type === 'open') {
      if (
        campaignData?.criteria &&
        Object.keys(campaignData?.criteria).length > 0
      ) {
        let criteria = campaignData.criteria;
        return (
          <>
            <span className="font-bold">
              <span className="me-2 titlecase d-none d-lg-flex fw-700">
                Required Criteria:{' '}
              </span>
              <span className="me-2 titlecase">
                {criteria?.athlete_type
                  ? `${criteria.athlete_type} athletes`
                  : 'Any athlete type'}
              </span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">
                {criteria?.sports &&
                campaignData?.allowed_sports &&
                campaignData?.allowed_sports?.length > 0
                  ? getSports(campaignData)
                  : 'Any sports'}
              </span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">
                {criteria?.gender ? `${criteria.gender}` : 'Any gender'}
              </span>
              <span className="me-2">|</span>
              {windowSize > 992 &&
                criteria?.locations &&
                criteria?.locations.length > 24 && (
                  <>
                    {' '}
                    <span>
                      {`${criteria.locations.splice(
                        0,
                        criteria.locations.length / 2,
                      )} `}
                    </span>
                    <div>{`${criteria.locations.splice(
                      -criteria.locations.length / 2,
                    )} `}</div>
                  </>
                )}
              {windowSize > 992 &&
                criteria?.locations &&
                criteria?.locations.length < 24 && (
                  <span>{`${criteria.locations}`}</span>
                )}
              {/* <span className="me-2 titlecase">
                {criteria?.locations
                  ? `${criteria.locations}`
                  : 'Anywhere located'}
              </span> */}
              {windowSize <= 992 &&
                criteria?.locations &&
                criteria?.locations.length > 3 && (
                  <span>{`${criteria.locations.splice(0, 3)} ... `}</span>
                )}
              {windowSize <= 992 &&
                criteria?.locations &&
                criteria?.locations.length <= 3 && (
                  <span>{`${criteria.locations}`}</span>
                )}
              {criteria?.locations === null && (
                <span className="me-2">Anywhere Located</span>
              )}
              <span className="me-2">|</span>
              <span className="me-2 titlecase">
                {criteria?.social_reach
                  ? showSocialReach(criteria?.social_reach)
                  : 'Any SM reach'}
              </span>
            </span>
          </>
        );
      } else {
        return (
          <>
            <span className="font-bold">
              <span className="me-2 titlecase fw-700 d-none d-lg-flex">
                Required Criteria:{' '}
              </span>
              <span className="me-2 titlecase">Any athlete type</span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">Any sports</span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">Any gender</span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">Anywhere located</span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">Any SM reach</span>
              <span className="me-2">|</span>
            </span>
          </>
        );
      }
    }
  };

  const showSocialReach = (social_reach) => {
    return (
      <>
        {social_reach?.total && (
          <>
            <span className="me-2 titlecase">
              Total SM reach: &nbsp;
              {social_reach?.total.map((val, idx) => {
                if (idx !== social_reach.total.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {campaignDetails && Object.keys(campaignDetails)?.length > 0 && (
        <div className={`campaign-card ${invited ? '' : 'campaign-open'}`}>
          <div className="d-xl-flex justify-content-evenly">
            <div className="d-lg-block d-none">
              <img
                width="50"
                height="50"
                src={campaignDetails.image_url}
                alt="Campaign icon"
                className="rounded-s"></img>
            </div>
            <div className="d-block ms-0 ms-xl-4 color-dark campaign-content">
              <div className="d-flex d-lg-none mb-2 align-items-center">
                <img
                  src={
                    campaignDetails?.user?.purchaser?.logo
                      ? campaignDetails?.user?.purchaser?.logo
                      : backgroundStyle?.defaultProfilePic
                  }
                  className="radius-m"
                  alt="Campaign icon"
                  height={40}
                  width={40}></img>
                <div className="ms-3">
                  {campaignDetails?.type === 'open' ? (
                    <OpenCampaign />
                  ) : (
                    <Lock />
                  )}
                  <span className="fw-500 font-15 ms-2">
                    {`${
                      campaignDetails?.type === 'open' ? 'Open' : 'Private'
                    } Campaign by `}
                    {campaignDetails?.user?.purchaser?.company}
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <div className="fw-700 campaign-title mb-lg-1">
                    {campaignDetails.title}
                  </div>
                  <div className="d-none d-lg-block">
                    {campaignDetails?.type === 'open' ? (
                      <OpenCampaign />
                    ) : (
                      <Lock />
                    )}
                    <span className="fw-500 font-15 ms-2">
                      {`${
                        campaignDetails?.type === 'open' ? 'Open' : 'Private'
                      } Campaign by `}
                      {campaignDetails?.user?.purchaser?.company}
                    </span>
                  </div>
                </div>
                <div className="d-none d-lg-block">
                  <button
                    className="view-apply-button fw-700"
                    onClick={() => {
                      history.push('/athlete/campaigns/' + campaignDetails.id);
                    }}>
                    {applied ? 'View' : 'View & Apply'}
                  </button>
                </div>
              </div>
              <div className="campaign-divider mt-4"></div>
              <div className="mt-3 font-m">
                <div className="fw-700 mb-2">Deliverables</div>
                {showDeliverables(campaignDetails)}
              </div>
              <RequiredCriteriaOpen>
                <br className="d-block d-lg-none" />
                <div className="d-block d-lg-none fw-800">
                  Required Criteria:
                </div>
                {/* <br className="d-block d-lg-none" /> */}
                {showCriteria(campaignDetails)}
              </RequiredCriteriaOpen>
              <div className="d-block d-lg-none mt-3">
                <button
                  className="view-apply-button fw-700"
                  onClick={() => {
                    history.push('/athlete/campaigns/' + campaignDetails.id);
                  }}>
                  {applied ? 'View' : 'View & Apply'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CampaignCard;
