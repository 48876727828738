import {useEffect} from 'react';
import http from '../helpers/http';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {twiliotoken} from '../store/athleteflow/action';
const Chat = require('twilio-chat');

export default function useTwilioVideo(channel_id, userId) {
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [loader, setLoader] = useState(false);
  const [messagesList, setMessage] = useState([]);
  const [participantsDetails, setParticipantDetails] = useState([]);
  const [channelPrivate, setChanel] = useState(null);

  const getToken = async () => {
    const response = await http.get(`/twilio/token?username=user${userId}`);
    return response.data.data.token;
  };

  const createChannelFn = async () => {
    let token = '';
    setLoader(true);
    try {
      token = await getToken();
      setToken(token);
      dispatch(twiliotoken(token));
    } catch {
      throw new Error('unable to get token, please reload this page');
    }
    const client = await Chat.Client.create(token);

    client.on('tokenAboutToExpire', async () => {
      console.log('tokken about to expire');
      const token = await getToken();
      client.updateToken(token);
    });

    client.on('tokenExpired', async () => {
      console.log('token expired');
      const token = await getToken();
      client.updateToken(token);
    });

    try {
      const channel = await client.getChannelBySid(channel_id);
      setMessage();
      await joinChannel(channel);
      if (participantsDetails.length === 0) {
        let particpatantChannel = await http.get(
          `/twilio/conversation/${channel_id}/participants`,
        );
        setParticipantDetails(particpatantChannel?.data?.data?.users);
      }
      setChanel(channel);
      getMessages(channel);
      channel.on('messageAdded', function (message) {
        getMessages(channel);
      });
      setLoader(false);
    } catch {
      throw new Error('unable to create channel, please reload this page');
    }
  };

  const joinChannel = async (channel) => {
    if (channel.channelState.status !== 'joined') {
      await channel.join();
    }
  };

  const getMessages = async (channel) => {
    const messages = await channel.getMessages();
    setMessage(messages.items);
  };

  useEffect(() => {
    let initiateToken = true;
    if (initiateToken && userId !== undefined && channel_id !== undefined) {
      createChannelFn(); // run it, run it
    }
    return () => {
      initiateToken = false;
    };
    /* eslint-disable-next-line */
  }, [channel_id, userId]);

  return {
    loader,
    token,
    messagesList,
    channelPrivate,
    participantsDetails,
  };
}
