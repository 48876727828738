import {Table, ConfigProvider, Empty} from 'antd';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {formatPrice, formatPriceCommas} from '../../../../../helpers/user';

const EarningsTable = (props) => {
  const {loading, earningData, onChange, count} = props;
  const history = useHistory();

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: 'ps-4',
      width: '160px',
      render: (text) => <span>{moment(text).format('MMM DD,YYYY')}</span>,
    },
    {
      title: 'Transaction',
      key: 'income',
      render: (record) => {
        return (
          <>
            <div className="text-muted d-flex mb-2 fw-600 text-nowrap">
              {record.action !== 'Funds withdrawal' ? (
                <>
                  {record?.deal === null ? (
                    <>
                      Income &nbsp;(
                      <div
                        className="cursor-pointer text-purple text-decoration-underline"
                        onClick={() =>
                          history.push(`order/details/${record?.order_id}`)
                        }>
                        Order #{record?.order_id}
                      </div>
                      )
                    </>
                  ) : (
                    <>
                      Income &nbsp;(
                      <div
                        className="cursor-pointer text-purple text-decoration-underline"
                        onClick={() =>
                          history.push(`deals/${record?.deal_id}`)
                        }>
                        Deal #{record?.deal_id}
                      </div>
                      )
                    </>
                  )}
                </>
              ) : (
                <>Funds withdrawn</>
              )}
            </div>
          </>
        );
      },
    },

    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        let recordName;
        if (record.deal === null) {
          recordName = record?.order?.title;
        } else {
          recordName = record?.deal?.title;
        }
        return <div className="w-200">{recordName}</div>;
      },
    },
    {
      title: 'Category/Subcategory',
      key: 'category',
      render: (record) => {
        let recordCategory;
        let recordSubcategory;
        if (record.deal === null) {
          let cardListProduct =
            record?.order?.listing?.type === 'Experiences'
              ? record?.order?.listing?.listing_experience
              : record?.order?.listing?.listing_product;

          recordSubcategory = cardListProduct?.subcategory?.name;
          recordCategory = cardListProduct?.category?.name;
        } else {
          recordCategory = record?.deal?.deliverables[0]?.type;
        }
        return (
          <div>
            {recordCategory} {recordSubcategory ? `/${recordSubcategory}` : ''}
          </div>
        );
      },
    },
    {
      title: 'Product Income',
      key: 'compensation_product',
      render: (record) => {
        let key = record?.compensation_product;
        return (
          <>
            {record.action !== 'Funds withdrawal' ? (
              <span>
                {key === 0 ? '$0.00' : `$${formatPrice(parseFloat(key))}`}
              </span>
            ) : (
              <span></span>
            )}
          </>
        );
      },
    },
    {
      title: 'Cash Income',
      key: 'compensation_cash',
      render: (record) => {
        let compensationCash;
        if (record.deal === null) {
          compensationCash = record?.price;
        } else {
          compensationCash = record?.compensation_cash;
        }

        return (
          <>
            {record.action !== 'Funds withdrawal' ? (
              <span>
                {compensationCash === 0
                  ? '$0.00'
                  : `$${formatPrice(parseFloat(compensationCash))}`}
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      title: 'Processing Fees',
      key: 'processing_fee',
      render: (record) => {
        let text = record?.processing_fee;
        return (
          <>
            {record.action !== 'Funds withdrawal' ? (
              <span>{text === 0 ? '$0.00' : `${formatPriceCommas(text)}`}</span>
            ) : (
              <span></span>
            )}
          </>
        );
      },
    },
    {
      title: 'Net Income',
      key: 'net_income',
      render: (record) => {
        let text = record.net_income;
        return (
          <>
            {record.action !== 'Funds withdrawal' ? (
              <span>
                {text === 0 ? '$0.00' : `$${formatPrice(parseFloat(text))}`}
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      title: 'Balance Change',
      key: 'balance_change',
      render: (record) => {
        const text = record.balance_change;
        return (
          <>
            {record.action !== 'Funds withdrawal' ? (
              <span className="color-green fw-700 text-nowrap">
                {formatPriceCommas(text)}
              </span>
            ) : (
              <span className="color-red fw-700 text-nowrap">
                -{formatPriceCommas(text)}
              </span>
            )}
          </>
        );
      },
    },
  ];

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No earnings found"
        />
      )}>
      <div className="table-responsive">
        <Table
          className="custom-table border-none earning-table"
          columns={columns}
          loading={loading}
          dataSource={earningData}
          onChange={onChange}
          // rowKey={earningData.id}
          rowClassName={(record) =>
            record.status === 'active' ? 'active' : 'inactive'
          }
          rowKey={(record) => record.id}
          onRow={(record) => ({
            // onClick: () => {
            //   if (record.id) {
            //     if (item === 'purchaser') {
            //       history.push(`dashboard/order/${record.id}`);
            //     } else {
            //       history.push(`order/details/${record.id}`);
            //     }
            //   }
            // },
          })}
          pagination={{
            pageSize: 10,
            total: count,
            hideOnSinglePage: true,
            showSizeChanger: false,
            // current: current,
          }}
        />
      </div>
    </ConfigProvider>
  );
};

export default EarningsTable;
