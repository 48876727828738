import React from 'react';

function TermsService() {
  const scrollDescription = (e) => {
    e.preventDefault();
    let idAttribute = e.target.getAttribute('href');
    let id = idAttribute.slice(1);
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const scrollTop = (e) => {
    e.preventDefault();
    document.getElementById('terms_condition').scrollIntoView({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div
        className="container pricing-section main-content"
        id="terms_condition">
        <div className="container center title mx-auto px-md-5 px-0 w-xl-80 mb-5 pb-md-5 pb-0">
          Terms of Service
        </div>
      </div>
      <div className="scroll-top" onClick={scrollTop}>
        <div className="up-arrow"></div>
      </div>
      <div className="bg-white p-md-5 p-0 terms-condition-text">
        <div className="container">
          <div className=" title mx-auto px-md-5 px-3 mw-80 mb-5 color-dark text-id font-15 color-dark">
            <h2 className="font-l pb-3 pt-md-0 pt-5 fw-700">
              <div className="mb-3">Terms of Service</div>
              <div className="font-m mt-2 fw-700">
                Last updated April 27, 2023
              </div>
            </h2>
            <p>
              Welcome to AthleteUp! We&rsquo;re glad you&rsquo;re here. Before
              using our service, it is important to understand our terms,
              policies, and rules.
            </p>
            <p>
              <b>Contents</b>
            </p>
            <ol>
              <li>
                <a
                  href="#1"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Agreement to Terms
                </a>
              </li>
              <li>
                <a
                  href="#2"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Supplemental Terms and Modification.
                </a>
              </li>
              <li>
                <a
                  href="#3"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Eligibility
                </a>
              </li>
              <li>
                <a
                  href="#4"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Site Summary
                </a>
              </li>
              <li>
                <a
                  href="#5"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  AthleteUp Shop: General Overview
                </a>
              </li>
              <li>
                <a
                  href="#6"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  AthleteUp Shop: Detailed Overview of Marketplace Listings
                </a>
              </li>
              <li>
                <a
                  href="#7"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Marketplace Listings – Prohibited Items
                </a>
              </li>
              <li>
                <a
                  href="#8"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Orders for Digital Products: Usage Rights
                </a>
              </li>
              <li>
                <a
                  href="#9"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Orders for Business / Commercial Use: Additional Terms Apply
                </a>
              </li>
              <li>
                <a
                  href="#10"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  AthleteUp Deals: Key Terms & General Overview
                </a>
              </li>
              <li>
                <a
                  href="#11"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  AthleteUp Deals: Standard Deal Terms{' '}
                </a>
              </li>
              <li>
                <a
                  href="#12"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Dispute Resolution Process
                </a>
              </li>
              <li>
                <a
                  href="#13"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Continued Disputes Between Athletes and Buyers
                </a>
              </li>
              <li>
                <a
                  href="#14"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Disputes With AthleteUp
                </a>
              </li>
              <li>
                <a
                  href="#15"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Physical Activity Liability Waiver
                </a>
              </li>
              <li>
                <a
                  href="#16"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  User Representations
                </a>
              </li>
              <li>
                <a
                  href="#17"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  User Registration and Account Security
                </a>
              </li>
              <li>
                <a
                  href="#18"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Payment Processing
                </a>
              </li>
              <li>
                <a
                  href="#19"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Free Trial for Athletes
                </a>
              </li>
              <li>
                <a
                  href="#20"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Athlete Subscription Cancellation
                </a>
              </li>
              <li>
                <a
                  href="#21"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Taxes
                </a>
              </li>
              <li>
                <a
                  href="#22"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Prohibited User Activities
                </a>
              </li>
              <li>
                <a
                  href="#23"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  User Generated Contributions
                </a>
              </li>
              <li>
                <a
                  href="#24"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Ownership of Content and Marks
                </a>
              </li>
              <li>
                <a
                  href="#25"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  License for User Contributions
                </a>
              </li>
              <li>
                <a
                  href="#26"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Social Media
                </a>
              </li>
              <li>
                <a
                  href="#27"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Submissions
                </a>
              </li>
              <li>
                <a
                  href="#28"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Third Party Websites and Contents
                </a>
              </li>
              <li>
                <a
                  href="#29"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  U.S. Government Rights
                </a>
              </li>
              <li>
                <a
                  href="#30"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Site Management
                </a>
              </li>
              <li>
                <a
                  href="#31"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="#32"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Digital Millennium Copyright Act (DMCA) Notice and Policy
                </a>
              </li>
              <li>
                <a
                  href="#33"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Term And Terminations
                </a>
              </li>
              <li>
                <a
                  href="#34"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Modifications And Interruptions
                </a>
              </li>
              <li>
                <a
                  href="#35"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Governing Law
                </a>
              </li>
              <li>
                <a
                  href="#36"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Corrections
                </a>
              </li>
              <li>
                <a
                  href="#37"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Disclaimer
                </a>
              </li>

              <li>
                <a
                  href="#38"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Limitations of Liability
                </a>
              </li>
              <li>
                <a
                  href="#39"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Indemnification
                </a>
              </li>
              <li>
                <a
                  href="#40"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  User Data
                </a>
              </li>
              <li>
                <a
                  href="#41"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Electronic Communications, Transactions, And Signatures
                </a>
              </li>
              <li>
                <a
                  href="#42"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  California Users and Residents
                </a>
              </li>
              <li>
                <a
                  href="#43"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Miscellaneous
                </a>
              </li>
              <li>
                <a
                  href="#44"
                  onClick={scrollDescription}
                  className="text-primary d-block mb-2">
                  Contact Us
                </a>
              </li>
            </ol>

            <ol className="font-20 fw-700" start="1" id="1">
              <li>
                <h2 className="font-20 fw-700">Agreement to Terms</h2>
              </li>
            </ol>
            <p>
              These Terms of Service (the &ldquo;Terms&rdquo;) constitute a
              legally binding agreement made between you, whether personally or
              on behalf of an entity, and AthleteUp, Inc.
              (&quot;AthleteUp&quot;, &ldquo;we&rdquo;, &ldquo;us&rdquo;, or
              &ldquo;our&rdquo;), concerning your access to and use of the
              AthleteUp website{' '}
              <a
                href="https://athleteup.com/"
                target="_blank"
                rel="noreferrer"
                className="text-primary">
                (www.athleteup.com)
              </a>{' '}
              as well as any other media related to or connected with the Site,
              including mobile applications and websites (collectively, the
              &ldquo;Site&rdquo;). If you access the Site or accept these Terms
              on behalf of a company or other legal entity, you represent and
              warrant that you have the authority to bind that legal entity and,
              in such event, &ldquo;you&rdquo; and &ldquo;your&rdquo; will refer
              to that legal entity.
            </p>
            <p>
              The Site provides an online marketplace for athletes and other
              identified talent associated with the sports industry (altogether,
              referred to as &ldquo;Athletes&rdquo;) to connect and engage
              commercially with individuals, businesses, non-profits, or other
              entities (altogether referred to as &ldquo;Buyers &rdquo;). These
              Terms outline the rights and responsibilities of AthleteUp and its
              Users and govern the use of the Site and its services.
            </p>
            <p>
              You agree that by accessing the Site, you have read, understood,
              and agree to be bound by all of these Terms. IF YOU DO NOT AGREE
              WITH ALL OF THESE TERMS OF SERVICE, THEN YOU ARE EXPRESSLY
              PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
              IMMEDIATELY.
            </p>
            <ol className="font-20 fw-700 mt-5" start="2" id="2">
              <li>
                <h3 className="font-20 fw-700">
                  Supplemental Terms and Modification&nbsp;
                </h3>
              </li>
            </ol>
            <p>
              In addition to these Terms, there may be supplemental terms and
              conditions or documents posted on the Site from time to time,
              which are hereby incorporated into these Terms by reference. We
              reserve the right, in our sole discretion, to modify or change
              these Terms of Service at any time without prior notice. We will
              notify you of any changes by updating the &ldquo;Last
              updated&rdquo; date of these Terms of Service. Please check these
              Terms every time you use our Site to understand the applicable
              terms. By continuing to use the Site after any changes or
              modifications to these Terms, you agree to be bound by the revised
              Terms.
            </p>
            <p>
              The information provided on the Site is intended only for
              individuals and entities that can form legally binding contracts
              under applicable law. The Site may not be suitable or compliant
              for use in certain jurisdictions or by certain individuals or
              entities. You are responsible for ensuring compliance with all
              local laws and regulations that apply to your use of the Site.
            </p>
            <p>
              The Site is not designed to comply with industry-specific
              regulations such as the Health Insurance Portability and
              Accountability Act (HIPAA) or the Federal Information Security
              Management Act (FISMA). If your use of the Site requires
              compliance with any such regulations, you may not use the Site.
              Additionally, you may not use the Site in a way that would violate
              the Gramm-Leach-Bliley Act (GLBA).
            </p>
            <ol className="font-20 fw-700 mt-5" start="3" id="3">
              <li>
                <h3 className="font-20 fw-700 mt-5">Eligibility</h3>
              </li>
            </ol>
            <p>
              To use the Site, you must be at least 13 years old. If you are a
              minor in your jurisdiction, which is generally anyone under the
              age of 18, you must have the consent and direct supervision of
              your parent or legal guardian to use the Site. By using the Site,
              you represent and warrant that you meet these eligibility
              requirements. If you are a minor, your parent or legal guardian
              must read and agree to these Terms of Service on your behalf
              before you use the Site.
            </p>
            <ol className="font-20 fw-700 mt-5" start="4">
              <li>
                <h3 className="font-20 fw-700" id="4">
                  Site Summary
                </h3>
              </li>
            </ol>
            <ul>
              <li>
                <p>
                  The Site is an online marketplace that connects Athletes with
                  Buyers for commercial transactions.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Athletes can create their own profile and online storefront
                  page (referred to as a &ldquo;Shop&rdquo;) to sell physical
                  and digital products, as well as virtual or in-person services
                  (collectively, the &ldquo;Marketplace Listings&rdquo;),
                  altogether referred to herein as &ldquo;AthleteUp Shop&rdquo;
                  activities.
                </p>
              </li>
              <li>
                <p>
                  The Site also enables Athletes to source custom contracted
                  business opportunities (&quot;Deals&quot;) related to
                  marketing sponsorships, endorsements, appearances, name,
                  image, &amp; likeness (&ldquo;NIL&rdquo;) rights licensing,
                  and more, altogether referred to herein as &ldquo;AthleteUp
                  Deals&rdquo; activities.
                </p>
              </li>
              <li>
                <p>
                  Athletes are defined as individuals who engage in sports,
                  coaching/training, or related media, and Buyers are either
                  fans (&ldquo;Fans&rdquo;), or businesses, non-profits, and
                  other entities (collectively &ldquo;Brands&rdquo;).
                </p>
              </li>
              <li>
                <p>
                  While AthleteUp helps facilitate transactions, Athletes and
                  Buyers are entirely responsible for the transactions that
                  result between them. By using this Site, each User
                  acknowledges and agrees that you are solely responsible for
                  each agreement you enter into, including but not limited to
                  assuring that each agreement adheres to all rules,
                  regulations, and laws under which you are subject.
                </p>
              </li>
              <li>
                <p>
                  AthleteUp does not charge commission fees (from either the
                  Athlete or the Buyer), but Athletes must pay a subscription
                  fee to use the Site.
                </p>
              </li>
              <li>
                <p>
                  In registering for an account, users agree to provide us with
                  accurate, complete and updated information and must not create
                  an account for fraudulent or misleading purposes.
                </p>
              </li>
              <li>
                <p>
                  AthleteUp provides payment processing for all transactions
                  through Stripe. Payments for Orders and Deal are collected
                  upfront from Buyers and released to Athletes after completion
                  of required activities and deliverables. Athletes can initiate
                  a payout from their AthleteUp account balance to their
                  external bank account at any time.
                </p>
              </li>
              <li>
                <p>
                  Only registered users may buy and sell on AthleteUp.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  These Terms do not create any agency, partnership, joint
                  venture, employment, or franchisee relationship between you
                  and AthleteUp.
                </p>
              </li>
            </ul>
            <ol className="font-20 fw-700 mt-5" start="5" id="5">
              <li>
                <h5 className="font-20 fw-700">
                  AthleteUp Shop: General Overview
                </h5>
              </li>
            </ol>
            <p>
              <b>Marketplace Listings:</b>&nbsp;Athletes can create Marketplace
              Listings to sell physical or digital products
              (&ldquo;Products&rdquo;) and in-person or virtual (including
              social media interactions) experiences
              (&ldquo;Experiences&rdquo;). All Marketplace Listings must comply
              with our prohibited list of items (&ldquo;Prohibited Items&rdquo;)
              as outlined in Section 7. Each Marketplace Listing includes a
              title, description, price, and supporting photo(s) or video(s) as
              set by the Athlete while creating the listing.&nbsp;
            </p>
            <p>
              <b>Orders:</b>&nbsp;When a Buyer purchases a Marketplace Listing,
              an Order is created. Orders represent the formal agreement between
              the Buyer and Athlete after a purchase has been made from the
              Athlete&apos;s Shop page. Buyers purchase Orders directly from an
              Athlete&apos;s Shop page. Each Order creates its own order page
              (&quot;Order Page&quot;) where Buyers and Athletes can communicate
              with each other in connection with the Order and track the
              Order&apos;s progress. While not every Order will necessitate
              communication between Buyer and Athlete, every Order Page will
              include an opened chat messaging thread that facilitates smooth
              and successful Order completion.
            </p>
            <p>
              <b>Progression of an Order to Completion:</b>&nbsp;Once a new
              Order is received by the Athlete, the Order Page is opened, and
              the Order has a status of &quot;In progress.&quot; When the
              Athlete completes the Order (or when the tracking for a shipped
              physical product is marked by the shipper as
              &quot;delivered&quot;), the Order is then marked as completed on
              the Order Page. At this time, the Order status changes to
              &quot;Completed,&quot; and the Buyer is notified via email that
              the Buyer has 4 days (96 hours) (the &quot;Dispute Period&quot;)
              to either confirm or dispute that the Order was adequately
              completed. At the earliest of (a) an Order being confirmed
              complete by the Buyer or (b) the conclusion of the Dispute Period
              (without a dispute being initiated), the payment for the Order
              will be transferred to the Athlete&apos;s AthleteUp account
              balance (&quot;AthleteUp Balance&quot;). If a Buyer does not
              initiate a dispute during the Dispute Period, it will be
              considered as the Buyer confirming that the Order has been
              completed, and there will be no further opportunity for the Buyer
              to dispute the Order.
            </p>
            <p>
              <b>Athlete&rsquo;s Proof of Work Requirement:</b>&nbsp;Athletes
              are required to compile proof of work (&ldquo;Proof of
              Work&rdquo;) as evidence to support and confirm that an Order was
              adequately completed. Athletes are encouraged, but not required,
              to submit Proof of Work to Buyers before marking an Order as
              complete. If an Athlete chooses not to submit Proof of Work to a
              Buyer, the Athlete must have Proof of Work readily available to
              promptly submit upon request from either the Buyer or AthleteUp
              customer support (&quot;Customer Support&quot;). Proof of Work may
              come in various forms, including but not limited to:&nbsp;
            </p>
            <ul>
              <li>
                <p>
                  Screen captures of completed social media-based deliverables
                  (e.g., posts, follows/likes, comments, etc.).
                </p>
              </li>
              <li>
                <p>
                  Screen captures evidencing that a virtual experience (e.g.,
                  live video call) occurred.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Photos or videos documenting in-person experiences (e.g.,
                  lessons or meet &amp; greets) occurred.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Paper trail evidence from the chat message thread on the Order
                  Page (e.g., Buyer providing written confirmation of the
                  completion of the Order; documented electronic transfer of
                  digital product deliverables; etc.).
                </p>
              </li>
              <li>
                <p>
                  Shipping tracking numbers and screenshots of shipping tracking
                  updates (e.g., screenshot of package being marked as delivered
                  by the carrier).
                </p>
              </li>
              <li>
                <p>
                  Photos or videos evidencing proper packing materials and
                  protection utilized for physical product shipments.&nbsp;
                </p>
              </li>
            </ul>
            <p>
              <b>Order Disputes:</b>&nbsp;Orders that have been
              completed/fulfilled are generally non-refundable. However, certain
              situations do allow for Buyer refunds after an Order has been
              completed by the Athlete, including but not limited to instances
              of complete non-performance by the Athlete, instances of a product
              or service not being delivered, or instances where a product is
              delivered damaged or differs significantly from its listing
              description. For an Order to be eligible for a potential refund
              after it is marked as completed by the Athlete, the Buyer must
              formally initiate a dispute on the Order Page during the Dispute
              Period, which is 96 hours from the Order being marked as completed
              by the Athlete. When an Order is disputed by the Buyer during the
              Dispute Period, all payments are immediately frozen/withheld by
              AthleteUp (rather than being disbursed to the Athlete or refunded
              to the Buyer) and the Order will enter the Dispute Resolution
              Process. For more information on this process, see Section 12
              &ldquo;Dispute Resolution Process&rdquo;. &nbsp;
            </p>
            <p>
              Note: Orders are not eligible to be cancelled/refunded based on
              the quality of service/product delivered by the Athlete if the
              service or product was rendered/delivered as described in the
              Marketplace Listing. If this situation arises, it is solely up to
              the Athlete to determine whether they would like to attempt to
              provide an alternative solution or if they would like to offer a
              full or partial refund to the Buyer.
            </p>
            <p>
              <b>Disputes Arising From Physical Product Orders:</b>&nbsp;If an
              Order for a physical product arrives damaged or differs
              significantly from its listing description, the Buyer must
              formally initiate a dispute on the Order Page during the 96-hour
              Dispute Period. Once the dispute is initiated, the Buyer must
              provide evidence (e.g., photo or video) supporting the arrival
              state of the delivered product. Refunds will be issued to the
              original form of payment. We do not provide refunds or returns for
              Prohibited Items. Customer Support may require that the item(s) be
              returned to the Athlete (with AthleteUp providing the return
              shipping label) for a refund. AthleteUp reserves the right to
              debit the Athlete&rsquo;s account for the cost of the return
              shipping label. When the Athlete is determined to be at fault
              (e.g., wrong item sent; item sent differs significantly from
              listing; insufficient shipping protection used; etc.), AthleteUp
              also reserves the right to debit the Athlete&rsquo;s account for
              the accrued shipping charge that must be refunded to the Buyer.
              &nbsp;
            </p>
            <p>
              <b>Basic Liability Insurance on Certain USPS Shipments:</b>
              &nbsp;USPS includes basic liability insurance coverage for package
              shipments via Priority Mail and Priority Mail Express at no extra
              cost. This basic liability coverage provides insurance up to $100
              for domestic shipments and up to $200 for international shipments.
              If eligible packages are lost or damaged by the shipper in transit
              to the Buyer, Athletes may be able to recoup some or all of their
              costs (up to the previously stated basic liability coverage
              limits) by filing a claim with USPS. During such situations,
              AthleteUp Customer Service will assist the Athlete in
              understanding the process to file the claim with USPS. AthleteUp
              may, but is not required to, file the claim on the Athlete&rsquo;s
              behalf. If USPS determines that the shipped item is not eligible
              for reimbursement under the above stated liability insurance
              coverage, Athletes will have no recourse available from AthleteUp
              for the loss. Refer to USPS website for additional information and
              terms related to their basic insurance coverage on Priority and
              Priority Express shipments.&nbsp;
            </p>
            <p>
              For packages that are not shipped via USPS Priority Mail or
              Priority Mail Express, or packages that include items sold for a
              value greater than the $100 coverage limit, there is no monetary
              recourse available to Athletes for items lost or damaged during
              shipment.&nbsp;
            </p>
            <p>
              <b>Athlete Cancelling an Order:</b>&nbsp;Sometimes an Athlete may
              need to cancel a Buyer&rsquo;s Order due to scheduling conflicts,
              limited availability, or other reasons. If an Athlete is unable to
              accept or complete an Order, they can cancel the Order from the
              Order Page at any time prior to marking the Order as complete. If
              an Order is cancelled by the Athlete for any reason, the entire
              purchase price will be refunded to the Buyer.
            </p>
            <p>
              <b>Buyer Cancelling an Order:</b>&nbsp;Once an Order is placed, it
              can only be cancelled by the Buyer for a refund if the Athlete has
              not already begun working on (or fully completed) the agreed-upon
              deliverables. If the Athlete has already shipped a physical
              product or digitally transferred a personalized video message, the
              Order cannot be cancelled and no refund will be issued. If a Buyer
              wishes to cancel an Order, they should immediately inform the
              Athlete via the chat thread on the Order Page and request that the
              Athlete initiate the Order cancellation/refund from the Order
              Page. If an Order is able to be successfully cancelled at the
              Buyer&apos;s request, the entire purchase price will be refunded
              to the Buyer. Buyers can also contact AthleteUp support for
              assistance in attempting to cancel an Order.
            </p>
            <p>
              If a Buyer requests to cancel an Order after the Athlete has begun
              working on (or completed) the agreed-upon deliverables, the Buyer
              and Athlete (each a &ldquo;Party&rdquo; and together the
              &ldquo;Parties&rdquo;) should discuss potential reasonable
              outcomes together in good faith negotiations. Potential outcomes
              may include the following: (a) Athlete will complete the
              deliverables as purchased and no refund will be issued; (b)
              Athlete will cease work on the deliverables and a full refund will
              be issued to the Buyer; or{' '}
              <code
                style={{
                  fontSize: '16px',
                  color: '#042237',
                  fontWeight: 400,
                }}>
                (c)
              </code>{' '}
              Athlete will cease work on the deliverables and a partial refund
              will be issued to the Buyer. If the Parties are unable to come to
              a resolution, they should contact AthleteUp support. Customer
              Support will review the situation and make a final decision, at
              its sole discretion, on whether the situation merits a full or
              partial refund. In the case of a partial refund, Customer Support
              can help facilitate the agreed-upon amount.
            </p>
            <p>
              <b>AthleteUp Cancelling an Order:</b>&nbsp;In certain
              circumstances, AthleteUp may need to cancel an Order to protect
              the safety of both the Buyer and Athlete. These circumstances may
              include, but are not limited to, suspected fraud, harassment,
              unlawful behavior, the sale of a Prohibited Item, a lack of action
              or responsiveness from the Athlete after ten or more days have
              passed since the Order was placed, the Buyer initiating a
              chargeback for the purchase from their credit card company, the
              account of the Athlete or Buyer being terminated, suspended, or
              deactivated, or any other violation of our Terms. If AthleteUp
              cancels a Buyer&apos;s Order for any reason, the entire purchase
              price will be refunded to the Buyer.
            </p>
            <p>
              <b>Order Payments Collected Upfront:</b>&nbsp;To create an Order,
              Buyers are required to pay upfront. Payments are held until an
              Order is completed and the Dispute Period has concluded without a
              dispute being raised, at which point the associated Order payment
              will be released to the Athlete&apos;s AthleteUp Balance.
            </p>
            <p>
              <b>
                Disclaimer Regarding Physical Products Marketplace Listings:
              </b>
              &nbsp;AthleteUp does not manufacture, store, or inspect any of the
              Marketplace Listings sold through our Site. Therefore, AthleteUp
              cannot make any warranties regarding the quality, safety, or
              legality of any items listed. Any legal claims related to an item
              purchased must be brought directly against the seller of the item
              (i.e., the Athlete). Buyers release AthleteUp from any claims
              related to items sold through our Site, including claims for
              defective items, misrepresentations by Athletes, or items that
              cause physical injury (such as product liability claims).&nbsp;
            </p>
            <p>
              <b>
                Ability to Document Order Fulfillment for Marketing Purposes:
              </b>
              &nbsp;By creating an Order for an experience (in-person or
              virtual), the Buyer consents that the participant may be
              photographed, videotaped, and/or recorded by the Athlete or
              AthleteUp. The Buyer also consents to the use of such photography,
              videography, and recording, as well as the participant&apos;s
              name, for the reasonable business purposes of the Athlete&apos;s
              or AthleteUp&apos;s marketing efforts in any and all media
              formats, including, but not limited to, television, radio, and
              digital media formats, including the Internet, without the payment
              of any consideration. However, Buyers have the express right to
              request that the Athlete and AthleteUp not collect or utilize the
              participant&apos;s name, image, or likeness for marketing or any
              other purposes. The Buyer must provide this request in writing to
              the Athlete.&nbsp;
            </p>
            <ol start="6" id="6" className="fw-700 font-20 mt-5">
              <li>
                <h3 className="fw-700 font-20">
                  AthleteUp Shop: Detailed Overview of Marketplace
                  Listings&nbsp;
                </h3>
              </li>
            </ol>
            <p>
              <b>Physical Products:</b>&nbsp;When selling physical products on
              AthleteUp, Athletes must use AthleteUp-provided pre-paid USPS
              shipping labels. Buyers are responsible for paying for shipping,
              which is added to the listed price for the item during the
              checkout process. AthleteUp has partnered with USPS to provide
              secure and discounted shipping rates, and all AthleteUp shipping
              labels come with USPS tracking to ensure safe delivery. If Athlete
              ships an order using a different shipping method other than the
              provided pre-paid USPS label, the Athlete will be solely
              responsible for the cost of shipping, and AthleteUp will not
              reimburse the Athlete for this expense. Athletes are expected to
              make best efforts to ship physical product Orders within 7
              calendar days of receiving the Order.&nbsp;
            </p>
            <p>
              USPS provides basic liability insurance coverage for package
              shipments via Priority Mail and Priority Mail Express for domestic
              and international shipments. The coverage provides insurance up to
              $100 for domestic shipments and up to $200 for international
              shipments. If eligible packages are lost or damaged in transit,
              Athletes may be able to recoup some or all of their costs by
              filing a claim with USPS. AthleteUp Customer Service will assist
              Athletes in understanding the process to file the claim with USPS.
              However, AthleteUp is not required to file the claim on the
              Athlete&apos;s behalf. If USPS determines that the shipped item is
              not eligible for reimbursement under the basic liability insurance
              coverage, the Athlete will have no recourse available from
              AthleteUp for the loss.
            </p>
            <p>
              Athletes are responsible for paying any overage charges associated
              with a shipment. USPS may charge overage fees to deliver packages
              that are over the weight and/or dimension limits specified on the
              shipping label. Additionally, Athletes must use proper shipping
              boxes or envelopes as required by USPS (e.g., when shipping with a
              USPS Priority Mail Flat-Rate label, Athletes are required to ship
              the item in the designated Priority Mail Flat-Rate box or
              envelope). If the prepaid shipping label is insufficient based on
              the package&apos;s weight, size, or if an incorrect box is used,
              then (i) the package may be returned to the Athlete, or (ii)
              overage fees will be charged, and the additional fees will be
              deducted from the Athlete&apos;s earnings by AthleteUp to cover
              the costs. If Athlete realizes that the shipping dimensions used
              to create a pre-paid shipping label are incorrect, they must
              inform the Buyer and cancel the Order. The Buyer can then
              repurchase the Order after the Athlete has corrected the shipping
              information associated with the listing.
            </p>
            <p>
              Overall, AthleteUp expects all Athletes to adhere to the shipping
              guidelines and requirements set forth by USPS to ensure successful
              delivery of all orders.
            </p>
            <p>
              <b>Digital Products:</b>&nbsp;Orders for digital products can
              include a variety of deliverable types, including downloadable
              video files, audio files, images, or other documents (such as
              PDFs, Word docs, etc.). When the digital product marketplace
              listing is generic in nature (i.e., not customized based on the
              individual order), the Athlete uploads the deliverable while
              creating the listing. Once a Buyer purchases the listing, the
              deliverable is automatically provided to the Buyer on the Order
              Page through the messaging thread.&nbsp;
            </p>
            <p>
              For Orders with a custom-made or personalized digital product,
              such as a request for a personalized video recording, these are
              categorized as &ldquo;virtual experiences&rdquo;. In this case,
              the Athlete will transmit the deliverable to the Buyer via the
              messaging thread once it has been completed. However, the Athlete
              is responsible for ensuring that the deliverable meets the
              agreed-upon specifications and requirements set forth in the
              Order.
            </p>
            <p>
              It is the responsibility of Users to scan all delivered files for
              viruses and malware. AthleteUp will not be held responsible for
              any damages that may occur due to site usage, use of content or
              files transferred. Additional terms apply related to usage rights
              granted to the Buyer of digital products on the Site. Please refer
              to Section 8 (&ldquo;Orders for Digital Products: Usage
              Rights&rdquo;) for further details.
            </p>
            <p>
              <b>In-Person Experiences:</b>&nbsp;Athletes can offer a variety of
              in-person experiences, such as coaching sessions, meet &amp;
              greets, or attending sports events. When a Buyer places an Order
              for an in-person experience, the messaging thread on the Order
              Page is the primary tool used to coordinate scheduling and
              fulfillment of the Order.&nbsp;
            </p>
            <p>
              If a Buyer fails to attend or is more than 15 minutes late to a
              scheduled in-person experience without providing written notice
              via the messaging thread at least 2 hours prior, the Athlete may
              mark the deliverable as complete and the Buyer is not entitled to
              a refund. The Athlete may, but is not obligated to, try to
              reschedule with the Buyer. If the Buyer provides at least 2 hours
              written notice, the Athlete is required to attempt to reschedule
              the experience with the Buyer rather than marking the Order as
              complete. If the Athlete is unable to accommodate the rescheduled
              experience, they have the option to cancel the Order and issue a
              refund.
            </p>
            <p>
              For any in-person experiences related to live action exercise,
              sports training, or other physical exertion, the Buyer must agree
              to the Physical Activity Liability Waiver (the
              &ldquo;Waiver&rdquo;) presented in Section 15 before completing
              the checkout for the Marketplace Listing. By engaging in these
              activities with the Athlete through the Site, the Buyer
              acknowledges that they have read and understand the Waiver and
              agrees to be bound by its terms.
            </p>
            <p>
              It&apos;s important to note that AthleteUp does not guarantee the
              behavior, conduct, safety, suitability, or ability of either
              Buyers or Athletes. Both Buyers and Athletes assume all risk
              associated with their meeting and/or use of in-person experiences,
              and AthleteUp is not liable for any in-person services provided by
              Athletes.
            </p>
            <p>
              <b>Virtual Experiences:</b>&nbsp;Orders for virtual experiences
              can include a variety of deliverable types, such as live video
              calls, personalized video recordings, live play of online video
              games, and direct messaging. When a Buyer places an Order for a
              virtual experience, the messaging thread is used as the primary
              tool to coordinate all details required for scheduling and
              fulfilling the Order. For Orders that require a live video call,
              AthleteUp provides a feature that enables users to initiate the
              video call directly from the messaging thread on the Order Page.
              This feature allows for convenient fulfillment of the deliverable
              while maintaining users&apos; privacy and not requiring personal
              phone numbers or email addresses.
            </p>
            <p>
              If the Order is for a personalized video recording, the Buyer
              acknowledges and agrees that the Athlete has sole discretion to
              determine how to fulfill the request and the content of the video
              created. The Athlete may not follow the request exactly, and they
              maintain the right to reject or cancel any Order at their sole
              discretion. If the Athlete cancels an Order, the Buyer will
              receive a refund.
            </p>
            <p>
              If a Buyer fails to attend or is more than 15 minutes late to a
              scheduled in-person experience without providing written notice
              via the messaging thread at least 2 hours prior, the Athlete may
              mark the deliverable as complete and the Buyer is not entitled to
              a refund. The Athlete may, but is not obligated to, try to
              reschedule with the Buyer. If the Buyer provides at least 2 hours
              written notice, the Athlete is required to attempt to reschedule
              the in-person experience with the Buyer rather than marking the
              Order as complete. If the Athlete is unable to accommodate the
              rescheduled experience, they have the option to cancel the Order
              and issue a refund.
            </p>
            <p>
              For live virtual experiences, if the Buyer is more than 15 minutes
              late to or does not attend a scheduled virtual experience without
              providing written notice via the messaging thread at least 2 hours
              prior, the Athlete may mark the deliverable as complete and the
              Buyer is not entitled to a refund. The Athlete may, but is not
              obligated to, try to reschedule with the Buyer. If the Buyer
              provides at least 2 hours written notice, the Athlete is required
              to attempt to reschedule the virtual experience with the Buyer
              rather than marking the Order as complete. If the Athlete is
              unable to accommodate the rescheduled experience, they have the
              option to cancel the Order and issue a refund.
            </p>
            <p>
              <b>Social Media Interactions:</b>&nbsp;Orders for social media
              interactions can include liking or commenting on a public post,
              following a person&apos;s account, or posting a personalized post
              or &quot;shoutout&quot; on the Athlete&apos;s social media
              account. The messaging thread is used as the primary tool to
              coordinate all details required for fulfilling the Order. Athletes
              have the option to cancel an Order if they are uncomfortable with
              the request for any reason.
            </p>
            <p>
              It is important to note that social media interactions, like all
              Marketplace Listings, are for personal, non-commercial use only,
              unless explicitly stated in the Marketplace Listing title and/or
              description that it is for business or commercial-use purposes.
            </p>
            <ol start="7" id="7" className="fw-700 font-20 mt-5">
              <li>
                <h3 className="fw-700 font-20">
                  Marketplace Listings &ndash; Prohibited Items:
                </h3>
              </li>
            </ol>
            <p>
              AthleteUp is committed to providing a safe and trustworthy
              marketplace for Buyers and Sellers. In order to maintain this
              environment, the following items are prohibited from being listed
              or sold on our platform:
            </p>
            <ol>
              <li>
                <p>
                  Illegal items: Any item that is illegal to sell or possess,
                  including but not limited to illegal drugs, narcotics, or any
                  controlled substances, as well as any stolen goods or items
                  that promote or support illegal activities.
                </p>
              </li>
              <li>
                <p>
                  Counterfeit or infringing items: Any item that infringes upon
                  the intellectual property rights of others, including
                  counterfeit items, knock-offs, pirated materials, or items
                  that bear trademarks or logos without authorization.
                </p>
              </li>
              <li>
                <p>
                  Dangerous or hazardous items: Any item that poses a threat to
                  the health, safety, or well-being of others, including but not
                  limited to firearms, ammunition, weapons, explosives,
                  hazardous materials, or any items that may be used to cause
                  harm.
                </p>
              </li>
              <li>
                <p>
                  Prohibited services: Any service that is illegal, unethical,
                  or promotes harmful activities, including but not limited to
                  prostitution, gambling, or any other activity that violates
                  applicable laws or regulations.
                </p>
              </li>
              <li>
                <p>
                  Offensive or discriminatory items: Any item that promotes hate
                  speech, violence, discrimination, or intolerance based on
                  race, gender, sexual orientation, religion, nationality, or
                  any other characteristic.
                </p>
              </li>
              <li>
                <p>
                  Adult content and sexually related items: Any item that
                  contains pornographic or sexually explicit material, including
                  items that depict nudity, sexual acts, or sexual content, as
                  well as items that are designed to stimulate sexual activity,
                  including sex toys and fetish items.
                </p>
              </li>
              <li>
                <p>
                  Government-issued documents or identification: Any item that
                  is designed to replicate, reproduce, or forge official
                  government-issued documents, including but not limited to
                  passports, visas, driver&apos;s licenses, and birth
                  certificates.
                </p>
              </li>
              <li>
                <p>
                  Prohibited food items: No food items may be sold on AthleteUp,
                  regardless of their safety, quality, or legality. This
                  includes, but is not limited to, perishable and non-perishable
                  items, homemade items, baked goods, candy, snacks,
                  supplements, and any other food product or ingredient.
                </p>
              </li>
              <li>
                <p>
                  Alcohol and tobacco products: Any item that contains or
                  promotes the sale of alcohol or tobacco products, including
                  but not limited to cigarettes, cigars, tobacco pipes, hookahs,
                  vaporizing instruments that contain nicotine or are associated
                  with tobacco or nicotine usage, vaporizing liquid without
                  nicotine, and alcoholic beverages.
                </p>
              </li>
              <li>
                <p>
                  Drugs and medications: Any drugs, medications, pills,
                  vitamins, and supplements.
                </p>
              </li>
              <li>
                <p>
                  Malicious software: Any item that contains a computer virus,
                  malware, or spyware that could harm the computer systems of
                  AthleteUp or its users.
                </p>
              </li>
              <li>
                <p>
                  Wanted ads: Any advertisements or listings for objects being
                  sought or desired.
                </p>
              </li>
              <li>
                <p>
                  Regulated financial products and services: Any regulated
                  financial products and services such as bonds, securities,
                  warranties, insurance, cryptocurrencies, gift cards or prepaid
                  cards, precious metals, or loose (unset) precious gemstones.
                </p>
              </li>
              <li>
                <p>
                  Real estate and real property: Any property or land, including
                  but not limited to houses, apartments, buildings, and
                  undeveloped land, as well as any services related to the
                  purchase, sale, or rental of such properties. This includes
                  rental properties and time shares, which are not allowed to be
                  listed or sold on the AthleteUp platform.
                </p>
              </li>
              <li>
                <p>
                  Automobiles and boats/watercraft: Any motor vehicle, including
                  but not limited to cars, trucks, motorcycles, and
                  boats/watercrafts, as well as any services related to the
                  purchase, sale, or rental of such vehicles.
                </p>
              </li>
              <li>
                <p>
                  Multi-level marketing schemes and promotional materials: Any
                  listing or promotion of multi-level marketing schemes or other
                  similar schemes.
                </p>
              </li>
              <li>
                <p>
                  Misleading or misinformation listings: Any listing that is
                  knowingly misleading or includes false or incomplete
                  information.
                </p>
              </li>
              <li>
                <p>
                  Live animals and taxidermy: Any live animals or taxidermy,
                  including but not limited to stuffed animals or mounted
                  wildlife.
                </p>
              </li>
              <li>
                <p>
                  Human remains and body parts: Any item that contains or is
                  made from human remains or body parts, including but not
                  limited to skulls, bones, or organs.
                </p>
              </li>
            </ol>
            <p>
              Please note that this list is not exhaustive, and AthleteUp
              reserves the right to remove any listings or accounts that violate
              this Prohibited Items clause at any time, without notice. We also
              reserve the right to report any illegal activity to the
              appropriate authorities.
            </p>
            <ol start="8" id="8" className="fw-800 font-20 mt-5">
              <li>
                <h3 className="fw-800 font-20">
                  Orders for Digital Products: Usage Rights
                </h3>
              </li>
            </ol>
            <p>
              When purchasing a digital product from an Athlete on the Site, the
              Buyer agrees to the following terms of use:
            </p>
            <ol>
              <li>
                <p>
                  The digital product is solely for personal, non-commercial use
                  unless explicitly identified in the Marketplace Listing&apos;s
                  title and/or description as being &quot;for business&quot; or
                  &quot;for commercial use&quot;.
                </p>
              </li>
              <li>
                <p>
                  The Athlete grants the Buyer a non-exclusive,
                  non-transferable, royalty-free, worldwide license to use the
                  digital product for personal, non-commercial use only.
                </p>
              </li>
              <li>
                <p>
                  The Buyer may use the digital file for personal purposes,
                  including but not limited to viewing, sharing, and displaying
                  the file for private, non-public use, and for gifting to
                  others.
                </p>
              </li>
              <li>
                <p>
                  If the purchased digital product is a personalized video
                  recording (i.e., a &quot;video shoutout&quot;) for personal
                  use, the Buyer is also granted the right to display/share the
                  personalized video recording via their personal social media
                  accounts.
                </p>
              </li>
              <li>
                <p>
                  The Buyer may not use the digital file for any commercial
                  purposes, including but not limited to promoting a business,
                  product, or service, or for any other commercial gain, without
                  obtaining explicit written permission from the Athlete.
                </p>
              </li>
              <li>
                <p>
                  The Buyer may not edit, modify, reproduce, distribute,
                  publicly display, or otherwise exploit the digital file in any
                  way that would violate the Athlete&apos;s intellectual
                  property rights or privacy rights, or any applicable laws or
                  regulations.
                </p>
              </li>
              <li>
                <p>
                  The Buyer may not sublicense, assign, or transfer the digital
                  file to any third party without prior written consent from the
                  Athlete.
                </p>
              </li>
              <li>
                <p>
                  The Athlete retains all intellectual property rights,
                  including but not limited to copyright, in the digital file.
                  The Buyer does not acquire any ownership rights or rights to
                  use the digital file in any manner other than as expressly
                  permitted in these Terms or in the Marketplace Listing title
                  and description.
                </p>
              </li>
              <li>
                <p>
                  The Athlete has the right to request the removal of the
                  digital file from any platform or website that is not
                  authorized by the Athlete. However, when the purchased digital
                  product is a personalized video recording for personal use,
                  the Buyer has received express approval to share via their
                  personal (non-commercial) social media account(s).
                </p>
              </li>
              <li>
                <p>
                  Any unauthorized use of the digital file may result in
                  termination of the Buyer&apos;s account and/or legal action by
                  the Athlete.
                </p>
              </li>
            </ol>
            <p>
              By purchasing a digital product, the Buyer acknowledges and agrees
              to abide by these usage rights and any additional restrictions or
              guidelines provided by the Athlete. Failure to comply with these
              usage rights may result in termination of the Buyer&apos;s account
              and/or legal action by the Athlete.
            </p>
            <ol start="9" id="9" className="font-20 fw-700 mt-5">
              <li>
                <h3 className="font-20 fw-700">
                  Orders for Business / Commercial Use: Additional Terms Apply
                </h3>
              </li>
            </ol>
            <p>
              Unless explicitly stated otherwise, all Marketplace Listings are
              intended for personal, non-commercial use only. However, if a
              Marketplace Listing is identified as being &quot;for
              business&quot; or &quot;for commercial use&quot; in its title
              and/or description, the purchase of that listing creates a
              &quot;Business Order&quot; between the Parties. Fulfillment of a
              Business Order will be subject to additional terms (the
              &quot;Business Order Terms&quot;), which specify the rights and
              responsibilities of the Parties with respect to content ownership
              and licensing and other terms.
            </p>
            <p>
              <b>Business Order Terms:</b>
            </p>
            <ol>
              <li>
                <p>
                  <b>Standardized Terms:</b>&nbsp;These Business Order Terms
                  outline default assumptions for certain rights and
                  responsibilities of the Parties when they engage in a Business
                  Order through the Site. They are not intended to exhaustively
                  cover every situation that may arise in a given Business
                  Order.
                </p>
              </li>
              <li>
                <p>
                  <b>Responsibility of Parties:</b>&nbsp;Each Party is
                  responsible for ensuring that its participation in a Business
                  Order does not conflict with any laws, rules, or regulations
                  to which it may be subject. AthleteUp recommends that Parties
                  consult with a legal advisor before entering into a Business
                  Order.
                </p>
              </li>
              <li>
                <p>
                  <b>Primary Source for Terms:</b>&nbsp;The Parties acknowledge
                  that the primary source for terms related to rights and
                  responsibilities with respect to a Business Order is the title
                  and description of the associated Marketplace Listing (the
                  &quot;Marketplace Listing Terms&quot;). The Business Order
                  Terms outlined below are intended to be supplementary and
                  subordinate to the Marketplace Listing Terms. In the event of
                  any conflict between the two, the Marketplace Listing Terms
                  will always take priority.
                </p>
              </li>
              <li>
                <p>
                  <b>Content Ownership:</b>&nbsp;If fulfilling a Business Order
                  involves the creation of content (&quot;Business Order
                  Content&quot;), including but not limited to images, videos,
                  social media posts, written testimonials, and other
                  promotional materials utilizing the Athlete&apos;s NIL, the
                  Business Order Content shall be owned by the Athlete, subject
                  to the rights granted to the Buyer as outlined in these
                  Business Order Terms.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <b>Grant of Rights to the Buyer:</b>&nbsp;By participating in
                  a Business Order through the Site in which Business Order
                  Content is created, the Athlete grants the Buyer a limited,
                  non-exclusive, worldwide, royalty-free right and license to
                  use, reproduce, distribute, display, and promote the Business
                  Order Content in its original form created by the Athlete for
                  purposes including but not limited to the Buyer&apos;s
                  promotional activities and campaigns. The Buyer shall not
                  modify the Business Order Content created by the Athlete
                  without the express written consent of the Athlete unless such
                  modification right is specifically listed in the associated
                  Marketplace Listing.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <b>Content Distribution Rights and Term:</b>&nbsp;The Buyer
                  may use the Business Order Content created by the Athlete
                  through various media channels, including but not limited to
                  the Buyer&apos;s website, social media accounts, television,
                  print, radio, and other electronic communications and
                  promotional materials, for a period of 30 days after the
                  Business Order Content is provided to the Buyer by the Athlete
                  (the &ldquo;Content Rights Term&rdquo;). &nbsp;
                </p>
              </li>
              <li>
                <p>
                  <b>Social Media Content Posts:</b>&nbsp;If the Business Order
                  requires the Athlete to post content to their social media
                  account(s), the Athlete shall not delete the social media post
                  for at least 30 days. If the Deliverable is for an ephemeral
                  social media content post that automatically disappears within
                  a preset period (usually 24 hours, such as Instagram Stories
                  or Snaps), the Athlete shall not delete the ephemeral post
                  until it automatically disappears in the expected time period.
                  &nbsp;
                </p>
              </li>
              <li>
                <p>
                  <b>Whitelisting of Athlete&rsquo;s Social Media Post:</b>
                  &nbsp;The ability to whitelist an Athlete&apos;s organic
                  social media content post with ad spend is not included in a
                  Business Order unless it is explicitly provided in the
                  description of the Marketplace Listing or if the Buyer is
                  provided with express written consent to whitelist from the
                  Athlete.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <b>Buyer-Provided Content:</b>&nbsp;If Buyer provides certain
                  content (including media, logos, trademarks, or other
                  intellectual property owned by the Buyer) to the Athlete to
                  fulfill a Business Order deliverable, the Buyer grants the
                  Athlete a limited, non-exclusive, worldwide, royalty-free
                  right and license to utilize the content for the purposes of
                  fulfilling Business Order deliverables. All rights to use the
                  Buyer-provided content and intellectual property end when the
                  Business Order is completed.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <b>Exclusivity:</b>&nbsp;Unless otherwise agreed upon in the
                  Marketplace Listing Terms, the Business Order does not grant
                  any exclusivity rights to the Buyer. The Athlete is free to
                  engage in sponsorship, endorsement, or other opportunities
                  with other Brands during and after the Content Rights Term.
                </p>
              </li>
              <li>
                <p>
                  <b>Buyer Approval of Business Order Content:</b>&nbsp;When a
                  Business Order requires the Athlete to post Business Order
                  Content to their social media account, the Athlete shall
                  provide Business Order Content to the Buyer for approval prior
                  to posting on their social media account. The Buyer may
                  request reasonable changes or modifications to the Business
                  Order Content to ensure compliance with brand guidelines or
                  other requirements. If the Buyer does not respond within 72
                  hours of being provided with the Business Order Content for
                  approval, then Buyer approval shall be implied, and the
                  Athlete may post the Business Order Content to the agreed
                  social media accounts.
                </p>
              </li>
              <li>
                <p>
                  <b>
                    Buyer&rsquo;s Right to Request Removal of Business Order
                    Content:
                  </b>
                  &nbsp;The Buyer may request the removal of any Business Order
                  Content at its sole discretion. The Athlete shall promptly
                  comply with any such request and remove the Business Order
                  Content from all relevant platforms or media.
                </p>
              </li>
              <li>
                <p>
                  <b>
                    Terms of the Marketplace Listing Override these Business
                    Order Terms:
                  </b>
                  &nbsp;If there is a conflict between these Business Order
                  Terms and the written description of the associated
                  Marketplace Listing, the written description in the associated
                  Marketplace Listing will take precedence over these Business
                  Order Terms. For instance, if the description of the
                  Marketplace Listing for a Business Order specifies that the
                  Content Rights Term for the Buyer is 60 days (rather than the
                  30 days specified in these Business Order Terms), the terms of
                  the Marketplace Listing (i.e., 60 days) will govern.
                </p>
              </li>
              <li>
                <p>
                  <b>Prohibited Business Order Content:</b>&nbsp;Prohibited
                  Business Order Content includes (but is not limited to):&nbsp;
                </p>
                <ol type="a">
                  <li>
                    <p>
                      Content that is offensive, discriminatory, defamatory, or
                      pornographic in nature. This includes content that is
                      racist, sexist, homophobic, discriminatory based on
                      religion, nationality, disability, or any other protected
                      characteristic, or otherwise harmful or disparaging
                      towards any individual or group.
                    </p>
                  </li>
                  <li>
                    <p>
                      Content that is illegal, promotes illegal activities, or
                      encourages harmful behavior. This includes content that
                      involves violence, drug abuse, or any other illegal or
                      harmful activities.
                    </p>
                  </li>
                  <li>
                    <p>
                      Content that includes or incorporates any intellectual
                      property, including but not limited to trademarks, logos,
                      images, music, or other materials, that are owned by a
                      third party and have not been legally secured through a
                      valid license or authorization from the third party.
                    </p>
                  </li>
                  <li>
                    <p>
                      Content that is intended for or directed towards children
                      under the age of thirteen, as defined by applicable laws
                      and regulations, including but not limited to the
                      Children&apos;s Online Privacy Protection Act (COPPA) in
                      the United States.
                    </p>
                  </li>
                  <li>
                    <p>
                      Content that does not comply with any applicable law or
                      regulation governing proper advertisement disclosure. This
                      includes failing to disclose any sponsored, paid, or
                      gifted content in accordance with the Federal Trade
                      Commission (FTC) guidelines or any other relevant
                      advertising disclosure requirements.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <b>Relationship of the Parties:</b>&nbsp;The relationship
                  between the Buyer and the Athlete for a Business Order is that
                  of independent contractors. Nothing in these Business Order
                  Terms creates an employer-employee relationship, partnership,
                  joint venture, agency, or any other form of legal association
                  or relationship between the Buyer and the Athlete. The Athlete
                  is responsible for all taxes, insurance, and other obligations
                  arising from the payments received under the Business Order.
                  The Athlete has no claim against the Buyer for any employee
                  benefits or compensation.
                </p>
              </li>
              <li>
                <p>
                  <b>Indemnification:</b>&nbsp;Both Buyer and the Athlete agree
                  to indemnify, defend, and hold harmless each other, and (if
                  applicable) their respective officers, directors, employees,
                  agents, and affiliates from and against any and all claims,
                  damages, liabilities, losses, and expenses, including
                  reasonable attorneys&apos; fees, arising out of or in
                  connection with any breach of these Business Order Terms, any
                  act or omission of either Party, or any violation of any
                  applicable law or regulation by either Party. Each
                  Party&apos;s indemnification obligation survives the
                  termination or expiration of the Business Order.
                </p>
              </li>
              <li>
                <p>
                  <b>Representation and Warranties:</b>&nbsp;Both Buyer and
                  Athlete represent and warrant that they have the legal right
                  and authority to enter into and perform the activities and
                  deliverables detailed in the Business Order and that the
                  performance of this Business Order by each Party will not
                  violate or infringe upon the rights of any third party. Both
                  Parties further represent and warrant that they will comply
                  with all applicable laws, regulations, and industry standards
                  in the performance of this Business Order. Each Party&apos;s
                  representations and warranties survive the termination or
                  expiration of the Business Order.
                </p>
              </li>
              <li>
                <p>
                  <b>Modification and Entire Agreement:</b>&nbsp;These Business
                  Order Terms, together with the associated Marketplace Listing
                  Terms, constitute the entire agreement between the Parties
                  regarding the Business Order and supersede all prior or
                  contemporaneous negotiations, understandings, and agreements,
                  whether written or oral, between the Parties regarding the
                  Business Order. No modification, amendment, or waiver of any
                  provision of these Business Order Terms or Marketplace Listing
                  Terms is effective unless in writing and signed by both
                  Parties.
                </p>
              </li>
              <li>
                <p>
                  <b>Governing Law and Venue:</b>&nbsp;These Business Order
                  Terms are governed by and construed in accordance with the
                  laws of the state of North Carolina without regard to its
                  conflicts of law principles. Any legal action arising out of
                  or relating to these Business Order Terms or the Business
                  Order must be filed in the state or federal courts located in
                  the state of North Carolina, and the Parties consent to the
                  jurisdiction of such courts.
                </p>
              </li>
              <li>
                <p>
                  <b>Severability:</b>&nbsp;If any provision of these Business
                  Order Terms is determined to be invalid, illegal, or
                  unenforceable, the remaining provisions remain in full force
                  and effect.
                </p>
              </li>
              <li>
                <p>
                  <b>Waiver:&nbsp;</b>The failure of either Party to enforce any
                  right or provision of these Business Order Terms shall not
                  constitute a waiver of such right or provision. Any waiver of
                  any breach or default under these Business Order Terms must be
                  in writing and signed by the Party granting the waiver, and
                  such waiver is limited to the specific breach or default
                  waived.
                </p>
              </li>
            </ol>
            <ol className="font-20 fw-700 mt-5" start="10" id="10">
              <li>
                <h3 className="font-20 fw-700">
                  AthleteUp Deals: Key Terms &amp; General Overview
                </h3>
              </li>
            </ol>
            <p>
              <b>Custom Offers:</b>&nbsp;Buyers can send Athletes a custom offer
              directly from the Athlete&apos;s Shop page. Each custom offer
              includes a title, description, and identified deliverables that
              are required to be completed for the offer, along with the
              associated compensation and required fulfillment timeline. The
              details of the custom offer are documented on the Deal page
              (&ldquo;Deal Page&rdquo;) which includes a messaging thread that
              enables the Parties to communicate with each other, negotiate the
              offer, and track the progression of the Deal.
            </p>
            <p>
              <b>Contractual Agreement:</b>&nbsp;When the Buyer and Athlete
              agree on the terms of the custom offer, a Deal is created, and
              they enter into a formal contractual agreement. Both Parties agree
              to act in good faith and cooperate with each other in the
              performance of their respective obligations. The specific
              parameters of the agreement, including required deliverables,
              expectations, timelines, compensation, content rights granted, and
              other requirements, are documented on the Deal Page. If the
              Parties choose, they are welcome to utilize their own alternative
              contractual agreement (&ldquo;Alternative Agreement&rdquo;) to
              document the agreed terms and conditions that the Parties of the
              Deal are subject to. If the Parties choose to use an Alternative
              Agreement, the terms of that agreement cannot conflict with those
              identified on the Deal Page (i.e., Deal description, deliverables,
              compensation, etc.), and in the event of such conflict, the
              Parties agree that the terms identified on the Deal Page shall
              govern.
            </p>
            <p>
              <b>Standard Deal Terms:</b>&nbsp;To facilitate quick agreement on
              a variety of types of Deals, AthleteUp provides a set of
              standardized Deal terms (the &ldquo;Standard Deal Terms&rdquo;)
              that outline default assumptions for certain rights and
              responsibilities of the Parties. These Standard Deal Terms are
              supplementary and subordinate to the terms identified on the Deal
              Page or in any Alternative Agreement (the &ldquo;Custom Deal
              Terms&rdquo;). In the event of conflicting terms between the
              Custom Deal Terms and the Standard Deal Terms, the Custom Deal
              Terms will govern. If the Parties wish to use different
              assumptions for certain terms identified in the Standard Deal
              Terms, these divergences must be clearly articulated in the Custom
              Deal Terms. Please refer to Section 11 for the entirety of the
              Standard Deal Terms.
            </p>
            <p>
              <b>Totality of the Contractual Agreement:</b>&nbsp;The Custom Deal
              Terms, including any Alternative Agreement, along with the
              Standard Deal Terms, combine to form the totality of the
              contractual agreement between the Parties. AthleteUp facilitates
              the deal and processes payments but is not a party to the
              agreement and has no right or obligation to enforce the agreement
              or any Alternative Agreement aside from obligations and procedures
              described in Section 12 (&ldquo;Dispute Resolution
              Process&rdquo;).
            </p>
            <p>
              <b>Acceptance of Agreement:</b>&nbsp;Parties can accept a Deal
              agreement by clicking &quot;accept&quot; on the Deal Page, which
              serves as evidence that the User has read and accepted all terms
              that the agreement is subject to (Custom Deal Terms and Standard
              Deal Terms) and creates a binding contractual agreement. No
              signature is required for the agreement to be executed and fully
              enforceable. It is each Party&apos;s responsibility to carefully
              review and understand the terms of the agreement and to confirm
              that the agreement does not conflict with any laws, rules, or
              regulations. AthleteUp recommends and encourages Parties to
              consult with a legal advisor if there is any uncertainty regarding
              the terms of an agreement.
            </p>
            <p>
              <b>Deal Deliverables:</b>&nbsp;Buyers can specify up to twelve
              individual tasks or activities as deliverables for each custom
              offer. Each deliverable must include a specific completion date
              and associated compensation.
            </p>
            <p>
              <b>Deal Compensation: </b>Athletes can receive compensation for
              Deals in different ways: through monetary cash payments
              (&quot;Cash Compensation&quot;), through receipt of products or
              services (&quot;Products Compensation&quot;), or a combination of
              both. If Products Compensation is offered, the Buyer must indicate
              a monetary value for the products or services being offered (as
              determined and identified by the Buyer).
            </p>
            <p>
              In some cases, the compensation for a Deal may also include a
              commission-based component (&quot;Commission Compensation&quot;),
              which involves paying additional compensation (in cash or
              products) based on certain future actions taken by end users (such
              as purchases of goods or services directly connected to an
              Athlete&apos;s sponsored social media post, or social media
              engagement statistics related to the Athlete&apos;s sponsored
              social media post). Commission Compensation cannot be collected
              upfront, as it is contingent on unknown future outcomes agreed
              upon by the Buyer and Athlete when the Deal is initially agreed.
            </p>
            <p>
              Compensation is identified for each deliverable of a Deal.
              Although certain Deals may have multiple deliverables that may be
              completed at different times, compensation is paid to Athletes
              only after all deliverables are completed and the Deal is marked
              as complete. Payment is made as a lump sum upon full Deal
              completion, rather than in interval payments for individual
              deliverables.
            </p>
            <p>
              <b>Cash Compensation Collected Upfront:</b>&nbsp;Buyers are
              required to assign a payment method when sending a custom offer.
              The Cash Compensation component of the Deal is charged and
              collected upfront from the Buyer if the custom offer is accepted
              and becomes a Deal. The cash is held and safeguarded by AthleteUp
              and will only be disbursed to Athletes once the Deal has been
              satisfactorily completed and the Dispute Period has ended without
              a dispute being lodged. In the event that the custom offer is
              declined or expires without being accepted, the Buyer&rsquo;s
              assigned payment method will not be charged.
            </p>
            <p>
              Products Compensation or Commission Compensation are the sole
              responsibility of the Buyer and Athlete to plan and coordinate
              successful delivery, as well as to resolve any issues or disputes
              that may arise relating to these types of compensation.
            </p>
            <p>
              <b>Deal Description:</b>&nbsp;The Deal description is where all
              the details and requirements for a Deal are documented. The
              description must be thorough and provide exact expectations to
              minimize potential misunderstandings. If any Deal terms (e.g.,
              content rights) are required that are not aligned with the default
              terms identified in the Standard Deal Terms, then they must be
              clearly spelled out in the deal description and/or in an
              Alternative Agreement (if applicable).
            </p>
            <p>The Deal description should include the following topics:</p>
            <ul>
              <li>
                <p>
                  Whether an Alternative Agreement is required outside of the
                  terms identified on the Deal Page and in the Standard Deal
                  Terms.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Buyer overview &ndash; briefly explain what your company or
                  organization does.
                </p>
              </li>
              <li>
                <p>
                  Goal for the Deal &ndash; describe your marketing goal and
                  what you hope to accomplish from the Deal. Identify if you are
                  hoping to achieve brand awareness, engagement, visits to
                  website, etc.
                </p>
              </li>
              <li>
                <p>
                  Specific requirements/preferences for completing the
                  deliverable(s) &ndash; i.e., identify any specific message you
                  would like the Athlete to include in the content and provide
                  direction regarding preferred captions, tagging, hashtags,
                  affiliate/promo codes, hyperlinks, etc.
                </p>
              </li>
              <li>
                <p>Content-related rights:</p>
                <ul>
                  <li>
                    <p>
                      Specific content rights granted to the Buyer regarding
                      Deal Deliverable content or ancillary content created
                      during execution of Deal Deliverable (i.e., allowed
                      distribution channels; period of time content usage is
                      allowed).
                    </p>
                  </li>
                  <li>
                    <p>
                      Whether ability to whitelist (brand conducting paid
                      advertising on Athlete&rsquo;s organic post) is granted on
                      sponsored social media posts.
                    </p>
                  </li>
                  <li>
                    <p>
                      Whether Athlete-generated content requires brand approval
                      prior to posting on social media.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  Whether any brand exclusivity is granted (for how long and
                  under what parameters).
                </p>
              </li>
              <li>
                <p>
                  Any additional information required from the Athlete (e.g.,
                  sizing, preferences, or shipping address information if the
                  Deal includes Products Compensation).&nbsp;
                </p>
              </li>
            </ul>
            <p>
              <b>Campaigns:</b>&nbsp;Brands can create campaigns
              (&ldquo;Campaigns&rdquo;) to post Deal opportunities to a broad
              audience of Athletes, enabling the sourcing of Deal partnerships
              at scale. To create a Campaign, the Brand inputs a Campaign image,
              title, description, deliverables, associated fulfillment dates,
              and a maximum compensation budget per Deal. The Campaign
              description should be detailed, providing Athletes with all the
              important information they need to understand the opportunity and
              expectations. This includes brand overview, campaign goals,
              specific deliverable requirements, content-related rights,
              exclusivity rights, whether an Alternative Agreement is required,
              and any additional information required from the Athlete. The
              Brand can also set targeted Athlete criteria by selecting targeted
              attributes such as athlete type, sport, gender, university, social
              media following, etc.
            </p>
            <p>
              The Brand can then classify the Campaign as either
              &ldquo;Private&rdquo; or &ldquo;Open&rdquo;. A Private Campaign
              means that only Athletes that are personally invited by the Brand
              can apply to the Campaign. An Open Campaign means any eligible
              Athlete who meets the Brand&apos;s set criteria can apply.
              Athletes matching the Campaign&apos;s criteria can discover the
              Campaign on the AthleteUp Campaign Discovery Board. Athletes can
              also set their account to be emailed when they match with a newly
              created Campaign.
            </p>
            <p>
              Interested Athletes can apply to a Campaign by entering their
              required compensation and a personal message. Brands review
              Campaign applications and can accept, decline, send a counter
              offer, or negotiate further (via chat or video calling built-in to
              the messaging thread). Brands can pause or archive a Campaign when
              they no longer wish to receive any more applications associated
              with the Campaign.&nbsp;
            </p>
            <p>
              <b>Deal Statuses:</b>&nbsp;When an offer for a Deal has not been
              agreed upon by both Parties, the Deal will have a status of
              &ldquo;Application/Offer&rdquo;. The Deal offeror can cancel or
              modify their application/offer until it has been accepted or
              declined. The Deal offeree has the option to accept, decline, or
              send a counter offer. Deals that are declined will take on a
              status of &ldquo;Declined,&rdquo; while accepted Deals will take
              on a status of &ldquo;In Progress.&rdquo; Deals where the Offeree
              sends a counter offer maintain a status of
              &ldquo;Application/Offer.&rdquo;&nbsp;
            </p>
            <p>
              The Buyer&apos;s payment method is not charged until the Deal is
              first accepted by both Parties and the Deal status becomes
              &ldquo;In progress.&rdquo; The messaging thread remains open for
              accepted Deals to facilitate ongoing dialogue between the Parties,
              ensure smooth fulfillment of deliverables, and to allow for the
              transmission of relevant documents or live video calling.
            </p>
            <p>
              After the Athlete completes all Deliverables for the Deal, either
              the Athlete or the Buyer can mark the Deal as complete on the Deal
              Page. If the Athlete marks the Deal as complete, the Buyer is
              notified via email and has a 96-hour Dispute Period to confirm or
              dispute that the Deal was adequately completed. If the Buyer marks
              the Deal as complete, the status for the Deal will immediately
              update to &ldquo;Completed,&rdquo; and the Dispute Period will end
              immediately.
            </p>
            <p>
              If the Dispute Period ends without the Buyer initiating a Dispute,
              the Deal will update to a status of &ldquo;Completed.&rdquo; If a
              Buyer does not dispute the Deal during the Dispute Period, it is
              effectively the same as the Buyer marking the Deal as
              &ldquo;Completed,&rdquo; and there will be no further opportunity
              for the Buyer to dispute the Deal. Once a Deal reaches a status of
              &ldquo;Completed,&rdquo; the Cash Compensation (less transaction
              processing fees) for the Deal will immediately transfer to the
              Athlete&apos;s AthleteUp account balance (&ldquo;AthleteUp
              Balance&rdquo;). &nbsp;
            </p>
            <p>
              <b>Athlete&rsquo;s Proof of Work Requirement:</b>&nbsp;Athletes
              are required to compile Proof of Work as evidence to support and
              confirm that Deal deliverables were adequately completed. Athletes
              are encouraged, but not required, to submit Proof of Work to
              Buyers before marking a Deal as complete. If an Athlete chooses
              not to submit Proof of Work to a Buyer, the Athlete must have
              Proof of Work readily available to promptly submit upon request
              from either the Buyer or Customer Support. Proof of Work may take
              various forms, including but not limited to screen captures of
              completed social media-based deliverables (e.g., posts,
              follows/likes, comments), screen captures of virtual experiences
              (e.g., live video calls), photos or videos documenting in-person
              experiences (e.g., speaking engagements, meet &amp; greets), and
              paper trail evidence from the chat message thread on the Deal Page
              (e.g., Buyer providing written confirmation of the completion of
              the Deal; documented electronic transfer of digital product
              deliverables).
            </p>
            <p>
              <b>Deal Disputes:</b>&nbsp;Completed Deals are generally
              non-refundable, except in certain cases such as complete
              non-performance by the Athlete. In the event of such a situation,
              Buyers must initiate a dispute on the Deal Page during the Dispute
              Period to be eligible for a potential refund. When a Deal is
              disputed during the Dispute Period, all payments are immediately
              frozen/withheld by AthleteUp, and the Deal enters the Dispute
              Resolution Process. For more information on this process, refer to
              Section 12 &ldquo;Dispute Resolution Process&rdquo;.
            </p>
            <p>
              Note that Deals cannot be cancelled or refunded based on the
              quality of service or product delivered by the Athlete if the
              service or product was rendered/delivered as described in the
              Custom Deal Terms. If such a situation arises, it is up to the
              Athlete to determine whether they want to offer an alternative
              solution or a full or partial refund to the Buyer.
            </p>
            <p>
              <b>Cancellation of Deals:</b>&nbsp;The ability to cancel or
              terminate a Deal is based on the specific language in each
              individual Deal agreement. For a Deal that relies on the Standard
              Deal Terms, the Parties generally have the flexibility to cancel a
              Deal for any reason by providing written notice to the other
              Party, but compensation for any completed deliverables is not
              refunded to the Buyer. However, for a Deal that includes Custom
              Deal Terms, specific justifiable reasons for cancellation or
              termination may be stipulated for each Party.
            </p>
            <p>
              In some cases, AthleteUp may need to cancel a Deal to ensure the
              safety of both the Buyer and Athlete. This may occur if there is
              suspected fraud, harassment, unlawful behavior, a chargeback
              initiated by the Buyer&apos;s credit card company, termination,
              suspension, or deactivation of the Athlete or Buyer account, or
              any other violation of our Terms.&nbsp;
            </p>
            <ol className="font-20 fw-700 mt-5" start="11" id="11">
              <li>
                <h3 className="font-20 fw-700">
                  AthleteUp Deals: Standard Deal Terms&nbsp;
                </h3>
              </li>
            </ol>
            <p>
              As described in Section 10, Deals on AthleteUp are subject to
              Standard Deal Terms that outline the basic rights and
              responsibilities of Athletes and Buyers when they engage in Deals
              through the Site. These Standard Deal Terms are supplementary and
              subordinate to the Custom Deal Terms agreed upon by the Parties.
              Any conflicting terms in these Standard Deal Terms will be
              superseded by the Custom Deal Terms.
            </p>
            <p>
              These Standard Deal Terms may not be applicable to every Deal on
              the Site, and AthleteUp does not guarantee that they cover every
              situation that may arise in a Deal. It is the responsibility of
              each Party to carefully review all the terms of the Agreement and
              ensure that they do not conflict with any applicable laws, rules,
              or regulations. AthleteUp strongly recommends that Parties consult
              with a legal advisor before entering into any Agreement.
            </p>
            <p>
              The Standard Deal Terms include the following terms and
              conditions:
            </p>
            <ol>
              <li>
                <p>
                  <b>Deal Term:</b>&nbsp;The deal term (&ldquo;Deal Term&rdquo;)
                  ids when both Parties agree to the Custom Offer and the Deal
                  status first becomes &quot;In progress&quot; on the Deal Page
                  (the &quot;Deal Agreement Date&quot;). It ends on the date
                  that the Dispute Period ends after the completion of all
                  deliverables (the &ldquo;Deal Completion Date&rdquo;).
                </p>
              </li>
              <li>
                <p>
                  <b>Content Creation and Ownership:</b>&nbsp;Any content
                  created by the Athlete or utilizing the Athlete&rsquo;s name,
                  image, and likeness (NIL) in connection with a Deal
                  (collectively, &quot;Deal Content&quot;), including but not
                  limited to social media posts, videos, images, and other
                  promotional materials, shall be owned by the Athlete, subject
                  to the rights granted to the Buyer as outlined in the
                  agreement.
                </p>
              </li>
              <li>
                <p>
                  <b>Grant of Rights to the Buyer:</b>&nbsp;When a Deal involves
                  the creation of Deal Content, the Athlete grants the Buyer a
                  limited, non-exclusive, worldwide, royalty-free right and
                  license to use, reproduce, distribute, display, and promote
                  the Deal Content in its original form (if created by the
                  Athlete) for the purpose specified in the agreement, including
                  but not limited to the Buyer&apos;s promotional activities and
                  campaigns. The Buyer may modify the Deal Content only if the
                  agreement allows or with express written consent from the
                  Athlete.
                </p>
              </li>
              <li>
                <p>
                  <b>Buyer-Provided Content:</b>&nbsp;If the Buyer provides
                  content, including media, logos, trademarks, or other
                  intellectual property to the Athlete in connection with a Deal
                  Deliverable, the Buyer grants the Athlete a limited,
                  non-exclusive, worldwide, royalty-free right and license to
                  use the content solely for the purpose of fulfilling the Deal
                  deliverables. All rights to use the Buyer-provided content and
                  intellectual property end at the conclusion of the Deal Term.
                </p>
              </li>
              <li>
                <p>
                  <b>Deal Content Distribution Rights:</b>&nbsp;The Buyer may
                  distribute the Deal Content created by the Athlete through
                  various media channels, including but not limited to the
                  Buyer&apos;s website, social media accounts, television,
                  print, radio, and other electronic communications and
                  promotional materials, during the period specified in the
                  Agreement.
                </p>
              </li>
              <li>
                <p>
                  <b>Term of Content Rights:&nbsp;</b>The rights granted to the
                  Buyer under this Agreement shall continue for the Deal Term
                  plus an additional 30 days (the &quot;Content Rights
                  Term&quot;), unless otherwise specified in writing in the
                  agreement. After the Deal Completion Date, the Buyer may
                  continue to use the Deal Content for the purposes detailed in
                  the agreement, such as ongoing advertising campaigns or
                  promotions, for a period of 30 days.
                </p>
              </li>
              <li>
                <p>
                  <b>Social Media Content Deliverables:</b>&nbsp;If the Athlete
                  is required to post content to their social media account(s)
                  as a part of the Deliverables, they shall not delete the
                  subject social media post before 60 days have passed, unless
                  it is an ephemeral post that automatically disappears within a
                  preset period. In that case, the Athlete shall not delete the
                  post until it automatically disappears in the expected period.
                </p>
              </li>
              <li>
                <p>
                  <b>Whitelisting:&nbsp;</b>The Buyer may request whitelisting,
                  which grants them access to the Athlete&apos;s social media
                  accounts to control paid promotional activities related to the
                  sponsored or endorsed content created under the Deal
                  opportunity. The Athlete and the Buyer must mutually agree to
                  any whitelisting arrangements, which shall be clearly
                  communicated and documented in the agreement or via express
                  written permission from the Athlete.
                </p>
              </li>
              <li>
                <p>
                  <b>Exclusivity:</b>&nbsp;Unless otherwise specified in the
                  agreement, the Deal shall not grant any exclusivity rights to
                  the Buyer. The Athlete shall be free to engage in sponsorship,
                  endorsement, or other opportunities with other Brands during
                  and after the Deal Term.
                </p>
              </li>
              <li>
                <p>
                  <b>Approval of Deal Content:</b>&nbsp;Unless otherwise
                  required in the agreement, the Athlete shall have the right to
                  post the Deal Content on their social media account(s) without
                  requiring Buyer approval. However, the Buyer shall have the
                  right to request reasonable changes or modifications to the
                  Deal Content to ensure compliance with brand guidelines or
                  other requirements.
                </p>
              </li>
              <li>
                <p>
                  <b>Removal of Deal Content:</b>&nbsp;The Buyer reserves the
                  right to request the removal of any Deal Content at its sole
                  discretion. The Athlete shall promptly comply with any such
                  request and remove the Deal Content from all relevant
                  platforms or media.
                </p>
              </li>
              <li>
                <p>
                  <b>Prohibited Deal Content:</b>&nbsp;Prohibited Deal Content
                  includes, but is not limited to:&nbsp;
                </p>
                <ol type="a">
                  <li>
                    <p>
                      Content that is offensive, discriminatory, defamatory, or
                      pornographic in nature. This includes content that is
                      racist, sexist, homophobic, discriminatory based on
                      religion, nationality, disability, or any other protected
                      characteristic, or otherwise harmful or disparaging
                      towards any individual or group.
                    </p>
                  </li>
                  <li>
                    <p>
                      Content that is illegal, promotes illegal activities, or
                      encourages harmful behavior. This includes content that
                      involves violence, drug abuse, or any other illegal or
                      harmful activities.
                    </p>
                  </li>
                  <li>
                    <p>
                      Content that includes or incorporates any intellectual
                      property, including but not limited to trademarks, logos,
                      images, music, or other materials, that are owned by a
                      third party and have not been legally secured through a
                      valid license or authorization from the third party.
                    </p>
                  </li>
                  <li>
                    <p>
                      Content that is intended for or directed towards children
                      under the age of thirteen, as defined by applicable laws
                      and regulations, including but not limited to the
                      Children&apos;s Online Privacy Protection Act (COPPA) in
                      the United States.
                    </p>
                  </li>
                  <li>
                    <p>
                      Content that does not comply with any applicable law or
                      regulation governing proper advertisement disclosure. This
                      includes failing to disclose any sponsored, paid, or
                      gifted content in accordance with the Federal Trade
                      Commission (FTC) guidelines or any other relevant
                      advertising disclosure requirements.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <b>Relationship of the Parties:</b>&nbsp;The Buyer and the
                  Athlete are independent contractors under this agreement.
                  Nothing in the agreement shall be construed to create an
                  employer-employee relationship, partnership, joint venture,
                  agency, or any other form of legal association or relationship
                  between the Parties. The Athlete will not be entitled to any
                  employee benefits or compensation, and will be solely
                  responsible for all taxes, insurance, and other obligations
                  arising from the payments made under the agreement.
                </p>
              </li>
              <li>
                <p>
                  <b>Deal Termination:</b>&nbsp;Either Party may terminate the
                  Deal for any reason by providing written notice (e.g., via the
                  messaging thread on the Deal Page) to the other Party.
                  Termination will not affect the rights and obligations of the
                  Parties that have already accrued prior to the termination.
                  Payments for any Deal Deliverables not already completed will
                  be refunded to the Buyer, and payments for any completed
                  Deliverables will be disbursed to the Athlete.
                </p>
              </li>
              <li>
                <p>
                  <b>Dispute Resolution:</b>&nbsp;Any disputes arising out of or
                  in connection with this Deal shall be resolved through good
                  faith negotiations between the Parties. If the Parties are
                  unable to resolve the dispute through negotiations, the
                  Parties will follow the procedures outlined in Section 12 of
                  the AthleteUp Terms of Service related to &quot;Dispute
                  Resolution Process&quot; and Section 13 &quot;Continued
                  Disputes Between Athletes and Buyers.&quot;
                </p>
              </li>
              <li>
                <p>
                  <b>Indemnification:</b>&nbsp;The Buyer and the Athlete agree
                  to indemnify, defend, and hold harmless each other, and (if
                  applicable) their respective officers, directors, employees,
                  agents, and affiliates from and against any and all claims,
                  damages, liabilities, losses, and expenses, including
                  reasonable attorneys&apos; fees, arising out of or in
                  connection with any breach of the agreement, any act or
                  omission of either Party, or any violation of any applicable
                  law or regulation by either Party. Each Party&apos;s
                  indemnification obligation will survive the termination or
                  expiration of the Agreement.
                </p>
              </li>
              <li>
                <p>
                  <b>Representation and Warranties:</b>&nbsp;Both Parties
                  represent and warrant to each other that they have the legal
                  right and authority to enter into and perform the activities
                  and Deliverables detailed in this agreement, and that the
                  performance of this agreement by each Party will not violate
                  or infringe upon the rights of any third party. Both Parties
                  further represent and warrant that they will comply with all
                  applicable laws, regulations, and industry standards in the
                  performance of this agreement. Each Party&apos;s
                  representations and warranties will survive the termination or
                  expiration of this agreement.
                </p>
              </li>
              <li>
                <p>
                  <b>Modification and Entire Agreement:</b>&nbsp;In the event of
                  any conflict between these Standard Deal Terms and the Custom
                  Deal Terms of the agreement, the Custom Deal Terms shall
                  prevail and supersede these Standard Deal Terms. No
                  modification, amendment, or waiver of any provision of the
                  Standard Deal Terms or Custom Deal Terms will be effective
                  unless in writing and signed by both Parties.
                </p>
              </li>
              <li>
                <p>
                  <b>Governing Law:</b>&nbsp;This agreement will be governed by
                  and construed in accordance with the laws of the state of
                  North Carolina without regard to its conflicts of law
                  principles.
                </p>
              </li>
              <li>
                <p>
                  <b>Severability:</b>&nbsp;If any provision of the agreement is
                  determined to be invalid, illegal, or unenforceable, such
                  provision will be severed from the Agreement, and the
                  remaining provisions will continue in full force and effect.
                </p>
              </li>
              <li>
                <p>
                  <b>Waiver:</b>&nbsp;The failure of either Party to enforce any
                  right or provision of the agreement will not constitute a
                  waiver of such right or provision, and no waiver of any breach
                  or default will be deemed a waiver of any subsequent breach or
                  default.
                </p>
              </li>
            </ol>
            <ol className="font-20 fw-700 mt-5" start="12" id="12">
              <li>
                <h3 className="font-20 fw-700">Dispute Resolution Process</h3>
              </li>
            </ol>
            <p>
              Once an Athlete marks an Order or a Deal as completed, a 96-hour
              Dispute Period immediately commences. During this period, Buyers
              are notified and given the option to confirm that the Order or
              Deal has been completed or to initiate a dispute.
            </p>
            <p>
              If a Buyer initiates a dispute, the Order or Deal will enter
              AthleteUp&apos;s Dispute Resolution Process, which involves the
              following steps:
            </p>
            <ol>
              <li>
                <p>
                  Customer Support will contact the Parties via email or
                  telephone within 2 to 3 business days to gather information on
                  the dispute.
                </p>
              </li>
              <li>
                <p>
                  The payment related to the disputed Order or Deal will be
                  withheld by AthleteUp until the dispute is resolved.
                </p>
              </li>
              <li>
                <p>
                  Buyers and Athletes are encouraged to communicate and attempt
                  to settle the dispute amongst themselves via good faith
                  negotiations. The chat messaging thread on the Order Page /
                  Deal Page will remain open during the Dispute Resolution
                  Process for continued communication between the Parties.
                </p>
              </li>
              <li>
                <p>
                  If the Parties are able to resolve the dispute on their own,
                  Customer Support will assist in changing the deal status to
                  Completed when agreed by both Parties. If a full or partial
                  refund is warranted and mutually agreed upon by the Parties,
                  Customer Support can process this as well.
                </p>
              </li>
              <li>
                <p>
                  If the Parties are unable to settle the dispute themselves,
                  Customer Support will review the situation and determine the
                  appropriate outcome of the Dispute Resolution Process (the
                  &ldquo;Dispute Outcome&rdquo;) based on the application of
                  these Terms of Service. This includes reviewing the Order/Deal
                  details (including Custom Deal Terms and Standard Deal Terms
                  (if applicable)), reviewing the messaging thread, requesting
                  Proof of Work from the Athlete, interviewing the Parties,
                  requesting any other supporting evidence, and asking follow-up
                  questions. The Dispute Outcome may include, but is not limited
                  to, the following actions being taken:&nbsp;
                </p>
                <ol type="a">
                  <li>
                    <p>
                      Processing a return and refund for certain covered issues
                      arising from an Order involving a shipped physical product
                      (e.g., wrong item shipped, item damaged or lost during
                      shipment, etc.);
                    </p>
                  </li>
                  <li>
                    <p>
                      Changing the Order/Deal status from &quot;Disputed&quot;
                      to &quot;In progress&quot; if evidence suggests that there
                      is additional action required to adequately complete the
                      Order/Deal;
                    </p>
                  </li>
                  <li>
                    <p>
                      Changing the Order/Deal status from &quot;Disputed&quot;
                      to &quot;Completed&quot; (and initiating associated
                      payment to the Athlete) when evidence suggests that the
                      Order/Deal was adequately completed;
                    </p>
                  </li>
                  <li>
                    <p>
                      Changing the status of the Order/Deal from
                      &quot;Disputed&quot; to &quot;Cancelled&quot; and issuing
                      a full refund to the Buyer;
                    </p>
                  </li>
                  <li>
                    <p>
                      Issuing a partial refund to the Buyer and changing the
                      Order/Deal status to either &quot;Completed&quot; or
                      &quot;Cancelled&quot;; or
                    </p>
                  </li>
                  <li>
                    <p>
                      Other actions deemed appropriate at AthleteUp&rsquo;s sole
                      discretion.&nbsp;
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              <b>Dispute Outcome:</b>&nbsp;AthleteUp will make the final
              decision on the Dispute Outcome, and the Parties cannot seek
              financial recourse from AthleteUp. If the Parties still disagree
              after the Dispute Outcome, they must resolve the dispute directly
              as outlined in Section 13. AthleteUp may choose not to issue a
              Dispute Outcome in certain situations, such as lack of clear
              evidence or complex terms. If AthleteUp declines to issue a
              Dispute Outcome, the funds will continue to be held by AthleteUp
              until the Parties inform AthleteUp of the ultimate resolution.
            </p>
            <p>
              <b>Chargebacks:</b>&nbsp;Buyers cannot file a chargeback, as it is
              a violation of these Terms and may result in account suspension.
              If a chargeback is filed, the funds will not be eligible for a
              refund through the Dispute Resolution Process. AthleteUp will
              review chargebacks on behalf of Athletes, and if possible, will
              return revenue to the Athlete. If a chargeback occurs after a
              transfer to the Athlete&apos;s Stripe account, AthleteUp will
              reverse the transfer for the chargeback amount. If a chargeback
              occurs after the Athlete has already paid out the income to their
              external bank account, a negative balance will be created on the
              Athlete&rsquo;s Stripe account, and Stripe will attempt to debit
              the external account on file.
            </p>
            <ol className="font-20 fw-700 mt-5" start="13" id="13">
              <li>
                <h3 className="font-20 fw-700">
                  Continued Disputes Between Athletes and Buyers
                </h3>
              </li>
            </ol>
            <p>
              To resolve any disputes between Athletes and Buyers, both Parties
              should attempt to resolve the issue through direct communication
              in good faith. If the dispute cannot be resolved through direct
              communication, both Parties agree to engage in binding arbitration
              in accordance with the rules of the American Arbitration
              Association. The arbitration will be conducted by a single
              arbitrator who is mutually agreed upon by the Parties, and the
              decision of the arbitrator will be final and binding on both
              Parties.
            </p>
            <p>
              Users agree to handle any disputes between themselves without
              involving AthleteUp. AthleteUp will not be involved in any
              disputes between Parties and will be released from any claims,
              demands, and damages arising out of disputes between the Parties
              or with other users.
            </p>
            <p>
              The Parties agree to indemnify and hold AthleteUp harmless from
              any and all claims, demands, and damages arising out of disputes
              between the Parties or between the Parties and third parties. This
              includes any damages arising from the use of AthleteUp&apos;s
              services, any breach by a Party of their obligations under these
              Terms of Service, or any infringement of the rights of a third
              party.
            </p>
            <p>
              If a User violates this clause or any other provision of these
              Terms of Service, AthleteUp reserves the right to take appropriate
              action, including account suspension or termination.&nbsp;
            </p>
            <ol start="14" id="14" className="font-20 fw-700 mt-5">
              <li>
                <h3 className="font-20 fw-700">Disputes With AthleteUp</h3>
              </li>
            </ol>
            <p>
              If a dispute arises between a user and AthleteUp, both parties
              agree to resolve the dispute through arbitration. By agreeing to
              arbitration, you understand that you are giving up the right to
              sue in court and have a jury trial. The arbitration will be
              conducted by the American Arbitration Association (AAA) in
              accordance with its Commercial Arbitration Rules and Supplementary
              Procedures for Consumer-Related Disputes. The arbitration will
              take place in Mecklenburg County, North Carolina, unless otherwise
              required by the applicable AAA rules or applicable law. The
              arbitration will be conducted in English and may take place in
              person, online, by phone, or through the submission of documents.
            </p>
            <p>
              The arbitrator will have exclusive authority to resolve any
              dispute relating to the interpretation, applicability,
              enforceability, or formation of these terms of service, including
              any claim that all or any part of these terms of service are void
              or voidable. The arbitrator&apos;s decision will be final and
              binding, and judgment may be entered in any court of competent
              jurisdiction.
            </p>
            <p>
              Each party will be responsible for its own costs and expenses,
              including attorneys&apos; fees, associated with the arbitration
              proceedings. However, the prevailing party will be entitled to
              recover its reasonable attorneys&apos; fees and costs from the
              non-prevailing party. The parties agree to keep the arbitration
              proceedings confidential and not to disclose any information about
              the proceedings, except as required by law.
            </p>
            <p>
              Either party may seek injunctive relief in any court of competent
              jurisdiction to prevent the actual or threatened infringement,
              misappropriation, or violation of that party&apos;s intellectual
              property rights or other proprietary rights.
            </p>
            <p>
              By using the AthleteUp Site, Users agree to waive any right to
              participate in a class action lawsuit or class-wide arbitration.
              All claims will be resolved on an individual basis, and not as
              part of any group, consolidated, or representative action.
            </p>
            <ol start="15" id="15" className="font-20 fw-700 mt-5">
              <li>
                <h3 className="font-20 fw-700">
                  Physical Activity Liability Waiver
                </h3>
              </li>
            </ol>
            <p>
              This liability waiver (the &quot;Waiver&quot;) is an agreement
              between you (the Buyer or the participant), who may be agreeing to
              this Waiver for yourself or on behalf of your child or ward (the
              &quot;Minor&quot;), and the coach/trainer/athlete (the
              &ldquo;Athlete&rdquo;) that you have chosen to engage in physical
              activity with through the AthleteUp Site. By agreeing to this
              Waiver, you acknowledge that you have read, understood, and agree
              to be bound by its terms.
            </p>
            <p>
              <b>Assumption of Risk:</b>&nbsp;You understand and acknowledge
              that engaging in physical activity comes with inherent risks,
              including but not limited to, the risk of injury, illness, or
              death. You accept these risks and voluntarily choose to
              participate in physical activity with the Athlete through the
              Site.
            </p>
            <p>
              <b>Release and Waiver of Liability:</b>&nbsp;In consideration of
              being allowed to participate in physical activity with the Athlete
              through the Site, you, on behalf of yourself or your minor child,
              hereby release, waive, and discharge the Athlete and AthleteUp,
              and their respective officers, directors, employees, agents, and
              affiliates (collectively, the &quot;Released Parties&quot;) from
              any and all liability for any and all injuries, losses, damages,
              claims, or causes of action arising out of or related to your
              participation in the physical activity, including but not limited
              to, any injuries resulting from live action exercise or physical
              exertion of any kind.
            </p>
            <p>
              <b>Indemnification:</b>&nbsp;You agree to indemnify and hold
              harmless the Released Parties from any and all losses, damages,
              expenses, or costs, including reasonable attorneys&apos; fees,
              arising out of or related to your participation in the physical
              activity.
            </p>
            <p>
              <b>Binding Effect:</b>&nbsp;This Waiver shall be binding upon and
              inure to the benefit of the parties hereto and their respective
              heirs, successors, and assigns.
            </p>
            <p>
              <b>Governing Law and Jurisdiction:</b>&nbsp;This Waiver shall be
              governed by and construed in accordance with the laws of the
              jurisdiction in which the Athlete is located. Any legal action
              arising out of or related to this Waiver shall be brought in the
              courts of the jurisdiction in which the Athlete is located.
            </p>
            <p>
              <b>Severability:</b>&nbsp;If any provision of this Waiver is held
              to be invalid or unenforceable, the remaining provisions shall
              remain in full force and effect.
            </p>
            <p>
              <b>Acknowledgment:</b>&nbsp;By engaging in physical activity with
              the Athlete through the Site, you acknowledge that you have read
              and understand this Waiver, and that you agree to be bound by its
              terms. If you are agreeing to this Waiver on behalf of the Minor,
              you acknowledge that you have read and understand this Waiver, and
              that you agree to be bound by its terms on behalf of the Minor.
            </p>
            <ol start="16" id="16" className="font-20 fw-700 mt-5">
              <li>
                <h3 className="font-20 fw-700">User Representations</h3>
              </li>
            </ol>
            <p>By using the Site, you represent and warrant that:&nbsp;</p>
            <ol type="a">
              <li>
                <p>
                  All information you provide during registration and thereafter
                  is accurate, current, and complete;
                </p>
              </li>
              <li>
                <p>
                  You will update your information promptly as necessary to keep
                  it accurate, current, and complete;
                </p>
              </li>
              <li>
                <p>
                  You have the legal capacity to agree to these Terms of Service
                  and will comply with them;
                </p>
              </li>
              <li>
                <p>
                  You are not under the age of 13 and, if you are a minor, you
                  have received parental permission to use the Site;
                </p>
              </li>
              <li>
                <p>
                  You will not access the Site through automated or non-human
                  means, such as bots or scripts;
                </p>
              </li>
              <li>
                <p>
                  You will not use the Site for any illegal or unauthorized
                  purpose; and
                </p>
              </li>
              <li>
                <p>
                  Your use of the Site will not violate any applicable law or
                  regulation.&nbsp;
                </p>
              </li>
            </ol>
            <p>
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Site (or any portion thereof).
            </p>
            <ol start="17" id="17" className="font-20 fw-700 mt-5">
              <li>
                <h3 className="font-20 fw-700">
                  User Registration and Account Security
                </h3>
              </li>
            </ol>
            <p>
              When you register with the Site, you agree to create a unique
              login and password to access your account. You are solely
              responsible for maintaining the confidentiality of your login
              information and password, and you are liable for any activities
              that occur on your account.
            </p>
            <p>
              We reserve the right to remove, reclaim, or change a username you
              select if we determine, in our sole discretion, that such username
              is inappropriate, obscene, or otherwise objectionable. We may also
              update your profile or Shop page with publicly available
              information, such as social media statistics or links, unless you
              notify us in writing that you prefer not to have this information
              displayed on your account.
            </p>
            <p>
              If you choose to share your login information with an agent or
              another individual who may provide assistance and act on your
              behalf, you are solely responsible for any activities that occur
              on your account. AthleteUp is not responsible or liable for any
              damages, losses, expenses, or liabilities incurred related to any
              unauthorized access to or use of your account.
            </p>
            <p>
              You agree to notify AthleteUp immediately if you suspect any
              unauthorized use of your account or any other breach of security
              related to the Site. AthleteUp reserves the right to suspend or
              terminate your account at any time for any reason, including if we
              suspect that your account has been compromised or that you have
              violated these Terms of Service.
            </p>
            <p>
              We may require Users to verify information, including but not
              limited to identity, financial or contact information, and may
              refuse a User access to or suspend a User from all or part of the
              Site until the completion of such verification, including placing
              holds on your AthleteUp account balance. Users agree not to create
              an account using false identity, providing false information, or
              if you have previously been removed or banned from the Site.
            </p>
            <p>
              If there is an issue with your account or listings, your account
              balance may be held initially for 30 or 90 days based on risk,
              including chargebacks and other Buyer claims. You may be required
              to provide additional information regarding your account usage,
              your identity, your listings or your transactions to release the
              holds on these funds. However, additional infractions may extend
              the holding period up to 180 days to cover our risk. Funds for
              illegal activity or illegal items will not be released to you.
            </p>
            <p>
              Funds may be held for up to 180 days to seek reimbursement from an
              Athlete in any of the following circumstances: (a) we provide a
              refund to a Buyer because an Athlete did not deliver the goods, or
              we otherwise determine that a refund is warranted in a particular
              circumstance; (b) we discover erroneous or duplicate transactions
              related to an Athlete;{' '}
              <code
                style={{
                  fontSize: '16px',
                  color: '#042237',
                  fontWeight: 400,
                }}>
                (c)
              </code>{' '}
              we receive a chargeback or payment reversal from a Buyer&apos;s
              payment instrument issuer for the amount of a Buyer&apos;s
              purchase from an Athlete; or (d) an Athlete does not act in
              accordance with AthleteUp&rsquo;s Terms, including the sale of
              prohibited items and/or the creation of multiple AthleteUp
              accounts.
            </p>
            <p>
              We may obtain reimbursement of any amounts owed by an Athlete to
              AthleteUp by deducting from future payments owed to the Athlete,
              reducing any balance from the Athlete&rsquo;s AthleteUp Balance,
              charging any of the Athlete&apos;s payment instrument(s) on file,
              or seeking reimbursement from the Athlete by any other lawful
              means, including by using third-party collections services. You
              authorize us to use any or all of these methods to seek
              reimbursement.
            </p>
            <ol start="18" id="18" className="font-20 fw-700 mt-5">
              <li>
                <h3 className="font-20 fw-700">Payment Processing</h3>
              </li>
            </ol>
            <p>
              Payment processing services for the Site are provided by Stripe
              and are subject to the&nbsp;
              <a
                href="https://stripe.com/legal/connect-account?"
                target="_blank"
                rel="noreferrer"
                className="text-primary">
                Stripe Connected Account Agreement
              </a>{' '}
              (or the&nbsp;
              <a
                href="https://stripe.com/legal/connect-account/recipient?"
                target="_blank"
                rel="noreferrer"
                className="text-primary">
                Stripe Recipient Agreement
              </a>{' '}
              for non-U.S. Users), which includes the Stripe Terms of Service
              (collectively, the &ldquo;Stripe Services Agreement&rdquo;). By
              agreeing to these Terms or continuing to operate an account with
              AthleteUp, you agree to be bound by the Stripe Services Agreement,
              which may be modified by Stripe from time to time. As a condition
              of AthleteUp enabling payment processing services through Stripe,
              you agree to provide AthleteUp accurate and complete information
              about you and your business, and you authorize AthleteUp to share
              it and transaction information related to your use of the payment
              processing services with Stripe, tax authorities, or as otherwise
              necessary to provide the AthleteUp services.
            </p>
            <ol start="19" id="19" className="font-20 fw-700 mt-5">
              <li>
                <h3 className="font-20 fw-700">Free Trial for Athletes</h3>
              </li>
            </ol>
            <p>
              We offer a 60-day free trial to new Athlete users who register
              with the Site, unless otherwise specified as part of a promotional
              offer. At the conclusion of the free trial period, your account
              will automatically be charged the full subscription fee, unless
              you cancel your account prior to the end of the free trial period.
              We reserve the right to change the duration of the free trial
              offered to new Athlete users at any time without prior notice.
              Please note that each user is only eligible for one new customer
              free trial, regardless of whether they cancel their account and
              sign up again with a different email address. Our system tracks
              all free trial usage and reserves the right to terminate any
              accounts found to be in violation of this policy.
            </p>
            <ol start="20" id="20" className="font-20 fw-700 mt-5">
              <li>
                <h3 className="fw-700 font-20">
                  Athlete Subscription Cancellation
                </h3>
              </li>
            </ol>
            <p>
              Athletes can cancel their subscription at any time by logging into
              their account or contacting us using the contact information
              provided below. The cancellation will take effect at the end of
              the current paid term. Please note that cancellation of a
              subscription will not result in a refund of any fees already paid.
              If you are unsatisfied with our services, please email us at&nbsp;
              <a href="mailto:info@athleteup.com" className="text-primary">
                info@athleteup.com
              </a>
              .
            </p>
            <ol className="font-20 fw-700 mt-5" start="21" id="21">
              <li>
                <h3 className="font-20 fw-700">Taxes</h3>
              </li>
            </ol>
            <p>
              As an Athlete on AthleteUp, you are responsible for reporting all
              income earned on the platform and remitting any required payments
              to the tax authorities. AthleteUp does not provide tax, legal, or
              accounting advice. We recommend that you consult with a qualified
              tax professional to understand your specific tax obligations
              related to your use of the Site.
            </p>
            <p>
              AthleteUp will provide a 1099 form at year-end to each Athlete who
              has earned at least $600 in cash income during the calendar year,
              as required by law. The 1099 form will also be sent to the
              applicable tax authorities, including the IRS and state taxing
              authorities. It is your responsibility to ensure that your tax
              information, including your name, address, and taxpayer
              identification number (TIN), is accurate and up-to-date on the
              AthleteUp platform.
            </p>
            <p>
              Please be aware that tax laws and regulations can be complex and
              subject to change. We encourage you to stay informed and seek
              professional advice to ensure that you are in compliance with all
              applicable tax laws related to your use of the AthleteUp Site.
            </p>
            <p>
              As a marketplace facilitator, AthleteUp may have certain sales tax
              collection and remittance responsibilities in some jurisdictions.
              We will calculate and collect the appropriate sales taxes on your
              behalf where required by law and appropriate. Please note that tax
              rates applied to your transactions may vary based on a number of
              factors, including the location of the buyer and seller, the item
              being sold, and any applicable exemptions or exclusions.
            </p>
            <p>
              Currently, AthleteUp is registered to collect and remit sales
              taxes in the states of North Carolina and New Jersey. We may also
              be required to register in additional states and jurisdictions as
              required by law. If you have any questions about sales taxes or
              your responsibilities as a seller on AthleteUp, please consult
              with a qualified tax professional or contact our support team for
              guidance.&nbsp;
            </p>
            <ol className="font-20 fw-700 mt-5" start="22" id="22">
              <li>
                <h3 className="font-20 fw-700 ">Prohibited User Activities</h3>
              </li>
            </ol>
            <p>
              You are only permitted to use the Site for its intended purpose.
              Any use of the Site for commercial purposes must be specifically
              approved by us. As a user of the Site, you agree not to engage in
              any of the following activities:
            </p>
            <ul>
              <li>
                <p>
                  Collecting data or content from the Site, whether directly or
                  indirectly, to create a compilation, database, directory, or
                  other collection without our written permission.
                </p>
              </li>
              <li>
                <p>
                  Attempting to obtain sensitive account information, such as
                  user passwords, by deceiving or misleading us or other users.
                </p>
              </li>
              <li>
                <p>
                  Disabling or circumventing security features of the Site or
                  its content that prevent or restrict access or use.
                </p>
              </li>
              <li>
                <p>
                  Engaging in activities that harm or tarnish our reputation or
                  the reputation of the Site, as determined by us.
                </p>
              </li>
              <li>
                <p>
                  Using information obtained from the Site to harass, abuse, or
                  harm another person.
                </p>
              </li>
              <li>
                <p>
                  Misusing our support services or making false reports of abuse
                  or misconduct.
                </p>
              </li>
              <li>
                <p>
                  Violating any applicable laws or regulations in your use of
                  the Site.
                </p>
              </li>
              <li>
                <p>Framing or linking to the Site without our authorization.</p>
              </li>
              <li>
                <p>
                  Uploading or transmitting viruses, Trojan horses, or other
                  material that interferes with the Site&apos;s operation or
                  disrupts another user&apos;s experience, including excessive
                  use of capital letters or spamming.
                </p>
              </li>
              <li>
                <p>
                  Engaging in any automated use of the Site, such as using
                  scripts to send comments or messages, or using data mining or
                  other data extraction tools.
                </p>
              </li>
              <li>
                <p>
                  Removing copyright or proprietary rights notices from any
                  content.
                </p>
              </li>
              <li>
                <p>
                  Impersonating another user or person or using another
                  user&apos;s username.
                </p>
              </li>
              <li>
                <p>
                  Uploading or transmitting material that collects or transmits
                  information without a user&apos;s knowledge or consent, such
                  as clear graphics interchange formats, web bugs, or cookies.
                </p>
              </li>
              <li>
                <p>
                  Interfering with or disrupting the Site or any connected
                  networks or services.
                </p>
              </li>
              <li>
                <p>
                  Harassing, intimidating, or threatening our employees or
                  agents who provide Marketplace Listings to you.
                </p>
              </li>
              <li>
                <p>
                  Repeatedly creating new Campaigns as a Brand without
                  significant modifications.
                </p>
              </li>
              <li>
                <p>
                  Attempting to bypass measures designed to prevent or restrict
                  access to the Site or any of its features.
                </p>
              </li>
              <li>
                <p>
                  Copying or adapting the Site&apos;s software, including Flash,
                  PHP, HTML, JavaScript, or other code.
                </p>
              </li>
              <li>
                <p>
                  Decompiling, disassembling, or reverse-engineering any
                  software that makes up a part of the Site.
                </p>
              </li>
              <li>
                <p>
                  Using any automated system, such as a spider, robot, cheat
                  utility, scraper, or offline reader, to access the Site
                  without authorization.
                </p>
              </li>
              <li>
                <p>
                  Using a buying or purchasing agent to make purchases on the
                  Site.
                </p>
              </li>
              <li>
                <p>
                  Making unauthorized use of Marketplace Listings, such as
                  collecting usernames or email addresses for unsolicited email
                  or creating user accounts under false pretenses.
                </p>
              </li>
              <li>
                <p>
                  Using the Site or its content to compete with us or engage in
                  any revenue-generating or commercial activities.
                </p>
              </li>
            </ul>
            <ol className="font-20 fw-700 mt-5" start="23" id="23">
              <li>
                <h3 className="font-20 fw-700 ">
                  User Generated Contributions
                </h3>
              </li>
            </ol>
            <p>
              As a user of the Site, you may have the opportunity to contribute
              content and materials, including text, photos, videos, comments,
              suggestions, or personal information (&quot;Contributions&quot;).
              Your Contributions may be viewed by other users of the Site or
              third-party websites, and they will be subject to the Site Privacy
              Policy.
            </p>
            <p>
              By creating or making available any Contributions, you represent
              and warrant that:
            </p>
            <ul>
              <li>
                <p>
                  Your Contributions do not infringe the proprietary rights of
                  any third party, including copyrights, patents, trademarks,
                  trade secrets, or moral rights.
                </p>
              </li>
              <li>
                <p>
                  You are the creator and owner of your Contributions, or you
                  have obtained the necessary licenses, rights, consents,
                  releases, and permissions to use and authorize us, the Site,
                  and other users of the Site to use your Contributions in any
                  way allowed by the Site and these Terms of Use.
                </p>
              </li>
              <li>
                <p>
                  You have obtained the written consent, release, and/or
                  permission of every identifiable person depicted in your
                  Contributions to use their name or likeness in connection with
                  the inclusion and use of your Contributions.
                </p>
              </li>
              <li>
                <p>
                  Your Contributions are accurate, truthful, and not misleading.
                </p>
              </li>
              <li>
                <p>
                  Your Contributions are not unsolicited or unauthorized
                  advertising, promotional materials, pyramid schemes, chain
                  letters, spam, mass mailings, or other forms of solicitation.
                </p>
              </li>
              <li>
                <p>
                  Your Contributions are not obscene, lewd, lascivious, violent,
                  harassing, libelous, slanderous, or otherwise objectionable,
                  as determined by us.
                </p>
              </li>
              <li>
                <p>
                  Your Contributions do not ridicule, mock, disparage,
                  intimidate, or abuse anyone.
                </p>
              </li>
              <li>
                <p>
                  Your Contributions are not used to harass or threaten any
                  other person, nor do they promote violence against a specific
                  person or group of people.
                </p>
              </li>
              <li>
                <p>
                  Your Contributions do not violate any applicable law,
                  regulation, or rule.
                </p>
              </li>
              <li>
                <p>
                  Your Contributions do not violate the privacy or publicity
                  rights of any third party.
                </p>
              </li>
              <li>
                <p>
                  Your Contributions do not violate any law concerning child
                  pornography or other laws intended to protect minors&apos;
                  health and well-being.
                </p>
              </li>
              <li>
                <p>
                  Your Contributions do not include any offensive comments
                  connected to race, national origin, gender, sexual preference,
                  or physical handicap.
                </p>
              </li>
              <li>
                <p>
                  Your Contributions do not violate any provision of these Terms
                  of Use or any applicable law or regulation.
                </p>
              </li>
            </ul>
            <p>
              Any violation of these requirements constitutes a violation of
              these Terms and may lead to the termination or suspension of your
              rights to use the Site and the Marketplace Listings.
            </p>
            <ol className="font-20 fw-700 mt-5" start="24" id="24">
              <li>
                <h3 className="font-20 fw-700 ">
                  Ownership of Content and Marks
                </h3>
              </li>
            </ol>
            <p>
              The Site and all of its Content and Marks are the exclusive
              property of AthleteUp or its licensors and are protected by
              intellectual property laws. You may access and use the Site and
              its Content for personal, non-commercial purposes only. Except as
              expressly authorized by AthleteUp, you may not copy, reproduce,
              distribute, sell, license, or otherwise exploit any part of the
              Site or its Content for any commercial purpose.
            </p>
            <ol className="font-20 fw-700 mt-5" start="25" id="25">
              <li>
                <h3 className="font-20 fw-700">
                  License for User Contributions
                </h3>
              </li>
            </ol>
            <p>
              By posting Contributions on the Site or on social media platforms
              related to your Shop page/profile, Marketplace Listings, Orders,
              Campaigns, or Deals with another User, you grant AthleteUp a
              non-exclusive, worldwide, perpetual, royalty-free license to use,
              reproduce, modify, adapt, publish, translate, create derivative
              works from, distribute, and display such Contributions for
              marketing and promotional purposes related to the Site and our
              services. You retain all ownership and intellectual property
              rights in your Contributions.
            </p>
            <p>
              You are solely responsible for your Contributions and any
              statements or representations made in them. AthleteUp has the
              right, but not the obligation, to monitor, edit, or delete any
              Contributions at any time and for any reason. AthleteUp is not
              liable for any damages resulting from the use or disclosure of any
              Contributions.
            </p>
            <ol className="font-20 fw-700 mt-5" start="26" id="26">
              <li>
                <h3 className="font-20 fw-700">Social Media</h3>
              </li>
            </ol>
            <p>
              Social media integration is available on our Site, allowing you to
              link your account with your third-party social media accounts
              (&quot;Third-Party Accounts&quot;). You may provide your
              Third-Party Account login information through the Site or allow us
              to access your Third-Party Account, as permitted by the applicable
              terms and conditions governing your use of each Third-Party
              Account. By linking your account with a Third-Party Account, you
              warrant that you have the right to disclose your login information
              and grant us access without violating any terms and conditions.
              You also understand that we may access, store, and make available
              any content that you&apos;ve provided and stored in your
              Third-Party Account (the &quot;Social Network Content&quot;) on
              the Site through your account, including your friend/follower
              lists.
            </p>
            <p>
              We may receive additional information from your Third-Party
              Account, depending on the privacy settings and notification
              preferences you&apos;ve set. If you post personally identifiable
              information on your Third-Party Accounts, it may also be available
              on the Site through your account. However, if a Third-Party
              Account becomes unavailable or we lose access, the Social Network
              Content may no longer be available on the Site.
            </p>
            <p>
              You may disconnect your Third-Party Account from the Site at any
              time. However, please note that any relationship you have with the
              third-party service providers governing your Third-Party Accounts
              is solely governed by your agreement with them. We are not
              responsible for any Social Network Content and do not review it
              for accuracy, legality, or non-infringement.
            </p>
            <p>
              You can deactivate the connection between the Site and your
              Third-Party Account through your account settings or by contacting
              us. We will attempt to delete any information obtained through
              your Third-Party Account, except for your username and profile
              picture that are associated with your account.
            </p>
            <ol className="font-20 fw-700 mt-5" start="27" id="27">
              <li>
                <h3 className="font-20 fw-700">Submissions</h3>
              </li>
            </ol>
            <p>
              We understand that you may have valuable insights and ideas to
              share with us about the Site or the Marketplace Listings. However,
              it is important to note that any suggestions, comments, ideas, or
              other information that you provide to us (&quot;Submissions&quot;)
              become our sole property and we may use, disseminate, and
              commercialize them without any obligation or compensation to you.
              By submitting any content, you represent and warrant that you have
              the right to do so, and you waive all moral rights to the content.
              You acknowledge and agree that we have no obligation to keep any
              Submissions confidential, and that we have no liability for any
              use or disclosure of such Submissions.
            </p>
            <ol className="font-20 fw-700 mt-5" start="28" id="28">
              <li>
                <h3 className="font-20 fw-700">
                  Third Party Websites and Contents
                </h3>
              </li>
            </ol>
            <p>
              We want to make it clear that any links to other websites
              (&quot;Third-Party Websites&quot;) or third-party content
              (&quot;Third-Party Content&quot;) available through the Site are
              not our responsibility. We do not investigate or monitor the
              accuracy, appropriateness, or completeness of Third-Party Websites
              or Third-Party Content, and we are not responsible for any
              opinions, content, products, or services offered on those
              websites. By accessing Third-Party Websites or using Third-Party
              Content, you assume all risk, and acknowledge that our Terms of
              Service do not apply. You should review the applicable terms and
              policies of any Third-Party Website or application you use or
              install. We are not responsible for any purchases made through
              Third-Party Websites, and you agree to hold us harmless for any
              harm caused by your purchase of such products or services.&nbsp;
            </p>
            <ol className="font-20 fw-700 mt-5" start="29" id="29">
              <li>
                <h3 className="font-20 fw-700">U.S. Government Rights</h3>
              </li>
            </ol>
            <p>
              We want to clarify that our services are considered
              &quot;commercial items&quot; according to Federal Acquisition
              Regulation (&ldquo;FAR&rdquo;) 2.101. If our services are acquired
              by any agency not within the Department of Defense
              (&ldquo;DOD&rdquo;), they will be subject to these Terms of
              Service in accordance with FAR 12.212 (for computer software) and
              FAR 12.211 (for technical data). If our services are acquired by
              any agency within the Department of Defense, they will be subject
              to these Terms of Service in accordance with Defense Federal
              Acquisition Regulation (&ldquo;DFARS&rdquo;) 227.7202-3. DFARS
              252.227-7015 also applies to technical data acquired by the DOD.
              This U.S. Government Rights clause supersedes any other FAR,
              DFARS, or other clause or provision that addresses government
              rights in computer software or technical data under these Terms of
              Service.&nbsp;
            </p>
            <ol className="font-20 fw-700 mt-5" start="30" id="30">
              <li>
                <h3 className="font-20 fw-700">Site Management</h3>
              </li>
            </ol>
            <p>
              We may, but are not obligated to, monitor the Site for any
              violation of these Terms of Service. If we, in our sole
              discretion, determine that any user has violated the law or these
              Terms of Service, we may take appropriate legal action, including
              reporting the user to law enforcement authorities. Additionally,
              we may, at our sole discretion and without any obligation, refuse,
              restrict access to, limit the availability of, or disable (to the
              extent technically feasible) any of your Contributions or any
              portion thereof. We reserve the right, without notice or
              liability, to remove from the Site or disable any files and
              content that are excessive in size or are in any way burdensome to
              our systems. We may also manage the Site in a manner that protects
              our rights and property and facilitates its proper functioning.
            </p>
            <ol className="font-20 fw-700 mt-5" start="31" id="31">
              <li>
                <h3 className="font-20 fw-700">Privacy Policy</h3>
              </li>
            </ol>
            <p>
              We are committed to protecting data privacy and security. By using
              the Site, you agree to our Privacy Policy posted on the Site,
              which is incorporated into these Terms of Service. Please note
              that the Site is hosted in the United States. If you access the
              Site from a region with laws or requirements that differ from
              those of the United States governing personal data collection,
              use, or disclosure, you agree to transfer your data to the United
              States by continuing to use the Site. Furthermore, we do not
              knowingly accept, request, or solicit information from children,
              nor do we market to them. Accordingly, we comply with the U.S.
              Children&rsquo;s Online Privacy Protection Act, and if we become
              aware that a child under 13 has provided personal information to
              us without verifiable parental consent, we will promptly delete
              such information from the Site.
            </p>
            <ol className="font-20 fw-700 mt-5" start="32" id="32">
              <li>
                <h3 className="font-20 fw-700">
                  Digital Millennium Copyright Act (DMCA) Notice and Policy
                </h3>
              </li>
            </ol>
            <p>
              &nbsp;At AthleteUp, we respect the intellectual property rights of
              others and take copyright infringement seriously. If you believe
              that any material available on or through our Site infringes upon
              any copyright you own or control, please immediately notify our
              Designated Copyright Agent using the contact information provided
              below (a &ldquo;Notification&rdquo;). A copy of your Notification
              will be sent to the person who posted or stored the material
              addressed in the Notification. Please be aware that under federal
              law, you may be held liable for damages if you make material
              misrepresentations in a Notification. Therefore, if you are
              uncertain whether material located on or linked to by our Site
              infringes your copyright, we recommend that you seek advice from a
              qualified attorney.
            </p>
            <p>
              To comply with the DMCA, all Notifications must meet the
              requirements of DMCA 17 U.S.C. &sect; 512
              <code
                style={{
                  fontSize: '16px',
                  color: '#042237',
                  fontWeight: 400,
                }}>
                (c)
              </code>
              (3) and include the following information:
            </p>
            <ol>
              <li>
                <p>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed;
                </p>
              </li>
              <li>
                <p>
                  Identification of the copyrighted work claimed to have been
                  infringed, or, if multiple copyrighted works on the Site are
                  covered by the Notification, a representative list of such
                  works on the Site;
                </p>
              </li>
              <li>
                <p>
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled,
                  and information reasonably sufficient to permit us to locate
                  the material;
                </p>
              </li>
              <li>
                <p>
                  Information reasonably sufficient to permit us to contact the
                  complaining party, such as an address, telephone number, and,
                  if available, an email address at which the complaining party
                  may be contacted;
                </p>
              </li>
              <li>
                <p>
                  A statement that the complaining party has a good faith belief
                  that use of the material in the manner complained of is not
                  authorized by the copyright owner, its agent, or the law; and
                </p>
              </li>
              <li>
                <p>
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that the complaining
                  party is authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed upon.
                </p>
              </li>
            </ol>
            <p>
              If we receive a Notification that complies with the DMCA, we will
              promptly remove or disable access to the allegedly infringing
              material. We will also notify the party who posted or stored the
              material addressed in the Notification, and provide them with a
              copy of the Notification. If the allegedly infringing material is
              removed or access is disabled as a result of a Notification, and
              you believe that your own copyrighted material has been removed
              from the Site as a result of a mistake or misidentification, you
              may submit a written counter-notification to our Designated
              Copyright Agent using the contact information provided below (a
              &ldquo;Counter Notification&rdquo;).
            </p>
            <p>
              To be an effective Counter Notification under the DMCA, your
              Counter Notification must include substantially the following:
            </p>
            <ol>
              <li>
                <p>
                  Identification of the material that has been removed or
                  disabled and the location at which the material appeared
                  before it was removed or disabled;
                </p>
              </li>
              <li>
                <p>
                  A statement that you consent to the jurisdiction of the
                  Federal District Court in which your address is located, or if
                  your address is outside the United States, for any judicial
                  district in which we are located;
                </p>
              </li>
              <li>
                <p>
                  A statement that you will accept service of process from the
                  party that filed the Notification or the party&apos;s agent;
                </p>
              </li>
              <li>
                <p>Your name, address, and telephone number;</p>
              </li>
              <li>
                <p>
                  A statement under penalty of perjury that you have a good
                  faith belief that the material in question was removed or
                  disabled as a result of a mistake or misidentification of the
                  material to be removed or disabled; and
                </p>
              </li>
              <li>
                <p>Your physical or electronic signature.</p>
              </li>
            </ol>
            <p>
              If we receive a valid Counter Notification that complies with the
              DMCA, we will restore the removed or disabled material, unless we
              first receive notice from the party filing the Notification
              informing us that such party has filed a court action to restrain
              you from engaging in infringing activity related to the material
              in question. Please note that if you materially misrepresent that
              the disabled or removed content was removed by mistake or
              misidentification, you may be liable for damages, including costs
              and attorney&apos;s fees. Filing a false Counter Notification
              constitutes perjury.
            </p>
            <p>
              Designated Copyright AgentAthleteUp Compliance
              Departmentinfo@athleteup.com
            </p>
            <ol className="font-20 fw-700 mt-5" start="33" id="33">
              <li>
                <h3 className="font-20 fw-700">Term And Terminations</h3>
              </li>
            </ol>
            <p>
              You agree that these Terms of Service will remain in effect as
              long as you use the Site. We reserve the right to deny access to
              the Site, including blocking certain IP addresses, without notice
              or liability, to any person who breaches any provision of these
              Terms of Service, any applicable law, or regulation. We may
              terminate your use of the Site or delete your account and any
              content you posted, without warning or notice, at any time and for
              any reason at our sole discretion. If we terminate or suspend your
              account, you are prohibited from creating a new account or
              accessing the Site under a fake or borrowed name, or the name of
              any third party.
            </p>
            <p>
              In addition to terminating or suspending your account, we reserve
              the right to pursue appropriate legal action, including civil,
              criminal, and injunctive redress.
            </p>
            <ol className="font-20 fw-700 mt-5" start="34" id="34">
              <li>
                <h3 className="font-20 fw-700">
                  Modifications And Interruptions
                </h3>
              </li>
            </ol>
            <p>
              We reserve the right to modify, change, or remove any content on
              the Site at any time and for any reason at our sole discretion,
              without notice or liability to you. We are not obligated to update
              any information on the Site, and we reserve the right to modify or
              discontinue all or part of the Site at any time without notice.
            </p>
            <p>
              We cannot guarantee uninterrupted access to the Site due to
              hardware, software, or maintenance-related problems, which may
              cause interruptions, delays, or errors. We are not liable for any
              loss, damage, or inconvenience caused by your inability to access
              or use the Site during any downtime or discontinuance of the Site.
              We are not obligated to maintain and support the Site, or to
              provide corrections, updates, or releases in connection with it.
            </p>
            <ol className="font-20 fw-700 mt-5" start="35" id="35">
              <li>
                <h3 className="font-20 fw-700">Governing Law</h3>
              </li>
            </ol>
            <p>
              These Terms of Service and your use of the Site are governed by
              the laws of the State of North Carolina, without regard to its
              conflict of law principles. Any legal action or proceeding arising
              out of or relating to these Terms of Service or the Site will be
              brought exclusively in a federal or state court in North Carolina.
            </p>
            <ol className="font-20 fw-700 mt-5" start="36" id="36">
              <li>
                <h3 className="font-20 fw-700">Corrections</h3>
              </li>
            </ol>
            <p>
              We strive to provide accurate and up-to-date information on the
              Site, but there may be typographical errors, inaccuracies, or
              omissions, including descriptions, pricing, availability, and
              other information. We reserve the right to correct any errors,
              inaccuracies, or omissions and to change or update the information
              on the Site at any time without notice.
            </p>
            <ol className="font-20 fw-700 mt-5" start="37" id="37">
              <li>
                <h3 className="font-20 fw-700">Disclaimer</h3>
              </li>
            </ol>
            <p>
              We make every effort to ensure the accuracy and completeness of
              the content provided on our site, but we cannot guarantee its
              reliability, suitability, or availability for your intended
              purpose. Therefore, your use of the site and our services is at
              your own risk. We do not make any warranties, express or implied,
              regarding the site and your use thereof, including but not limited
              to the implied warranties of merchantability, fitness for a
              particular purpose, and non-infringement. We will not be held
              liable for any errors, mistakes, or inaccuracies of content and
              materials, any personal injury or property damage resulting from
              your access to and use of the site, any unauthorized access to or
              use of our secure servers and/or any and all personal information
              and/or financial information stored therein, any interruption or
              cessation of transmission to or from the site, any bugs, viruses,
              trojan horses, or the like which may be transmitted to or through
              the site by any third party, and any errors or omissions in any
              content and materials or for any loss or damage of any kind
              incurred as a result of the use of any content posted,
              transmitted, or otherwise made available via the site. We do not
              warrant, endorse, guarantee, or assume responsibility for any
              product or service advertised or offered by a third party through
              the site or any hyperlinked website. We are not responsible for
              monitoring any transaction between you and any third-party
              providers of products or services. As with the purchase of a
              product or service through any medium or in any environment, you
              should use your best judgment and exercise caution where
              appropriate.
            </p>
            <ol className="font-20 fw-700 mt-5" start="38" id="38">
              <li>
                <h3 className="font-20 fw-700">Limitations of Liability</h3>
              </li>
            </ol>
            <p>
              To the fullest extent permitted by applicable law, we and our
              directors, employees, or agents will not be liable to you or any
              third party for any direct, indirect, consequential, exemplary,
              incidental, special, or punitive damages, including but not
              limited to lost profit, lost revenue, loss of data, or other
              damages arising from your use of the site, even if we have been
              advised of the possibility of such damages. Notwithstanding
              anything to the contrary contained herein, our liability to you
              for any cause whatsoever and regardless of the form of the action
              will at all times be limited to the lesser of the amount paid, if
              any, by you to us during the six (6) month period prior to any
              cause of action arising or $1,000.00 USD. The limitations of
              liability contained in these terms of service shall apply
              regardless of any failure of essential purpose of any limited
              remedy. Certain laws may not allow the exclusion or limitation of
              certain damages or warranties, so some or all of the above
              disclaimers or limitations may not apply to you, and you may have
              additional rights under applicable law.
            </p>
            <ol className="font-20 fw-700 mt-5" start="39" id="39">
              <li>
                <h3 className="font-20 fw-700">Indemnification</h3>
              </li>
            </ol>
            <p>
              We understand the importance of protecting our site, as well as
              the users of our site. Therefore, you agree to defend, indemnify,
              and hold us and our subsidiaries, affiliates, officers, agents,
              partners, and employees harmless from any loss, damage, liability,
              claim, or demand made by any third party that arises out of or is
              related to (1) your Contributions; (2) use of the Site; (3) breach
              of these Terms of Service; (4) any breach of your representations
              and warranties set forth in these Terms of Service; (5) your
              violation of the rights of a third party, including but not
              limited to intellectual property rights; or (6) any overt harmful
              act toward any other user of the Site with whom you connected via
              the Site. We reserve the right, at your expense, to assume the
              exclusive defense and control of any matter for which you are
              required to indemnify us, and you agree to cooperate with our
              defense of such claims. We will use reasonable efforts to notify
              you of any such claim, action, or proceeding which is subject to
              this indemnification upon becoming aware of it.
            </p>
            <ol className="font-20 fw-700 mt-5" start="40" id="40">
              <li>
                <h3 className="font-20 fw-700">User Data</h3>
              </li>
            </ol>
            <p>
              We understand that you rely on our site to manage important data.
              Therefore, we will maintain certain data that you transmit to the
              Site for the purpose of managing the performance of the Site, as
              well as data relating to your use of the Site. Although we perform
              regular routine backups of data, you are solely responsible for
              all data that you transmit or that relates to any activity you
              have undertaken using the Site. You agree that we shall have no
              liability to you for any loss or corruption of any such data, and
              you hereby waive any right of action against us arising from any
              such loss or corruption of such data.
            </p>
            <ol className="font-20 fw-700 mt-5" start="41" id="41">
              <li>
                <h3 className="font-20 fw-700">
                  Electronic Communications, Transactions, And Signatures
                </h3>
              </li>
            </ol>
            <p>
              We understand that electronic communication has become a standard
              form of communication, and we want to make sure that we can
              communicate with our users as efficiently as possible. By visiting
              the Site, sending us emails, sending chat messages, and completing
              online forms, you are engaging in electronic communications. You
              consent to receive electronic communications, and you agree that
              all agreements, notices, disclosures, and other communications we
              provide to you electronically, via email and on the Site, satisfy
              any legal requirement that such communication be in writing. You
              hereby agree to the use of electronic agreements, signatures,
              contracts, orders, and other records, and to electronic delivery
              of notices, policies, and records of transactions initiated or
              completed by us or via the Site. You acknowledge that you are not
              required to provide an original signature or receive
              non-electronic records. You also waive any rights or requirements
              under any statutes, regulations, rules, ordinances, or other laws
              in any jurisdiction which require an original signature or
              delivery or retention of non-electronic records, or to payments or
              the granting of credits by any means other than electronic means.
            </p>
            <ol className="font-20 fw-700 mt-5" start="42" id="42">
              <li>
                <h3 className="font-20 fw-700">
                  California Users and Residents
                </h3>
              </li>
            </ol>
            <p>
              We value our relationship with our users in California and want to
              ensure their satisfaction. If any complaint with us is not
              satisfactorily resolved, you can contact the Complaint Assistance
              Unit of the Division of Consumer Services of the California
              Department of Consumer Affairs in writing at 1625 North Market
              Blvd., Suite N 112, Sacramento, California 95834, or by telephone
              at (800) 952-5210 or (916) 445-1254.
            </p>
            <ol className="font-20 fw-700 mt-5" start="43" id="43">
              <li>
                <h3 className="font-20 fw-700">Miscellaneous</h3>
              </li>
            </ol>
            <p>
              These Terms of Service and any policies or operating rules posted
              on the Site or in respect to the Site constitute the entire
              agreement between you and us. Our failure to enforce any right or
              provision of these Terms of Service shall not be deemed a waiver
              of such right or provision. These Terms of Service shall be
              interpreted in accordance with the laws of the jurisdiction in
              which you reside or, if different, the jurisdiction in which we
              are located. Any dispute arising under or relating to these Terms
              of Service, the Site, or the services provided through the Site
              shall be resolved exclusively by the competent courts located in
              the jurisdiction in which you reside or, if different, the
              jurisdiction in which we are located. If any provision of these
              Terms of Service is found to be invalid or unenforceable, such
              provision shall be struck and the remaining provisions shall be
              enforced. These Terms of Service are not assignable, transferable,
              or sublicensable by you except with our prior written consent.
              These Terms of Service may not be modified by you except in
              writing duly signed by both parties. These Terms of Service do not
              create any agency, partnership, joint venture, or employment
              relationship between you and us. You acknowledge that you have
              read these Terms of Service, understand them, and agree to be
              bound by them.&nbsp;
            </p>
            <ol className="font-20 fw-700 mt-5" start="44" id="44">
              <li>
                <h3 className="font-20 fw-700">Contact Us</h3>
              </li>
            </ol>
            <p>
              In order to resolve a complaint regarding the Site or to receive
              further information regarding use of the Site, please contact us
              at:
            </p>
            <p>
              AthleteUp, Inc.
              <a href="tel:704-459-7188" className="text-dark d-block ">
                Phone:704-459-7188
              </a>
              <a href="mailto:info@athleteup.com" className="text-primary">
                info@athleteup.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default TermsService;
