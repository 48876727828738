import React, {useState, useEffect, useRef} from 'react';
import {Input, Select} from 'antd';
import {shippingData} from './MockData';
import {Formik} from 'formik';
import * as yup from 'yup';
import {Tooltip} from 'antd';

const Pricing = (props) => {
  const {
    setListingData,
    selectedCard,
    uspsBoxType,
    next,
    prev,
    physicalProduct,
    listingData,
    countries,
    getStateList,
    stateList,
    setListingPhysicalVal,
  } = props;
  const [package_type, setPackageType] = useState();
  const [dimensionsBOX, setDimesionsBox] = useState(true);
  const {Option} = Select;

  const uspsBoxTypeArray = Object.keys(uspsBoxType).map((key) => {
    return uspsBoxType[key];
  });

  const inputRef = useRef(null);
  let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
  let schema = yup.object().shape({
    price: yup
      .number()
      .test(
        'is-decimal',
        'The amount should be a decimal with maximum two digits after comma',
        (val) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        },
      )
      .min(1, 'Price must be greater than $1')
      .max(999999999)
      // .positive('Must be more than 0')
      .required('Price is required'),
  });

  useEffect(() => {
    if (package_type === 1) {
      setDimesionsBox(false);
    } else {
      setDimesionsBox(true);
    }
  }, [package_type]);

  return (
    <div className="overview">
      <h1 className="fw-bold text-center">Pricing</h1>
      <p className="mb-5 color-muted text-center title">
        Set the price you would like to charge for this listing.
      </p>
      <div className="mt-5 mb-3 overview mw-570 mx-auto">
        <Formik
          initialValues={{
            price: listingData?.price ? listingData?.price : '',
            package_type: 'Custom Package',
            dimensions: {
              // length: '',
              // width: '',
              // height: '',
              length: listingData?.dimensions?.length
                ? listingData?.dimensions?.length
                : '',
              width: listingData?.dimensions?.width
                ? listingData?.dimensions?.width
                : '',
              height: listingData?.dimensions?.height
                ? listingData?.dimensions?.height
                : '',
            },
            weight: listingData?.weight ? listingData?.weight : '',
            weight_units: listingData?.weight_units
              ? listingData?.weight_units
              : 'lb',
            usps_box_type: listingData?.usps_box_type
              ? listingData?.usps_box_type
              : '',
            zip: listingData?.zip ? listingData?.zip : '',
            street1: listingData.street1 ? listingData?.street1 : '',
            street2: listingData.street2 ? listingData?.street2 : '',
            city: listingData.city ? listingData?.city : '',
            state: listingData.state ? listingData?.state : '',
            country: listingData?.country ? listingData?.country : 'US',
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            let listingPayload;
            listingPayload = values;
            if (physicalProduct) {
              setListingPhysicalVal({...listingPayload});
            } else {
              setListingData({...listingPayload});
              next();
            }
          }}>
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            errors,
            touched,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="form-group text-start mb-4">
                  <h6 className="form-label color-dark">
                    Price <span className="text-danger">*</span>
                  </h6>
                  <div className="input-box">
                    <span className="prefix">$</span>
                    <input
                      type="number"
                      name="price"
                      onKeyDown={(evt) =>
                        evt.key === 'e' && evt.preventDefault()
                      }
                      onChange={handleChange}
                      placeholder="0.00"
                      ref={inputRef}
                      onWheel={(e) => {
                        if (
                          inputRef &&
                          inputRef.current &&
                          inputRef.current.blur
                        ) {
                          inputRef.current.blur();
                        }
                        // e.preventDefault();
                      }}
                      value={values.price}
                      maxLength="4"
                      className="input-custom-var2 custom-number form-control input-lg w-100"
                    />
                  </div>

                  <div className="text-danger text-sm mt-3 fw-bold">
                    {errors.price && touched.price && errors.price}
                  </div>
                </div>

                <ul className="opacity-50 ps-3 lh-m fw-500 info-text mb-4">
                  <li>
                    You have the ability to set your own price, and you can
                    change it at any time.
                  </li>
                  <li>
                    Set a price that feels right to you, and worth your time.
                  </li>
                  <li>
                    Consider any similar listings from other Athletes as a
                    datapoint for deciding your listing price.
                  </li>
                </ul>
                {(selectedCard === 'experience' || !physicalProduct) && (
                  <div className="h-120"></div>
                )}
                {physicalProduct && (
                  <>
                    <h3 className="title">
                      Details for shipping cost estimation
                    </h3>
                    <div className="bg-light shipping-cost-holder">
                      <div className="color-muted fw-bold mb-2">
                        Package type
                      </div>

                      <div className="row mx-n2 mb-3">
                        {shippingData.map((data, index) => {
                          return (
                            <div
                              className={`col cursor-pointer text-center px-2 ${
                                values.package_type === data.key
                                  ? 'active-card'
                                  : ''
                              }`}
                              key={data.key}
                              onClick={() => {
                                setFieldValue('package_type', data.key);
                                setPackageType(index);
                              }}>
                              <div className="default-card mb-2 mh-100-px ">
                                <div className="card-bg text-muted d-flex align-items-center">
                                  {data.icon}
                                </div>
                              </div>
                              <div className="px-4 lh-m color-dark fw-500">
                                {data.key}
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {dimensionsBOX ? (
                        <>
                          <div className="color-muted fw-bold mb-2">
                            Dimensions <span className="text-danger">*</span>
                          </div>
                          <div className="row mx-n2">
                            <div className="col-12 col-md-4 mb-4 px-2">
                              <Input
                                addonBefore="L (in)"
                                type="number"
                                name="dimensions.length"
                                className="input-custom-var2"
                                value={values.dimensions.length}
                                // onChange={handleChange}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-md-4 mb-4 px-2">
                              <Input
                                type="number"
                                addonBefore="W (in)"
                                name="dimensions.width"
                                className="input-custom-var2"
                                onChange={handleChange}
                                value={values.dimensions.width}
                              />
                            </div>
                            <div className="col-12 col-md-4 mb-4 px-2">
                              <Input
                                type="number"
                                addonBefore="H (in)"
                                name="dimensions.height"
                                className="input-custom-var2"
                                onChange={handleChange}
                                value={values.dimensions.height}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="mb-4">
                          <div className="color-muted fw-bold mb-2">
                            USPS Flat Rate Packaging
                            <span className="text-danger">*</span>
                          </div>
                          <Select
                            className="custom-select custom-select-light"
                            name="usps_box_type"
                            value={values.usps_box_type}
                            onChange={(value) =>
                              setFieldValue('usps_box_type', value)
                            }>
                            {uspsBoxTypeArray.map((option) => (
                              <Select.Option
                                value={option.type}
                                key={option.type}>
                                {option.name} ({option.dimensions})
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div className="row mx-n2">
                        <div className="col-12 col-md-9 px-2 mb-3">
                          <div className="color-muted fw-bold mb-2">
                            Package Weight
                            <span className="text-danger">*</span>
                          </div>
                          <div className="d-flex custom-input-var3">
                            <Input
                              type="number"
                              name="weight"
                              placeholder="0"
                              className="input-custom-var2 right-border"
                              value={values.weight}
                              onChange={handleChange}
                            />
                            <Select
                              className="custom-select"
                              name="weight_units"
                              value={values.weight_units}
                              // onChange={handleChange}
                              onChange={(value) =>
                                setFieldValue('weight_units', value)
                              }>
                              <Option value="lb">LB</Option>
                              <Option value="oz">OZ</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-n2">
                        <div className="col-12 mt-4 px-2 mb-3">
                          <h6 className="fw-bold d-flex align-items-center mb-0">
                            Your ship-from address
                            <Tooltip
                              placement="bottom"
                              title="Your main address where you ship packages from. This is required for accurate shipping cost calculations and so the package has a Return Address in case an item is undeliverable.">
                              <span className="price-img-shadow ms-2">?</span>
                            </Tooltip>
                          </h6>
                        </div>
                        <div className="col-md-12 px-2 mb-3">
                          <div className="col-md-12 mb-3">
                            <div className="color-muted fw-bold mb-2">
                              Country <span className="text-danger">*</span>
                            </div>
                            <Select
                              className="custom-select custom-select-light size-l "
                              name="country"
                              value={values.country}
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => {
                                setFieldValue('country', value);
                                getStateList(value);
                              }}>
                              {countries !== undefined && (
                                <>
                                  {countries.map((option) => (
                                    <Select.Option
                                      value={option.isoCode}
                                      key={option.name}>
                                      {option.name}
                                    </Select.Option>
                                  ))}
                                </>
                              )}
                            </Select>
                          </div>
                          <div className="color-muted fw-bold mb-2">
                            Street / Address line 1
                            <span className="text-danger">*</span>
                          </div>
                          <input
                            className="form-control input-lg"
                            value={values.street1}
                            type="text"
                            name="street1"
                            placeholder="Street"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-12 px-2 mb-3">
                          <div className="color-muted fw-bold mb-2">
                            Address line 2
                          </div>
                          <input
                            className="form-control input-lg"
                            value={values.street2}
                            type="text"
                            name="street2"
                            placeholder="Apt / Suite / Unit #"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 px-2 mb-3">
                          <div className="color-muted fw-bold mb-2">
                            City
                            <span className="text-danger"> *</span>
                          </div>
                          <input
                            className="input-custom-var2 form-control"
                            name="city"
                            id="city"
                            value={values.city}
                            onChange={(e) => {
                              setFieldValue('city', e.target.value);
                            }}
                          />
                        </div>

                        <div className="col-md-6 px-2 mb-3">
                          <div className="color-muted fw-bold mb-2">
                            State
                            <span className="text-danger"> *</span>
                          </div>
                          {values.country === 'US' ||
                          values.country === 'CA' ? (
                            <Select
                              className="custom-select custom-select-light size-l "
                              name="state"
                              value={values.state}
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => {
                                setFieldValue('state', value);
                              }}>
                              <>
                                {stateList.map((option, i) => (
                                  <Select.Option value={option.state} key={i}>
                                    {option.state}
                                  </Select.Option>
                                ))}
                              </>
                            </Select>
                          ) : (
                            <input
                              className="input-custom-var2 form-control"
                              name="state"
                              id="state"
                              value={values.state}
                              onChange={(e) => {
                                setFieldValue('state', e.target.value);
                              }}
                            />
                          )}
                        </div>

                        <div className="col-md-6 col-12 px-2">
                          <div className="color-muted fw-bold mb-2">
                            Your Zip Code <span className="text-danger">*</span>
                          </div>
                          <Input
                            name="zip"
                            type="text"
                            placeholder=""
                            // maxLength={5}
                            className="input-custom-var2 form-control"
                            value={values.zip}
                            onChange={(e) => {
                              setFieldValue('zip', e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="border-bottom mt-5 mw-570 mx-auto d-none d-md-block"></div>
                <div className="steps-action d-flex flex-row pt-4">
                  <div
                    className="text-muted fw-bold align-items-center d-flex cursor-pointer mx-md-0 mx-3 t-buttom"
                    onClick={prev}>
                    BACK
                  </div>

                  {physicalProduct ? (
                    <>
                      {dimensionsBOX ? (
                        <button
                          className={`btn btn-primary ms-auto 
                            ${
                              values.dimensions.length <= 0 ||
                              values.dimensions.width <= 0 ||
                              values.dimensions.height <= 0 ||
                              values.weight <= 0 ||
                              values.zip === '' ||
                              values.street1 === '' ||
                              values.city === '' ||
                              values.price === '' ||
                              values.state === '' ||
                              values.country === ''
                                ? 'disabled'
                                : ''
                            }`}
                          type="submit">
                          Next
                          <i className="icon icon-right ms-3"></i>
                        </button>
                      ) : (
                        <button
                          className={`btn btn-primary ms-auto 
                            ${
                              values.usps_box_type === '' ||
                              values.weight <= 0 ||
                              values.zip === '' ||
                              values.street1 === '' ||
                              values.city === '' ||
                              values.state === '' ||
                              values.price === ''
                                ? 'disabled'
                                : ''
                            }`}
                          type="submit">
                          Next
                          <i className="icon icon-right ms-3"></i>
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className={`btn btn-primary ms-auto ${
                        values.price === 0 ? 'disabled' : ''
                      }`}
                      type="submit">
                      Next
                      <i className="icon icon-right ms-3"></i>
                    </button>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Pricing;
