import styled from 'styled-components';
import {Card} from 'antd';

export const AthleteCard = styled(Card)`
  width: 100%;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  padding: 30px;
  border: none;

  .ant-card-body {
    padding: 0px;
  }

  @media screen and (max-width: 767.98px) {
    box-shadow: none;
    border: solid 1px #d3dce1;
    border-radius: 8px;
    padding: 20px;
  }
`;

export const BackSection = styled.div`
  color: #7741fe;
  font-weight: 600;
  cursor: pointer;
`;

export const CampaignDetailsCard = styled.div`
  margin-top: 25px;
  background: #ffffff;
  box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  padding: 40px 40px 50px;
  color: #042237;
  @media screen and (max-width: 992px) {
    padding: 0;
    box-shadow: none;
  }

  .cam-header {
    font-weight: 700;
    font-size: 36px;
    @media screen and (max-width: 992px) {
      font-size: 30px;
    }
  }

  .cam-sub-title {
    margin: 15px 0 25px;
    display: flex;
    align-items: center;
  }

  .cam-desc-card {
    border: 1px solid #d3dce1;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .cam-title {
    padding: 20px 30px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid #d3dce1;
    @media screen and (max-width: 992px) {
      padding: 20px;
    }
  }

  .cam-desc {
    padding: 20px 30px;
    line-height: 26px;
    @media screen and (max-width: 992px) {
      padding: 20px;
    }
  }

  .cam-details-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #d3dce1;
    @media screen and (max-width: 992px) {
      font-size: 15px;
    }
  }
`;

export const ActionButton = styled.div`
  display: flex;
  height: 40px;
  width: 130px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background: #042237;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin: 0 5px;
  @media screen and (max-width: 992px) {
    width: -webkit-fill-available;
    width: -moz-available;
    font-size: 15px;
    margin-top: 25px;
  }
`;

export const ActionButtonDisabled = styled(ActionButton)`
  cursor: unset;
`;

export const ActionGreyButton = styled(ActionButton)`
  background: #f2f5f7;
  color: #435969;
`;

export const ActionBlackButton = styled(ActionButton)`
  background: #435969;
  color: #ffffff;
`;

export const ActionGreenButton = styled(ActionButton)`
  background: #31bf83;
  color: #ffffff;
`;

export const CardTheme = styled.div`
  // border-radius: 15px;
  // background: url(${(props) => props.bgUrl});
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b6fccf+53,b6fccf+53,80e8e5+85 */
  background: #b6fccf; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #b6fccf 53%,
    #b6fccf 53%,
    #80e8e5 85%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #b6fccf 53%,
    #b6fccf 53%,
    #80e8e5 85%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #b6fccf 53%,
    #b6fccf 53%,
    #80e8e5 85%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b6fccf', endColorstr='#80e8e5',GradientType=1 ); /* IE6-9 */

  background-size: cover;
  padding: 30px 40px;
  color: #042237;
  margin-bottom: 60px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 15px;

  @media screen and (max-width: 992px) {
    // background: url(${(props) => props.bgUrlMob});
    background-size: cover;
    flex-direction: column;
    padding: 30px 25px;
    margin-bottom: 40px;
    border-radius: 8px;
  }

  .ct-left {
    margin-right: 30px;
    @media screen and (max-width: 992px) {
      margin-right: 0;
    }
  }

  .ct-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    @media screen and (max-width: 992px) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  .ct-content {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #435969;
    margin-top: 10px;
    padding-right: 50px;

    @media screen and (max-width: 992px) {
      font-size: 14px;
      line-height: 21px;
      margin-top: 5px;
      padding: 0;
    }
  }

  .ct-button {
    padding: 18px;
    min-width: 265px;
    align-items: center;
    box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);

    @media screen and (max-width: 992px) {
      margin-top: 20px;
      font-size: 17px;
      font-weight: 700;
      height: 52px;
      justify-content: center;
      display: flex;
    }
  }

  .ct-btn-contain {
    @media screen and (max-width: 992px) {
      width: -webkit-fill-available;
      width: -moz-available;
    }
  }
`;
