import React, {useRef, useState} from 'react';
import {Row, Col, Select} from 'antd';
import * as yup from 'yup';
import {Formik} from 'formik';
import moment from 'moment';
import useApi from '../../../../../hooks/useApi';
import Text from '../../../../../components/Text/Text';
import {
  CampaignDetail,
  CustomAmount,
  CustomSelectDropDown,
  CustomTextArea,
  DeliverablesContainer,
  FulfillmentDatePicker,
  Heading,
} from '../../../../Purchaser/components/features/Campaigns/style';
import {CheckoutButton, CustomInput} from '../../../../Purchaser/pages/style';
import {PaymentModalFooter} from '../../../../Purchaser/components/style';

let schema = yup.object().shape({
  description: yup.string().required('Please enter description'),
  deliverables: yup.array().of(
    yup.object().shape({
      type: yup.string().required('Deliverable type required'),
      fulfillment_type: yup.string().required('Fulfillment date required'),
      fulfillment_date: yup
        .string()
        .when('fulfillment_type', {
          is: 'Specific Date' || 'Before Date',
          then: yup.string().required('Date is required'),
        })
        .nullable(),
      fulfillment_days: yup
        .string()
        .nullable()
        .when('fulfillment_type', {
          is: 'Within X Days after product received',
          then: yup.string().required('Days is required'),
        }),
    }),
  ),
});

const CreateCounterOffer = ({
  dealData,
  createAthCounterOffer,
  closeCounterOffer,
}) => {
  const {Option} = Select;
  const [compensationErrors, setCompensationErrors] = useState(['']);
  const [deliverableTypes] = useApi('/athlete/deliverable-types');
  const [fulfillmentTypes] = useApi('/purchaser/campaign/fullfillment-types');
  const [fulfillmentDays] = useApi('/purchaser/campaign/fullfillment-days');
  const [expirationDays] = useApi('/purchaser/campaign/deal/expiration-days');
  const formRef = useRef();

  // const computeInitialTotal = (deliverables) => {
  //   let tp = 0;
  //   deliverables.forEach(deliverable => {
  //     tp = tp + deliverable?.compensation_cash + deliverable?.compensation_product;
  //   });
  //   return tp;
  // }

  let initialValues = {
    title: dealData?.title,
    description:
      dealData?.counter_offers.length > 0
        ? dealData?.counter_offers[0].description
        : dealData?.description,
    deliverables:
      dealData?.counter_offers.length > 0
        ? dealData?.counter_offers[0].deliverables
        : dealData?.deliverables,
    total_price:
      dealData?.counter_offers && dealData?.counter_offers.length > 0
        ? dealData?.counter_offers[0].total_price
        : dealData?.total_price,
    expiration_period:
      dealData?.counter_offers.length > 0
        ? dealData?.counter_offers[0].expiration_period
        : dealData?.expiration_period,
  };

  const submitForm = (values) => {
    validateCompensation(formRef);
    let compensationValid = compensationErrors.every((error) => {
      return error === '';
    });
    if (compensationValid) {
      values.deliverables.forEach((deliverable) => {
        if (deliverable.compensation_cash === '') {
          deliverable.compensation_cash = 0;
        } else {
          deliverable.compensation_cash = Number(deliverable.compensation_cash);
        }
        if (deliverable.compensation_product === '') {
          deliverable.compensation_product = 0;
        } else {
          deliverable.compensation_product = Number(
            deliverable.compensation_product,
          );
        }
      });
    }
    let payload = {...values};
    delete payload.title;
    payload.deliverables.forEach((deliverable) => {
      delete deliverable?.id;
      delete deliverable?.deal_id;
      delete deliverable?.createdAt;
      delete deliverable?.updatedAt;
      delete deliverable?.deal_counter_offer_id;
      if (
        deliverable.fulfillment_type !== 'Within X Days after product received'
      ) {
        delete deliverable.fulfillment_days;
      }
    });
    payload.expiration_unit = 'day';
    createAthCounterOffer(payload);
    closeCounterOffer();
  };

  const getSplitOption = (option) => {
    let limit = 40;
    let indexToSplit = 0;
    while (limit > -1) {
      if (option[limit] === ' ') {
        indexToSplit = limit;
        break;
      }
      limit = limit - 1;
    }
    let optionFirst = option.substr(0, indexToSplit);
    let optionSecond = option.substr(indexToSplit);
    return (
      <>
        {optionFirst} <br />
        {optionSecond}
      </>
    );
  };

  const validateEachCompensation = (deliverable, idx) => {
    let cash = Number(deliverable.compensation_cash);
    let product = Number(deliverable.compensation_product);
    if (cash === '' && product === '') {
      let compErrors = compensationErrors;
      compErrors[idx] = 'Either cash or product budget is mandatory';
      setCompensationErrors(compErrors);
    } else if (
      (cash === 0 || cash === '') &&
      (product === 0 || product === '')
    ) {
      let compErrors = compensationErrors;
      compErrors[idx] =
        'Either cash or product budget should be greater than zero';
      setCompensationErrors(compErrors);
    } else if (cash < 0 || product < 0) {
      let compErrors = compensationErrors;
      compErrors[idx] = 'Budget should be positive';
      setCompensationErrors(compErrors);
    } else {
      let compErrors = compensationErrors;
      compErrors[idx] = '';
      setCompensationErrors(compErrors);
    }
  };

  const validateCompensation = (formRef, idx = -1) => {
    let deliverables = formRef.current.values.deliverables;
    if (idx === -1) {
      deliverables.forEach((deliverable, idx) => {
        validateEachCompensation(deliverable, idx);
      });
    } else {
      validateEachCompensation(deliverables[idx], idx);
    }
  };

  const computeTotal = (formRef, setFieldValue) => {
    let deliverables = formRef.current.values.deliverables;
    let total = 0;
    deliverables.forEach((deliverable) => {
      if (deliverable?.compensation_cash) {
        total = total + Number(deliverable.compensation_cash);
      }
      if (deliverable?.compensation_product) {
        total = total + Number(deliverable.compensation_product);
      }
    });
    setFieldValue('total_price', total);
  };

  const onCashCompensationChange = (value, idx, setFieldValue, formRef) => {
    setFieldValue(`deliverables[${idx}].compensation_cash`, value).then(
      (res) => {
        validateCompensation(formRef, idx);
        computeTotal(formRef, setFieldValue);
      },
    );
  };

  const onProductCompensationChange = (value, idx, setFieldValue, formRef) => {
    setFieldValue(`deliverables[${idx}].compensation_product`, value).then(
      (res) => {
        validateCompensation(formRef, idx);
        computeTotal(formRef, setFieldValue);
      },
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={(values) => submitForm(values)}
      innerRef={formRef}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isValid,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <CampaignDetail className="my-4 ">
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="24"
                mrBottom="6"
                className="form-label "
                textColor="#042237">
                Deal title <span className="text-danger ms-1"> *</span>
              </Text>
              <CustomInput
                type="text"
                name="title"
                value={values.title}
                disabled
                onChange={(e) => {
                  setFieldValue('title', e.target.value);
                }}
              />
              <div className="text-danger text-sm fw-500">
                {touched.title && errors.title && errors.title}
              </div>
            </CampaignDetail>
            <CampaignDetail className="mb-4">
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="24"
                className="form-label "
                mrBottom="6"
                textColor="#042237">
                Description <span className="text-danger ms-1"> *</span>
              </Text>
              <CustomTextArea
                rows={5}
                name="description"
                value={values.description}
                disabled
                onChange={(e) => setFieldValue('description', e.target.value)}
              />
              <div className="text-danger text-sm fw-500">
                {touched.description &&
                  errors.description &&
                  errors.description}
              </div>
            </CampaignDetail>
            <CampaignDetail>
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="24"
                className="form-label "
                mrBottom="6"
                textColor="#042237">
                Deliverable(s)
              </Text>
              {values.deliverables.map((deliverable, idx, deliverables) => (
                <DeliverablesContainer key={idx}>
                  <div className="d-flex flex-column mb-3">
                    <Text
                      display="flex"
                      weight="600"
                      size="16"
                      lineHeight="22"
                      className="form-label "
                      textColor="#435969">
                      Deliverable type{' '}
                      <span className="text-danger ms-1"> *</span>
                    </Text>
                    <div className="d-lg-block d-none">
                      <CustomSelectDropDown
                        value={deliverable.type}
                        disabled
                        name={`deliverables[${idx}].type`}
                        onChange={(value) =>
                          setFieldValue(`deliverables[${idx}].type`, value)
                        }>
                        {deliverableTypes?.data?.map((option) => (
                          <Option value={option} key={option}>
                            {option}
                          </Option>
                        ))}
                      </CustomSelectDropDown>
                    </div>
                    <div className="d-block d-lg-none">
                      <CustomSelectDropDown
                        value={deliverable.type}
                        disabled
                        name={`deliverables[${idx}].type`}
                        onChange={(value) =>
                          setFieldValue(`deliverables[${idx}].type`, value)
                        }>
                        {deliverableTypes?.data?.map((option) => (
                          <Option value={option} key={option}>
                            {option.length > 40
                              ? getSplitOption(option)
                              : option}
                          </Option>
                        ))}
                      </CustomSelectDropDown>
                    </div>
                    <div className="text-danger text-sm fw-500">
                      {touched?.deliverables &&
                        touched?.deliverables[idx]?.type &&
                        errors?.deliverables &&
                        errors?.deliverables[idx]?.type &&
                        errors?.deliverables[idx]?.type}
                    </div>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <Text
                      display="flex"
                      weight="600"
                      size="16"
                      lineHeight="22"
                      className="form-label "
                      textColor="#435969">
                      Fulfillment date{' '}
                      <span className="text-danger ms-1"> *</span>
                    </Text>
                    <Row gutter={[16]}>
                      <Col xs={{span: 24}} md={{span: 24}} lg={{span: 24}}>
                        <CustomSelectDropDown
                          value={deliverable.fulfillment_type}
                          disabled
                          name={`deliverables[${idx}].fulfillment_type`}
                          onChange={(value) =>
                            setFieldValue(
                              `deliverables[${idx}].fulfillment_type`,
                              value,
                            )
                          }>
                          {fulfillmentTypes?.data?.map((option) => (
                            <Option value={option} key={option}>
                              {option}
                            </Option>
                          ))}
                        </CustomSelectDropDown>
                        <div className="text-danger text-sm fw-500">
                          {touched?.deliverables &&
                            touched?.deliverables[idx]?.fulfillment_type &&
                            errors?.deliverables &&
                            errors?.deliverables[idx]?.fulfillment_type &&
                            errors?.deliverables[idx]?.fulfillment_type}
                        </div>
                      </Col>
                      <Col
                        xs={{span: 24}}
                        md={{span: 24}}
                        lg={{span: 12}}
                        className="mt-2">
                        {(deliverable.fulfillment_type === 'Specific Date' ||
                          deliverable.fulfillment_type === 'Before Date') && (
                          <>
                            <FulfillmentDatePicker
                              disabled
                              name={`deliverables[${idx}].fulfillment_date`}
                              format="MM/DD/YYYY"
                              value={moment(
                                deliverable.fulfillment_date,
                                'YYYY-MM-DD',
                              )}
                              onChange={(date) => {
                                if (date) {
                                  setFieldValue(
                                    `deliverables[${idx}].fulfillment_date`,
                                    date.format('MM/DD/YYYY'),
                                  );
                                }
                              }}></FulfillmentDatePicker>
                            <div className="text-danger text-sm fw-500">
                              {touched?.deliverables &&
                                touched?.deliverables[idx]?.fulfillment_date &&
                                errors?.deliverables &&
                                errors?.deliverables[idx]?.fulfillment_date &&
                                errors?.deliverables[idx]?.fulfillment_date}
                            </div>
                          </>
                        )}
                        {deliverable.fulfillment_type ===
                          'Within X Days after product received' && (
                          <>
                            <CustomSelectDropDown
                              value={deliverable.fulfillment_days}
                              disabled
                              name={`deliverables[${idx}].fulfillment_days`}
                              onChange={(value) =>
                                setFieldValue(
                                  `deliverables[${idx}].fulfillment_days`,
                                  value,
                                )
                              }>
                              {fulfillmentDays?.data?.map((option) => (
                                <Option value={option} key={option}>
                                  {option + ' days'}
                                </Option>
                              ))}
                            </CustomSelectDropDown>
                            <div className="text-danger text-sm fw-500">
                              {(touched?.deliverables &&
                                touched?.deliverables[idx]?.fulfillment_days) ||
                                (errors?.deliverables &&
                                  errors?.deliverables[idx]?.fulfillment_days &&
                                  errors?.deliverables[idx]?.fulfillment_days)}
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <Text
                      display="flex"
                      mrBottom="8"
                      weight="600"
                      size="16"
                      lineHeight="22"
                      className="form-label "
                      textColor="#435969">
                      Compensation
                      <span className="text-danger ms-1"> *</span>
                    </Text>
                    <Row gutter={[16]}>
                      <Col xs={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                        <CustomAmount className="highlighted">
                          <Text
                            display="flex"
                            weight="500"
                            size="15"
                            lineHeight="25"
                            opacity="0.7"
                            textColor="#435969">
                            Cash
                          </Text>
                          <Text
                            display="flex"
                            weight="500"
                            size="18"
                            lineHeight="25"
                            textColor="#042237">
                            ${' '}
                            <input
                              min="0"
                              max="1000000000"
                              className="compensation_input"
                              type="number"
                              value={deliverable.compensation_cash}
                              name={`deliverables[${idx}].compensation_cash`}
                              onChange={(e) =>
                                onCashCompensationChange(
                                  e.target.value.replace(/^0+/, ''),
                                  idx,
                                  setFieldValue,
                                  formRef,
                                )
                              }
                            />
                          </Text>
                        </CustomAmount>
                      </Col>
                      <Col xs={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                        <CustomAmount className="highlighted">
                          <Text
                            display="flex"
                            weight="500"
                            size="15"
                            lineHeight="25"
                            opacity="0.7"
                            textColor="#435969">
                            Products
                          </Text>
                          <Text
                            display="flex"
                            weight="500"
                            size="18"
                            lineHeight="25"
                            textColor="#042237">
                            ${' '}
                            <input
                              min="0"
                              max="1000000000"
                              className="compensation_input"
                              type="number"
                              value={deliverable.compensation_product}
                              name={`deliverables[${idx}].compensation_product`}
                              onChange={(e) =>
                                onProductCompensationChange(
                                  e.target.value.replace(/^0+/, ''),
                                  idx,
                                  setFieldValue,
                                  formRef,
                                )
                              }
                            />
                          </Text>
                        </CustomAmount>
                      </Col>
                    </Row>
                    <div className="text-danger text-sm fw-500">
                      {compensationErrors[idx]}
                    </div>
                  </div>
                  <Heading>
                    <li>
                      Compensation can be in Cash, Products or Both. For product
                      compensation add the estimated value of the product
                    </li>
                  </Heading>
                </DeliverablesContainer>
              ))}
            </CampaignDetail>
            <CampaignDetail className="my-5">
              <CustomAmount bgColor="#F2F5F7">
                <Text
                  display="flex"
                  weight="500"
                  size="18"
                  lineHeight="25"
                  opacity="0.7"
                  textColor="#042237">
                  Total
                </Text>
                <Text
                  display="flex"
                  weight="500"
                  size="18"
                  lineHeight="25"
                  textColor="#042237">
                  ${' '}
                  <input
                    min="0"
                    max="1000000000"
                    className="compensation_input"
                    type="number"
                    value={values.total_price}
                    name="total_price"
                    disabled
                  />
                </Text>
              </CustomAmount>
            </CampaignDetail>
            <CampaignDetail className="mb-5">
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="24"
                mrBottom="6"
                className="form-label "
                textColor="#042237">
                Offer expiration
                <span className="text-danger ms-1"> *</span>
              </Text>
              <CustomSelectDropDown
                value={values.expiration_period}
                disabled
                name="expiration_period"
                onChange={(value) => setFieldValue('expiration_period', value)}>
                {expirationDays?.data?.map((option) => (
                  <Option value={option} key={option}>
                    {`${option} days`}
                  </Option>
                ))}
              </CustomSelectDropDown>
            </CampaignDetail>
            <PaymentModalFooter>
              <CheckoutButton type="submit">
                <Text
                  weight="800"
                  size="18"
                  lineHeight="23"
                  textColor="#FFFFFF"
                  cursor="pointer">
                  CREATE COUNTER OFFER
                </Text>
              </CheckoutButton>
            </PaymentModalFooter>
          </form>
        );
      }}
    </Formik>
  );
};

export default CreateCounterOffer;
