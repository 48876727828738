import {put, call, takeLatest, delay} from 'redux-saga/effects';
import {
  HANDLE_REGISTER,
  HANDLE_RESET_PASSWORD,
  HANDLE_FORGOT_PASSWORD,
  HANDLE_SPORTS_LISTS,
  HANDLE_PROFILE_SUBMIT,
  CREATE_LIST,
  RESEND_FERIFICATION,
  HANDLE_CREATE_STRIPE_ACCOUNT,
  CREATE_STRIPE_SUBSCRIPTION,
  GET_SHOP_LISTING,
  GET_SUBSCRIPTIONS,
  GET_STRIPE_STATUS,
  EDIT_LIST_STATUS,
  GET_ATH_CAMPAIGNS,
  GET_INVITED_CAMPAIGNS,
  GET_ATH_CAMPAIGN_DATA,
  GET_ATH_DEALS,
  GET_ATH_DEAL_DATA,
  SET_ORDER_STATUS,
  GET_ORDER_DETAILS,
  APPLY_TO_CAMPAIGN,
  EDIT_LISTING,
  UPDATE_DEAL_STATUS,
  COUNTER_OFFER,
  UPDATE_DEAL_COUNTER_OFFER_STATUS,
  ORDER_CANCEL,
} from './actionTypes';
import {
  hanldeError,
  handlerRegisterSuccess,
  handleMessage,
  handleSportsListsSuccess,
  handleCreateListStatus,
  handleAthleteUserData,
  resetVerificationStatus,
  profileSubmitSuccess,
  createStripeAccountRedirect,
  handleAthleteListsData,
  createStripeSubscriptionRedirect,
  handleSubscriptionsData,
  setStripeStatus,
  setLoader,
  setCampaigns,
  setInvitedCampaigns,
  appendCampaigns,
  setAthleteCampaignData,
  appendDeals,
  setDeals,
  setAthleteDealData,
  orderCompleteStatus,
  orderDetails,
  handleApplyCampaignStatus,
  editListDetailsStatus,
  handleDealStatusChanged,
  handleCounterOfferCreated,
  handleCancelOrder,
  setCouponValid,
} from './action';
import {fetchAthleteData} from '../users/actions';
import {
  triggerRegister,
  triggerForgotPassword,
  triggerResetPassword,
  fetchSportsLists,
  triggerProfileSubmit,
  tiggerCreateList,
  triggerResendVerification,
  triggerCreateStripeAccount,
  triggerCreateStripeSubscription,
  fetchShopsList,
  triggerGetSubscriptions,
  getStripeStatus,
  setListStatus,
  triggerGetCampaigns,
  triggerGetInvitedCampaigns,
  triggerGetCampaignData,
  triggerGetDeals,
  triggerGetDealData,
  triggetOrderComplete,
  triggetGetOrder,
  triggerCampaignApplication,
  triggerEditList,
  triggerDealStatusUpdate,
  triggerCreateCounterOffer,
  triggerDealCounterOfferStatusUpdate,
  triggerCancelOrder,
} from '../../modules/Athlete/helpers/apiController';
import {isString} from 'formik';
import {setDealsLoader} from '../brandflow/action';

function* onRegister(payload) {
  try {
    const response = yield call(triggerRegister, payload.payload);
    yield put(handleAthleteUserData(response.data.data));
    localStorage.setItem('athletUp-token', response.data.data.token);
    localStorage.setItem('id', response.data.data.id);
    localStorage.setItem('userRole', response.data.data.role);
    localStorage.setItem(
      'athletup-refresh-token',
      response.data.data.refresh_token,
    );
    yield put(handlerRegisterSuccess(true));
  } catch (error) {
    // console.log(error.response.data?.message);
    yield put(handleMessage(error.response.data.message));
    yield put(handlerRegisterSuccess(false));
  }
}

function* onResetPassword(payload) {
  try {
    const response = yield call(triggerResetPassword, payload.payload);
    yield put(handleMessage(response.data));
  } catch (error) {
    yield put(handleMessage(error));
    // yield put(hanldeError("restPassword", error));
  }
}
function* onForgotPassword(payload) {
  try {
    const response = yield call(triggerForgotPassword, payload.payload);
    yield put(handleMessage(response.data));
  } catch (error) {
    yield put(handleMessage(error?.response?.data));
    // yield put(handlerForgotPasswordSuccess(false));
  }
}

function* getSportsLists() {
  try {
    const response = yield call(fetchSportsLists);
    yield put(handleSportsListsSuccess(response.data.rows));
  } catch (error) {
    yield put(hanldeError(error));
  }
}

function* onProfileSubmit(payload) {
  try {
    yield put(profileSubmitSuccess(false));
    // console.log(payload, "profile payload")
    const response = yield call(triggerProfileSubmit, payload);
    yield put(profileSubmitSuccess(true));
    yield put(fetchAthleteData(response?.data?.data?.user_id));
  } catch (error) {
    // if (error.response?.data?.success === false) {
    //   console.log(error.response?.data?.message);
    // }
    yield put(handleMessage(error.response?.data));
    yield put(profileSubmitSuccess(false));
    // yield put(handlerForgotPasswordSuccess(false));
  }
}

function* onCreateList(payload) {
  try {
    yield call(tiggerCreateList, payload.payload);
    // yield put(handleMessage(response.data));
    yield put(handleCreateListStatus(true));
  } catch (error) {
    yield put(hanldeError(error?.response?.data));
    // yield put(handleMessage(error?.response?.data));
    yield put(handleCreateListStatus(false));
  }
}

function* onResendVerificationEmail(payload) {
  try {
    const response = yield call(triggerResendVerification, payload.payload);
    yield put(resetVerificationStatus(true));
    yield put(handleMessage(response.data));
    yield put(hanldeError());
  } catch (error) {
    console.log(error.response.data.message);
    yield put(resetVerificationStatus(false));
    yield put(handleMessage(error.response.data.message));
  }
}

function* onCreateStripeAccount() {
  try {
    const response = yield call(triggerCreateStripeAccount);
    yield put(handleMessage(response.data.data));
    yield put(hanldeError());
    yield put(createStripeAccountRedirect(response.data.data));
  } catch (error) {
    yield put(hanldeError(error));
    yield put(handleMessage(error));
    yield put(createStripeAccountRedirect({url: 'null'}));
  }
}

function* getSubscriptions(payload) {
  try {
    const response = yield call(triggerGetSubscriptions, payload);
    yield put(handleSubscriptionsData(response?.data?.data[0]));
    yield put(handleMessage(response.data));
    yield put(hanldeError());
    if (
      payload?.payload &&
      isString(payload?.payload) &&
      payload?.payload !== ''
    ) {
      yield put(setCouponValid(true));
    } else {
      yield put(setCouponValid(null));
    }
  } catch (error) {
    yield put(handleMessage(error));
    yield put(hanldeError(error));
    // yield put(handleSubscriptionsData({}));
    yield put(setCouponValid(false));
  }
}

function* onCreateSubscription(payload) {
  try {
    const response = yield call(
      triggerCreateStripeSubscription,
      payload.payload,
    );
    yield put(handleMessage(response.data));
    yield put(hanldeError());
    // debugger;
    yield put(createStripeSubscriptionRedirect(response.data.data));
  } catch (error) {
    yield put(hanldeError(error));
    yield put(handleMessage(error));
    yield put(createStripeSubscriptionRedirect({url: 'null'}));
  }
}

function* getShopLists(payload) {
  try {
    yield put(setLoader(true));
    const response = yield call(fetchShopsList, payload.payload);
    yield put(handleAthleteListsData(response.data.data.rows));
    yield put(handleMessage(''));
    yield put(setLoader(false));
  } catch (error) {
    yield put(setLoader(false));
    yield put(hanldeError(error));
  }
}

function* fetchStripeStatus() {
  try {
    const response = yield call(getStripeStatus);
    let stripeStatus = response?.data?.data;
    let status;
    let menuStatus;
    if (
      stripeStatus?.subscription_valid === false &&
      stripeStatus?.subscription_activated_on !== null
    ) {
      status = true;
    } else if (
      stripeStatus?.subscription_valid === false &&
      stripeStatus?.stripe_account_valid === false
    ) {
      status = false;
      menuStatus = true;
    } else {
      status = false;
      menuStatus = false;
    }
    yield put(
      setStripeStatus({
        data: response?.data?.data,
        status: status,
        menuStatus: menuStatus,
      }),
    );

    // yield put(handleMessage(response?.data));
  } catch (error) {
    yield put(hanldeError(error));
  }
}

function* onEditListStatus(payload) {
  try {
    yield call(setListStatus, payload.payload);
    const response = yield call(fetchShopsList, payload.payload.listPayload);
    yield put(handleAthleteListsData(response.data.data.rows));
  } catch (error) {
    console.log(error);
    // yield put(deleteListStatus(false));
    // yield put(hanldeError(error));
  }
}

function* onGetCampaigns(payload) {
  try {
    yield put(setLoader(true));
    const response = yield call(triggerGetCampaigns, payload.payload);
    if (payload.payload.page > 1) {
      yield put(appendCampaigns(response?.data?.data));
    } else {
      yield put(setCampaigns(response?.data?.data));
    }
    // yield put(handleMessage(response.data));
    yield put(hanldeError());
    yield put(setLoader(false));
  } catch (error) {
    console.log(error);
    yield put(hanldeError(error));
    yield put(handleMessage(error));
    yield put(setLoader(false));
  }
}

function* onGetInvitedCampaigns(payload) {
  try {
    yield put(setLoader(true));
    const response = yield call(triggerGetInvitedCampaigns, payload.payload);
    yield put(setInvitedCampaigns(response?.data?.data?.rows));
    // yield put(handleMessage(response.data));
    yield put(hanldeError());
    yield put(setLoader(false));
  } catch (error) {
    console.log(error);
    yield put(hanldeError(error));
    yield put(handleMessage(error));
    yield put(setLoader(false));
  }
}

function* onGetAthleteCampaignData(payload) {
  try {
    yield put(setLoader(true));
    const response = yield call(triggerGetCampaignData, payload.payload);
    yield put(setAthleteCampaignData(response?.data?.data));
    // yield put(handleMessage(response.data));
    yield put(hanldeError());
    yield put(setLoader(false));
  } catch (error) {
    console.log(error);
    yield put(hanldeError(error));
    yield put(setAthleteCampaignData({}));
    yield put(handleMessage(error));
    yield put(setLoader(false));
  }
}

function* onApplyToCampaign(payload) {
  try {
    yield call(triggerCampaignApplication, payload);
    yield put(hanldeError());
    yield put(handleApplyCampaignStatus(true));
    yield delay(2000);
    yield put(handleApplyCampaignStatus(false));
  } catch (error) {
    console.log(error);
    yield put(hanldeError());
    yield put(handleApplyCampaignStatus(false));
  }
}

function* onGetDeals(payload) {
  try {
    yield put(setDealsLoader(true));
    const response = yield call(triggerGetDeals, payload.payload);
    if (payload.payload.page > 1) {
      yield put(appendDeals(response?.data?.data));
    } else {
      yield put(setDeals(response?.data?.data));
    }
    yield put(hanldeError());
    yield put(setDealsLoader(false));
  } catch (error) {
    console.log(error);
    yield put(hanldeError(error));
    yield put(handleMessage(error));
    yield put(setDealsLoader(false));
  }
}

function* onOrderComplete(payload) {
  try {
    yield call(triggetOrderComplete, payload.payload);
    // yield put(orderDetails(response.data.data));
    yield put(orderCompleteStatus(true));
  } catch (error) {
    console.log(error, 'Order Complete error');
    // yield put(handleMessage('Error in order completion'));
  }
}
function* onGetOrder(payload) {
  try {
    yield put(setLoader(true));
    const response = yield call(triggetGetOrder, payload.payload);
    yield put(orderDetails(response.data.data));
    yield put(orderCompleteStatus(false));
    yield put(setLoader(false));
    yield put(handleMessage());
    yield put(handleCancelOrder(false));
    // console.log(response, 'getOrder');
  } catch (error) {
    yield put(setLoader(false));
    console.log(error, 'Get order error');
  }
}

function* onGetAthleteDealData(payload) {
  try {
    yield put(setLoader(true));
    const response = yield call(triggerGetDealData, payload.payload);
    yield put(setAthleteDealData(response?.data?.data));
    // yield put(handleMessage(response.data));
    yield put(hanldeError());
    yield put(setLoader(false));
  } catch (error) {
    console.log(error);
    console.log(error?.response);
    yield put(hanldeError(error));
    yield put(setAthleteDealData({}));
    yield put(handleMessage(error));
    yield put(setLoader(false));
  }
}
function* onEditListing(payload) {
  try {
    yield call(triggerEditList, payload.payload);
    yield put(editListDetailsStatus(true));
  } catch (error) {
    console.log(error.response.data.message);
    yield put(handleMessage(error.response.data.message));
    yield put(editListDetailsStatus(false));
  }
}

function* onUpdateDealStatus(payload) {
  try {
    yield call(triggerDealStatusUpdate, payload);
    yield put(hanldeError());
    yield put(handleDealStatusChanged(payload.payload.status));
    yield delay(2000);
    yield put(handleDealStatusChanged(null));
  } catch (error) {
    console.log(error);
    yield put(hanldeError());
    yield put(handleDealStatusChanged(null));
  }
}

function* onUpdateDealCounterOfferStatus(payload) {
  try {
    yield call(triggerDealCounterOfferStatusUpdate, payload);
    yield put(hanldeError());
    yield put(handleDealStatusChanged(payload.payload.status));
    yield delay(2000);
    yield put(handleDealStatusChanged(null));
  } catch (error) {
    console.log(error);
    yield put(hanldeError());
    yield put(handleDealStatusChanged(null));
  }
}

function* onCreateCounterOffer(payload) {
  try {
    yield call(triggerCreateCounterOffer, payload);
    yield put(hanldeError(''));
    yield put(handleCounterOfferCreated(true));
    yield delay(2000);
    yield put(handleCounterOfferCreated(false));
  } catch (error) {
    console.log(error);
    yield put(hanldeError(error));
    yield put(handleCounterOfferCreated(false));
  }
}

function* onCancelOrder(payload) {
  try {
    yield call(triggerCancelOrder, payload.payload);
    yield put(handleCancelOrder(true));
  } catch (error) {
    yield put(handleMessage(error.response.data.message));
    console.log(error.response.data.message);
  }
}

// Register here for all Api calls
function* AthleteModuleSaga() {
  yield takeLatest(HANDLE_REGISTER, onRegister);
  yield takeLatest(HANDLE_RESET_PASSWORD, onResetPassword);
  yield takeLatest(HANDLE_FORGOT_PASSWORD, onForgotPassword);
  yield takeLatest(HANDLE_SPORTS_LISTS, getSportsLists);
  yield takeLatest(HANDLE_PROFILE_SUBMIT, onProfileSubmit);
  yield takeLatest(CREATE_LIST, onCreateList);
  yield takeLatest(RESEND_FERIFICATION, onResendVerificationEmail);
  yield takeLatest(HANDLE_CREATE_STRIPE_ACCOUNT, onCreateStripeAccount);
  yield takeLatest(CREATE_STRIPE_SUBSCRIPTION, onCreateSubscription);
  yield takeLatest(GET_SUBSCRIPTIONS, getSubscriptions);
  yield takeLatest(GET_SHOP_LISTING, getShopLists);
  yield takeLatest(GET_STRIPE_STATUS, fetchStripeStatus);
  yield takeLatest(EDIT_LIST_STATUS, onEditListStatus);
  yield takeLatest(GET_ATH_CAMPAIGNS, onGetCampaigns);
  yield takeLatest(GET_INVITED_CAMPAIGNS, onGetInvitedCampaigns);
  yield takeLatest(GET_ATH_CAMPAIGN_DATA, onGetAthleteCampaignData);
  yield takeLatest(GET_ATH_DEALS, onGetDeals);
  yield takeLatest(GET_ATH_DEAL_DATA, onGetAthleteDealData);
  yield takeLatest(SET_ORDER_STATUS, onOrderComplete);
  yield takeLatest(GET_ORDER_DETAILS, onGetOrder);
  yield takeLatest(APPLY_TO_CAMPAIGN, onApplyToCampaign);
  yield takeLatest(EDIT_LISTING, onEditListing);
  yield takeLatest(UPDATE_DEAL_STATUS, onUpdateDealStatus);
  yield takeLatest(
    UPDATE_DEAL_COUNTER_OFFER_STATUS,
    onUpdateDealCounterOfferStatus,
  );
  yield takeLatest(COUNTER_OFFER, onCreateCounterOffer);
  yield takeLatest(ORDER_CANCEL, onCancelOrder);
}

export default AthleteModuleSaga;
