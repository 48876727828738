import React, {useState, Fragment, useEffect} from 'react';
import {PaymentMethod, PriceSummary, PaymentMethodHeading} from '../../style';
import CheckoutCardWrapperDeal from '../../payment/CheckoutCardDeal';

const PayModalContainer = ({dealData}) => {
  const [totalCash, setTotalCash] = useState(50);

  useEffect(() => {
    let total = 0;
    let deliverables = [];
    if (dealData?.counter_offers && dealData?.counter_offers.length > 0) {
      deliverables = dealData.counter_offers[0].deliverables;
    } else {
      deliverables = dealData.deliverables;
    }
    deliverables.forEach((deliverable) => {
      total = total + deliverable.compensation_cash;
    });
    setTotalCash(total);
  }, [dealData]);

  return (
    <Fragment>
      <div className="d-flex w-100 mt-3 px-3">
        <div className="d-flex flex-column w-100">
          <div className="d-flex justify-content-between align-items-center w-100 mb-4 mt-2">
            <PriceSummary>Total (cash compensation)</PriceSummary>
            <PriceSummary>{`$${totalCash}`}</PriceSummary>
          </div>
        </div>
      </div>
      <PaymentMethod>
        <PaymentMethodHeading>Payment method</PaymentMethodHeading>
        <CheckoutCardWrapperDeal
          dealData={dealData}
          totalCash={totalCash}></CheckoutCardWrapperDeal>
      </PaymentMethod>
    </Fragment>
  );
};

export default PayModalContainer;
