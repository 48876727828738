import React, {useEffect} from 'react';
import {icons} from '../../../utils/staticStrings';
import ConfirmationMessage from '../../../common/components/ConfirmationMessage';
import useApi from '../../../hooks/useApi';
import {fetchPurchaserData} from '../../../store/users/actions';
import {useDispatch} from 'react-redux';

const PaymnetSuccess = () => {
  const dispatch = useDispatch();
  let ListingId = localStorage.getItem('listingId');
  const [listingData] = useApi(`/athlete/listing/${ListingId}`);

  useEffect(() => {
    let id = localStorage.getItem('id');
    if (id !== null) {
      dispatch(fetchPurchaserData(id));
    }
  }, [dispatch]);

  return (
    <ConfirmationMessage
      icons={icons}
      title="Thank you for your purchase"
      subText={`We just sent your order to ${listingData?.data?.athlete?.name}. Check the progress of your order from the My Purchases tab of your account dashboard.`}
      btnText="GO TO DASHBOARD"
      redirectionLink="/purchaser/dashboard"
    />
  );
};

export default PaymnetSuccess;
