import styled from 'styled-components';
import {Menu} from 'antd';

export const CustomMenu = styled(Menu)`
  border-inline-end: none;

  .ant-menu-item a {
    color: #042237 !important;
  }
  .ant-menu-item a:hover {
    color: #042237 !important;
  }
  li:hover {
    background-color: white !important;
    color: #042237 !important;
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: white !important;
  }

  .active {
    background-color: white !important;
  }
`;

export const EligibleCam = styled.div`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #31BF83;
  text-transform: capitalize;
`;
