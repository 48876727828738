import React, {useState, useEffect, useRef} from 'react';
import {Row, Col, Tabs} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import '../../modules/Purchaser/pages/pages.scss';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import useDeviceSize from '../../hooks/useDeviceSize';
import {backgroundStyle} from '../../utils/staticStrings';
import {Show} from '../../components/ShowAndHide';
import Text from '../../components/Text/Text';
import AthleteInfo from '../../modules/Purchaser/components/AthleteInfo';
import AthleteInfoCard from '../../modules/Purchaser/components/AthleteInfoCard';
import AboutSection from './AboutSection';
import {
  CustomTabs,
  Icon,
  ShareDetailsMobile,
} from '../../modules/Purchaser/components/style';
import {CreateCampaign} from '../../modules/Purchaser/components/features/Campaigns/style';
import {setHeader} from '../../store/athleteflow/action';
import AppLayout from '../AppLayout';
import CampaignDetails from '../../modules/Purchaser/components/features/Campaigns/CampaignDetails';
import {CustomCampaign} from '../../modules/Purchaser/components/features/Athletes/styled';
import InviteContainer from '../../modules/Purchaser/components/features/Athletes/InviteContainer';

const {TabPane} = Tabs;

const AthleteProfile = (props) => {
  const {flow} = props;
  const formRef = useRef();
  const size = useDeviceSize();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCustomOfferModalVisible, setIsCustomOfferModalVisible] =
    useState(false);
  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);

  const operations = (
    <ShareDetailsMobile>
      <Icon
        width="18"
        height="18"
        mrRight="16"
        src={backgroundStyle.shareIcon}
        alt="verification"
      />
      <Icon
        width="18"
        height="18"
        src={backgroundStyle.dotsIcon}
        alt="verification"
      />
    </ShareDetailsMobile>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showCustomOfferModal = () => {
    setIsCustomOfferModalVisible(true);
  };

  const handleCustomOfferCancel = () => {
    setIsCustomOfferModalVisible(false);
  };

  const showSubmitOfferModal = () => {
    setIsCustomOfferModalVisible(false);
    setIsSubmitModalVisible(true);
  };

  const handleSubmitOfferCancel = () => {
    setIsSubmitModalVisible(false);
  };

  function callback(key) {
    // console.log(key);
  }

  useEffect(() => {
    if (
      history.location.pathname === '/purchaser/athletes/profile' ||
      history.location.pathname === '/athlete/view-profile'
    ) {
      dispatch(setHeader(false));

      // triggerModal('success', 'Login success');
    }
  }, [history.location.pathname, dispatch]);

  return (
    <AppLayout>
      <CustomCampaign
        style={{top: 100}}
        width={1040}
        zIndex={2500}
        closeIcon={<CloseOutlined />}
        title={
          <div
            className="d-flex justify-content-center align-items-center text-center"
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '18px',
              color: '#042237',
            }}>
            Custom offer for Ruud
          </div>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}>
        <InviteContainer
          type="offer"
          showSubmitOfferModal={showSubmitOfferModal}
        />
      </CustomCampaign>
      <CustomCampaign
        style={{top: 100}}
        width={1040}
        zIndex={2500}
        closeIcon={<CloseOutlined />}
        title={
          <div
            className="d-flex justify-content-center align-items-center text-center"
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '18px',
              color: '#042237',
            }}>
            Select a Campaign to invite Ruud
          </div>
        }
        visible={isCustomOfferModalVisible}
        onCancel={handleCustomOfferCancel}
        footer={null}>
        <InviteContainer type="invite" />
      </CustomCampaign>
      <CreateCampaign
        style={{top: 100}}
        width={1040}
        zIndex={2500}
        closeIcon={<CloseOutlined />}
        title={
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '18px',
              color: '#042237',
            }}>
            Create Campaign
          </div>
        }
        visible={isSubmitModalVisible}
        onCancel={handleSubmitOfferCancel}
        footer={null}>
        <CampaignDetails type="offer" formRef={formRef} />
      </CreateCampaign>
      <div className="bubble-bg"></div>
      <div className="content content-wrapper ">
        {/* <div className="container"> */}
        <Row width="100%">
          <Col
            style={{
              minHeight: 280,
              padding: '45px 25px 10px 0px',
              //   marginRight: '20px',
            }}
            xs={{span: 0}}
            sm={{span: 0}}
            md={{span: 8}}>
            <AboutSection
              type="athlete"
              flow={flow}
              showModal={showModal}
              showCustomOfferModal={showCustomOfferModal}
            />
          </Col>
          <Col
            style={{
              minHeight: 280,
              paddingTop: `40px`,
              paddingLeft: `20px`,
              paddingRight: `20px`,
              position: 'relative',
            }}
            sm={{span: 24}}
            md={{span: 16}}>
            <Show show={size?.width > 767}>
              <AthleteInfo />
              <Row width="100%" className="mt-5">
                <Col>
                  <Text
                    display="flex"
                    weight="700"
                    size="28"
                    lineHeight="32"
                    mrRight="11"
                    textColor="#042237">
                    Ruud's Shop (4)
                  </Text>
                </Col>
              </Row>
              <Row>
                <AthleteInfoCard />
                <AthleteInfoCard />
                <AthleteInfoCard />
                <AthleteInfoCard />
              </Row>
            </Show>
            <Show show={size?.width <= 767}>
              <AthleteInfo
                type={'athlete'}
                flow={flow}
                showModal={showModal}
                showCustomOfferModal={showCustomOfferModal}
              />
              <CustomTabs
                defaultActiveKey="1"
                size="large"
                onChange={callback}
                tabBarExtraContent={operations}>
                <TabPane tab="RUUD'S SHOP" key="1" style={{fontWeight: '700'}}>
                  <Text
                    display="flex"
                    weight="700"
                    size="28"
                    lineHeight="32"
                    mrRight="11"
                    textColor="#042237">
                    <Row>
                      <AthleteInfoCard />
                      <AthleteInfoCard />
                      <AthleteInfoCard />
                      <AthleteInfoCard />
                    </Row>
                  </Text>
                </TabPane>
                <TabPane tab="ABOUT" key="2">
                  <AboutSection />
                </TabPane>
              </CustomTabs>
            </Show>
          </Col>
        </Row>
      </div>
      {/* </div> */}
    </AppLayout>
  );
};

export default AthleteProfile;
