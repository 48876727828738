import React, {useEffect, useState} from 'react';
import {profileSection} from '../../../../../utils/staticStrings';
import '../features.scss';
import {Upload, DatePicker, Popover, Select, Input} from 'antd';
import {icons} from '../../../../../utils/staticStrings';
import {Formik} from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

// import moment from 'moment';
import {UploadAssets} from '../../../../../hooks/UploadImage';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';
import ImgCrop from 'antd-img-crop';
import SearchLocationInput from '../../../../../common/components/GoogleSearchLocation';
import loadGMaps from '../../../../../hooks/loadGMaps';
import Loader from 'react-loader-spinner';

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const CreateProfile = (props) => {
  const {Option} = Select;
  const {
    sportsData,
    collegeDetail,
    team,
    interests,
    profileData,
    athleteTypes,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [loadimgImageStatus, setLoadingImageStatus] = useState(false);
  const [address, setAddress] = useState({});

  // Add type only if mode is 'edit'
  let schema;
  if (props.mode === 'create') {
    schema = yup.object().shape({
      gender: yup.string().required('Please select gender'),
      dob: yup.string().required('Date is required'),
      headliner: yup.string().required('Headliner is required'),
      bio: yup.string().required('Please share bio details'),
      location: yup.string().required('Location required'),
    });
  } else if (props.mode === 'edit') {
    schema = yup.object().shape({
      gender: yup.string().required('Please select gender'),
      dob: yup.string().required('Date is required'),
      type: yup.string().required('Select type'),
      headliner: yup.string().required('Headliner is required'),
      bio: yup.string().required('Please share bio details'),
      location: yup.string().required('Location required'),
    });
  }

  useEffect(() => {
    setAddress(profileData?.address);
  }, [profileData?.address]);

  const getFormattedAddress = (athleteAddress) => {
    if (Object.keys(athleteAddress).length > 0) {
      return athleteAddress?.city + ', ' + athleteAddress?.state + ', ';
    } else {
      return '';
    }
  };

  let initialValues = {
    gender: profileData?.gender ? profileData?.gender : '',
    image_url: profileData?.image_url ? profileData?.image_url : '',
    dob: profileData?.dob
      ? dayjs(profileData?.dob).format('MM-DD-YYYY')
      : undefined,
    location: profileData?.address
      ? getFormattedAddress(profileData?.address)
      : '',
    // street1: profileData?.address?.street1 ? profileData?.address?.street1 : '',
    // zip: profileData?.address?.zip ? profileData?.address?.zip : '',
    headliner: profileData?.athlete?.headliner
      ? profileData?.athlete?.headliner
      : '',
    sports: profileData?.athlete?.sports
      ? profileData?.athlete?.sports.map((obj) => obj.id)
      : [],
    colleges: profileData?.athlete?.colleges
      ? profileData?.athlete?.colleges.map((obj) => obj.id)
      : [],
    teams: profileData?.athlete?.teams
      ? profileData?.athlete?.teams.map((obj) => obj.id)
      : [],
    hometown: profileData?.athlete?.hometown
      ? profileData?.athlete?.hometown
      : '',
    alma_mater_high_school: profileData?.athlete?.alma_mater_high_school
      ? profileData?.athlete?.alma_mater_high_school
      : '',
    alma_mater_college: profileData?.athlete?.alma_mater_college
      ? profileData?.athlete?.alma_mater_college
      : '',
    alma_mater_college_grad_year: profileData?.athlete
      ?.alma_mater_college_grad_year
      ? profileData?.athlete?.alma_mater_college_grad_year
      : '',
    bio: profileData?.bio ? profileData?.bio : '',
    lifestyles: profileData?.athlete?.lifestyles
      ? profileData?.athlete?.lifestyles.map((obj) => obj.id)
      : [],
    accolades: profileData?.athlete?.accolades
      ? profileData?.athlete?.accolades
      : '',
    // custom_deals_enabled: true,
  };

  if (props.mode === 'edit') {
    initialValues.type = profileData?.athlete?.type
      ? profileData?.athlete?.type
      : '';
  }

  const {TextArea} = Input;
  // const handleUpload = (data) => {
  //   setImageUrl(data);
  // };
  const uploadFile = (fileObj, setValue) => {
    const handleUpload = (data) => {
      setValue('image_url', data);
    };
    UploadAssets(fileObj, handleUpload, setLoadingImageStatus)();
  };

  useEffect(() => {
    loadGMaps(() => {
      setLoaded(true);
    });
  });

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toastMessage('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 12;
    if (!isLt2M) {
      errorToastMessage('Image must smaller than 12MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const content = (type, description) => {
    return (
      <div style={{width: '190px'}}>
        <div className="fw-bold font-m">{type}</div>
        <div className="mt-1">{description}</div>
      </div>
    );
  };

  const getChangedValues = (values, initialValues) => {
    return Object.entries(values).reduce((acc, [key, value]) => {
      if (!Array.isArray(value)) {
        const hasChanged = initialValues[key] !== value;
        if (hasChanged) {
          acc[key] = value;
        }
      } else if (value.length > 0) {
        acc[key] = value;
      }

      return acc;
    }, {});
  };

  const profileSubmit = (values) => {
    props.profileFormSubmit({...values});
  };

  function disabledDate(current) {
    // Can not select days after today
    return current && current > dayjs().endOf('day');
  }

  const getAddress = (addressObj) => {
    let currAddress = {};
    addressObj?.address_components.forEach((comp) => {
      if (comp?.types?.includes('locality')) {
        currAddress.city = comp.long_name;
      } else if (comp?.types?.includes('administrative_area_level_1')) {
        currAddress.state = comp.short_name;
      } else if (comp?.types?.includes('country')) {
        currAddress.country = comp.short_name;
      } else if (comp?.types?.includes('postal_code')) {
        currAddress.zip = comp.short_name;
      }
    });
    setAddress(currAddress);
  };

  const getHomeTown = (addressObj) => {
    // Hometown remove zip code
    let currHometown = [];
    addressObj?.address_components.forEach((comp) => {
      if (comp?.types?.includes('locality')) {
        currHometown = currHometown + comp.long_name + ',';
      } else if (comp?.types?.includes('administrative_area_level_1')) {
        currHometown = currHometown + ' ' + comp.short_name + ',';
      } else if (comp?.types?.includes('country')) {
        currHometown = currHometown + ' ' + comp.long_name;
      }
    });
    return currHometown;
  };

  return (
    <>
      <div className="container-fluid p-0 custom-profile-holder">
        {props.mode !== 'edit' && (
          <>
            <div className="profile-text mb-2">{profileSection.text1}</div>
            <div className="profile-title">{profileSection.title}</div>
            <div className="profile-text1 mt-2">{profileSection.text2}</div>
          </>
        )}
        <div className="profilepart-1 mt-5 mb-5">
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => {
              let changedValues = getChangedValues(values, initialValues);
              const createprofilePayload = {...changedValues, dob: values.dob};
              delete createprofilePayload.location;
              // delete createprofilePayload.street1;
              // delete createprofilePayload.zip;
              createprofilePayload.address = address;
              // createprofilePayload.address.street1 = values.street1;
              // createprofilePayload.address.zip = values.zip;

              // console.log(createprofilePayload, changedValues);
              profileSubmit(createprofilePayload);
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isValid,
              isSubmitting,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="container-fluid">
                    <div className="d-md-flex align-items-center">
                      <div className="profile-pic-upload flex-shrink-0 mb-5  mx-auto m-none position-relative">
                        <label
                          className="text text-center"
                          htmlFor="profile-pic">
                          {profileSection.pictext}
                        </label>
                        <ImgCrop rotateSlider>
                          <Upload
                            className={`profile-input  ${
                              values.image_url ? 'profile-image-holder' : ''
                            }`}
                            beforeUpload={beforeUpload}
                            customRequest={(fileList) =>
                              uploadFile(fileList, setFieldValue)
                            }
                            showUploadList={false}
                            name="image_url"
                            maxCount={1}>
                            <div className="">
                              {values.image_url ? (
                                <div className="position-relative">
                                  <img
                                    src={values.image_url}
                                    alt="profilePic"
                                    className="profile-img-holder"
                                  />
                                  <p className="text-purple mt-3 fw-bold text-center change-image-text">
                                    Change photo
                                  </p>
                                </div>
                              ) : (
                                <div className="d-flex align-items-center  flex-column w-100 h-100 ">
                                  <img
                                    src={icons.cameraIcon}
                                    alt="camera Icon"
                                  />
                                  <p className="text-purple mt-3 fw-bold">
                                    Choose Image
                                  </p>
                                </div>
                              )}
                            </div>
                          </Upload>
                        </ImgCrop>
                        {loadimgImageStatus && (
                          <div className="loader text-center">
                            <Loader
                              type="Bars"
                              color="#00BFFF"
                              height={50}
                              width={100}
                            />
                          </div>
                        )}
                        {/* <div className="text-danger text-sm fw-500">
                          {touched.image_url && errors.image_url && errors.image_url}
                        </div> */}
                      </div>
                      <div className="ms-0 ms-md-4 flex-grow-1 mb-3 md-md-5">
                        <div className="mb-3">
                          <label className="text" htmlFor="gender">
                            {profileSection.profilegender}
                            <span className="text-danger"> *</span>
                          </label>
                          <Select
                            placeholder="Select gender"
                            className="custom-select custom-select-light select-option-md"
                            value={values.gender || undefined}
                            onChange={(value) => setFieldValue('gender', value)}
                            name="gender">
                            <Option
                              value="male"
                              className="custom-select-option">
                              {profileSection.profilegentype1}
                            </Option>
                            <Option
                              value="female"
                              className="custom-select-option">
                              {profileSection.profilegentype2}
                            </Option>
                          </Select>
                          <div className="text-danger text-sm fw-500">
                            {touched.gender && errors.gender && errors.gender}
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="text" htmlFor="date">
                            {profileSection.date}
                            <span className="text-danger"> *</span>
                          </label>
                          <DatePicker
                            className="pro-input input-lg"
                            disabledDate={disabledDate}
                            name="dob"
                            format="MM/DD/YYYY"
                            value={values.dob ? dayjs(values.dob, 'MM-DD-YYYY') : undefined}
                            onChange={(date) => {
                              if (date) {
                                // console.log(date.format('MM/DD/YYYY'));
                                setFieldValue('dob', date.format('MM-DD-YYYY'));
                              }
                            }}
                          />
                        </div>
                        <div
                          className={`mb-4 ${
                            props.mode === 'edit' ? 'edit-location' : ''
                          }`}>
                          <label className="text" htmlFor="location">
                            {profileSection.location}
                            <span className="text-danger"> *</span>
                          </label>
                          {loaded && (
                            <>
                              <SearchLocationInput
                                name="location"
                                id="location"
                                placeholder={
                                  props.mode === 'edit'
                                    ? values.location
                                    : 'City'
                                }
                                onChange={(val, addressObject) => {
                                  setFieldValue('location', val);
                                  getAddress(addressObject);
                                }}
                              />
                            </>
                          )}

                          <div className="text-danger text-sm fw-500">
                            {touched.location &&
                              errors.location &&
                              errors.location}
                          </div>
                        </div>
                      </div>
                    </div>

                    {props.mode === 'edit' && (
                      <div className="col-12 mb-4">
                        <label className="text" htmlFor="type">
                          Your athlete type
                          <span className="text-danger"> *</span>
                        </label>
                        <Select
                          className="custom-select custom-select-light"
                          style={{textTransform: 'capitalize'}}
                          name="type"
                          id="type"
                          value={values.type}
                          placeholder="Select type"
                          onChange={(value) => setFieldValue('type', value)}>
                          {athleteTypes?.data?.map((option) => (
                            <Option
                              value={option}
                              key={option}
                              className="custom-select-option"
                              style={{textTransform: 'capitalize'}}>
                              {option}
                            </Option>
                          ))}
                        </Select>
                        <div className="text-danger text-sm fw-500">
                          {touched.type && errors.type && errors.type}
                        </div>
                      </div>
                    )}
                    <div className="mb-4">
                      <label className="text" htmlFor="location">
                        Set your profile's headliner
                        <Popover
                          content={content(
                            'Headliner',
                            'Your headliner is a short descriptor beneath your name and profile picture. It is your first text-based intro to a visitor to know who you are.',
                          )}
                          trigger="hover"
                          placement="right"
                          className="info-icon ms-2">
                          <span>?</span>
                        </Popover>
                        <span className="text-danger ms-2"> *</span>
                      </label>
                      <TextArea
                        name="headliner"
                        showCount
                        value={values.headliner}
                        className="mt-1 custom-text-count"
                        placeholder="E.g.”NCAA football-University of Michigan”; “Former pro golfer”; “Olympic beach volleyball player”;"
                        maxLength={86}
                        onChange={(e) => {
                          setFieldValue('headliner', e.target.value);
                        }}
                      />
                      <div className="text-danger text-sm fw-500">
                        {touched.headliner &&
                          errors.headliner &&
                          errors.headliner}
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="text" htmlFor="hometown">
                        Tag your sport
                        <Popover
                          content={content(
                            'Sport(s)',
                            'Tag the sport(s) you play, coach, or cover in the media. If retired, the sport from your career.',
                          )}
                          trigger="hover"
                          placement="right"
                          className="info-icon ms-2">
                          <span>?</span>
                        </Popover>
                      </label>

                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Start typing sport"
                        value={values.sports}
                        onChange={(value) => setFieldValue('sports', value)}
                        name="sports"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {sportsData?.data?.rows.map((option) => (
                          <Select.Option
                            value={option.id}
                            key={option.id}
                            disabled={
                              values.sports.length >= 3
                                ? values.sports.includes(option.id)
                                  ? false
                                  : true
                                : false
                            }
                            className="custom-select-option">
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-4">
                      <label className="text" htmlFor="high_school">
                        Tag your college/university
                        <Popover
                          content={content(
                            'College/University',
                            'Tag the college(s) you play for, coach for, or cover in the media. Can be currently or in the past. If attended solely academically, do not tag here but list under ‘Alma mater-college’ below.',
                          )}
                          trigger="hover"
                          placement="right"
                          className="info-icon ms-2">
                          <span>?</span>
                        </Popover>
                      </label>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Start typing college name"
                        value={values.colleges}
                        onChange={(value) => setFieldValue('colleges', value)}
                        name="colleges"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {collegeDetail?.data?.rows.map((option) => (
                          <Select.Option
                            value={option.id}
                            key={option.id}
                            disabled={
                              values.colleges.length >= 3
                                ? values.colleges.includes(option.id)
                                  ? false
                                  : true
                                : false
                            }
                            className="custom-select-option">
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-4">
                      <label className="text" htmlFor="high_school">
                        Tag your pro teams or organizations
                        <Popover
                          content={content(
                            'Pro teams or organizations',
                            'Tag the pro team(s) or organization(s) that you play for, coach for, or cover in the media. Can be currently or in the past. If multiple, we recommend tagging the 1-3 teams that you are most associated with.',
                          )}
                          trigger="hover"
                          placement="right"
                          className="info-icon ms-2">
                          <span>?</span>
                        </Popover>
                      </label>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Start typing team name"
                        name="teams"
                        value={values.teams}
                        onChange={(value) => setFieldValue('teams', value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {team?.data?.rows.map((option) => (
                          <Select.Option
                            value={option.id}
                            key={option.id}
                            disabled={
                              values.teams.length >= 3
                                ? values.teams.includes(option.id)
                                  ? false
                                  : true
                                : false
                            }
                            className="custom-select-option">
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>

                    <div
                      className={`mb-4 ${
                        props.mode === 'edit' ? 'edit-location' : ''
                      }`}>
                      <label className="text" htmlFor="hometown">
                        Hometown
                      </label>
                      {loaded && (
                        <SearchLocationInput
                          name="hometown"
                          id="hometown"
                          placeholder={
                            props.mode === 'edit' ? values.hometown : 'Hometown'
                          }
                          onChange={(value, addressObject) => {
                            let homeTownString = getHomeTown(addressObject);
                            setFieldValue('hometown', homeTownString);
                          }}
                        />
                      )}
                    </div>

                    <div className="mb-4">
                      <label className="text" htmlFor="high_school">
                        Alma mater - High School
                      </label>
                      <input
                        className="pro-input mt-1"
                        name="alma_mater_high_school"
                        id="alma_mater_high_school"
                        placeholder="High School"
                        value={values.alma_mater_high_school}
                        onChange={(e) => {
                          setFieldValue(
                            'alma_mater_high_school',
                            e.target.value,
                          );
                        }}
                      />
                    </div>

                    <div className="row mb-3 mx-n1">
                      <div className="col-lg-8 col-md-6 col-12 mt-1 mb-3 mb-md-0 px-1">
                        <label className="text" htmlFor="college">
                          Alma mater - College
                          <Popover
                            content={content(
                              'College',
                              'When you attended college as a student regardless of if you participated in athletics.',
                            )}
                            trigger="hover"
                            placement="right"
                            className="info-icon ms-2">
                            <span>?</span>
                          </Popover>
                        </label>
                        <input
                          className="pro-input mt-1"
                          name="alma_mater_college"
                          id="alma_mater_college"
                          placeholder={profileSection.college}
                          value={values.alma_mater_college}
                          onChange={(e) => {
                            setFieldValue('alma_mater_college', e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-1 px-1">
                        <label className="text" htmlFor="college">
                          Graduation year
                          <Popover
                            content={content(
                              'Graduation',
                              'Graduation year can be historical year, expected future year, or left blank.',
                            )}
                            trigger="hover"
                            placement="right"
                            className="info-icon ms-2">
                            <span>?</span>
                          </Popover>
                        </label>
                        <input
                          className="pro-input mt-1"
                          name="college_grad_year"
                          id="Gradyear"
                          type="number"
                          value={values.alma_mater_college_grad_year}
                          placeholder="Graduation year"
                          onChange={(e) => {
                            setFieldValue(
                              'alma_mater_college_grad_year',
                              e.target.value,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-4 textarea-custom">
                      <label className="text" htmlFor="bio">
                        {profileSection.bio}
                        <Popover
                          content={content(
                            'Bio',
                            'Share any additional relevant details about who you are, your background, or aspirations',
                          )}
                          trigger="hover"
                          placement="right"
                          className="info-icon ms-2">
                          <span>?</span>
                        </Popover>

                        <span className="text-danger ms-2">*</span>
                      </label>
                      <TextArea
                        name="bio"
                        showCount
                        className="mt-1 custom-text-count"
                        placeholder="Add bio details"
                        maxLength={1000}
                        value={values.bio}
                        onChange={(e) => setFieldValue('bio', e.target.value)}
                      />
                      <div className="text-danger text-sm fw-500">
                        {touched.bio && errors.bio && errors.bio}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="text" htmlFor="hometown">
                        Lifestyles & Interests
                        <Popover
                          content={content(
                            'Lifestyles & Interests',
                            'Choose from some pre - selected tags to let brands and fans get to know you a little better. (Select up to 10).',
                          )}
                          trigger="hover"
                          placement="right"
                          className="info-icon ms-2">
                          <span>?</span>
                        </Popover>
                      </label>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Start typing lifestyles"
                        value={values.lifestyles}
                        onChange={(value) => setFieldValue('lifestyles', value)}
                        name="lifestyles"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {interests?.data?.rows.map((option) => (
                          <Select.Option
                            value={option.id}
                            key={option.id}
                            disabled={
                              values.lifestyles.length >= 10
                                ? values.lifestyles.includes(option.id)
                                  ? false
                                  : true
                                : false
                            }
                            className="custom-select-option">
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-5">
                      <label className="text" htmlFor="location">
                        Accolades
                        <Popover
                          content={content(
                            'Accolades',
                            'Share details on any hard-earned accolades you’ve won.',
                          )}
                          trigger="hover"
                          placement="right"
                          className="info-icon ms-2">
                          <span>?</span>
                        </Popover>
                      </label>
                      <TextArea
                        name="accolades"
                        showCount
                        className="mt-1 custom-text-count"
                        placeholder="Add accolades"
                        maxLength={1000}
                        value={values.accolades}
                        onChange={(e) =>
                          setFieldValue('accolades', e.target.value)
                        }
                      />
                    </div>

                    <div
                      className={`${
                        props.mode !== 'edit' ? 'text-end' : 'text-center'
                      }`}>
                      <button
                        type="submit"
                        className={`btn btn-primary ${
                          props.mode !== 'edit' ? 'mw-170' : 'mw-570'
                        }`}>
                        {props.mode !== 'edit'
                          ? profileSection.btnlabel
                          : 'SAVE'}
                      </button>
                    </div>
                    {/* ) : (
                      <div className="text-center">
                        <button
                          type="submit"
                          // onClick={() => props.upadateActiveKey('3')}
                          className="btn btn-primary ">
                          SAVE
                        </button>
                      </div>
                    )} */}
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateProfile;
