// Login Action Types
export const HANDLE_ERROR = 'HANDLE_ERROR';
export const HANDLE_SUCCESS_RESPONSE = 'HANDLE_SUCCESS_RESPONSE';
export const HANDLE_REGISTER = 'HANDLE_REGISTER';
export const HANDLE_SPORTS_LISTS = 'HANDLE_SPORTS_LISTS';
export const HANDLE_SPORTS_LISTS_SUCCESS = 'HANDLE_SPORTS_LISTS_SUCCESS';
export const HANDLE_REGISTER_SUCCESS = 'HANDLE_REGISTER_SUCCESS';
export const HANDLE_RESET_PASSWORD = 'HANDLE_RESET_PASSWORD';
export const HANDLE_FORGOT_PASSWORD = 'HANDLE_FORGOT_PASSWORD';
export const HANDLE_MESSAGE = 'HANDLE_MESSAGE';
export const HANDLE_PROFILE_SUBMIT = 'HANDLE_PROFILE_SUBMIT';
export const HANDLE_PROFILE_SUBMIT_SUCCESS = 'HANDLE_PROFILE_SUBMIT_SUCCESS';
export const SET_HEADER = 'SET_HEADER';
export const CREATE_LIST = 'CREATE_LIST';
export const CREATE_STATUS = 'CREATE_STATUS';
export const RESEND_FERIFICATION = 'RESEND_FERIFICATION';
export const ATHLETE_LOGIN_DATA = 'ATHLETE_LOGIN_DATA';
export const RESET_EMAIL_STATUS = 'RESET_EMAIL_STATUS';
export const ATHLETE_PROFILE_SUBMIT_STATUS = 'ATHLETE_PROFILE_SUBMIT_STATUS';
export const HANDLE_CREATE_STRIPE_ACCOUNT = 'HANDLE_CREATE_STRIPE_ACCOUNT';
export const REDIRECT_CREATE_STRIPE_ACCOUNT = 'REDIRECT_CREATE_STRIPE_ACCOUNT';
export const CREATE_STRIPE_SUBSCRIPTION = 'CREATE_STRIPE_SUBSCRIPTION';
export const REDIRECT_CREATE_STRIPE_SUBSCRIPTION =
  'REDIRECT_CREATE_STRIPE_SUBSCRIPTION';
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_SHOP_LISTING = 'GET_SHOP_LISTINGs';
export const SET_SHOP_LISTING = 'SET_SHOP_LISTING';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const SET_COUPON_VALID = 'SET_COUPON_VALID';
export const GET_STRIPE_STATUS = 'GET_STRIPE_STATUS';
export const SET_STRIPE_STATUS = 'SET_STRIPE_STATUS';
export const EDIT_LIST_STATUS = 'EDIT_LIST_STATUS';
export const LOADER = 'LOADER';
export const GET_ATH_CAMPAIGNS = 'GET_ATH_CAMPAIGNS';
export const SET_ATH_CAMPAIGNS = 'SET_ATH_CAMPAIGNS';
export const APPEND_ATH_CAMPAIGNS = 'APPEND_ATH_CAMPAIGNS';
export const GET_INVITED_CAMPAIGNS = 'GET_INVITED_CAMPAIGNS';
export const SET_INVITED_CAMPAIGNS = 'SET_INVITED_CAMPAIGNS';
export const GET_ATH_CAMPAIGN_DATA = 'GET_ATH_CAMPAIGN_DATA';
export const SET_ATH_CAMPAIGN_DATA = 'SET_ATH_CAMPAIGN_DATA';
export const GET_ATH_DEALS = 'GET_ATH_DEALS';
export const SET_ATH_DEALS = 'SET_ATH_DEALS';
export const APPEND_ATH_DEALS = 'APPEND_ATH_DEALS';
export const GET_ATH_DEAL_DATA = 'GET_ATH_DEAL_DATA';
export const SET_ATH_DEAL_DATA = 'SET_ATH_DEAL_DATA';
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
export const SET_ORDER_COMPLETD_SUCESS = 'SET_ORDER_COMPLETD_SUCESS';
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS;';
export const APPLY_TO_CAMPAIGN = 'APPLY_TO_CAMPAIGN';
export const APPLY_CAMPAIGN_STATUS = 'APPLY_CAMPAIGN_STATUS';
export const EDIT_LISTING = 'EDIT_LISTING';
export const EDIT_LISTING_STATUS = 'EDIT_LISTING_STATUS';
export const UPDATE_DEAL_STATUS = 'UPDATE_DEAL_STATUS';
export const UPDATE_DEAL_COUNTER_OFFER_STATUS =
  'UPDATE_DEAL_COUNTER_OFFER_STATUS';
export const DEAL_STATUS_CHANGED = 'DEAL_STATUS_CHANGED';
export const COUNTER_OFFER = 'COUNTER_OFFER';
export const COUNTER_OFFER_CREATED = 'COUNTER_OFFER_CREATED';
export const ORDER_CANCEL = 'ORDER_CANCEL';
export const ORDER_CANCEL_STATUS = 'ORDER_CANCEL_STATUS';
export const BROWSER_LIST_BACK = 'BROWSER_LIST_BACK';
export const VIDEO_TOKEN = 'VIDEO_TOKEN';
