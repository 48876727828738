import React, {useState, useEffect, useRef} from 'react';
import {Carousel} from 'antd';
import YoutubeEmbed from './YoutubeEmbed';
import {Avatar, Badge} from 'antd';
import {PlayCircleOutlined} from '@ant-design/icons';
import useDeviceSize from '../../hooks/useDeviceSize';

const CustomCarouselThumbnail = (props) => {
  const {slidesData} = props;
  const size = useDeviceSize();
  // const slidesData = ['https://s3.amazonaws.com/backend-stage-use1.athleteup.com/images/1651666744075.png', 'https://s3.amazonaws.com/backend-stage-use1.athleteup.com/images/1651666894936.png', 'https://s3.amazonaws.com/backend-stage-use1.athleteup.com/images/1651666909235.png']
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const isValidUrl = (string) => {
    const matchpattern =
      /* eslint-disable-next-line */
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
    return matchpattern.test(string);
  };

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: `${size.width > 781 ? false : true}`,
    fade: true,
    asNavFor: '.slider-nav',
  };

  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '5px',
    infinite: true,
  };

  return (
    <div className="CustomCarouselThumbnail">
      <div className="slider-wrapper">
        <Carousel
          {...settingsMain}
          asNavFor={nav2}
          ref={slider1}
          className="slide-wrap">
          {slidesData.map((slide, index) => {
            let image = isValidUrl(slide);
            return (
              <div className="slick-slide" key={index}>
                {image ? (
                  <img className="slick-slide-image" src={slide} alt={index} />
                ) : (
                  <>
                    <YoutubeEmbed embedId={slide} />
                  </>
                )}
              </div>
            );
          })}
        </Carousel>

        {slidesData.length >= 2 && (
          <div className="thumbnail-slider-wrap">
            <Carousel {...settingsThumbs} asNavFor={nav1} ref={slider2}>
              {slidesData.map((slide, index) => {
                let image = isValidUrl(slide);
                return (
                  <div className="slick-slide" key={index}>
                    {image ? (
                      <img
                        className="slick-slide-image"
                        src={slide}
                        alt={index}
                        width="120px"
                      />
                    ) : (
                      // <YoutubeEmbed embedId={slide} />

                      <Badge
                        count={<PlayCircleOutlined />}
                        className="badge-play">
                        <Avatar
                          src={`https://img.youtube.com/vi/${slide}/hqdefault.jpg`}
                          alt="card-img"
                          className={`img-fluid object-fit-contain purchases-list-imag sm `}
                        />
                      </Badge>
                    )}
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomCarouselThumbnail;
