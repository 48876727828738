import React from "react";
import Text from "../../../../../components/Text/Text";
import { CheckoutButton } from "../../../pages/style";
import { CampaignDetailContainer } from "./style";

const ArchiveCampaign = ({moveToArchive}) => {
  return (
    <CampaignDetailContainer className="my-3">
      <Text
        display="flex"
        weight="500"
        size="18"
        lineHeight="30"
        mrBottom="6"
        className="form-label "
        textColor="#042237">
         Please confirm that you would like to move this Campaign to Archive. Once this Campaign has been Archived, it will no longer be able to be activated again to receive new applications or send offers. The campaign details will 
         still be available to reference in the "History" tab.
      </Text>
      <CheckoutButton onClick={moveToArchive}>
        <Text weight="800" size="18" lineHeight="23" textColor="#FFFFFF" cursor="pointer">
          Move to archive
        </Text>
      </CheckoutButton>
    </CampaignDetailContainer>
  );
}

export default ArchiveCampaign;