import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setHeader} from '../../../../../store/athleteflow/action';
import {useLocation} from 'react-router-dom';
import AppLayout from '../../../../../common/AppLayout';
import AppHeader from '../../../../../common/components/AppHeader';
import StatsData from '../../../../../common/components/Stats';
import {Tabs} from 'antd';
import {
  CustomTabs,
  LayoutDesktopView,
} from '../../../../../modules/Purchaser/components/style';
import Orders from '../../../../../modules/Purchaser/components/features/Dashboard/Orders';
import http from '../../../../../helpers/http';
import OrderTable from '../../../../../common/components/OrderTable';
import useDeviceSize from '../../../../../hooks/useDeviceSize';
import {handleSidebarTab} from '../../../../../store/users/actions';
import {useInterval} from '../../../../../hooks/intervalHook';
import useTabActive from '../../../../../hooks/useTabActive';
import SubScriptionBanner from '../../../../../common/components/SubscriptionBanner';

const AtheletOrders = () => {
  const dispatch = useDispatch();
  const size = useDeviceSize();
  const location = useLocation();
  const [orderPage, setPagevalue] = useState({
    pageSize: 12,
    page: 1,
    status: '',
    user: 'athlete',
  });
  const {TabPane} = Tabs;
  const [tabPane] = useTabActive();
  const [statsData, setStatsData] = useState({});
  const [orderCount, setOrderCount] = useState(0);
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadPath, setLoad] = useState(false);
  const [newOrderCount, setNewOrderCount] = useState(0);
  const {stripeStatus, membeShipExpired} = useSelector(
    (state) => state.AthleteModuleReducer,
  );

  const fetchOrders = async (data) => {
    try {
      setLoading(true);
      let url = `/athlete/order?page=${data.page}&pageSize=${data.pageSize}`;
      if (data?.status) {
        url = `${url}&status=${data.status}`;
      }
      const res = await http.get(url);
      setStatsData(res.data.data.stats);
      setOrderCount(res.data.data.orders.count);
      setNewOrderCount(res.data.data.new_orders.count);
      setOrderData(res.data.data.orders.rows);
      setLoading(false);
    } catch (err) {
      console.log(err);
      // setError(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (location.pathname === '/athlete/orders') {
      dispatch(setHeader(false));
      dispatch(handleSidebarTab('orders'));
    }
  }, [location.pathname, dispatch]);

  const callbackOrder = useCallback(async () => {
    try {
      fetchOrders(orderPage);
      if (location.pathname === '/athlete/orders') {
        setLoad(true);
      } else {
        setLoad(false);
      }
    } catch (err) {
      // dispatch(hideModal());
      console.log(err, 'Fetch api error');
    }
  }, [orderPage, location.pathname, setLoad]);

  useInterval(callbackOrder, loadPath === true && tabPane ? 40000 : null);

  useEffect(() => {
    callbackOrder();
  }, [callbackOrder]);

  const pageHandler = (page) => {
    if (page.current) {
      setPagevalue({...orderPage, page: page.current});
    }
  };
  const callback = (key) => {
    if (key !== 'all') {
      setPagevalue({...orderPage, page: 1, status: key, pageSize: 12});
    } else {
      setPagevalue({page: 1, pageSize: 12});
    }
  };

  // console.log(orderCount, "orderData data", orderPage.pageSize)

  const loadMore = () => {
    if (orderCount > orderPage.pageSize) {
      setPagevalue((prev) => {
        return {
          ...orderPage,
          pageSize: prev.pageSize + 12,
        };
      });
    }
  };
  return (
    <AppLayout>
      <div className="bubble-bg"></div>
      <div className="content-wrapper">
        <AppHeader
          title="Orders"
          subTitle={
            <p className="font-20 color-muted mb-5 fw-600">
              View and manage every order from sale to fulfillment.
            </p>
          }
        />
        {membeShipExpired && <SubScriptionBanner stripeStatus={stripeStatus} />}
        <StatsData
          title="Your orders"
          cardLabel1="In progress"
          cardLabel2="Total orders completed "
          cardLabel3="Order earnings this month"
          cardLabel4="Total order earnings"
          label1Data={statsData?.orders?.active}
          label2Data={statsData?.orders?.completed}
          label3Data={statsData?.earnings_this_month}
          label4Data={statsData?.earnings_total}
          labelNewCount1={newOrderCount}
          labelNewCount2={statsData?.orders_new?.completed}
        />
        {/* {loading && (
          <div className="d-flex align-items-center justify-content-center">
            <Loader type="Bars" color="#00BFFF" height={50} width={100} />
          </div>
        )} */}
        {size?.width > 767.98 ? (
          <LayoutDesktopView>
            <CustomTabs defaultActiveKey="all" size="large" onChange={callback}>
              <TabPane tab="ALL ORDERS" key="all">
                <OrderTable
                  loading={loading}
                  orderData={orderData}
                  item="athlete"
                  onChange={pageHandler}
                  current={orderPage.page}
                  count={orderCount}
                />
              </TabPane>
              <TabPane tab="IN PROGRESS" key="active">
                <OrderTable
                  loading={loading}
                  orderData={orderData}
                  item="athlete"
                  onChange={pageHandler}
                  current={orderPage.page}
                  count={orderCount}
                />
              </TabPane>
              <TabPane tab="COMPLETED" key="completed">
                <OrderTable
                  loading={loading}
                  orderData={orderData}
                  item="athlete"
                  onChange={pageHandler}
                  current={orderPage.page}
                  count={orderCount}
                />
              </TabPane>
              <TabPane tab="DISPUTED" key="disputed">
                <OrderTable
                  loading={loading}
                  orderData={orderData}
                  item="athlete"
                  onChange={pageHandler}
                  current={orderPage.page}
                  count={orderCount}
                />
              </TabPane>
              <TabPane tab="CANCELLED" key="declined">
                <OrderTable
                  loading={loading}
                  orderData={orderData}
                  item="athlete"
                  onChange={pageHandler}
                  current={orderPage.page}
                  count={orderCount}
                />
              </TabPane>
            </CustomTabs>
          </LayoutDesktopView>
        ) : (
          <CustomTabs defaultActiveKey="all" size="large" onChange={callback}>
            <TabPane tab="ALL ORDERS" key="all">
              <>
                {orderCount !== 0 ? (
                  <Orders
                    item="athlete"
                    orderData={orderData}
                    orderCount={orderCount}
                  />
                ) : (
                  <h5 className="text-muted text-center py-5">
                    No orders to display
                  </h5>
                )}
                {orderCount > orderPage.pageSize && (
                  <button
                    type="button"
                    className="btn btn-lg btn-primary mt-3 view-more  "
                    onClick={loadMore}>
                    view more
                  </button>
                )}
              </>
            </TabPane>
            <TabPane tab="IN PROGRESS" key="active">
              <>
                {orderCount !== 0 ? (
                  <Orders
                    item="athlete"
                    orderData={orderData}
                    orderCount={orderCount}
                  />
                ) : (
                  <h5 className="text-muted text-center py-5">
                    No orders to display
                  </h5>
                )}
                {orderCount > orderPage.pageSize && (
                  <button
                    type="button"
                    className="btn btn-lg btn-primary mt-3 view-more  "
                    onClick={loadMore}>
                    view more
                  </button>
                )}
              </>
            </TabPane>
            <TabPane tab="COMPLETED" key="completed">
              {orderCount !== 0 ? (
                <Orders
                  item="athlete"
                  orderData={orderData}
                  orderCount={orderCount}
                />
              ) : (
                <h5 className="text-muted text-center py-5">
                  No orders to display
                </h5>
              )}
              {orderCount > orderPage.pageSize && (
                <button
                  type="button"
                  className="btn btn-lg btn-primary mt-3 view-more  "
                  onClick={loadMore}>
                  view more
                </button>
              )}
            </TabPane>
            <TabPane tab="DISPUTED" key="disputed">
              {orderCount !== 0 ? (
                <Orders
                  item="athlete"
                  orderData={orderData}
                  orderCount={orderCount}
                />
              ) : (
                <h5 className="text-muted text-center py-5">
                  No orders to display
                </h5>
              )}
              {orderCount > orderPage.pageSize && (
                <button
                  type="button"
                  className="btn btn-lg btn-primary mt-3 view-more  "
                  onClick={loadMore}>
                  view more
                </button>
              )}
            </TabPane>
            <TabPane tab="CANCELLED" key="declined">
              <>
                {orderCount !== 0 ? (
                  <Orders
                    item="athlete"
                    orderData={orderData}
                    orderCount={orderCount}
                  />
                ) : (
                  <h5 className="text-muted text-center py-5">
                    No orders to display
                  </h5>
                )}
                {orderCount > orderPage.pageSize && (
                  <button
                    type="button"
                    className="btn btn-lg btn-primary mt-3 view-more  "
                    onClick={loadMore}>
                    view more
                  </button>
                )}
              </>
            </TabPane>
          </CustomTabs>
        )}
      </div>
    </AppLayout>
  );
};

export default AtheletOrders;
