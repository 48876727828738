import React from 'react';
import Text from '../../../../../components/Text/Text';
import {CampaignDetailContainer} from '../../../../Purchaser/components/features/Campaigns/style';
import {CheckoutButton} from '../../../../Purchaser/pages/style';

const DisputeDeal = ({markDisputed, orderType}) => {
  return (
    <CampaignDetailContainer className="my-3">
      <Text
        display="flex"
        weight="500"
        size="18"
        lineHeight="30"
        mrBottom="6"
        className="form-label "
        textColor="#042237">
        Please confirm you wish to dispute this
        {orderType === 'order' ? ' order' : ' deal'}. If a dispute is initiated,
        AthleteUp Admin will be reaching out to both parties to gather further
        information. The message thread remains open during the dispute period
        and both parties are encouraged to first attempt to work out any issues
        or misunderstandings between themselves before requiring Admin dispute
        resolution assistance.
      </Text>
      <CheckoutButton onClick={markDisputed}>
        <Text
          weight="800"
          size="18"
          lineHeight="23"
          textColor="#FFFFFF"
          cursor="pointer">
          Dispute {orderType === 'order' ? 'Order' : 'Deal'}
        </Text>
      </CheckoutButton>
    </CampaignDetailContainer>
  );
};

export default DisputeDeal;
