import React from 'react';
import './chart.scss';
import {formatSocialCount} from '../../../helpers/user';
import Text from '../../../components/Text/Text';

const StackedChart = ({socialData}) => {
  return (
    <>
      <div className="social-media-meter">
        {socialData.map((item, i) => {
          return (
            item.count > 0 && (
              <div
                id={item.name}
                className=" d-flex"
                key={i}
                style={{flex: item.value}}>
                .
              </div>
            )
          );
        })}
      </div>
      <div className="social-media-meter media-icon-set ">
        {socialData.map((item, i) => {
          return (
            item.count > 0 && (
              <div
                key={'media' + i}
                className="media-icon flex-fill d-flex"
                style={{flex: item.value, textAlign: 'center'}}
                onClick={() => {
                  window.open(item.url, '_blank');
                }}>
                <span className="cursor-pointer">{item.icon}</span>
                <Text
                  display="flex"
                  weight="500"
                  size="16"
                  mrLeft="5"
                  lineHeight="28"
                  textColor="#435969"
                  cursor="pointer">
                  {formatSocialCount(item.count)}
                </Text>
              </div>
            )
          );
        })}
      </div>
    </>
  );
};

export default StackedChart;
