import React from 'react';
import {formatPriceCommas} from '../../helpers/user';
import {Tooltip} from 'antd';
import {pricingSection} from '../../utils/staticStrings';

const Stats = (props) => {
  const {
    cardLabel1,
    cardLabel2,
    cardLabel3,
    cardLabel4,
    label1Data,
    label2Data,
    label3Data,
    label4Data,
    swap,
    labelNewCount1,
    labelNewCount2,
    type,
    tooltipText,
  } = props;

  return (
    <>
      <h6 className="font-m fw-bold color-muted text-uppercase ls-m mb-3">
        {props.title}
      </h6>
      <div className="row stats-card">
        <div className="col-xl-3 col-sm-6 col-6 mb-4 px-2 px-xl-3">
          <div className="card-grid card-var-1">
            <h6 className="color-muted">{cardLabel1}</h6>
            <div className="d-flex align-items-center  justify-content-md-start">
              {type === 'statsDollor' ? (
                <h2 className="m-0 me-2 ">{formatPriceCommas(label1Data)}</h2>
              ) : (
                <h2 className="m-0 me-2 ">{label1Data ? label1Data : 0}</h2>
              )}
              {labelNewCount1 > 0 && (
                <span className="badge bg-purple">
                  {labelNewCount1} {type === 'campaign' ? 'active' : 'New'}
                </span>
              )}
              {/* {statsData.data.new_orders > 0 &&
                <span className="badge bg-purple">{statsData.data.new_orders} New</span>
              } */}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-6 mb-4 px-2 px-xl-3">
          <div className="card-grid card-var-2">
            <h6 className="color-muted">
              {cardLabel2}
              {tooltipText && (
                <Tooltip placement="bottom" title={tooltipText}>
                  <span className="price-img">
                    <img
                      src={
                        pricingSection?.pricingSectionAthleteListCard
                          ?.section4texticon
                      }
                      alt="priceimg"
                    />
                  </span>
                </Tooltip>
              )}
            </h6>
            <div className="d-flex align-items-center  justify-content-md-start">
              {type === 'statsDollor' ? (
                <h2 className="m-0 me-2 ">{formatPriceCommas(label2Data)}</h2>
              ) : (
                <h2 className="m-0 me-2 ">{label2Data ? label2Data : 0}</h2>
              )}

              {labelNewCount2 > 0 && (
                <span className="badge bg-purple">
                  {labelNewCount2} {type === 'campaign' ? 'in progress' : 'New'}
                </span>
              )}
            </div>
          </div>
        </div>
        <div
          className={`col-xl-3 col-sm-6 col-6 mb-4 px-2 px-xl-3 ${
            swap ? 'order-md-4' : ''
          } `}>
          <div className="card-grid card-var-3">
            <h6 className="color-light">{cardLabel3}</h6>
            <div className="d-flex align-items-center d-none d-lg-block">
              <h2 className="m-0 me-2 text-white">
                ${label3Data ? formatPriceCommas(label3Data) : 0}
              </h2>
            </div>
            <div className="d-flex align-items-center d-block d-lg-none">
              <h2 className="m-0 me-2 text-white">
                ${label3Data ? formatPriceCommas(label3Data) : 0}
              </h2>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-6 mb-4 px-2 px-xl-3">
          <div className="card-grid card-var-3">
            <h6 className="color-light">{cardLabel4}</h6>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center d-none d-lg-block">
                <h2 className="m-0 me-2 text-white">
                  ${label4Data ? formatPriceCommas(label4Data) : 0}
                </h2>
              </div>
              <div className="d-flex align-items-center d-block d-lg-none">
                <h2
                  // style={{fontSize: '22px'}}
                  className="m-0 me-2 text-white">
                  ${label4Data ? formatPriceCommas(label4Data) : 0}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
