import React, { useEffect, useState } from 'react';
import { icons } from '../../../../../utils/staticStrings';
import { useDispatch, useSelector } from 'react-redux';
import { createStripeAccountRedirect, setHeader } from '../../../../../store/athleteflow/action';
import {
  createStripeAccount,
} from '../../../../../store/athleteflow/action';
import Loader from 'react-loader-spinner';
// import { errorToastMessage } from '../../../../../helpers/toastMessage';

const PricingConfirmation = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { createStripeAccountUrl } = useSelector(
    (state) => state.AthleteModuleReducer,
  );

  useEffect(() => {
    dispatch(setHeader(true));
  }, [dispatch]);

  // To do - handle errors

  // useEffect(() => {
  //   if (error.message.statusCode === 400 || error.message.statusCode === 404) {
  //     errorToastMessage(error.message.message);
  //   }
  //   if (message.text.success === false) {
  //     errorToastMessage(message.text.message);
  //   }
  // }, [error, message]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createStripeAccountUrl !== 'null' && createStripeAccountUrl !== undefined) {
      console.log("createStripeAccountUrl ", createStripeAccountUrl);
      const redirectUrl = createStripeAccountUrl;
      dispatch(createStripeAccountRedirect("null"));
      window.location.replace(redirectUrl);
    }
  }, [createStripeAccountUrl, dispatch]);

  const handleCreateAccount = () => {
    setIsLoading(true);
    dispatch(createStripeAccount());
  }

  return (
    <>
      {isLoading &&
        <div className="loader">
          <Loader
            type="Bars"
            color="#00BFFF"
            height={50}
            width={100}
          />
        </div>
      }
      {!isLoading &&
        <div className="athlete-page-bg pricing-confirmation">
          <div className="profile-container">
            <div className="profile-main px-3 px-md-0 pricing-confirmation">
              <div className="profile-section">
                <div className="container-fluid p-0 text-center">
                  <img src={icons.tickColorIcon} alt="tickIcon" className="mt-3 mb-5" />
                  <div className="profile-title">You are now signed up on AthleteUp</div>
                  <div className="mw-770 mx-auto">
                    <div className="profile-text1 mt-2 mb-5 font-20">
                      You can now start selling custom experiences and products from your personal shop while also landing exclusive sponsorship deals.
                    </div>
                    <div className="card-light card-list-spacing">
                      <h6 className="font-l fw-bold">Set how you'll get paid</h6>
                      <p className="color-muted font-15 mb-4 fw-500">
                        Add your bank account information in order for you to receive the funds from your sales
                      </p>
                      <div className="">
                        <button
                          type="submit"
                          className="btn clickbtn text-uppercase fw-800"
                          onClick={handleCreateAccount}>
                          Link your bank account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}
export default PricingConfirmation;