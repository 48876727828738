import React from 'react';
import {backgroundStyle} from '../../utils/staticStrings';
import {CardTheme} from '../../modules/Athlete/components/style';
import {useHistory} from 'react-router-dom';

const SubScriptionBanner = (props) => {
  const {stripeStatus} = props;
  const history = useHistory();

  let membeShipExpired =
    stripeStatus?.subscription_valid === false &&
    stripeStatus?.subscription_activated_on !== null;

  return (
    <CardTheme
      bgUrl={backgroundStyle.themeBg}
      bgUrlMob={backgroundStyle.themeBgMob}>
      <div className="ct-left">
        <div className="ct-title">
          {membeShipExpired
            ? 'Reactivate your membership'
            : 'Start your free trial'}
        </div>
        <div className="ct-content">
          Your account is deactivated and your Shop and profile are not visible
          to the public. Reactivate your membership today to publish your Shop
          and start landing deals.
        </div>
      </div>
      <div className="ct-btn-contain">
        <button
          className={`btn btn-primary ms-auto ct-button ${
            membeShipExpired ? 'text-nowrap' : ''
          }`}
          onClick={() => {
            history.push('/athlete/price-details');
          }}
          type="primary">
          {membeShipExpired ? 'REACTIVATE MEMBERSHIP' : 'Start Trial'}
        </button>
      </div>
    </CardTheme>
  );
};
export default SubScriptionBanner;
