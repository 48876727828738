import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getSubscriptions} from '../../../../store/athleteflow/action';
import http from '../../../../helpers/http';
import {toastMessage} from '../../../../helpers/toastMessage';
import {currencyFormat} from '../../../../helpers/user';
import {CheckOutlined} from '@ant-design/icons';

const ChangeBillingPeriod = (props) => {
  const {
    setBillingModal,
    updated,
    setIsLoading,
    nodDaysLeft,
    planType,
    setMemberReload,
    subscriptionPlan,
    upComingPlan,
  } = props;
  const [yearPriceId, setYearPriceId] = useState('');
  const [monthPriceId, setMonthPriceId] = useState('');
  const [activeId, setActiveId] = useState(planType);
  const [plan, setPlan] = useState(planType);
  const dispatch = useDispatch();
  const {subscriptions} = useSelector((state) => state.AthleteModuleReducer);

  const [dataTabs] = useState([
    {
      id: 'year',
      name: `${
        subscriptionPlan?.items?.data[0]?.plan?.interval === 'month'
          ? 'Switch to'
          : 'Keep'
      }  Annual`,
      title: 'annual',
    },
    {
      id: 'month',
      name: `${
        subscriptionPlan?.items?.data[0]?.plan?.interval === 'month'
          ? 'Keep'
          : 'Switch to'
      }  Monthly`,
      title: 'monthly',
    },
    {
      id: 'cancel',
      name: 'Cancel membership',
    },
  ]);

  useEffect(() => {
    if (Object.keys(subscriptions).length === 0) {
      dispatch(getSubscriptions());
    }
  }, [dispatch, subscriptions]);

  useEffect(() => {
    if (
      subscriptions !== undefined &&
      Object.values(subscriptions).length > 0
    ) {
      const price1 = subscriptions?.prices[0];
      const price2 = subscriptions?.prices[1];
      if (price1?.recurring?.interval === 'year') {
        setYearPriceId(price1.id);
      } else if (price1?.recurring?.interval === 'month') {
        setMonthPriceId(price1.id);
      }
      if (price2?.recurring?.interval === 'year') {
        setYearPriceId(price2.id);
      } else if (price2?.recurring?.interval === 'month') {
        setMonthPriceId(price2.id);
      }
    }
  }, [subscriptions]);

  // useEffect(() => {
  //   if (subscription?.data?.subscription?.plan?.interval === 'year') {
  //     const startDate = moment.unix(
  //       subscription?.data?.subscription?.current_period_start,
  //     );
  //     const now = moment();
  //     if (startDate.diff(now, 'months') > 11) {
  //       setAllowEdit(true);
  //     } else {
  //       setAllowEdit(false);
  //     }
  //   }
  // }, [setAllowEdit, subscription]);

  // console.log(subscriptionPlan, 'subscriptionPlan');
  const changePlan = async () => {
    setIsLoading(false);
    try {
      let priceId = '';
      let updatePlan = '';
      if (plan === 'year') {
        priceId = yearPriceId;
        updatePlan = 'yearly';
      } else if (plan === 'month') {
        priceId = monthPriceId;
        updatePlan = 'monthly';
      }
      const payload = {
        price_id: priceId,
      };
      const response = await http.put('/stripe/subscription', payload);
      if (response?.data?.success === true) {
        const msg = `Plan changed to ${updatePlan} successfully`;
        toastMessage(msg);
        updated();
        setBillingModal(false);
      }
    } catch (err) {
      console.log(err);
      toastMessage(err?.response?.data);
      setBillingModal(false);
    }
  };

  const cancelMemberShip = async () => {
    setIsLoading(false);

    try {
      const response = await http.delete('/stripe/subscription');
      if (response?.data?.success === true) {
        const msg = `Membership successfully cancelled beginning next billing period`;
        toastMessage(msg);
        setBillingModal(false);
        setMemberReload(true);
        updated();
      }
    } catch (err) {
      toastMessage(err.response?.data?.message);
      setBillingModal(false);
      updated();
    }
  };

  return (
    <>
      <p className="fw-bold  text-grey font-m mb-4">
        Any changes to your membership will take effect at the beginning of your
        next billing cycle. You still have days&nbsp;
        {nodDaysLeft > 0 ? nodDaysLeft : 0} left to use your AthleteUp
        membership benefits until the next billing cycle.
      </p>
      <h6 className="fw-bold mb-3">Current Plan</h6>
      <div className="row overview mb-4">
        <div className="col col-md-4 mb-3 px-2">
          <div className="list-card custom-list list-membership active">
            <div className="bg-white">
              <div className="card-bg">
                <div className="py-2">
                  <div className="text-capitalize">
                    {subscriptionPlan?.items?.data[0]?.plan?.interval ===
                    'month'
                      ? 'Monthly'
                      : 'Annual'}
                  </div>
                  <div>
                    membership (
                    {currencyFormat(
                      subscriptionPlan?.items?.data[0]?.price?.unit_amount *
                        0.01,
                    )}
                    /{subscriptionPlan?.items?.data[0]?.plan?.interval})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h6 className="fw-bold mb-3">Next billing cycle</h6>
      <div className="overview row">
        {Object.keys(subscriptions).length > 0 && (
          <>
            {dataTabs.map((data) => {
              return (
                <div className="col col-md-4 mb-3 px-2" key={data.id}>
                  <div
                    name="category_id"
                    className={`list-card custom-list list-membership  ${
                      activeId === data.id ? 'active' : ' '
                    }`}
                    onClick={() => {
                      setActiveId(data.id);
                      setPlan(data.id);
                    }}>
                    <div className="bg-white">
                      <div
                        className={`card-bg ${
                          activeId === data.id ? '' : 'opaque-7'
                        }`}>
                        <div className="py-2">
                          <div>{data.name}</div>
                          {data.title === 'annual' ? (
                            <div>
                              membership (
                              {currencyFormat(
                                subscriptions?.prices[0]?.unit_amount * 0.01,
                              )}
                              /year)
                            </div>
                          ) : data.title === 'monthly' ? (
                            <div>
                              membership (
                              {currencyFormat(
                                subscriptions?.prices[1]?.unit_amount * 0.01,
                              )}
                              /month)
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {activeId === 'cancel' && (
        <div className=" fw-bold">
          <p className="pt-3 color-muted">
            If you cancel your membership your account will be deactivated upon
            the conclusion of your current billing cycle. You can continue to
            use your AthleteUp membership benefits until then.
          </p>
          <p className="text-danger">Please note:</p>
          <ul className="m-0 ps-3 text-danger mb-4">
            <li>
              If you cancel your membership, your profile/shop page (including
              your listings) will no longer be publicly discoverable on the
              website, and you will no longer have access to the Campaign
              Discovery Board.
            </li>
            <li>
              It is recommended that you first withdraw any funds in your
              AthleteUp balance and complete any in-progress orders or deals
              before closing your AthleteUp account.
            </li>
            <li>
              After closing your account, you will still be able to login and
              access certain information such as historical orders, deals,
              earnings, and messages. However, after 12 months of an account
              being closed, this historical information will be deleted and no
              longer accessible. We therefore recommend reviewing this
              information before you close your account, so that you can save
              any files and information that you might need in the future.
            </li>
          </ul>
        </div>
      )}
      <div className="col">
        {activeId === 'cancel' ? (
          <>
            {planType === 'cancel' ? (
              <button className="btn btn-primary disabled align-items-center d-flex justify-content-center">
                SAVED
                <CheckOutlined className="ms-2" />
              </button>
            ) : (
              <button className="btn btn-primary" onClick={cancelMemberShip}>
                CANCEL MEMBERSHIP
              </button>
            )}
          </>
        ) : (
          <>
            {activeId === upComingPlan ? (
              <button className="btn btn-primary disabled align-items-center d-flex justify-content-center">
                SAVED
                <CheckOutlined className="ms-2" />
              </button>
            ) : (
              <button className="btn btn-primary" onClick={changePlan}>
                SAVE
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ChangeBillingPeriod;
