import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Header from './common/header/header';
import Footer from './common/footer/footer';
// import 'antd/dist/antd.css';
import './assets/scss/style.scss';
import { ConfigProvider } from "antd";

import modules from './modules';
import WebFont from 'webfontloader';
import ScrollToTop from './ScrollToTop';

WebFont.load({
  google: {
    families: ['Manrope:300,400,500,600,700,800', 'sans-serif'],
  },
});

function App() {
  return (
    <Router>
      <ScrollToTop>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Manrope",
              colorInfoText: "#7741fe",
              colorInfoBg: "#7741fe0f",
              colorInfoBorder: "#7741fe",
            }
          }}
        >
          <div className="App">
            <Header />
            {modules.map((module) => (
              <Route {...module.routeProps} key={module.name} />
            ))}
            <Footer />
          </div>
        </ConfigProvider>
      </ScrollToTop>
    </Router>
  );
}

export default App;
