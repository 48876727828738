import React, {useEffect, useMemo, useCallback, useState, useRef} from 'react';
import {Formik} from 'formik';
import {Input, Radio, Alert, Popover, } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import EditGallery from './EditGallery';
import ShippingBox from './ShippingBox';
import DigitalProductUi from './DigitalProductUi';
import * as yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import useApi from '../../hooks/useApi';
import { createListing, editListDetails } from '../../store/athleteflow/action';
import http from '../../helpers/http';
import {
  errorToastMessage,
  toastMessage,
} from '../../helpers/toastMessage';
import Text from '../../components/Text/Text';

const RequirementTooltip = () => {
  return (<div style={{ maxWidth: 300 }}>
    <Text size="16">
      You have the option of adding an additional checkout field to request more information from your customers that you
      may need to fulfill the order. Leave blank if no additional request from customers is needed.
    </Text>
  </div>);
};

const {TextArea} = Input;
const ListingForm = (props) => {
  const {
    listing,
    onDelete = null,
    // setUrlValid,
    stateFromPostalCode,
    getStateList,
    template = false
  } = props;
  const dispatch = useDispatch();
  const [mediaList, setMediaList] = useState([]);
  const [embedCode, setEmbedCode] = useState(null);

  let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

  let schema = yup.object().shape({
    price: yup
      .number()
      .test(
        'is-decimal',
        'The amount should be a decimal with maximum two digits after comma',
        (val) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        },
      )
      .min(0.5)
      .max(999999999)
      // .positive('Must be more than 0')
      .required('Price is required'),
  });
  const inputRef = useRef(null);
  const plainOptions = [
    {label: 'Unlimited quantity ', value: false},
    {label: 'Limited quantity available', value: true},
  ];
  const [uspsBoxType] = useApi(`/athlete/listing/usps-box-types`);
  const [countries] = useApi('/assets/countries');

  // const {
  //   shopListing,
  //   stripeStatus,
  //   isLoading,
  //   editLstStatus,
  //   message,
  //   membeShipExpired,
  // } = useSelector((state) => state.AthleteModuleReducer);
  const {stateList} = useSelector((state) => state.BrandModuleReducer);

  const product = useMemo(() => {
    if(!listing) return null;

    switch(listing.type){
    case 'Experiences': return listing.listing_experience;
    default: return listing.listing_product; // get real type here in case we have more in the future.
    }
  }, [listing]);

  const listingType = useMemo(() => {
    if(!listing) return null;

    switch(listing.type){
    case 'Experiences': return 'experience';
    default: return 'product'; // get real type here in case we have more in the future.
    }
  }, [listing]);

  const handleEmbedCode = (code) => setEmbedCode(code);

  const updateListing = useCallback((data) => {
    let payloadData = data;

    if (embedCode) {
      payloadData = {...data, media: [...data.media, embedCode]};
    }
   
    dispatch(
      editListDetails({
        payload: payloadData,
        type: listingType,
        id: listing.id,
      }),
    );
  }, [dispatch, listing, listingType, embedCode]);

  useEffect(() => {
    if(!listing) return;

    // load attachments as they may change and need to be stored before a save occurs.
    let result = listing?.attachments.map((attachment) => {
      return attachment.type === 'image'
        ? attachment.url
        : () => {}
    });
  
    const mediaArray = result.filter((element) => {
      return element !== undefined;
    });

    setMediaList(mediaArray);
  }, [listing]);
  

  const initialValues = {
    title: product?.title,
    price: '',
    description: product?.description,
    purchaser_requirements: product?.purchaser_requirements,
    category_id: product?.category_id,
    subcategory_id: product?.subcategory_id,

    type: listing?.type,
    is_limited: product?.is_limited,
    ...(product?.is_limited && {
      limited_quantity: product?.limited_quantity,
    }),

    ...(((product?.subcategory_id === 1 &&
      product?.category_id === 1) ||
      (product?.subcategory_id === 2 &&
        product?.category_id === 1)) && {
      is_coaching: product?.is_coaching,
    }),

    ...(product?.category_id === 4 && {
      package_type: product?.shipping_detail?.package_type,
      weight: '',
      weight_units: product?.shipping_detail?.weight_units,
      is_shipaddress_needed: product?.is_shipaddress_needed,
      from_address: {
        zip: '',
        city: '',
        street1: '',
        street2: '',
        state: '',
        country: 'US',
      },

      dimensions: {
        length: product?.shipping_detail?.dimensions?.length
          ? product?.shipping_detail?.dimensions?.length
          : '',
        width: product?.shipping_detail?.dimensions?.width
          ? product?.shipping_detail?.dimensions?.width
          : '',
        height: product?.shipping_detail?.dimensions?.height
          ? product?.shipping_detail?.dimensions?.height
          : '',
      },

      usps_box_type: product?.shipping_detail?.usps_box_type
        ? product?.shipping_detail?.usps_box_type
        : '',
      // ...(product?.shipping_detail?.package_type ===
      //   'USPS Flat Rate Packaging' && {
      //   usps_box_type: product?.shipping_detail?.usps_box_type,
      // }),
      // ...(product?.shipping_detail?.package_type !==
      //   'USPS Flat Rate Packaging' && {
      //   dimensions: product?.shipping_detail?.dimensions,
      // }),
    }),
    ...(product?.category_id === 5 && {
      message: product?.message,
      digital_product_urls: product?.digital_product_urls,
    }),
  };

  const handleDelete = useCallback((id) => {
    http
      .delete(`/athlete/listing/${id}/`)
      .then((res) => {
        toastMessage(res.data.data);
       
        onDelete(id);
      })
      .catch((err) => {
        // message.error(err.response.data.detail);
        errorToastMessage(err.response.data.message);
      });
  }, [onDelete]);

  const handleDeleteMedia = useCallback(async (media) => {
    // If this is a template, need to splice the media array to not include this media id.
    const newMediaList = mediaList.filter((m) => m !== media);

    // Pre-api call to show speed
    await setMediaList(newMediaList);

    // If this is a templated listing, don't actually delete the resource from the template.
    if(template) return;

    // If not template, delete off the listing.
  }, [setMediaList, template, mediaList]);

  const handleMediaChange = useCallback((media) => setMediaList(media), [setMediaList]);

  const handleSubmit = useCallback(async (values, setSubmitting) => {
    // template should fire off a create request.
    if(template){
      let insert = { ...values, id: undefined, media: mediaList };

      if (embedCode) {
        insert.media = [...insert.media, embedCode];
      }

      dispatch(createListing(insert));

      return;
    }

    let editListPayload;
    if (values.purchaser_requirements === '') {
      editListPayload = {...values, purchaser_requirements: null};
    } else {
      editListPayload = values;
    }

    editListPayload.media = mediaList;
    if (embedCode) {
      editListPayload.media = [...editListPayload.media, embedCode];
    }

    updateListing(editListPayload);
  }, [dispatch, updateListing, mediaList, template, embedCode]);

  if(!product || !listing) return null;

  return (
    <div className="container-fluid" style={{ paddingRight: 3, paddingLeft: 3 }}>
      <Formik
        enableReinitialize
        initialValues={{...initialValues}}
        validationSchema={schema}
        onSubmit={handleSubmit}>
        {({
          values,
          onChange,

          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit} className="p-0 px-md-4">
              <h2 className="font-l fw-800 mb-20">Overview</h2>
              <div className="form-group mb-4">
                <span className="form-label d-block">
                  Title of the Experience
                </span>
                <TextArea
                  type="text"
                  name="title"
                  maxLength={80}
                  showCount
                  className="form-control input-lg custom-text-count"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  status={((!values.title) && template) || errors.title ? 'error' : ''}
                />
              </div>
              <div className="form-group mb-4">
                <span className="form-label d-block">Category</span>
                <input
                  type="text"
                  className="form-control input-lg"
                  value={product?.category?.name}
                  disabled
                />
              </div>
              <div className="form-group mb-5">
                <span className="form-label d-block">Subcategory</span>
                <input
                  type="text"
                  className="form-control input-lg"
                  value={product?.subcategory?.name}
                  disabled
                />
              </div>

              {((product?.subcategory_id === 1 &&
                product?.category_id === 1) ||
                (product?.subcategory_id === 2 &&
                  product?.category_id === 1)) && (
                <>
                  <h6 className="title">
                    Tag this Live video call as related to coaching/lessons ?
                  </h6>
                  <div className="mb-4 overview-card d-inline-flex align-items-start">
                    <div
                      name="category_id"
                      onClick={() => {
                        setFieldValue('is_coaching', true);
                      }}
                      className={`list-card custom-list me-2 ${
                        values.is_coaching === true ? 'active' : ''
                      }`}
                      style={{
                        width: '120px',
                      }}>
                      <div className="bg-white ">
                        <div className="card-bg color-muted opaque-7 d-flex justify-content-center">
                          Yes
                        </div>
                      </div>
                    </div>
                    <div
                      name="category_id"
                      onClick={() => {
                        setFieldValue('is_coaching', false);
                      }}
                      className={`list-card custom-list ${
                        values.is_coaching === false ? 'active' : ''
                      }`}
                      style={{
                        width: '120px',
                      }}>
                      <div className="bg-white">
                        <div className="card-bg color-muted opaque-7 d-flex justify-content-center">
                          No
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <h2 className="font-l fw-bold mt-5 mb-20 fw-800">Price</h2>

              <div className="form-group mb-5 input-custom-var2 text-left">
                {/* <span className="form-label d-block">Price</span> */}
                <Input
                  prefix="$"
                  type="number"
                  name="price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-lg text-left"
                  value={values.price}
                  status={(errors.price && touched.price) || ((+values.price <= 0) && template) ? 'error' : ''}
                  suffix={<span style={{ color: 'red' }}>*</span>}
                />

                <div className="text-danger text-sm mt-3 fw-bold">
                  {errors.price && touched.price && errors.price}
                </div>

                {values?.category_id === 4 && (
                  <ShippingBox
                    setFieldValue={setFieldValue}
                    shipping_detail={product?.shipping_detail}
                    from_address={listing?.from_address}
                    uspsBoxType={uspsBoxType}
                    onChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    countries={countries}
                    stateFromPostalCode={stateFromPostalCode}
                    getStateList={getStateList}
                    stateList={stateList}
                    template={template}
                  />
                )}
              </div>
              <h2 className="font-l fw-bold mb-20 fw-800">Description</h2>
              <div className="form-group mb-3">
                {/* <span className="form-label d-block">Description</span> */}
                <TextArea
                  rows="10"
                  maxLength={2000}
                  showCount={false}
                  name="description"
                  className="form-control input-lg custom-text-count"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  status={((!values.description) && template) || errors.description ? 'error' : ''}
                />

                <div className="d-lg-flex antd-radio-inline align-items-center custom-radio-inline mt-3">
                  <Radio.Group
                    options={plainOptions}
                    value={values.is_limited}
                    defaultValue="limit"
                    name="is_limited"
                    onChange={(e) => {
                      setFieldValue('is_limited', e.target.value);
                    }}
                  />
                  {values.is_limited && (
                    <>
                      <Input
                        addonBefore="Qty"
                        placeholder="0"
                        type="number"
                        ref={inputRef}
                        onKeyDown={(evt) =>
                          evt.key === 'e' && evt.preventDefault()
                        }
                        onWheel={(e) => {
                          if (
                            inputRef &&
                            inputRef.current &&
                            inputRef.current.blur
                          ) {
                            inputRef.current.blur();
                          }
                          // e.preventDefault();
                        }}
                        min={0}
                        value={values.limited_quantity}
                        name="limited_quantity"
                        onChange={handleChange}
                        className="input-custom-var2 ms-lg-auto ms-4"
                      />
                    </>
                  )}
                </div>
                {product?.digital_product_urls && (<>
                  <DigitalProductUi
                    setFieldValue={setFieldValue}
                    digital_product_urls={product?.digital_product_urls}
                    message={values?.message}
                  />

                  {/* Todo(BRYCE) - add error message for front end validation */}
                </>)}
              </div>
              <h2 className="font-l fw-bold mt-5 mb-20 fw-800">Requirements</h2>
              <div className="form-group mb-5">
                <span className="form-label d-block">
                  What would you like to request from customers?{' '}
                  <Popover content={RequirementTooltip} title="Additional Fields" trigger="hover">
                    <InfoCircleOutlined color="#0000ff" />
                  </Popover>
                </span>
                <TextArea
                  rows="5"
                  name="purchaser_requirements"
                  maxLength={300}
                  className="form-control input-lg"
                  value={values.purchaser_requirements}
                  onBlur={handleBlur}
                  status={((!values.purchaser_requirements) && template) || errors.purchaser_requirements ? 'error' : ''}
                  onChange={(e) => {
                    setFieldValue('purchaser_requirements', e.target.value);
                  }}
                />
              </div>
              <h2 className="font-l fw-800 mt-5 mb-2">Gallery</h2>

              <div className=" mx-auto">
                {((template) && (<div style={{ margin: '0px 0px' }}>
                  <Alert
                    showIcon={false}
                    
                    description={<span><span className="font-bolt" style={{fontWeight: 800, color: "#1677ff"}}>TIP </span>It's not required, but we recommend replacing this generic photo with a custom photo of yourself or the product.</span>}
                    type="info"
                  />
                </div>))}

                <EditGallery
                  media={mediaList}
                  onChange={handleMediaChange}
                  onDelete={handleDeleteMedia}
                  setFieldValue={setFieldValue}
                  onEmbedCode={handleEmbedCode}
                  embedCode={embedCode}
                />
                
              </div>

              <div className="bottom-pos-btn-container mt-5">
                {/* check button enable physical product for zip code  */}
                {values?.category_id === 4 ? (
                  <button
                    type="submit"
                    className={`btn btn-primary ${
                      (embedCode !== undefined || mediaList.length > 0) &&
                      stateFromPostalCode !== ''
                        ? ''
                        : 'disabled'
                    }`}>
                    Save
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={`btn btn-primary ${
                      embedCode !== undefined || mediaList.length > 0
                        ? ''
                        : 'disabled'
                    }`}>
                    Save To Shop
                  </button>
                )}
                { ((!template && listing?.id) && (<div
                  type="submit"
                  className="text-center w-100 btn mt-4 fw-800 font-18 color-red"
                  onClick={handleDelete}>
                  DELETE LISTING
                </div>))}
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ListingForm;
