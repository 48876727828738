import React, {useState, useEffect} from 'react';
import {Checkbox, Divider, Radio} from 'antd';
import {backgroundStyle, purchaserSection} from '../../../utils/staticStrings';
import {useDispatch, useSelector} from 'react-redux';
import useApi from '../../../hooks/useApi';
import {
  fetchAthleteProfile,
  fetchNewlyJoineAthletes,
} from '../../../store/users/actions';
import {setHeader} from '../../../store/athleteflow/action';
import {
  NewlyJoinedCards,
  FilterCheckbox,
  Filters,
  FilterRadioSort,
  CustomSearchOutlined,
} from './style';
import {DeskTopLayoutView, MobileLayoutViewView} from '../pages/style';
import AthleteCardLayout from './AthleteCardLayout';
import Loader from 'react-loader-spinner';
import {CheckboxDropDown} from '../../Purchaser/components/CheckBoxDropDown';
import useDeviceSize from '../../../hooks/useDeviceSize';
import {RadioDropdown} from '../../Purchaser/components/RadioDropdown';

const Home = () => {
  const [sportsData] = useApi('/athlete/sports?sport_count_order=true');
  const [seachResult, setSerachresult] = useState('');
  const [athleteTypeOptions, setAthleteTypeOptions] = useState([]);
  const [listingTypeOptions, setListingTypeOptions] = useState([]);
  const [sportsActiveId, setSportsActiveId] = useState('all');
  const [sportsName, setSportsActiveName] = useState('All');
  const [showNewlyJoinedCard, setNewlyJoinedCards] = useState(true);
  const [recommendedOption, setRecommendedOption] = useState(true);
  const [sortOptions, setSortOptions] = useState([]);
  const [athleteFilters] = useApi('/athlete/athlete-filters');
  const [athleteTypes] = useApi('/athlete/athlete-types');
  // const [newlyJoinedCardList] = useApi(
  //   `/athlete?is_newly_joined=true&page=1&pageSize=100${
  //     sportsActiveId !== 'all' ? `&sport_ids=${sportsActiveId}` : ''
  //   }`,
  // );
  const size = useDeviceSize();
  const dispatch = useDispatch();
  const [pageValue, setPagevalue] = useState({
    pageSize: 12,
    page: 1,
    search: '',
    sport_ids: [],
    sort: 'recommended',
    athlete_type: [],
    listing_type: [],
  });
  const {athletsProfile, athletesCount, isLoading, newlyJoinedCardList} =
    useSelector((state) => state.UsersReducer);

  useEffect(() => {
    if (athleteFilters?.data?.sort?.length > 0) {
      let ops = [];
      athleteFilters?.data?.sort.forEach((ele) => {
        ops.push({
          value: ele,
          heading: ele,
        });
      });
      setSortOptions(ops);
    }
    if (
      athleteFilters?.data?.listing_type &&
      Object.keys(athleteFilters?.data?.listing_type).length > 0
    ) {
      let ops = [];
      const filters = athleteFilters?.data?.listing_type;
      Object.keys(filters).forEach((ListHeader) => {
        let ListTypeVal = ListHeader;
        if (ListHeader.includes('%')) {
          /* eslint-disable-next-line */
          ListTypeVal = ListHeader.replace('%26', '&');
        }
        ops.push({
          value: ListHeader,
          heading: ListTypeVal,
        });
      });
      setListingTypeOptions(ops);
    }
  }, [athleteFilters]);

  useEffect(() => {
    if (athleteTypes?.data?.length > 0) {
      let athleteOptions = [];
      athleteTypes.data.forEach((athType) => {
        let athTypeVal = athType;
        if (athType.includes('&')) {
          /* eslint-disable-next-line */
          athTypeVal = athType.replace('&', '%26');
        }
        let athOption = {
          value: athTypeVal,
          heading: athType,
        };
        athleteOptions.push(athOption);
      });
      setAthleteTypeOptions(athleteOptions);
    }
  }, [athleteTypes]);

  useEffect(() => {
    document.querySelector('body').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    dispatch(setHeader(true));
  }, [dispatch]);

  useEffect(() => {
    if (size.width < 778) {
      setNewlyJoinedCards(true);
    }
  }, [size.width]);

  const selectedAthleteFilter = (checkedValues) => {
    if (checkedValues.length > 0) {
      setNewlyJoinedCards(true);
    }
    setPagevalue({...pageValue, page: 1, athlete_type: checkedValues});
  };

  const selectedListType = (checkedValues) => {
    if (checkedValues.length > 0) {
      setNewlyJoinedCards(true);
    }
    setPagevalue({...pageValue, page: 1, listing_type: checkedValues});
  };

  const selectedSortRadio = (e) => {
    let selectedOption = e.target.value;
    if (selectedOption === 'recommended') {
      setNewlyJoinedCards(true);
      setRecommendedOption(true);
    } else {
      setNewlyJoinedCards(false);
      setRecommendedOption(false);
    }
    setPagevalue({...pageValue, page: 1, sort: selectedOption});
  };

  const handleSearch = (e) => {
    setSerachresult(e.target.value);
    setPagevalue({...pageValue, page: 1, search: e.target.value});
  };

  const loadMore = () => {
    if (athletesCount > pageValue.pageSize)
      setPagevalue((prev) => {
        return {
          ...pageValue,
          page: prev.page + 1,
        };
      });
  };

  const setSportsId = (sportsId) => {
    setSportsActiveId(sportsId);
    setNewlyJoinedCards(true);
    if (sportsId !== 'all') {
      setPagevalue({...pageValue, sport_ids: [sportsId]});
    } else {
      setPagevalue({...pageValue, sport_ids: 'none'});
    }
  };

  const selectedSort = (selectedValue) => {
    if (selectedValue === 'recommended') {
      setNewlyJoinedCards(true);
      setRecommendedOption(true);
    } else {
      setNewlyJoinedCards(false);
      setRecommendedOption(false);
    }
    if (selectedValue) {
      setPagevalue({...pageValue, page: 1, sort: selectedValue});
    } else {
      setPagevalue({pageSize: 12, page: 1});
    }
  };

  // console.log(athletsProfile, 'athletsProfile');

  useEffect(() => {
    dispatch(fetchAthleteProfile({...pageValue, newlyJoioned: false}));
  }, [pageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showNewlyJoinedCard) {
      dispatch(
        fetchNewlyJoineAthletes({
          ...pageValue,
          pageSize: 14,
          newlyJoioned: true,
          sort: '',
        }),
      );
    }
  }, [showNewlyJoinedCard, pageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const menuItems = () => {
    return (
      <>
        <div
          className="sportTextContainer"
          key="all"
          onClick={() => {
            setSportsId('all');
            setSportsActiveName('All');
          }}>
          <div
            className={`sportText ${sportsActiveId === 'all' ? 'active' : ''}`}>
            <div>All Sports</div>
          </div>
        </div>

        {sportsData?.data?.rows?.map((ele) => {
          return (
            <div
              className="sportTextContainer"
              key={ele.id}
              onClick={() => {
                setSportsId(ele.id);
                setSportsActiveName(ele.name);
              }}>
              <div
                className={`sportText ${
                  sportsActiveId === ele.id ? 'active' : ''
                }`}>
                <div>{ele.name}</div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const FilterCheckBoxOptions = ({data}) => {
    return data.map((dataItem, i) => {
      return (
        <div className="flex my-1" key={i}>
          <FilterCheckbox value={dataItem?.value}>
            <span className="titlecase">{dataItem?.heading}</span>
          </FilterCheckbox>
        </div>
      );
    });
  };

  const FilterRadioOptions = ({data}) => {
    return data.map((dataItem, i) => {
      return (
        <div className="flex my-1" key={'Radio' + i}>
          <FilterRadioSort value={dataItem?.value}>
            {dataItem?.heading}
          </FilterRadioSort>
        </div>
      );
    });
  };
  return (
    <>
      <div className="purchaseContainer discover-athletes-margin">
        <div className="cover">
          <div className="coverImg"></div>
          <div className="container">
            <div className="coverText ">
              <div className="coverTitle">{purchaserSection.title}</div>
              <div className="coverSub">{purchaserSection.subtext}</div>
            </div>
          </div>
        </div>

        <div className="content position-relative theme-bubble-bg">
          <div className="container px-lg-0 px-3 purchaser-container">
            <div className="searchContainer mt-4 mt-md-0">
              <input
                value={seachResult}
                className="search"
                placeholder="E.g., Name, school, team, etc"
                onChange={(e) => handleSearch(e)}
              />
              <CustomSearchOutlined style={{fontSize: '20px'}} />
              {size.width < '772' && (
                <div className="d-flex ms-2 sort-checkbox">
                  <RadioDropdown
                    defaultValue="recommended"
                    dropdownOptions={sortOptions}
                    optionSelected={selectedSort}
                  />
                </div>
              )}
            </div>
            <MobileLayoutViewView>
              <div className="filterSort mb-3">
                <div className="filter">
                  <div className="filterIcon">
                    <img src={backgroundStyle.filterIcon} alt="filter-Icon" />
                  </div>
                </div>
                <CheckboxDropDown
                  defaultValue="Athlete Type"
                  marginRight="mr-2"
                  dropdownOptions={athleteTypeOptions}
                  optionSelected={selectedAthleteFilter}
                />
                <CheckboxDropDown
                  defaultValue="Listing type"
                  marginRight="mr-0"
                  dropdownOptions={listingTypeOptions}
                  optionSelected={selectedListType}
                />
              </div>
            </MobileLayoutViewView>
            <div className="d-flex discover-athlete-container">
              <DeskTopLayoutView>
                <div className="d-flex flex-column pe-3 me-2">
                  <h6 className="mb-3 fw-bold">Sort</h6>
                  <Radio.Group
                    onChange={(e) => selectedSortRadio(e)}
                    defaultValue="recommended">
                    <Filters>{FilterRadioOptions({data: sortOptions})}</Filters>
                  </Radio.Group>
                  <Divider className="my-2" />
                  <h6 className="mt-2 fw-bold">Athlete Type</h6>
                  <Checkbox.Group onChange={selectedAthleteFilter}>
                    <Filters>
                      {FilterCheckBoxOptions({data: athleteTypeOptions})}
                    </Filters>
                  </Checkbox.Group>
                  <Divider className="my-2" />
                  <h6 className="mt-2 fw-bold">Listing Type</h6>
                  <Checkbox.Group onChange={selectedListType}>
                    <Filters>
                      {FilterCheckBoxOptions({data: listingTypeOptions})}
                    </Filters>
                  </Checkbox.Group>
                </div>
              </DeskTopLayoutView>
              <div className="products d-flex justify-content-start mt-0">
                <div className="w-100 d-md-block">
                  <h2 className="fw-600">Sports</h2>
                  <div className="sportBarContainer scroll-custom d-flex">
                    {menuItems()}
                  </div>
                </div>

                <div className=" d-md-block">
                  {showNewlyJoinedCard &&
                    recommendedOption &&
                    seachResult.length === 0 && (
                      <>
                        {newlyJoinedCardList.length > 4 && (
                          <div className="mb-3 mb-md-5">
                            <h2 className="mt-3 mt-md-0 fw-600">
                              Newly Joined - {sportsName}
                              {sportsName === 'All' ? ' Sports' : ''}
                            </h2>
                            <NewlyJoinedCards className="scroll-custom row flex-nowrap pb-2 newly-joined-section">
                              <AthleteCardLayout
                                athletsProfile={newlyJoinedCardList}
                              />
                            </NewlyJoinedCards>
                          </div>
                        )}
                      </>
                    )}
                </div>
                {seachResult.length > 0 ? (
                  <h2 className="mt-4 mt-md-0 fw-600">
                    Results for "{seachResult}"
                  </h2>
                ) : (
                  <h2 className="mt-4 mt-md-0 fw-600">
                    All - {sportsName}
                    {sportsName === 'All' ? ' Sports' : ''}
                  </h2>
                )}
                <div className="row productList">
                  <AthleteCardLayout athletsProfile={athletsProfile} />
                </div>
                {athletesCount > pageValue.page * pageValue.pageSize && (
                  <div className="row center">
                    <div className="col">
                      <button
                        type="button"
                        className="btn clickbtnblue d-flex view-more mx-auto align-items-center text-center  justify-content-center"
                        onClick={loadMore}>
                        {isLoading && (
                          <Loader
                            type="Bars"
                            color="#00BFFF"
                            width={20}
                            className="me-3"
                          />
                        )}
                        view more
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <SemiCircleHome /> */}
          </div>
          {/* <div className="semicicle-bg d-none d-md-block">
            <img alt="bubble" src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTU5IiBoZWlnaHQ9IjczNSIgdmlld0JveD0iMCAwIDU1OSA3MzUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuMyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00NzIuNDU1IDBDMjYzLjM0MiAzLjUzNTQzIDczLjI1NjggMTQ0LjMxNiAxNi40MzI2IDM1Ni4zODlDNS45OTYwOSAzOTUuMzM4IDAuNjU1MjczIDQzNC40NTIgMCA0NzMuMDIzVjQ4OS4yMjVDMS40OTcwNyA1NzcuMzg3IDI3LjM4OTYgNjYyLjE2NSA3Mi43MzM0IDczNUg1NTlWNi40MTQ3QzUzNS40MzUgMi41MDIzOCA1MTEuOTEgMC4zOTQyNTcgNDg4LjU4NSAwSDQ3Mi40NTVaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfMTUyMzRfNzM5MCkiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl8xNTIzNF83MzkwIiB4MT0iMzc0LjkyNCIgeTE9IjI4LjMzMTUiIHgyPSIxMjIuMzIiIHkyPSI2NTkuNTcyIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiNEQkU2RUEiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSJ3aGl0ZSIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=' />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Home;
