import React, {useEffect, useCallback} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import '../pages/pages.scss';
import {setHeader, twiliotoken} from '../../../store/athleteflow/action';
import AppLayout from '../../../common/AppLayout';
import {BackArrow} from '../../Athlete/components/Icons';
import {BackSection} from './style';
import OrderDetailsCard from '../../../common/components/OrderDetailsCard';
import http from '../../../helpers/http';
import {toastMessage} from '../../../helpers/toastMessage';
import {useState} from 'react';
import moment from 'moment';
import {CloseOutlined} from '@ant-design/icons';
import {CreateCampaign} from '../../Purchaser/components/features/Campaigns/style';
import DisputeDeal from '../components/features/Deals/DisputeDeal';
// import {getNewSidebarCount} from '../../../store/users/actions';
const Chat = require('twilio-chat');

const PurchaseDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {orderId} = useParams();
  const [orderDetails, setOrderDetail] = useState({});
  const [messagesList, setMessage] = useState([]);
  const [channelPrivate, setChanel] = useState(null);
  const [participantsDetails, setParticipantDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [tokenTwilio, setToken] = useState('');
  const [orderCompleteStatus, setOrderComplete] = useState(false);
  const [disputeModalVisible, setDisputeModalVisible] = useState(false);
  const {purchaserDetails} = useSelector((state) => state.UsersReducer);

  const getOrderDetail = async () => {
    try {
      let url = `/purchaser/order/${orderId}`;
      const res = await http.get(url);
      setOrderDetail(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const markReadOrder = useCallback(
    async (data) => {
      try {
        let url = `/purchaser/order/${orderId}/mark-read`;
        await http.put(url);
      } catch (err) {
        console.log(err);
      }
    },
    [orderId],
  );

  useEffect(() => {
    markReadOrder(orderId);
  }, [orderId, markReadOrder]);

  useEffect(() => {
    let initiateOrder = true;
    if (
      (history.location.pathname === `/purchaser/dashboard/order/${orderId}` ||
        history.location.pathname === `/purchaser/deals/details`) &&
      initiateOrder
    ) {
      dispatch(setHeader(false));
      getOrderDetail();
    }

    return () => {
      initiateOrder = false;
    };
  }, [history.location.pathname, dispatch, orderId]); // eslint-disable-line react-hooks/exhaustive-deps

  const disputeOrder = async () => {
    try {
      let url = `/purchaser/order/${orderId}/dispute`;
      await http.put(url);
      setDisputeModalVisible(false);
      toastMessage('Order disputed');
      getOrderDetail();
    } catch (err) {
      console.log(err);
    }
  };
  const sendMessageAction = (text) => {
    if (text && String(text).trim()) {
      channelPrivate && channelPrivate.sendMessage(text);
    }
  };

  const getToken = async () => {
    const response = await http.get(
      `/twilio/token?username=user${purchaserDetails?.id}`,
    );
    return response.data.data.token;
  };

  const createChannelFn = async () => {
    let token = '';
    setLoader(true);
    try {
      token = await getToken();
      setToken(token);
      dispatch(twiliotoken(token));
    } catch {
      throw new Error('unable to get token, please reload this page');
    }

    const client = await Chat.Client.create(token);

    client.on('tokenAboutToExpire', async () => {
      console.log('token epired');
      const token = await getToken();
      client.updateToken(token);
    });

    client.on('tokenExpired', async () => {
      const token = await getToken();
      client.updateToken(token);
    });

    try {
      const channel = await client.getChannelBySid(
        orderDetails?.data?.channel_id,
      );
      await http.put(
        `twilio/conversation/${orderDetails?.data?.channel_id}/mark-read`,
      );
      if (participantsDetails.length === 0) {
        let particpatantChannel = await http.get(
          `/twilio/conversation/${orderDetails?.data?.channel_id}/participants`,
        );
        setParticipantDetails(particpatantChannel?.data?.data);
      }

      await joinChannel(channel);

      setChanel(channel);
      getMessages(channel);
      channel.on('messageAdded', function (message) {
        getMessages(channel);
        // setMessage([...messagesFromChannel?.items, message]);
      });
      setLoader(false);
    } catch {
      throw new Error('unable to create channel, please reload this page');
    }
  };

  const joinChannel = async (channel) => {
    if (channel.channelState.status !== 'joined') {
      await channel.join();
    }
  };
  const getMessages = async (channel) => {
    const messages = await channel.getMessages();
    setMessage(messages.items);
  };

  const startVideoCall = () => {
    if (tokenTwilio === '') {
      return;
    }

    channelPrivate.sendMessage(
      'in inviting you to join a video call happening now.',
      `/purchaser/video-call/${orderDetails?.data?.channel_id}`,
    );

    window.open(`/purchaser/video-call/${orderDetails?.data?.channel_id}`);
  };

  useEffect(() => {
    let initiateToken = true;
    if (
      initiateToken &&
      purchaserDetails?.id !== undefined &&
      orderDetails?.data?.channel_id !== undefined
    ) {
      createChannelFn(); // run it, run it
    }
    return () => {
      initiateToken = false;
    };
    /* eslint-disable-next-line */
  }, [purchaserDetails, orderDetails]);

  const purchaserOrderComplete = async () => {
    try {
      let url = `/purchaser/order/${orderId}/complete`;
      await http.put(url);
      toastMessage('Order completed');
      getOrderDetail();
    } catch (err) {
      toastMessage(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (
      orderDetails?.data?.athlete_marked_complete &&
      !orderDetails?.data?.is_closed
    ) {
      let bannerShowTillDate = moment(orderDetails?.data?.completed_on).add(
        4,
        'days',
      );

      if (
        !moment().isBetween(
          moment(orderDetails?.data?.completed_on),
          bannerShowTillDate,
        )
      ) {
        setOrderComplete(true);
      }
    } else {
      setOrderComplete(false);
    }
  }, [orderDetails?.data]);

  return (
    <AppLayout contentClassname="applayout-mobile-view">
      <div className="bubble-bg"></div>
      <BackSection
        mrLeft="18"
        onClick={() => {
          history.push('/purchaser/dashboard');
        }}>
        <BackArrow className="ms-3" />
        <span className="ms-2">Back</span>
      </BackSection>
      {orderDetails?.data !== undefined &&
        orderDetails?.data?.channel_id !== null && (
          <OrderDetailsCard
            orderDetails={orderDetails?.data}
            type="purchaser"
            orderDispute={setDisputeModalVisible}
            purchaserOrderComplete={purchaserOrderComplete}
            sendMessageAction={sendMessageAction}
            conversationProxy={orderDetails?.data?.channel_id}
            messages={messagesList}
            loader={loader}
            participantsDetails={participantsDetails}
            channelProxy={channelPrivate}
            enableAttachMent={false}
            startVideoCall={startVideoCall}
            orderCompleteStatus={orderCompleteStatus}
            messageStatus={
              orderDetails?.data?.status === 'declined' ||
              orderDetails?.data?.is_closed ||
              orderDetails?.data?.purchaser_marked_complete
            }
          />
        )}

      {disputeModalVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          visible={disputeModalVisible}
          onCancel={() => {
            setDisputeModalVisible(false);
          }}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span style={{textTransform: 'capitalize'}}>
                Mark order #{orderDetails?.data?.id} as disputed
              </span>
            </div>
          }
          footer={null}>
          <div className="pb-3">
            <DisputeDeal markDisputed={disputeOrder} orderType="order" />
          </div>
        </CreateCampaign>
      )}
    </AppLayout>
  );
};

export default PurchaseDetails;
