import React, {useEffect, useState} from 'react';
import {Select, Tabs} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Text from '../../../../../components/Text/Text';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import ProfileImage from '../../ProfileImage';
import {setHeader} from '../../../../../store/athleteflow/action';
import AppLayout from '../../../../../common/AppLayout';
import {CreateCampaign} from './style';
import {AthleteCard} from '../../../pages/style';
import CampaignModalContainer from './CampaignModalContainer';
import Hr from '../../../../../components/Hr/index';
import {BackArrow} from '../../../../Athlete/components/Icons';
import DealsTag from '../Deals/DealsTag';
import moment from 'moment';
import Orders from '../Dashboard/Orders';
import {
  getCampaignData,
  handleActivateOrPauseCampaign,
  handleEditCampaignStatus,
} from '../../../../../store/brandflow/action';
import {
  CustomTabs,
  LayoutDesktopView,
  LayoutMobileView,
  DealsContainer,
  MediaFlex,
  Deliverables,
  OfferDetails,
  CardDetails,
} from '../../style';
import {
  CustomEditButtonContainer,
  SendInviteButton,
  CriteriaDetail,
} from './style';
import CampaignStats from './CampaignStats';
import {BackSection} from '../../style';
import {
  CheckoutButton,
  DeskTopLayoutView,
  MobileLayoutViewView,
  MobileDivContainer,
} from '../../../pages/style';
import {useLocation} from 'react-router-dom';
import {Person} from '../../../helpers/icons';
import {toastMessage} from '../../../../../helpers/toastMessage';
import Loader from 'react-loader-spinner';
import ArchiveCampaign from './ArchiveCampaign';
import useApi from '../../../../../hooks/useApi';
import {TableWithoutPagination} from '../Dashboard/TableWithoutPagination';
import {formatPriceCommas} from '../../../../../helpers/user';

const {TabPane} = Tabs;

const CampaignsDetails = () => {
  const {Option} = Select;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [createdDate, setCreatedDate] = useState('');
  const [selectClassname, setSelectClassname] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [states] = useApi('/athlete/states');
  const [collegeDetail] = useApi('/athlete/colleges');
  const [offerDeals, setOfferDeals] = useState([]);
  const [progressDeals, setProgressDeals] = useState([]);
  const [completedDeals, setCompletedDeals] = useState([]);
  const [declinedDeals, setDeclinedDeals] = useState([]);
  const {campaignData, editCampaignStatus, isLoading, campaignStatusChanged} =
    useSelector((state) => state.BrandModuleReducer);

  function callback(key) {
    // console.log(key);
  }

  useEffect(() => {
    dispatch(getCampaignData({id: location?.state?.id}));
  }, [location, dispatch]);

  useEffect(() => {
    setStatus(campaignData.status);
    if (campaignData.status === 'active') {
      setSelectClassname('dropdown-success');
    } else if (campaignData.status === 'inactive') {
      setSelectClassname('dropdown-danger');
    }
  }, [campaignData?.status]);

  useEffect(() => {
    let createdAtDate = moment(campaignData?.createdAt).format('MMM DD, YYYY');
    setCreatedDate(createdAtDate);
    let updatedAtDate = moment(campaignData?.updatedAt).format('MMM DD, YYYY');
    setUpdatedDate(updatedAtDate);
  }, [
    campaignData?.createdAt,
    campaignData?.updatedAt,
    campaignData?.expiration_period,
  ]);

  useEffect(() => {
    if (history.location.pathname === '/purchaser/campaigns/details') {
      dispatch(setHeader(false));
    }
  }, [history.location.pathname, dispatch]);

  useEffect(() => {
    if (editCampaignStatus === true) {
      toastMessage('Campaign edited successfully');
      dispatch(handleEditCampaignStatus(false));
      dispatch(getCampaignData({id: location?.state?.id}));
    }
  }, [editCampaignStatus, dispatch, location]);

  useEffect(() => {
    if (campaignStatusChanged === true) {
      toastMessage('Campaign status changed');
      dispatch(handleActivateOrPauseCampaign(false));
      dispatch(getCampaignData({id: location?.state?.id}));
    }
  }, [campaignStatusChanged, dispatch, location?.state?.id]);

  useEffect(() => {
    if (campaignData && campaignData?.deals) {
      const deals = campaignData?.deals;
      let dealsOffer = [];
      let dealsProgress = [];
      let dealsCompleted = [];
      let dealsDeclined = [];
      deals.forEach((deal) => {
        if (deal.status === 'application/offer') {
          dealsOffer.push(deal);
        } else if (deal.status === 'in progress') {
          dealsProgress.push(deal);
        } else if (deal.status === 'completed') {
          dealsCompleted.push(deal);
        } else if (deal.status === 'declined') {
          dealsDeclined.push(deal);
        }
      });
      setOfferDeals(dealsOffer);
      setProgressDeals(dealsProgress);
      setCompletedDeals(dealsCompleted);
      setDeclinedDeals(dealsDeclined);
    }
  }, [campaignData]);

  const inviteStatusType = (value) => {
    if (value === 'completed') {
      return 'status-success ';
    } else if (value === 'in progress') {
      return 'status-progress';
    } else if (value === 'declined') {
      return 'cancel';
    } else if (value === 'application/offer') {
      return 'offer';
    } else if (value === 'Awaiting response') {
      return 'awaiting';
    }
  };

  const moveToArchive = () => {
    setIsArchiveModalVisible(false);
    const payload = {
      apiPayload: {
        status: 'archived',
      },
      id: campaignData.id,
    };
    dispatch(handleActivateOrPauseCampaign(payload));
  };

  const handleOpenArchive = () => {
    setIsArchiveModalVisible(true);
  };

  const handleCancelArchive = () => {
    setIsArchiveModalVisible(false);
  };

  const handleChange = (value) => {
    if (value === 'archived') {
      handleOpenArchive();
    } else {
      setStatus(value);
      const payload = {
        apiPayload: {
          status: value,
        },
        id: campaignData.id,
      };
      if (value === 'active') {
        setSelectClassname('dropdown-success');
      } else if (value === 'inactive') {
        setSelectClassname('dropdown-danger');
      }
      dispatch(handleActivateOrPauseCampaign(payload));
    }
  };

  const deliverOn = (deliverable) => {
    const fulFillType = deliverable.fulfillment_type;
    if (fulFillType === 'Before Date') {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD, YYYY',
      );
      return `Deliver before: ${fulFillDate}`;
    } else if (fulFillType === 'TBD') {
      return 'TBD';
    } else if (fulFillType === 'Specific Date') {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD, YYYY',
      );
      return `Deliver on: ${fulFillDate}`;
    } else if (fulFillType === 'Within X Days after product received') {
      return `Within ${deliverable.fulfillment_days} days after product received`;
    }
  };

  const statusType = (value) => {
    if (value === 'completed') {
      return 'status-success ';
    } else if (value === 'in progress') {
      return 'status-progress';
    } else if (value === 'declined') {
      return 'cancel';
    } else if (value === 'application/offer') {
      return 'offer';
    } else if (value === 'disputed') {
      return 'disputed';
    }
  };

  const showLocations = (locations) => {
    if (locations.length === 0) {
      return 'Anywhere located';
    } else {
      let locationList = [];
      if (states && states?.data && states?.data.length > 0) {
        for (let i = 0; i < states.data.length; i++) {
          for (let j = 0; j < locations.length; j++) {
            if (locations[j] === states.data[i].key) {
              locationList.push(states.data[i].name + ', ');
            }
          }
          if (locations.length === locationList.length) {
            let lastName = locationList[locationList.length - 1];
            locationList[locationList.length - 1] = lastName.replace(',', '');
            break;
          }
        }
        return locationList;
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showColleges = (colleges) => {
    let collegesList = '';
    if (
      collegeDetail &&
      collegeDetail?.data &&
      collegeDetail?.data?.rows &&
      collegeDetail?.data?.rows.length > 0
    ) {
      for (let i = 0; i < collegeDetail?.data?.rows.length; i++) {
        for (let j = 0; j < colleges.length; j++) {
          if (colleges[j] === collegeDetail?.data?.rows[i].id) {
            collegesList =
              collegesList === ''
                ? collegeDetail?.data?.rows[i]?.name
                : collegesList + ', ' + collegeDetail?.data?.rows[i]?.name;
          }
        }
      }
      return collegesList;
    }
  };

  const getSports = (campaignData) => {
    let allowedSports = campaignData.allowed_sports;
    let sportsList = '';
    allowedSports.forEach((sportObj, idx) => {
      if (idx !== allowedSports.length - 1) {
        sportsList = sportsList + ' ' + sportObj?.name + ' ,';
      } else sportsList = sportsList + ' ' + sportObj.name;
    });
    return sportsList;
  };

  const showSocialReach = (social_reach) => {
    return (
      <>
        {social_reach?.total && social_reach?.total.length > 0 && (
          <>
            <span className="me-2 titlecase">
              Total SM reach: &nbsp;
              {social_reach?.total.map((val, idx) => {
                if (idx !== social_reach.total.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
        {social_reach?.instagram && social_reach?.instagram.length > 0 && (
          <>
            <span className="me-2 titlecase">
              Instagram reach: &nbsp;
              {social_reach?.instagram.map((val, idx) => {
                if (idx !== social_reach.instagram.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
        {social_reach?.twitter && social_reach?.twitter.length > 0 && (
          <>
            <span className="me-2 titlecase">
              Twitter reach: &nbsp;
              {social_reach?.twitter.map((val, idx) => {
                if (idx !== social_reach.twitter.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
        {social_reach?.tiktok && social_reach?.tiktok.length > 0 && (
          <>
            <span className="me-2 titlecase">
              Tiktok reach: &nbsp;
              {social_reach?.tiktok.map((val, idx) => {
                if (idx !== social_reach.tiktok.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
        {social_reach?.youtube && social_reach?.youtube.length > 0 && (
          <>
            <span className="me-2 titlecase">
              Youtube reach: &nbsp;
              {social_reach?.youtube.map((val, idx) => {
                if (idx !== social_reach.youtube.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
        {social_reach?.facebook && social_reach?.facebook.length > 0 && (
          <>
            <span className="me-2 titlecase">
              Facebook reach: &nbsp;
              {social_reach?.facebook.map((val, idx) => {
                if (idx !== social_reach.facebook.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
      </>
    );
  };

  const checkForSMReachData = (social_reach) => {
    let val = false;
    if (Object.keys(social_reach).length > 0) {
      for (let key in social_reach) {
        if (social_reach[key].length > 0) {
          return true;
        }
      }
    }
    return val;
  };

  const showCriteria = (campaignData) => {
    if (campaignData?.type === 'private') {
      return 'Private Campaign - only personally invited Athletes are allowed to apply';
    } else if (campaignData?.type === 'open') {
      if (
        campaignData?.criteria &&
        Object.keys(campaignData?.criteria).length > 0
      ) {
        let criteria = campaignData.criteria;
        return (
          <>
            <span>
              <span className="me-2 titlecase">
                {criteria?.athlete_type
                  ? `${criteria.athlete_type} athletes`
                  : 'Any athlete type'}
              </span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">
                {criteria?.sports &&
                campaignData?.allowed_sports &&
                campaignData?.allowed_sports?.length > 0
                  ? getSports(campaignData)
                  : 'Any sports'}
              </span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">
                {criteria?.gender ? `${criteria.gender}` : 'Any gender'}
              </span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">
                {criteria?.locations
                  ? showLocations(criteria?.locations)
                  : 'Anywhere located'}
              </span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">
                {campaignData?.allowed_colleges
                  ? showColleges(campaignData?.allowed_colleges)
                  : 'Any university'}
              </span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">
                {criteria?.social_reach &&
                checkForSMReachData(criteria?.social_reach)
                  ? showSocialReach(criteria?.social_reach)
                  : 'Any SM reach'}
              </span>
              {!criteria?.social_reach && <span className="me-2">|</span>}
            </span>
          </>
        );
      } else {
        return (
          <>
            <span>
              <span className="me-2 titlecase">Any athlete type</span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">Any sports</span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">Any gender</span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">Anywhere located</span>
              <span className="me-2">|</span>
              <span className="me-2 titlecase">Any SM reach</span>
              <span className="me-2">|</span>
            </span>
          </>
        );
      }
    }
  };

  const goToAthletes = () => {
    history.push('/purchaser/athletes');
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const dealColumns = [
    {
      title: 'Deal ID',
      dataIndex: 'id',
      key: 'id',
      // width: "120px",
      render: (text) => (
        <span className="fw-700 color-dark font-15">#{text}</span>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <span>{moment(text).format('MMM DD,YYYY')}</span>,
    },
    {
      title: 'Athlete',
      dataIndex: 'athlete',
      key: 'athlete',
      render: (text) => (
        <div className="d-flex align-items-center">
          <ProfileImage
            width={32}
            imgWidth={32}
            desktopImgWidth={32}
            imgContainerClassName="purchaser radius-m"
            height={32}
            src={text?.image_url}
          />
          <span className="text-start">{text?.name}</span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        return (
          <span className={`${statusType(text)} text-capitalize`}>{text}</span>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (text) => {
        return <span>{`$${text}`}</span>;
      },
    },
  ];

  const showDeals = (dealsSelected) => {
    if (dealsSelected.length === 0) {
      return (
        <h3 className="my-5 py-5 text-center fw-bold">No deals to display</h3>
      );
    } else
      return (
        <TableWithoutPagination
          item="deal"
          columns={dealColumns}
          rows={dealsSelected}
          loading={isLoading}
        />
      );
  };

  const inviteColumns = [
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <span>{moment(text).format('MMM DD,YYYY')}</span>,
    },
    {
      title: 'Athlete',
      dataIndex: 'athlete',
      key: 'athlete',
      render: (text) => (
        <div className="d-flex align-items-center">
          <ProfileImage
            width={32}
            imgWidth={32}
            desktopImgWidth={32}
            imgContainerClassName="purchaser radius-m"
            height={32}
            src={text?.image_url}
          />
          <span className="text-start">{text?.name}</span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <span
          className={` ${inviteStatusType(text)} ${
            text === 'active' ? '' : 'text-capitalize'
          }  ms-3`}>
          {text === 'active'
            ? 'In progress'
            : text === 'declined'
            ? 'Canceled'
            : text}
        </span>
      ),
    },
  ];

  const showInvites = () => {
    if (campaignData?.invites) {
      if (campaignData?.invites.length === 0) {
        return (
          <h3 className="my-5 py-5 text-center fw-bold">No invites sent</h3>
        );
      } else
        return (
          <TableWithoutPagination
            item="invite"
            columns={inviteColumns}
            rows={campaignData?.invites}
            loading={isLoading}
          />
        );
    }
  };

  const renderDealsMobile = (deals) => {
    if (isLoading) {
      return (
        <div className="loader-contain">
          <div className="loader">
            <Loader type="Bars" color="#00BFFF" height={50} width={50} />
          </div>
        </div>
      );
    } else {
      if (deals && deals.length > 0) {
        return <Orders item="campaign-deals" rows={deals} />;
      } else if (deals.length === 0) {
        return (
          <div className="fw-700 d-flex font-18 justify-content-center my-4 pt-5">
            No deals to display
          </div>
        );
      }
    }
  };

  const renderInvitesMobile = () => {
    if (isLoading) {
      return (
        <div className="loader-contain">
          <div className="loader">
            <Loader type="Bars" color="#00BFFF" height={50} width={50} />
          </div>
        </div>
      );
    } else {
      if (campaignData && campaignData?.invites) {
        if (campaignData?.invites.length > 0) {
          return <Orders item="invites" rows={campaignData?.invites} />;
        } else if (campaignData?.invites.length === 0) {
          return (
            <div className="fw-700 d-flex font-18 justify-content-center my-4 ">
              No invites sent
            </div>
          );
        }
      }
    }
  };

  return (
    <AppLayout contentClassname="applayout-mobile-view">
      {isModalVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '18px',
                color: '#042237',
                padding: '20px 0',
              }}>
              Edit Campaign
            </div>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}>
          <CampaignModalContainer
            closeModal={handleCancel}
            modalView={true}
            mode="edit"
            campaignData={campaignData}
          />
        </CreateCampaign>
      )}
      {isArchiveModalVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span style={{textTransform: 'capitalize'}}>
                Move campaign #{campaignData?.id} to archive
              </span>
            </div>
          }
          visible={isArchiveModalVisible}
          onCancel={handleCancelArchive}
          footer={null}>
          <ArchiveCampaign moveToArchive={moveToArchive} />
        </CreateCampaign>
      )}
      <div className="bubble-bg"></div>
      <BackSection
        mrLeft="18"
        onClick={() => {
          history.goBack();
        }}>
        <BackArrow />
        <span className="ms-2">Back</span>
      </BackSection>
      {isLoading && (
        <div className="loader">
          <Loader type="Bars" color="#00BFFF" height={50} width={50} />
        </div>
      )}
      {!isLoading && (
        <>
          <MobileLayoutViewView>
            <MobileDivContainer>
              <div className="d-flex justify-content-between  align-items-center">
                <div className="d-flex align-items-center">
                  <Text
                    display="flex"
                    weight="700"
                    size="36"
                    smSize="30"
                    smLineHeight="40"
                    lineHeight="55"
                    textColor="#042237">
                    Campaign #{campaignData?.id}
                  </Text>
                </div>

                <div className="card-button-group d-xl-flex align-items-center d-none">
                  <CustomEditButtonContainer>
                    {campaignData?.status !== 'archived' && (
                      <>
                        <Select
                          defaultValue={campaignData.status}
                          onChange={handleChange}
                          value={status}
                          className={`me-2 d-flex ${selectClassname}`}>
                          <Option value="active">Active</Option>
                          <Option value="inactive">Paused</Option>
                          <Option value="archived">Move to Archive</Option>
                        </Select>
                        <button
                          className="active-dropdown-button-outline-desktop d-flex text-center"
                          onClick={showModal}>
                          <i className="icon-edit me-2 d-flex"></i> Edit
                        </button>
                      </>
                    )}
                    {campaignData?.status === 'archived' && (
                      <div className="me-2 archived-label">Archived</div>
                    )}
                  </CustomEditButtonContainer>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <ProfileImage
                  mobileImgWidth={32}
                  width={32}
                  imgWidth={32}
                  imgContainerClassName="purchaser"
                  height={32}
                  src={campaignData?.image_url}
                  borderRadius={'7px'}
                />
                {campaignData?.type === 'private' && (
                  <img
                    width="12"
                    height="12"
                    className="me-1 align-items-center"
                    src={backgroundStyle?.lock}
                    alt="Private "
                  />
                )}
                {campaignData?.type === 'open' && (
                  <div className="ms-1">
                    <Person />
                  </div>
                )}
                <Text
                  display="flex"
                  weight="400"
                  textCase="capitalize"
                  size="14"
                  mrLeft="5"
                  textColor="#435969">
                  {`${campaignData?.type} campaign`}
                  <span className="ms-3 me-3">|</span>
                  <span>{createdDate}</span>
                </Text>
              </div>
              <div className="card-button-group d-xl-none  justify-content-between mb-3">
                <CustomEditButtonContainer>
                  {campaignData?.status !== 'archived' && (
                    <>
                      <Select
                        value={status}
                        defaultValue={campaignData.status}
                        onChange={handleChange}
                        className={`me-2 ${selectClassname} mobile-select`}>
                        <Option value="active">Active</Option>
                        <Option value="inactive">Paused</Option>
                        <Option value="archived">Move to Archive</Option>
                      </Select>
                      <button
                        className="active-dropdown-button-outline mobile-edit"
                        onClick={() => {
                          history.push(`/purchaser/campaigns/edit`, {
                            campaignData: campaignData,
                          });
                        }}>
                        <i className="icon-edit"></i>
                      </button>
                    </>
                  )}
                  {campaignData?.status === 'archived' && (
                    <div className="me-2 archived-label">Archived</div>
                  )}
                </CustomEditButtonContainer>
              </div>
              <DealsContainer>
                <MediaFlex>
                  <Text
                    display="flex"
                    weight="700"
                    size="20"
                    lineHeight="27"
                    textColor="#042237">
                    {campaignData?.title}
                  </Text>
                  <div className="d-flex align-items-center">
                    <Text
                      display="flex"
                      weight="500"
                      size="16"
                      className="my-2 me-2"
                      lineHeight="27"
                      textColor="#042237">
                      Max Budget
                    </Text>
                    <Text
                      display="flex"
                      weight="800"
                      size="20"
                      className="mt-2 mb-2"
                      lineHeight="27"
                      textColor="#042237">
                      ${' '}
                      {campaignData?.total_price &&
                        formatPriceCommas(campaignData?.total_price)}
                    </Text>
                  </div>
                </MediaFlex>
                <Hr mrBottom="20px" mrTop="20px" />
                <div className="d-flex flex-column">
                  <Text
                    display="flex"
                    weight="700"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    Description
                  </Text>
                  <Text
                    display="flex"
                    weight="400"
                    size="16"
                    lineHeight="26"
                    className="white-pre-wrap word-break"
                    textColor="#042237">
                    {campaignData?.description}
                  </Text>
                  <Hr mrBottom="20px" mrTop="20px" />
                </div>
                <Deliverables>
                  <Text
                    display="flex"
                    weight="700"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    Deliverables
                  </Text>
                  {campaignData?.deliverables &&
                    campaignData?.deliverables.map((deliverable, idx) => (
                      <MediaFlex key={idx}>
                        <DealsTag
                          src={backgroundStyle?.mark}
                          text={deliverable?.type}
                        />
                        <DealsTag
                          src={backgroundStyle?.clock}
                          text={deliverOn(deliverable)}
                        />
                        <DealsTag
                          src={backgroundStyle?.roundDollar}
                          text={`Cash ($${formatPriceCommas(
                            deliverable?.compensation_cash,
                          )}) 
                          + Products ($${formatPriceCommas(
                            deliverable?.compensation_product,
                          )})`}
                        />
                      </MediaFlex>
                    ))}
                </Deliverables>
                <Hr mrBottom="20px" mrTop="20px" />
                <OfferDetails>
                  <CardDetails>
                    <Text
                      display="flex"
                      weight="400"
                      size="15"
                      mrRight="20"
                      lineHeight="26"
                      textColor="#435969">
                      Last update: {updatedDate}
                    </Text>
                  </CardDetails>
                </OfferDetails>
              </DealsContainer>
              <CriteriaDetail>
                <div className="d-flex align-items-center">
                  <Text
                    weight="500"
                    size="13"
                    lineHeight="18"
                    textColor="#435969">
                    <span className="fw-bold me-2">Required Criteria :</span>
                    {showCriteria(campaignData)}
                  </Text>
                </div>
              </CriteriaDetail>
              <Text
                display="flex"
                weight="400"
                size="15"
                lineHeight="26"
                textColor="#435969"></Text>
            </MobileDivContainer>
          </MobileLayoutViewView>
          <DeskTopLayoutView>
            <AthleteCard>
              <div className="d-flex justify-content-between  align-items-center">
                <div className="d-flex align-items-center">
                  <Text
                    display="flex"
                    weight="700"
                    size="36"
                    lineHeight="55"
                    textColor="#042237">
                    Campaign #{campaignData?.id}
                  </Text>
                </div>

                <div className="card-button-group d-xl-flex align-items-center d-none">
                  <CustomEditButtonContainer>
                    {campaignData?.status !== 'archived' && (
                      <>
                        <Select
                          value={status}
                          defaultValue={campaignData.status}
                          onChange={handleChange}
                          className={`me-2 d-flex ${selectClassname} desktop-select`}>
                          <Option value="active">Active</Option>
                          <Option value="inactive">Paused</Option>
                          <Option value="archived">Move to Archive</Option>
                        </Select>
                        <button
                          className="active-dropdown-button-outline-desktop d-flex text-center"
                          onClick={showModal}>
                          <i className="icon-edit me-2 d-flex"></i> Edit
                        </button>
                      </>
                    )}
                    {campaignData?.status === 'archived' && (
                      <div className="me-2 archived-label">Archived</div>
                    )}
                  </CustomEditButtonContainer>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <ProfileImage
                  width={32}
                  imgWidth={32}
                  imgContainerClassName="purchaser"
                  height={32}
                  src={campaignData?.image_url}
                />
                {campaignData?.type === 'private' && (
                  <img
                    width="12"
                    height="12"
                    className="me-1 align-items-center"
                    src={backgroundStyle?.lock}
                    alt="Private "
                  />
                )}
                {campaignData?.type === 'open' && (
                  <div className="ms-1">
                    <Person />
                  </div>
                )}
                <Text
                  display="flex"
                  textCase="capitalize"
                  weight="400"
                  size="14"
                  mrLeft="5"
                  textColor="#435969">
                  {`${campaignData?.type} campaign`}
                  <span className="ms-3 me-3">|</span>
                  <span>{createdDate}</span>
                </Text>
              </div>
              <div className="card-button-group d-xl-none  justify-content-between mb-3">
                <CustomEditButtonContainer>
                  {campaignData?.status !== 'archived' && (
                    <>
                      <Select
                        defaultValue={campaignData.status}
                        onChange={handleChange}
                        value={status}
                        className={`me-2 ${selectClassname}`}>
                        <Option value="active">Active</Option>
                        <Option value="inactive">Paused</Option>
                        <Option value="archived">Move to Archive</Option>
                      </Select>
                      <button
                        className="active-dropdown-button-outline "
                        onClick={showModal}>
                        <i className="icon-edit"></i>
                      </button>
                    </>
                  )}
                  {campaignData?.status === 'archived' && (
                    <div className="me-2 archived-label">Archived</div>
                  )}
                </CustomEditButtonContainer>
              </div>

              <DealsContainer>
                <MediaFlex style={{padding: '0px 30px'}}>
                  <Text
                    display="flex"
                    weight="700"
                    size="20"
                    lineHeight="27"
                    textColor="#042237">
                    {campaignData?.title}
                  </Text>
                  <div className="d-flex align-items-center">
                    <Text
                      display="flex"
                      weight="500"
                      size="18"
                      className="my-2 mx-2"
                      lineHeight="27"
                      textColor="#042237">
                      Max Budget
                    </Text>
                    <Text
                      display="flex"
                      weight="800"
                      size="20"
                      className="mt-2 mb-2"
                      lineHeight="27"
                      textColor="#042237">
                      ${' '}
                      {campaignData?.total_price &&
                        formatPriceCommas(campaignData?.total_price)}
                    </Text>
                  </div>
                </MediaFlex>
                <Hr mrBottom="20px" mrTop="20px" />
                <div
                  className="d-flex flex-column "
                  style={{padding: '0px 30px'}}>
                  <Text
                    display="flex"
                    weight="700"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    Description
                  </Text>
                  <Text
                    display="flex"
                    weight="400"
                    size="16"
                    lineHeight="26"
                    className="white-pre-wrap"
                    textColor="#042237">
                    {campaignData?.description}
                  </Text>
                </div>
                <Deliverables>
                  <Text
                    display="flex"
                    weight="700"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    Deliverables
                  </Text>
                  {campaignData?.deliverables &&
                    campaignData?.deliverables.map((deliverable, idx) => (
                      <MediaFlex key={idx}>
                        <DealsTag
                          src={backgroundStyle?.mark}
                          text={deliverable?.type}
                        />
                        <DealsTag
                          src={backgroundStyle?.clock}
                          text={deliverOn(deliverable)}
                        />
                        <DealsTag
                          src={backgroundStyle?.roundDollar}
                          text={`Cash ($${formatPriceCommas(
                            deliverable?.compensation_cash,
                          )}) 
                          + Products ($${formatPriceCommas(
                            deliverable?.compensation_product,
                          )})`}
                        />
                      </MediaFlex>
                    ))}
                </Deliverables>
                <Hr mrBottom="20px" mrTop="20px" />
                <OfferDetails>
                  <CardDetails>
                    <Text
                      display="flex"
                      weight="400"
                      size="15"
                      mrRight="20"
                      lineHeight="26"
                      textColor="#435969">
                      Last update: {updatedDate}
                    </Text>
                  </CardDetails>
                </OfferDetails>
              </DealsContainer>
              <CriteriaDetail>
                <Text
                  display="flex"
                  weight="700"
                  size="13"
                  lineHeight="18"
                  textColor="#435969">
                  Required Criteria:
                </Text>
                <Text
                  display="flex"
                  weight="400"
                  size="13"
                  mrLeft="20"
                  lineHeight="26"
                  textColor="#435969">
                  <Text
                    display="flex"
                    weight="500"
                    size="13"
                    mrLeft="10"
                    lineHeight="26"
                    textColor="#435969">
                    {showCriteria(campaignData)}
                  </Text>
                </Text>
              </CriteriaDetail>
            </AthleteCard>
          </DeskTopLayoutView>
          <div className="applayout-div ">
            <CampaignStats stats={campaignData?.stats} />
            <div className="d-lg-flex  d-md-none d-sm-none d-none justify-content-center">
              <SendInviteButton>
                <CheckoutButton onClick={goToAthletes}>
                  <img
                    src={backgroundStyle.sendMessage}
                    alt="send message"
                    width="18"
                    className="me-3"
                    height="16"
                  />
                  <Text
                    weight="800"
                    size="18"
                    lineHeight="23"
                    textColor="#FFFFFF"
                    cursor="pointer">
                    SEND INVITES OR OFFERS TO ATHLETES
                  </Text>
                </CheckoutButton>
              </SendInviteButton>
            </div>
            <div className="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none d-xxl-none  justify-content-center mb-3">
              <SendInviteButton>
                <CheckoutButton onClick={goToAthletes}>
                  <img
                    src={backgroundStyle.sendMessage}
                    alt="send message"
                    width="18"
                    className="me-3 mb-0"
                    height="16"
                  />
                  <Text
                    weight="800"
                    size="18"
                    lineHeight="23"
                    textColor="#FFFFFF">
                    SEND INVITES OR OFFERS
                  </Text>
                </CheckoutButton>
              </SendInviteButton>
            </div>

            <LayoutDesktopView>
              <CustomTabs defaultActiveKey="1" size="large" onChange={callback}>
                <TabPane
                  tab="INVITES SENT"
                  key="1"
                  style={{overflowY: 'scroll'}}>
                  {showInvites()}
                </TabPane>
                <TabPane
                  tab="APPLICATION/OFFERS"
                  key="2"
                  style={{overflowY: 'scroll'}}>
                  {showDeals(offerDeals)}
                </TabPane>
                <TabPane
                  tab="DEALS IN PROGRESS"
                  key="3"
                  style={{overflowY: 'scroll'}}>
                  {showDeals(progressDeals)}
                </TabPane>
                <TabPane
                  tab="COMPLETED DEALS"
                  key="4"
                  style={{overflowY: 'scroll'}}>
                  {showDeals(completedDeals)}
                </TabPane>
                <TabPane
                  tab="DECLINED DEALS"
                  key="5"
                  style={{overflowY: 'scroll'}}>
                  {showDeals(declinedDeals)}
                </TabPane>
              </CustomTabs>
            </LayoutDesktopView>
            <LayoutMobileView>
              <CustomTabs defaultActiveKey="1" size="large" onChange={callback}>
                <TabPane
                  tab="INVITES SENT"
                  key="1"
                  style={{overflowY: 'scroll'}}>
                  {renderInvitesMobile()}
                </TabPane>
                <TabPane
                  tab="APPLICATION/OFFERS"
                  key="2"
                  style={{overflowY: 'scroll'}}>
                  {renderDealsMobile(offerDeals)}
                </TabPane>
                <TabPane
                  tab="DEALS IN PROGRESS"
                  key="3"
                  style={{overflowY: 'scroll'}}>
                  {renderDealsMobile(progressDeals)}
                </TabPane>
                <TabPane
                  tab="COMPLETED DEALS"
                  key="4"
                  style={{overflowY: 'scroll'}}>
                  {renderDealsMobile(completedDeals)}
                </TabPane>
                <TabPane
                  tab="DECLINED DEALS"
                  key="5"
                  style={{overflowY: 'scroll'}}>
                  {renderDealsMobile(declinedDeals)}
                </TabPane>
              </CustomTabs>
            </LayoutMobileView>
          </div>
        </>
      )}
    </AppLayout>
  );
};

export default CampaignsDetails;
