import React, {useState, useEffect, useCallback} from 'react';
import {headerSection} from '../../utils/staticStrings';
import {Layout, Menu, Collapse} from 'antd';
import {
  Campaigns,
  Home,
  MyShop,
  Order,
  Deals,
  Messages,
  Earnings,
  Campaign,
  Athletes,
  Purchaser,
  MyProfileIcon,
} from './SidebarIcons';
import ProfileSettings from '../components/ProfileSettings';
import {useSelector, useDispatch} from 'react-redux';
import {CustomMenu, EligibleCam} from './styled';
import {getStripeStatus} from '../../store/athleteflow/action';
import {getNewSidebarCount} from '../../store/users/actions';
import {useInterval} from '../../hooks/intervalHook';
import useTabActive from '../../hooks/useTabActive';

const {Panel} = Collapse;

const MobileMenu = (props) => {
  const {Sider} = Layout;
  const [myPurchase, setMyPurchase] = useState(false);
  const [userId, setUserId] = useState('fan');
  const [current, setCurrent] = useState('dashboard');
  const [registered, setRegistered] = useState(false);
  const dispatch = useDispatch();
  const [tabActive] = useTabActive();
  const {stripeStatus, campaignsCount, membeShipExpired, disableMenu} =
    useSelector((state) => state.AthleteModuleReducer);
  const {newCount} = useSelector((state) => state.UsersReducer);
  const {Item} = Menu;
  const userRole = localStorage.getItem('userRole');

  useEffect(() => {
    setCurrent(userRole);
    setUserId(localStorage.getItem('id'));
  }, [userRole]);

  const callbackCount = useCallback(async () => {
    try {
      const userRole = localStorage.getItem('userRole');
      let type = userRole === 'athlete' ? 'athlete' : 'purchaser';
      dispatch(getNewSidebarCount(type));
    } catch (err) {
      // dispatch(hideModal());
      console.log(err, 'Fetch api error');
    }
  }, [dispatch]);

  useInterval(callbackCount, tabActive ? 40000 : null);

  useEffect(() => {
    callbackCount();
  }, [callbackCount]);
  useEffect(() => {
    if (current === 'brand' || current === 'fan') {
      setMyPurchase(true);
    }
    if (current === 'athlete') {
      setMyPurchase(false);
    }
  }, [current]);

  useEffect(() => {
    if (current === 'athlete') {
      dispatch(getStripeStatus());
    }
  }, [dispatch, current]);
  useEffect(() => {
    if (Object.keys(stripeStatus).length > 0) {
      if (
        stripeStatus?.subscription_valid === true &&
        stripeStatus?.stripe_account_valid === true
      ) {
        setRegistered(true);
      } else {
        setRegistered(false);
      }
    }
  }, [stripeStatus]);

  function callback(key) {
    console.log(key);
  }

  return (
    <Sider
      width={300}
      collapsedWidth="0"
      trigger={null}
      className={`sidebar-bg ${props.className} ${
        disableMenu && !membeShipExpired && current === 'athlete'
          ? 'disableMenu'
          : ''
      }`}>
      <div className="ant-layout-sider">
        <div className="d-flex align-items-center px-5 mt-5">
          <ProfileSettings myPurchase={myPurchase} />
        </div>
        <CustomMenu theme="light" mode="inline" selectedKeys={[current]}>
          {current === 'athlete' && (
            <Item
              key="dashboard"
              icon={<Home />}
              className={` ${membeShipExpired ? 'menu-disable' : ''}`}>
              <a href={`/${current}/dashboard`}>Home</a>
            </Item>
          )}
          {(current === 'brand' || current === 'fan') && (
            <Item key="dashboard" icon={<Purchaser />}>
              <a href={`/purchaser/dashboard`}>
                {current === 'brand' ? 'MY PURCHASES' : 'PURCHASES'}
                {newCount?.order_count > 0 && (
                  <span className="badge badge-purple ms-2">
                    {newCount?.order_count}
                  </span>
                )}
              </a>
            </Item>
          )}

          {current === 'athlete' && (
            <>
              <Collapse accordion activeKey="1" onChange={callback}>
                <Panel
                  showArrow={false}
                  header={
                    <div className="d-flex align-items-center">
                      <img
                        alt="headerlogo"
                        className="header-logo"
                        src={headerSection.headerShopLogo}
                      />
                    </div>
                  }
                  key="1">
                  <Item
                    key="view-profile"
                    icon={<MyProfileIcon />}
                    className={`${disableMenu ? 'menu-enable' : ''} ${
                      membeShipExpired ? 'menu-disable' : ''
                    }`}>
                    <a href={`/${current}/view-profile/${userId}`}>
                      VIEW PUBLIC PROFILE
                    </a>
                  </Item>
                  <Item
                    key="my-shop"
                    icon={<MyShop />}
                    className={`${disableMenu ? 'menu-enable' : ''}`}>
                    <a href={`/${current}/my-shop`}>My LISTINGS</a>
                  </Item>
                  <Item key="orders" icon={<Order />}>
                    <a href={`/${current}/orders`}>
                      MANAGE ORDERS
                      {newCount?.order_count > 0 && (
                        <span className="badge badge-purple ms-2">
                          {newCount?.order_count}
                        </span>
                      )}
                    </a>
                  </Item>
                </Panel>
              </Collapse>
              <Collapse activeKey="2" accordion onChange={callback}>
                <Panel
                  showArrow={false}
                  header={
                    <div className="d-flex align-items-center">
                      <img
                        alt="headerlogo"
                        className="header-logo"
                        src={headerSection.headerDealLogo}
                      />
                    </div>
                  }
                  key="2">
                  <Item
                    key="campaigns"
                    icon={<Campaigns />}
                    className={`${disableMenu ? 'menu-enable' : ''}`}>
                    <a href={`/${current}/campaigns`}>DISCOVER CAMPAIGNS</a>
                    {!registered && (
                      <EligibleCam>
                        {campaignsCount} Eligible Campaigns
                      </EligibleCam>
                    )}
                  </Item>
                  <Item key="/deals" icon={<Deals />}>
                    <a href={`/${current}/deals`}>
                      MANAGE DEALS
                      {newCount?.deal_count > 0 && (
                        <span className="badge badge-purple ms-2">
                          {newCount?.deal_count}
                        </span>
                      )}
                    </a>
                  </Item>
                </Panel>
              </Collapse>
            </>
          )}

          {(current === 'brand' || current === 'fan') && (
            <Item key="/deals" icon={<Deals />}>
              <a href={`/purchaser/deals`}>
                {current === 'brand' ? 'MANAGE DEALS' : 'DEALS'}{' '}
                {newCount?.deal_count > 0 && (
                  <span className="badge badge-purple ms-2">
                    {newCount?.deal_count}
                  </span>
                )}
              </a>
            </Item>
          )}

          {current === 'brand' && (
            <Item key="campaigns" icon={<Campaign />}>
              <a href={`/purchaser/campaigns`}>CAMPAIGNS</a>
            </Item>
          )}
          <Item key="messages" icon={<Messages />}>
            {current === 'athlete' && (
              <a href={`/athlete/messages`}>
                Messages
                {newCount?.new_message_flag && (
                  <span className="badge-purple-small ms-2"></span>
                )}
              </a>
            )}
            {(current === 'brand' || current === 'fan') && (
              <a href={`/purchaser/messages`}>
                Messages
                {newCount?.new_message_flag && (
                  <span className="badge-purple-small ms-2"></span>
                )}
              </a>
            )}
          </Item>

          {(current === 'brand' || current === 'fan') && (
            <Item key="athletes" icon={<Athletes />}>
              <a href={`/purchaser/athletes`}>ATHLETES</a>
            </Item>
          )}
          {current === 'athlete' && (
            <Item key="earnings" icon={<Earnings />}>
              <a href={`/athlete/earnings`}>Earnings</a>
            </Item>
          )}
        </CustomMenu>
      </div>
    </Sider>
  );
};

export default MobileMenu;
