import {notification} from 'antd';

export const errorToastMessage = (error) => {
  // console.log(error);

  notification.open({
    message: error,
    className: 'alert alert-danger ',
    duration: 4,
  });
};

export const toastMessage = (message, duration = 6) => {
  notification.open({
    message: message,
    className: 'alert alert-light ',
    duration: 4,
  });
};
