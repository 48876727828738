import React from 'react';
import {CampaignStatsCard} from './style';
import Text from '../../../../../components/Text/Text';
import {DeskTopLayoutView, MobileLayoutViewView} from '../../../pages/style';
import './styles.scss';

const CampaignStats = ({stats}) => {
  return (
    <>
      <div className="row stats-card mx-n2 mx-xl-n4 mt-4 mb-0">
        <div className="col-xl-3 col-6 px-2 px-xl-3">
          <CampaignStatsCard className="card-grid ">
            <div className="invite-heading">
              <Text
                display="flex"
                weight="600"
                size="16"
                lineHeight="22"
                smSize="16"
                smLineHeight="22"
                textColor="#435969">
                Invites sent
              </Text>
            </div>
            <div className="d-flex align-items-center">
              <Text
                display="flex"
                weight="700"
                size="33"
                lineHeight="64"
                mrRight="8"
                smSize="26"
                smLineHeight="64"
                textColor="#042237">
                {stats?.invites_sent}
              </Text>

              {/* <span className="badge bg-purple">
                {' '}
                {stats?.invites_sent} New
              </span> */}
              {/* {statsData.data.new_orders > 0 &&
                <span className="badge bg-purple">{statsData.data.new_orders} New</span>
              } */}
            </div>
          </CampaignStatsCard>
        </div>
        <div className="col-xl-3 col-6 px-2 px-xl-3">
          <CampaignStatsCard className="card-grid ">
            <DeskTopLayoutView>
              <Text
                display="flex"
                weight="600"
                size="16"
                lineHeight="22"
                smSize="16"
                smLineHeight="22"
                textColor="#435969">
                Application/offers
              </Text>
            </DeskTopLayoutView>
            <MobileLayoutViewView>
              <Text
                display="flex"
                weight="600"
                size="16"
                lineHeight="22"
                smSize="16"
                smLineHeight="17"
                textColor="#435969">
                Application/
              </Text>
              <Text
                display="flex"
                weight="600"
                size="16"
                lineHeight="22"
                smSize="16"
                smLineHeight="17"
                textColor="#435969">
                offers
              </Text>
            </MobileLayoutViewView>

            <div className="d-flex align-items-center">
              {/* {statsData?.data?.all_orders || cardOneHeadingData} */}
              <Text
                display="flex"
                weight="700"
                size="33"
                lineHeight="64"
                mrRight="8"
                smSize="26"
                smLineHeight="64"
                textColor="#042237">
                {stats?.applications}
              </Text>

              {/* {stats?.applications > 0 && (
                <span className="badge bg-purple">
                  {stats?.applications} New
                </span>
              )} */}
            </div>
          </CampaignStatsCard>
        </div>
        <div className="col-xl-3 col-6 px-2 px-xl-3">
          <CampaignStatsCard className="card-grid ">
            <Text
              display="flex"
              weight="600"
              size="16"
              lineHeight="22"
              smSize="16"
              smLineHeight="22"
              textColor="#435969">
              Accepted deals
            </Text>
            <div className="d-flex align-items-center">
              {/* {statsData?.data?.all_orders || cardOneHeadingData} */}
              <Text
                display="flex"
                weight="700"
                size="33"
                lineHeight="64"
                mrRight="8"
                smSize="26"
                smLineHeight="64"
                textColor="#042237">
                {stats?.reached}
              </Text>

              {/* {stats?.accepted && <span className="badge bg-purple">{stats?.accepted} New</span>} */}
              {/* {statsData.data.new_orders > 0 &&
                <span className="badge bg-purple">{statsData.data.new_orders} New</span>
              } */}
            </div>
          </CampaignStatsCard>
        </div>
        <div className="col-xl-3 col-6 px-2 px-xl-3">
          <CampaignStatsCard className="card-grid ">
            <Text
              display="flex"
              weight="600"
              size="16"
              lineHeight="22"
              smSize="16"
              smLineHeight="22"
              textColor="#435969">
              Declined deals
            </Text>
            <div className="d-flex align-items-center">
              {/* {statsData?.data?.all_orders || cardOneHeadingData} */}
              <Text
                display="flex"
                weight="700"
                size="33"
                lineHeight="64"
                mrRight="8"
                smSize="26"
                smLineHeight="64"
                textColor="#042237">
                {stats?.declined}
              </Text>

              {/* <span className="badge bg-purple">{stats?.declined} New</span> */}
              {/* {statsData.data.new_orders > 0 &&
                <span className="badge bg-purple">{statsData.data.new_orders} New</span>
              } */}
            </div>
          </CampaignStatsCard>
        </div>
      </div>
    </>
  );
};

export default CampaignStats;
