import React from 'react';

function TermsService() {
  const scrollTop = (e) => {
    e.preventDefault();
    document.getElementById('terms_condition').scrollIntoView({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <div
        className="container pricing-section main-content"
        id="terms_condition">
        <div className="container center title mx-auto px-md-5 px-0 w-xl-80 mb-5 pb-md-5 pb-0">
          Privacy Policy
        </div>
      </div>
      <div className="scroll-top" onClick={scrollTop}>
        <div className="up-arrow"></div>
      </div>
      <div className="bg-white p-md-5 p-0 terms-condition-text">
        <div className="container">
          <div className=" title mx-auto px-md-5 px-3 mw-80 mb-5 color-dark text-id font-15 color-dark">
            <h2 className="font-l pb-3 pt-md-0 pt-5 fw-700">
              <div className="mb-3">Privacy Policy</div>
              <div className="font-m mt-2 fw-700">
                Last updated May 03, 2023
              </div>
            </h2>

            <p>
              This privacy notice for AthleteUp, Inc. (&quot;<b>Company</b>
              ,&quot; &quot;<b>we</b>,&quot; &quot;<b>us</b>,&quot; or &quot;
              <b>our</b>&quot;), describes how and why we might collect, store,
              use, and/or share (&quot;<b>process</b>&quot;) your information
              when you use our services (&quot;<b>Services</b>&quot;), such as
              when you:
            </p>
            <ul>
              <li>
                <p>
                  Visit our website at&nbsp;
                  <a
                    href="https://athleteup.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary">
                    https://athleteup.com
                  </a>
                  , or any website of ours that links to this privacy notice.
                </p>
              </li>
              <li>
                <p>
                  Engage with us in other related ways, including any sales,
                  marketing, or events.
                </p>
              </li>
            </ul>

            <ol className="font-20 fw-700 mt-5" start="1" id="1">
              <li>
                <h3 className="font-20 fw-700">
                  WHAT INFORMATION DO WE COLLECT?
                </h3>
              </li>
            </ol>
            <p></p>
            <p>
              <i>
                <b>In Short:</b>&nbsp;We collect personal information that you
                voluntarily provide to us when you register on the
                Services,&nbsp;express an interest in obtaining information
                about us or our products and Services, when you participate in
                activities on the Services, or otherwise when you contact us.
              </i>
            </p>
            <p>
              <b>
                {' '}
                <u>Personal information you provide:</u>
              </b>
              &nbsp;The personal information that we collect depends on your
              user type (e.g., athlete, brand, fan), the context of your
              interactions with us and the Services, the choices you make, and
              the products and features you use. The personal information we
              collect may include the following:
            </p>
            <ul>
              <li>
                <p>
                  <b>Contact information,</b>&nbsp;such as your&nbsp;first and
                  last name, email address, mailing address, and phone number.
                </p>
              </li>
              <li>
                <p>
                  <b>Profile information,</b>&nbsp;such as your username,
                  password, photographs, date of birth, gender, geographic
                  location, athlete type, sport and team affiliations, hometown,
                  alma maters, biographical details, accolades, social media
                  account URL links and follower counts, and other personal
                  information you choose to add to your profile.
                </p>
              </li>
              <li>
                <p>
                  <b>
                    {' '}
                    Payment and transactional information needed to complete
                    orders, deals, and payouts,
                  </b>
                  &nbsp;such as debit/credit card information, billing address,
                  bank account information, and shipping address.
                </p>
              </li>
              <li>
                <p>
                  <b>Identity verification information,</b>&nbsp;such as your
                  legal name, social security number, identification ID or tax
                  ID, date of birth, photograph and/or other proof of
                  identification.
                </p>
              </li>
              <li>
                <p>
                  <b>Feedback or correspondence,</b>&nbsp;such as information
                  you provide when you contact us.
                </p>
              </li>
              <li>
                <p>
                  <b> Content that you upload or share to the Services,</b>
                  &nbsp;such as text, photos, and videos in connection with
                  orders, deals, marketplace listings, campaigns,
                  correspondence, and your profile.
                </p>
              </li>
              <li>
                <p>
                  <b>Marketing information,</b> such as your preferences for
                  receiving communications about our activities and publications
                  and details about how you engage with our communications.
                </p>
              </li>
            </ul>
            <p>
              All payment data and identity verification data is collected and
              stored by Stripe. You may find their privacy notice link(s)
              here:&nbsp;
              <a href="https://stripe.com/privacy" className="text-primary">
                https://stripe.com/privacy.
              </a>
            </p>
            <p></p>
            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            <p></p>
            <p>
              <b>
                <u> Information automatically collected:</u>
                <br />
              </b>
              <i>
                <b>In Short: </b>&nbsp;Some information &mdash; such as your
                Internet Protocol (IP) address and/or browser and device
                characteristics &mdash; is collected automatically when you
                visit our Services.
              </i>
            </p>
            <p></p>
            <p>
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <p></p>
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies.&nbsp;
            </p>
            <p></p>
            <p>The information we collect includes:</p>
            <ul>
              <li>
                <p>
                  Log and Usage Data.&nbsp;Log and usage data is
                  service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access
                  or use our Services and which we record in log files.
                  Depending on how you interact with us, this log data may
                  include your IP address, device information, browser type, and
                  settings and information about your activity in the Services
                  (such as the date/time stamps associated with your usage,
                  pages and files viewed, searches, and other actions you take
                  such as which features you use), device event information
                  (such as system activity, error reports (sometimes called
                  &quot;crash dumps&quot;), and hardware settings).
                </p>
              </li>
              <li>
                <p>
                  Device Data.&nbsp;We collect device data such as information
                  about your computer, phone, tablet, or other device you use to
                  access the Services. Depending on the device used, this device
                  data may include information such as your IP address (or proxy
                  server), device and application identification numbers,
                  location, browser type, hardware model, Internet service
                  provider and/or mobile carrier, operating system, and system
                  configuration information.
                </p>
              </li>
              <li>
                <p>
                  Location Data.&nbsp;We collect location data such as
                  information about your device&apos;s location, which can be
                  either precise or imprecise. How much information we collect
                  depends on the type and settings of the device you use to
                  access the Services. For example, we may use GPS and other
                  technologies to collect geolocation data that tells us your
                  current location (based on your IP address). You can opt out
                  of allowing us to collect this information either by refusing
                  access to the information or by disabling your Location
                  setting on your device. However, if you choose to opt out, you
                  may not be able to use certain aspects of the Services.
                </p>
              </li>
            </ul>
            <p>
              <b>
                <u>Information from third party sources</u>:
              </b>
              &nbsp;We may combine personal information we receive from you with
              personal information we obtain from other sources, such as:
            </p>
            <ul>
              <li>
                <p>
                  <b>Public sources,</b>&nbsp;such as social media platforms or
                  official university or professional team websites.
                </p>
              </li>
              <li>
                <p>
                  <b>Business partners,</b>&nbsp;such as companies that have
                  entered into joint marketing relationships or other joint
                  ventures with us.
                </p>
              </li>
            </ul>

            <ol className="font-20 fw-700 mt-5" start="2" id="2">
              <li>
                <h3 className="font-20 fw-700">
                  HOW DO WE PROCESS YOUR INFORMATION?
                </h3>
              </li>
            </ol>
            <p></p>
            <p>
              <i>
                <b>In Short:</b>&nbsp;We process your information to provide,
                improve, and administer our Services, communicate with you, for
                security and fraud prevention, and to comply with law. We may
                also process your information for other purposes with your
                consent.
              </i>
            </p>
            <p>
              <b>
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </b>
            </p>
            <ul>
              <li>
                <p>
                  <b>
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.
                  </b>
                  &nbsp;We may process your information so you can create and
                  log in to your account, as well as keep your account in
                  working order.
                </p>
              </li>
              <li>
                <p>
                  <b>
                    To deliver and facilitate delivery of services to the user.
                  </b>
                  &nbsp;We may process your information to provide you with the
                  requested Services, including processing and tracking orders
                  and deals, facilitating payments, establishing and maintaining
                  your user profile, shop, and marketplace listings.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <b>To respond to user inquiries/offer support to users.</b>
                  &nbsp;We may process your information to respond to your
                  inquiries and solve any potential issues you might have with
                  the requested service.
                </p>
              </li>
              <li>
                <p>
                  <b>To send administrative information to you.</b>&nbsp;We may
                  process your information to send you details about our
                  products and services, changes to our terms and policies, and
                  other similar information.
                </p>
              </li>
              <li>
                <p>
                  <b>To fulfill and manage your orders and deals.</b>&nbsp;We
                  may process your information to fulfill and manage your
                  orders, deals, payments, and returns made through the
                  Services.
                </p>
              </li>
              <li>
                <p>
                  <b>To request feedback.</b>&nbsp;We may process your
                  information when necessary to request feedback and to contact
                  you about your use of our Services.
                </p>
              </li>
              <li>
                <p>
                  <b> To send you marketing and promotional communications.</b>
                  &nbsp;We may process the personal information you send to us
                  for our marketing purposes, if this is in accordance with your
                  marketing preferences. You can opt out of our marketing emails
                  at any time. For more information, see Section 10 below).
                </p>
              </li>
              <li>
                <p>
                  <b>To deliver targeted advertising to you.</b>&nbsp;We may
                  process your information to develop and display personalized
                  content and advertising tailored to your interests, location,
                  and more.
                </p>
              </li>
              <li>
                <p>
                  <b>To protect our Services.</b>&nbsp;We may process your
                  information as part of our efforts to keep our Services safe
                  and secure, including fraud monitoring and prevention.
                </p>
              </li>
              <li>
                <p>
                  <b>To identify usage trends.</b>&nbsp;We may process
                  information about how you use our Services to better
                  understand how they are being used so we can improve them.
                </p>
              </li>
              <li>
                <p>
                  <b>For research and development.</b>&nbsp;We may process your
                  information to analyze and improve the Services and to develop
                  new products and services, including by studying use of our
                  Services.
                </p>
              </li>
              <li>
                <p>
                  <b> To create anonymous data.</b>&nbsp;We may process your
                  information to create anonymous data from your personal
                  information and other individuals whose personal information
                  we collect. We make personal information into anonymous data
                  by removing information that makes the data personally
                  identifiable to you. We may use this anonymous data and share
                  it with third parties for our lawful business purposes,
                  including to analyze and improve our Services and to promote
                  our business.
                </p>
              </li>
              <li>
                <p>
                  <b>
                    To determine the effectiveness of our marketing and
                    promotional campaigns.
                  </b>
                  &nbsp;We may process your information to better understand how
                  to provide marketing and promotional campaigns that are most
                  relevant to you.
                </p>
              </li>
              <li>
                <p>
                  <b>To save or protect an individual&apos;s vital interest.</b>
                  &nbsp;We may process your information when necessary to save
                  or protect an individual&rsquo;s vital interest, such as to
                  prevent harm.
                </p>
              </li>
            </ul>

            <ol className="font-20 fw-700 mt-5" start="3" id="3">
              <li>
                <h3 className="font-20 fw-700">
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                </h3>
              </li>
            </ol>
            <p>
              <i>
                <b>In Short:</b>&nbsp;We only process your personal information
                when we believe it is necessary and we have a valid legal reason
                (i.e., legal basis) to do so under applicable law, like with
                your consent, to comply with laws, to provide you with services
                to enter into or fulfill our contractual obligations, to protect
                your rights, or to fulfill our legitimate business interests.
              </i>
            </p>
            <p></p>
            <p>
              <i>
                <b>
                  <u>
                    If you are located in the EU or UK, this section applies to
                    you:
                  </u>
                </b>
              </i>
              <br />
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </p>
            <ul>
              <li>
                <p>
                  <b>Consent.</b>&nbsp;We may process your information if you
                  have given us permission (i.e., consent) to use your personal
                  information for a specific purpose. You can withdraw your
                  consent at any time. Learn more about withdrawing your
                  consent.
                </p>
              </li>
              <li>
                <p>
                  <b>Performance of a Contract.</b>&nbsp;We may process your
                  personal information when we believe it is necessary to
                  fulfill our contractual obligations to you, including
                  providing our Services or at your request prior to entering
                  into a contract with you.
                </p>
              </li>
              <li>
                <p>
                  <b>Legitimate Interests.</b>&nbsp;We may process your
                  information when we believe it is reasonably necessary to
                  achieve our legitimate business interests and those interests
                  do not outweigh your interests and fundamental rights and
                  freedoms. For example, we may process your personal
                  information for some of the purposes described in order to:
                </p>
                <ul>
                  <li>
                    <p>
                      Send users information about special offers and discounts
                      on our products and services
                    </p>
                  </li>
                  <li>
                    <p>
                      Develop and display personalized and relevant advertising
                      content for our users
                    </p>
                  </li>
                  <li>
                    <p>
                      Analyze how our Services are used so we can improve them
                      to engage and retain users
                    </p>
                  </li>
                  <li>
                    <p>Support our marketing activities</p>
                  </li>
                  <li>
                    <p>
                      Diagnose problems and/or prevent fraudulent activities
                    </p>
                  </li>
                  <li>
                    <p>
                      Understand how our users use our products and services so
                      we can improve user experience
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <b>Legal Obligations.</b>&nbsp;We may process your information
                  where we believe it is necessary for compliance with our legal
                  obligations, such as to cooperate with a law enforcement body
                  or regulatory agency, exercise or defend our legal rights, or
                  disclose your information as evidence in litigation in which
                  we are involved.
                </p>
              </li>
              <li>
                <p>
                  <b>Vital Interests.</b>&nbsp;We may process your information
                  where we believe it is necessary to protect your vital
                  interests or the vital interests of a third party, such as
                  situations involving potential threats to the safety of any
                  person.
                </p>
              </li>
            </ul>
            <p></p>
            <p>
              <i>
                <b>
                  <u>
                    If you are located in Canada, this section applies to you:
                  </u>
                </b>
              </i>
              <br />
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You
              can&nbsp;withdraw your consent&nbsp;at any time.
            </p>
            <p></p>
            <p>
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </p>
            <ul>
              <li>
                <p>
                  If collection is clearly in the interests of an individual and
                  consent cannot be obtained in a timely way
                </p>
              </li>
              <li>
                <p>For investigations and fraud detection and prevention</p>
              </li>
              <li>
                <p>
                  For business transactions provided certain conditions are met
                </p>
              </li>
              <li>
                <p>
                  If it is contained in a witness statement and the collection
                  is necessary to assess, process, or settle an insurance claim
                </p>
              </li>
              <li>
                <p>
                  For identifying injured, ill, or deceased persons and
                  communicating with next of kin
                </p>
              </li>
              <li>
                <p>
                  If we have reasonable grounds to believe an individual has
                  been, is, or may be victim of financial abuse
                </p>
              </li>
              <li>
                <p>
                  If it is reasonable to expect collection and use with consent
                  would compromise the availability or the accuracy of the
                  information and the collection is reasonable for purposes
                  related to investigating a breach of an agreement or a
                  contravention of the laws of Canada or a province
                </p>
              </li>
              <li>
                <p>
                  If disclosure is required to comply with a subpoena, warrant,
                  court order, or rules of the court relating to the production
                  of records
                </p>
              </li>
              <li>
                <p>
                  If it was produced by an individual in the course of their
                  employment, business, or profession and the collection is
                  consistent with the purposes for which the information was
                  produced
                </p>
              </li>
              <li>
                <p>
                  If the collection is solely for journalistic, artistic, or
                  literary purposes
                </p>
              </li>
              <li>
                <p>
                  If the information is publicly available and is specified by
                  the regulations
                </p>
              </li>
            </ul>

            <ol className="font-20 fw-700 mt-5" start="4" id="4">
              <li>
                <h3 className="font-20 fw-700">
                  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </h3>
              </li>
            </ol>
            <p></p>
            <p>
              <i>
                <b>In Short:</b>&nbsp;We may share information in specific
                situations described in this section and/or with the following
                categories of third parties.
              </i>
            </p>
            <p>
              <b>
                Vendors, Consultants, and Other Third-Party Service Providers.
              </b>
              &nbsp;We may share your data with third-party vendors, service
              providers, contractors, or agents (&quot;<b>third parties</b>
              &quot;) who perform services for us or on our behalf and require
              access to such information to do that work. We have contracts in
              place with our third parties, which are designed to help safeguard
              your personal information. This means that they cannot do anything
              with your personal information unless we have instructed them to
              do it. They will also not share your personal information with any
              organization apart from us. They also commit to protect the data
              they hold on our behalf and to retain it for the period we
              instruct. The categories of third parties we may share personal
              information with are as follows:
            </p>
            <ul>
              <li>
                <p>Data analytics services</p>
              </li>
              <li>
                <p>Cloud computing services</p>
              </li>
              <li>
                <p>Sales &amp; marketing tools</p>
              </li>
              <li>
                <p>Order fulfillment service providers</p>
              </li>
              <li>
                <p>Payment processors</p>
              </li>
              <li>
                <p>
                  User account registration &amp; authentication/verification
                  services
                </p>
              </li>
              <li>
                <p>Product engineering &amp; design tools</p>
              </li>
              <li>
                <p>Finance &amp; accounting tools</p>
              </li>
            </ul>
            <p></p>
            <p>
              We also may need to share your personal information in the
              following situations:
            </p>
            <p></p>
            <p>
              <b>Professional Advisors.</b>&nbsp;We may share your information
              with lawyers, auditors, bankers and insurers, where necessary in
              the course of the professional services that they render to us.
            </p>
            <p>
              <b>Business Transfers.</b>&nbsp;We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </p>
            <p>
              <b>When we use Google Maps Platform APIs.</b>&nbsp;We may share
              your information with certain Google Maps Platform APIs (e.g.,
              Google Maps API, Places API).
            </p>
            <p>
              <b> Affiliates.</b>&nbsp;We may share your information with our
              affiliates, in which case we will require those affiliates to
              honor this privacy notice. Affiliates include our parent company
              and any subsidiaries, joint venture partners, or other companies
              that we control or that are under common control with us.
            </p>
            <p>
              <b>Business Partners.</b>&nbsp;We may share your information with
              our business partners to offer you certain products, services, or
              promotions.
            </p>
            <p>
              <b>Authorities and Others.</b>&nbsp;We may share your information
              with&nbsp;law enforcement, government authorities, and private
              parties, as we believe in good faith to be necessary or
              appropriate for the compliance and safety of the Services.
            </p>

            <ol className="font-20 fw-700 mt-5" start="5" id="5">
              <li>
                <h3 className="font-20 fw-700">
                  DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </h3>
              </li>
            </ol>

            <p></p>
            <p>
              <i>
                <b>In Short:</b>&nbsp;We may use cookies and other tracking
                technologies to collect and store your information.
              </i>
            </p>
            <p></p>
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information.&nbsp;
            </p>
            <ol className="font-20 fw-700 mt-5" start="6" id="6">
              <li>
                <h3 className="font-20 fw-700">
                  IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                </h3>
              </li>
            </ol>

            <p></p>
            <p>
              <i>
                <b>In Short:</b>&nbsp;We may transfer, store, and process your
                information in countries other than your own.
              </i>
            </p>
            <p></p>
            <p>
              Our servers are located in the United States. If you are accessing
              our Services from outside the United States, please be aware that
              your information may be transferred to, stored, and processed by
              us in our facilities and by those third parties with whom we may
              share your personal information, in&nbsp;the United
              States,&nbsp;and other countries. If you are a resident in the
              European Economic Area (EEA) or United Kingdom (UK), then these
              countries may not necessarily have data protection laws or other
              similar laws as comprehensive as those in your country. However,
              we will take all necessary measures to protect your personal
              information in accordance with this privacy notice and applicable
              law.
            </p>
            <p></p>
            <p>
              <u>
                <i>European Commission&apos;s Standard Contractual Clauses:</i>
              </u>
              <br />
              We have implemented measures to protect your personal information,
              including by using the European Commission&apos;s Standard
              Contractual Clauses for transfers of personal information between
              our group companies and between us and our third-party providers.
              These clauses require all recipients to protect all personal
              information that they process originating from the EEA or UK in
              accordance with European data protection laws and
              regulations.&nbsp;Our Standard Contractual Clauses can be provided
              upon request.&nbsp;We have implemented similar appropriate
              safeguards with our third-party service providers and partners and
              further details can be provided upon request.
            </p>

            <ol className="font-20 fw-700 mt-5" start="7" id="7">
              <li>
                <h3 className="font-20 fw-700">
                  HOW LONG DO WE KEEP YOUR INFORMATION?
                </h3>
              </li>
            </ol>
            <p></p>
            <p>
              <i>
                <b>In Short:</b>&nbsp;We keep your information for as long as
                necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law.
              </i>
            </p>
            <p></p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than twelve (12) months past the termination of the
              user&apos;s account.
            </p>
            <p></p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>

            <ol className="font-20 fw-700 mt-5" start="8" id="8">
              <li>
                <h3 className="font-20 fw-700">
                  HOW DO WE KEEP YOUR INFORMATION SAFE?
                </h3>
              </li>
            </ol>
            <p></p>
            <p>
              <i>
                <b>In Short:</b>&nbsp;We aim to protect your personal
                information through a system of organizational and technical
                security measures.
              </i>
            </p>
            <p></p>
            <p>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
            <ol className="font-20 fw-700 mt-5" start="9" id="9">
              <li>
                <h3 className="font-20 fw-700">CHILDREN</h3>
              </li>
            </ol>

            <p></p>
            <p>
              <i>
                <b>In Short:</b>&nbsp;The Services are not intended for use by
                children under 13 years of age.
              </i>
            </p>
            <p></p>
            <p>
              The Services are not intended for use by children under 13 years
              of age. If we learn that we have collected personal information
              through the Service from a child under 13 without the consent of
              the child&rsquo;s parent or guardian as required by law, we will
              delete it.
            </p>

            <ol className="font-20 fw-700 mt-5" start="10" id="10">
              <li>
                <h3 className="font-20 fw-700">
                  WHAT ARE YOUR PRIVACY RIGHTS?
                </h3>
              </li>
            </ol>

            <p></p>
            <p>
              <i>
                <b>In Short:</b>&nbsp;In some regions, such as the European
                Economic Area (EEA), United Kingdom (UK), and Canada, you have
                rights that allow you greater access to and control over your
                personal information. You may review, change, or terminate your
                account at any time.
              </i>
            </p>
            <p></p>
            <p>
              In some regions (like the EEA, UK, and Canada), you have certain
              rights under applicable data protection laws. These may include
              the right (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. You can make such a request by contacting us by using
              the contact details provided in the last section below. We will
              consider and act upon any request in accordance with applicable
              data protection laws. If you are located in the EEA or UK and you
              believe we are unlawfully processing your personal information,
              you also have the right to complain to your&nbsp;
              <a
                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                className="text-primary">
                <u>Member State data protection authority</u>
              </a>{' '}
              or&nbsp;
              <a
                href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                className="text-primary">
                <u>UK data protection authority</u>
              </a>
              . If you are located in Switzerland, you may contact the&nbsp;
              <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                className="text-primary">
                <u>Federal Data Protection and Information Commissioner</u>
              </a>
              .
            </p>
            <p></p>
            <p>
              <b>
                <u>Withdrawing your consent:</u>
              </b>
              &nbsp;If we are relying on your consent to process your personal
              information, which may be express and/or implied consent depending
              on the applicable law, you have the right to withdraw your consent
              at any time. You can withdraw your consent at any time by
              contacting us by using the contact details provided in the last
              section of this Privacy Policy below or by updating your
              notification preferences in your account settings.&nbsp;
            </p>
            <p></p>
            <p>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <p></p>
            <p>
              <b>
                <u>Opting out of marketing and promotional communications:</u>
              </b>
              &nbsp;You can unsubscribe from our marketing and promotional
              communications at any time by clicking on the unsubscribe link in
              the emails that we send, or by updating your notification
              preferences in your account settings, or by emailing us at
              info@athleteup.com. You will then be removed from the marketing
              lists. However, we may still communicate with you &mdash; for
              example, to send you service-related messages that are necessary
              for the administration and use of your account, to respond to
              service requests, or for other non-marketing purposes.&nbsp;
            </p>
            <p></p>
            <p>
              <b>
                <u>Account Information:</u>
              </b>
              &nbsp;If you would at any time like to review or change the
              information in your account or terminate your account, you can
              login to your account settings and update your user account.
            </p>
            <p></p>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </p>
            <p></p>
            <p>
              <b>
                <u>Cookies and similar technologies:</u>
              </b>
              &nbsp;Most Web browsers are set to accept cookies by default. If
              you prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Services. You may also&nbsp;
              <a href="http://www.aboutads.info/choices/" className="text-dark">
                <u> opt out of interest-based advertising by advertisers</u>
              </a>{' '}
              on our Services.&nbsp;
            </p>
            <p></p>
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at info@athleteup.com.
            </p>

            <ol className="font-20 fw-700 mt-5" start="11" id="11">
              <li>
                <h3 className="font-20 fw-700">
                  CONTROLS FOR DO-NOT-TRACK FEATURES
                </h3>
              </li>
            </ol>

            <p></p>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (&quot;DNT&quot;) feature or
              setting you can activate to signal your privacy preference not to
              have data about your online browsing activities monitored and
              collected. At this stage no uniform technology standard for
              recognizing and implementing DNT signals has been finalized. As
              such, we do not currently respond to DNT browser signals or any
              other mechanism that automatically communicates your choice not to
              be tracked online. If a standard for online tracking is adopted
              that we must follow in the future, we will inform you about that
              practice in a revised version of this privacy notice.
            </p>
            <ol className="font-20 fw-700 mt-5" start="12" id="12">
              <li>
                <h3 className="font-20 fw-700">
                  DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </h3>
              </li>
            </ol>

            <p></p>
            <p>
              <i>
                <b>In Short:</b>&nbsp;Yes, if you are a resident of California,
                you are granted specific rights regarding access to your
                personal information.
              </i>
            </p>
            <p></p>
            <p>
              California Civil Code Section 1798.83, also known as the
              &quot;Shine The Light&quot; law, permits our users who are
              California residents to request and obtain from us, once a year
              and free of charge, information about categories of personal
              information (if any) we disclosed to third parties for direct
              marketing purposes and the names and addresses of all third
              parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California
              resident and would like to make such a request, please submit your
              request in writing to us using the contact information provided
              below.
            </p>
            <p></p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.,
              backups, etc.).
            </p>
            <p>
              <b>CCPA Privacy Notice</b>
            </p>
            <p>
              The California Code of Regulations defines a &quot;resident&quot;
              as:
            </p>
            <p></p>
            <ol>
              <li>
                <p>
                  Every individual who is in the State of California for other
                  than a temporary or transitory purpose; and
                </p>
              </li>
              <li>
                <p>
                  Every individual who is domiciled in the State of California
                  who is outside the State of California for a temporary or
                  transitory purpose.
                </p>
              </li>
            </ol>
            <p>
              All other individuals are defined as &quot;non-residents.&quot;
            </p>
            <p>
              If this definition of &quot;resident&quot; applies to you, we must
              adhere to certain rights and obligations regarding your personal
              information.
            </p>
            <p>
              <b>What categories of personal information do we collect?</b>
            </p>
            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <p></p>
            <div align="left">
              <table className="table border">
                <tbody>
                  <tr>
                    <td>
                      <b>Category</b>
                    </td>
                    <td>
                      <b>Examples</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Identifiers</p>
                    </td>
                    <td>
                      <p>
                        Contact details, such as real name, alias, postal
                        address, telephone or mobile contact number, unique
                        personal identifier, online identifier, Internet
                        Protocol address, email address, and account name
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        Personal information categories listed in the California
                        Customer Records statute
                      </p>
                    </td>
                    <td>
                      <p>
                        Name, contact information, education, employment,
                        employment history, and financial information
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        Protected classification characteristics under
                        California or federal law
                      </p>
                    </td>
                    <td>
                      <p>Gender and date of birth</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Commercial information</p>
                    </td>
                    <td>
                      <p>
                        Transaction information, purchase history, financial
                        details, and payment information
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Internet or other similar network activity</p>
                    </td>
                    <td>
                      <p>
                        Browsing history, search history, online behavior,
                        interest data, and interactions with our and other
                        websites, applications, systems, and advertisements
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Geolocation data</p>
                    </td>
                    <td>
                      <p>Device location</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        Audio, electronic, visual, thermal, olfactory, or
                        similar information
                      </p>
                    </td>
                    <td>
                      <p>
                        Images and audio, video or call recordings created in
                        connection with our business activities
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Professional or employment-related information</p>
                    </td>
                    <td>
                      <p>
                        Business contact details in order to provide you our
                        Services at a business level or job title, work history,
                        and professional qualifications if you apply for a job
                        with us
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Education Information</p>
                    </td>
                    <td>
                      <p>Student records and directory information</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Sensitive Personal Information</p>
                    </td>
                    <td>
                      <p>
                        Account login information, debit or credit card numbers,
                        drivers&apos; licenses and social security numbers
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p></p>
            <p>
              We will use and retain the collected personal information as
              needed to provide the Services or for&nbsp;as long as the user has
              an account with us.
            </p>
            <p></p>
            <p>
              Sensitive Personal Information may be used, or disclosed to a
              service provider or contractor, for additional, specified
              purposes. You have the right to limit the use or disclosure of
              your sensitive personal information.
            </p>
            <p></p>
            <p>
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </p>
            <ul>
              <li>
                <p>Receiving help through our customer support channels;</p>
              </li>
              <li>
                <p>Participation in customer surveys or contests; and</p>
              </li>
              <li>
                <p>
                  Facilitation in the delivery of our Services and to respond to
                  your inquiries.
                </p>
              </li>
            </ul>
            <p>
              <b>How do we use and share your personal information?</b>
              <br />
              AthleteUp, Inc. collects and shares your personal information
              through:
            </p>
            <ul>
              <li>
                <p>Targeting cookies/marketing cookies</p>
              </li>
              <li>
                <p>Social media cookies</p>
              </li>
              <li>
                <p>Beacons/pixels/tags</p>
              </li>
            </ul>
            <p>
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </p>
            <p>
              You may contact us by email at info@athleteup.com,&nbsp;or by
              referring to the contact details at the bottom of this document.
            </p>
            <p>
              If you are using an authorized agent to exercise your right to
              opt-out we may deny a request if the authorized agent does not
              submit proof that they have been validly authorized to act on your
              behalf.
            </p>
            <p>
              <b>Will your information be shared with anyone else?</b>
              <br />
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf, following the same
              strict privacy protection obligations mandated by the CCPA.
            </p>
            <p>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be &quot;selling&quot; of your personal information.
            </p>
            <p>
              AthleteUp, Inc. has not sold or shared any personal information to
              third parties for a business or commercial purpose in the
              preceding twelve (12) months.&nbsp;AthleteUp, Inc.&nbsp;has
              disclosed the following categories of personal information to
              third parties for a business or commercial purpose in the
              preceding twelve (12) months:
            </p>
            <ul>
              <li>
                <p>
                  Identifiers, such as contact details like your real name,
                  alias, postal address, telephone or mobile contact number,
                  unique personal identifier, online identifier, Internet
                  Protocol address, email address, and account name.
                </p>
              </li>
              <li>
                <p>
                  Personal Information, as defined in the California Customer
                  Records law, such as your name, contact information,
                  education, employment, employment history, and financial
                  information.
                </p>
              </li>
              <li>
                <p>
                  Characteristics of protected classifications under California
                  or federal law, such as gender or date of birth.
                </p>
              </li>
              <li>
                <p>
                  Commercial information, such as transaction information,
                  purchase history, financial details, and payment information.
                </p>
              </li>
              <li>
                <p>
                  Internet or other electronic network activity information,
                  such as browsing history, search history, online behavior,
                  interest data, and interactions with our and other websites,
                  applications, systems, and advertisements.
                </p>
              </li>
              <li>
                <p>
                  Professional or employment-related information, such as
                  business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us.
                </p>
              </li>
              <li>
                <p>
                  Education information, such as student records and directory
                  information.
                </p>
              </li>
            </ul>
            <p></p>
            <p>
              The categories of third parties to whom we disclosed personal
              information for a business or commercial purpose can be found
              under Section 4 of this Privacy Policy.
            </p>
            <p>
              <b>Your rights with respect to your personal data</b>
              <br />
              <u>
                <i>
                  Right to request deletion of the data &ndash; Request to
                  delete:
                </i>
              </u>
            </p>
            <p>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </p>
            <p>
              <u>
                <i>Right to be informed &mdash; Request to know:</i>
              </u>
              <br />
              Depending on the circumstances, you have a right to know:
            </p>
            <ul>
              <li>
                <p>Whether we collect and use your personal information;</p>
              </li>
              <li>
                <p>The categories of personal information that we collect;</p>
              </li>
              <li>
                <p>
                  The purposes for which the collected personal information is
                  used;
                </p>
              </li>
              <li>
                <p>
                  Whether we sell or share personal information to third
                  parties;
                </p>
              </li>
              <li>
                <p>
                  The categories of personal information that we sold, shared,
                  or disclosed for a business purpose;
                </p>
              </li>
              <li>
                <p>
                  The categories of third parties to whom the personal
                  information was sold, shared, or disclosed for a business
                  purpose;
                </p>
              </li>
              <li>
                <p>
                  The business or commercial purpose for collecting, selling, or
                  sharing personal information; and
                </p>
              </li>
              <li>
                <p>
                  The specific pieces of personal information we collected about
                  you.
                </p>
              </li>
            </ul>
            <p>
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </p>
            <p>
              <u>
                <i>
                  Right to Non-Discrimination for the Exercise of a
                  Consumer&rsquo;s Privacy Rights
                </i>
              </u>
              <br />
              We will not discriminate against you if you exercise your privacy
              rights.
            </p>
            <p></p>
            <p className="mb-0">
              <u>
                <i>
                  Right to Limit Use and Disclosure of Sensitive Personal
                  Information
                </i>
              </u>
              <br />
              If the business collects any of the following:
            </p>
            <ul>
              <li>
                <p>
                  Social security information, drivers&apos; licenses, state ID
                  cards, passport numbers
                </p>
              </li>
              <li>
                <p>Account login information</p>
              </li>
              <li>
                <p>
                  Credit card numbers, financial account information, or
                  credentials allowing access to such accounts
                </p>
              </li>
              <li>
                <p>Precise geolocation</p>
              </li>
              <li>
                <p>
                  Racial or ethnic origin, religious or philosophical beliefs,
                  union membership
                </p>
              </li>
              <li>
                <p>
                  The contents of email and text, unless the business is the
                  intended recipient of the communication
                </p>
              </li>
              <li>
                <p>Genetic data, biometric data, and health data</p>
              </li>
              <li>
                <p>Data concerning sexual orientation and sex life</p>
              </li>
            </ul>
            <p>
              you have the right to direct that business to limit its use of
              your sensitive personal information to that use which is necessary
              to perform the Services.
            </p>
            <p>
              Once a business receives your request, they are no longer allowed
              to use or disclose your sensitive personal information for any
              other purpose unless you provide consent for the use or disclosure
              of sensitive personal information for additional purposes.
            </p>
            <p></p>
            <p>
              Please note that sensitive personal information that is collected
              or processed without the purpose of inferring characteristics
              about a consumer is not covered by this right, as well as the
              publicly available information.
            </p>
            <p></p>
            <p>
              To exercise your right to limit use and disclosure of sensitive
              personal information, please email info@athleteup.com.
            </p>
            <p>
              <u>
                <i>Verification process:</i>
              </u>
              <br />
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g., phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </p>
            <p></p>
            <p>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. However, if we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </p>
            <p className="mb-0">
              <u>
                <i>Other privacy rights:</i>
              </u>
            </p>
            <ul>
              <li>
                <p>
                  You may object to the processing of your personal information.
                </p>
              </li>
              <li>
                <p>
                  You may request correction of your personal data if it is
                  incorrect or no longer relevant, or ask to restrict the
                  processing of the information.
                </p>
              </li>
              <li>
                <p>
                  You can designate an authorized agent to make a request under
                  the CCPA on your behalf. We may deny a request from an
                  authorized agent that does not submit proof that they have
                  been validly authorized to act on your behalf in accordance
                  with the CCPA.
                </p>
              </li>
              <li>
                <p>
                  You may request to opt out from future selling or sharing of
                  your personal information to third parties. Upon receiving an
                  opt-out request, we will act upon the request as soon as
                  feasibly possible, but no later than fifteen (15) days from
                  the date of the request submission.
                </p>
              </li>
            </ul>
            <p>
              To exercise these rights, you can contact us by email at
              info@athleteup.com,&nbsp;or by referring to the contact details at
              the bottom of this document. If you have a complaint about how we
              handle your data, we would like to hear from you.
            </p>

            <ol className="font-20 fw-700 mt-5" start="13" id="13">
              <li>
                <h3 className="font-20 fw-700">
                  DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </h3>
              </li>
            </ol>
            <p>
              <i>
                <b>In Short:</b>&nbsp;Yes, if you are a resident of Virginia,
                you may be granted specific rights regarding access to and use
                of your personal information.
              </i>
            </p>
            <p>
              <b>Virginia CDPA Privacy Notice</b>
            </p>
            <p>
              <i>
                <u>Under the Virginia Consumer Data Protection Act (CDPA):</u>
              </i>
              <br />
              &quot;Consumer&quot; means a natural person who is a resident of
              the Commonwealth acting only in an individual or household
              context. It does not include a natural person acting in a
              commercial or employment context.
            </p>
            <p></p>
            <p>
              &quot;Personal data&quot; means any information that is linked or
              reasonably linkable to an identified or identifiable natural
              person. &quot;Personal data&quot; does not include de-identified
              data or publicly available information.
            </p>
            <p></p>
            <p>
              &quot;Sale of personal data&quot; means the exchange of personal
              data for monetary consideration.
            </p>
            <p></p>
            <p>
              If this definition &quot;consumer&quot; applies to you, we must
              adhere to certain rights and obligations regarding your personal
              data.
            </p>
            <p></p>
            <p>
              The information we collect, use, and disclose about you will vary
              depending on how you interact with AthleteUp, Inc. and our
              Services.
            </p>

            <p>
              <i>
                <u>Your rights with respect to your personal data:</u>
              </i>
            </p>
            <ul>
              <li>
                <p>
                  Right to be informed whether or not we are processing your
                  personal data
                </p>
              </li>
              <li>
                <p>Right to access your personal data</p>
              </li>
              <li>
                <p>Right to correct inaccuracies in your personal data</p>
              </li>
              <li>
                <p>Right to request deletion of your personal data</p>
              </li>
              <li>
                <p>
                  Right to obtain a copy of the personal data you previously
                  shared with us
                </p>
              </li>
              <li>
                <p>
                  Right to opt out of the processing of your personal data if it
                  is used for targeted advertising, the sale of personal data,
                  or profiling in furtherance of decisions that produce legal or
                  similarly significant effects (&quot;profiling&quot;)
                </p>
              </li>
            </ul>
            <p>
              AthleteUp, Inc. has not sold any personal data to third parties
              for business or commercial purposes. AthleteUp, Inc. will not sell
              personal data in the future belonging to website visitors, users,
              and other consumers.
            </p>
            <p>
              <i>
                <u>Exercise your rights provided under the Virginia CDPA:</u>
              </i>
              <br />
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </p>
            <p></p>
            <p>
              You may contact us by email at&nbsp;
              <a href="mailto:info@athleteup.com" className="text-primary">
                <u> info@athleteup.com</u>
              </a>{' '}
              or by referring to the contact details at the bottom of this
              document.
            </p>
            <p>
              If you are using an authorized agent to exercise your rights, we
              may deny a request if the authorized agent does not submit proof
              that they have been validly authorized to act on your behalf.
            </p>
            <p>
              Verification process:We may request that you provide additional
              information reasonably necessary to verify you and your
              consumer&apos;s request. If you submit the request through an
              authorized agent, we may need to collect additional information to
              verify your identity before processing your request.
            </p>
            <p>
              Upon receiving your request, we will respond without undue delay,
              but in all cases, within forty-five (45) days of receipt. The
              response period may be extended once by forty-five (45) additional
              days when reasonably necessary. We will inform you of any such
              extension within the initial 45-day response period, together with
              the reason for the extension.
            </p>
            <p>
              <i>
                <u>Right to appeal:</u>
              </i>
              <br />
              If we decline to take action regarding your request, we will
              inform you of our decision and reasoning behind it. If you wish to
              appeal our decision, please email us at info@athleteup.com. Within
              sixty (60) days of receipt of an appeal, we will inform you in
              writing of any action taken or not taken in response to the
              appeal, including a written explanation of the reasons for the
              decisions. If your appeal if denied, you may contact the&nbsp;
              <a
                href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                className="text-primary">
                <u>Attorney General to submit a complaint</u>
              </a>
              .
            </p>

            <ol className="font-20 fw-700 mt-5" start="14" id="14">
              <li>
                <h3 className="font-20 fw-700">
                  DO WE MAKE UPDATES TO THIS NOTICE?
                </h3>
              </li>
            </ol>
            <p>
              <i>
                <b>In Short:</b>&nbsp;Yes, we will update this notice as
                necessary to stay compliant with relevant laws.
              </i>
            </p>
            <p></p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated &quot;Revised&quot; date
              and the updated version will be effective as soon as it is
              accessible. If we make material changes to this privacy notice, we
              may notify you either by prominently posting a notice of such
              changes or by directly sending you a notification. We encourage
              you to review this privacy notice frequently to be informed of how
              we are protecting your information.
            </p>

            <ol className="font-20 fw-700 mt-5" start="15" id="15">
              <li>
                <h3 className="font-20 fw-700">
                  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </h3>
              </li>
            </ol>
            <p className="mb-4">
              If you have questions or comments about this notice, you may email
              us at info@athleteup.com&nbsp;or by post to:
            </p>
            <p className="mb-0">AthleteUp, Inc.</p>
            <p className="mb-0">7312 Glenroe Dr.</p>
            <p>Huntersville, NC 28078&nbsp;United States</p>

            <ol className="font-20 fw-700 mt-5" start="16" id="16">
              <li>
                <h3 className="font-20 fw-700">
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                  YOU?
                </h3>
              </li>
            </ol>

            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it. To request to
              review, update, or delete your personal information, please
              contact us at{' '}
              <a href="mailto:info@athleteup.com" className="text-primary">
                info@athleteup.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default TermsService;
