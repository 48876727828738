import React, {useState, useEffect, useCallback} from 'react';
import {Tabs} from 'antd';
import moment from 'moment';
import AppHeader from '../../../../../common/components/AppHeader';
import {CustomTabs} from '../../../../Purchaser/components/style';
import {TableWithInfiniteScroll} from './TableWithInfiniteScroll';
import '../../../../../assets/scss/_deals.scss';
import Loader from 'react-loader-spinner';
import DealsMob from './DealsMob';
import {useDispatch, useSelector} from 'react-redux';
import {getDeals} from '../../../../../store/athleteflow/action';
import ProfileImage from '../../../../Purchaser/components/ProfileImage';
import useApi from '../../../../../hooks/useApi';
import StatsData from '../../../../../common/components/Stats';
import {useLocation} from 'react-router-dom';
import {useInterval} from '../../../../../hooks/intervalHook';
import {formatPriceCommas} from '../../../../../helpers/user';
import useTabActive from '../../../../../hooks/useTabActive';
import SubScriptionBanner from '../../../../../common/components/SubscriptionBanner';

const statusType = (value) => {
  if (value === 'completed') {
    return 'status-success ';
  } else if (value === 'in progress') {
    return 'status-progress';
  } else if (value === 'declined') {
    return 'cancel';
  } else if (value === 'application/offer') {
    return 'offer';
  } else if (value === 'disputed') {
    return 'disputed';
  }
};

const ManageDeals = () => {
  const {TabPane} = Tabs;
  const {isLoading, dealsCount, currentDeals, stripeStatus, membeShipExpired} =
    useSelector((state) => state.AthleteModuleReducer);
  const {dealsLoader} = useSelector((state) => state.BrandModuleReducer);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(1);
  const [loadPath, setLoad] = useState(false);
  const [statsData] = useApi('/athlete/campaign/deal/stats');
  const location = useLocation();
  const [tabActive] = useTabActive();
  const callbackDeal = useCallback(async () => {
    try {
      dispatch(
        getDeals({
          page: page,
          pageSize: 5,
          status: status,
        }),
      );
      if (location.pathname === '/athlete/deals') {
        setLoad(true);
      } else {
        setLoad(false);
      }
    } catch (err) {
      // dispatch(hideModal());
      console.log(err, 'Fetch api error');
    }
  }, [dispatch, status, page, location.pathname]);

  useEffect(() => {
    setPage(1);
  }, [status]);

  useInterval(callbackDeal, loadPath === true && tabActive ? 40000 : null);

  useEffect(() => {
    callbackDeal();
  }, [callbackDeal]);

  const pageHandler = (pageData) => {
    if (pageData.current) {
      setPage(pageData.current);
    }
  };

  const keyChanged = (key) => {
    if (key === 'all') {
      setStatus(null);
    } else {
      setStatus(key);
    }
  };

  const loadMore = () => {
    if (dealsCount > 5) {
      setPage((prev) => {
        return prev + 1;
      });
    }
  };

  const dealColumns = [
    {
      title: 'Deal ID',
      dataIndex: 'id',
      key: 'id',
      // width: "120px",
      render: (text) => (
        <span className="fw-700 color-dark font-15">#{text}</span>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <span>{moment(text).format('MMM DD,YYYY')}</span>,
    },
    {
      title: 'Offerer',
      dataIndex: 'purchaser',
      key: 'purchaser',
      render: (text) => (
        <div className="d-flex align-items-center">
          <ProfileImage
            width={32}
            imgWidth={32}
            desktopImgWidth={32}
            imgContainerClassName="purchaser radius-m"
            height={32}
            src={
              text?.purchaser?.logo ? text?.purchaser?.logo : text?.image_url
            }
          />
          <span className="text-start">
            {text?.purchaser?.company ? text?.purchaser?.company : text?.name}
          </span>
        </div>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <div style={{width: '300px'}}>{text}</div>,
    },
    {
      title: 'Amount',
      key: 'total_price',
      render: (_, record) => (
        <span>
          {record?.counter_offers && record?.counter_offers.length > 0
            ? '$' + formatPriceCommas(record?.counter_offers[0]?.total_price)
            : '$' + formatPriceCommas(record?.total_price)}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        return (
          <span className={`${statusType(text)} text-capitalize`}>{text}</span>
        );
      },
    },
  ];

  const renderDeals = () => {
    if (dealsLoader) {
      return (
        <div className="loader-contain text-center">
          <Loader type="Bars" color="#00BFFF" height={50} width={50} />
        </div>
      );
    } else {
      if (currentDeals && currentDeals.length > 0) {
        return (
          <TableWithInfiniteScroll
            item="deal"
            columns={dealColumns}
            rows={currentDeals}
            loading={isLoading}
            count={dealsCount}
            current={page}
            onChange={pageHandler}
          />
        );
      } else if (currentDeals.length === 0) {
        return (
          <div className="fw-700 d-flex font-18 justify-content-center my-4 pt-5">
            No deals to display
          </div>
        );
      }
    }
  };

  const renderDealsMobile = () => {
    if (isLoading) {
      return (
        <div className="loader-contain text-center">
          <Loader type="Bars" color="#00BFFF" height={50} width={50} />
        </div>
      );
    } else {
      if (currentDeals && currentDeals.length > 0) {
        return (
          <DealsMob
            dealsCount={dealsCount}
            isLoading={isLoading}
            status={status}
            page={page}
            loadMore={loadMore}
          />
        );
      } else if (currentDeals.length === 0) {
        return (
          <div className="fw-700 d-flex font-18 justify-content-center my-4 pt-5">
            No deals to display
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <AppHeader
          headerClass="deal-header"
          title="Deals"
          subTitle={
            <p className="font-20 color-muted mb-5 fw-600">
              View and manage every deal opportunity from application through
              final completion.
            </p>
          }
        />
      </div>
      {membeShipExpired && <SubScriptionBanner stripeStatus={stripeStatus} />}
      <StatsData
        {...{
          cardLabel1: 'Active deals',
          cardLabel2: 'Total completed deals',
          cardLabel3: 'Deal earnings this month',
          cardLabel4: ' Total deal earnings',
          label1Data: statsData?.data?.active,
          label2Data: statsData?.data?.complete,
          label3Data: statsData?.data?.spent_this_month,
          label4Data: statsData?.data?.spent_total,
          title: 'YOUR DEALS',
          labelNewCount1: statsData?.data?.active_new,
          // labelNewCount2={statsData?.data?.orders_new?.completed}
        }}
      />

      <div className="d-none d-lg-block deals-tab">
        <CustomTabs defaultActiveKey="all" size="large" onChange={keyChanged}>
          <TabPane tab="ALL DEALS" key="all" style={{overflowY: 'auto'}}>
            {renderDeals()}
          </TabPane>
          <TabPane
            tab="APPLICATIONS/OFFERS"
            key="applied"
            style={{overflowY: 'auto'}}>
            {renderDeals()}
          </TabPane>
          <TabPane
            tab="DEALS IN PROGRESS"
            key="in progress"
            style={{overflowY: 'auto'}}>
            {renderDeals()}
          </TabPane>
          <TabPane tab="COMPLETED" key="completed" style={{overflowY: 'auto'}}>
            {renderDeals()}
          </TabPane>
          <TabPane tab="DECLINED" key="declined" style={{overflowY: 'auto'}}>
            {renderDeals()}
          </TabPane>
          <TabPane tab="DISPUTED" key="disputed" style={{overflowY: 'auto'}}>
            {renderDeals()}
          </TabPane>
        </CustomTabs>
      </div>
      <div className="d-block d-lg-none">
        <CustomTabs defaultActiveKey="all" size="large" onChange={keyChanged}>
          <TabPane tab="ALL DEALS" key="all" style={{overflowY: 'auto'}}>
            {renderDealsMobile()}
          </TabPane>
          <TabPane
            tab="APPLICATIONS/OFFERS"
            key="applied"
            style={{overflowY: 'auto'}}>
            {renderDealsMobile()}
          </TabPane>
          <TabPane
            tab="DEALS IN PROGRESS"
            key="in progress"
            style={{overflowY: 'auto'}}>
            {renderDealsMobile()}
          </TabPane>
          <TabPane tab="COMPLETED" key="completed" style={{overflowY: 'auto'}}>
            {renderDealsMobile()}
          </TabPane>
          <TabPane tab="DECLINED" key="declined" style={{overflowY: 'auto'}}>
            {renderDealsMobile()}
          </TabPane>
          <TabPane tab="DISPUTED" key="disputed" style={{overflowY: 'auto'}}>
            {renderDealsMobile()}
          </TabPane>
        </CustomTabs>
      </div>
    </>
  );
};

export default ManageDeals;
