import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setHeader } from "../../../../../store/athleteflow/action";
import { CloseIcon } from "../../../../../utils/SocialIcons";
import CampaignModalContainer from "./CampaignModalContainer";

const CampaignModalContainerMobile = ({mode}) => {
  const [campaignData, setCampaignData] = useState({});
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(()=> {
    if(location?.state?.campaignData) {
      setCampaignData(location?.state?.campaignData);
    }
  }, [location?.state?.campaignData]);

  useEffect(()=> {
    dispatch(setHeader(false));
  }, [dispatch]);

  const goBack = () => {
    history.push(`/purchaser/campaigns`);
  }

  return(
    <div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          fontSize: '20px',
          fontWeight: '800',
          lineHeight: '27px',
          color: '#042237',
          padding: '30px 0',
          position: 'relative'
        }}>
        <span style={{textTransform: 'capitalize'}}>{`${mode} Campaign`}</span>
        <span style={{position: 'absolute', padding: '10px', right: '8%'}}
          onClick={goBack}
        ><CloseIcon/></span>
      </div>
      <CampaignModalContainer 
        mode={mode}
        modalView={false}
        campaignData={campaignData}
      />
    </div>
  );
}

export default CampaignModalContainerMobile;