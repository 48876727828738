import React from 'react';
import '../features.scss';
import {
  bannerDetailsSection,
  brandsSection,
  backgroundStyle,
  calendlyLink,
} from '../../../../../utils/staticStrings';
import circleBg from '../../../../../assets/images/bannerEllipseImg.svg';
import {Link} from 'react-router-dom';
import {PopupWidget} from 'react-calendly';

export default function index(props) {
  // const onClickLearnMore = () => {
  //     props.onLearnMoreClick();
  // }

  return props.showBrands ? (
    <div className="brands-details">
      <div className="brand-row">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 banner-texts">
            <div className="row mb-3">
              <div className="col">
                <div className="main-heading">{brandsSection.title}</div>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-xxl-11">
                <div className="title">{brandsSection.subtext}</div>
              </div>
            </div>
            <div className="row btnrow">
              <div className="col-12 col-lg-6 col-xl-6">
                {/* <button className='clickbtnwhite' onClick={onClickLearnMore}> LEARN MORE</button> */}
                <PopupWidget
                  url={`https://calendly.com/${calendlyLink}`}
                  /*
                   * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                   * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                   */
                  rootElement={document.getElementById('root')}
                  text="BOOK A DEMO"
                  textColor="#042237"
                  color="#FFF"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 bg-ellipse">
            <img
              className="brand-image"
              src={brandsSection.img}
              alt="brand-imag"
            />
            <img
              className="brand-image-mob"
              src={backgroundStyle.brandsBannerCardMob}
              alt="brand-imag"
            />
          </div>
        </div>
      </div>
      <div className=" banner-base">
        <div className="row">
          <div
            className="col-12 col-sm-8 col-md-10 col-lg-12 col-xl-9 col-xxl-8 bg-arrow mx-auto"
            style={{
              background: `url('${backgroundStyle.bannerBaseLight}')`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
            <div className="base-heading">{brandsSection.basetitle}</div>
            <div className="base-text">{brandsSection.basetexts}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="banner-details">
      <div className=" banner-row">
        <div className="row banner-girl-image">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-7 col-xxl-6 banner-texts">
            <div className="row mb-3">
              <div className="col">
                <div className="title">{bannerDetailsSection.title}</div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xxl-12">
                <div className="main-heading">
                  {bannerDetailsSection.heading}
                </div>
              </div>
            </div>
            <div className="d-flex btnrow mt-5">
              <div className=" clickbtnbluecontain mb-3 mb-sm-0">
                <Link to="athlete/register">
                  <button className="clickbtnblue">SIGN UP</button>
                </Link>
              </div>
              <div className=" calendly-contain  ms-2 ms-md-3">
                {/* <button className='clickbtnwhite' onClick={onClickLearnMore}> LEARN MORE</button> */}
                <PopupWidget
                  url={`https://calendly.com/${calendlyLink}`}
                  /*
                   * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                   * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                   */
                  rootElement={document.getElementById('root')}
                  text={
                    props.deviceSize.width > 450 ? 'BOOK A DEMO' : 'BOOK DEMO'
                  }
                  textColor="#042237"
                  color="#FFF"
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-6 bg-ellipse ">
            {/* <img
              src={girlBannerImg}
              alt="Girl with basketball"
              className="girl-banner-img"
            /> */}
            <img src={circleBg} alt="" className="circle-banner-bg" />
            <div className="bannerImg"></div>
          </div>
        </div>
      </div>
      <div className="row banner-base">
        <div className="col-12 col-sm-12 col-md-10 col-lg-12 col-xl-9 col-xxl-8 bg-arrow justify-content-center d-flex flex-column">
          <div className="base-heading">{bannerDetailsSection.baseHeading}</div>
          <div className="base-text">{bannerDetailsSection.basetexts}</div>
        </div>
      </div>
    </div>
  );
}
