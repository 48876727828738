import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import PasswordInput from '../../../../common/components/PasswordInput';

let schema = yup.object().shape({
  old_password: yup.string().required('Old password is required'),
  new_password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/, // eslint-disable-line
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    )
    .required('New password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
    .required('Please confirm password'),
});

const ChangePassword = (props) => {
  return (
    <div className="mw-770 mx-auto my-md-5 my-3 profilepart-1 ">
      <Formik
        initialValues={{
          old_password: '',
          new_password: '',
          confirmPassword: '',
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          console.log(values);
          const passwordPayload = { old_password: values.old_password, new_password: values.new_password };
          props.resetPassword(passwordPayload);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="mb-4 form-input">
                <h6 className="font-18 mb-2 fw-700">Current password</h6>
                <div className="form-floating form-billing-floating account-form">
                  <PasswordInput
                    name="old_password"
                    changeEvent={handleChange}
                    value={values.old_password}
                    blurEvent={handleBlur}
                    className="input-lg"
                    placeHolder="Old Password"
                  />
                  {/* <input type="email" className="form-control" value={values.email} type="text" name="email" id="floatingInput" onChange={handleChange} placeholder="name@example.com" /> */}
                  <div className="text-danger text-sm">
                    {touched.old_password &&
                      errors.old_password &&
                      errors.old_password}
                  </div>
                </div>
              </div>
              <div className="mb-4 form-input">
                <h6 className="font-18 mb-2 fw-700">New password</h6>
                <div className="form-floating form-billing-floating account-form">
                  <PasswordInput
                    name="new_password"
                    changeEvent={handleChange}
                    value={values.new_password}
                    blurEvent={handleBlur}
                    placeHolder="New Password"
                  />
                  <div className="text-danger text-sm">
                    {touched.new_password &&
                      errors.new_password &&
                      errors.new_password}
                  </div>
                </div>
              </div>
              <div className="mb-4 form-input">
                <h6 className="font-18 mb-2 fw-700">Repeat new password</h6>
                <div className="form-floating form-billing-floating account-form">
                  <PasswordInput
                    name="confirmPassword"
                    changeEvent={handleChange}
                    value={values.confirmPassword}
                    blurEvent={handleBlur}
                    placeHolder="Confirm Password"
                  />
                  <div className="text-danger text-sm">
                    {touched.confirmPassword &&
                      errors.confirmPassword &&
                      errors.confirmPassword}
                  </div>
                </div>
              </div>
              <div className="bottom-pos-btn-container mt-5 mw-570 mx-auto">
                <button type="submit" className="btn clickbtn">
                  SAVE
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
export default ChangePassword;
