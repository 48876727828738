import React, {useState} from 'react';
import {Row, Col, Select, Upload, Popover, Button} from 'antd';
import ImgCrop from 'antd-img-crop';
import * as yup from 'yup';
import {Formik} from 'formik';
import Text from '../../../../../components/Text/Text';
import {backgroundStyle, icons} from '../../../../../utils/staticStrings';
import {UploadAssets} from '../../../../../hooks/UploadImage';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

import {CloseIcon} from '../../../../../utils/SocialIcons';
import {
  CampaignDetailContainer,
  CampaignImageDetail,
  CustomInput,
  CampaignDetail,
  CustomTextArea,
  CustomSelectDropDown,
  DeliverablesContainer,
  Heading,
  CustomAmount,
  FulfillmentDatePicker,
} from './style';
import {PaymentModalFooter} from '../../style';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';
import {CheckoutButton} from '../../../pages/style';
import Loader from 'react-loader-spinner';
import useApi from '../../../../../hooks/useApi';
import {useSelector} from 'react-redux';
import CheckoutCardWrapperOffer from '../../payment/CheckoutCardOffer';

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

let schema = yup.object().shape({
  image_url: yup.string().required('Image required'),
  title: yup.string().required('Please enter title'),
  description: yup.string().required('Please enter description'),
  deliverables: yup.array().of(
    yup.object().shape({
      type: yup.string().required('Deliverable type required'),
      fulfillment_type: yup.string().required('Fulfillment date required'),
      fulfillment_date: yup.string().when('fulfillment_type', {
        is: 'Specific Date' || 'Before Date',
        then: yup.string().required('Date is required'),
      }),
      fulfillment_days: yup
        .string()
        .nullable()
        .when('fulfillment_type', {
          is: 'Within X Days after product received',
          then: yup.string().required('Days is required'),
        }),
    }),
  ),
});

const CampaignDetails = ({
  next,
  type = '',
  campaignForm1,
  athleteDetails,
  submitOffer,
  taggedToCampaign,
  showPayment = false,
  setShowPayment,
  formRef,
}) => {
  const {Option} = Select;
  const [loadimgImageStatus, setLoadingImageStatus] = useState(false);
  const [maxDeliverableErrorMsg, setMaxDeliverableErrorMsg] = useState('');
  const [compensationErrors, setCompensationErrors] = useState(['']);
  const [noCashComp, setNoCashComp] = useState(false);
  const [deliverableTypes] = useApi('/athlete/deliverable-types');
  const [fulfillmentTypes] = useApi('/purchaser/campaign/fullfillment-types');
  const [fulfillmentDays] = useApi('/purchaser/campaign/fullfillment-days');
  const [expirationDays] = useApi('/purchaser/campaign/deal/expiration-days');
  const {purchaserDetails} = useSelector((state) => state.UsersReducer);

  const generateDealTitle = () => {
    return `${athleteDetails?.name} + ${purchaserDetails?.purchaser?.company} : ${campaignForm1?.title}`;
  };

  console.log(campaignForm1.deliverables)
  let initialValues = {
    image_url: campaignForm1?.image_url
      ? campaignForm1?.image_url
      : purchaserDetails?.purchaser?.logo
      ? purchaserDetails?.purchaser?.logo
      : backgroundStyle.defaultProfilePic,
    title:
      type === 'offer' && taggedToCampaign
        ? generateDealTitle()
        : campaignForm1?.title
        ? campaignForm1?.title
        : '',
    description: campaignForm1?.description ? campaignForm1?.description : '',
    deliverables: campaignForm1?.deliverables
      ? campaignForm1?.deliverables
      : [
          {
            type: '',
            compensation_cash: '',
            compensation_product: '',
            fulfillment_type: '',
            fulfillment_date: dayjs().add(1, 'days').format('MM-DD-YYYY'),
          },
        ],
    total_price: campaignForm1?.total_price ? campaignForm1?.total_price : '',
    expiration_period: '14',
  };

  function disabledDate(current) {
    // Can not select days before today
    return current < dayjs().endOf('day');
  }

  const checkForCashCompensation = (values) => {
    let deliverables = values.deliverables;
    let total_cash = 0;
    if (deliverables.length === 1) {
      total_cash = deliverables[0].compensation_cash;
    } else {
      deliverables.forEach((deliverable) => {
        total_cash = total_cash + deliverable.compensation_cash;
      });
    }
    return total_cash;
  };

  const submitForm = (values) => {
    validateCompensation(formRef);
    let compensationValid = compensationErrors.every((error) => {
      return error === '';
    });
    if (compensationValid) {
      values.deliverables.forEach((deliverable) => {
        if (deliverable.compensation_cash === '') {
          deliverable.compensation_cash = 0;
        } else {
          deliverable.compensation_cash = Number(deliverable.compensation_cash);
        }
        if (deliverable.compensation_product === '') {
          deliverable.compensation_product = 0;
        } else {
          deliverable.compensation_product = Number(
            deliverable.compensation_product,
          );
        }
      });
      if (type === 'offer') {
        const total_cash = checkForCashCompensation(values);
        if (total_cash === 0) {
          setNoCashComp(true);
        } else {
          setNoCashComp(false);
        }
        setShowPayment(true);
      } else {
        goToNextTab(values);
      }
    }
  };

  const getSplitOption = (option) => {
    let limit = 40;
    let indexToSplit = 0;
    while (limit > -1) {
      if (option[limit] === ' ') {
        indexToSplit = limit;
        break;
      }
      limit = limit - 1;
    }
    let optionFirst = option.substr(0, indexToSplit);
    let optionSecond = option.substr(indexToSplit);
    return (
      <>
        {optionFirst} <br />
        {optionSecond}
      </>
    );
  };

  const goToNextTab = (values) => {
    next(values);
  };

  const content = (para1, para2) => {
    return (
      <div style={{width: '250px'}}>
        <div className="mt-1">{para1}</div>
        <div className="mt-1">{para2}</div>
      </div>
    );
  };

  const addDeliverable = (deliverables, setFieldValue) => {
    if (deliverables.length < 24) {
      if (maxDeliverableErrorMsg !== '') {
        setMaxDeliverableErrorMsg('');
      }
      const newDeliverable = {
        type: '',
        compensation_cash: '',
        compensation_product: '',
        fulfillment_type: '',
        fulfillment_date: dayjs().add(1, 'days').format('MM-DD-YYYY'),
      };
      let newDeliverables = [...deliverables];
      newDeliverables.push(newDeliverable);
      setFieldValue('deliverables', [...newDeliverables]);
      let compErrors = compensationErrors;
      compErrors.push('');
      setCompensationErrors(compErrors);
    } else {
      setMaxDeliverableErrorMsg('Maximum number of deliverables is 24');
    }
  };

  const removeDeliverable = (deliverables, idx, setFieldValue, formRef) => {
    if (deliverables.length === 1) {
      errorToastMessage(
        'Atleast one deliverable is mandatory to create a campaign',
      );
    } else {
      let newDeliverables = [...deliverables];
      newDeliverables.splice(idx, 1);
      setFieldValue('deliverables', [...newDeliverables]).then((res) => {
        computeTotal(formRef, setFieldValue);
      });
    }
  };

  const validateEachCompensation = (deliverable, idx) => {
    let cash = Number(deliverable.compensation_cash);
    let product = Number(deliverable.compensation_product);
    if (cash === '' && product === '') {
      let compErrors = compensationErrors;
      compErrors[idx] = 'Either cash or product budget is mandatory';
      setCompensationErrors(compErrors);
    } else if (
      (cash === 0 || cash === '') &&
      (product === 0 || product === '')
    ) {
      let compErrors = compensationErrors;
      compErrors[idx] =
        'Either cash or product budget should be greater than zero';
      setCompensationErrors(compErrors);
    } else if (cash < 0 || product < 0) {
      let compErrors = compensationErrors;
      compErrors[idx] = 'Budget should be positive';
      setCompensationErrors(compErrors);
    } else {
      let compErrors = compensationErrors;
      compErrors[idx] = '';
      setCompensationErrors(compErrors);
    }
  };

  const validateCompensation = (formRef, idx = -1) => {
    let deliverables = formRef.current.values.deliverables;
    if (idx === -1) {
      deliverables.forEach((deliverable, idx) => {
        validateEachCompensation(deliverable, idx);
      });
    } else {
      validateEachCompensation(deliverables[idx], idx);
    }
  };

  const computeTotal = (formRef, setFieldValue) => {
    let deliverables = formRef.current.values.deliverables;
    let total = 0;
    deliverables.forEach((deliverable) => {
      if (deliverable?.compensation_cash) {
        total = total + Number(deliverable.compensation_cash);
      }
      if (deliverable?.compensation_product) {
        total = total + Number(deliverable.compensation_product);
      }
    });
    setFieldValue('total_price', total);
  };

  const onCashCompensationChange = (value, idx, setFieldValue, formRef) => {
    setFieldValue(`deliverables[${idx}].compensation_cash`, value).then(
      (res) => {
        validateCompensation(formRef, idx);
        computeTotal(formRef, setFieldValue);
      },
    );
  };

  const onProductCompensationChange = (value, idx, setFieldValue, formRef) => {
    setFieldValue(`deliverables[${idx}].compensation_product`, value).then(
      (res) => {
        validateCompensation(formRef, idx);
        computeTotal(formRef, setFieldValue);
      },
    );
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toastMessage('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 12;
    if (!isLt2M) {
      errorToastMessage('Image must smaller than 12MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadFile = (fileObj, setValue) => {
    const handleUpload = (data) => {
      setValue('image_url', data);
    };
    UploadAssets(fileObj, handleUpload, setLoadingImageStatus)();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={(values) => submitForm(values)}
      innerRef={formRef}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isValid,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {!showPayment && (
              <>
                <CampaignDetailContainer>
                  {type !== 'offer' && (
                    <div className="d-flex w-100 mb-5">
                      <div style={{position: 'relative'}}>
                        <ImgCrop rotateSlider>
                          <Upload
                            beforeUpload={beforeUpload}
                            customRequest={(fileList) =>
                              uploadFile(fileList, setFieldValue)
                            }
                            className="custom-upload"
                            showUploadList={false}
                            name="image_url"
                            maxCount={1}>
                            {values.image_url ? (
                              <div className="position-relative">
                                <img
                                  src={values.image_url}
                                  alt="profilePic"
                                  className="profile-img-holder"
                                />
                                <p className="text-purple mt-3 fw-bold text-center change-image-text">
                                  Change photo
                                </p>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center  flex-column w-100 h-100 ">
                                <img src={icons.cameraIcon} alt="camera Icon" />
                                <p className="text-purple mt-3 fw-bold">
                                  Choose Image
                                </p>
                              </div>
                            )}
                          </Upload>
                        </ImgCrop>
                        {loadimgImageStatus && (
                          <div className="loader text-center">
                            <Loader
                              type="Bars"
                              color="#00BFFF"
                              height={50}
                              width={100}
                            />
                          </div>
                        )}
                        <div className="text-danger text-sm fw-500">
                          {touched.image_url &&
                            errors.image_url &&
                            errors.image_url}
                        </div>
                      </div>
                      <CampaignImageDetail>
                        <Text
                          display="flex"
                          weight="700"
                          size="18"
                          lineHeight="24"
                          textColor="#042237">
                          Campaign photo
                        </Text>
                        <Text
                          display="flex"
                          weight="500"
                          size="15"
                          lineHeight="20"
                          mrTop="10"
                          textColor="#042237">
                          Your brand image is used as the default campaign
                          photo. You can change it by uploading new image.
                        </Text>
                      </CampaignImageDetail>
                    </div>
                  )}
                </CampaignDetailContainer>
                <CampaignDetail
                  className={`mb-4 ${type === 'offer' ? 'mt-4' : ''}`}>
                  <Text
                    display="flex"
                    weight="700"
                    size="18"
                    lineHeight="24"
                    mrBottom="6"
                    className="form-label "
                    textColor="#042237">
                    {`${type === 'offer' ? 'Deal' : 'Campaign'} title`}{' '}
                    <span className="text-danger ms-1"> *</span>
                    {type === 'offer' && taggedToCampaign && (
                      <Popover
                        content={content(
                          'A generic title has been created, but you can modify this title if desired.',
                        )}
                        trigger="hover"
                        placement="right"
                        className="info-icon ms-2 mt-1 cursor-pointer">
                        <span>?</span>
                      </Popover>
                    )}
                  </Text>
                  <CustomInput
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={(e) => {
                      setFieldValue('title', e.target.value);
                    }}
                  />
                  <div className="text-danger text-sm fw-500">
                    {touched.title && errors.title && errors.title}
                  </div>
                </CampaignDetail>
                {type === 'offer' && taggedToCampaign && (
                  <CampaignDetail className="mb-4">
                    <Text
                      display="flex"
                      weight="700"
                      size="18"
                      lineHeight="24"
                      mrBottom="6"
                      className="form-label "
                      textColor="#042237">
                      Tagged Campaign
                    </Text>
                    <CustomInput
                      disabled
                      type="text"
                      name="title"
                      value={campaignForm1?.title}
                    />
                  </CampaignDetail>
                )}
                <CampaignDetail className="mb-4">
                  <Text
                    display="flex"
                    weight="700"
                    size="18"
                    lineHeight="24"
                    className="form-label "
                    mrBottom="6"
                    textColor="#042237">
                    Description <span className="text-danger ms-1"> *</span>
                  </Text>
                  <CustomTextArea
                    rows={5}
                    name="description"
                    value={values.description}
                    onChange={(e) =>
                      setFieldValue('description', e.target.value)
                    }
                  />
                  <div className="text-danger text-sm fw-500">
                    {touched.description &&
                      errors.description &&
                      errors.description}
                  </div>
                </CampaignDetail>
                <CampaignDetail>
                  <Text
                    display="flex"
                    weight="700"
                    size="18"
                    lineHeight="24"
                    className="form-label "
                    mrBottom="6"
                    textColor="#042237">
                    Deliverable(s)
                  </Text>
                  {values.deliverables.map((deliverable, idx, deliverables) => (
                    <DeliverablesContainer key={idx}>
                      <div
                        className="deliverable-close-button"
                        onClick={() => {
                          removeDeliverable(
                            formRef.current.values.deliverables,
                            idx,
                            setFieldValue,
                            formRef,
                          );
                        }}>
                        <CloseIcon />
                      </div>
                      <div className="d-flex flex-column mb-3">
                        <Text
                          display="flex"
                          weight="600"
                          size="16"
                          lineHeight="22"
                          className="form-label "
                          textColor="#435969">
                          Deliverable type
                          <span className="text-danger ms-1"> *</span>
                        </Text>
                        <div className="d-lg-block d-none">
                          <CustomSelectDropDown
                            className="select-dropdown-theme2"
                            value={deliverable.type}
                            name={`deliverables[${idx}].type`}
                            onChange={(value) =>
                              setFieldValue(`deliverables[${idx}].type`, value)
                            }>
                            {deliverableTypes?.data?.map((option) => (
                              <Option
                                value={option}
                                key={option}
                                className="text-transform-none">
                                {option}
                              </Option>
                            ))}
                          </CustomSelectDropDown>
                        </div>
                        <div className="d-block d-lg-none">
                          <CustomSelectDropDown
                            className="select-dropdown-theme2"
                            value={deliverable.type}
                            name={`deliverables[${idx}].type`}
                            onChange={(value) =>
                              setFieldValue(`deliverables[${idx}].type`, value)
                            }>
                            {deliverableTypes?.data?.map((option) => (
                              <Option
                                value={option}
                                key={option}
                                className="text-transform-none">
                                {option.length > 40
                                  ? getSplitOption(option)
                                  : option}
                              </Option>
                            ))}
                          </CustomSelectDropDown>
                        </div>
                        <div className="text-danger text-sm fw-500">
                          {touched?.deliverables &&
                            touched?.deliverables[idx]?.type &&
                            errors?.deliverables &&
                            errors?.deliverables[idx]?.type &&
                            errors?.deliverables[idx]?.type}
                        </div>
                      </div>
                      <div className="d-flex flex-column mb-3">
                        <Text
                          display="flex"
                          weight="600"
                          size="16"
                          lineHeight="22"
                          className="form-label "
                          textColor="#435969">
                          Fulfillment date{' '}
                          <span className="text-danger ms-1"> *</span>
                        </Text>
                        <Row gutter={[16]}>
                          <Col xs={{span: 24}} md={{span: 24}} lg={{span: 24}}>
                            <CustomSelectDropDown
                              value={deliverable.fulfillment_type}
                              name={`deliverables[${idx}].fulfillment_type`}
                              onChange={(value) =>
                                setFieldValue(
                                  `deliverables[${idx}].fulfillment_type`,
                                  value,
                                )
                              }>
                              {fulfillmentTypes?.data?.map((option) => (
                                <Option value={option} key={option}>
                                  {option}
                                </Option>
                              ))}
                            </CustomSelectDropDown>
                            <div className="text-danger text-sm fw-500">
                              {touched?.deliverables &&
                                touched?.deliverables[idx]?.fulfillment_type &&
                                errors?.deliverables &&
                                errors?.deliverables[idx]?.fulfillment_type &&
                                errors?.deliverables[idx]?.fulfillment_type}
                            </div>
                          </Col>
                          <Col
                            xs={{span: 24}}
                            md={{span: 24}}
                            lg={{span: 12}}
                            className="mt-2">
                            {(deliverable.fulfillment_type ===
                              'Specific Date' ||
                              deliverable.fulfillment_type ===
                                'Before Date') && (
                              <>
                                <FulfillmentDatePicker
                                  disabledDate={disabledDate}
                                  name={`deliverables[${idx}].fulfillment_date`}
                                  format="MM/DD/YYYY"
                                  value={deliverable.fulfillment_date ? dayjs(
                                    deliverable.fulfillment_date,
                                    'MM-DD-YYYY',
                                  ) : undefined}
                                  onChange={(date) => {
                                    if (date) {
                                      setFieldValue(
                                        `deliverables[${idx}].fulfillment_date`,
                                        date.format('MM-DD-YYYY'),
                                      );
                                    }
                                  }}></FulfillmentDatePicker>
                                <div className="text-danger text-sm fw-500">
                                  {touched?.deliverables &&
                                    touched?.deliverables[idx]
                                      ?.fulfillment_date &&
                                    errors?.deliverables &&
                                    errors?.deliverables[idx]
                                      ?.fulfillment_date &&
                                    errors?.deliverables[idx]?.fulfillment_date}
                                </div>
                              </>
                            )}
                            {deliverable.fulfillment_type ===
                              'Within X Days after product received' && (
                              <>
                                <CustomSelectDropDown
                                  value={deliverable.fulfillment_days}
                                  name={`deliverables[${idx}].fulfillment_days`}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `deliverables[${idx}].fulfillment_days`,
                                      value,
                                    )
                                  }>
                                  {fulfillmentDays?.data?.map((option) => (
                                    <Option value={option} key={option}>
                                      {option + ' days'}
                                    </Option>
                                  ))}
                                </CustomSelectDropDown>
                                <div className="text-danger text-sm fw-500">
                                  {(touched?.deliverables &&
                                    touched?.deliverables[idx]
                                      ?.fulfillment_days) ||
                                    (errors?.deliverables &&
                                      errors?.deliverables[idx]
                                        ?.fulfillment_days &&
                                      errors?.deliverables[idx]
                                        ?.fulfillment_days)}
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="d-flex flex-column mb-3">
                        <Text
                          display="flex"
                          weight="600"
                          size="16"
                          lineHeight="22"
                          className="form-label "
                          textColor="#435969">
                          {type === 'offer' ? 'Compensation' : 'Budget (max)'}
                          <span className="text-danger ms-1"> *</span>
                          {type === 'offer' ? (
                            <Popover
                              content={content(
                                'Your offer for how much the athlete will be paid in cash or in free product (payment in-kind) upon successful completion of the deal.',
                              )}
                              trigger="hover"
                              placement="right"
                              className="info-icon ms-2 cursor-pointer">
                              <span>?</span>
                            </Popover>
                          ) : (
                            <Popover
                              content={content(
                                'What values you set here do not constitute any type of formal offer or obligation to ultimately complete any deal(s) at this level of compensation.',
                                'This is strictly to convey to prospective athletes what your potential maximum budget is in order to bring more efficiency to athlete/brand partnership process.',
                              )}
                              trigger="hover"
                              placement="right"
                              className="info-icon ms-2 cursor-pointer">
                              <span>?</span>
                            </Popover>
                          )}
                        </Text>
                        <Row gutter={[16]}>
                          <Col xs={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                            <CustomAmount>
                              <Text
                                display="flex"
                                weight="500"
                                size="15"
                                lineHeight="25"
                                opacity="0.7"
                                textColor="#435969">
                                {`Cash ${type === 'offer' ? '' : '(up to)'}`}
                              </Text>
                              <Text
                                display="flex"
                                weight="500"
                                size="18"
                                lineHeight="25"
                                textColor="#042237">
                                ${' '}
                                <input
                                  type="number"
                                  min="0"
                                  max="1000000000"
                                  className="compensation_input"
                                  value={deliverable.compensation_cash}
                                  name={`deliverables[${idx}].compensation_cash`}
                                  onChange={(e) =>
                                    onCashCompensationChange(
                                      e.target.value.replace(/^0+/, ''),
                                      idx,
                                      setFieldValue,
                                      formRef,
                                    )
                                  }
                                />
                              </Text>
                            </CustomAmount>
                          </Col>
                          <Col xs={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                            <CustomAmount>
                              <Text
                                display="flex"
                                weight="500"
                                size="15"
                                lineHeight="25"
                                opacity="0.7"
                                textColor="#435969">
                                {`Products ${
                                  type === 'offer' ? '' : '(up to)'
                                }`}
                              </Text>
                              <Text
                                display="flex"
                                weight="500"
                                size="18"
                                lineHeight="25"
                                textColor="#042237">
                                $
                                <input
                                  type="number"
                                  min="0"
                                  max="1000000000"
                                  className="compensation_input"
                                  value={deliverable.compensation_product}
                                  name={`deliverables[${idx}].compensation_product`}
                                  onChange={(e) =>
                                    onProductCompensationChange(
                                      e.target.value.replace(/^0+/, ''),
                                      idx,
                                      setFieldValue,
                                      formRef,
                                    )
                                  }
                                />
                              </Text>
                            </CustomAmount>
                          </Col>
                        </Row>
                        <div className="text-danger text-sm fw-500">
                          {compensationErrors[idx]}
                        </div>
                      </div>
                      <Heading>
                        <li>
                          Compensation can be in Cash, Products or Both. For
                          product compensation add the estimated value of the
                          product
                        </li>
                      </Heading>
                    </DeliverablesContainer>
                  ))}
                  <div
                    className="d-flex justify-content-center align-items-center my-4"
                    onClick={() => {
                      addDeliverable(
                        formRef.current.values.deliverables,
                        setFieldValue,
                      );
                    }}>
                    <Text
                      display="flex"
                      padding="10"
                      cursor="pointer"
                      weight="700"
                      size="18"
                      lineHeight="25"
                      textColor="#7741FE">
                      + Add additional deliverable
                    </Text>
                  </div>
                  <div className="text-danger text-sm fw-500">
                    {maxDeliverableErrorMsg !== '' && maxDeliverableErrorMsg}
                  </div>
                </CampaignDetail>
                <CampaignDetail className="my-5">
                  <CustomAmount bgColor="#F2F5F7">
                    <Text
                      display="flex"
                      weight="500"
                      size="18"
                      lineHeight="25"
                      opacity="0.7"
                      textColor="#042237">
                      {type === 'offer'
                        ? 'Total compensation offered'
                        : 'Total Amount'}
                    </Text>
                    <Text
                      display="flex"
                      weight="500"
                      size="18"
                      lineHeight="25"
                      textColor="#042237">
                      ${' '}
                      <input
                        min="0"
                        max="1000000000"
                        className="compensation_input"
                        type="number"
                        value={values.total_price}
                        name="total_price"
                        disabled
                      />
                    </Text>
                  </CustomAmount>
                </CampaignDetail>
                {type === 'offer' && (
                  <CampaignDetail className="mb-5">
                    <Text
                      display="flex"
                      weight="700"
                      size="18"
                      lineHeight="24"
                      mrBottom="6"
                      className="form-label "
                      textColor="#042237">
                      Offer expiration
                      <span className="text-danger ms-1"> *</span>
                      <Popover
                        content={content(
                          'The number of days before unresponded offers will automatically expire.',
                        )}
                        trigger="hover"
                        placement="right"
                        className="info-icon ms-2 mt-1 cursor-pointer">
                        <span>?</span>
                      </Popover>
                    </Text>
                    <CustomSelectDropDown
                      value={values.expiration_period}
                      name="expiration_period"
                      onChange={(value) =>
                        setFieldValue('expiration_period', value)
                      }>
                      {expirationDays?.data?.map((option) => (
                        <Option value={option} key={option}>
                          {`${option} days`}
                        </Option>
                      ))}
                    </CustomSelectDropDown>
                  </CampaignDetail>
                )}
              </>
            )}
            {type === 'offer' &&
              showPayment &&
              (noCashComp ? (
                <CampaignDetail>
                  <div className="mt-3 mb-5">
                    <Text
                      display="flex"
                      weight="700"
                      size="18"
                      lineHeight="24"
                      mrBottom="15"
                      mrTop="20"
                      className="form-label "
                      textColor="#042237">
                      This offer has only product compensation. No payment
                      details required.
                    </Text>
                  </div>
                  <div className="checkout-payment-form-button-container">
                    <Button
                      type="primary"
                      className="btn btn-save-var2 btn-lg mb-4"
                      onClick={() => {
                        submitOffer(values, campaignForm1?.id);
                      }}>
                      SUBMIT OFFER
                    </Button>
                  </div>
                </CampaignDetail>
              ) : (
                <CampaignDetail>
                  <Text
                    display="flex"
                    weight="700"
                    size="18"
                    lineHeight="24"
                    mrBottom="15"
                    mrTop="20"
                    className="form-label "
                    textColor="#042237">
                    Assign payment method
                  </Text>
                  <CheckoutCardWrapperOffer
                    campaignForm1={campaignForm1}
                    values={values}
                    submitOffer={submitOffer}></CheckoutCardWrapperOffer>
                </CampaignDetail>
              ))}
            {!showPayment && (
              <PaymentModalFooter>
                <CheckoutButton type="submit">
                  <Text
                    weight="800"
                    size="18"
                    lineHeight="23"
                    textColor="#FFFFFF"
                    cursor="pointer">
                    {type === 'offer' && 'SAVE & CONTINUE'}
                    {type !== 'offer' && 'NEXT'}
                  </Text>
                </CheckoutButton>
              </PaymentModalFooter>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default CampaignDetails;
