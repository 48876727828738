import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../../../../store/athleteflow/action';
import AppLayout from '../../../../../common/AppLayout';
import Stats from './Stats';
import { List, Avatar } from 'antd';
import ProfileSettings from '../../../../../common/components/ProfileSettings';

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const loadMore = useRef();

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetch(
      'https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo',
    )
      .then((res) => res.json())
      .then((body) => {
        setData([...data, ...body.results]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  loadMore.current = loadMoreData;

  useEffect(() => {
    loadMore.current();
  }, []);

  useEffect(() => {
    if (history.location.pathname === '/athlete/dashboard') {
      dispatch(setHeader(false));
      // triggerModal('success', 'Login success');
    }
  }, [history.location.pathname, dispatch]);

  return (
    <AppLayout>
      <div className="bubble-bg"></div>

      <div className="content-wrapper">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h1 className="header">Welcome Ruud!</h1>
            <p className="font-20 color-muted mb-5">
              You have<span className="text-purple">3</span> new orders and
              <span className="text-purple">2</span> deals to review.
            </p>
          </div>
          <div className="d-none d-md-block">
            <ProfileSettings />
          </div>
        </div>
        <Stats />
        <div className="container-fluid my-5">
          <div className="row">
            <div className="col-xl-6 col-12 mb-4">
              <h6 className="font-m fw-bold color-muted text-uppercase ls-m mb-3">
                New orders
              </h6>
              <div
                id="scrollableDiv"
                className="rouded  custom-listing  px-4 py-2"
                style={{
                  height: 400,
                  overflow: 'auto',
                  padding: '0 16px',
                  borderRadius: '20px',
                  boxShadow: ' 0px 27px 90px rgba(0, 0, 0, 0.07)',
                }}>
                <List
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        avatar={<Avatar src={item.picture.large} />}
                        title={
                          <a href="https://ant.design">{item.name.last}</a>
                        }
                        description={item.email}
                      />
                      <div className="text-muted">Content</div>
                    </List.Item>
                  )}
                />
              </div>
            </div>
            <div className="col-xl-6 col-12">
              <h6 className="font-m fw-bold color-muted text-uppercase ls-m mb-3">
                Top Listings
              </h6>
              <div
                id="scrollableDiv"
                className="rouded px-4 py-2 custom-listing"
                style={{
                  height: 400,
                  overflow: 'auto',
                  padding: '0 16px',
                  borderRadius: '20px',
                  boxShadow: ' 0px 27px 90px rgba(0, 0, 0, 0.07)',
                }}>
                <List
                  header={
                    <div className="d-flex  font-m">
                      <div>Listing</div>
                      <div className="ms-3">Description</div>
                      <div className="ms-auto">Orderss</div>
                    </div>
                  }
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        avatar={<Avatar src={item.picture.large} />}
                        title={
                          <a href="https://ant.design">{item.name.last}</a>
                        }
                        description={item.email}
                      />
                      <div className="text-muted">Content</div>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Dashboard;
