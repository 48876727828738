import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import {useSelector, useDispatch} from 'react-redux';
import {loginSection} from '../../../../../utils/staticStrings';
import {submitForgotPassword} from '../../../../../store/athleteflow/action';
import * as yup from 'yup';
import '../features.scss';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';

let schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid Email ID.')
    .required('Email ID is Required'),
});

const ForgotPassword = () => {
  const {message} = useSelector((state) => state.AthleteModuleReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (message.text.success === true) {
      toastMessage(message.text.data, 0);
    }
    if (message.text.success === false) {
      errorToastMessage(message.text.message);
    }
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  const forgotPassword = async (values, setSubmitting) => {
    try {
      setSubmitting(true);
      dispatch(submitForgotPassword(values));
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      // console.log(err, "OOOOO")
    }
  };

  return (
    <div className="login-main-bg">
      <div className="padding-main bg-white">
        <div className="login-main">
          <div className="row login-section">
            <div className="col-12 col-sm-10 col-md-10 col-lg-5 col-xl-5 col-xxl-6 me-lg-3">
              <div className="auth-holder mx-auto">
                <div className="login-title mb-3">Forgot password?</div>
                <div className="login-texts mb-5">
                  Enter your email to reset your password
                </div>
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={schema}
                  onSubmit={(values, {setSubmitting}) => {
                    forgotPassword(values, setSubmitting);
                  }}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            value={values.email}
                            name="email"
                            id="floatingInput"
                            onChange={handleChange}
                            placeholder="name@example.com"
                          />
                          <label htmlFor="floatingInput">Email address</label>
                          <div className="text-danger text-sm">
                            {touched.email && errors.email && errors.email}
                          </div>
                        </div>
                        <div className="bottom-pos-btn-container mt-0">
                          <button type="submit" className="btn clickbtn">
                            RESET PASSWORD
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
                <div className="py-4">
                  <Link
                    to="/login"
                    className="d-flex text-muted text-center justify-content-center no-underline ms-3">
                    Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-10 col-lg-6 col-xl-6 col-xxl-5 mt-5 mt-sm-0 d-none d-md-block">
              <div className="bg ms-0 ms-xl-5 mt-5 mt-xl-0">
                <img
                  className="boy-image"
                  src={loginSection.imgrightbg}
                  alt="mikebg"
                />
                <div className="info-block">
                  <div className="nameage">{loginSection.nameage}</div>
                  <div className="playertitle">{loginSection.playertitle}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
