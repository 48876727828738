import React, {useEffect, useState} from 'react';
import {Select} from 'antd';
import ProfileImage from '../../../components/ProfileImage';
import Text from '../../../../../components/Text/Text';
import moment from 'moment';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import {
  CampaignHeading,
  CampaignContainer,
  CampaignDetails,
  CampaignsCountDetails,
  LayoutDesktopView,
  LayoutMobileView,
} from './style';
import './styles.scss';
import {CustomDivider} from '../../../pages/style';
import {Person} from '../../../helpers/icons';
import {useDispatch} from 'react-redux';
import {handleActivateOrPauseCampaign} from '../../../../../store/brandflow/action';
import {useHistory} from 'react-router-dom';
import {formatPriceCommas} from '../../../../../helpers/user';

const ShopCard = ({
  campaignData,
  openEditCampaign,
  campaignCardClick,
  handleOpenArchive,
}) => {
  const {Option} = Select;
  const [totalCashCompensation, setTotalCashCompensation] = useState(0);
  const [totalProductCompensation, setTotalProductCompensation] = useState(0);
  const [selectClassname, setSelectClassname] = useState('');
  const [createdDate, setCreatedDate] = useState('');
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let totalCashComp = 0;
    let totalProductComp = 0;
    campaignData?.deliverables.forEach((deliverable) => {
      totalCashComp = totalCashComp + deliverable?.compensation_cash;
      totalProductComp = totalProductComp + deliverable?.compensation_product;
    });
    setTotalCashCompensation(totalCashComp);
    setTotalProductCompensation(totalProductComp);
  }, [campaignData?.deliverables]);

  useEffect(() => {
    let createdAtDate = moment(campaignData?.createdAt).format('MMM DD, YYYY');
    setCreatedDate(createdAtDate);
  }, [campaignData?.createdAt]);

  useEffect(() => {
    setStatus(campaignData.status);
    if (campaignData.status === 'active') {
      setSelectClassname('dropdown-success');
    } else if (campaignData.status === 'inactive') {
      setSelectClassname('dropdown-danger');
    }
  }, [campaignData?.status]);

  const handleChange = (value) => {
    if (value === 'archived') {
      handleOpenArchive(campaignData);
    } else {
      setStatus(value);
      const payload = {
        apiPayload: {
          status: value,
        },
        id: campaignData.id,
      };
      if (value === 'active') {
        setSelectClassname('dropdown-success');
      } else if (value === 'inactive') {
        setSelectClassname('dropdown-danger');
      }
      dispatch(handleActivateOrPauseCampaign(payload));
    }
  };

  return (
    <div className="custom-card card-theme-1 mb-3">
      <div className="d-xl-flex d-block">
        <div className="d-flex justify-content-between me-lg-3">
          <ProfileImage
            width={50}
            height={70}
            src={campaignData?.image_url}
            desktopImgWidth={50}
            mobileImgWidth={70}
            className="purchaser radius-m cursor-pointer"
            onClick={campaignCardClick}
          />
          <div className="card-button-group d-xl-none mt-3 mt-md-0 justify-content-between ">
            <div className="d-flex">
              {campaignData?.status !== 'archived' && (
                <>
                  <Select
                    defaultValue={campaignData.status}
                    value={status}
                    onChange={handleChange}
                    className={`me-2 ${selectClassname} mobile-select`}>
                    <Option value="active">Active</Option>
                    <Option value="inactive">Paused</Option>
                    <Option value="archived">Move to Archive</Option>
                  </Select>
                  <button className="active-dropdown-button-outline mobile-edit">
                    <i
                      className="icon-edit"
                      onClick={() => {
                        history.push(`/purchaser/campaigns/edit`, {
                          campaignData: campaignData,
                        });
                      }}></i>
                  </button>
                </>
              )}
              {campaignData?.status === 'archived' && (
                <div className="me-2 archived-label">Archived</div>
              )}
            </div>
          </div>
        </div>

        <CampaignContainer className="d-flex w-100" onClick={campaignCardClick}>
          <CampaignHeading>
            <Text
              display="flex heading"
              weight="700"
              cursor="pointer"
              size="24"
              lineHeight="32"
              mdSize="20"
              smLineHeight="30"
              textColor="#042237">
              {campaignData?.title}
            </Text>
            <LayoutDesktopView>
              <Text
                display="flex"
                cursor="pointer"
                weight="800"
                size="26"
                mdSize="20"
                lineHeight="32"
                textColor="#042237">
                Up to ${formatPriceCommas(campaignData?.total_price)}
              </Text>
            </LayoutDesktopView>
          </CampaignHeading>
          <CampaignDetails>
            {campaignData?.type === 'private' && (
              <img className="me-1" src={backgroundStyle?.lock} alt={'lock'} />
            )}
            {campaignData?.type === 'open' && (
              <div className="me-1">
                <Person />
              </div>
            )}
            <Text
              display="flex"
              cursor="pointer"
              weight="500"
              size="15"
              lineHeight="20"
              smSize="14"
              smLineHeight="19"
              textColor="#042237">
              <span className="me-2 d-flex">
                <LayoutDesktopView className="cursor-pointer titlecase">
                  {campaignData?.type} Campaign
                </LayoutDesktopView>
                <LayoutDesktopView>(#{campaignData?.id})</LayoutDesktopView>
                <LayoutMobileView>#{campaignData?.id}</LayoutMobileView>
              </span>
              |
              <span className="ms-2 d-flex">
                <LayoutDesktopView>
                  {`Created on ${createdDate}`}{' '}
                </LayoutDesktopView>
                <LayoutMobileView>{createdDate}</LayoutMobileView>
              </span>
            </Text>
          </CampaignDetails>
          <LayoutMobileView>
            <Text
              display="flex"
              weight="700"
              size="24"
              mdSize="20"
              lineHeight="32"
              cursor="pointer"
              textColor="#042237">
              Up to ${formatPriceCommas(campaignData?.total_price)}
            </Text>
          </LayoutMobileView>
          <LayoutDesktopView>
            <CustomDivider />
          </LayoutDesktopView>
          <CampaignsCountDetails>
            <div className="d-flex count">
              <Text
                display="flex "
                weight="400"
                size="18"
                mdSize="18"
                lineHeight="26"
                smSize="17"
                cursor="pointer"
                textColor="#042237">
                Invites sent :
              </Text>
              <Text
                display="flex "
                weight="600"
                size="18"
                mdSize="17"
                lineHeight="26"
                smSize="17"
                cursor="pointer"
                textColor="#042237">
                {campaignData?.stats?.invites_sent}
              </Text>
            </div>
            <div className="d-flex count">
              <Text
                display="flex"
                weight="400"
                size="18"
                mdSize="17"
                cursor="pointer"
                lineHeight="26"
                smSize="17"
                textColor="#042237">
                Applications received :
              </Text>
              <Text
                display="flex"
                weight="600"
                size="18"
                mdSize="17"
                lineHeight="26"
                smSize="17"
                textColor="#042237">
                {campaignData?.stats?.applications}
              </Text>
            </div>
            <div className="d-flex count">
              <Text
                display="flex"
                weight="400"
                size="18"
                mdSize="17"
                lineHeight="26"
                cursor="pointer"
                smSize="17"
                textColor="#042237">
                Deals reached :
              </Text>
              <Text
                display="flex"
                weight="600"
                size="18"
                mdSize="17"
                lineHeight="26"
                smSize="17"
                cursor="pointer"
                textColor="#042237">
                {campaignData?.stats?.reached}
              </Text>
            </div>
          </CampaignsCountDetails>
          <LayoutDesktopView>
            <CustomDivider />
          </LayoutDesktopView>
          <LayoutDesktopView>
            <Text
              display="flex"
              weight="400"
              size="18"
              cursor="pointer"
              lineHeight="26"
              smSize="17"
              textColor="#042237">
              Compensation: Up to $
              {formatPriceCommas(campaignData?.total_price)} in Cash ($
              {formatPriceCommas(totalCashCompensation)}) + Products ($
              {formatPriceCommas(totalProductCompensation)})
            </Text>
          </LayoutDesktopView>
          <LayoutMobileView>
            <Text
              display="flex"
              weight="400"
              size="18"
              cursor="pointer"
              lineHeight="26"
              smSize="17"
              textColor="#042237">
              {`Cash ($${formatPriceCommas(
                totalCashCompensation,
              )}) + Products ($${formatPriceCommas(totalProductCompensation)})`}
            </Text>
          </LayoutMobileView>
        </CampaignContainer>

        <div className="card-button-group d-xl-flex align-items-center d-none">
          <div>
            {campaignData?.status !== 'archived' && (
              <>
                <Select
                  defaultValue={campaignData.status}
                  value={status}
                  onChange={handleChange}
                  className={`mb-3 me-0 ${selectClassname}`}>
                  <Option value="active">Active</Option>
                  <Option value="inactive">Paused</Option>
                  <Option value="archived">Move to Archive</Option>
                </Select>
                <button className="btn-outline" onClick={openEditCampaign}>
                  <i className="icon-edit"></i> Edit
                </button>
              </>
            )}
            {campaignData?.status === 'archived' && (
              <div className="me-2 archived-label">Archived</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCard;
