import React, {useState, useEffect} from 'react';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import AudioImage from '../../../src/assets/images/Audio.svg';
import PdfImage from '../../../src/assets/images/PDF.svg';
import Attachment from '../../../src/assets/images/attachment.svg';

const MessageMediaUI = ({
  messageList,
  participantsDetails,
  type,
  channelProxy,
  setMessageRead,
}) => {
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaName, setMediaName] = useState(null);

  let owner =
    messageList?.author === 'system'
      ? messageList?.author
      : parseInt(messageList.author.match(/\d+/)[0]);
  let profileUser = participantsDetails?.users.find(
    (item) => item.id === owner,
  );

  useEffect(() => {
    channelProxy.setAllMessagesConsumed().then((res) => {
      if (setMessageRead && res === 0) {
        setMessageRead(channelProxy.sid);
      }
    });
  }, [channelProxy, setMessageRead]);

  useEffect(() => {
    let messageMedia = true;

    if (messageMedia && messageList.type === 'media' && mediaUrl === null) {
      messageList.media.getContentTemporaryUrl().then((url) => {
        setMediaUrl(url);
      });

      setMediaName(messageList?.media?.filename);
    }
    return () => {
      messageMedia = false;
    };
    /* eslint-disable-next-line */
  }, [messageList.media]);

  let contentType = messageList?.media?.contentType;

  const renderMultimedia = (media) => {
    switch (media) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
      case 'image/svg+xml':
        return (
          <div className="media-placeholder d-flex align-items-center bg-light radius-m px-2 px-md-4 py-2 mt-3">
            <div
              className="preview-icon icon-m"
              style={{
                backgroundImage: `url(${mediaUrl})`,
                backgroundSize: 'cover',
                borderRadius: '6px',
              }}></div>
            <div className="fw-600 ms-1 ms-lg-3 text-truncate text-dark">
              {mediaName}
            </div>
            <a
              className="fw-bold text-purple ms-auto"
              download="message-doc"
              target="_blank"
              rel="noreferrer noopener"
              href={mediaUrl}>
              Download
            </a>
          </div>
        );
      case 'application/pdf':
        return (
          <div className="media-placeholder d-flex align-items-center bg-light radius-m px-2 px-md-4 py-2 mt-3">
            <div
              className="preview-icon"
              style={{
                backgroundImage: `url(${PdfImage})`,
              }}></div>
            <div className="fw-600 ms-1 ms-lg-3 text-truncate text-dark">
              {mediaName}
            </div>
            <a
              className="fw-bold text-purple ms-auto"
              download="message-doc"
              target="_blank"
              rel="noreferrer noopener"
              href={mediaUrl}>
              Download
            </a>
          </div>
        );

      case 'audio/aac':
      case 'audio/mpeg':
      case 'audio/mp3':
      case 'audio/wav':
      case 'audio/flac':
      case 'audio/x-m4a':
      case 'audio/x-ms-wma':
        return (
          <div className="media-placeholder d-flex align-items-center bg-light radius-m px-2 px-md-4 py-2 mt-3">
            <div
              className="preview-icon"
              style={{
                backgroundImage: `url(${AudioImage}) `,
              }}></div>
            <div className="fw-600 ms-1 ms-lg-3 text-truncate text-dark">
              {mediaName}
            </div>
            <a
              className="fw-bold text-purple ms-auto"
              download="message-doc"
              target="_blank"
              rel="noreferrer noopener"
              href={mediaUrl}>
              Download
            </a>
          </div>
        );

      case 'video/mp4':
        return (
          <>
            <div className="media-placeholder d-flex align-items-center bg-light radius-m px-2 px-md-4 py-2 mt-3">
              <video>
                <source src={mediaUrl} type="video/mp4" />{' '}
              </video>
              <div className="fw-600 ms-1 ms-lg-3 text-truncate text-dark">
                {mediaName}
              </div>
              <a
                className="fw-bold text-purple ms-auto"
                download="message-doc"
                target="_blank"
                rel="noreferrer noopener"
                href={mediaUrl}>
                Download
              </a>
            </div>
          </>
        );
      default:
        return (
          <div className="media-placeholder d-flex align-items-center bg-light radius-m px-2 px-md-4 py-2 mt-3">
            <div
              className="preview-icon"
              style={{
                backgroundImage: `url(${Attachment})`,
              }}></div>
            <div className="fw-600 ms-1 ms-lg-3 text-truncate text-dark">
              {mediaName}
            </div>
            <a
              className="fw-bold text-purple ms-auto"
              download="message-doc"
              target="_blank"
              rel="noreferrer noopener"
              href={mediaUrl}>
              Download
            </a>
          </div>
        );
    }
  };

  let profileImage;

  if (profileUser?.purchaser?.logo) {
    profileImage = profileUser?.purchaser?.logo;
  } else if (profileUser?.image_url !== null) {
    profileImage = profileUser?.image_url;
  } else {
    profileImage = '/images/NoProfile_Gray_Logo.png';
  }

  return (
    <div className="d-flex message-channel-list">
      <img
        width="50px"
        src={profileImage ? profileImage : '/images/NoProfile_Gray_Logo.png'}
        className="proile-message radius-m"
        alt="starIcon"
      />

      <div className="ms-3 flex-grow-1">
        <h4 className="fw-600 font-m mb-2 d-flex justify-content-between align-items-center">
          <div className="font-m">
            {profileUser?.purchaser !== null ? (
              <>
                <span className="text-capitalize">
                  {profileUser?.purchaser?.company}
                </span>
                {profileUser?.purchaser?.company === '' ||
                profileUser?.purchaser?.company === null ? (
                  <span className="text-capitalize">{profileUser?.name}</span>
                ) : (
                  <span className="text-capitalize">({profileUser?.name})</span>
                )}
              </>
            ) : (
              <> {profileUser?.name}</>
            )}
          </div>
          <div className="fw-300 font-xs text-muted">
            {moment(messageList.dateCreated).format(' MMM Do, h:mm a')}
          </div>
        </h4>
        {messageList.type === 'media' && mediaUrl ? (
          <div>
            {!mediaUrl ? (
              <Loader type="Bars" color="#00BFFF" height={20} width={20} />
            ) : (
              <>{renderMultimedia(contentType)}</>
            )}
            {/* {mediaDownloadFailed && (
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <p>Failed to load media</p>
              </div>
            )} */}
          </div>
        ) : null}
        <div className="color-primary-dark m-0 font-m">
          {Object.keys(messageList?.attributes).length === 0 ? (
            <> {messageList.body}</>
          ) : (
            <div className="d-block text-dark fw-400">
              <div className="btn-icon-video btn me-2"></div>
              {profileUser?.name} is inviting you to join a video call happening
              now
              <a
                href={messageList?.attributes}
                target="_blank"
                rel="noreferrer"
                className="btn bg-purple ms-2 text-white fw-700 font-s cursor-pointer radius-l">
                JOIN CALL
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageMediaUI;
