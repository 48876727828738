import React from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {CheckoutTextArea} from './style';
import {Select} from 'antd';

const BillingForm = ({
  setAddressDetail,
  setInscription,
  cardListProduct,
  countries,
  formRef,
  getStateList,
  stateList,
}) => {
  let schema = yup.object().shape({
    fname: yup.string().required('First name required'),
    lname: yup.string().required('Last name required'),
    street1: yup.string().required('Address line1 required'),
    city: yup.string().required('City required'),
    state: yup.string().required('State required'),
    country: yup.string().required('Country required'),
    inscription:
      cardListProduct?.purchaser_requirements &&
      yup.string().required('Please add requirements'),
    // zip: yup.string().required('Zip code required'),
  });

  let paymentPayload;
  if (paymentPayload) {
    JSON.parse(localStorage.getItem('paymentPayload'));
  }

  return (
    <Formik
      initialValues={{
        fname: '',
        lname: '',
        street1: '',
        street2: '',
        state: '',
        country: 'US',
        zip: '',
        inscription: '',
        city: '',
      }}
      enableReinitialize
      innerRef={formRef}
      validationSchema={schema}
      onSubmit={(values) => {
        let billingPayload = {
          purchaser_name: values?.fname + ' ' + values?.lname,
          street1: values?.street1,
          ...(values?.street2 && {
            street2: values?.street2,
          }),
          state: values?.state,
          country: values?.country,
          zip: values?.zip ? values?.zip : '00000',
          city: values?.city,
        };
        setInscription(values?.inscription);
        setAddressDetail(billingPayload);
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        submitForm,
      }) => {
        return (
          <form onSubmit={handleSubmit} className="m-0">
            {cardListProduct?.purchaser_requirements && (
              <div className="box-checkout p-3 p-md-4">
                <p className="text-muted font-m">
                  Please provide the following additional information.
                </p>
                <div className="d-flex flex-column mt-4 ">
                  <h6 className="fw-700 mb-3">Additional Order Details</h6>
                </div>

                <div className="d-flex flex-column  mb-4">
                  <span className="form-label d-block">
                    {cardListProduct?.purchaser_requirements
                      ? cardListProduct?.purchaser_requirements
                      : ' Please provide custom text for inscription'}
                    <span className="text-danger"> *</span>
                  </span>
                  <CheckoutTextArea
                    value={values.inscription}
                    onChange={(e) =>
                      setFieldValue('inscription', e.target.value)
                    }
                    placeholder="Add requirements"
                    autoSize={{minRows: 3, maxRows: 5}}
                  />
                  <div className="text-danger text-sm fw-700">
                    {touched.inscription &&
                      errors.inscription &&
                      errors.inscription}
                  </div>
                </div>
              </div>
            )}

            <div className="box-checkout p-3 p-md-4">
              <div className="d-flex flex-column mt-4 ">
                <h6 className="fw-700 mb-3">Shipping Details</h6>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <span className="form-label d-block">
                      First Name
                      <span className="text-danger">*</span>
                    </span>
                    <input
                      className="form-control input-lg"
                      value={values.fname}
                      type="text"
                      name="fname"
                      placeholder="First Name"
                      onChange={handleChange}
                    />

                    <div className="text-danger text-sm fw-700">
                      {touched.fname && errors.fname && errors.fname}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <span className="form-label d-block">
                      Last Name
                      <span className="text-danger">*</span>
                    </span>
                    <input
                      className="form-control input-lg"
                      value={values.lname}
                      type="text"
                      name="lname"
                      placeholder="Last Name"
                      onChange={handleChange}
                    />

                    <div className="text-danger text-sm fw-700">
                      {touched.lname && errors.lname && errors.lname}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <span className="form-label d-block">
                    Country <span className="text-danger">*</span>
                  </span>
                  <Select
                    className="custom-select custom-select-light size-l "
                    name="country"
                    value={values.country}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      setFieldValue('country', value);
                      getStateList(value);
                      setFieldValue('state', '');
                    }}>
                    {countries !== undefined && (
                      <>
                        {countries.map((option) => (
                          <Select.Option
                            value={option.isoCode}
                            key={option.name}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </>
                    )}
                  </Select>

                  <div className="text-danger text-sm fw-700">
                    {touched.country && errors.country && errors.country}
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <span className="form-label d-block">
                      Street / Address line 1
                      <span className="text-danger">*</span>
                    </span>
                    <input
                      className="form-control input-lg"
                      value={values.street1}
                      type="text"
                      name="street1"
                      placeholder="Street"
                      onChange={handleChange}
                    />

                    <div className="text-danger text-sm fw-700">
                      {touched.street1 && errors.street1 && errors.street1}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <span className="form-label d-block">Address line 2</span>
                    <input
                      className="form-control input-lg"
                      value={values.street2}
                      type="text"
                      name="street2"
                      placeholder="Apt / Suite / Unit #"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <span className="form-label d-block">
                    City <span className="text-danger">*</span>
                  </span>
                  <input
                    className="form-control input-lg"
                    value={values.city}
                    type="text"
                    name="city"
                    placeholder="City"
                    onChange={handleChange}
                  />

                  <div className="text-danger text-sm fw-700">
                    {touched.city && errors.city && errors.city}
                  </div>
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <div className="color-muted fw-bold mb-2">
                    State
                    <span className="text-danger"> *</span>
                  </div>
                  {values.country === 'US' || values.country === 'CA' ? (
                    <Select
                      className="custom-select custom-select-light size-l "
                      name="state"
                      value={values.state}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        setFieldValue('state', value);
                      }}>
                      <>
                        {stateList.map((option, i) => (
                          <Select.Option value={option.state} key={i}>
                            {option.state}
                          </Select.Option>
                        ))}
                      </>
                    </Select>
                  ) : (
                    <input
                      className="input-custom-var2 form-control"
                      name="state"
                      id="state"
                      value={values.state}
                      onChange={(e) => {
                        setFieldValue('state', e.target.value);
                      }}
                    />
                  )}

                  <div className="text-danger text-sm fw-700">
                    {touched.state && errors.state && errors.state}
                  </div>
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <span className="form-label d-block">
                    Zip code / Postal code
                    {/* <span className="text-danger">*</span> */}
                  </span>
                  <input
                    className="form-control input-lg"
                    value={values.zip}
                    type="text"
                    placeholder="Zip code"
                    name="zip"
                    onChange={(e) => {
                      setFieldValue('zip', e.target.value);
                    }}
                  />
                  {/* <div className="text-danger text-sm fw-700">
                    {touched.zip && errors.zip && errors.zip}
                  </div> */}
                </div>
              </div>
              {/* 
              <button className="btn btn-save-var2" type="submit">
                Save
              </button> */}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default BillingForm;
