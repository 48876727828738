import React from 'react';
import AddSocialAccount from '../../../../modules/Athlete/components/features/Profile/AddSocialAccount';

const SocialProfiles = (props) => {
  const {accountData, editProfileSubmit} = props;

  const profileSocialFormSubmit = (values) => {
    editProfileSubmit({...values, name: accountData?.name});
    // upadateActiveKey('5');
  };
  return (
    <div className="mw-770 mx-auto my-md-5 my-3 profilepart-1">
      <AddSocialAccount
        // upadateActiveKey={props.upadateActiveKey}
        profileData={accountData}
        mode="edit"
        profileSocialFormSubmit={profileSocialFormSubmit}
      />
    </div>
  );
};
export default SocialProfiles;
