import React, {useEffect} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {
  submitBrandRegister,
  handleError,
  handlerRegisterSuccess,
} from '../../../../../store/brandflow/action';
import {registerAsAthlete} from '../../../../../utils/staticStrings';
import {useDispatch, useSelector} from 'react-redux';
import PasswordStrength from '../../../../../common/components/PasswordStrength';
import './register.scss';
import {toastMessage} from '../../../../../helpers/toastMessage';

let schema = yup.object().shape({
  fname: yup.string().required('First name is Required'),
  lname: yup.string().required('Last name is Required'),
  email: yup
    .string()
    .email('Please enter a valid Email ID.')
    .required('Email ID is Required'),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\\/;':"-]).{8,}/, // eslint-disable-line
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    )
    .required(' Password is Required'),
});

const RegisterFan = ({setsuccessMain, flow, history}) => {
  const dispatch = useDispatch();
  const {error, registerSuccess} = useSelector(
    (state) => state.BrandModuleReducer,
    // Same reducer for brand and fan as of now
  );

  const loginHandler = (data, setSubmitting) => {
    const loginPayload = {
      name:
        data.lname.trim() !== ''
          ? `${data.fname.trim()} ${data.lname.trim()}`
          : data.fname.trim(),
      email: data.email.toLowerCase(),
      password: data.password,
      role: 'fan',
    };
    dispatch(submitBrandRegister(loginPayload));
  };

  useEffect(() => {
    if (registerSuccess !== 'null') {
      if (registerSuccess) {
        toastMessage('Successfully registered');
        if (flow === 'purchaser') {
          history.push('/purchaser/checkout');
        } else {
          setsuccessMain(true);
        }
      } else {
        toastMessage(error.message.message);
        dispatch(handleError(''));
      }
      dispatch(handlerRegisterSuccess('null'));
    }
    // eslint-disable-next-line
  }, [registerSuccess, error, dispatch, setsuccessMain]);

  return (
    <Formik
      initialValues={{
        fname: '',
        lname: '',
        email: '',
        password: '',
      }}
      validationSchema={schema}
      onSubmit={(values, {setSubmitting}) => {
        loginHandler(values, setSubmitting);
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit} className="mt-5 mb-3">
            <div className="container-fluid">
              <div className="row g-0">
                <div className="col-xl-6 col-md-12 col-sm-12 pe-0 pe-xl-1">
                  <div className="form-floating brand-form mb-2">
                    <input
                      className="form-control"
                      value={values.fname}
                      type="text"
                      name="fname"
                      onChange={handleChange}
                      placeholder={registerAsAthlete.inputfirst}
                    />
                    {
                      <label htmlFor="floatingInput">
                        {registerAsAthlete.inputfirst}
                      </label>
                    }

                    <div className="text-danger text-sm">
                      {touched.fname && errors.fname && errors.fname}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-12 col-sm-12 ps-0 ps-xl-1">
                  <div className="form-floating brand-form mb-2">
                    <input
                      className="form-control"
                      value={values.lname}
                      type="text"
                      name="lname"
                      id="floatingInputLname"
                      onChange={handleChange}
                      placeholder={registerAsAthlete.inputlast}
                    />
                    {
                      <label htmlFor="floatingInputLname">
                        {registerAsAthlete.inputlast}
                      </label>
                    }
                    <div className="text-danger text-sm">
                      {touched.lname && errors.lname && errors.lname}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-floating brand-form mb-2">
                    <input
                      className="form-control"
                      value={values.email}
                      type="email"
                      name="email"
                      id="floatingInputEmail"
                      onChange={handleChange}
                      placeholder={registerAsAthlete.inputemail.Brand}
                      autoComplete="false"
                    />

                    <label htmlFor="floatingInputEmail">
                      {registerAsAthlete.inputemail.Brand}
                    </label>

                    <div className="text-danger text-sm">
                      {touched.email && errors.email && errors.email}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <PasswordStrength
                    fieldId="password"
                    className="form-floating"
                    label={registerAsAthlete.inputpass}
                    placeholder="Enter Password"
                    name="password"
                    blurEvent={handleBlur}
                    onChange={(val) => {
                      setFieldValue('password', val);
                    }}
                  />
                  <div className="text-danger text-sm">
                    {touched.password && errors.password && errors.password}
                  </div>
                </div>
              </div>
              <div className="bottom-pos-btn-container mt-3">
                <button type="submit" className="btn clickbtn text-uppercase">
                  {registerAsAthlete.btnlabel}
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default RegisterFan;
