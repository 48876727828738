import React from 'react';
import useApi from '../../../../../hooks/useApi';

const Stats = () => {
  const [statsData] = useApi('/athlete/listing/dashboard/stats');

  return (
    <>
      <h6 className="font-m fw-bold color-muted text-uppercase ls-m mb-3">
        Your stats
      </h6>
      <div className="row stats-card mx-n2 mx-xl-n4">
        <div className="col-xl-3 col-6 mb-4 px-2 px-xl-3">
          <div className="card-grid card-var-1">
            <h6 className="color-muted mb-4">Orders</h6>
            <div className="d-flex align-items-center">
              <h2 className="m-0 me-2 ">{statsData?.data?.all_orders}</h2>
              {/* {statsData.data.new_orders > 0 &&
                <span className="badge bg-purple">{statsData.data.new_orders} New</span>
              } */}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-6 mb-4 px-2 px-xl-3">
          <div className="card-grid card-var-2">
            <h6 className="color-muted mb-4">Custom deals</h6>
            <div className="d-flex align-items-center">
              <h2 className="m-0 me-2 ">{statsData?.data?.all_custom_deals}</h2>
              {/* <span className="badge bg-purple">3 New</span> */}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-6 mb-4 px-2 px-xl-3">
          <div className="card-grid card-var-3">
            <h6 className="color-light mb-4">Earnings this month</h6>
            <div className="d-flex align-items-center">
              <h2 className="m-0 me-2 text-white">
                $ {statsData?.data?.earnings_month}
              </h2>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-6 mb-4 px-2 px-xl-3">
          <div className="card-grid card-var-3">
            <h6 className="color-light mb-4">Earnings all time</h6>
            <div className="d-flex align-items-center">
              <h2 className="m-0 me-2 text-white">
                $ {statsData?.data?.all_earnings}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
