import React, {useState, useEffect} from 'react';
import {Upload, Input} from 'antd';
import {UploadAssets} from '../../../../../hooks/UploadImage';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';
import ImgCrop from 'antd-img-crop';
import Loader from 'react-loader-spinner';

const Gallery = (props) => {
  const {setListingData, media, setMedia, prev, next, videoUrl, setVideoUrl} =
    props;
  const [urlValid, setUrlValid] = useState(false);
  const [videoUrlText, setVideoText] = useState(videoUrl);
  const [loadimgImageStatus, setLoadingImageStatus] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const {Dragger} = Upload;

  useEffect(() => {
    if (videoUrl !== '') {
      setUrlValid(true);
    } else {
      setUrlValid(false);
    }
  }, [videoUrl]);

  const handleUpload = (data) => {
    setMedia([...media, data]);
    setListingData({media: [...media, data]});
    // setGalleryData([...galleryData, data])
  };

  const validateUrl = (url) => {
    if (url !== '') {
      setVideoText(url);
      const regExp =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      const match = url.match(regExp);
      if (match) {
        setUrlValid(true);
        setValidationMessage('');
        // console.log(match[1])
        setVideoUrl(url);
        setListingData({media: [...media, match[1]]});
      } else {
        setUrlValid(false);
        setValidationMessage('Invalid url');
      }
    } else {
      setVideoUrl();
      setListingData({media: media});
      setUrlValid(false);
      setValidationMessage('');
    }
  };

  const uploadFile = (fileObj) => {
    UploadAssets(fileObj, handleUpload, setLoadingImageStatus)();
  };

  const deleteList = (item) => {
    const deletedList = media.filter((list) => list !== item);
    setMedia(deletedList);
    setListingData({media: deletedList});
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toastMessage('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 12;
    if (!isLt2M) {
      errorToastMessage('Image must smaller than 12MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <div className="overview">
      <h1 className="fw-bold text-center">Gallery</h1>
      <p className="mb-5 color-muted text-center title">
        Upload a Gallery of photos/videos that tell Purchasers what to expect
      </p>
      <div className="mt-5 mb-3 overview mw-570 mx-auto">
        <div className="gallery-upload">
          {/* <ImgCrop rotate>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              // fileList={fileList}
              // onChange={onChange}
              // onPreview={onPreview}
            >
              ff
            </Upload>
          </ImgCrop> */}

          <ImgCrop beforeCrop={(file) => beforeUpload(file)}>
            <Dragger
              listType="none"
              // beforeUpload={beforeUpload}
              customRequest={(fileList) => uploadFile(fileList)}
              className="custom-upload mb-3 py-2"
              showUploadList={false}
              // multiple={true}
              // onChange={uploadingImage}
            >
              <p className="ant-upload-drag-icon mb-1"></p>
              <p className="ant-upload-text d-flex justify-content-center">
                <span className="d-none d-md-block">
                  Drag and drop images, or{' '}
                </span>
                <span className="text-purple ms-2">Browse</span>
              </p>
              <p className="ant-upload-hint text-muted mx-4 px-3">
                The first image will be used as the thumbnail in listing preview
              </p>
            </Dragger>
          </ImgCrop>

          <div className="list-array">
            {loadimgImageStatus && (
              <div className="loader zIndex5">
                <Loader type="Bars" color="#00BFFF" height={50} width={100} />
              </div>
            )}
            <div className="d-flex gallery-list mx-0">
              {[...Array(6)].map((key, i) => (
                <div className="gallery-card mx-1 empty" key={i + `i`}></div>
              ))}
            </div>
            <div className="d-flex gallery-list mx-0 img-list">
              {media.map((data, key) => (
                <div className="gallery-card mx-1" key={key}>
                  <span
                    onClick={(e) => deleteList(data)}
                    className="text-danger fw-bold delete-icon"></span>{' '}
                  <img src={data} alt="Gallery " />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <h5 className="text-dark title mb-0">Video URL</h5>
          <p className="text-muted mb-2">
            Upload your supporting video to Youtube and enter the Youtube URL
          </p>
          <Input
            onChange={(e) => {
              validateUrl(e.target.value);
              setVideoText(e.target.value);
            }}
            className="input-custom-var2 form-control"
            value={videoUrlText}
            placeholder="Add URL "
          />
          <div className="text-danger fw-700 mt-2">{validationMessage}</div>
        </div>
        <div className="border-bottom mt-5 mw-570 mx-auto d-none d-md-block"></div>
        <div className="steps-action d-flex flex-row pt-4">
          <div
            className="text-muted fw-bold align-items-center d-flex cursor-pointer mx-md-0 mx-3 t-buttom"
            onClick={prev}>
            BACK
          </div>
          <button
            className={`btn btn-primary ms-auto ${
              urlValid || media.length > 0 ? '' : 'disabled'
            }`}
            type="submit"
            onClick={next}>
            Next
            <i className="icon icon-right ms-3"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
