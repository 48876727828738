import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch, Redirect} from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';
// import {setHeader} from '../../store/athleteflow/action';
import PurchaseDetails from './components/PurchaseDetails';
import Deals from './components/features/Deals';
import Details from './components/features/Deals/Details';
import Checkout from './pages/Checkout';
import ConfirmandPay from './pages/ConfirmandPay';
import Dashboard from './components/features/Dashboard';
import Messages from './components/Messages';
import Campaigns from './components/features/Campaigns';
import CampaignsDetails from './components/features/Campaigns/CampaignsDetails';
import Athletes from './components/features/Athletes';
import AthleteProfile from '../../common/AthleteProfile/AthleteProfile';
import MyAccount from '../UserLogin/Components/MyAcconts';
import PurchaserProfile from './components/features/Profile/PurchaserProfile';
import PurchaserRegister from '../Athlete/components/features/Register';
import {fetchPurchaserData} from '../../store/users/actions';
import {useDispatch} from 'react-redux';
import PaymentSucces from './pages/PaymentSuccess';
import VideoCall from '../../common/VideoCall';
import CampaignModalContainerMobile from './components/features/Campaigns/CampaignModalContainerMobile';

const isAuthenticated = () => {
  if (localStorage.getItem('athletUp-token') !== null) {
    return true;
  } else {
    localStorage.clear();
    return false;
  }
};

const PrivateRoute = ({component: Component, ...rest}) => {
  const isLoggedIn = isAuthenticated();
  const destinationPath = rest.location.pathname;
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{pathname: '/login', state: {from: destinationPath}}} />
        );
      }}
    />
  );
};

const Purchaser = () => {
  let {path} = useRouteMatch();
  const dispatch = useDispatch();

  let id = localStorage.getItem('id');
  let userRole = localStorage.getItem('userRole');
  // console.log(id, 'id');
  useEffect(() => {
    if (id !== null && userRole !== 'athlete') {
      dispatch(fetchPurchaserData(id));
    }
  }, [dispatch, id, userRole]);

  return (
    <div className="">
      <Switch>
        <Route exact path={path} component={() => <Home />} />
        {/* <Route exact path={`${path}/header`} component={() => <Header />} /> */}
        <Route
          exact
          path={`${path}/profile/:profileId`}
          component={() => <Profile />}
        />
        <Route exact path={`${path}/athletes`} component={() => <Athletes />} />
        <Route
          exact
          path={`${path}/athletes/profile`}
          component={() => <AthleteProfile flow="purchaser" />}
        />
        <Route
          exact
          path={`${path}/register`}
          component={() => <PurchaserRegister flow="purchaser" />}
        />

        <PrivateRoute
          exact
          path={`${path}/messages`}
          component={() => <Messages />}
        />

        <PrivateRoute
          exact
          path={`${path}/campaigns`}
          component={() => <Campaigns />}
        />
        <PrivateRoute
          exact
          path={`${path}/campaigns/details`}
          component={() => <CampaignsDetails />}
        />
        <PrivateRoute
          exact
          path={`${path}/campaigns/create`}
          component={() => <CampaignModalContainerMobile mode="create" />}
        />
        <PrivateRoute
          exact
          path={`${path}/campaigns/edit`}
          component={() => <CampaignModalContainerMobile mode="edit" />}
        />
        <PrivateRoute
          exact
          path={`${path}/deals`}
          component={() => <Deals />}
        />
        <PrivateRoute
          exact
          path={`${path}/deals/:dealId`}
          component={() => <Details />}
        />
        <PrivateRoute
          exact
          path={`${path}/checkout`}
          component={() => <Checkout />}
        />
        <PrivateRoute
          exact
          path={`${path}/payment-success`}
          component={() => <PaymentSucces />}
        />

        <PrivateRoute
          exact
          path={`${path}/dashboard`}
          component={() => <Dashboard />}
        />

        <PrivateRoute
          exact
          path={`${path}/dashboard/order/:orderId`}
          component={() => <PurchaseDetails />}
        />

        <PrivateRoute
          exact
          path={`${path}/payment`}
          component={() => <ConfirmandPay />}
        />

        <Route
          exact
          path={`${path}/account-details`}
          component={() => <MyAccount accountType="purchaser" />}
        />

        <Route
          exact
          path={`${path}/profile`}
          component={() => <PurchaserProfile mode="create" />}
        />

        <PrivateRoute
          path={`${path}/video-call/:channelId`}
          component={() => <VideoCall />}
        />
        <Redirect path="*" to="/" />
      </Switch>
    </div>
  );
};

const PurchaserModule = {
  routeProps: {
    path: '/purchaser',
    exact: false,
    component: Purchaser,
  },
  name: 'Purchaser',
};

export default PurchaserModule;
