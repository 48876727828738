import React from 'react'
import {brandMarketingSection} from '../../../../../utils/staticStrings'

export default function index(props) {
    return (
        <div className='brand-detail-section'>
            <div className='row center'>
             <div className='col-11 col-sm-9 col-md-8 col-lg-12 col-xl-10 col-xxl-7 title'> {brandMarketingSection.title} </div>
            </div>   

            <div className='row center'>
            <div className='col-sm-12 col-md-11 col-lg-12 col-xl-12 col-xxl-10 texts'>{brandMarketingSection.texts}</div>
            </div>            
        </div>
    )
}
