import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setHeader} from '../../../../../store/athleteflow/action';
import {Steps, Progress} from 'antd';
import {useHistory} from 'react-router-dom';
import ListingType from './ListingType';
import OverView from './OverView';
import Pricing from './Pricing';
import Description from './Description';
import Requirements from './Requirements';
import Gallery from './Gallery';
import Review from './Review';
import {headerSection} from '../../../../../utils/staticStrings';
import CustomModal from '../../../../../common/components/CustomModal';
import ConfirmModal from '../../../../../common/components/ConfirmModal';
import useApi from '../../../../../hooks/useApi';
import {
  createListing,
  browserBack,
  hanldeError,
} from '../../../../../store/athleteflow/action';
import {getState} from '../../../../../store/brandflow/action';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';
// import {Prompt} from 'react-router-dom';
import http from '../../../../../helpers/http';

const CreateListing = () => {
  const dispatch = useDispatch();
  const {createStatus, error, listBackButton} = useSelector(
    (state) => state.AthleteModuleReducer,
  );
  const {stateList} = useSelector((state) => state.BrandModuleReducer);
  const {athleteDetails} = useSelector((state) => state.UsersReducer);

  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [percent, setPercent] = useState(100 / 7);
  const [visible, setVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [digitalProduct, setDigitalProduct] = useState(false);
  const [physicalProduct, setPhysicalProduct] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [subCategoryName, setSubCategoryName] = useState('');
  const [media, setMedia] = useState([]);
  const [shopListing, setShopListing] = useState([]);
  const [selectedCard, setSelectedCard] = useState('experience');
  const [page, setPage] = useState(1);
  const [categoryId, setCategoryId] = useState('');
  const [subcategoryId, setSubacategoryId] = useState('');
  const [tombstoneTotalPage, setTombstoneTotalPage] = useState(0);
  let pageSize = 15;
  const [listingData, setListingData] = useState({
    type: 'Experiences',
    category_id: '',
  });
  const [categoryData] = useApi(`/athlete/categories?type=${selectedCard}`);
  const [uspsBoxType] = useApi(`/athlete/listing/usps-box-types`);

  const [countries] = useApi('/assets/countries');

  const physicalProductVaidation = async (data) => {
    try {
      let url = `/stripe/validate-address`;
      let shipping_address = {
        street1: data.street1,
        ...(data.street2 !== '' && {street2: data.street2}),
        state: data.state,
        country: data.country,
        zip: data.zip,
        city: data.city,
      };

      const res = await http.post(url, {shipping_address: shipping_address});
      if (res?.data?.success) {
        next();
        setListingData({...listingData, ...data});
      }
    } catch (err) {
      errorToastMessage(err?.response?.data?.message);
    }
  };

  const getTombstone = async (page, cId, subId) => {
    try {
      let url = `/athlete/listing/tombstones?category_id=${cId}&subcategory_id=${subId}&page=${page}&pageSize=${pageSize}`;
      const res = await http.get(url);
      setShopListing([...shopListing, ...res?.data?.data?.rows]);
      setTombstoneTotalPage(Math.ceil(res?.data?.data?.count / 3));
    } catch (err) {
      errorToastMessage(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (categoryId !== '' && subcategoryId !== '')
      getTombstone(page, categoryId, subcategoryId);
  }, [subcategoryId, categoryId, page]); // eslint-disable-line react-hooks/exhaustive-deps

  const setTombstonePageSize = (currentPage) => {
    if (page < tombstoneTotalPage && currentPage === pageSize * page - 2) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (error?.message?.statusCode === 400) {
      errorToastMessage(error.message.message);
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error?.message?.success === false) {
      if (error?.message?.message[0]?.text) {
        errorToastMessage(error?.message?.message[0]?.text);
      } else {
        errorToastMessage(error?.message?.message);
      }
    }
  }, [error]);

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (!listBackButton) {
        if (
          window.confirm(
            'Are you sure you want to navigate away from this screen and lose your progress on this listing? \n If you only wish to go back to the previous screen of the listing creation, click "Cancel", then use the gray "Back" button at bottom of this page instead.',
          )
        ) {
          // your logic
          history.push('/athlete/my-shop');
          dispatch(browserBack(true));
        } else {
          window.history.pushState(null, null, window.location.pathname);
          dispatch(browserBack(false));
        }
      }
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [listBackButton, history, dispatch]);

  useEffect(() => {
    if (createStatus === true) {
      toastMessage('List created successfully');
      history.replace('/athlete/my-shop');
    }
  }, [createStatus, history]);

  useEffect(() => {
    dispatch(setHeader(false));
    dispatch(browserBack(false));
    dispatch(hanldeError(''));
    dispatch(getState({country: 'US'}));
  }, [dispatch]);

  useEffect(() => {
    document.querySelector('body').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [current]);

  const getStateList = (val) => {
    if (val === 'US' || val === 'CA') {
      dispatch(getState({country: val}));
    }
  };

  const setCardType = (type) => {
    setSelectedCard(type);
  };
  const setListingPayload = (payload) => {
    setListingData({...listingData, ...payload});
  };

  const setListingPhysicalVal = (payload) => {
    physicalProductVaidation(payload);
    // setListingData({...listingData, ...payload});
  };

  const next = () => {
    setCurrent(current + 1);
    setPercent((100 / 7) * (current + 2));
  };

  const createListAction = () => {
    const listDataFinal = {
      type: listingData?.type,
      category_id: listingData?.category_id,
      subcategory_id: listingData?.subcategory_id,
      title: listingData?.title,
      description: listingData?.description,
      price: listingData?.price,
      is_limited: listingData?.is_limited,
      ...(listingData?.is_limited && {
        limited_quantity: listingData?.limited_quantity,
      }),
      ...(listingData?.purchaser_requirements.length > 0 && {
        purchaser_requirements: listingData?.purchaser_requirements,
      }),
      ...(((listingData.subcategory_id === 1 &&
        listingData.category_id === 1) ||
        (listingData.subcategory_id === 2 &&
          listingData.category_id === 1)) && {
        is_coaching: listingData?.is_coaching,
      }),
      media: listingData?.media,

      ...(physicalProduct && {
        package_type: listingData?.package_type,
        weight: listingData?.weight,
        weight_units: listingData?.weight_units,

        is_shipaddress_needed: true,
        message: listingData?.message,

        from_address: {
          zip: listingData?.zip,
          city: listingData?.city,
          street1: listingData?.street1,
          ...(listingData?.street2 && {
            street2: listingData?.street2,
          }),
          state: listingData?.state,
          country: listingData?.country,
        },

        ...(listingData?.package_type === 'USPS Flat Rate Packaging' && {
          usps_box_type: listingData?.usps_box_type,
        }),
        ...(listingData?.package_type !== 'USPS Flat Rate Packaging' && {
          dimensions: listingData?.dimensions,
        }),
      }),
      ...(digitalProduct && {
        message: listingData?.message,
        digital_product_urls: listingData?.digital_product_urls,
      }),
    };
    dispatch(createListing(listDataFinal));
  };
  const prev = () => {
    setCurrent(current - 1);
    setPercent((100 / 7) * current);
  };
  // const {Step} = Steps;
  const steps = [
    {
      title: 'LISTING TYPE',
      content: (
        <ListingType
          setCardType={setCardType}
          selectedCard={selectedCard}
          setListingData={setListingPayload}
          next={next}
        />
      ),
    },
    {
      title: 'OVERVIEW',
      content: (
        <OverView
          selectedCard={selectedCard}
          categoryData={categoryData}
          listingData={listingData}
          setListingData={setListingPayload}
          setDigitalProduct={setDigitalProduct}
          setPhysicalProduct={setPhysicalProduct}
          next={next}
          prev={prev}
          setCategoryName={setCategoryName}
          setSubCategoryName={setSubCategoryName}
          listingCarousel={shopListing}
          country={athleteDetails?.address?.country}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          setSubacategoryId={setSubacategoryId}
          setTombstonePageSize={setTombstonePageSize}
          setPage={setPage}
          setShopListing={setShopListing}
        />
      ),
    },
    {
      title: 'PRICING',
      content: (
        <Pricing
          setListingData={setListingPayload}
          selectedCard={selectedCard}
          uspsBoxType={uspsBoxType?.data}
          physicalProduct={physicalProduct}
          listingData={listingData}
          next={next}
          prev={prev}
          countries={countries?.data}
          getStateList={getStateList}
          stateList={stateList}
          setListingPhysicalVal={setListingPhysicalVal}
        />
      ),
    },
    {
      title: 'DESCRIPTION',
      content: (
        <Description
          selectedCard={selectedCard}
          setListingData={setListingPayload}
          digitalProduct={digitalProduct}
          physicalProduct={physicalProduct}
          listingData={listingData}
          next={next}
          prev={prev}
        />
      ),
    },
    {
      title: 'REQUIREMENTS',
      content: (
        <Requirements
          selectedCard={selectedCard}
          listingData={listingData}
          setListingData={setListingPayload}
          physicalProduct={physicalProduct}
          next={next}
          prev={prev}
        />
      ),
    },
    {
      title: 'GALLERY',
      content: (
        <Gallery
          setListingData={setListingPayload}
          listingData={listingData}
          setMedia={setMedia}
          media={media}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          next={next}
          prev={prev}
        />
      ),
    },
    {
      title: 'REVIEW',
      content: (
        <Review
          listingData={listingData}
          media={media}
          prev={prev}
          createListAction={createListAction}
          setVideoUrl={setVideoUrl}
          categoryName={categoryName}
          subCategoryName={subCategoryName}
        />
      ),
    },
  ];

  return (
    <>
      {/* <Prompt
        message={(location, action) => {
          if (action === 'POP') {
            console.log('Backing up...');

            history.go(1);
            // Add your back logic here
          }

          return true;
        }}
      /> */}

      <div className="list-holder">
        <div className="header-style header-light header-fixed athlete-bg">
          <div className="athlete-page-bg border-bottom">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center py-3">
                <a href="/">
                  <img
                    alt="headerlogo"
                    src={headerSection.headerLogoBlack}
                    className="d-inline-block align-top"
                  />
                </a>
                <div
                  className="text-purple cursor-pointer"
                  onClick={() => setVisible(true)}>
                  Exit <i className="icon-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          visible={visible}
          width="630px"
          isLoading={false}
          className="create-list-modal">
          <ConfirmModal
            onCancel={() => setVisible(false)}
            title="Are you sure you want to exit"
            text="Fusce bibendum nisl eu luctus tempus. Donec pellentesque lorem ipsum dolor sit amet adipiscing turpis sagittis"
            link="/athlete/my-shop"
          />
        </CustomModal>
        <Progress percent={percent} className="progressWizard" />
        <Steps 
          current={current} 
          className="createListing" 
          type="navigation" 

          items={steps.map((item) => ({ title: item.title }))}
        
        />
        <div className="wizard-holder">
          <div className="steps-content pb-5">{steps[current].content}</div>
        </div>
      </div>
    </>
  );
};

export default CreateListing;
