import React, {useState} from 'react';
import {
  pricingSection,
  backgroundStyle,
} from '../../../../../utils/staticStrings';

const PricingList = () => {
  const [show, setShow] = useState(false);

  const renderPricingLists = () => {
    const listArray = pricingSection.pricingListAthleteApp;
    let listData = show === false ? listArray.slice(0, 5) : listArray;

    return listData.map((ele, i) => {
      return (
        <div className="row liststext" key={i}>
          <div
            className="bullet"
            style={{
              background: `url('${backgroundStyle.pricingCardTick}')`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'start',
            }}></div>
          <div className="col">{ele}</div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="center pad0 mb-4">
        <div className="cc mw-100">
          <div className="benefits">{renderPricingLists()}</div>
          <div
            className="cursor-pointer fw-bold text-purple pb-5"
            onClick={() => setShow(!show)}>
            {!show ? 'VIEW MORE' : ''}
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingList;
