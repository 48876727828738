import {
  HANDLE_BRAND_REGISTER,
  HANDLE_BRAND_ERROR,
  HANDLE_BRAND_SUCCESS_RESPONSE,
  HANDLE_BRAND_REGISTER_SUCCESS,
  HANDLE_PURCHASER_PROFILE_SUBMIT,
  HANDLE_PURCHASER_PROFILE_STATUS,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_STATUS,
  GET_CAMPAIGNS,
  SET_CAMPAIGNS,
  GET_CAMPAIGN_DATA,
  SET_CAMPAIGN_DATA,
  GET_SHIPPING_COST,
  SET_SHIPPING_COST,
  EDIT_CAMPAIGN,
  EDIT_CAMPAIGN_STATUS,
  INVITE_ATHLETE_TO_CAMPAIGN,
  INVITE_CAMPAIGN_STATUS,
  LOADER,
  FETCH_ORDERS,
  SET_ORDERS,
  HANDLE_BRAND_MESSAGE,
  ACTIVATE_OR_PAUSE_CAMPAIGN,
  CAMPAIGN_STATUS_CHANGED,
  CREATE_CUSTOM_OFFER,
  CREATE_OFFER_STATUS,
  GET_DEALS,
  SET_DEALS,
  GET_DEAL_DATA,
  SET_DEAL_DATA,
  TAG_DEAL_TO_CAMPAIGN,
  TAG_TO_CAMPAIGN_STATUS,
  APPEND_CAMPAIGNS,
  GET_PAYMENT_METHOD,
  SET_PAYMENT_METHOD,
  UPDATE_DEAL_STATUS_BRAND,
  DEAL_STATUS_CHANGED_BRAND,
  UPDATE_DEAL_COUNTER_OFFER_STATUS_BRAND,
  COUNTER_OFFER_BRAND,
  COUNTER_OFFER_CREATED_BRAND,
  MARK_DEAL_DISPUTE,
  ADRRESS_VALIDATION,
  APPEND_DEALS,
  STATE_LIST,
  SET_STATE_LIST,
  DEALS_LOADER,
} from './actionTypes';

export const setLoader = (data) => {
  return {
    type: LOADER,
    payload: data,
  };
};

export const handleError = (error) => {
  return {
    type: HANDLE_BRAND_ERROR,
    payload: error,
  };
};

export const handleLoginSuccess = (data) => {
  return {
    type: HANDLE_BRAND_SUCCESS_RESPONSE,
    payload: data,
  };
};

export const submitBrandRegister = (data) => {
  return {
    type: HANDLE_BRAND_REGISTER,
    payload: data,
  };
};

export const handlerRegisterSuccess = (data) => {
  return {
    type: HANDLE_BRAND_REGISTER_SUCCESS,
    payload: data,
  };
};

export const handleCreateCampaignStatus = (data) => {
  return {
    type: CREATE_CAMPAIGN_STATUS,
    payload: data,
  };
};

export const purchaserProfileSubmit = (data, id) => {
  return {
    type: HANDLE_PURCHASER_PROFILE_SUBMIT,
    payload: data,
    id: id,
  };
};

export const purchaserProfileStatus = (data) => {
  return {
    type: HANDLE_PURCHASER_PROFILE_STATUS,
    payload: data,
  };
};

export const createCampaign = (data) => {
  return {
    type: CREATE_CAMPAIGN,
    payload: data,
  };
};

export const getCampaigns = (data) => {
  return {
    type: GET_CAMPAIGNS,
    payload: data,
  };
};

export const setCampaigns = (data) => {
  return {
    type: SET_CAMPAIGNS,
    payload: data,
  };
};

export const appendCampaigns = (data) => {
  return {
    type: APPEND_CAMPAIGNS,
    payload: data,
  };
};

export const getCampaignData = (data) => {
  return {
    type: GET_CAMPAIGN_DATA,
    payload: data,
  };
};

export const setCampaignData = (data) => {
  return {
    type: SET_CAMPAIGN_DATA,
    payload: data,
  };
};
export const getShippingCost = (data) => {
  return {
    type: GET_SHIPPING_COST,
    payload: data,
  };
};
export const setShippingCartData = (data) => {
  return {
    type: SET_SHIPPING_COST,
    payload: data,
  };
};

export const editCampaign = (data) => {
  return {
    type: EDIT_CAMPAIGN,
    payload: data,
  };
};

export const handleEditCampaignStatus = (data) => {
  return {
    type: EDIT_CAMPAIGN_STATUS,
    payload: data,
  };
};

export const inviteAthleteToCampaign = (data, campaignId) => {
  return {
    type: INVITE_ATHLETE_TO_CAMPAIGN,
    payload: data,
    campaignId: campaignId,
  };
};

export const handleInviteCampaignStatus = (data) => {
  return {
    type: INVITE_CAMPAIGN_STATUS,
    payload: data,
  };
};

export const fetchOrders = (data) => {
  return {
    type: FETCH_ORDERS,
    payload: data,
  };
};
export const handleBrandMessage = (data) => {
  return {
    type: HANDLE_BRAND_MESSAGE,
    payload: data,
  };
};

export const handleOrderdData = (data) => {
  return {
    type: SET_ORDERS,
    payload: data,
  };
};

export const handleActivateOrPauseCampaign = (data) => {
  return {
    type: ACTIVATE_OR_PAUSE_CAMPAIGN,
    payload: data,
  };
};

export const handleCampaignStatusChanged = (data) => {
  return {
    type: CAMPAIGN_STATUS_CHANGED,
    payload: data,
  };
};

export const createCustomOffer = (data) => {
  return {
    type: CREATE_CUSTOM_OFFER,
    payload: data,
  };
};

export const handleCreateOfferStatus = (data) => {
  return {
    type: CREATE_OFFER_STATUS,
    payload: data,
  };
};

export const getPurchaserDeals = (data) => {
  return {
    type: GET_DEALS,
    payload: data,
  };
};

export const setDeals = (data) => {
  return {
    type: SET_DEALS,
    payload: data,
  };
};

export const appendDeals = (data) => {
  return {
    type: APPEND_DEALS,
    payload: data,
  };
};

export const getDealData = (data) => {
  return {
    type: GET_DEAL_DATA,
    payload: data,
  };
};

export const setDealData = (data) => {
  return {
    type: SET_DEAL_DATA,
    payload: data,
  };
};

export const tagDealToCampaign = (data) => {
  return {
    type: TAG_DEAL_TO_CAMPAIGN,
    payload: data,
  };
};

export const handleTagToCampaignStatus = (data) => {
  return {
    type: TAG_TO_CAMPAIGN_STATUS,
    payload: data,
  };
};

export const getPaymentMethod = (data) => {
  return {
    type: GET_PAYMENT_METHOD,
    payload: data,
  };
};

export const setPaymentMethod = (data) => {
  return {
    type: SET_PAYMENT_METHOD,
    payload: data,
  };
};

export const updateDealStatus = (data, dealId) => {
  return {
    type: UPDATE_DEAL_STATUS_BRAND,
    payload: data,
    dealId: dealId,
  };
};

export const updateDealCounterOfferStatus = (data, dealId) => {
  return {
    type: UPDATE_DEAL_COUNTER_OFFER_STATUS_BRAND,
    payload: data,
    dealId: dealId,
  };
};

export const markDealDispute = (dealId) => {
  return {
    type: MARK_DEAL_DISPUTE,
    dealId: dealId,
  };
};

export const handleDealStatusChanged = (data) => {
  return {
    type: DEAL_STATUS_CHANGED_BRAND,
    payload: data,
  };
};

export const createCounterOfferBrand = (data, dealId) => {
  return {
    type: COUNTER_OFFER_BRAND,
    payload: data,
    dealId: dealId,
  };
};

export const handleCounterOfferCreated = (data) => {
  return {
    type: COUNTER_OFFER_CREATED_BRAND,
    payload: data,
  };
};

export const shipAddressValid = (data) => {
  return {
    type: ADRRESS_VALIDATION,
    payload: data,
  };
};

export const getState = (data) => {
  return {
    type: STATE_LIST,
    payload: data,
  };
};
export const handleStateList = (data) => {
  return {
    type: SET_STATE_LIST,
    payload: data,
  };
};
export const setDealsLoader = (data) => {
  return {
    type: DEALS_LOADER,
    payload: data,
  };
};
