import React from 'react';
import {
  CheckoutInfoAlert,
  ResendEmailButton,
} from '../../modules/Purchaser/pages/style';

import {InfoCircleOutlined} from '@ant-design/icons';
import Text from '../../components/Text/Text';

const AlertFixedTopbar = (props) => {
  const {title, clickAction, buttonText} = props;
  return (
    <div>
      <CheckoutInfoAlert className="mb-0 mt-5 mt-md-0">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center ">
              <InfoCircleOutlined
                style={{fontSize: '22px', color: '#55AF74'}}
              />
              <Text
                weight="500"
                mrLeft="12"
                size="16"
                smSize="13"
                smLineHeight="18"
                lineHeight="22"
                textColor="#435969">
                {title}
              </Text>
            </div>
            <ResendEmailButton onClick={clickAction}>
              {buttonText}
            </ResendEmailButton>
          </div>
        </div>
      </CheckoutInfoAlert>
    </div>
  );
};

export default AlertFixedTopbar;
