// import { post, get, put } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import http from '../../../helpers/http';
import {isString} from 'formik';

export const triggerLogin = (payload) => {
  return http.post(url.USER_LOGIN, payload);
};

export const triggerRegister = (payload) => {
  return http.post(url.REGISTER, payload);
};

export const triggerBrandRegister = (payload) => {
  return http.post(url.REGISTER_BRAND, payload);
};

export const triggerForgotPassword = (payload) => {
  return http.post(url.FORGOT_PASSWORD, payload);
};

export const triggerResetPassword = (payload) => {
  return http.post(url.RESET_PASSWORD, payload);
};

export const fetchSportsLists = () => {
  return http.get(url.GET_SPORTS_LIST);
};

export const triggerProfileSubmit = (payload) => {
  return http.put(`athlete/details/${payload.id}`, payload.payload);
};

// export const verifyUserToken = (payload) => {
//   return post(`account/verify-token?encrypted_key=${payload}`);
// };

export const tiggerCreateList = (payload) => {
  return http.post('athlete/listing', payload);
};

export const triggerResendVerification = (payload) => {
  return http.put(`account/resend-verification-email?user_id=${payload}`);
};

export const getProfile = (payload) => {
  return http.post(`account/verify-token?encrypted_key=${payload}`);
};

export const triggerCreateStripeAccount = () => {
  return http.post('/account/stripe', {});
};

export const triggerGetSubscriptions = (payload) => {
  if (
    payload?.payload &&
    isString(payload?.payload) &&
    payload?.payload !== ''
  ) {
    return http.get('/stripe/products?coupon=' + payload.payload);
  } else {
    return http.get('/stripe/products');
  }
};

export const triggerCreateStripeSubscription = (payload) => {
  return http.post('/stripe/checkout-session', payload);
};

export const fetchShopsList = (payload) => {
  let url = `/athlete/listing?page=${payload.page}&pageSize=${payload.pageSize}&ts=${Date.now().toString()}`;
  if (payload?.status) {
    url = `${url}&status=${payload.status}`;
  }
  if(payload?.type) {
    url = `${url}&type=${payload.type}`;
  }
  return http.get(url, { 
    headers: { 
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0', 
    } 
  });
};

export const getStripeStatus = () => {
  return http.get('/account/account-validity');
};

export const getAthletList = (payload) => {
  // let url = `/athlete?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search}&sport_id=${payload.sport_id}`;

  let url = `/athlete?page=${payload.page}&pageSize=${payload.pageSize}`;
  if (payload?.search) {
    url = `${url}&search=${payload.search}`;
  }

  if (payload?.newlyJoioned) {
    url = `${url}&is_newly_joined=${payload.newlyJoioned}`;
  }

  if (payload?.sport_ids?.length > 0 && payload?.sport_ids !== 'none') {
    url = `${url}&sport_ids=${payload.sport_ids}`;
  }
  if (payload?.sort) {
    url = `${url}&sort=${payload.sort}`;
  }
  if (payload?.athlete_type?.length > 0) {
    url = `${url}&athlete_type=${payload.athlete_type}`;
  }
  if (payload?.listing_type?.length > 0) {
    url = `${url}&listing_type=${payload.listing_type}`;
  }
  if (payload?.locations?.length > 0) {
    url = `${url}&locations=${payload.locations}`;
  }
  if (payload?.genders?.length > 0) {
    url = `${url}&genders=${payload.genders}`;
  }
  if (payload?.lifestyle_ids?.length > 0) {
    url = `${url}&lifestyle_ids=${payload.lifestyle_ids}`;
  }
  if (payload?.total_social_reach?.length > 0) {
    url = `${url}&total_social_reach=${payload.total_social_reach}`;
  }
  return http.get(url);
};
export const getAthleteDetails = (payload) => {
  return http.get(`/athlete/${payload}`);
};

export const getAthletePublicProfile = (payload) => {
  return http.get(`/athlete/public-profile/${payload}`);
};
export const getPurchaserDetails = (payload) => {
  return http.get(`/purchaser/&{payload}`);
};

export const setNewPassword = (payload) => {
  return http.put(`/account/change-password`, payload);
};

export const setListStatus = (payload) => {
  return http.put(
    `/athlete/listing/${payload.id}/status?status=${payload.status}`,
  );
};

export const triggerGetCampaigns = (payload) => {
  let url = `/athlete/campaign?page=${payload.page}&pageSize=${payload.pageSize}&status=${payload.status}`;
  if (payload?.deliverable_type) {
    let delType = payload.deliverable_type;
    if (payload.deliverable_type.includes('&')) {
      /* eslint-disable-next-line */
      delType = payload.deliverable_type.replace('&', '%26');
    }
    url = `${url}&deliverable_type=${delType}`;
  }
  if (payload?.industry_id) {
    url = `${url}&industry_id=${payload.industry_id}`;
  }
  if (payload?.compensation_type) {
    url = `${url}&compensation_type=${payload.compensation_type}`;
  }
  if (payload?.compensation_level) {
    url = `${url}&compensation_level=${payload.compensation_level}`;
  }
  return http.get(url);
};

export const triggerGetInvitedCampaigns = (payload) => {
  let url = `/athlete/campaign?page=${payload.page}&pageSize=${payload.pageSize}&status=invited`;
  return http.get(url);
};

export const triggerGetDeals = (payload) => {
  let url = `/athlete/campaign/deal?page=${payload.page}&pageSize=${payload.pageSize}`;
  if (payload?.status) {
    url = `${url}&status=${payload.status}`;
  }
  return http.get(url);
};

export const triggerGetCampaignData = (payload) => {
  return http.get(`/athlete/campaign/${payload.id}`);
};

export const triggerGetDealData = (payload) => {
  return http.get(`/deal/${payload.id}`);
};
export const triggetOrderComplete = (payload) => {
  return http.put(`/${payload.type}/order/${payload.id}/complete`);
};

export const triggetGetOrder = (payload) => {
  return http.get(`/${payload.type}/order/${payload.id}`);
};

export const triggerCampaignApplication = (payload) => {
  return http.post(
    `/athlete/campaign/${payload.campaignId}/apply`,
    payload.payload,
  );
};
export const triggerEditList = (payload) => {
  return http.put(
    `/athlete/listing/${payload.id}/${payload.type}`,
    payload.payload,
  );
};

export const triggerDealStatusUpdate = (payload) => {
  return http.put(`/deal/${payload.dealId}/athlete/status`, payload.payload);
};

export const triggerDealCounterOfferStatusUpdate = (payload) => {
  return http.put(`/deal/${payload.dealId}/offer_status`, payload.payload);
};

export const triggerCreateCounterOffer = (payload) => {
  return http.post(`/deal/${payload.dealId}/counter_offer`, payload.payload);
};

export const triggerCancelOrder = (payload) => {
  return http.put(
    `/${payload.type}/order/${payload.id}/decline`,
    payload.payload,
  );
};
