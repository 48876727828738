import React, {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Row, Col} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import Text from '../../../components/Text/Text';
import ModalContainer from '../components/ModalContainr';
import ModalHeader from '../components/ModalHeader';
import {
  AthleteCard,
  AthleteProfileImageContainer,
  AthleteProductDetailContainer,
  AthleteProductPrice,
  CustomAthleteTag,
  AthleteCardContainer,
  TagContainer,
  CheckoutInfoCard,
  PriceSummaryCard,
  CheckoutButton,
  DeskTopLayoutView,
  MobileLayoutViewView,
  CustomDivider,
  CheckoutInfoAlert,
  ResendEmailButton,
} from './style';
import {AthleteInfoModal} from '../components/style';
import {useDispatch, useSelector} from 'react-redux';
import {
  resendVerificationEmail,
  setHeader,
} from '../../../store/athleteflow/action';
import {
  getShippingCost,
  setShippingCartData,
  shipAddressValid,
  getState,
} from '../../../store/brandflow/action';
import {fetchPurchaserData} from '../../../store/users/actions';
import useApi from '../../../../src/hooks/useApi';
import {toastMessage} from '../../../helpers/toastMessage';
import InputCounter from '../../../common/components/InputCounter';
import VideoThumbnail from '../../../common/components/VideoThumbnail';
import BillingForm from './BillingForm';
import {Skeleton, Space} from 'antd';
import Loader from 'react-loader-spinner';
import BillingFormService from './BillingFormNoPhysicalItem';

const Checkout = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [adddressPaylod, setAddressPaylod] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [inscription, setInscription] = useState('');
  // const [invalidZipCode, setInvalidZipCode] = useState(false);
  const {purchaserDetails, isLoading} = useSelector(
    (state) => state.UsersReducer,
  );
  const {validAddress, vaidationMessage, stateList} = useSelector(
    (state) => state.BrandModuleReducer,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const {message, resetEmailStatus} = useSelector(
    (state) => state.AthleteModuleReducer,
  );
  const {shippingData} = useSelector((state) => state.BrandModuleReducer);

  let ListingId = localStorage.getItem('listingId');

  const [listingData] = useApi(`/athlete/listing/${ListingId}`);
  const [countries] = useApi(`/assets/countries`);

  const formRef = useRef();

  let cardListProduct =
    listingData?.data?.type === 'Experiences'
      ? listingData?.data?.listing_experience
      : listingData?.data?.listing_product;

  let OrderLeft = cardListProduct?.limited_quantity;

  useEffect(() => {
    if (!validAddress) {
      if (vaidationMessage.length > 0) {
        // price api for all type payload message to dispay error message
        if (vaidationMessage[0].text) {
          toastMessage(vaidationMessage[0].text);
        } else {
          toastMessage(vaidationMessage);
        }
      } else {
        toastMessage(vaidationMessage);
      }
      dispatch(shipAddressValid({status: true, msg: ''}));
    }
  }, [validAddress, vaidationMessage.length, vaidationMessage, dispatch]);

  useEffect(() => {
    if (resetEmailStatus === true) {
      if (message.text.success === true) {
        toastMessage(message.text.data);
      }
    }
  }, [message, resetEmailStatus]);

  useEffect(() => {
    dispatch(setHeader(true));
    dispatch(getState({country: 'US'}));
  }, [dispatch]);

  useEffect(() => {
    let id = localStorage.getItem('id');
    if (id !== null && Object.keys(purchaserDetails).length > 0) {
      dispatch(fetchPurchaserData(id));
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (Object.keys(adddressPaylod).length > 0) {
      let shppingAdressPaylod = {
        shipping_address: adddressPaylod,
        quantity: quantity,
      };
      dispatch(getShippingCost({shppingAdressPaylod, ListingId}));
    } else {
      dispatch(setShippingCartData({}));
    }
  }, [quantity, dispatch, ListingId]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let paymentPayload;
    if (cardListProduct !== undefined && Object.keys(shippingData).length > 0) {
      paymentPayload = {
        quantity: quantity,
        shippingData: shippingData,
        ...(cardListProduct?.category_id === 4
          ? {
              physicalProduct: true,
            }
          : {
              physicalProduct: false,
            }),
        ...(inscription && {
          inscription: inscription,
        }),
        shipping_address: adddressPaylod,
      };
      localStorage.setItem('paymentPayload', JSON.stringify(paymentPayload));

      history.push('/purchaser/payment');
    }
  }, [shippingData, quantity, cardListProduct]); // eslint-disable-line react-hooks/exhaustive-deps

  const setAddressDetail = (data) => {
    setAddressPaylod(data);
    let shippingPayload = Object.fromEntries(
      Object.entries(data).filter(([key, v]) => key !== 'purchaser_name'),
    );
    let shppingAdressPaylod = {
      shipping_address: shippingPayload,
      quantity: quantity,
    };
    dispatch(getShippingCost({shppingAdressPaylod, ListingId}));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getStateList = (val) => {
    if (val === 'US' || val === 'CA') {
      dispatch(getState({country: val}));
    }
  };

  const currencyFormat = (num) => {
    if (num !== undefined) {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  };

  const checkoutValidation = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="mh-100-vh d-flex align-items-center justify-content-center">
          <Loader type="Bars" color="#00BFFF" height={50} width={100} />
        </div>
      ) : (
        <div className="purchaser-margin">
          {!purchaserDetails?.email_verified && (
            <CheckoutInfoAlert>
              <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center ">
                    <InfoCircleOutlined
                      style={{fontSize: '22px', color: '#55AF74'}}
                    />
                    <Text
                      weight="500"
                      mrLeft="12"
                      size="16"
                      smSize="13"
                      smLineHeight="18"
                      lineHeight="22"
                      textColor="#435969">
                      {purchaserDetails?.name}, you need to activate your
                      account{' '}
                      <span style={{fontWeight: 700}}>
                        {purchaserDetails?.email}.
                      </span>
                    </Text>
                  </div>
                  <ResendEmailButton
                    onClick={() =>
                      dispatch(resendVerificationEmail(purchaserDetails?.id))
                    }>
                    Resend Email
                  </ResendEmailButton>
                </div>
              </div>
            </CheckoutInfoAlert>
          )}
          <div className="container">
            <Row width="100%" gutter="20">
              <Col
                xs={{span: 24}}
                sm={{span: 24}}
                md={{span: 24}}
                lg={{span: 17}}>
                <AthleteCard
                  className={`${
                    cardListProduct?.category_id !== 4 ? 'mb-5' : ''
                  }`}>
                  <AthleteInfoModal
                    style={{top: 100}}
                    width={1040}
                    zIndex={2500}
                    title={<ModalHeader />}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}>
                    <ModalContainer />
                  </AthleteInfoModal>
                  <AthleteCardContainer>
                    <AthleteProfileImageContainer>
                      {listingData?.data?.attachments[0]?.type === 'video' ? (
                        <div className="video-thumbnail-md">
                          <VideoThumbnail
                            embedId={listingData?.data?.attachments[0]?.url}
                          />
                        </div>
                      ) : (
                        <img
                          src={listingData?.data?.attachments[0]?.url}
                          alt="card-img"
                          className="img-fluid object-fit-contain purchases-list-imag "
                        />
                      )}

                      <MobileLayoutViewView>
                        <Text
                          weight="700"
                          size="18"
                          lineHeight="25"
                          className=" ms-3 line-ellipse-1"
                          textColor="#042237">
                          {cardListProduct?.title}
                        </Text>
                      </MobileLayoutViewView>
                    </AthleteProfileImageContainer>

                    <AthleteProductDetailContainer>
                      <DeskTopLayoutView>
                        <Text
                          display="flex"
                          weight="700"
                          size="26"
                          lineHeight="32"
                          className="line-ellipse-1"
                          textColor="#042237">
                          {cardListProduct?.title}
                        </Text>
                      </DeskTopLayoutView>

                      <TagContainer>
                        <CustomAthleteTag>
                          {cardListProduct?.category.name}
                        </CustomAthleteTag>
                        <CustomAthleteTag>
                          {cardListProduct?.subcategory.name}
                        </CustomAthleteTag>
                      </TagContainer>
                      <Text
                        display="flex"
                        weight="500"
                        size="16"
                        smSize="14"
                        smLineHeight="24"
                        lineHeight="26"
                        className="line-ellipse-2"
                        textColor="#435969">
                        {cardListProduct?.description}
                      </Text>
                    </AthleteProductDetailContainer>
                    <MobileLayoutViewView>
                      <div className="d-flex justify-content-between mt-3">
                        <div className="w-120">
                          {/* <InputCounter setQuantity={setQuantity} /> */}
                          <InputCounter
                            setQuantity={setQuantity}
                            limted={cardListProduct?.is_limited}
                            orderLeft={OrderLeft}
                          />
                        </div>
                        <Text
                          display="flex"
                          weight="700"
                          size="28"
                          className="d-flex "
                          lineHeight="32"
                          textColor="#042237">
                          $ {cardListProduct?.price * quantity}
                        </Text>
                      </div>
                    </MobileLayoutViewView>

                    <AthleteProductPrice content="space-between">
                      <Text
                        display="flex"
                        weight="700"
                        size="28"
                        className="d-flex justify-content-end"
                        lineHeight="32"
                        textColor="#042237">
                        {currencyFormat(cardListProduct?.price)}
                        {/* {currencyFormat(cardListProduct?.price)} */}
                      </Text>
                      {/* <Input addonBefore="-" addonAfter="+" defaultValue={1} /> */}
                      <InputCounter
                        setQuantity={setQuantity}
                        limted={cardListProduct?.is_limited}
                        orderLeft={OrderLeft}
                      />
                    </AthleteProductPrice>
                  </AthleteCardContainer>
                </AthleteCard>

                <CheckoutInfoCard>
                  {cardListProduct?.category_id === 4 ? (
                    <BillingForm
                      setAddressDetail={setAddressDetail}
                      setInscription={setInscription}
                      cardListProduct={cardListProduct}
                      countries={countries?.data}
                      formRef={formRef}
                      getStateList={getStateList}
                      stateList={stateList}
                    />
                  ) : (
                    <BillingFormService
                      setAddressDetail={setAddressDetail}
                      cardListProduct={cardListProduct}
                      setInscription={setInscription}
                      countries={countries?.data}
                      formRef={formRef}
                    />
                  )}
                </CheckoutInfoCard>
              </Col>
              <Col
                xs={{span: 24}}
                sm={{span: 24}}
                md={{span: 24}}
                lg={{span: 7}}>
                <PriceSummaryCard>
                  <Text
                    display="flex"
                    weight="700"
                    size="18"
                    className="mb-3"
                    lineHeight="25"
                    textColor="#042237">
                    Price Summary
                  </Text>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between mb-3">
                      <Text
                        weight="500"
                        size="16"
                        lineHeight="22"
                        textColor="#042237">
                        Subtotal
                      </Text>
                      <Text
                        weight="500"
                        size="16"
                        lineHeight="22"
                        textColor="#042237">
                        {!shippingData?.subtotal ? (
                          <>
                            {currencyFormat(cardListProduct?.price * quantity)}
                          </>
                        ) : (
                          <>{currencyFormat(shippingData?.subtotal)}</>
                        )}
                      </Text>
                    </div>

                    <div className="d-flex justify-content-between mb-3">
                      <Text
                        weight="500"
                        size="16"
                        lineHeight="22"
                        textColor="#042237">
                        Sales tax
                      </Text>
                      <Text
                        weight="500"
                        size="16"
                        lineHeight="22"
                        textColor="#042237">
                        {Object.keys(shippingData).length === 0 ? (
                          <Space>
                            <Skeleton.Button
                              active="true"
                              size="default"
                              shape="square"
                            />
                          </Space>
                        ) : (
                          <>{currencyFormat(shippingData?.sales_tax)}</>
                        )}
                      </Text>
                    </div>
                    {cardListProduct?.category_id === 4 && (
                      <div className="d-flex justify-content-between mb-3">
                        <Text
                          weight="500"
                          size="16"
                          lineHeight="22"
                          textColor="#042237">
                          Shipping
                        </Text>
                        <Text
                          weight="500"
                          size="16"
                          lineHeight="22"
                          textColor="#042237">
                          {Object.keys(shippingData).length === 0 ? (
                            <Space>
                              <Skeleton.Button
                                active="true"
                                size="default"
                                shape="square"
                              />
                            </Space>
                          ) : (
                            <>
                              {/* {currencyFormat(shippingData?.shipping_tax)} */}
                              {shippingData?.shipping_price === 0 ? (
                                <>$0.00</>
                              ) : (
                                <> ${shippingData?.shipping_price}</>
                              )}
                            </>
                          )}
                        </Text>
                      </div>
                    )}
                  </div>
                  <CustomDivider />
                  <div className="d-flex justify-content-between mb-2">
                    <Text
                      weight="500"
                      size="18"
                      lineHeight="21"
                      textColor="#042237">
                      Total
                    </Text>
                    <Text
                      weight="700"
                      size="18"
                      lineHeight="24"
                      textColor="#042237">
                      {!shippingData?.total_price ? (
                        <>{currencyFormat(cardListProduct?.price * quantity)}</>
                      ) : (
                        <>{currencyFormat(shippingData?.total_price)}</>
                      )}
                    </Text>
                  </div>
                  <div
                  // onClick={(event) => {
                  //   Object.keys(shippingData).length > 0
                  //     ? history.push('/purchaser/payment')
                  //     : event.preventDefault();
                  // }}
                  // className={`cursor-pointer ${
                  //   Object.keys(shippingData).length > 0
                  //     ? ''
                  //     : 'inactive-state'
                  // }`}
                  >
                    <div
                      className=" text-white font-m"
                      type="submit"
                      onClick={() => checkoutValidation()}>
                      <CheckoutButton className="fw-800">
                        CONTINUE TO CHECKOUT
                      </CheckoutButton>
                    </div>
                  </div>
                </PriceSummaryCard>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
