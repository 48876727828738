import React, {useEffect} from 'react';
import {Card, Input} from 'antd';
import CustomCarousel from '../../../../../common/components/CustomCarousel';
import MediaBox from './MediaBox';
import {Formik} from 'formik';

const OverView = (props) => {
  const {
    selectedCard,
    next,
    prev,
    setListingData,
    listingData,
    categoryData,
    setDigitalProduct,
    setCategoryName,
    setSubCategoryName,
    setPhysicalProduct,
    listingCarousel,
    country,
    categoryId,
    setCategoryId,
    setSubacategoryId,
    setTombstonePageSize,
    setPage,
    setShopListing,
  } = props;
  const [subCategoryList, setSubCategoryList] = React.useState([]);
  const {Meta} = Card;
  useEffect(() => {
    if (categoryId !== '') {
      const item = categoryData?.data?.rows.find(
        (item) => item.id === categoryId,
      );
      setSubCategoryList(item?.subcategories);
    }
  }, [categoryId, categoryData]);

  useEffect(() => {
    if (categoryId === 5) {
      setDigitalProduct(true);
    } else {
      setDigitalProduct(false);
    }
    if (categoryId === 4) {
      setPhysicalProduct(true);
    } else {
      setPhysicalProduct(false);
    }
  }, [categoryId, setDigitalProduct, setPhysicalProduct]);

  const unsetState = () => {
    setListingData({category_id: '', subcategory_id: '', title: ''});
    setCategoryId('');
    setPage(1);
    setShopListing([]);
    prev();
  };

  const {TextArea} = Input;

  return (
    <div className="">
      <h1 className="fw-bold text-center">Overview</h1>
      <p className="mb-5 color-muted text-center title">
        {`Please select the category and subcategory of the ${selectedCard}`}.{' '}
      </p>
      <div className="mt-5 mb-3 overview mw-570 mx-auto">
        <Formik
          initialValues={{
            category_id: listingData.category_id ? listingData.category_id : '',
            subcategory_id: listingData?.subcategory_id
              ? listingData?.subcategory_id
              : '',
            is_coaching: listingData?.is_coaching
              ? listingData?.is_coaching
              : false,
            title: listingData?.title ? listingData?.title : '',
          }}
          onSubmit={(values, {setSubmitting}) => {
            setListingData(values);
            next();
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="container-fluid p-0">
                  <div className="row mb-5">
                    {categoryData?.data?.count > 0 && (
                      <>
                        {categoryData?.data?.rows.map((data) => {
                          return (
                            <div
                              key={data.id}
                              className={`col mb-3 overview-card ${
                                data.id === 4 && country !== 'US'
                                  ? 'coming-soon'
                                  : ''
                              }`}>
                              <div
                                name="category_id"
                                className={`list-card ${
                                  values.category_id === data.id
                                    ? 'active'
                                    : ' '
                                }`}
                                onClick={() => {
                                  setFieldValue('category_id', data.id);
                                  setCategoryId(data.id);
                                  setCategoryName(data.name);
                                  setPage(1);
                                  setShopListing([]);
                                }}>
                                <div className="bg-white">
                                  <Card
                                    className="card-bg"
                                    style={{width: '100%'}}
                                    cover={
                                      <div
                                        className={`card-bg-${data.id} bg-holder`}
                                      />
                                    }>
                                    <Meta description={data.name} />
                                  </Card>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                  {subCategoryList !== undefined && (
                    <>
                      {subCategoryList.length > 0 ? (
                        <>
                          <h3 className="title">
                            Select subcategory (based on your deliverable type){' '}
                            <span className="text-danger">*</span>
                          </h3>
                          <div className="row mb-3">
                            {subCategoryList.map((data, index) => {
                              return (
                                <div
                                  className="col-12 col-lg-6 mb-3"
                                  key={data.id}>
                                  <div
                                    className={`list-card custom-list  ${
                                      values.subcategory_id === data.id
                                        ? 'active'
                                        : ' '
                                    }`}
                                    key={data.id}
                                    name="subcategory_id"
                                    onClick={() => {
                                      setFieldValue('subcategory_id', data.id);
                                      setSubCategoryName(data.name);
                                      setSubacategoryId(data?.id);
                                      setPage(1);
                                      setShopListing([]);
                                    }}>
                                    <div className="bg-white br-5">
                                      <div
                                        className={`card-bg ${
                                          values.subcategory_id === data.id
                                            ? ''
                                            : 'color-muted opaque-7'
                                        }`}>
                                        {data.name}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {((values.subcategory_id === 1 &&
                            values.category_id === 1) ||
                            (values.subcategory_id === 2 &&
                              values.category_id === 1)) && (
                            <>
                              <h6 className="title">
                                Tag this Live video call as related to
                                coaching/lessons ?
                              </h6>
                              <div className="mb-4 overview-card d-inline-flex align-items-start">
                                <div
                                  name="category_id"
                                  onClick={() => {
                                    setFieldValue('is_coaching', true);
                                  }}
                                  className={`list-card custom-list me-2 ${
                                    values.is_coaching === true ? 'active' : ''
                                  }`}
                                  style={{
                                    width: '120px',
                                  }}>
                                  <div className="bg-white br-5">
                                    <div className="card-bg color-muted opaque-7 d-flex">
                                      Yes
                                    </div>
                                  </div>
                                </div>
                                <div
                                  name="category_id"
                                  onClick={() => {
                                    setFieldValue('is_coaching', false);
                                  }}
                                  className={`list-card custom-list ${
                                    values.is_coaching === false ? 'active' : ''
                                  }`}
                                  style={{
                                    width: '120px',
                                  }}>
                                  <div className="bg-white br-5">
                                    <div className="card-bg color-muted opaque-7 d-flex">
                                      No
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="form-group text-start mb-4">
                            <h6 className="form-label color-dark font-18">
                              Title <span className="text-danger">*</span>
                            </h6>

                            <TextArea
                              className=" custom-text-count"
                              name="title"
                              rows="1"
                              maxLength={80}
                              value={values.title}
                              onChange={handleChange}
                              placeholder=""
                              showCount
                            />
                          </div>
                          <div className="bg-light rounded-3 mb-4">
                            <div className="text-muted text-sm text-start px-3 py-2">
                              Example listings for inspiration (based on your
                              selected subcategory above)
                            </div>
                          </div>
                          {listingCarousel && (
                            <>
                              {listingCarousel.length > 1 && (
                                <CustomCarousel
                                  variableWidth={true}
                                  slidesToShow={1}
                                  setCurrentSlide={setTombstonePageSize}
                                  nextArrow={true}
                                  prevArrow={true}>
                                  {listingCarousel.map((data) => (
                                    <div className="px-2" key={data.id}>
                                      <MediaBox mediaData={data} />
                                    </div>
                                  ))}
                                </CustomCarousel>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <div className="h-90"></div>
                      )}
                    </>
                  )}

                  <div className="border-bottom mt-5 mw-570 mx-auto d-none d-md-block"></div>
                  <div className="steps-action d-flex flex-row pt-4">
                    <div
                      className="text-muted fw-bold align-items-center d-flex cursor-pointer mx-md-0 mx-3 t-buttom"
                      onClick={unsetState}>
                      BACK
                    </div>
                    <button
                      className={`btn btn-primary ms-auto ${
                        values.category_id === '' ||
                        values.subcategory_id === '' ||
                        values.title === ''
                          ? 'disabled'
                          : ''
                      }`}
                      type="submit">
                      Next
                      <i className="icon icon-right ms-3"></i>
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default OverView;
