import React from 'react';

const MediaBox = ({mediaData}) => {
  return (
    <div className="bg-white media-card d-flex">
      <div
        style={{
          width: '70px',
          height: '70px',
          backgroundColor: '#ddd',
          borderRadius: '14px',
          backgroundImage: `url(${mediaData?.asset})`,
          backgroundSize: 'cover',
        }}>
        {/* <img
          src={mediaData?.asset}
          alt="card-img"
          // className="img-fluid radius-l"
          style={{objectFit: 'cover'}}
        /> */}
      </div>
      <div className="ms-3">
        <h3
          className="font-s fw-bold text-dark line-ellipse-2"
          title={mediaData?.title}>
          {mediaData?.title}
        </h3>
        <div className="d-flex">
          <div className="badge-light me-2">{mediaData?.category?.name}</div>
          <div className="badge-light me-2">
            {' '}
            {mediaData?.subcategory?.name}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaBox;
