import React, { useState } from 'react';
import { Avatar, Badge } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import YoutubeEmbed from './YoutubeEmbed';
import CustomModal from "./CustomModal"

const VideoThumbnail = (props) => {
  const { embedId, size } = props
  const [visible, setVisible] = useState(false)
  return (
    <Badge count={<PlayCircleOutlined />} className="badge-play">
      <Avatar
        src={`https://img.youtube.com/vi/${embedId}/hqdefault.jpg`}
        alt="card-img"
        className={`img-fluid object-fit-contain purchases-list-imag  ${size === "lg" ? "md" : "sm"} `}
        onClick={() => setVisible(true)}
      />

      {visible &&
        <>
          <CustomModal
            visible={visible}
            width="710px"
            isLoading={false}
            className="update-payment-modal"
            onClickCancel={() => setVisible(false)}>
            <div className='my-5 h-400'>
              <YoutubeEmbed embedId={embedId} />
            </div>
          </CustomModal>
        </>}
    </Badge>
  )
}
export default VideoThumbnail