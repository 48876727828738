import React from 'react';
import CreateProfile from '../../../../modules/Athlete/components/features/Profile/CreateProfile';
import {Show} from '../../../../components/ShowAndHide';
import BrandDetails from '../../../Purchaser/components/features/Profile/BrandDetails';

const EditProfile = (props) => {
  const {
    // upadateActiveKey,
    accountType,
    accountData,
    sportsData,
    collegeDetail,
    team,
    interests,
    editProfileSubmit,
    athleteTypes,
  } = props;

  // console.log(accountData, "accountData")
  const profileFormSubmit = (values) => {
    // console.log(values, 'edit payload');
    editProfileSubmit({...values, name: accountData?.name});
    // upadateActiveKey("3")
  };

  return (
    <div className="mw-770 mx-auto my-md-5 my-3 profilepart-1">
      <Show show={accountType === 'purchaser'}>
        <BrandDetails mode="edit" />
      </Show>
      <Show show={accountType !== 'purchaser'}>
        <CreateProfile
          mode="edit"
          // upadateActiveKey={upadateActiveKey}
          profileData={accountData}
          sportsData={sportsData}
          collegeDetail={collegeDetail}
          team={team}
          interests={interests}
          profileFormSubmit={profileFormSubmit}
          athleteTypes={athleteTypes}
        />
      </Show>
    </div>
  );
};
export default EditProfile;
