import React from 'react';
import {Link} from 'react-router-dom';
import {
  meetOurStars,
  backgroundStyle,
} from '../../../../../utils/staticStrings';
import '../features.scss';

export default function index(props) {
  const {meetStars} = props;
  const renderStarCards = () => {
    return meetStars.rows.map((ele, i) => {
      return (
        <Link
          to={`/purchaser/profile/${ele.id}`}
          key={ele.id}
          className="col-md-4 col-6">
          <div className="card">
            {ele.image_url ? (
              <img
                src={ele.image_url}
                alt="profileImages"
                className="image_holder_card"
              />
            ) : (
              <div className="placeholder-athlete-icon"></div>
            )}

            <div className="card-body">
              <h5 className="card-title athlete-name" title={ele.name}>
                {ele.name}
              </h5>
              <div
                className="card-text text-card-list mb-3 line-ellipse-2"
                style={{maxWidth: '230px'}}>
                {ele?.athlete?.headliner && (
                  <span title={ele?.athlete?.headliner}>
                    {ele?.athlete?.headliner}
                  </span>
                )}
              </div>
              <p className="card-text mt-auto">
                <img src={backgroundStyle.meetStarsListIcon} alt="starIcon" />{' '}
                <span className="listingsCount">
                  {' '}
                  {ele.listing_count} listings
                </span>
              </p>
            </div>
          </div>
        </Link>
      );
    });
  };

  return (
    <div className="bgWhite">
      <div className="padding-main">
        <div className="sectionbg">
          <div className="meet-our-stars">
            <div className=" title mb-3">{meetOurStars.title}</div>
            {/* generate dynamic cards here */}

            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 g-3 athlete-grid">
              {meetStars?.rows.length > 0 && <>{renderStarCards()}</>}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/purchaser">
                <button className="clickbtnblue">BROWSE ALL</button>
              </Link>
            </div>

            {/* <div className="row center">
          <div className="col">
            <button type="button" className="btn clickbtnblue">
              BROWSE ALL
            </button>
          </div>
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
