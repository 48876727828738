import React, {useState, useEffect} from 'react';
import {Button, Checkbox, Radio} from 'antd';
import {loadStripe} from '@stripe/stripe-js';
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../helpers/toastMessage';
import http from '../../../../helpers/http';
import {toTitleCase} from '../../../../helpers/user';
import {useHistory} from 'react-router-dom';
import useApi from '../../../../hooks/useApi';
import {useSelector} from 'react-redux';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const CheckoutCard = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const {paymentData} = props;
  const [loading, setLoading] = useState(false);
  const [name, setCardName] = useState('');
  const [saveCard, setSaveCard] = useState(true);
  const [errorMessage, setErrorNameMessage] = useState(false);
  const [defPM, setDefPM] = useState(null);
  const [enterCard, setEnterCard] = useState(null);
  const [cardLoading, setCardloading] = useState(true);
  const {purchaserDetails} = useSelector((state) => state.UsersReducer);
  const onChange = (e) => {
    setSaveCard(e.target.checked);
  };
  const [PMs] = useApi('/stripe/payment-methods');

  useEffect(() => {
    if (PMs && PMs?.success) {
      if (PMs?.data && PMs?.data[0]) {
        setDefPM(PMs?.data[0]);
        setEnterCard(false);
        setTimeout(() => {
          setCardloading(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setCardloading(false);
          setEnterCard(true);
        }, 3000);
      }
    }
  }, [PMs]);

  const onCardChange = (e) => {
    if (e.target.value === 'new') {
      setEnterCard(true);
    } else {
      setEnterCard(false);
    }
  };
  const number = elements.getElement(CardNumberElement) || null;
  const expiryElement = elements.getElement(CardExpiryElement) || null;
  const cvc = elements.getElement(CardCvcElement) || null;
  if (number !== null) {
    number.on('change', function (event) {
      var displayError = document.getElementById('card-errors-number');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  if (expiryElement !== null) {
    expiryElement.on('change', function (event) {
      var displayError = document.getElementById('card-errors-expiry');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  if (cvc !== null) {
    cvc.on('change', function (event) {
      var displayError = document.getElementById('card-errors-cvc');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  const pay = async (event) => {
    event.preventDefault();
    setErrorNameMessage(true);

    let shipping_Payload = Object.fromEntries(
      Object.entries(paymentData?.shipping_address).filter(
        ([key, v]) => key !== 'purchaser_name',
      ),
    );
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    let paymentMenthod_Id;
    if (enterCard) {
      const {paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: number,
      });
      paymentMenthod_Id = paymentMethod?.id;
      // setLoading(true);
    }

    let data = {
      shipping_address: shipping_Payload,
      inscription: paymentData?.inscription,
      quantity: paymentData?.quantity,
      saveCard: saveCard,
      listing_id: localStorage.getItem('listingId'),
      paymentMethodId: enterCard ? paymentMenthod_Id : defPM?.id,
      purchaser_name: paymentData?.shipping_address?.purchaser_name
        ? paymentData?.shipping_address?.purchaser_name
        : purchaserDetails?.name,
    };
    let paymentSecuredData = await http.post(
      `stripe/order/payment_intent`,
      data,
    );
    let paymentIntent = paymentSecuredData?.data?.data?.paymentIntent;

    if (paymentIntent) {
      let result;

      result = await stripe.confirmCardPayment(paymentIntent, {
        ...(enterCard
          ? {
              payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                  name: name || null,
                },
              },
            }
          : {payment_method: defPM?.id}),
      });

      // console.log(result, 'result');
      if (result.error) {
        // Show error to your customer.
        setLoading(false);
        console.log(result.error.message, 'Error message');
        errorToastMessage(result.error?.message);
        return;
      }
      // console.log(result, "Resilt stripe")
      await sleep(1000);
      try {
        toastMessage(result.paymentIntent.status);
        setLoading(false);
        history.push('/purchaser/payment-success');
      } catch (err) {
        console.log(err);
        setLoading(false);
        errorToastMessage(err.response?.data?.message);
      }
    }
  };

  return (
    <div className="checkout-wrapper">
      {cardLoading && (
        <div className="fw-700 title mb-2 mt-4 color-dark">
          Card details loading...
        </div>
      )}
      {!cardLoading && defPM && (
        <>
          {/* <div className="fw-700 title mb-2 mt-4 color-dark">
            Payment Method
          </div> */}
          <div className="card-options radio-card-payment">
            <Radio.Group defaultValue="useCard" onChange={onCardChange}>
              <Radio value="useCard" className="mb-3">
                {defPM?.card?.brand &&
                  `${toTitleCase(defPM?.card?.brand)} ending in ${
                    defPM?.card?.last4
                  } 
            expiring ${defPM?.card?.exp_month}/${defPM?.card?.exp_year}`}
              </Radio>
              <Radio value="new">Add new card</Radio>
            </Radio.Group>
          </div>
        </>
      )}

      {enterCard && !cardLoading && (
        <>
          <div className="brand-form mb-3">
            <span className="form-label d-block mb-1">Name on card</span>
            <input
              className="form-control checkout-payment-input-stripe"
              value={name}
              type="text"
              name="name"
              id="paymentName"
              onChange={(e) => setCardName(e.target.value)}
              autoComplete="false"
            />
            {name.length === 0 && errorMessage && (
              <div className="text-danger fw-bold">Card name required</div>
            )}
          </div>
          {/*<div className="checkout-payment-form-item-cardelement">
       <CardElement
          options={{
            classes: {
              base: "checkout-payment-input-stripe",
            },
          }}
        /> 
      </div>*/}
          <div className="checkout-payment-form-item-cardelement mb-3">
            <span className="form-label d-block mb-1">Card number</span>
            <CardNumberElement
              options={{
                classes: {
                  base: 'checkout-payment-input-stripe',
                },
              }}
            />
            <div id="card-errors-number" className="text-danger fw-bold"></div>
          </div>
          <div className="row">
            <div className="col mb-4">
              <span className="form-label d-block mb-1">Expiration</span>
              <CardExpiryElement
                options={{
                  classes: {
                    base: 'checkout-payment-input-stripe',
                  },
                }}
              />

              <div
                id="card-errors-expiry"
                className="text-danger fw-bold"></div>
            </div>
            <div className="col mb-4">
              <span className="form-label d-block mb-1">CVC</span>
              <CardCvcElement
                options={{
                  classes: {
                    base: 'checkout-payment-input-stripe',
                  },
                }}
              />
              <div id="card-errors-cvc" className="text-danger fw-bold"></div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Checkbox
              onChange={onChange}
              checked={saveCard}
              className="text-start justify-content-start">
              Save card for later
            </Checkbox>
          </div>
        </>
      )}
      {!cardLoading && (
        <div className="checkout-payment-form-button-container">
          <Button
            type="primary"
            className="btn btn-save-var2 btn-lg"
            onClick={pay}
            loading={loading}>
            CONFIRM AND PAY
          </Button>
        </div>
      )}
    </div>
  );
};

const CheckoutCardWrapper = (props) => {
  const {paymentData} = props;
  const [stripe, setStripe] = useState(null);
  const [stripeKEy, setStripeKey] = useState(null);

  useEffect(() => {
    let isComponentMounted = true;
    const loadStripeHandler = async () => {
      try {
        if (stripeKEy && isComponentMounted) {
          const stripePromise = await loadStripe(stripeKEy);
          setStripe(stripePromise);
        }
      } catch (err) {
        console.log(err);
      }
    };
    loadStripeHandler();
    return () => {
      isComponentMounted = false;
    };
  }, [stripeKEy]);

  useEffect(() => {
    let getKey = true;
    const getData = async () => {
      const data = await http.get('/stripe/client_secret');
      if (getKey) {
        setStripeKey(data.data.data.key);
      }
    };
    getData();

    return () => {
      getKey = false;
    };
  }, []);

  // If no Stripe object, do not render the Stripe Element.
  if (!stripe) {
    return <p>Loading...</p>;
  }

  return (
    <Elements stripe={stripe}>
      <CheckoutCard {...props} paymentData={paymentData} />
    </Elements>
  );
};
export default CheckoutCardWrapper;
