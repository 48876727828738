import React, {useState} from 'react';
import {Radio, Upload, Input, Image} from 'antd';
import {Formik} from 'formik';
import * as yup from 'yup';
// import {UploadAssets} from '../../../../../hooks/UploadImage';
import {UploadImageDigitalProduct} from '../../../../../hooks/UploadImageDigitalProduct';
import AudioImage from '../../../../../assets/images/Audio.svg';
import DocImage from '../../../../../assets/images/Doc.svg';
import PdfImage from '../../../../../assets/images/PDF.svg';
import VideoImage from '../../../../../assets/images/Video.svg';

const Description = (props) => {
  const {setListingData, prev, next, digitalProduct, listingData} = props;
  const [is_limited, setIsLimited] = useState(false);
  const [digitaProductList, setDigitalProduct] = useState([]);
  const [loadimgImageStatus, setLoadingImageStatus] = useState(false);

  const plainOptions = [
    {label: 'Unlimited quantity ', value: false},
    {label: 'Limited quantity available', value: true},
  ];

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  const getProductUrls = (productListObj) => {
    let digtalProductUrl = productListObj.map((a) => a.path);
    setListingData({...listingData, digital_product_urls: digtalProductUrl});
  };

  const removeItem = (list) => {
    // digitaProductList.splice(list, 1);
    let newArray = digitaProductList.filter((_, i) => i !== list);

    setDigitalProduct(newArray);
    getProductUrls(newArray);
  };

  const uploadFile = (fileObj) => {
    let fileObject;
    const handleUpload = async (data) => {
      let fileThumbnail = await getBase64(fileObj.file.originFileObj);
      fileObject = {
        name: fileObj.file.name,
        path: data,
        type: fileObj.file.type,
        thumbnail: fileThumbnail,
      };
      setDigitalProduct([...digitaProductList, fileObject]);
      getProductUrls([...digitaProductList, fileObject]);
    };
    UploadImageDigitalProduct(
      fileObj.file,
      handleUpload,
      setLoadingImageStatus,
    )();
    // if (
    //   fileObj.file.type === 'image/svg+xml' ||
    //   fileObj.file.type === 'image/png' ||
    //   fileObj.file.type === 'image/jpeg'
    // ) {
    //   UploadAssets(fileObj, handleUpload, setLoadingImageStatus)();
    // } else {
    //   UploadImageDigitalProduct(
    //     fileObj.file,
    //     handleUpload,
    //     setLoadingImageStatus,
    //   )();
    // }
  };

  let schema = yup.object().shape({
    limited_quantity:
      is_limited &&
      yup
        .number()
        .positive('Must be more than 0')
        .integer('Must be more than 0')
        .required('Price is required'),
  });

  return (
    <div className="overview">
      <h1 className="fw-bold text-center">Description</h1>
      <p className="mb-5 color-muted text-center title">
        Provide any additional details to the purchaser for this listing.
      </p>
      <div className="mt-5 mb-3 overview mw-570 mx-auto">
        <Formik
          initialValues={{
            description: listingData?.description
              ? listingData?.description
              : '',
            is_limited: listingData?.is_limited
              ? listingData?.is_limited
              : false,
            limited_quantity: listingData?.limited_quantity
              ? listingData?.limited_quantity
              : '',
            message: listingData?.message ? listingData?.message : '',
            digital_product_urls: listingData?.digital_product_urls
              ? listingData?.digital_product_urls
              : '',
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            let descriptionPayload;

            if (digitalProduct) {
              descriptionPayload = {
                description: values.description,
                is_limited: values.is_limited,
                message: values.message,
              };
            } else {
              descriptionPayload = {
                description: values.description,
                is_limited: values.is_limited,
              };
            }
            if (is_limited) {
              descriptionPayload = {
                ...descriptionPayload,
                limited_quantity: values.limited_quantity,
              };
            }
            setListingData(descriptionPayload);
            next();
          }}>
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            errors,
            touched,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="form-group text-start mb-4">
                  <h6 className="form-label color-dark">
                    Description <span className="text-danger">*</span>
                  </h6>
                  <textarea
                    className="form-control"
                    name="description"
                    rows="3"
                    value={values.description}
                    onChange={(e) =>
                      setFieldValue('description', e.target.value)
                    }
                    placeholder={`Add a description for this ${
                      listingData?.type === 'Experiences'
                        ? 'experience'
                        : 'listing'
                    }`}
                  />
                </div>
                <div className="d-lg-flex antd-radio-inline align-items-center custom-radio-inline">
                  <Radio.Group
                    options={plainOptions}
                    value={values.is_limited}
                    defaultValue="limit"
                    name="is_limited"
                    onChange={(e) => {
                      setFieldValue('is_limited', e.target.value);
                      setIsLimited(e.target.value);
                    }}
                  />
                  {values.is_limited && (
                    <>
                      <Input
                        addonBefore="Qty"
                        placeholder="0"
                        type="number"
                        onKeyDown={(evt) =>
                          evt.key === 'e' && evt.preventDefault()
                        }
                        min={0}
                        name="limited_quantity"
                        value={values.limited_quantity}
                        onChange={handleChange}
                        className="input-custom-var2 ms-lg-auto ms-4 input-qty"
                      />
                    </>
                  )}
                </div>

                <div className="mb-5"></div>
                {digitalProduct && (
                  <>
                    <div className="bg-light shipping-cost-holder">
                      <div className="upload-image-holder mb-5">
                        <p className="text-sm text-muted text-uploadr">
                          Upload your digital products and users will be able to
                          access them instantly after purchase
                        </p>

                        <Upload
                          multiple={true}
                          fileList={digitaProductList}
                          listType="picture"
                          showUploadList={false}
                          onChange={(fileList) => uploadFile(fileList)}
                          className="upload-list-inline">
                          <div className="btn-light-purple text-purple btn px-5">
                            <i className="icon-upload me-2"></i> Upload work
                          </div>
                        </Upload>

                        {loadimgImageStatus && (
                          <span className="fw-bold text-primary text-center">
                            Loading
                          </span>
                        )}
                        {digitaProductList.length > 0 && (
                          <>
                            {digitaProductList.map((item, i) => {
                              return (
                                <div
                                  className="ant-upload-list-item d-flex align-items-center"
                                  key={i}>
                                  {(item.type === 'application/pdf' ||
                                    item.type === 'pdf') && (
                                    <Image src={PdfImage} alt="Pdf Image" />
                                  )}
                                  {(item.type === 'audio/aac' ||
                                    item.type === 'audio/mpeg') && (
                                    <Image src={AudioImage} alt="Audio Image" />
                                  )}
                                  {(item.type === 'video/x-msvideo' ||
                                    item.type === 'video/mp4' ||
                                    item.type === 'video/mpeg') && (
                                    <Image src={VideoImage} alt="Video Image" />
                                  )}
                                  {(item.type === 'text/csv' ||
                                    item.type === 'application/msword' ||
                                    item.type ===
                                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && (
                                    <Image src={DocImage} alt="Doc Image" />
                                  )}

                                  {(item.type === 'image/jpeg' ||
                                    item.type === 'image/svg+xml' ||
                                    item.type === 'image/png') && (
                                    <Image
                                      src={item.thumbnail}
                                      alt={item.name}
                                    />
                                  )}
                                  {/* <div className="text-dark fw-bold ms-3">
                                    {item.name}
                                  </div> */}
                                  <a
                                    className="text-dark fw-bold ms-3"
                                    download={item.name}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    href={item.path}>
                                    {item.name}
                                  </a>
                                  <div
                                    className="ms-auto cancel-icon"
                                    onClick={() => removeItem(i)}></div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                      <div>
                        <div className="color-muted fw-bold mb-2">
                          Add a message <span className="text-danger">*</span>
                        </div>
                        <textarea
                          placeholder="Type your message here"
                          className="form-control "
                          rows="3"
                          name="message"
                          onChange={(e) =>
                            setFieldValue('message', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                {!digitalProduct && <div className="h-120"></div>}

                <div className="border-bottom mt-5 mw-570 mx-auto d-none d-md-block"></div>
                <div className="steps-action d-flex flex-row pt-4">
                  <div
                    className="text-muted fw-bold align-items-center d-flex cursor-pointer mx-md-0 mx-3 t-buttom"
                    onClick={prev}>
                    BACK
                  </div>

                  {!digitalProduct && (
                    <>
                      {values.is_limited ? (
                        <button
                          className={`btn btn-primary ms-auto ${
                            values.description === '' ||
                            values.limited_quantity === ''
                              ? 'disabled'
                              : ''
                          }`}
                          type="submit">
                          Next
                          <i className="icon icon-right ms-3"></i>
                        </button>
                      ) : (
                        <button
                          className={`btn btn-primary ms-auto ${
                            values.description === '' ? 'disabled' : ''
                          }`}
                          type="submit">
                          Next
                          <i className="icon icon-right ms-3"></i>
                        </button>
                      )}
                    </>
                  )}
                  {digitalProduct && (
                    <>
                      {values.is_limited ? (
                        <button
                          className={`btn btn-primary ms-auto ${
                            values.description === '' ||
                            values.limited_quantity === '' ||
                            values.message === '' ||
                            digitaProductList.length < 1
                              ? 'disabled'
                              : ''
                          }`}
                          type="submit">
                          Next
                          <i className="icon icon-right ms-3"></i>
                        </button>
                      ) : (
                        <button
                          className={`btn btn-primary ms-auto ${
                            values.description === '' ||
                            values.message === '' ||
                            digitaProductList.length < 1
                              ? 'disabled'
                              : ''
                          }`}
                          type="submit">
                          Next
                          <i className="icon icon-right ms-3"></i>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Description;
