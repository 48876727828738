import React, {useEffect, useState} from 'react';
import {
  msgSection,
  registerAsAthlete,
  registerSection,
} from '../../../../../utils/staticStrings';
import '../features.scss';
import Text from '../../../../../components/Text/Text';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  submitRegister,
  handlerRegisterSuccess,
  // resendVerificationEmail,
} from '../../../../../store/athleteflow/action';
import {Show} from '../../../../../components/ShowAndHide';
import RegisterBrand from './RegisterBrand';
import PasswordStrength from '../../../../../common/components/PasswordStrength';
import * as yup from 'yup';
import {Formik} from 'formik';
import {toastMessage} from '../../../../../helpers/toastMessage';
import RegisterFan from './RegisterFan';
import useApi from '../../../../../hooks/useApi';
import {Select} from 'antd';
// import { handleMessage } from '../../../../../store/users/actions';

const {Option} = Select;

let schema = yup.object().shape({
  fname: yup.string().required('First name is Required'),
  lname: yup.string().required('Last name is Required'),
  email: yup
    .string()
    .email('Please enter a valid Email ID.')
    .required('Email ID is Required'),
  type: yup.string().required('Select type'),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/, // eslint-disable-line
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    )
    .required(' Password is Required'),
});

const Register = (props) => {
  const [tab, setTab] = useState('Athlete');
  const history = useHistory();
  const [showForm, setShowForm] = useState(false);
  const [successMain, setsuccessMain] = useState(false);
  const [athleteTypes] = useApi('/athlete/athlete-types');
  const {flow} = props;
  const dispatch = useDispatch();
  const {registerSuccess, message, resetEmailStatus} = useSelector(
    (state) => state.AthleteModuleReducer,
  );

  useEffect(() => {
    if (flow === 'purchaser') {
      setTab('Brand');
    }
  }, [flow]);

  useEffect(() => {
    if (resetEmailStatus === true) {
      if (message.text.success === true) {
        toastMessage(message.text.data);
      }
      setsuccessMain(true);
    }
  }, [message, resetEmailStatus]);

  useEffect(() => {
    if (registerSuccess !== 'null') {
      if (registerSuccess) {
        toastMessage('Successfully registered');
        setsuccessMain(!successMain);
      } else {
        toastMessage(message.text);
        // dispatch(handleMessage(''));
      }

      dispatch(handlerRegisterSuccess('null'));
    }
  }, [successMain, registerSuccess, message.text, dispatch]);

  // const emailSuccessMessage = () => {
  //   const userId = athleteData?.id
  //     ? athleteData?.id
  //     : localStorage.getItem('id');
  //   dispatch(resendVerificationEmail(userId));
  // };

  const loginHandler = (data, setSubmitting) => {
    const loginPayload = {
      name:
        data.lname.trim() !== ''
          ? `${data.fname.trim()} ${data.lname.trim()}`
          : data.fname.trim(),
      email: data.email.toLowerCase(),
      password: data.password,
      type: data.type,
    };
    dispatch(submitRegister(loginPayload));
  };

  return (
    <div className="register-main-bg">
      <div className="padding-main">
        {!successMain ? (
          <div
            className={`register-main ${
              flow !== 'purchaser' ? '' : 'register-purchaser'
            }`}>
            <div className="row register-section large-screen">
              {!showForm ? (
                <div className="col-12 col-sm-10 col-md-10 col-lg-5 col-xl-5 col-xxl-6 me-lg-3">
                  <div className="auth-holder mx-auto ">
                    <div className="register-title mb-2 ">
                      <Text
                        display="flex"
                        weight="700"
                        size="55"
                        lineHeight="64"
                        textColor="#042237">
                        {registerSection.title}
                      </Text>
                    </div>
                    <div className="register-texts">
                      {registerSection.texts}
                    </div>
                    <div className="row card-section">
                      {flow !== 'purchaser' && (
                        <div className="col-md-12 col-lg-12 col-xl-4 col-xxl-4 mb-3">
                          <div
                            className={
                              tab === 'Athlete'
                                ? 'selectedTab'
                                : 'unselectedTab'
                            }
                            onClick={() => setTab('Athlete')}>
                            <div
                              className={tab === 'Athlete' ? 'ifSelected' : ''}>
                              <div
                                className={
                                  tab === 'Athlete' ? 'ifSelectedbg' : ''
                                }>
                                <div className="row">
                                  <div className="col-2 col-lg-4 col-xl-12">
                                    <img
                                      src={registerSection.img1}
                                      alt="rgimg"
                                    />
                                  </div>
                                  <div className="col-10 col-lg-8 col-xl-12">
                                    <div className="card-texts">
                                      {registerSection.title1}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className={`col-md-12 col-lg-12 mb-3 ${
                          flow !== 'purchaser'
                            ? 'col-xl-4 col-xxl-4'
                            : 'col-xl-6 col-xxl-6'
                        } `}>
                        <div
                          className={
                            tab === 'Brand' ? 'selectedTab' : 'unselectedTab'
                          }
                          onClick={() => setTab('Brand')}>
                          <div className={tab === 'Brand' ? 'ifSelected' : ''}>
                            <div
                              className={tab === 'Brand' ? 'ifSelectedbg' : ''}>
                              <div className="row">
                                <div className="col-2 col-lg-4 col-xl-12">
                                  <img src={registerSection.img2} alt="rgimg" />
                                </div>
                                <div className="col-10 col-lg-8 col-xl-12">
                                  <div className="card-texts">
                                    {registerSection.title2}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-md-12 col-lg-12 mb-3 ${
                          flow !== 'purchaser'
                            ? 'col-xl-4 col-xxl-4'
                            : 'col-xl-6 col-xxl-6'
                        } `}>
                        <div
                          className={
                            tab === 'Fan' ? 'selectedTab' : 'unselectedTab'
                          }
                          onClick={() => setTab('Fan')}>
                          <div className={tab === 'Fan' ? 'ifSelected' : ''}>
                            <div
                              className={tab === 'Fan' ? 'ifSelectedbg' : ''}>
                              <div className="row">
                                <div className="col-2 col-lg-4 col-xl-12">
                                  <img src={registerSection.img3} alt="rgimg" />
                                </div>
                                <div className="col-10 col-lg-8 col-xl-12">
                                  <div className="card-texts">
                                    {registerSection.title3}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col mb-4">
                      <button
                        type="button"
                        className="btn clickbtn"
                        onClick={() => setShowForm(!showForm)}>
                        {registerSection.btnlabel}
                      </button>
                    </div>
                    <div className="no-account">
                      {registerSection.signintext}
                      <span
                        className="color ms-2"
                        onClick={() => {
                          history.push('/login');
                        }}>
                        Sign in
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-12 col-sm-10 col-md-10 col-lg-5 col-xl-6 col-xxl-6">
                  <div className="auth-holder mx-auto">
                    <div className="register-title mb-2">
                      <Text
                        display="flex"
                        weight="700"
                        size="55"
                        lineHeight="64"
                        textColor="#042237">
                        {registerAsAthlete.title[tab]}
                      </Text>
                    </div>
                    <div className="register-texts">
                      {registerAsAthlete.texts}
                    </div>
                    <Show show={tab === 'Athlete'}>
                      <Formik
                        initialValues={{
                          fname: '',
                          lname: '',
                          email: '',
                          password: '',
                          type: '',
                        }}
                        validationSchema={schema}
                        onSubmit={(values, {setSubmitting}) => {
                          loginHandler(values, setSubmitting);
                        }}>
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => {
                          return (
                            <form onSubmit={handleSubmit} className="mt-5 mb-2">
                              <div className="container-fluid">
                                <div className="row g-0">
                                  <div className="col-xl-6 col-md-12 col-sm-12 pe-0 pe-xl-1">
                                    <div className="form-floating mb-2">
                                      <input
                                        className="form-control"
                                        value={values.fname}
                                        type="text"
                                        name="fname"
                                        onChange={handleChange}
                                        placeholder={
                                          registerAsAthlete.inputfirst
                                        }
                                      />
                                      <label htmlFor="floatingInput">
                                        {registerAsAthlete.inputfirst}
                                      </label>
                                      <div className="text-danger text-sm">
                                        {touched.fname &&
                                          errors.fname &&
                                          errors.fname}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-6 col-md-12 col-sm-12 ps-0 ps-xl-1">
                                    <div className="form-floating mb-2">
                                      <input
                                        className="form-control"
                                        value={values.lname}
                                        type="text"
                                        name="lname"
                                        id="floatingInputLname"
                                        onChange={handleChange}
                                        placeholder={
                                          registerAsAthlete.inputlast
                                        }
                                      />
                                      <label htmlFor="floatingInputLname">
                                        {registerAsAthlete.inputlast}
                                      </label>
                                      <div className="text-danger text-sm">
                                        {touched.lname &&
                                          errors.lname &&
                                          errors.lname}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-floating mb-2">
                                      <input
                                        className="form-control"
                                        value={values.email}
                                        type="email"
                                        name="email"
                                        id="floatingInputEmail"
                                        onChange={handleChange}
                                        placeholder={
                                          registerAsAthlete.inputemail[tab]
                                        }
                                        autoComplete="false"
                                      />
                                      <label htmlFor="floatingInputEmail">
                                        {registerAsAthlete.inputemail[tab]}
                                      </label>
                                      <div className="text-danger text-sm">
                                        {touched.email &&
                                          errors.email &&
                                          errors.email}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <PasswordStrength
                                      label={registerAsAthlete.inputpass}
                                      placeholder="Enter Password"
                                      blurEvent={handleBlur}
                                      name="password"
                                      onChange={(val) => {
                                        setFieldValue('password', val);
                                      }}
                                    />
                                    <div className="text-danger text-sm">
                                      {touched.password &&
                                        errors.password &&
                                        errors.password}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="my-2 form-floating float-selectbox">
                                      <Select
                                        style={{textTransform: 'capitalize'}}
                                        name="type"
                                        className={`custom-select custom-select-light ${
                                          values.type !== ''
                                            ? 'selected-option'
                                            : 'not-selected-option'
                                        }`}
                                        value={values.type}
                                        onChange={(val) =>
                                          setFieldValue('type', val)
                                        }
                                        placeholder="Enter athlete type"
                                        onBlur={handleBlur}>
                                        {athleteTypes?.data?.map((option) => (
                                          <Option
                                            value={option}
                                            key={option}
                                            style={{
                                              textTransform: 'capitalize',
                                            }}>
                                            {option}
                                          </Option>
                                        ))}
                                      </Select>
                                      <label
                                        htmlFor="floatingSelect"
                                        className="floating-label">
                                        {registerAsAthlete.inputtype}
                                      </label>
                                      <div className="text-danger text-sm">
                                        {touched.type &&
                                          errors.type &&
                                          errors.type}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="bottom-pos-btn-container mt-3">
                                  <button
                                    type="submit"
                                    className="btn clickbtn text-uppercase">
                                    {registerAsAthlete.btnlabel}
                                  </button>
                                </div>
                              </div>
                            </form>
                          );
                        }}
                      </Formik>
                    </Show>
                    <Show show={tab === 'Brand'}>
                      <RegisterBrand
                        setsuccessMain={setsuccessMain}
                        flow={flow}
                        history={history}
                      />
                    </Show>
                    <Show show={tab === 'Fan'}>
                      <RegisterFan
                        setsuccessMain={setsuccessMain}
                        flow={flow}
                        history={history}
                      />
                    </Show>
                    <div className="policy mb-5">
                      {'By signing up you agree to our '}
                      <a className="terms me-1" href="/terms-service">
                        <u>Terms of Service</u>
                      </a>
                      and
                      <a className="terms ms-1" href="/privacy-policy">
                        <u>Privacy Policy</u>
                      </a>
                    </div>
                    <div className="no-account">
                      {registerSection.signintext}
                      <span
                        className="color ms-2"
                        onClick={() => {
                          history.push('/login');
                        }}>
                        Sign in
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className=" col-md-10 col-lg-5 col-xl-6 col-xxl-5 mt-5 mt-sm-0 d-none d-md-block">
                <div className="bg ms-0 ms-xl-5 mt-5 mt-xl-0">
                  <img
                    className="girl-image"
                    src={registerSection.imgrightbg[tab]}
                    alt="janebg"
                  />
                  <Show show={tab === 'Athlete'}>
                    <div className="info-block">
                      <div className="nameage">{registerSection.nameage}</div>
                      <div className="playertitle">
                        {registerSection.playertitle}
                      </div>
                    </div>
                  </Show>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="register-main">
            <div className="row msg-section">
              <div className="col text-center">
                <div className="bg-circle mx-auto">
                  <div>
                    <img
                      className="msgicon"
                      src={msgSection.msgicon}
                      alt="msgicon"
                    />
                  </div>
                  {/* <div className="smallcircle text-center">1</div> */}
                </div>
                <div className="title mt-4">{msgSection.title}</div>
                <div className="col-12 col-sm-8 col-md-9 col-lg-9 col-xl-7 col-xxl-6 mx-auto">
                  <div className="texts mt-4">{msgSection.text}</div>
                </div>
                {/* <div className="col-11 col-sm-7 col-md-7 col-lg-7 col-xl-5 col-xxl-4 mt-5 mx-auto">
                  <button
                    type="button"
                    className="btn clickbtn"
                    onClick={() => emailSuccessMessage()}
                    // onClick={() => setsuccessMain(!successMain)}
                  >
                    {msgSection.btnlabel}
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
