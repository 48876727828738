import {all, fork} from 'redux-saga/effects';

import PostSaga from './posts/saga';
import AthleteModuleSaga from './athleteflow/saga';
import BrandModuleSaga from './brandflow/saga';
import UserModuleSaga from './users/saga';

export default function* rootSaga() {
  yield all([fork(PostSaga)]);
  yield all([fork(AthleteModuleSaga)]);
  yield all([fork(BrandModuleSaga)]);
  yield all([fork(UserModuleSaga)]);
}
