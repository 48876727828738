import React, {useState} from 'react';
import {Dropdown, Menu, Radio} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {FiltersGrid} from '../pages/style';
import {SelectDropDown} from './style';

export const RadioDropdown = ({
  defaultValue,
  dropdownOptions,
  optionSelected,
}) => {
  const [checkedList, setCheckedList] = useState(defaultValue);
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const onChangeCheckboxGroup = (list) => {
    setCheckedList(list.target.value);
    optionSelected(list.target.value);
  };

  const FilterCheckBoxOptions = ({data}) => {
    return (
      <Radio.Group
        onChange={onChangeCheckboxGroup}
        style={{width: 200}}
        value={checkedList}>
        {data.map((dataItem, i) => {
          return (
            <Radio value={dataItem.value} key={i} className="dropdown-checkbox">
              {dataItem.heading}
            </Radio>
          );
        })}
      </Radio.Group>
    );
  };

  const menu = (data) => (
    <Menu className={`action-dropdown height-sm`}>
      <Menu.Item key="1" className="dropdown-filter">
        <div>
          <FiltersGrid>
            {FilterCheckBoxOptions({
              data: dropdownOptions,
            })}
          </FiltersGrid>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    (<Dropdown
      overlay={menu}
      trigger={['click']}
      onClick={() => setVisible(true)}
      className="me-0"
      open={visible}
      onVisibleChange={handleVisibleChange}>
      {/* <a onClick={(e) => e.preventDefault()} className="text-dark"> */}
      <SelectDropDown
        // className={visible || dropdownStatus ? 'active' : ' '}
        style={{backgroundColor: '#ffffff'}}
        onClick={(e) => e.stopPropagation()}>
        <div className="d-flex align-items-center justify-content-between w-100 d-v1">
          <div className="d-flex me-3">
            {/* <img src={backgroundStyle.sortIcon} alt="srt-Icon" /> */}
            Sort
          </div>
          <div className="d-flex align-items-center">
            <DownOutlined style={{fontSize: '12px'}} />
          </div>
        </div>
      </SelectDropDown>
    </Dropdown>)
  );
};
