import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import AppLayout from '../../../../../common/AppLayout';
import {setHeader} from '../../../../../store/athleteflow/action';
import '../../../../../../src/assets/scss/_campaign.scss';
import {Switch} from 'react-router-dom';
import CampaignView from './CampaignView';
import {PrivateRoute} from '../../..';
import {useRouteMatch} from 'react-router-dom';
import DiscoverCampaigns from './DiscoverCampaigns';
import {handleSidebarTab} from '../../../../../store/users/actions';

const Campaigns = () => {
  const dispatch = useDispatch();
  let {path} = useRouteMatch();

  useEffect(() => {
    dispatch(setHeader(false));
    dispatch(handleSidebarTab('campaigns'));
  }, [dispatch]);

  return (
    <AppLayout contentClassname="campaign-layout">
      <Switch>
        <PrivateRoute
          exact
          path={path}
          component={() => <DiscoverCampaigns />}
        />
        <PrivateRoute
          exact
          path={`${path}/:id`}
          component={() => <CampaignView />}
        />
      </Switch>
    </AppLayout>
  );
};

export default Campaigns;
