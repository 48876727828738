import React from 'react';
import '../../../pages/pages.scss';
import {Table} from 'antd';
import {useHistory} from 'react-router-dom';

export const TableWithInfiniteScroll = ({
  columns,
  item,
  rows,
  count,
  current,
  onChange,
}) => {
  const history = useHistory();

  return (
    <div className="table-responsive">
      <Table
        className="custom-table border-none"
        columns={columns}
        dataSource={rows}
        onChange={onChange}
        rowKey={(record) => record.id}
        onRow={(record) => ({
          onClick: () => {
            if (record.id) {
              if (item === 'deal') {
                history.push(`/purchaser/deals/${record.id}`);
              }
            }
          },
        })}
        rowClassName={(record) => {
          return record.purchaser_read !== true ? 'active' : 'inactive';
        }}
        pagination={{
          pageSize: 5,
          total: count,
          hideOnSinglePage: true,
          showSizeChanger: false,
          current: current,
        }}></Table>
    </div>
  );
};
