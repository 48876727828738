import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Row, Col, Alert } from 'antd';
import TemplateCard from './TemplateCard';
import ListingForm from '../../../../../common/ListingForm/ListingForm';
import CustomModal from '../../../../../common/components/CustomModal';


const TemplateListCardUI = (props) => {
  const {
    isLoading,
    templates = [],
  } = props;
  const [selection, setSelection] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleClick = (listing) => {
    if(listing.type === 'Physical or digital products' && listing.listing_product.digital_product_urls?.length > 0) {
      listing.listing_product.digital_product_urls = [];
    }

    setSelection(listing);
    setShowEditModal(true);
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="m-3 d-block" style={{ maxWidth: 1400, backgroundColor: props.backgroundColor, borderColor: "#000", borderRadius: 20, padding: 15, opacity: 0.8 }}>
        {isLoading ? (
          <div className="d-flex py-5 align-items-center justify-content-center">
            <Loader type="Bars" color="#00BFFF" height={50} width={100} />
          </div>
        ) : (
          <>
            <h1 className="my-2 py-2 text-center fw-bold font-30">{props.templateType}</h1>
            <Row justify="center">
              
              {templates?.length > 0 ? templates.map(temp => 
                <Col xs={24} sm={24} md={6} span={6} key={temp.id}>
                  <TemplateCard 
                    key={temp.id}
                    listing={temp} 
                    onClick={handleClick} 
                    selection={selection}
                  />
                </Col>
              ) : (
                <h3 className="my-5 py-5 text-center fw-bold">
                  Loading...
                </h3>
              )}
            </Row>
          </>
        )}
        <div>
          <CustomModal
            title="Add Listing to your Shop"
            visible={showEditModal}
            width="710px"
            isLoading={false}
            className="edit-list-modal"
            onClickCancel={() => setShowEditModal(false)}>
            <>
              <div style={{ margin: '10px 25px' }}>
                <Alert
                  showIcon={false}
                  message=""
                  description="Generic information is used for each field (e.g. title, description, requirements, listing photo). Be sure to update to best reflect the product/service that you are offering."
                  type="info"
                />
              </div>
              <ListingForm
                listing={selection}
                template
              />
            </>
          </CustomModal>
        </div>
      </div>
    </div>
  );
};
export default TemplateListCardUI;
