import React, {useRef, useState} from 'react';
import {Row, Col, Select, Button} from 'antd';
import * as yup from 'yup';
import {Formik} from 'formik';
import Text from '../../../../../components/Text/Text';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {PaymentModalFooter} from '../../style';
import {CheckoutButton} from '../../../pages/style';
import useApi from '../../../../../hooks/useApi';
import {
  CampaignDetail,
  CustomAmount,
  CustomInput,
  CustomSelectDropDown,
  CustomTextArea,
  DeliverablesContainer,
  FulfillmentDatePicker,
  Heading,
} from '../Campaigns/style';
import CheckoutCardWrapperCounterOffer from './CheckoutCardCounterOffer';
import {CloseIcon} from '../../../../../utils/SocialIcons';
import {errorToastMessage} from '../../../../../helpers/toastMessage';

dayjs.extend(customParseFormat);

let schema = yup.object().shape({
  description: yup.string().required('Please enter description'),
  deliverables: yup.array().of(
    yup.object().shape({
      type: yup.string().required('Deliverable type required'),
      fulfillment_type: yup.string().required('Fulfillment date required'),
      fulfillment_date: yup
        .string()
        .when('fulfillment_type', {
          is: 'Specific Date' || 'Before Date',
          then: yup.string().required('Date is required'),
        })
        .nullable(),
      fulfillment_days: yup
        .string()
        .nullable()
        .when('fulfillment_type', {
          is: 'Within X Days after product received',
          then: yup.string().required('Days is required'),
        }),
    }),
  ),
});

const CounterOfferModal = ({
  dealData,
  createCounterOffer,
  closeCounterOffer,
  showPayment = false,
  setShowPayment,
}) => {
  const {Option} = Select;
  const [compensationErrors, setCompensationErrors] = useState(['']);
  const [maxDeliverableErrorMsg, setMaxDeliverableErrorMsg] = useState('');
  const [deliverableTypes] = useApi('/athlete/deliverable-types');
  const [fulfillmentTypes] = useApi('/purchaser/campaign/fullfillment-types');
  const [fulfillmentDays] = useApi('/purchaser/campaign/fullfillment-days');
  const [expirationDays] = useApi('/purchaser/campaign/deal/expiration-days');
  const [noCashComp, setNoCashComp] = useState(false);
  const formRef = useRef();

  const setDeliverables = (deliverables) => {
    let newDeliverables = [];
    deliverables.forEach((deliverable) => {
      let newDeliverable = {...deliverable};
      if (deliverable?.fulfillment_date) {
        newDeliverable.fulfillment_date = moment(
          deliverable?.fulfillment_date,
        ).format('MM-DD-YYYY');
      }
      newDeliverables = [...newDeliverables, newDeliverable];
    });
    return newDeliverables;
  };

  let initialValues = {
    title: dealData?.title,
    description:
      dealData?.counter_offers.length > 0
        ? dealData?.counter_offers[0].description
        : dealData?.description,
    deliverables:
      dealData?.counter_offers.length > 0
        ? setDeliverables(dealData?.counter_offers[0].deliverables)
        : setDeliverables(dealData?.deliverables),
    total_price:
      dealData?.counter_offers && dealData?.counter_offers.length > 0
        ? dealData?.counter_offers[0]?.total_price
        : dealData?.total_price,
    expiration_period:
      dealData?.counter_offers.length > 0
        ? dealData?.counter_offers[0].expiration_period
        : dealData?.expiration_period,
  };

  function disabledDate(current) {
    // Can not select days before today
    return current < moment().endOf('day');
  }

  const checkForCashCompensation = (values) => {
    let deliverables = values.deliverables;
    let total_cash = 0;
    if (deliverables.length === 1) {
      total_cash = deliverables[0].compensation_cash;
    } else {
      deliverables.forEach((deliverable) => {
        total_cash = total_cash + deliverable.compensation_cash;
      });
    }
    return total_cash;
  };

  const addDeliverable = (deliverables, setFieldValue) => {
    if (deliverables.length < 24) {
      if (maxDeliverableErrorMsg !== '') {
        setMaxDeliverableErrorMsg('');
      }
      const newDeliverable = {
        type: '',
        compensation_cash: '',
        compensation_product: '',
        fulfillment_type: '',
        fulfillment_date: moment().add(1, 'days').format('MM-DD-YYYY'),
      };
      let newDeliverables = [...deliverables];
      newDeliverables.push(newDeliverable);
      setFieldValue('deliverables', [...newDeliverables]);
      let compErrors = compensationErrors;
      compErrors.push('');
      setCompensationErrors(compErrors);
    } else {
      setMaxDeliverableErrorMsg('Maximum number of deliverables is 24');
    }
  };

  const submitForm = (values) => {

    validateCompensation(formRef);
    let compensationValid = compensationErrors.every((error) => {
      return error === '';
    });
    if (compensationValid) {
      values.deliverables.forEach((deliverable) => {
        
        // The fulfillment date is in rxjs date format...make it into format for server.
        if(deliverable.fulfillment_date) {
          deliverable.fulfillment_date = deliverable.fulfillment_date?.format ? deliverable.fulfillment_date.format('MM/DD/YYYY') : moment(deliverable.fulfillment_date, 'MM-DD-YYYY').format('MM/DD/YYYY');
        }

        if (deliverable.compensation_cash === '') {
          deliverable.compensation_cash = 0;
        } else {
          deliverable.compensation_cash = Number(deliverable.compensation_cash);
        }
        if (deliverable.compensation_product === '') {
          deliverable.compensation_product = 0;
        } else {
          deliverable.compensation_product = Number(
            deliverable.compensation_product,
          );
        }
      });
      const total_cash = checkForCashCompensation(values);
      if (total_cash === 0) {
        setNoCashComp(true);
      } else {
        setNoCashComp(false);
      }
      setShowPayment(true);
    }
  };

  const handleCreateCounterOffer = (values) => {
    let payload = {...values};
    delete payload.title;
    payload.deliverables.forEach((deliverable) => {
      delete deliverable?.id;
      delete deliverable?.deal_id;
      delete deliverable?.createdAt;
      delete deliverable?.updatedAt;
      delete deliverable?.deal_counter_offer_id;
      if (
        deliverable.fulfillment_type !== 'Within X Days after product received'
      ) {
        delete deliverable.fulfillment_days;
      }
    });
    payload.expiration_unit = 'day';
    createCounterOffer(payload);
    closeCounterOffer();
  };

  const getSplitOption = (option) => {
    let limit = 40;
    let indexToSplit = 0;
    while (limit > -1) {
      if (option[limit] === ' ') {
        indexToSplit = limit;
        break;
      }
      limit = limit - 1;
    }
    let optionFirst = option.substr(0, indexToSplit);
    let optionSecond = option.substr(indexToSplit);
    return (
      <>
        {optionFirst} <br />
        {optionSecond}
      </>
    );
  };

  const validateEachCompensation = (deliverable, idx) => {
    let cash = Number(deliverable.compensation_cash);
    let product = Number(deliverable.compensation_product);
    if (cash === '' && product === '') {
      let compErrors = compensationErrors;
      compErrors[idx] = 'Either cash or product budget is mandatory';
      setCompensationErrors(compErrors);
    } else if (
      (cash === 0 || cash === '') &&
      (product === 0 || product === '')
    ) {
      let compErrors = compensationErrors;
      compErrors[idx] =
        'Either cash or product budget should be greater than zero';
      setCompensationErrors(compErrors);
    } else if (cash < 0 || product < 0) {
      let compErrors = compensationErrors;
      compErrors[idx] = 'Budget should be positive';
      setCompensationErrors(compErrors);
    } else {
      let compErrors = compensationErrors;
      compErrors[idx] = '';
      setCompensationErrors(compErrors);
    }
  };

  const validateCompensation = (formRef, idx = -1) => {
    let deliverables = formRef.current.values.deliverables;
    if (idx === -1) {
      deliverables.forEach((deliverable, idx) => {
        validateEachCompensation(deliverable, idx);
      });
    } else {
      validateEachCompensation(deliverables[idx], idx);
    }
  };

  const computeTotal = (formRef, setFieldValue) => {
    let deliverables = formRef.current.values.deliverables;
    let total = 0;
    deliverables.forEach((deliverable) => {
      if (deliverable?.compensation_cash) {
        total = total + Number(deliverable.compensation_cash);
      }
      if (deliverable?.compensation_product) {
        total = total + Number(deliverable.compensation_product);
      }
    });
    setFieldValue('total_price', total);
  };

  const onCashCompensationChange = (value, idx, setFieldValue, formRef) => {
    setFieldValue(`deliverables[${idx}].compensation_cash`, value).then(
      (res) => {
        validateCompensation(formRef, idx);
        computeTotal(formRef, setFieldValue);
      },
    );
  };

  const onProductCompensationChange = (value, idx, setFieldValue, formRef) => {
    setFieldValue(`deliverables[${idx}].compensation_product`, value).then(
      (res) => {
        validateCompensation(formRef, idx);
        computeTotal(formRef, setFieldValue);
      },
    );
  };

  const removeDeliverable = (deliverables, idx, setFieldValue, formRef) => {
    if (deliverables.length === 1) {
      errorToastMessage(
        'Atleast one deliverable is mandatory to create a campaign',
      );
    } else {
      let newDeliverables = [...deliverables];
      newDeliverables.splice(idx, 1);
      setFieldValue('deliverables', [...newDeliverables]).then((res) => {
        computeTotal(formRef, setFieldValue);
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={(values) => submitForm(values)}
      innerRef={formRef}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isValid,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {!showPayment && (
              <>
                <CampaignDetail className="my-4">
                  <Text
                    display="flex"
                    weight="700"
                    size="18"
                    lineHeight="24"
                    mrBottom="6"
                    className="form-label "
                    textColor="#042237">
                    Deal title <span className="text-danger ms-1"> *</span>
                  </Text>
                  <CustomInput
                    type="text"
                    name="title"
                    value={values.title}
                    disabled
                    onChange={(e) => {
                      setFieldValue('title', e.target.value);
                    }}
                  />
                  <div className="text-danger text-sm fw-500">
                    {touched.title && errors.title && errors.title}
                  </div>
                </CampaignDetail>
                <CampaignDetail className="mb-4">
                  <Text
                    display="flex"
                    weight="700"
                    size="18"
                    lineHeight="24"
                    className="form-label "
                    mrBottom="6"
                    textColor="#042237">
                    Description <span className="text-danger ms-1"> *</span>
                  </Text>
                  <CustomTextArea
                    rows={5}
                    name="description"
                    value={values.description}
                    onChange={(e) =>
                      setFieldValue('description', e.target.value)
                    }
                  />
                  <div className="text-danger text-sm fw-500">
                    {touched.description &&
                      errors.description &&
                      errors.description}
                  </div>
                </CampaignDetail>
                <CampaignDetail>
                  <Text
                    display="flex"
                    weight="700"
                    size="18"
                    lineHeight="24"
                    className="form-label "
                    mrBottom="6"
                    textColor="#042237">
                    Deliverable(s)
                  </Text>
                  {values.deliverables.map((deliverable, idx, deliverables) => (
                    <DeliverablesContainer key={idx}>
                      <div
                        className="deliverable-close-button"
                        onClick={() => {
                          removeDeliverable(
                            formRef.current.values.deliverables,
                            idx,
                            setFieldValue,
                            formRef,
                          );
                        }}>
                        <CloseIcon />
                      </div>
                      <div className="d-flex flex-column mb-3" style={{ paddingBottom: 12 }}>
                        <Text
                          display="flex"
                          weight="600"
                          size="16"
                          lineHeight="22"
                          className="form-label "
                          textColor="#435969">
                          Deliverable type
                          <span className="text-danger ms-1"> *</span>
                        </Text>
                        <div className="d-lg-block d-none">
                          <CustomSelectDropDown
                            value={deliverable.type}
                            name={`deliverables[${idx}].type`}
                            onChange={(value) =>
                              setFieldValue(`deliverables[${idx}].type`, value)
                            }>
                            {deliverableTypes?.data?.map((option) => (
                              <Option value={option} key={option}>
                                {option}
                              </Option>
                            ))}
                          </CustomSelectDropDown>
                        </div>
                        <div className="d-block d-lg-none">
                          <CustomSelectDropDown
                            value={deliverable.type}
                            name={`deliverables[${idx}].type`}
                            onChange={(value) =>
                              setFieldValue(`deliverables[${idx}].type`, value)
                            }>
                            {deliverableTypes?.data?.map((option) => (
                              <Option value={option} key={option}>
                                {option.length > 40
                                  ? getSplitOption(option)
                                  : option}
                              </Option>
                            ))}
                          </CustomSelectDropDown>
                        </div>
                        <div className="text-danger text-sm fw-500">
                          {touched?.deliverables &&
                            touched?.deliverables[idx]?.type &&
                            errors?.deliverables &&
                            errors?.deliverables[idx]?.type &&
                            errors?.deliverables[idx]?.type}
                        </div>
                      </div>
                      <div className="d-flex flex-column mb-3" style={{ paddingBottom: 12 }}>
                        <Text
                          display="flex"
                          weight="600"
                          size="16"
                          lineHeight="22"
                          className="form-label "
                          textColor="#435969">
                          Fulfillment date{' '}
                          <span className="text-danger ms-1"> *</span>
                        </Text>
                        <Row gutter={[16]}>
                          <Col xs={{span: 24}} md={{span: 24}} lg={{span: 24}}>
                            <CustomSelectDropDown
                              value={deliverable.fulfillment_type}
                              name={`deliverables[${idx}].fulfillment_type`}
                              onChange={(value) =>
                                setFieldValue(
                                  `deliverables[${idx}].fulfillment_type`,
                                  value,
                                )
                              }>
                              {fulfillmentTypes?.data?.map((option) => (
                                <Option value={option} key={option}>
                                  {option}
                                </Option>
                              ))}
                            </CustomSelectDropDown>
                            <div className="text-danger text-sm fw-500">
                              {touched?.deliverables &&
                                touched?.deliverables[idx]?.fulfillment_type &&
                                errors?.deliverables &&
                                errors?.deliverables[idx]?.fulfillment_type &&
                                errors?.deliverables[idx]?.fulfillment_type}
                            </div>
                          </Col>
                          <Col
                            xs={{span: 24}}
                            md={{span: 24}}
                            lg={{span: 12}}
                            className="mt-2">
                            {(deliverable.fulfillment_type ===
                              'Specific Date' ||
                              deliverable.fulfillment_type ===
                                'Before Date') && (
                              <>
                                <FulfillmentDatePicker
                                  disabledDate={disabledDate}
                                  name={`deliverables[${idx}].fulfillment_date`}
                                  format="MM/DD/YYYY"
                                  value={deliverable.fulfillment_date ? dayjs(deliverable.fulfillment_date) : undefined}
                                  onChange={(date) => {
                                    if (date) {
                                      setFieldValue(
                                        `deliverables[${idx}].fulfillment_date`,
                                        date
                                      );
                                    }
                                  }}></FulfillmentDatePicker>
                                <div className="text-danger text-sm fw-500">
                                  {touched?.deliverables &&
                                    touched?.deliverables[idx]
                                      ?.fulfillment_date &&
                                    errors?.deliverables &&
                                    errors?.deliverables[idx]
                                      ?.fulfillment_date &&
                                    errors?.deliverables[idx]?.fulfillment_date}
                                </div>
                              </>
                            )}
                            {deliverable.fulfillment_type ===
                              'Within X Days after product received' && (
                              <>
                                <CustomSelectDropDown
                                  value={
                                    deliverable.fulfillment_days
                                      ? deliverable.fulfillment_days
                                      : '2'
                                  }
                                  defaultValue="2"
                                  name={`deliverables[${idx}].fulfillment_days`}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `deliverables[${idx}].fulfillment_days`,
                                      value,
                                    )
                                  }>
                                  {fulfillmentDays?.data?.map((option) => (
                                    <Option value={option} key={option}>
                                      {option + ' days'}
                                    </Option>
                                  ))}
                                </CustomSelectDropDown>
                                <div className="text-danger text-sm fw-500">
                                  {touched?.deliverables &&
                                    touched?.deliverables[idx]
                                      ?.fulfillment_days &&
                                    errors?.deliverables &&
                                    errors?.deliverables[idx]
                                      ?.fulfillment_days &&
                                    errors?.deliverables[idx]?.fulfillment_days}
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="d-flex flex-column mb-3" style={{ paddingBottom: 12 }}>
                        <Text
                          display="flex"
                          weight="600"
                          size="16"
                          lineHeight="22"
                          className="form-label "
                          textColor="#435969">
                          Compensation
                          <span className="text-danger ms-1"> *</span>
                        </Text>
                        <Row gutter={[16]}>
                          <Col xs={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                            <CustomAmount>
                              <Text
                                display="flex"
                                weight="500"
                                size="15"
                                lineHeight="25"
                                opacity="0.7"
                                textColor="#435969">
                                Cash $
                              </Text>
                              <Text
                                display="flex"
                                weight="500"
                                size="18"
                                lineHeight="25"
                                textColor="#042237">
                                ${' '}
                                <input
                                  min="0"
                                  max="1000000000"
                                  className="compensation_input"
                                  type="number"
                                  value={deliverable.compensation_cash}
                                  name={`deliverables[${idx}].compensation_cash`}
                                  onChange={(e) =>
                                    onCashCompensationChange(
                                      e.target.value.replace(/^0+/, ''),
                                      idx,
                                      setFieldValue,
                                      formRef,
                                    )
                                  }
                                />
                              </Text>
                            </CustomAmount>
                          </Col>
                          <Col xs={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                            <CustomAmount>
                              <Text
                                display="flex"
                                weight="500"
                                size="15"
                                lineHeight="25"
                                opacity="0.7"
                                textColor="#435969">
                                Products $
                              </Text>
                              <Text
                                display="flex"
                                weight="500"
                                size="18"
                                lineHeight="25"
                                textColor="#042237">
                                ${' '}
                                <input
                                  min="0"
                                  max="1000000000"
                                  className="compensation_input"
                                  type="number"
                                  value={deliverable.compensation_product}
                                  name={`deliverables[${idx}].compensation_product`}
                                  onChange={(e) =>
                                    onProductCompensationChange(
                                      e.target.value.replace(/^0+/, ''),
                                      idx,
                                      setFieldValue,
                                      formRef,
                                    )
                                  }
                                />
                              </Text>
                            </CustomAmount>
                          </Col>
                        </Row>
                        <div className="text-danger text-sm fw-500">
                          {compensationErrors[idx]}
                        </div>
                      </div>
                      <Heading>
                        <li>
                          Compensation can be in Cash, Products or Both. For
                          product compensation add the estimated value of the
                          product
                        </li>
                      </Heading>
                    </DeliverablesContainer>
                  ))}
                  <div
                    className="d-flex justify-content-center align-items-center my-4"
                    onClick={() => {
                      addDeliverable(
                        formRef.current.values.deliverables,
                        setFieldValue,
                      );
                    }}>
                    <Text
                      display="flex"
                      padding="10"
                      cursor="pointer"
                      weight="700"
                      size="18"
                      lineHeight="25"
                      textColor="#7741FE">
                      + Add additional deliverable
                    </Text>
                  </div>
                  <div className="text-danger text-sm fw-500">
                    {maxDeliverableErrorMsg !== '' && maxDeliverableErrorMsg}
                  </div>
                </CampaignDetail>
                <CampaignDetail className="my-5">
                  <CustomAmount bgColor="#F2F5F7">
                    <Text
                      display="flex"
                      weight="500"
                      size="18"
                      lineHeight="25"
                      opacity="0.7"
                      textColor="#042237">
                      Total
                    </Text>
                    <Text
                      display="flex"
                      weight="500"
                      size="18"
                      lineHeight="25"
                      textColor="#042237">
                      ${' '}
                      <input
                        min="0"
                        max="1000000000"
                        className="compensation_input"
                        type="number"
                        value={values.total_price}
                        name="total_price"
                        disabled
                      />
                    </Text>
                  </CustomAmount>
                </CampaignDetail>
                <CampaignDetail className="mb-5">
                  <Text
                    display="flex"
                    weight="700"
                    size="18"
                    lineHeight="24"
                    mrBottom="6"
                    className="form-label "
                    textColor="#042237">
                    Offer expiration
                    <span className="text-danger ms-1"> *</span>
                  </Text>
                  <CustomSelectDropDown
                    value={values.expiration_period}
                    name="expiration_period"
                    onChange={(value) =>
                      setFieldValue('expiration_period', value)
                    }>
                    {expirationDays?.data?.map((option) => (
                      <Option value={option} key={option}>
                        {`${option} days`}
                      </Option>
                    ))}
                  </CustomSelectDropDown>
                </CampaignDetail>
                <PaymentModalFooter>
                  <CheckoutButton type="submit">
                    <Text
                      weight="800"
                      size="18"
                      lineHeight="23"
                      textColor="#FFFFFF"
                      cursor="pointer">
                      {'SAVE & CONTINUE'}
                    </Text>
                  </CheckoutButton>
                </PaymentModalFooter>
              </>
            )}
            {showPayment &&
              (noCashComp ? (
                <CampaignDetail>
                  <div className="mt-3 mb-5">
                    <Text
                      display="flex"
                      weight="700"
                      size="18"
                      lineHeight="24"
                      mrBottom="15"
                      mrTop="20"
                      className="form-label "
                      textColor="#042237">
                      This offer has only product compensation. No payment
                      details required.
                    </Text>
                  </div>
                  <div className="checkout-payment-form-button-container">
                    <Button
                      type="primary"
                      className="btn btn-save-var2 btn-lg mb-4"
                      style={{ paddingTop: 20 }}
                      onClick={() => {
                        handleCreateCounterOffer(values);
                      }}>
                      CREATE COUNTER OFFER
                    </Button>
                  </div>
                </CampaignDetail>
              ) : (
                <CampaignDetail>
                  <Text
                    display="flex"
                    weight="700"
                    size="18"
                    lineHeight="24"
                    mrBottom="15"
                    mrTop="20"
                    className="form-label "
                    textColor="#042237">
                    Assign payment method
                  </Text>
                  <CheckoutCardWrapperCounterOffer
                    values={values}
                    handleCreateCounterOffer={
                      handleCreateCounterOffer
                    }></CheckoutCardWrapperCounterOffer>
                </CampaignDetail>
              ))}
          </form>
        );
      }}
    </Formik>
  );
};

export default CounterOfferModal;
