import React, {useEffect, useState} from 'react';
import {Dropdown, Menu, Checkbox} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {FiltersGrid} from '../pages/style';
import {SelectDropDown} from './style';

export const CheckboxDropDown = ({
  defaultValue,
  dropdownOptions,
  optionSelected,
  height,
  initialValues
}) => {
  const [checkedList, setCheckedList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const _ = require('lodash');

  useEffect(()=> {
    if(initialValues && initialValues.length > 0) {
      setCheckedList(initialValues);
    }
  }, [initialValues, checkedList]);

  const clearAll = () => {
    setCheckedList([]);
    optionSelected([]);
    setVisible(false);
    setDropdownStatus(false);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const onChangeCheckboxGroup = (list) => {
    setCheckedList(list);
    if (list.length >= 1) {
      setDropdownStatus(true);
    } else {
      setDropdownStatus(false);
    }
    optionSelected(list);
  };

  const isChecked = (dataItem, initialValues) => {
    console.log(dataItem);
    console.log(initialValues);
    initialValues.forEach(rangeVal => {
      if(_.isEqual(rangeVal, dataItem?.value)) {
        return true;
      }
    });
    // return false;
  }

  const FilterCheckBoxOptions = ({data}) => {
    return (
      <Checkbox.Group
        onChange={onChangeCheckboxGroup}
        style={{width: 200}}
        value={initialValues? initialValues: checkedList}>
        {data.map((dataItem, i) => {
          return (
            <Checkbox
              // checked={initialValues? isChecked(dataItem, initialValues): dataItem.value}
              defaultChecked={initialValues? isChecked(dataItem, initialValues): false}
              value={dataItem.value}
              key={i}
              className="dropdown-checkbox">
              {dataItem.heading}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    );
  };

  const menu = (data) => (
    <Menu className={`action-dropdown ${height ? 'height-sm' : ''}`}>
      <Menu.Item key="1" className="dropdown-filter">
        <div>
          <FiltersGrid>
            {FilterCheckBoxOptions({
              data: dropdownOptions,
            })}
          </FiltersGrid>
        </div>
      </Menu.Item>
      <Menu.Item key="2" className="p-0">
        <hr className="m-0 border-top border-color " />
      </Menu.Item>
      <Menu.Item key="3" className="p-0">
        {/* <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}>
          Check all
        </Checkbox> */}
        <div
          className="d-flex w-100 py-3 align-items-center justify-content-center cursor-pointer fw-800"
          onClick={clearAll}>
          CLEAR ALL
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    (<Dropdown
      overlay={menu}
      trigger={['click']}
      onClick={() => setVisible(true)}
      open={visible}
      onVisibleChange={handleVisibleChange}>
      {/* <a onClick={(e) => e.preventDefault()} className="text-dark"> */}
      <SelectDropDown
        className={visible || dropdownStatus ? 'active' : ' '}
        style={{backgroundColor: '#ffffff'}}
        onClick={(e) => e.stopPropagation()}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex me-3">{defaultValue}</div>
          <div className="d-flex align-items-center">
            <DownOutlined style={{fontSize: '12px'}} />
          </div>
        </div>
      </SelectDropDown>
    </Dropdown>)
  );
};
