import React from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import {formatPrice, formatPriceCommas} from '../../../../../helpers/user';

const EarningsMobileTable = ({item, earningData, loadMore}) => {
  const history = useHistory();

  return (
    <div className="mobile-earning-card ">
      {earningData.map((data) => {
        let recordName;
        let recordCategory;
        let recordSubCategory;
        let compensationCash;
        if (data.deal === null) {
          compensationCash = data?.price;
          recordName =
            data?.order?.listing?.type === 'Experiences'
              ? data?.order?.listing?.listing_experience?.title
              : data?.order?.listing?.listing_product?.title;

          recordCategory =
            data?.order?.listing?.type === 'Experiences'
              ? data?.order?.listing?.listing_experience?.category?.name
              : data?.order?.listing?.listing_product?.category?.name;

          recordSubCategory =
            data?.order?.listing?.type === 'Experiences'
              ? data?.order?.listing?.listing_experience?.subcategory?.name
              : data?.order?.listing?.listing_product?.subcategory?.name;
        } else {
          compensationCash = data?.compensation_cash;
          recordName = data?.deal?.title;
          recordCategory = data?.deal?.deliverables[0]?.type;
        }

        return (
          <div
            key={data.id}
            className="order-card border-bottom p-3 "
            // onClick={() => handleRowClick(data?.id)}
          >
            <div>
              <div className="d-flex justify-content-between">
                <h3 className="font-18 fw-700 m-0">
                  {moment(data.createdAt).format('MMM DD,YYYY')}
                </h3>

                {data.action === 'Funds withdrawal' ? (
                  <span className="font-m color-red fw-800">
                    -{formatPriceCommas(data?.balance_change)}
                  </span>
                ) : (
                  <div className="font-m color-green fw-800">
                    {formatPriceCommas(data?.balance_change)}
                  </div>
                )}
              </div>
              <div className="text-muted d-flex mb-2 font-s fw-600">
                {data.action === 'Funds withdrawal' ? (
                  <>Funds withdrawn</>
                ) : (
                  <>
                    {data?.deal === null ? (
                      <>
                        Income &nbsp;(
                        <div
                          className="cursor-pointer text-purple text-decoration-underline"
                          onClick={() =>
                            history.push(`order/details/${data.order_id}`)
                          }>
                          Order #{data.order_id}
                        </div>
                        )
                      </>
                    ) : (
                      <>
                        Income &nbsp;(
                        <div
                          className="cursor-pointer text-purple text-decoration-underline"
                          onClick={() => history.push(`deals/${data.deal_id}`)}>
                          Deal #{data.deal_id}
                        </div>
                        )
                      </>
                    )}
                  </>
                )}
              </div>
              <h6 className="color-dark font-15 fw-500 mb-0">{recordName}</h6>
              <div className="text-muted d-flex mb-4 font-s fw-500 mt-1">
                {recordCategory}{' '}
                {recordSubCategory ? `/${recordSubCategory}` : ''}
              </div>

              {data.action !== 'Funds withdrawal' && (
                <div className="d-flex justify-content-between">
                  <div className="text-center">
                    <img
                      src={backgroundStyle?.productIcon}
                      alt="productIcon"
                      height="20px"
                      className="mb-2"
                    />
                    <h6 className="text-muted font-xs">
                      {data?.compensation_product === 0
                        ? '$0.00'
                        : `$${formatPrice(
                            parseFloat(parseFloat(data?.compensation_product)),
                          )}`}
                    </h6>
                  </div>

                  <div className="text-center">
                    <img
                      src={backgroundStyle?.cashIcon}
                      alt="rounddollar"
                      className="mb-2"
                      height="20px"
                    />
                    <h6 className="text-muted font-xs">
                      {compensationCash === 0
                        ? '$0.00'
                        : `$${formatPrice(parseFloat(compensationCash))}`}
                    </h6>
                  </div>
                  <div className="text-center">
                    <h6 className="color-muted ">Fees</h6>
                    <h6 className="text-muted font-xs">
                      {data?.processing_fee === 0
                        ? '$0.00'
                        : `${formatPriceCommas(data?.processing_fee)}`}
                    </h6>
                  </div>
                  <div className="text-center">
                    <h6 className="color-muted">Net Income</h6>
                    <h6 className="text-muted text font-xs">
                      {data?.net_income === 0
                        ? '$0.00'
                        : `$${formatPrice(parseFloat(data?.net_income))}`}
                    </h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EarningsMobileTable;
