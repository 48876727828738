import React, {useEffect} from 'react';
import {useRouteMatch, Switch, Route, Redirect} from 'react-router-dom';
import Login from './pages/login';

const UserLogin = () => {
  let {path} = useRouteMatch();

  useEffect(() => {
    let id = localStorage.getItem('id');
    if (id) {
      localStorage.clear();
    }
  }, []);

  return (
    <div>
      <Switch>
        <Route path={path} component={() => <Login />} />
        <Route exact path="/">
          <Redirect to="/athlete" />
        </Route>
        <Redirect path="*" to="/" />
      </Switch>
    </div>
  );
};

const UserModule = {
  routeProps: {
    path: '/login',
    exact: true,
    component: UserLogin,
  },
  name: 'Login',
};

export default UserModule;
