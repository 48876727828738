import React from 'react';
import {string} from 'prop-types';
import Styled from './Styled';

export const Hr = ({classNames, color, size, mrBottom, mrTop}) => (
  <Styled.Hr
    className={classNames}
    style={{marginBottom: `${mrBottom}`, marginTop: `${mrTop}`}}
    color={color}
    size={size}
  />
);

Hr.propTypes = {
  color: string,
  size: string,
  classNames: string,
};

Hr.defaultProps = {
  color: `#d3dce1`,
  size: '1',
  classNames: '',
};

export default Hr;
