import React from 'react';
import {CloseOutlined} from '@ant-design/icons';
import {CreateCampaign} from './../../../../Purchaser/components/features/Campaigns/style';

const MarkCompleteModal = (props) => {
  const {completeModalVisible, setCompleteModal, markComplete, orderDetails} =
    props;

  return (
    <CreateCampaign
      style={{top: 100}}
      width={800}
      zIndex={800}
      closeIcon={<CloseOutlined />}
      title={
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '30px',
            color: '#042237',
            padding: '20px 0',
          }}>
          <span style={{textTransform: 'capitalize'}}>
            Mark order #{orderDetails?.id} as complete
          </span>
        </div>
      }
      visible={completeModalVisible}
      onCancel={() => setCompleteModal(false)}
      footer={null}>
      <div className="px-3 px-md-5 py-3">
        <div className="font-18">
          Please confirm that you would like to mark this order as complete.
          Marking complete indicates that all the deliverables have been
          fulfilled from your side.
        </div>

        <div className="row mt-5 btn-modal-set">
          <div className="col me-3">
            <button
              className="btn btn-block btn-outline text-dark "
              onClick={() => setCompleteModal(false)}>
              Go Back
            </button>
          </div>
          <div className="col">
            <button
              className="btn btn-block btn-primary"
              onClick={markComplete}>
              Mark Complete
            </button>
          </div>
        </div>
      </div>
      {/* <CompleteDeal markDealComplete={markDealComplete} /> */}
    </CreateCampaign>
  );
};

export default MarkCompleteModal;
