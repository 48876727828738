import React, {useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import Text from '../../components/Text/Text';
import {setHeader} from '../../store/athleteflow/action';
import AppLayout from '../AppLayout';
import ProfileSettings from '../components/ProfileSettings';
import {handleSidebarTab, getNewSidebarCount} from '../../store/users/actions';
import {useDispatch, useSelector} from 'react-redux';
import 'sendbird-uikit/dist/index.css';
import http from '../../helpers/http';
import AppHeader from '../../common/components/AppHeader';
import ConversationsList from './ConversationsList';
import Loader from 'react-loader-spinner';
import {Layout} from 'antd';
import {BackArrow} from '../../../src/modules/Athlete/components/Icons';
import CustomMessageChannel from './CustomMessageChannel';
import useDeviceSize from '../../hooks/useDeviceSize';
import moment from 'moment';
import SubScriptionBanner from '../components/SubscriptionBanner';
import {useIntersectionObserver} from '../../hooks/useIntersectionObserver';
const Chat = require('twilio-chat');

const MessageHolder = (props) => {
  const {type, messageInfo} = props;
  const {Content, Sider} = Layout;
  const history = useHistory();
  const dispatch = useDispatch();
  const [conversations, setConversations] = useState({});
  const [clientState, setClient] = useState({});
  const [messagesList, setMessage] = useState([]);
  const [channelPrivate, setChannel] = useState(null);
  const [loader, setLoader] = useState(true);
  const [loadingChannel, setLoadingChannel] = useState(false);
  const [selectedConversationSid, setSelectedCoversationSid] = useState('');
  const [participantsDetails, setParticipantDetails] = useState([]);
  const [channelDetails, setSelectedChannelDetail] = useState({});
  const [attachVideo, setAttachvideo] = useState(false);
  const [attachMedia, setAttachMedia] = useState(false);
  const [channelUserName, setChannelUserName] = useState({});
  const [messageType, setMessageType] = useState();
  const [messageListView, toggleMessageSideView] = useState(true);
  const [messageFreezeStatus, setMessageFreezeStatus] = useState(false);
  const [messageRead, setMessageRead] = useState('');
  const [subScribedLoadChannel, setSubScribedChannel] = useState({});
  const ref = useRef(null);
  const interSecting = useIntersectionObserver({ref});
  const {stripeStatus, membeShipExpired} = useSelector(
    (state) => state.AthleteModuleReducer,
  );

  const size = useDeviceSize();

  useEffect(() => {
    if (
      history.location.pathname === '/purchaser/messages' ||
      history.location.pathname === '/athlete/messages'
    ) {
      dispatch(setHeader(false));
      dispatch(handleSidebarTab('messages'));
    }
  }, [history.location.pathname, dispatch]);

  /* function to  enable the video */
  useEffect(() => {
    if (channelDetails?.listing !== undefined) {
      let cardListProduct =
        channelDetails?.listing?.type === 'Experiences'
          ? channelDetails?.listing?.listing_experience
          : channelDetails?.listing?.listing_product;
      if (
        (cardListProduct?.category_id === 1 &&
          cardListProduct?.subcategory_id === 2) ||
        (cardListProduct?.category_id === 1 &&
          cardListProduct?.subcategory_id === 3)
      ) {
        setAttachvideo(true);
      } else {
        setAttachvideo(false);
      }
    } else {
      setAttachvideo(true);
    }
  }, [channelDetails]);

  const scrollDiv = (refs) => {
    if (refs !== null) {
      // scrollDivRef.current = refs;
      refs.scrollTop = refs.scrollHeight;
    }
  };

  const userRole = localStorage.getItem('userRole');

  /* function to  enable the attachment */
  useEffect(() => {
    if (channelDetails?.listing !== undefined) {
      if (userRole === 'athlete') {
        setAttachMedia(true);
      } else {
        setAttachMedia(false);
      }
    } else {
      setAttachMedia(true);
    }
  }, [channelDetails, userRole]);

  useEffect(() => {
    if (Object.keys(channelDetails).length > 0) {
      if (
        channelDetails?.completed_on !== null &&
        channelDetails?.status === 'completed'
      ) {
        let bannerShowTillDate = moment(channelDetails?.completed_on).add(
          4,
          'days',
        );

        if (
          !moment().isBetween(
            moment(channelDetails?.completed_on),
            bannerShowTillDate,
          )
        ) {
          setMessageFreezeStatus(true);
        }
      } else {
        setMessageFreezeStatus(false);
      }
    }
  }, [channelDetails]);

  useEffect(() => {
    if (size.width < '991') {
      // setMessageChannel(false);
      // toggleMessageSideView(true);
    }
  }, [size]);

  const startVideoCall = () => {
    let param;
    param = userRole === 'athlete' ? 'athlete' : 'purchaser';
    channelPrivate.sendMessage(
      'Please join video call',
      `/${param}/video-call/${selectedConversationSid}`,
    );
    window.open(`/${param}/video-call/${selectedConversationSid}`);
  };

  useEffect(() => {
    let initiateSelectedChannel = true;
    if (
      initiateSelectedChannel &&
      selectedConversationSid &&
      Object.keys(clientState).length > 0
    ) {
      (async () => {
        setLoadingChannel(true);
        setMessage([]);
        setChannel(null);
        const selectedChannel = await clientState.getChannelBySid(
          selectedConversationSid,
        );

        http.put(`twilio/conversation/${selectedConversationSid}/mark-read`);

        setChannel(selectedChannel);
        let particpatantChannel = await http.get(
          `/twilio/conversation/${selectedConversationSid}/participants`,
        );

        setParticipantDetails(particpatantChannel?.data?.data);
        setLoadingChannel(false);
      })();
    }
    return () => {
      initiateSelectedChannel = false;
    };
    /* eslint-disable-next-line */
  }, [selectedConversationSid]);

  /* function to off channel while toggling */
  useEffect(() => {
    const messageAdded = (msg) => {
      setMessage((m) => m.concat([msg]));
    };

    if (channelPrivate) {
      channelPrivate.getMessages().then((msgs) => {
        setMessage(msgs.items);
      });
      channelPrivate.on('messageAdded', messageAdded);
      return () => {
        channelPrivate.off('messageAdded', messageAdded);
      };
    }

    /* eslint-disable-next-line */
  }, [channelPrivate]);

  /* function to off channel while toggling */

  const getToken = async () => {
    const response = await http.get(
      `/twilio/token?username=user${messageInfo?.id}`,
    );
    return response.data.data.token;
  };

  const getSortedChannel = async (channelIds) => {
    const response = await http.get(`/twilio/conversations?sids=${channelIds}`);
    let res = response?.data?.data;
    if (Object.keys(conversations).length > 0) {
      setConversations({...res, ...conversations});
    } else {
      setConversations(res);
    }
    /* for default select first list */
    if (Object.keys(res)[0] && size.width > 991) {
      setSelectedCoversationSid(Object.keys(res)[0]);
      let firstChannelDetail = res[Object.keys(res)[0]];
      let messageType = firstChannelDetail?.order ? 'order' : 'deal';
      let firstChannelDscr = firstChannelDetail?.order
        ? firstChannelDetail?.order
        : firstChannelDetail?.deal;
      let profileUser = firstChannelDetail.users.find(
        (item) => item.id !== messageInfo?.id,
      );
      channelDetailsOnSelect(firstChannelDscr, profileUser, messageType);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (interSecting) {
      // loadMore()
      if (subScribedLoadChannel?.hasNextPage) {
        subScribedLoadChannel.nextPage().then((channelData) => {
          setSubScribedChannel(channelData);
          const channelIds = channelData?.items.map((data) => {
            return data.sid;
          });
          if (channelIds.length > 0) {
            getSortedChannel(channelIds);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interSecting, subScribedLoadChannel]);
  const createChannelFn = async () => {
    let token = '';
    setLoader(true);
    try {
      token = await getToken();
    } catch {
      throw new Error('unable to get token, please reload this page');
    }

    const client = await Chat.Client.create(token);

    setClient(client);
    client.on('tokenAboutToExpire', async () => {
      const token = await getToken();
      client.updateToken(token);
    });

    client.on('tokenExpired', async () => {
      const token = await getToken();
      client.updateToken(token);
    });

    try {
      const channel = await client.getSubscribedChannels();
      let subscribedChannels = await client.getSubscribedChannels();
      setSubScribedChannel(subscribedChannels);
      const channelIds = channel?.items.map((data) => {
        return data.sid;
      });

      if (channelIds.length > 0) {
        getSortedChannel(channelIds);
      } else {
        setLoader(false);
      }
      client.on('messageAdded', async (Message) => {
        getSortedChannel(channelIds);
      });

      let type = userRole === 'athlete' ? 'athlete' : 'purchaser';
      dispatch(getNewSidebarCount(type));
    } catch {
      setLoader(false);
      throw new Error('unable to create channel, please reload this page');
    }
  };

  const channelDetailsOnSelect = (messageDetails, profileUser, channelType) => {
    setSelectedChannelDetail(messageDetails);
    setChannelUserName(profileUser);
    setMessageType(channelType);
    const userRole = localStorage.getItem('userRole');
    let type = userRole === 'athlete' ? 'athlete' : 'purchaser';
    dispatch(getNewSidebarCount(type));
    if (size.width < '991') {
      // setMessageChannel(!showMessageChannel);
      toggleMessageSideView(false);
    }
  };

  // const scrollToBottom = () => {
  //   if (scrollDivRef.current !== null) {
  //     const scrollHeight = scrollDivRef.current.scrollHeight;
  //     const height = scrollDivRef.current.clientHeight;
  //     const maxScrollTop = scrollHeight - height;
  //     scrollDivRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  //   }
  // };

  useEffect(() => {
    let initiateToken = true;
    if (initiateToken && messageInfo?.id !== undefined) {
      createChannelFn(); // run it, run it
    }
    return () => {
      initiateToken = false;
    };
    /* eslint-disable-next-line */
  }, [messageInfo]);

  const sendMessageAction = (text) => {
    if (text && String(text).trim()) {
      channelPrivate && channelPrivate.sendMessage(text);
    }
  };

  return (
    <AppLayout contentClassname="msg-mobile">
      <div className="content-wrapper">
        {messageListView && (
          <>
            {type !== 'purchaser' ? (
              <AppHeader
                title="Messages"
                subTitle={
                  <p className="font-20 color-muted mb-5 fw-600">
                    Access all of your latest message threads related to your
                    orders and your deals.
                  </p>
                }
              />
            ) : (
              <div className="d-flex align-items-center justify-content-between">
                <div className=" d-block">
                  <Text
                    display="flex"
                    weight="700"
                    size="55"
                    lineHeight="64"
                    smSize="36"
                    smLineHeight="46"
                    textColor="#042237">
                    Messages
                  </Text>
                  <p className="font-20 color-muted mb-4 fw-600 mt-1">
                    Access all of your latest message threads related to your
                    orders and your deals.
                  </p>
                </div>
                <div className="d-none d-md-block">
                  <ProfileSettings myPurchase={true} />
                </div>
              </div>
            )}
          </>
        )}
        {type === 'athlete' && membeShipExpired && (
          <SubScriptionBanner stripeStatus={stripeStatus} />
        )}

        <div className="conversations-window-wrapper mt-0 mt-md-5 mb-5">
          {loader || Object.keys(conversations).length === 0 ? (
            <div className="d-flex align-items-center justify-content-center">
              {loader ? (
                <Loader type="Bars" color="#00BFFF" height={50} width={100} />
              ) : (
                <h3 className="color-muted">You've no new message</h3>
              )}
            </div>
          ) : (
            <Layout className="conversations-window-container">
              {messageListView && (
                <Sider
                  className="conversation-sidebar"
                  theme={'light'}
                  width={size.width > '991' ? '330' : '100%'}
                  collapsedWidth={0}>
                  {size.width > '768' ? (
                    <h5 className="font-20 m-0 fw-600 py-3 px-3 bg-light color-dark border-end border-bottom">
                      Inbox
                    </h5>
                  ) : (
                    <h5 className="font-22 m-0 fw-700 py-3 px-4 color-dark">
                      Inbox
                    </h5>
                  )}
                  <div className="custom-listing">
                    {Object.keys(conversations).length > 0 ? (
                      <>
                        {Object.keys(conversations).map((key, index) => {
                          return (
                            <ConversationsList
                              key={index}
                              setSelectedCoversationSid={
                                setSelectedCoversationSid
                              }
                              selectedConversationSid={selectedConversationSid}
                              channelId={key}
                              channelList={conversations[key]}
                              userId={messageInfo?.id}
                              selectedChannelDetails={channelDetailsOnSelect}
                              width={size.width}
                              client={clientState}
                              type={type}
                              channelPrivate={channelPrivate}
                              messageRead={messageRead}
                              setMessageRead={setMessageRead}
                            />
                          );
                        })}
                        {subScribedLoadChannel?.hasNextPage && (
                          <div ref={ref} className="text-center">
                            <Loader
                              type="Bars"
                              color="#00BFFF"
                              height={30}
                              width={20}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <Loader
                          type="Bars"
                          color="#00BFFF"
                          height={50}
                          width={100}
                        />
                      </div>
                    )}
                  </div>
                </Sider>
              )}
              {/* {showMessageChannel && ( */}
              <>
                {size.width < '991' && (
                  <div
                    className="text-purple cursor-pointer my-3 fw-700 g"
                    onClick={() => {
                      // setMessageChannel(!showMessageChannel);
                      toggleMessageSideView(true);
                    }}>
                    <BackArrow className="me-3" />
                    &nbsp; Back
                  </div>
                )}
                {selectedConversationSid &&
                  Object.keys(participantsDetails).length > 0 && (
                    <Content className="conversation-section">
                      <CustomMessageChannel
                        sendMessageAction={sendMessageAction}
                        messages={messagesList}
                        loader={loader}
                        participantsDetails={participantsDetails}
                        channelProxy={channelPrivate}
                        loadingChannel={loadingChannel}
                        commonMessageChannel={true}
                        enableAttachMent={attachMedia}
                        enableVideo={attachVideo}
                        channelUserName={channelUserName}
                        channelDetails={channelDetails}
                        messageType={messageType}
                        scrollDiv={scrollDiv}
                        startVideoCall={startVideoCall}
                        setMessageRead={setMessageRead}
                        type={userRole}
                        channelList={conversations[selectedConversationSid]}
                        messageStatus={
                          messageFreezeStatus ||
                          channelDetails?.status === 'declined' ||
                          channelDetails?.purchaser_marked_complete ||
                          channelDetails?.isClosed
                        }
                      />
                    </Content>
                  )}
              </>
            </Layout>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default MessageHolder;
