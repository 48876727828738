import React, {useState, useEffect} from 'react';
import {CustomTabs} from '../style';
import {Tabs} from 'antd';
import AccountDetails from './AccountDetails';
import EditProfile from './EditProfie';
import SocialProfiles from './SocialProfiles';
import Billing from './Billing';
import ChangePassword from './ChangePassword';
import {useHistory} from 'react-router-dom';
import {Show} from '../../../../components/ShowAndHide';
import {useSelector} from 'react-redux';
import useApi from '../../../../hooks/useApi';
import {profileSubmit, setHeader} from '../../../../store/athleteflow/action';
import {useDispatch} from 'react-redux';
import {passwordResetAction} from '../../../../store/users/actions';
import BrandDetails from '../../../Purchaser/components/features/Profile/BrandDetails';
import PurchaserAccountDetails from '../../../Purchaser/components/features/Profile/PurchaserAccountDetails';
import BrandAuthHeader from '../../../Purchaser/components/features/Profile/BrandAuthHeader';
import {toastMessage} from '../../../../helpers/toastMessage';
import http from '../../../../helpers/http';
import {InfoCircleOutlined} from '@ant-design/icons';
import {
  CheckoutInfoAlert,
  ResendEmailButton,
} from '../../../../modules/Purchaser/pages/style';

const MyAcconts = ({accountType = ''}) => {
  const {TabPane} = Tabs;
  const history = useHistory();
  const [tabKey, setTabKey] = useState('1');
  const [plan, setPlan] = useState('');
  const [subscription, setSubscription] = useState({});
  const [memberDeactivated, setmemberDeactivated] = useState(false);
  const [memberTableReload, setMemberReload] = useState(false);
  const [accountApiLoad, setAccountValidityApiLoad] = useState(false);
  const [subscriptionLoad, setSubscriptionLoad] = useState(false);
  const [stripeDetailsPending, setStripeDetailsPending] = useState(false);
  const [upComingPlan, setUpcomingPlan] = useState('');
  const {athleteDetails, passwordResetStatus, purchaserDetails} = useSelector(
    (state) => state.UsersReducer,
  );
  const {profileSubmitStatus, adressError} = useSelector(
    (state) => state.AthleteModuleReducer,
  );
  const [sportsData] = useApi('/athlete/sports');
  const [collegeDetail] = useApi('/athlete/colleges');
  const [team] = useApi('/athlete/teams');
  const [interests] = useApi('/athlete/lifestyle');
  const [athleteTypes] = useApi('/athlete/athlete-types');
  const dispatch = useDispatch();

  const fetchMembership = async () => {
    setSubscriptionLoad(true);
    try {
      const res = await http.get('/stripe/membership');
      if (res?.data?.data?.cancelled_plan_flag) {
        setPlan('cancel');
        setUpcomingPlan('');
      } else if (res?.data?.data?.upcoming_price?.recurring?.interval) {
        setPlan(res?.data?.data?.upcoming_price?.recurring?.interval);
        setUpcomingPlan(res?.data?.data?.upcoming_price?.recurring?.interval);
      } else {
        setPlan(res?.data?.data?.subscription?.items?.data[0]?.plan?.interval);
        setUpcomingPlan('');
      }
      setSubscription(res?.data);
      setSubscriptionLoad(false);
    } catch (err) {
      console.log(err);
      setSubscriptionLoad(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('activeAccountTab') !== null) {
      setTabKey(localStorage.getItem('activeAccountTab'));
    }
  }, [accountType]);

  useEffect(() => {
    dispatch(setHeader(true));
  }, [dispatch]);

  useEffect(() => {
    let initiateToken = true;
    const role = localStorage.getItem('userRole');
    if (initiateToken && role === 'athlete') {
      fetchMembership(); // run it, run it
    }
    return () => {
      initiateToken = false;
    };
  }, []);

  // console.log(passwordResetStatus, message, "passwordResetStatus")
  useEffect(() => {
    if (passwordResetStatus === true) {
      localStorage.clear();
      history.push('/login');
    }
  }, [passwordResetStatus, history]);

  useEffect(() => {
    if (profileSubmitStatus === true) {
      toastMessage('Profile edited successfully');
      window.scrollTo(0, 0);
    }
  }, [profileSubmitStatus]);

  useEffect(() => {
    if (profileSubmitStatus === false) {
      if (adressError !== undefined && adressError !== null && adressError[0]) {
        toastMessage(adressError[0].text);
      }
    }
  }, [profileSubmitStatus, adressError]);

  useEffect(() => {
    let initiateToken = true;
    const role = localStorage.getItem('userRole');
    if (initiateToken && role === 'athlete') {
      fetchAccountStatus(); // run it, run it
    }
    return () => {
      initiateToken = false;
    };
  }, [memberTableReload]);

  const fetchAccountStatus = async () => {
    setAccountValidityApiLoad(false);
    http
      .get('/account/account-validity')
      .then((res) => {
        if (res && res?.data && res?.data?.data) {
          if (
            res?.data?.data?.stripe_account_requirements?.currently_due.length >
              0 ||
            res?.data?.data?.stripe_account_requirements?.past_due.length > 0
          ) {
            setStripeDetailsPending(true);
          }
          if (
            res?.data?.data?.subscription_activated_on !== null &&
            !res?.data?.data?.subscription_valid
          ) {
            setmemberDeactivated(true);
          } else {
            setmemberDeactivated(false);
          }
          setAccountValidityApiLoad(true);
        }
      })
      .catch(() => {
        setAccountValidityApiLoad(true);
      });
  };

  const upadateActiveKey = (key) => {
    setTabKey(key);
  };

  const editProfileSubmit = (value) => {
    const athleteId = athleteDetails.id;
    dispatch(profileSubmit(value, athleteId));
  };

  const resetPassword = (payload) => {
    dispatch(passwordResetAction(payload));
  };

  const callback = (key) => {
    setTabKey(key);
    localStorage.removeItem('activeAccountTab');
  };

  const goToDashboard = () => {
    if (accountType === 'purchaser') {
      history.push('/purchaser/dashboard');
    } else {
      http.get('/account/account-validity').then((res) => {
        if (res && res?.data && res?.data?.data) {
          const stripeActValid = res?.data?.data?.stripe_account_valid;
          const subscriptionValid = res?.data?.data?.subscription_valid;

          if (stripeActValid && subscriptionValid) {
            history.push('/athlete/dashboard');
          } else {
            history.push('/athlete/my-shop');
          }
        }
      });
    }
  };

  // For testing
  // purchaserDetails.role = 'brand';
  const redirectStripeUrl = async () => {
    await http
      .post('/account/stripe')
      .then((res) => {
        window.location.replace(res?.data?.data?.url);
      })
      .catch((err) => {
        console.log(err.response?.data);
      });
  };

  return (
    <div className="app-wrapper account">
      {stripeDetailsPending && (
        <div className="mt-5">
          <CheckoutInfoAlert className="mb-0 pt-5">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center ">
                  <InfoCircleOutlined
                    style={{fontSize: '22px', color: '#55AF74'}}
                  />
                  <h1 className="fw-500 ms-2 font-m">
                    Stripe requires some additional information to keep your
                    account in goodstanding and to keep payouts enabled. View
                    your account details now.
                  </h1>
                </div>
                <ResendEmailButton onClick={redirectStripeUrl}>
                  View Stripe Account
                </ResendEmailButton>
              </div>
            </div>
          </CheckoutInfoAlert>
        </div>
      )}
      <div className={`profile-main ${stripeDetailsPending ? 'pt-4' : ''}`}>
        <div className="profile-section">
          <div
            className={`container tabs-account ${
              accountType === 'purchaser'
                ? 'container-xl'
                : 'edit-athlete-profile'
            }`}>
            <div
              className="text-purple cursor-pointer fw-600"
              onClick={() => goToDashboard()}>
              <i className="icon-left me-2"></i> Back to Home
            </div>
            <h1 className="font-xxl color-dark fw-700 mt-md-5 mt-3">
              My Account
            </h1>
            <p className="color-muted font-20 fw-600 mb-md-5 mb-3">
              View and update your account information.
            </p>
            {Object.keys(athleteDetails).length > 0 && (
              <Show show={accountType !== 'purchaser'}>
                <CustomTabs activeKey={tabKey} onChange={callback}>
                  <TabPane tab="Account details" key="1">
                    <AccountDetails
                      upadateActiveKey={upadateActiveKey}
                      accountType={accountType}
                      accountData={athleteDetails}
                      editProfileSubmit={editProfileSubmit}
                    />
                  </TabPane>
                  <TabPane tab="Edit profile" key="2">
                    <EditProfile
                      upadateActiveKey={upadateActiveKey}
                      accountData={athleteDetails}
                      sportsData={sportsData}
                      collegeDetail={collegeDetail}
                      team={team}
                      interests={interests}
                      editProfileSubmit={editProfileSubmit}
                      athleteTypes={athleteTypes}
                    />
                  </TabPane>
                  <TabPane tab="Social Profiles" key="3">
                    <SocialProfiles
                      upadateActiveKey={upadateActiveKey}
                      accountData={athleteDetails}
                      editProfileSubmit={editProfileSubmit}
                    />
                  </TabPane>
                  <TabPane tab="Membership" key="4">
                    <Billing
                      upadateActiveKey={upadateActiveKey}
                      fetchMembership={fetchMembership}
                      subscription={subscription}
                      plan={plan}
                      memberDeactivated={memberDeactivated}
                      setMemberReload={setMemberReload}
                      accountApiLoad={accountApiLoad}
                      subscriptionLoad={subscriptionLoad}
                      upComingPlan={upComingPlan}
                    />
                  </TabPane>
                  <TabPane tab="Change password" key="5">
                    <ChangePassword resetPassword={resetPassword} />
                  </TabPane>
                </CustomTabs>
              </Show>
            )}
            {Object.keys(purchaserDetails).length > 0 && (
              <Show show={accountType === 'purchaser'}>
                <CustomTabs activeKey={tabKey} onChange={callback}>
                  {purchaserDetails?.role === 'brand' && (
                    <TabPane tab="Brand details" key="1">
                      <BrandDetails
                        profileData={purchaserDetails}
                        mode="edit"
                        // upadateActiveKey={upadateActiveKey}
                        accountType={accountType}
                        tabKey={tabKey}
                      />
                    </TabPane>
                  )}
                  <TabPane tab="Account details" key="2">
                    <PurchaserAccountDetails
                      // upadateActiveKey={upadateActiveKey}
                      mode="edit"
                      profileData={purchaserDetails}
                      tabKey={tabKey}
                    />
                  </TabPane>
                  <TabPane tab="Billing" key="3">
                    <>
                      <BrandAuthHeader
                        mode="edit"
                        profileData={purchaserDetails}
                      />
                      <Billing upadateActiveKey={upadateActiveKey} />
                    </>
                  </TabPane>
                  <TabPane tab="Change password" key="4">
                    <>
                      <BrandAuthHeader
                        mode="edit"
                        profileData={purchaserDetails}
                      />
                      <ChangePassword resetPassword={resetPassword} />
                    </>
                  </TabPane>
                </CustomTabs>
              </Show>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyAcconts;
