import React, {useState} from 'react';
import {CustomSelect} from '../../../../Purchaser/components/Select';
import {CamFilters} from './style';
import useApi from '../../../../../hooks/useApi';

const Filters = ({filtersChanged, filtersChangedMobile, filters}) => {
  const [deliverableTypes] = useApi(`/athlete/deliverable-types`);
  const [industries] = useApi('/athlete/industries');
  const [athleteFilters] = useApi(`/athlete/athlete-filters`);
  const compensationTypes = [
    {id: 'cash', name: 'Cash'},
    {id: 'product', name: 'Product'},
  ];
  const [delType, setDelType] = useState(
    filters && filters?.deliverable_type ? filters?.deliverable_type : '',
  );
  const [industry, setIndustry] = useState(
    filters && filters?.industry_id ? filters?.industry_id : '',
  );
  const [compLevel, setCompLevel] = useState(
    filters && filters?.compensation_level ? filters?.compensation_level : '',
  );
  const [compType, setCompType] = useState(
    filters && filters?.compensation_type ? filters?.compensation_type : '',
  );

  const valChanged = (value, key) => {
    if (filtersChanged) {
      filtersChanged(value, key);
    }
    switch (key) {
      case 'deliverable_type':
        setDelType(value);
        break;
      case 'industry_id':
        setIndustry(value);
        break;
      case 'compensation_type':
        setCompType(value);
        break;
      case 'compensation_level':
        setCompLevel(value);
        break;
      default:
        break;
    }
  };

  const applyFilters = () => {
    const filters = {};
    if (delType !== '') {
      filters.deliverable_type = delType;
    }
    if (industry !== '') {
      filters.industry_id = industry;
    }
    if (compType !== '') {
      filters.compensation_type = compType;
    }
    if (compLevel !== '') {
      filters.compensation_level = compLevel;
    }
    filtersChangedMobile(filters);
  };

  return (
    <CamFilters className="d-flex align-items-center">
      <div className="fw-700 font-m color-dark me-4 d-none d-lg-flex">
        Filter by
      </div>
      <div className="d-block">
        <div className="mb-3 d-block d-md-flex">
          <CustomSelect
            defaultValue={
              filters && filters?.deliverable_type
                ? filters?.deliverable_type
                : 'Deliverable type'
            }
            marginRight="mr-2"
            className="cam-filter-dropdown cam-del-type mb-lg-0 mb-3"
            dropdownOptions={deliverableTypes?.data}
            handleSelect={(value) => {
              valChanged(value, 'deliverable_type');
            }}
          />
          <CustomSelect
            defaultValue={
              filters && filters?.industry_id
                ? filters?.industry_id
                : "Brand's industry"
            }
            marginRight="mr-2"
            className="cam-filter-dropdown"
            dropdownOptions={industries?.data?.rows}
            handleSelect={(value) => {
              valChanged(value, 'industry_id');
            }}
          />
        </div>
        <div>
          <CustomSelect
            defaultValue={
              filters && filters?.compensation_level
                ? filters?.compensation_level
                : 'Compensation level'
            }
            marginRight="mr-2"
            modify={'comp_level'}
            dropdownOptions={athleteFilters?.data?.compensation_range}
            className="cam-filter-dropdown mb-lg-0 mb-3"
            handleSelect={(value) => {
              valChanged(value, 'compensation_level');
            }}
          />
          <CustomSelect
            defaultValue={
              filters && filters?.compensation_type
                ? filters?.compensation_type
                : 'Compensation Type'
            }
            marginRight="mr-0"
            dropdownOptions={compensationTypes}
            className="cam-filter-dropdown mb-lg-0 mb-3"
            handleSelect={(value) => {
              valChanged(value, 'compensation_type');
            }}
          />
        </div>
        <div className="d-flex d-lg-none">
          <button
            type="button"
            onClick={applyFilters}
            className="btn btn-primary mb-4 mx-4">
            APPLY
          </button>
        </div>
      </div>
    </CamFilters>
  );
};

export default Filters;
