import {combineReducers} from 'redux';

import PostReducer from './posts/reducer';
import AthleteModuleReducer from './athleteflow/reducer';
import BrandModuleReducer from './brandflow/reducer';
import UsersReducer from './users/reducer';

const appReducer = combineReducers({
  PostReducer,
  AthleteModuleReducer,
  BrandModuleReducer,
  UsersReducer,
});

export default appReducer;
