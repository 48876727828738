import http from '../../helpers/http';

export const triggerGetTransactions = (payload) => {
  let url = `/${payload.role}/transaction?page=${payload.page}&pageSize=${payload.pageSize}`;
  if (payload?.is_subscription_related && payload?.role === 'athlete') {
    url = `${url}&is_subscription_related=${payload.is_subscription_related}`;
  }

  return http.get(url);
};

export const triggerGetNewCount = (payload) => {
  let url = `/${payload}/new-count`;
  return http.get(url);
};
