import React from 'react';
import {Row, Col, Dropdown} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import '../../modules/Purchaser/pages/pages.scss';
import Text from '../../components/Text/Text';
import ProfileImage from '../../modules/Purchaser/components/ProfileImage';
import {backgroundStyle} from '../../utils/staticStrings';
import {
  OfferButton,
  CustomDivider,
  BackgroundImage,
  AthleteDesktopContainer,
  AboutHeading,
  AboutSectionButton,
  CustomDropDownOption,
  CustomAthleteTag,
} from '../../modules/Purchaser/pages/style';
import Hr from '../../components/Hr/index';
import {athleteData} from '../../modules/Purchaser/pages/mock';
import {EditProfileButton} from './style';
import {useHistory, useLocation} from 'react-router-dom';
import profilePlaceholderImage from '../../assets/images/profilePlaceholder.png';
import {useSelector} from 'react-redux';

const AboutSection = ({
  type,
  openCustomOffer,
  flow,
  athleteDetails,
  openCampaignInvite,
  openSubmitOffer,
  size,
}) => {
  const history = useHistory();
  const location = useLocation();
  // const [showAllOffers, setShowAllOffers] = useState(false);
  const {purchaserDetails} = useSelector((state) => state.UsersReducer);

  // const onClickMore = () => {
  //   setShowAllOffers(true);
  // };

  const showOffers = () => {
    let athData;
    // if (!showAllOffers)  = athleteData.slice(0, 6);
    athData = athleteData;
    return (
      <>
        {athData.map((item, i) => {
          return (
            <Col
              key={i}
              className="d-flex align-items-center"
              xs={{span: 12}}
              sm={{span: 12}}
              md={{span: 12}}
              lg={{span: 12}}>
              {item?.src}
              <Text
                display="flex"
                weight="500"
                size="14"
                mrLeft="5"
                lineHeight="20"
                textColor="#435969">
                {item?.name}
              </Text>
            </Col>
          );
        })}
        {/* {!showAllOffers && ( */}
      </>
    );
  };
  const menu = () => (
    <CustomDropDownOption>
      <div onClick={openCustomOffer}>
        <Text
          display="flex"
          weight="800"
          size="17"
          className="py-3 align-items-center justify-content-center"
          lineHeight="23"
          textColor="#FFFFFF"
          cursor="pointer">
          CUSTOM OFFERS
        </Text>
      </div>
      <Hr mrBottom="0px" mrTop="0px" />
      <div onClick={openCampaignInvite}>
        <Text
          display="flex"
          weight="800"
          size="17"
          className="py-3 align-items-center justify-content-center"
          lineHeight="23"
          textColor="#FFFFFF"
          cursor="pointer">
          INVITE TO APPLY
        </Text>
      </div>
    </CustomDropDownOption>
  );

  const editProfile = () => {
    history.push(`/athlete/account-details`);
  };
  return (
    <AboutSectionButton>
      <AthleteDesktopContainer>
        {athleteDetails?.image_url ? (
          <ProfileImage
            width={size.width > 1300 ? 300 : size.width > 992 ? 200 : 150}
            desktopImgWidth={
              size.width > 1300 ? 300 : size.width > 992 ? 200 : 150
            }
            borderRadius={size.width > 992 ? 30 : 15}
            className="img-fluid radius-l"
            src={athleteDetails?.image_url}
          />
        ) : (
          <ProfileImage
            width={size.width > 1200 ? 300 : 150}
            desktopImgWidth={size.width > 1200 ? 300 : 150}
            borderRadius={size.width > 992 ? 30 : 15}
            className="img-fluid radius-l"
            src={profilePlaceholderImage}
          />
        )}
        <BackgroundImage
          className="img-fluid"
          src={backgroundStyle?.bgPic}
          alt="background"
        />
      </AthleteDesktopContainer>

      {flow === 'athlete' ? (
        <>
          <EditProfileButton onClick={editProfile}>
            <i className="icon-edit me-2"></i>
            EDIT PROFILE INFO
          </EditProfileButton>
        </>
      ) : purchaserDetails && Object.keys(purchaserDetails).length > 0 ? (
        (purchaserDetails?.role === 'brand' ||
          purchaserDetails?.role === 'fan') && (
          <>
            {purchaserDetails?.role === 'fan' && (
              <OfferButton
                onClick={() => {
                  openSubmitOffer({});
                }}>
                <div>
                  <Text
                    display="flex"
                    weight="800"
                    size="17"
                    cursor="pointer"
                    lineHeight="23"
                    textColor="#FFFFFF">
                    CUSTOM OFFER
                  </Text>
                </div>
              </OfferButton>
            )}

            {purchaserDetails?.role === 'brand' && (
              <Dropdown overlay={menu}>
                <OfferButton>
                  <Text
                    display="flex"
                    cursor="pointer"
                    weight="800"
                    size="17"
                    lineHeight="23"
                    textColor="#FFFFFF">
                    WORK TOGETHER
                    <div
                      className="d-flex align-items-center"
                      style={{position: 'absolute', right: '20px', top: '40%'}}>
                      <DownOutlined
                        style={{fontSize: '12px', color: 'white'}}
                      />
                    </div>
                  </Text>
                </OfferButton>
              </Dropdown>
            )}
          </>
        )
      ) : (
        <OfferButton
          onClick={() => {
            history.push({
              pathname: '/login',
              state: {prev: location.pathname},
            });
          }}>
          <div>
            <Text
              display="flex"
              weight="800"
              size="17"
              cursor="pointer"
              lineHeight="23"
              textColor="#FFFFFF">
              CUSTOM OFFER
            </Text>
          </div>
        </OfferButton>
      )}

      <Row className="mt-0 mt-md-4 mw-354" gutter={[0, 10]}>
        <AboutHeading>
          <Text
            display="flex"
            weight="500"
            size="15"
            lineHeight="20"
            className="about-profile"
            textColor="#435969"
            opacity="0.6">
            Send {athleteDetails.name} a custom offer for:
          </Text>
        </AboutHeading>
        {showOffers()}
      </Row>

      <CustomDivider />
      <div className="mb-4">
        <Text
          display="flex"
          weight="700"
          size="22"
          lineHeight="32"
          textColor="#042237">
          About {athleteDetails?.name}
        </Text>
        <Text
          display="flex"
          weight="500"
          size="16"
          lineHeight="26"
          textColor="#435969">
          {athleteDetails?.bio}
        </Text>
      </div>
      {athleteDetails?.athlete?.hometown && (
        <div className="mb-4">
          <Text
            display="flex"
            weight="700"
            size="22"
            lineHeight="32"
            textColor="#042237">
            Hometown
          </Text>
          <Text
            display="flex"
            weight="500"
            size="16"
            lineHeight="26"
            textColor="#435969">
            {athleteDetails?.athlete?.hometown}
          </Text>
          {athleteDetails?.athlete?.alma_mater_high_school && (
            <Text
              display="flex"
              weight="500"
              size="16"
              lineHeight="26"
              textColor="#435969">
              {athleteDetails?.athlete?.alma_mater_high_school}
            </Text>
          )}
        </div>
      )}
      {athleteDetails?.athlete?.alma_mater_college && (
        <div className="mb-4">
          <Text
            display="flex"
            weight="700"
            size="22"
            lineHeight="32"
            textColor="#042237">
            College
          </Text>
          <Text
            display="flex"
            weight="500"
            size="16"
            lineHeight="26"
            textColor="#435969">
            {athleteDetails?.athlete?.alma_mater_college}
            {athleteDetails?.athlete?.alma_mater_college_grad_year && (
              <>
                , Graduation&nbsp;
                {athleteDetails?.athlete?.alma_mater_college_grad_year}
              </>
            )}
          </Text>
        </div>
      )}

      {athleteDetails?.athlete?.accolades && (
        <div className="mb-4">
          <Text
            display="flex"
            weight="700"
            size="22"
            lineHeight="32"
            textColor="#042237">
            Accolades
          </Text>
          <Text
            display="flex"
            weight="500"
            size="16"
            lineHeight="26"
            textColor="#435969">
            {athleteDetails?.athlete?.accolades}
            {/* <List style={{ paddingLeft: '0px !important' }}>
              <li>Top 100 4-star recruit out of high school</li>
              <li>Became starting PG as a freshman, 2019</li>
              <li>Academic Honor Roll, 2020, 2021</li>
              <li>Voted preseason All-American, 2021</li>
            </List> */}
          </Text>
        </div>
      )}
      {athleteDetails?.athlete?.lifestyles && (
        <div className="mb-4">
          <Text
            display="flex"
            weight="700"
            size="22"
            lineHeight="32"
            textColor="#042237">
            Lifestyle & Interests
          </Text>
          <div className="d-block overflow-hidden">
            <div className="">
              {athleteDetails?.athlete?.lifestyles.map((item, index, list) => (
                <div key={index}>
                  {/* <div className="d-inline-block" key={1}>
                  {item.name}
                </div> */}

                  <CustomAthleteTag>{item.name}</CustomAthleteTag>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </AboutSectionButton>
  );
};

export default AboutSection;
