import {
  LOGIN_ACTION,
  HANDLE_SUCCESS_RESPONSE,
  HANDLE_MESSAGE,
  LOADER,
  GET_PROFILE,
  FETCH_PROFILE,
  SIDE_BAR_TAB,
  FETCH_ATHLETE,
  SET_ATHLETE_DATA,
  GET_ATHLETE_DATA,
  SET_ATHLETE_DETAILS,
  PASSWORD_RESET,
  PASSWORD_RESET_STATUS,
  LOGOUT,
  CLEAR_LOCAL_DATA,
  SET_ATHLETE_INFINITE_DATA,
  GET_PURCHASER_DATA,
  SET_PURCHASER_DETAILS,
  GET_TRANSACTIONS,
  SET_TRANSACTIONS,
  APPEND_TRANSACTIONS,
  FETCH_NEWLY_JOINED_ATHLETE,
  SET_NEWLYJOINED_ATHLETE_DETAILS,
  LOGGED_OUT_STATUS,
  GET_SIDEBAR_COUNT,
  SET_NEW_COUNT,
  SET_PROFILE_CREATION,
  SET_TOKEN_ERROR,
  SET_NO_ATHLETE,
} from './actionTypes';

export const userLogin = (user) => {
  return {
    type: LOGIN_ACTION,
    payload: user,
  };
};

export const handleSidebarTab = (page) => {
  return {
    type: SIDE_BAR_TAB,
    payload: page,
  };
};

export const handleMessage = (data) => {
  return {
    type: HANDLE_MESSAGE,
    payload: data,
  };
};

export const handleLoginSuccess = (data) => {
  return {
    type: HANDLE_SUCCESS_RESPONSE,
    payload: data,
  };
};

export const loader = (data) => {
  return {
    type: LOADER,
    payload: data,
  };
};

export const handleProfileData = (data) => {
  return {
    type: GET_PROFILE,
    payload: data,
  };
};

export const createPucrhaseProfile = (data) => {
  return {
    type: SET_PROFILE_CREATION,
    payload: data,
  };
};
export const JWtError = (data) => {
  return {
    type: SET_TOKEN_ERROR,
    payload: data,
  };
};
export const fetchProfileData = (data) => {
  return {
    type: FETCH_PROFILE,
    payload: data,
  };
};

export const fetchAthleteProfile = (data) => {
  return {
    type: FETCH_ATHLETE,
    payload: data,
  };
};

export const fetchNewlyJoineAthletes = (data) => {
  return {
    type: FETCH_NEWLY_JOINED_ATHLETE,
    payload: data,
  };
};

export const handleNewlyAddedAthletes = (data) => {
  return {
    type: SET_NEWLYJOINED_ATHLETE_DETAILS,
    payload: data,
  };
};
export const handleAthleteData = (data) => {
  return {
    type: SET_ATHLETE_DATA,
    payload: data,
  };
};

export const setAthleteMoreLists = (data) => {
  return {
    type: SET_ATHLETE_INFINITE_DATA,
    payload: data,
  };
};

export const fetchAthleteData = (data) => {
  return {
    type: GET_ATHLETE_DATA,
    payload: data,
  };
};

export const handleAthleteDetail = (data) => {
  return {
    type: SET_ATHLETE_DETAILS,
    payload: data,
  };
};

export const passwordResetAction = (data) => {
  return {
    type: PASSWORD_RESET,
    payload: data,
  };
};

export const passwordResetStatus = (data) => {
  return {
    type: PASSWORD_RESET_STATUS,
    payload: data,
  };
};

export const changeLoggedOutStatus = (data) => {
  return {
    type: LOGGED_OUT_STATUS,
    payload: data,
  };
};

export const logoutData = () => {
  return {
    type: LOGOUT,
  };
};

export const clearLocalData = () => {
  return {
    type: CLEAR_LOCAL_DATA,
  };
};

export const fetchPurchaserData = (id) => {
  return {
    type: GET_PURCHASER_DATA,
    id: id,
  };
};

export const handlePurchaserDetail = (data) => {
  return {
    type: SET_PURCHASER_DETAILS,
    payload: data,
  };
};

export const getTransactions = (data) => {
  return {
    type: GET_TRANSACTIONS,
    payload: data,
  };
};

export const setTransactions = (data) => {
  return {
    type: SET_TRANSACTIONS,
    payload: data,
  };
};

export const appendTransactions = (data) => {
  return {
    type: APPEND_TRANSACTIONS,
    payload: data,
  };
};

export const getNewSidebarCount = (data) => {
  return {
    type: GET_SIDEBAR_COUNT,
    payload: data,
  };
};

export const setSideBarCount = (data) => {
  return {
    type: SET_NEW_COUNT,
    payload: data,
  };
};
export const atheleteNotfound = (data) => {
  return {
    type: SET_NO_ATHLETE,
    payload: data,
  };
};
