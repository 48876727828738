import React, { useState } from 'react';
import { icons } from '../../utils/staticStrings';

const PasswordInput = (props) => {
  const [showPwd, setShowPwd] = useState(false);

  const handleShow = () => {
    setShowPwd((prev) => !prev);
  };

  return (
    <React.Fragment>
      <input
        type={showPwd ? 'text' : 'password'}
        name={props.name}
        className="form-control form"
        placeholder={props.placeHolder ? props.placeHolder : 'Your Password'}
        onChange={props.changeEvent}
        onBlur={props.blurEvent}
        value={props.value}
        autoComplete={props.value}
      />
      <div className="password-eye">
        {showPwd ? (
          <div onClick={handleShow}>
            <img src={icons.eyeIcon} alt="eye-icon" />
          </div>
        ) : (
          <div onClick={handleShow}>
            <img src={icons.hideIcon} alt="hide-icon" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default PasswordInput;
