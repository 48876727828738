import {
  GET_MODAL,
  LOGIN_ACTION,
  HANDLE_MESSAGE,
  HANDLE_SUCCESS_RESPONSE,
  LOADER,
  GET_PROFILE,
  SIDE_BAR_TAB,
  SET_ATHLETE_DATA,
  SET_ATHLETE_DETAILS,
  PASSWORD_RESET_STATUS,
  CLEAR_LOCAL_DATA,
  SET_ATHLETE_INFINITE_DATA,
  SET_PURCHASER_DETAILS,
  APPEND_TRANSACTIONS,
  SET_TRANSACTIONS,
  SET_NEWLYJOINED_ATHLETE_DETAILS,
  LOGGED_OUT_STATUS,
  SET_NEW_COUNT,
  SET_PROFILE_CREATION,
  SET_TOKEN_ERROR,
  SET_NO_ATHLETE,
} from './actionTypes';

const initialState = {
  isLoading: false,
  page: '',
  user: {},
  modal: {
    type: '',
    modalProps: null,
  },
  message: {
    type: '',
    text: '',
  },
  profileData: {},
  athletsProfile: [],
  newlyJoinedCardList: [],
  athletesCount: 0,
  athleteDetails: {},
  purchaserDetails: {},
  transactionsCount: 0,
  transactions: [],
  passwordResetStatus: false,
  clearData: false,
  loggedOutStatus: false,
  newCount: {},
  brandProfileCreation: false,
  jwtError: false,
  atheleteNotfound: false,
};

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ACTION:
      state = {
        ...state,
        user: action.payload,
        isLoading: true,
        clearData: false,
      };
      break;
    case SIDE_BAR_TAB:
      state = {...state, page: action.payload};
      break;
    case HANDLE_MESSAGE:
      state = {
        ...state,
        message: {
          type: action.type,
          text: action.payload,
        },
      };
      break;
    case HANDLE_SUCCESS_RESPONSE:
      state = {
        ...state,
        user: action.payload,
      };
      break;
    case GET_MODAL:
      state = {...state, type: action.payload.type};
      break;
    case GET_PROFILE:
      state = {...state, profileData: action.payload};
      break;
    case SET_TOKEN_ERROR:
      state = {...state, jwtError: action.payload};
      break;
    case LOADER:
      state = {...state, isLoading: action.payload};
      break;
    case SET_ATHLETE_DATA:
      state = {
        ...state,
        athletsProfile: action.payload.rows,
        athletesCount: action.payload.count,
      };
      break;
    case SET_ATHLETE_INFINITE_DATA:
      state = {
        ...state,
        athletsProfile: [...state.athletsProfile, ...action.payload.rows],
        athletesCount: action.payload.count,
      };
      break;
    case PASSWORD_RESET_STATUS:
      state = {
        ...state,
        passwordResetStatus: action.payload,
      };
      break;
    case LOGGED_OUT_STATUS:
      state = {
        ...state,
        loggedOutStatus: action.payload,
      };
      break;
    case SET_ATHLETE_DETAILS:
      state = {
        ...state,
        athleteDetails: action.payload,
      };
      break;
    case SET_NEWLYJOINED_ATHLETE_DETAILS:
      state = {
        ...state,
        newlyJoinedCardList: action.payload,
      };
      break;
    case SET_PURCHASER_DETAILS:
      state = {
        ...state,
        purchaserDetails: action.payload,
      };
      break;
    case SET_TRANSACTIONS:
      state = {
        ...state,
        transactions: action.payload.rows,
        transactionsCount: action.payload.count,
      };
      break;
    case APPEND_TRANSACTIONS:
      state = {
        ...state,
        transactions: [...state.transactions, ...action.payload.rows],
        transactionsCount: action.payload.count,
      };
      break;
    case CLEAR_LOCAL_DATA:
      state = {
        ...state,
        user: {},
        clearData: true,
      };
      break;
    case SET_NEW_COUNT:
      state = {
        ...state,
        newCount: action.payload,
      };
      break;

    case SET_PROFILE_CREATION:
      state = {
        ...state,
        brandProfileCreation: action.payload,
      };
      break;
    case SET_NO_ATHLETE:
      state = {
        ...state,
        atheleteNotfound: action.payload,
      };
      break;
    default:
      state = {...state};
      break;
  }

  return state;
};

export default UsersReducer;
