import React, {useEffect} from 'react';
import {useRouteMatch, Switch, Route, Redirect} from 'react-router-dom';
import Register from './components/features/Register';
import Profile from './components/features/Profile';
import ForgotPassword from './components/features/ForgotPassword';
import ResetPassword from './components/features/ResetPassword';
import Dashboard from './components/features/Dashboard';
import MyShop from './components/features/MyShop';
import CreateListing from './components/features/CreateListing';
import CreateTemplateListing from './components/features/CreateTemplateListing';
import ChooseListingMethod from './components/features/ChooseListingMethod';
import Campaigns from './components/features/Campaigns';
import MyAccount from '../UserLogin/Components/MyAcconts';
import Deals from './components/features/Deals';
import PricingAthlete from './components/features/PricingAthlete';
import PricingConfirmation from './components/features/PricingAthlete/PricingConfirmation';
import MyShopUnlisted from './components/features/MyShopUnlisted';
import Messages from './components/features/Message';
import AtheleteOrders from './components/features/Orders';
import AtheleteOrdersDetails from './components/features/Orders/OrderDetails';
import AthleteProfile from '../Purchaser/pages/Profile';
import BankDetailSuccess from './components/features/PricingAthlete/BankDetailSuccess';
import {fetchAthleteData} from '../../store/users/actions';
import {useDispatch} from 'react-redux';
import Earnings from './components/features/Earnings';
import SocialProfile from './components/features/Profile/AddSocialAccountDetails';
import VideoCall from '../../common/VideoCall';
// import ViewPublicProfile from './components/features/ViewPublicProfile';

const isAuthenticated = () => {
  if (localStorage.getItem('athletUp-token') !== null) {
    return true;
  } else {
    localStorage.clear();
    return false;
  }
};

export const PrivateRoute = ({component: Component, ...rest}) => {
  const isLoggedIn = isAuthenticated();
  const destinationPath = rest.location.pathname;
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{pathname: '/login', state: {from: destinationPath}}} />
        );
      }}
    />
  );
};

const Athlete = () => {
  let {path} = useRouteMatch();
  const dispatch = useDispatch();
  useEffect(() => {
    let id = localStorage.getItem('id');
    if (id !== null) {
      dispatch(fetchAthleteData(id));
    }
  }, [dispatch]);

  return (
    <div>
      <Switch>
        <Route path={`${path}/register`} component={() => <Register />} />
        <Route path={`${path}/profile`} component={() => <Profile />} />

        <Route
          path={`${path}/add-social-account`}
          component={() => <SocialProfile />}
        />
        <Route
          path={`${path}/view-profile/:profileId`}
          component={() => <AthleteProfile flow="athlete" />}
        />
        <Route
          path={`${path}/forgot-password`}
          component={() => <ForgotPassword />}
        />
        <Route
          path={`${path}/reset-password`}
          component={() => <ResetPassword />}
        />
        <PrivateRoute
          path={`${path}/dashboard`}
          component={() => <Dashboard />}
        />
        <PrivateRoute path={`${path}/my-shop`} component={() => <MyShop />} />
        <PrivateRoute
          path={`${path}/my-shop-unlisted`}
          component={() => <MyShopUnlisted />}
        />
        <PrivateRoute
          path={`${path}/choose-listing-method`}
          component={() => <ChooseListingMethod />}
        />
        <PrivateRoute
          path={`${path}/create-template-listing`}
          component={() => <CreateTemplateListing />}
        />
        <PrivateRoute
          path={`${path}/create-listing`}
          component={() => <CreateListing />}
        />
        <PrivateRoute
          path={`${path}/campaigns`}
          component={() => <Campaigns />}
        />
        <PrivateRoute
          path={`${path}/messages`}
          component={() => <Messages />}
        />
        <PrivateRoute path={`${path}/deals`} component={() => <Deals />} />
        <PrivateRoute
          path={`${path}/account-details`}
          component={() => <MyAccount />}
        />
        <PrivateRoute
          path={`${path}/price-details`}
          component={() => <PricingAthlete />}
        />
        <PrivateRoute
          path={`${path}/bank-detail-confirmation`}
          component={() => <PricingConfirmation />}
        />
        <PrivateRoute
          path={`${path}/bank-detail-success`}
          component={() => <BankDetailSuccess />}
        />
        <PrivateRoute
          path={`${path}/orders`}
          component={() => <AtheleteOrders />}
        />
        <PrivateRoute
          path={`${path}/order/details/:orderId`}
          component={() => <AtheleteOrdersDetails />}
        />
        <PrivateRoute
          path={`${path}/earnings`}
          component={() => <Earnings />}
        />
        <PrivateRoute
          path={`${path}/video-call/:channelId`}
          component={() => <VideoCall />}
        />

        {/* <Route exact path="/">
          <Redirect to="/login" />
        </Route> */}
        <Redirect path="*" to="/" />
      </Switch>
    </div>
  );
};

const AthleteModule = {
  routeProps: {
    path: '/athlete',
    exact: false,
    component: Athlete,
  },
  name: 'Athlete',
};

export default AthleteModule;
