import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  createStripeSubscription,
  createStripeSubscriptionRedirect,
  getSubscriptions,
  setCouponValid,
  setHeader,
} from '../../../../../store/athleteflow/action';
import {headerSection} from '../../../../../utils/staticStrings';
import SwitchButton from '../../../../../common/components/SwitchButton';
import {pricingSection} from '../../../../../utils/staticStrings';
import PricingList from './PricingList';
import {Tooltip} from 'antd';
import Loader from 'react-loader-spinner';
import Text from '../../../../../components/Text/Text';
import {CustomInput} from '../../../../Purchaser/pages/style';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';
import http from '../../../../../helpers/http';

const PricingAthlete = () => {
  const dispatch = useDispatch();
  const [switchTab, setSwitchTab] = useState(true); // true: yearly
  // const [checkOutScreen, setCheckoutScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disableStartTrial, setDisableStartTrial] = useState(true);
  const [yearPriceId, setYearPriceId] = useState('');
  const [monthPriceId, setMonthPriceId] = useState('');
  const [coupon, setCoupon] = useState('');
  const [enteredCoupon, setEnteredCoupon] = useState('');
  const [pricePack, setPricePack] = useState('');
  const [trialDay, setTrialDay] = useState(false);
  const [trialMonths, setTrialMonths] = useState(2);
  const [trialDays, setTrialDays] = useState();
  const [memberDeactivated, setmemberDeactivated] = useState(false);
  const tooltipText =
    'This fee is charged by our credit card processor (Stripe) at 2.9% +$0.30 per transaction. None of this fee, nor any other commission from your sales, goes to AthleteUp.';
  const {createStripeSubscriptionUrl, subscriptions, couponValid} = useSelector(
    (state) => state.AthleteModuleReducer,
  );

  useEffect(() => {
    dispatch(setHeader(false));
    dispatch(getSubscriptions(''));
  }, [dispatch]);

  useEffect(() => {
    let initiateToken = true;
    const role = localStorage.getItem('userRole');
    if (initiateToken && role === 'athlete') {
      fetchAccountStatus(); // run it, run it
    }
    return () => {
      initiateToken = false;
    };
  }, []);
  useEffect(() => {
    if (couponValid === false) {
      errorToastMessage('Coupon not valid');
    }
    setDisableStartTrial(false);
    dispatch(setCouponValid(null));
  }, [couponValid, dispatch]);

  useEffect(() => {
    setDisableStartTrial(true);
    if (coupon !== '') {
      dispatch(getSubscriptions(coupon));
    }
  }, [dispatch, coupon]);

  useEffect(() => {
    // console.log('subscriptions-->', subscriptions);
    if (
      subscriptions !== undefined &&
      Object.values(subscriptions).length > 0
    ) {
      let priceArray = subscriptions?.prices;
      let selectedPrice;
      let selectTrialPeriodDays;
      if (switchTab) {
        let selectedPriceYearly = priceArray.filter(
          (a) => a.recurring.interval === 'year',
        );
        selectedPrice = (selectedPriceYearly[0]?.unit_amount / 12) * 0.01;
        selectTrialPeriodDays =
          selectedPriceYearly[0]?.recurring?.trial_period_days;
      } else {
        let selectedPriceMonthly = priceArray.filter(
          (a) => a.recurring.interval === 'month',
        );
        selectedPrice = selectedPriceMonthly[0]?.unit_amount * 0.01;
        selectTrialPeriodDays =
          selectedPriceMonthly[0]?.recurring?.trial_period_days;
      }
      setPricePack(selectedPrice.toFixed());
      if (selectTrialPeriodDays > 30) {
        setTrialDay(false);
        setTrialDays(selectTrialPeriodDays);
        setTrialMonths(Math.floor(selectTrialPeriodDays / 30));
      } else {
        setTrialDay(true);
        setTrialMonths(0);
        setTrialDays(selectTrialPeriodDays);
      }
    }
  }, [subscriptions, switchTab]);

  useEffect(() => {
    if (
      subscriptions !== undefined &&
      Object.values(subscriptions).length > 0
    ) {
      setDisableStartTrial(false);
      const price1 = subscriptions?.prices[0];
      const price2 = subscriptions?.prices[1];
      if (price1?.recurring?.interval === 'year') {
        setYearPriceId(price1.id);
        if (
          subscriptions?.metadata?.promo &&
          subscriptions?.metadata?.promo !== ''
        ) {
          if (switchTab) {
            setTimeout(() => {
              toastMessage('Coupon applied');
            }, 1500);
          }
        }
      } else if (price1?.recurring?.interval === 'month') {
        setMonthPriceId(price1.id);
        if (
          subscriptions?.metadata?.promo &&
          subscriptions?.metadata?.promo !== ''
        ) {
          if (!switchTab) {
            setTimeout(() => {
              toastMessage('Coupon applied');
            }, 1500);
          }
        }
      }
      if (price2?.recurring?.interval === 'year') {
        setYearPriceId(price2.id);
        if (
          subscriptions?.metadata?.promo &&
          subscriptions?.metadata?.promo !== ''
        ) {
          if (switchTab) {
            setTimeout(() => {
              toastMessage('Coupon applied');
            }, 1500);
          }
        }
      } else if (price2?.recurring?.interval === 'month') {
        setMonthPriceId(price2.id);
        if (
          subscriptions?.metadata?.promo &&
          subscriptions?.metadata?.promo !== ''
        ) {
          if (!switchTab) {
            setTimeout(() => {
              toastMessage('Coupon applied');
            }, 1500);
          }
        }
      }
    }
    // setIsLoading(false);
  }, [subscriptions, switchTab]);

  useEffect(() => {
    if (
      createStripeSubscriptionUrl !== 'null' &&
      createStripeSubscriptionUrl !== undefined
    ) {
      // console.log('createStripeSubscriptionUrl ', createStripeSubscriptionUrl);
      const redirectUrl = createStripeSubscriptionUrl;
      dispatch(createStripeSubscriptionRedirect('null'));
      window.location.replace(redirectUrl);
    }
  }, [createStripeSubscriptionUrl, dispatch]);

  let cardDetails = pricingSection.pricingSectionAthleteListCard;

  const toggleTab = () => {
    setSwitchTab(!switchTab);
  };

  const fetchAccountStatus = async () => {
    setIsLoading(true);
    http.get('/account/account-validity').then((res) => {
      if (res && res?.data && res?.data?.data) {
        if (
          res?.data?.data?.subscription_activated_on !== null &&
          !res?.data?.data?.subscription_valid &&
          res?.data?.data?.stripe_account_valid
        ) {
          setmemberDeactivated(true);
        } else {
          setmemberDeactivated(false);
        }
        setIsLoading(false);
      }
    });
  };

  const startFreeTrialAction = () => {
    setIsLoading(true);
    let priceId = '';
    if (switchTab) priceId = yearPriceId;
    else priceId = monthPriceId;
    const subscriptionDetails = {
      price_id: priceId,
    };
    dispatch(createStripeSubscription(subscriptionDetails));
  };

  const applyCoupon = () => {
    if (coupon === enteredCoupon) {
      toastMessage('Coupon already applied');
    } else {
      setCoupon(enteredCoupon);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loader">
          <Loader type="Bars" color="#00BFFF" height={50} width={100} />
        </div>
      )}
      {!isLoading && (
        <div className="pricing-section p-0">
          <div className="header-style header-light header-fixed">
            <div className="athlete-page-bg">
              <div className="container">
                <div className="d-flex justify-content-between align-items-center py-3">
                  <a href="/">
                    <img
                      alt="headerlogo"
                      src={headerSection.headerLogoBlack}
                      className="d-inline-block align-top"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-main pricing-comp">
            <div className="mw-570 mx-auto text-center">
              <h1 className="font-xl color-body fw-700 text-center mb-3">
                <>
                  {memberDeactivated
                    ? 'Renew your membership now'
                    : 'Start your free trial now'}
                </>
              </h1>
              {memberDeactivated ? (
                <p className="text-center font-20 color-muted fw-500">
                  Entering your credit card details will let you renew your
                  membership and publish your Storefront
                </p>
              ) : (
                <p className="text-center font-20 color-muted fw-500">
                  {/* Entering your credit card details will let you publish your
                  Storefront and will begin a{' '}
                  {trialMonths === 0 ? trialDays : trialMonths}-
                  {trialDay ? 'days' : 'months'} Free Trial */}
                  Get started for free, no credit card required.
                </p>
              )}
              <SwitchButton switchTab={switchTab} toggleTab={toggleTab} />
              <div className="content">
                <div className=" center sammary-pad">
                  <div>
                    <div className="center pad0 ">
                      <div className={`sammary`}>
                        <div className="sammary-bg">
                          {!memberDeactivated && (
                            <div className="col justify-content-center">
                              <button
                                type="button"
                                className="btn btn-primary prbutton font-15">
                                {trialMonths === 0 ? trialDays : trialMonths}
                                {trialDay ? ' Days' : ' Months'} Free Trial
                              </button>
                            </div>
                          )}
                          <div
                            className={`row strikeBotton justify-content-center mt-4 mb-4 athlete-title ${
                              memberDeactivated ? 'pt-3' : ''
                            }`}>
                            <div className="col-9 start">
                              <div className="pricingCard">
                                <span className="cardTitle col-12 col-sm-7 col-md-7 col-lg-4 col-xl-3 col-xxl-3 cols">
                                  {switchTab
                                    ? cardDetails?.yearlySection1text1
                                    : cardDetails?.monthlySection1text1}
                                </span>
                                <br className="mobile-break" />
                                <span className="cardSubtitle col-12 col-sm-7 col-md-7 col-lg-4 col-xl-3 col-xxl-3 cols">
                                  {switchTab
                                    ? cardDetails?.yearlySection1text2
                                    : cardDetails?.monthlySection1text2}
                                </span>
                              </div>
                            </div>
                            <div className={'col-3 cardSpacing mob-price'}>
                              <span className="cardMainText">
                                <div className="d-md-flex cardMainTextContent ms-0 align-items-center">
                                  <div className="pricetext ">
                                    {subscriptions !== undefined &&
                                      Object.values(subscriptions).length >
                                        0 && <>${pricePack}</>}
                                  </div>
                                  <div className="monthtext ms-1">
                                    {cardDetails?.section1text4}
                                  </div>
                                </div>
                              </span>
                            </div>
                            <div className="mob-border-bottom"></div>
                          </div>
                          {cardDetails?.section2text1 && (
                            <div className="row pricingSub ">
                              <div className="col-9 start">
                                <span className="cardSubTexts">
                                  {cardDetails?.section2text1}
                                </span>
                              </div>
                              <div className="col-3 cardSpacing">
                                <span className="cardMainText">
                                  {cardDetails?.section2text2}
                                </span>
                              </div>
                            </div>
                          )}
                          {cardDetails?.section3text1 && (
                            <div className="row strikeBotton ">
                              <div className="col-9 start">
                                <span className="cardSubTexts">
                                  {cardDetails?.section3text1}
                                </span>
                              </div>
                              <div className="col-3 cardSpacing">
                                <span className="cardMainText">
                                  {cardDetails?.section3text2}
                                </span>
                              </div>
                              <div className="mob-border-bottom"></div>
                            </div>
                          )}
                          {cardDetails?.section4text1 && (
                            <div className="row center credit-card-text">
                              <div className="col-12 ">
                                <span>{cardDetails?.section4text1}</span>
                                <Tooltip placement="bottom" title={tooltipText}>
                                  <span className="price-img">
                                    <img
                                      src={cardDetails?.section4texticon}
                                      alt="priceimg"
                                    />
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <PricingList />

                    {/* {!memberDeactivated && (
                      <>
                        <p className="fw-500 color-dark">
                          Your card will not be charged until the end of the
                           free trial and you'll be reminded by email of the end
                           of the trial 7 days before the trial ends.
                        </p>
                        <p className="color-muted font-s mb-5">
                          You can cancel anytime
                        </p>
                      </>
                    )} */}
                    <div className="">
                      <div className="row">
                        <div
                          className="col-md-8 col-12"
                          style={{textAlign: 'left'}}>
                          <Text
                            display="flex"
                            weight="600"
                            size="16"
                            lineHeight="24"
                            mrBottom="6"
                            className="form-label "
                            textColor="#042237">
                            Coupon code
                          </Text>
                          <CustomInput
                            type="text"
                            value={enteredCoupon}
                            style={{maxWidth: '365px'}}
                            onChange={(event) => {
                              setEnteredCoupon(event.target.value);
                            }}
                          />
                        </div>
                        <div
                          className="col-md-4 col-12"
                          style={{marginTop: '30px'}}>
                          <button
                            className="btn btn-primary ct-button ms-3 ms-lg-0 cursor-pointer coupon-button"
                            onClick={() => {
                              applyCoupon();
                            }}
                            disabled={enteredCoupon === ''}
                            type="primary">
                            Apply
                          </button>

                          {/* Clear button if required */}
                          {/* <button
                            className="btn ct-button view-more ms-4 align-items-center text-center cursor-pointer justify-content-center"
                            style={{}}
                            onClick={()=> {clearCoupon()}}
                            disabled={enteredCoupon===""}
                            type="primary">
                            Clear
                          </button> */}
                        </div>
                      </div>
                    </div>
                    {subscriptions?.metadata?.promo &&
                      subscriptions?.metadata?.promo !== '' && (
                        <div className="d-flex align-items-center justify-content-center mt-4">
                          <span>
                            <span className="text-purple me-1">{coupon} </span>{' '}
                            {`coupon applied and you have ${trialMonths} Months (${trialDays} days) of free trial`}
                          </span>
                        </div>
                      )}
                    {couponValid === false && (
                      <div className="d-flex align-items-center justify-content-center mt-4">
                        <span className="text-purple me-1">{coupon} </span>{' '}
                        coupon applied is invalid
                      </div>
                    )}
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="a-styled-button text-uppercase styledBtn"
                        onClick={startFreeTrialAction}
                        disabled={disableStartTrial}>
                        {memberDeactivated
                          ? 'Renew membership'
                          : 'Start for free'}
                      </button>
                      <p className="color-muted font-s mt-3">
                        No credit card, no commitment
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PricingAthlete;
