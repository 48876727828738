import React from 'react';

const InputNumber = (props) => {
  const {name, placeholder, numberRef, setValue, setFieldValue, val} = props;

  return (
    <input
      className="pro-input"
      name={name}
      type="number"
      value={val}
      // value={values.tiktok_followers}
      placeholder={placeholder}
      onKeyDown={(e) => {
        ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
      }}
      ref={numberRef}
      onWheel={(e) => {
        if (numberRef && numberRef.current && numberRef.current.blur) {
          numberRef.current.blur();
        }
      }}
      onChange={(e) => {
        if (e.target.value > 0) {
          setValue(e.target.value);
          setFieldValue(name, e.target.value);
        } else {
          setValue(0);
          setFieldValue(name, '');
        }
      }}
    />
  );
};

export default InputNumber;
