import React from 'react';
import {headerSection} from '../../../../../utils/staticStrings';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {logoutData} from '../../../../../store/users/actions';

const BrandDetails = (props) => {
  let {profileData, mode} = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(logoutData());
    history.push('/login');
  };

  return (
    <div className="athlete-page-bg">
      <div className="profile-container">
        <div className="header-style header-light header-fixed athlete-bg">
          <div className="container-fluid px-4 page-contain athlete-page-bg">
            <div className="d-flex justify-content-between align-items-center py-3 profile-wrapper">
              <a href="/">
                <img
                  alt="headerlogo"
                  src={headerSection.headerLogoBlack}
                  className="d-inline-block align-top"
                />
              </a>
              {mode === 'create' && (
                <div className="text-dark d-flex fw-600 d-none d-md-flex">
                  Signed in as {profileData?.name}.
                  <div
                    onClick={logout}
                    className=" ms-2 text-purple cursor-pointer text-link">
                    Sign out
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandDetails;
