import {
  HANDLE_ERROR,
  HANDLE_REGISTER,
  HANDLE_REGISTER_SUCCESS,
  HANDLE_RESET_PASSWORD,
  HANDLE_FORGOT_PASSWORD,
  HANDLE_MESSAGE,
  HANDLE_SUCCESS_RESPONSE,
  HANDLE_SPORTS_LISTS,
  HANDLE_SPORTS_LISTS_SUCCESS,
  HANDLE_PROFILE_SUBMIT,
  SET_HEADER,
  CREATE_LIST,
  CREATE_STATUS,
  RESEND_FERIFICATION,
  ATHLETE_LOGIN_DATA,
  RESET_EMAIL_STATUS,
  ATHLETE_PROFILE_SUBMIT_STATUS,
  HANDLE_CREATE_STRIPE_ACCOUNT,
  REDIRECT_CREATE_STRIPE_ACCOUNT,
  CREATE_STRIPE_SUBSCRIPTION,
  GET_SHOP_LISTING,
  SET_SHOP_LISTING,
  REDIRECT_CREATE_STRIPE_SUBSCRIPTION,
  GET_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS,
  GET_STRIPE_STATUS,
  SET_STRIPE_STATUS,
  EDIT_LIST_STATUS,
  LOADER,
  SET_ATH_CAMPAIGNS,
  GET_ATH_CAMPAIGNS,
  GET_INVITED_CAMPAIGNS,
  SET_INVITED_CAMPAIGNS,
  APPEND_ATH_CAMPAIGNS,
  GET_ATH_CAMPAIGN_DATA,
  SET_ATH_CAMPAIGN_DATA,
  GET_ATH_DEALS,
  SET_ATH_DEALS,
  APPEND_ATH_DEALS,
  GET_ATH_DEAL_DATA,
  SET_ATH_DEAL_DATA,
  SET_ORDER_STATUS,
  SET_ORDER_COMPLETD_SUCESS,
  GET_ORDER_DETAILS,
  SET_ORDER_DETAILS,
  APPLY_TO_CAMPAIGN,
  APPLY_CAMPAIGN_STATUS,
  EDIT_LISTING,
  EDIT_LISTING_STATUS,
  UPDATE_DEAL_STATUS,
  DEAL_STATUS_CHANGED,
  COUNTER_OFFER,
  COUNTER_OFFER_CREATED,
  UPDATE_DEAL_COUNTER_OFFER_STATUS,
  ORDER_CANCEL,
  ORDER_CANCEL_STATUS,
  BROWSER_LIST_BACK,
  VIDEO_TOKEN,
  SET_COUPON_VALID,
} from './actionTypes';

export const hanldeError = (error) => {
  return {
    type: HANDLE_ERROR,
    payload: error,
  };
};

export const hanldeLoginSuccess = (data) => {
  return {
    type: HANDLE_SUCCESS_RESPONSE,
    payload: data,
  };
};

export const submitRegister = (data) => {
  return {
    type: HANDLE_REGISTER,
    payload: data,
  };
};

export const handlerRegisterSuccess = (data) => {
  return {
    type: HANDLE_REGISTER_SUCCESS,
    payload: data,
  };
};
export const submitResetPassword = (data) => {
  return {
    type: HANDLE_RESET_PASSWORD,
    payload: data,
  };
};

export const submitForgotPassword = (data) => {
  return {
    type: HANDLE_FORGOT_PASSWORD,
    payload: data,
  };
};
export const handleMessage = (data) => {
  return {
    type: HANDLE_MESSAGE,
    payload: data,
  };
};
export const handleSportsLists = () => {
  return {
    type: HANDLE_SPORTS_LISTS,
  };
};

export const handleSportsListsSuccess = (data) => {
  return {
    type: HANDLE_SPORTS_LISTS_SUCCESS,
    payload: data,
  };
};

export const profileSubmit = (data, id) => {
  return {
    type: HANDLE_PROFILE_SUBMIT,
    payload: data,
    id: id,
  };
};

export const setLoader = (data) => {
  return {
    type: LOADER,
    payload: data,
  };
};

export const setHeader = (data) => {
  return {
    type: SET_HEADER,
    payload: data,
  };
};

export const createListing = (data) => {
  return {
    type: CREATE_LIST,
    payload: data,
  };
};
export const handleCreateListStatus = (data) => {
  return {
    type: CREATE_STATUS,
    payload: data,
  };
};
export const resendVerificationEmail = (data) => {
  return {
    type: RESEND_FERIFICATION,
    payload: data,
  };
};
export const resetVerificationStatus = (data) => {
  return {
    type: RESET_EMAIL_STATUS,
    payload: data,
  };
};

export const handleAthleteUserData = (data) => {
  return {
    type: ATHLETE_LOGIN_DATA,
    payload: data,
  };
};

export const profileSubmitSuccess = (data) => {
  return {
    type: ATHLETE_PROFILE_SUBMIT_STATUS,
    payload: data,
  };
};

export const createStripeAccount = () => {
  return {
    type: HANDLE_CREATE_STRIPE_ACCOUNT,
  };
};

export const createStripeAccountRedirect = (data) => {
  return {
    type: REDIRECT_CREATE_STRIPE_ACCOUNT,
    payload: data.url,
  };
};

export const getSubscriptions = (data) => {
  return {
    type: GET_SUBSCRIPTIONS,
    payload: data,
  };
};

export const handleSubscriptionsData = (data) => {
  return {
    type: SET_SUBSCRIPTIONS,
    payload: data,
  };
};

export const createStripeSubscription = (data) => {
  return {
    type: CREATE_STRIPE_SUBSCRIPTION,
    payload: data,
  };
};

export const createStripeSubscriptionRedirect = (url) => {
  return {
    type: REDIRECT_CREATE_STRIPE_SUBSCRIPTION,
    payload: url,
  };
};

export const setCouponValid = (data) => {
  return {
    type: SET_COUPON_VALID,
    payload: data,
  };
};

export const getShopListing = (data) => {
  return {
    type: GET_SHOP_LISTING,
    payload: data,
  };
};
export const handleAthleteListsData = (data) => {
  return {
    type: SET_SHOP_LISTING,
    payload: data,
  };
};

export const getStripeStatus = () => {
  return {
    type: GET_STRIPE_STATUS,
  };
};

export const setStripeStatus = (data) => {
  return {
    type: SET_STRIPE_STATUS,
    payload: data,
  };
};

export const editStatusListing = (data) => {
  return {
    type: EDIT_LIST_STATUS,
    payload: data,
  };
};

export const getCampaigns = (data) => {
  return {
    type: GET_ATH_CAMPAIGNS,
    payload: data,
  };
};

export const setCampaigns = (data) => {
  return {
    type: SET_ATH_CAMPAIGNS,
    payload: data,
  };
};

export const appendCampaigns = (data) => {
  return {
    type: APPEND_ATH_CAMPAIGNS,
    payload: data,
  };
};

export const getInvitedCampaigns = (data) => {
  return {
    type: GET_INVITED_CAMPAIGNS,
    payload: data,
  };
};

export const setInvitedCampaigns = (data) => {
  return {
    type: SET_INVITED_CAMPAIGNS,
    payload: data,
  };
};

export const getAthleteCampaignData = (data) => {
  return {
    type: GET_ATH_CAMPAIGN_DATA,
    payload: data,
  };
};

export const setAthleteCampaignData = (data) => {
  return {
    type: SET_ATH_CAMPAIGN_DATA,
    payload: data,
  };
};

export const getDeals = (data) => {
  return {
    type: GET_ATH_DEALS,
    payload: data,
  };
};

export const setDeals = (data) => {
  return {
    type: SET_ATH_DEALS,
    payload: data,
  };
};

export const appendDeals = (data) => {
  return {
    type: APPEND_ATH_DEALS,
    payload: data,
  };
};

export const getAthleteDealData = (data) => {
  return {
    type: GET_ATH_DEAL_DATA,
    payload: data,
  };
};

export const setAthleteDealData = (data) => {
  return {
    type: SET_ATH_DEAL_DATA,
    payload: data,
  };
};
export const orderComplete = (data) => {
  return {
    type: SET_ORDER_STATUS,
    payload: data,
  };
};

export const orderCompleteStatus = (data) => {
  return {
    type: SET_ORDER_COMPLETD_SUCESS,
    payload: data,
  };
};
export const fetchOrderDetails = (data) => {
  return {
    type: GET_ORDER_DETAILS,
    payload: data,
  };
};

export const orderDetails = (data) => {
  return {
    type: SET_ORDER_DETAILS,
    payload: data,
  };
};

export const applyToCampaign = (data, campaignId) => {
  return {
    type: APPLY_TO_CAMPAIGN,
    payload: data,
    campaignId: campaignId,
  };
};

export const handleApplyCampaignStatus = (data) => {
  return {
    type: APPLY_CAMPAIGN_STATUS,
    payload: data,
  };
};

export const editListDetails = (data) => {
  return {
    type: EDIT_LISTING,
    payload: data,
  };
};

export const editListDetailsStatus = (data) => {
  return {
    type: EDIT_LISTING_STATUS,
    payload: data,
  };
};
export const updateDealStatus = (data, dealId) => {
  return {
    type: UPDATE_DEAL_STATUS,
    payload: data,
    dealId: dealId,
  };
};

export const updateDealCounterOfferStatus = (data, dealId) => {
  return {
    type: UPDATE_DEAL_COUNTER_OFFER_STATUS,
    payload: data,
    dealId: dealId,
  };
};

export const handleDealStatusChanged = (data) => {
  return {
    type: DEAL_STATUS_CHANGED,
    payload: data,
  };
};

export const createCounterOffer = (data, dealId) => {
  return {
    type: COUNTER_OFFER,
    payload: data,
    dealId: dealId,
  };
};

export const handleCounterOfferCreated = (data) => {
  return {
    type: COUNTER_OFFER_CREATED,
    payload: data,
  };
};

export const orderCancel = (data) => {
  return {
    type: ORDER_CANCEL,
    payload: data,
  };
};
export const handleCancelOrder = (data) => {
  return {
    type: ORDER_CANCEL_STATUS,
    payload: data,
  };
};

export const browserBack = (data) => {
  return {
    type: BROWSER_LIST_BACK,
    payload: data,
  };
};

export const twiliotoken = (data) => {
  return {
    type: VIDEO_TOKEN,
    payload: data,
  };
};
