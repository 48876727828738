import https from '../helpers/http';
import axios from 'axios';
import {errorToastMessage, toastMessage} from '../helpers/toastMessage';

const access_token = localStorage.getItem('athletUp-token');
const config = {
  headers: {
    Authorization: access_token ? `Bearer ${access_token}` : '',
  },
};

export const UploadAssets =
  (fileObj, handleUpload, setLoadingImageStatus) => () => {
    // console.log(fileObj, 'Gallery');

    https
      .get(`/aws/signed-url?file_name=${fileObj.file.name}`, config)
      .then((res) => {
        setLoadingImageStatus(true);
        axios
          .put(`${res.data.data.pre_signed_url}`, fileObj.file, {
            headers: {'Content-Type': res.data.data.content_type},
          })
          .then((_) => {
            toastMessage('success', 'Image uploaded successfully');
            setLoadingImageStatus(false);
            handleUpload(res.data.data.get_url);
          })
          .catch((err) => {
            errorToastMessage(err);
            alert(err);
          });
      })
      .catch((err) => {
        errorToastMessage('Error Uploading');
      });
  };
