import {
  HANDLE_ERROR,
  HANDLE_SUCCESS_RESPONSE,
  HANDLE_REGISTER_SUCCESS,
  HANDLE_MESSAGE,
  HANDLE_SPORTS_LISTS_SUCCESS,
  HANDLE_PROFILE_SUBMIT_SUCCESS,
  SET_HEADER,
  CREATE_LIST,
  CREATE_STATUS,
  ATHLETE_LOGIN_DATA,
  RESET_EMAIL_STATUS,
  ATHLETE_PROFILE_SUBMIT_STATUS,
  REDIRECT_CREATE_STRIPE_ACCOUNT,
  SET_SHOP_LISTING,
  REDIRECT_CREATE_STRIPE_SUBSCRIPTION,
  SET_SUBSCRIPTIONS,
  SET_STRIPE_STATUS,
  LOADER,
  SET_ATH_CAMPAIGNS,
  SET_INVITED_CAMPAIGNS,
  APPEND_ATH_CAMPAIGNS,
  SET_ATH_CAMPAIGN_DATA,
  SET_ATH_DEALS,
  APPEND_ATH_DEALS,
  SET_ATH_DEAL_DATA,
  SET_ORDER_COMPLETD_SUCESS,
  SET_ORDER_DETAILS,
  APPLY_CAMPAIGN_STATUS,
  EDIT_LISTING_STATUS,
  DEAL_STATUS_CHANGED,
  COUNTER_OFFER_CREATED,
  ORDER_CANCEL_STATUS,
  BROWSER_LIST_BACK,
  VIDEO_TOKEN,
  SET_COUPON_VALID,
} from './actionTypes';

const initialState = {
  isLoading: false,

  error: {
    type: '',
    message: '',
  },
  message: {
    type: '',
    text: '',
  },
  registerSuccess: 'null',
  sportsListsArray: [],
  header: true,
  listingData: [],
  createStatus: false,
  athleteData: {},
  resetEmailStatus: false,
  profileSubmitStatus: false,
  subscriptions: {},
  createStripeAccountUrl: 'null',
  createStripeSubscriptionUrl: 'null',
  shopListing: [],
  stripeStatus: {},
  campaigns: [],
  campaignsCount: 0,
  invitedCampaigns: [],
  athleteCampaignData: {},
  deals: [],
  currentDeals: [],
  dealsCount: 0,
  dealData: {},
  orderCompleted: false,
  orderDetails: {},
  applyCampaignStatus: false,
  editLstStatus: false,
  dealStatusChanged: null,
  counterOfferCreated: false,
  orderCancelStatus: false,
  listBackButton: false,
  videoToken: '',
  couponValid: null,
  membeShipExpired: false,
  disableMenu: false,
};

const AthleteModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_ERROR:
      state = {
        ...state,
        error: {
          type: action.type,
          message: action.payload,
        },
      };
      break;
    case LOADER:
      state = {
        ...state,
        isLoading: action.payload,
      };
      break;
    case HANDLE_MESSAGE:
      state = {
        ...state,
        message: {
          type: action.type,
          text: action.payload,
        },
      };
      break;
    case HANDLE_SUCCESS_RESPONSE:
      state = {
        ...state,
        user: action.payload,
      };
      break;
    case HANDLE_REGISTER_SUCCESS:
      state = {
        ...state,
        registerSuccess: action.payload,
      };
      break;
    case HANDLE_SPORTS_LISTS_SUCCESS:
      state = {
        ...state,
        sportsListsArray: action.payload,
      };
      break;
    case HANDLE_PROFILE_SUBMIT_SUCCESS:
      state = {
        ...state,
      };
      break;

    case SET_HEADER:
      state = {
        ...state,
        header: action.payload,
      };
      break;
    case CREATE_LIST:
      state = {
        ...state,
        listingData: action.payload,
      };
      break;
    case CREATE_STATUS:
      state = {
        ...state,
        createStatus: action.payload,
      };
      break;
    case ATHLETE_LOGIN_DATA:
      state = {
        ...state,
        athleteData: action.payload,
      };
      break;
    case RESET_EMAIL_STATUS:
      state = {
        ...state,
        resetEmailStatus: action.payload,
      };
      break;
    case ATHLETE_PROFILE_SUBMIT_STATUS:
      state = {
        ...state,
        profileSubmitStatus: action.payload,
      };
      break;
    case REDIRECT_CREATE_STRIPE_ACCOUNT:
      state = {
        ...state,
        createStripeAccountUrl: action.payload,
      };
      break;
    case SET_SUBSCRIPTIONS:
      state = {
        ...state,
        subscriptions: action.payload,
      };
      break;
    case REDIRECT_CREATE_STRIPE_SUBSCRIPTION:
      state = {
        ...state,
        createStripeSubscriptionUrl: action.payload,
      };
      break;
    case SET_COUPON_VALID:
      state = {
        ...state,
        couponValid: action.payload,
      };
      break;
    case SET_SHOP_LISTING:
      state = {
        ...state,
        shopListing: action.payload,
      };
      break;
    case SET_STRIPE_STATUS:
      state = {
        ...state,
        stripeStatus: action.payload?.data,
        membeShipExpired: action?.payload?.status,
        disableMenu: action?.payload?.menuStatus,
      };
      break;
    case SET_ATH_CAMPAIGNS:
      state = {
        ...state,
        campaigns: action.payload.rows,
        campaignsCount: action.payload.count,
      };
      break;
    case APPEND_ATH_CAMPAIGNS:
      state = {
        ...state,
        campaigns: [...state.campaigns, ...action.payload.rows],
        campaignsCount: action.payload.count,
      };
      break;
    case SET_INVITED_CAMPAIGNS:
      state = {
        ...state,
        invitedCampaigns: action.payload,
      };
      break;
    case SET_ATH_CAMPAIGN_DATA:
      state = {
        ...state,
        athleteCampaignData: action.payload,
      };
      break;
    case SET_ATH_DEALS:
      state = {
        ...state,
        deals: action.payload.rows,
        currentDeals: action.payload.rows,
        dealsCount: action.payload.count,
      };
      break;
    case APPEND_ATH_DEALS:
      state = {
        ...state,
        deals: [...state.deals, ...action.payload.rows],
        currentDeals: action.payload.rows,
        dealsCount: action.payload.count,
      };
      break;
    case SET_ATH_DEAL_DATA:
      state = {
        ...state,
        dealData: action.payload,
      };
      break;
    case SET_ORDER_COMPLETD_SUCESS:
      state = {
        ...state,
        orderCompleted: action.payload,
      };
      break;
    case SET_ORDER_DETAILS:
      state = {
        ...state,
        orderDetails: action.payload,
      };
      break;
    case APPLY_CAMPAIGN_STATUS:
      state = {
        ...state,
        applyCampaignStatus: action.payload,
      };
      break;
    case EDIT_LISTING_STATUS:
      state = {
        ...state,
        editLstStatus: action.payload,
      };
      break;
    case DEAL_STATUS_CHANGED:
      state = {
        ...state,
        dealStatusChanged: action.payload,
      };
      break;
    case COUNTER_OFFER_CREATED:
      state = {
        ...state,
        counterOfferCreated: action.payload,
      };
      break;
    case ORDER_CANCEL_STATUS:
      state = {
        ...state,
        orderCancelStatus: action.payload,
      };
      break;
    case BROWSER_LIST_BACK:
      state = {
        ...state,
        listBackButton: action.payload,
      };
      break;
    case VIDEO_TOKEN:
      state = {
        ...state,
        videoToken: action.payload,
      };
      break;
    default:
      state = {...state};
      break;
  }
  return state;
};

export default AthleteModuleReducer;
