import React, {useState, useRef} from 'react';
import {profileSection2} from '../../../../../utils/staticStrings';
import '../features.scss';
import {Formik} from 'formik';
import * as yup from 'yup';
import CustomNumber from '../../../../../common/components/CustomNumber';

const AddSocialAccount = (props) => {
  const {profileData} = props;
  const [instaFollower, setInstaValidation] = useState();
  const [twitterFollower, setTwitterValidation] = useState();
  const [facebookFollower, setFacbookValidaton] = useState();
  const [tikTokFollower, setTiktokValidation] = useState();
  const [youtubeFollwer, setYouTubeValidation] = useState();

  const schema = yup.object({
    urls: yup.object().shape({
      instagram_url:
        instaFollower > 0 && yup.string().required('Instagram url is required'),
      twitter_url:
        twitterFollower > 0 && yup.string().required('Twitter url required'),
      facebook_url:
        facebookFollower > 0 && yup.string().required('Facebook url required'),
      tiktok_url:
        tikTokFollower > 0 && yup.string().required('Ticktock url required'),
      youtube_url:
        youtubeFollwer > 0 && yup.string().required('Youtube url required'),
    }),
  });

  const instaRef = useRef(null);
  const twitterRef = useRef(null);
  const facebookRef = useRef(null);
  const tiktokRef = useRef(null);
  const youTubeRef = useRef(null);

  return (
    <>
      <div className="container-fluid p-0">
        {props.mode !== 'edit' && (
          <>
            <div className="profile-text">{profileSection2.text1}</div>
            <div className="profile-title">{profileSection2.title}</div>
            <div className="profile-text1 mt-2">{profileSection2.text2}</div>
          </>
        )}
        <div className="profilepart-1 mt-5">
          <Formik
            initialValues={{
              urls: {
                tiktok_url: profileData?.social?.urls?.tiktok_url
                  ? profileData?.social?.urls?.tiktok_url
                  : '',
                youtube_url: profileData?.social?.urls?.youtube_url
                  ? profileData?.social?.urls?.youtube_url
                  : '',
                facebook_url: profileData?.social?.urls?.facebook_url
                  ? profileData?.social?.urls?.facebook_url
                  : '',
                twitter_url: profileData?.social?.urls?.twitter_url
                  ? profileData?.social?.urls?.twitter_url
                  : '',
                instagram_url: profileData?.social?.urls?.instagram_url
                  ? profileData?.social?.urls?.instagram_url
                  : '',
              },
              tiktok_followers: profileData?.social?.tiktok_followers
                ? profileData?.social?.tiktok_followers
                : '',
              facebook_followers: profileData?.social?.facebook_followers
                ? profileData?.social?.facebook_followers
                : '',
              youtube_followers: profileData?.social?.youtube_followers
                ? profileData?.social?.youtube_followers
                : '',
              instagram_followers: profileData?.social?.instagram_followers
                ? profileData?.social?.instagram_followers
                : '',
              twitter_followers: profileData?.social
                ? profileData?.social?.twitter_followers
                : '',
            }}
            validationSchema={schema}
            onSubmit={(values, {setSubmitting}) => {
              const socialFormPayload = {
                ...((values.urls.tiktok_url !== '' ||
                  values.urls.instagram_url !== '' ||
                  values.urls.twitter_url !== '' ||
                  values.urls.youtube_url !== '' ||
                  values.urls.facebook_url !== '') && {
                  urls: {
                    ...(values.urls.instagram_url !== '' && {
                      instagram_url: values.urls.instagram_url,
                    }),
                    ...(values.urls.twitter_url !== '' && {
                      twitter_url: values.urls.twitter_url,
                    }),
                    ...(values.urls.tiktok_url !== '' && {
                      tiktok_url: values.urls.tiktok_url,
                    }),
                    ...(values.urls.youtube_url !== '' && {
                      youtube_url: values.urls.youtube_url,
                    }),
                    ...(values.urls.facebook_url !== '' && {
                      facebook_url: values.urls.facebook_url,
                    }),
                  },
                }),
                instagram_followers:
                  values.instagram_followers === ''
                    ? 0
                    : values.instagram_followers,
                twitter_followers:
                  values.twitter_followers === ''
                    ? 0
                    : values.twitter_followers,
                tiktok_followers:
                  values.tiktok_followers === '' ? 0 : values.tiktok_followers,
                facebook_followers:
                  values.facebook_followers === ''
                    ? 0
                    : values.facebook_followers,
                youtube_followers:
                  values.youtube_followers === ''
                    ? 0
                    : values.youtube_followers,
              };
              props.profileSocialFormSubmit(socialFormPayload);
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              // console.log(errors, 'lll');
              return (
                <form onSubmit={handleSubmit}>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="">
                        <span className="labelText" htmlFor="fb">
                          {profileSection2.insta}
                        </span>
                        <div className="row g-2">
                          <div className="col-12 col-lg-8 mb-1 mb-lg-4">
                            <input
                              className="pro-input"
                              name="urls.instagram_url"
                              id="fb"
                              value={values.urls.instagram_url}
                              placeholder="URL to your Instagram account"
                              onChange={handleChange}
                            />
                            {instaFollower !== '' && (
                              <div className="text-danger fw-700 text-sm">
                                {errors?.urls?.instagram_url &&
                                  touched?.urls?.instagram_url &&
                                  errors?.urls?.instagram_url}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-lg-4 mb-4 mb-lg-4">
                            <CustomNumber
                              name="instagram_followers"
                              numberRef={instaRef}
                              setValue={setInstaValidation}
                              placeholder={profileSection2.fbfollower}
                              setFieldValue={setFieldValue}
                              val={values.instagram_followers}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <span className="labelText" htmlFor="fb">
                          {profileSection2.twit}
                        </span>
                        <div className="row g-2">
                          <div className="col-12 col-lg-8 mb-1 mb-lg-4">
                            <input
                              className="pro-input"
                              name="urls.twitter_url"
                              id="fb"
                              value={values.urls.twitter_url}
                              placeholder="URL to your Twitter account"
                              onChange={handleChange}
                            />
                            {twitterFollower !== '' && (
                              <div className="text-danger fw-700 text-sm">
                                {errors?.urls?.twitter_url &&
                                  touched?.urls?.twitter_url &&
                                  errors?.urls?.twitter_url}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-lg-4 mb-4 mb-lg-4">
                            <CustomNumber
                              name="twitter_followers"
                              numberRef={twitterRef}
                              setValue={setTwitterValidation}
                              placeholder={profileSection2.fbfollower}
                              setFieldValue={setFieldValue}
                              val={values.twitter_followers}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <span className="labelText" htmlFor="fb">
                        {profileSection2.fb}
                      </span>
                      <div className="row g-2">
                        <div className="col-12 col-lg-8 mb-1 mb-lg-4">
                          <input
                            className="pro-input"
                            name="urls.facebook_url"
                            id="fb"
                            value={values.urls.facebook_url}
                            placeholder={profileSection2.fbp}
                            onChange={handleChange}
                          />

                          {facebookFollower !== '' && (
                            <div className="text-danger fw-700 text-sm">
                              {errors?.urls?.facebook_url &&
                                touched?.urls?.facebook_url &&
                                errors?.urls?.facebook_url}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-lg-4 mb-4 mb-lg-4">
                          <CustomNumber
                            name="facebook_followers"
                            numberRef={facebookRef}
                            setValue={setFacbookValidaton}
                            placeholder={profileSection2.fbfollower}
                            setFieldValue={setFieldValue}
                            val={values.facebook_followers}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <span className="labelText">{profileSection2.ttok}</span>
                      <div className="row g-2">
                        <div className="col-12 col-lg-8 mb-1 mb-lg-4">
                          <input
                            className="pro-input"
                            name="urls.tiktok_url"
                            id="ttok"
                            value={values.urls.tiktok_url}
                            placeholder={profileSection2.ttokp}
                            onChange={handleChange}
                          />
                          {tikTokFollower !== '' && (
                            <div className="text-danger fw-700 text-sm">
                              {errors?.urls?.tiktok_url &&
                                touched?.urls?.tiktok_url &&
                                errors?.urls?.tiktok_url}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-lg-4 mb-4 mb-lg-4">
                          <CustomNumber
                            name="tiktok_followers"
                            numberRef={tiktokRef}
                            setValue={setTiktokValidation}
                            placeholder={profileSection2.ttfollower}
                            setFieldValue={setFieldValue}
                            val={values.tiktok_followers}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <span className="labelText">{profileSection2.ytube}</span>
                      <div className="row g-2">
                        <div className="col-12 col-lg-8 mb-1 mb-lg-4">
                          <input
                            className="pro-input"
                            name="urls.youtube_url"
                            value={values.urls.youtube_url}
                            placeholder={profileSection2.ytubep}
                            onChange={handleChange}
                          />

                          {youtubeFollwer !== '' && (
                            <div className="text-danger fw-700 text-sm">
                              {errors?.urls?.youtube_url &&
                                touched?.urls?.youtube_url &&
                                errors?.urls?.youtube_url}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-lg-4 mb-4 mb-lg-4">
                          <CustomNumber
                            name="youtube_followers"
                            numberRef={youTubeRef}
                            setValue={setYouTubeValidation}
                            placeholder={profileSection2.ytfollower}
                            setFieldValue={setFieldValue}
                            val={values.youtube_followers}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-8 mt-5 mx-auto">
                      <button type="submit" className="btn clickbtn">
                        {props.mode !== 'edit'
                          ? profileSection2.btnlabel
                          : 'SAVE'}
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddSocialAccount;
