import React from 'react';
import {Link} from 'react-router-dom';
import {AthleteModalBody, AthleteModalBodyInfo} from './style';
import Text from '../../../components/Text/Text';
import {CustomAthleteTag, TagContainer, ModalOfferButton} from '../pages/style';
import CustomCarouselThumbnail from '../../../common/components/CustomCarouselThumbnail';
import { formatPrice } from '../../../helpers/user';

const ModalContainer = (props) => {
  const {athleteModalDetails, orderLeft} = props;
  let cardListProduct =
    athleteModalDetails?.type === 'Experiences'
      ? athleteModalDetails?.listing_experience
      : athleteModalDetails?.listing_product;
  let TumbnialUrl = athleteModalDetails?.attachments.map((a) => a.url);
  const userRole = localStorage.getItem('userRole');

  return (
    <AthleteModalBody>
      <div className="review-holder">
        <div className="d-md-flex">
          <div className="review-left-panel">
            <div className="assets-holder assets-auto-height">
              {TumbnialUrl.length > 1 ? (
                <CustomCarouselThumbnail
                  slidesData={TumbnialUrl}></CustomCarouselThumbnail>
              ) : (
                <>
                  {TumbnialUrl.toString().split('.').pop() === 'jpg' ||
                  TumbnialUrl.toString().split('.').pop() === 'jpeg' ||
                  TumbnialUrl.toString().split('.').pop() === 'png' ? (
                    <>
                      <img
                        src={TumbnialUrl}
                        alt="card-img"
                        className="img-fluid object-fit-contain radius-l w-100"
                        // className="img-fluid object-fit-contain purchases-list-imag w-100"
                      />
                    </>
                  ) : (
                    <div className="video-background">
                      <div className="video-foreground video-no-preview">
                        <iframe
                          src={`https://www.youtube.com/embed/${TumbnialUrl}?controls=0`}
                          width="100%"
                          frameBorder="0"
                          allowFullScreen
                          title="Video"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="review-right-panel text-muted mt-5 mt-md-0">
            <AthleteModalBodyInfo>
              <TagContainer>
                <CustomAthleteTag>
                  {cardListProduct?.category?.name}
                </CustomAthleteTag>
                <CustomAthleteTag>
                  {cardListProduct?.subcategory?.name}
                </CustomAthleteTag>
                {cardListProduct?.is_coaching && (
                  <CustomAthleteTag>Coaching/Lessons</CustomAthleteTag>
                )}
              </TagContainer>
              <Text
                display="flex"
                weight="700"
                size="44"
                lineHeight="45"
                smSize="22"
                smLineHeight="27"
                textColor="#042237">
                {cardListProduct?.title}
              </Text>
              <div className="d-flex justify-content-start mt-2 mb-2">
                <Text
                  display="flex"
                  weight="700"
                  size="28"
                  smSize="20"
                  lineHeight="32"
                  textColor="#042237">
                  ${formatPrice(cardListProduct?.price)}
                </Text>
              </div>

              {orderLeft <= 5 && (
                <div className="fw-bold text-danger">
                  {orderLeft} {orderLeft === 1 ? 'order' : 'orders'} left
                </div>
              )}

              {userRole === 'brand' || userRole === 'fan' ? (
                <Link
                  to={`/purchaser/checkout`}
                  className="d-content"
                  onClick={() =>
                    localStorage.setItem('listingId', athleteModalDetails.id)
                  }>
                  <ModalOfferButton>
                    <Text
                      display="flex"
                      weight="800"
                      size="17"
                      cursor="pointer"
                      lineHeight="23"
                      textColor="#FFFFFF">
                      PURCHASE NOW
                    </Text>
                  </ModalOfferButton>
                </Link>
              ) : (
                <Link
                  to={`/purchaser/register`}
                  className="d-content"
                  onClick={() =>
                    localStorage.setItem('listingId', athleteModalDetails.id)
                  }>
                  <ModalOfferButton>
                    <Text
                      display="flex"
                      weight="800"
                      cursor="pointer"
                      size="17"
                      lineHeight="23"
                      textColor="#FFFFFF">
                      PURCHASE NOW
                    </Text>
                  </ModalOfferButton>
                </Link>
              )}
              <Text
                display="flex"
                weight="700"
                size="22"
                smSize="20"
                lineHeight="32"
                textColor="#042237">
                About the listing
              </Text>
              <Text
                display="flex"
                weight="500"
                size="16"
                lineHeight="26"
                textColor="#435969">
                {cardListProduct?.description}
              </Text>
            </AthleteModalBodyInfo>
          </div>
        </div>
      </div>
    </AthleteModalBody>
  );
};

export default ModalContainer;
