import React from 'react';
import Loader from 'react-loader-spinner';
import ShopCard from './ShopCard';

const ListCardUI = (props) => {
  const {
    isLoading,
    showModal,
    shopListing,
    editListing,
    listPayload,
    setListPayload,
    activeTab,
    stripeStatus,
    deleteListing,
    membeShipExpired,
  } = props;

  const loadMore = () => {
    if (shopListing.length === listPayload.pageSize)
      setListPayload((prev) => {
        return {
          ...listPayload,
          pageSize: prev.pageSize + 10,
        };
      });
  };

  // console.log(activeTab, 'activeTab');

  return (
    <>
      {isLoading ? (
        <div className="d-flex py-5 align-items-center justify-content-center">
          <Loader type="Bars" color="#00BFFF" height={50} width={100} />
        </div>
      ) : (
        <>
          {shopListing?.length > 0 ? (
            <>
              <ShopCard
                showModal={showModal}
                listData={shopListing}
                editListing={editListing}
                stripeStatus={stripeStatus}
                deleteListing={deleteListing}
                membeShipExpired={membeShipExpired}
              />
              {shopListing.length === listPayload.pageSize && (
                <div className="text-center">
                  <button
                    type="button"
                    className="btn clickbtnblue view-more"
                    onClick={loadMore}>
                    view more
                  </button>
                </div>
              )}
            </>
          ) : (
            <h3 className="my-5 py-5 text-center fw-bold">
              {activeTab === '' ? (
                <>No listing to show</>
              ) : activeTab === 'inactive' ? (
                <>No paused listings to show</>
              ) : (
                <>No ${activeTab} listing to show</>
              )}
            </h3>
          )}
        </>
      )}
    </>
  );
};
export default ListCardUI;
