import React, {useState} from 'react';
import moment from 'moment';
import Text from '../../components/Text/Text';
import {
  AthleteProfileImageContainer,
  MobileLayoutViewView,
  DeskTopLayoutView,
  CheckoutCard,
  MobileDetailsContainer,
  MessageCard,
  MobileDetailsContainerSecond,
} from '../../modules/Purchaser/pages/style';
import {
  OrderInfoAlert,
  CustomInfoCircleOutlined,
  ConfirmOrder,
  ButtonsContainer,
  DisputeOrder,
  ConfirmDisputeOrder,
  OrderContainer,
  PurchaseDetailValue,
} from '../../modules/Purchaser/components/style';
import {AthleteCard} from '../../modules/Athlete/components/style';
import ProfileImage from '../../modules/Purchaser/components/ProfileImage';
import {
  MarkCompleteButton,
  CompletedButton,
} from '../../modules/Athlete/components/features/Deals/style';
import {Tick, GreenTick} from '../../modules/Athlete/components/Icons';
// import MultiMediaListUI from './MultiMediaListUI';
import {currencyFormat} from '../../helpers/user';
import CustomMessageChannel from '../Messages/CustomMessageChannel';
import VideoThumbnail from './VideoThumbnail';
import CustomModal from '../../common/components/CustomModal';
import {Tooltip} from 'antd';
import {pricingSection} from '../../utils/staticStrings';

const OrderDetailsCard = (props) => {
  const {
    orderDetails,
    type,
    shippingLabel,
    setCompleteModal,
    setCancelModalVisible,
    purchaserOrderComplete,
    orderDispute,
    sendMessageAction,
    messages,
    loader,
    participantsDetails,
    channelProxy,
    enableAttachMent,
    startVideoCall,
    messageStatus,
    orderCompleteStatus,
  } = props;

  const [showMore, setShowMore] = useState(false);
  const [visible, setVisible] = useState(false);
  let cardDetails = pricingSection.pricingSectionAthleteListCard;
  // const [showBanner, setShowBanner] = useState(true);

  const statusType = (value) => {
    if (value === 'completed') {
      return 'status-success';
    } else if (value === 'active') {
      return 'status-progress';
    } else if (value === 'disputed') {
      return 'disputed';
    } else if (value === 'declined') {
      return 'cancel';
    }
  };

  let orderDetailList = orderDetails?.listing;
  let cardListProduct =
    orderDetailList?.type === 'Experiences'
      ? orderDetailList?.listing_experience
      : orderDetailList?.listing_product;

  /* Banner for order  */
  let purchaseBanner;
  let status;
  status = orderDetails?.athlete_marked_complete ? true : false;

  let completeStatus =
    cardListProduct?.category_id === 4 ? orderDetails?.is_delivered : status;

  let athleteCompleteButton =
    cardListProduct?.category_id === 4 ? orderDetails?.is_delivered : true;
  // let completedSattus =

  console.log(
    type,
    completeStatus,
    'completeStatus',
    orderCompleteStatus,
    'orderCompleteStatus',
    status,
    'status',
    orderDetails?.purchaser_marked_complete,
    'purchaser_marked_complete',
  );

  if (type === 'purchaser') {
    if (
      completeStatus &&
      !orderCompleteStatus &&
      !orderDetails?.marked_dispute &&
      !orderDetails?.purchaser_marked_complete
    ) {
      purchaseBanner = true;
    } else {
      purchaseBanner = false;
    }
  } else {
    purchaseBanner = false;
  }

  let completedLastDate = moment(orderDetails?.completed_on)
    .add(4, 'days')
    .format('MMM Do');

  return (
    <>
      {orderDetails?.status === 'disputed' && (
        <div className="disputed mt-3 mb-0 mx-3 mx-md-0 d-block">
          Successful order completion is currently disputed. Admin will be
          reaching out to both parties individually to discuss the details.
        </div>
      )}
      {orderDetails?.status === 'declined' && type === 'purchaser' && (
        <div className="disputed mt-3 mb-0 mx-3 mx-md-0 d-block">
          This order has been cancelled and a full refund has been issued to
          your original method of payment. Please allow 3 to 5 business days for
          the refund to be reflected in your account.
        </div>
      )}
      <MobileLayoutViewView>
        <MobileDetailsContainer>
          <div className="d-flex align-items-center mt-3">
            <Text
              display="flex"
              weight="700"
              size="36"
              lineHeight="55"
              smSize="30"
              smLineHeight="40"
              textColor="#042237">
              Order #{orderDetails?.id}
            </Text>
            {type === 'athlete' && orderDetails?.status === 'completed' ? (
              <></>
            ) : (
              <>
                {/* /*for athletes marked complete below condition to handle */}
                {orderDetails?.athlete_marked_complete &&
                type === 'athlete' &&
                orderDetails?.status === 'active' ? (
                  <>
                    <span className="status-success ms-3">Completed</span>
                    <span className="ms-2 offer font-s">Completed by you</span>
                  </>
                ) : (
                  <span
                    className={` ${statusType(orderDetails?.status)} ms-3 ${
                      orderDetails?.status === 'active' ? '' : 'text-capitalize'
                    } `}>
                    {orderDetails?.status === 'active'
                      ? 'In progress'
                      : orderDetails?.status === 'declined'
                      ? 'Canceled'
                      : orderDetails?.status}
                  </span>
                )}
              </>
            )}
          </div>
          <div className="d-flex align-items-center">
            <ProfileImage
              width={32}
              imgWidth={32}
              imgContainerClassName="purchaser profile-sm"
              height={32}
              src={
                type === 'purchaser'
                  ? orderDetailList?.athlete?.image_url
                  : orderDetails?.user?.image_url
              }
            />
            <div className="d-flex flex-column">
              <Text
                display="flex"
                weight="500"
                size="14"
                mrLeft="5"
                textColor="#435969">
                <span className="me-2">
                  {type === 'purchaser' ? (
                    <>Purchased from {orderDetailList?.athlete?.name}</>
                  ) : (
                    <>Purchased by {orderDetails?.user?.name}</>
                  )}
                </span>
                |
              </Text>
              <Text
                display="flex"
                weight="400"
                size="14"
                mrLeft="5"
                textColor="#435969">
                <span>
                  {moment(orderDetails?.updatedAt).format('MMM DD,YYYY')}
                </span>
              </Text>
            </div>
          </div>

          {type === `athlete` && (
            <div className="mt-3">
              {orderDetails?.status === 'active' ? (
                <>
                  {/* Athelte marks complete then shod this button */}
                  {orderDetails?.athlete_marked_complete ? (
                    <>
                      <CompletedButton>
                        <GreenTick />
                        <span className="ms-3 text-capitalize">Completed</span>
                      </CompletedButton>
                    </>
                  ) : (
                    <>
                      {athleteCompleteButton && (
                        <MarkCompleteButton
                          className="my-3"
                          onClick={() => setCompleteModal(true)}>
                          <Tick />
                          <span className="ms-2">Mark as complete</span>
                        </MarkCompleteButton>
                      )}
                      <button
                        className="cancel-button ms-0 ms-md-2"
                        onClick={() => setCancelModalVisible(true)}>
                        Cancel order
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {orderDetails?.status !== 'declined' &&
                    orderDetails?.status !== 'disputed' && (
                      <CompletedButton>
                        <GreenTick />
                        <span className="ms-3 text-capitalize">
                          {orderDetails?.status}
                        </span>
                      </CompletedButton>
                    )}
                </>
              )}
            </div>
          )}

          {type === `athlete` &&
            orderDetails?.athlete_marked_complete &&
            !orderDetails?.marked_dispute &&
            !orderCompleteStatus && (
              <div className="mb-4 bg-color-grey text-white text-center p-2 radius-m font-12 mt-3 mt-md-0">
                The Purchaser has 4 days (until {completedLastDate}) to confirm
                or dispute this order.
              </div>
            )}
        </MobileDetailsContainer>
        {/* later change this condition to is_delivered and */}

        {purchaseBanner &&
          !orderDetails?.marked_dispute &&
          orderDetails?.refund_on === null && (
            <ConfirmOrder>
              <OrderContainer>
                <Text
                  display="flex"
                  weight="600"
                  size="15"
                  lineHeight="26"
                  textColor="#ffffff">
                  Your order has been marked as
                  {cardListProduct?.category_id === 4
                    ? ' delivered'
                    : ' completed'}
                  .
                </Text>
                <Text
                  display="flex"
                  weight="400"
                  size="12"
                  lineHeight="20"
                  textColor="#ffffff">
                  You have 4 days (until {completedLastDate}) to confirm or
                  dispute this order.
                </Text>
              </OrderContainer>
              <ButtonsContainer>
                {orderDetails?.marked_dispute ? (
                  <DisputeOrder className="cursor-disabled">
                    Dispute order
                  </DisputeOrder>
                ) : (
                  <DisputeOrder
                    // onClick={orderDispute}
                    onClick={() => {
                      orderDispute(true);
                    }}>
                    Dispute order
                  </DisputeOrder>
                )}
                {orderDetails?.marked_dispute ? (
                  <ConfirmDisputeOrder className="cursor-disabled">
                    Confirm order completed
                  </ConfirmDisputeOrder>
                ) : (
                  <ConfirmDisputeOrder onClick={purchaserOrderComplete}>
                    Confirm order completed
                  </ConfirmDisputeOrder>
                )}
              </ButtonsContainer>
            </ConfirmOrder>
          )}
        <MobileDetailsContainer>
          <AthleteCard>
            <AthleteProfileImageContainer>
              {orderDetailList?.attachments[0]?.type === 'video' ? (
                <div className="video-background">
                  <div className="video-thumbnail-sm">
                    <VideoThumbnail
                      embedId={orderDetailList?.attachments[0]?.url}
                    />
                  </div>
                </div>
              ) : (
                <img
                  src={orderDetailList?.attachments[0]?.url}
                  alt="card-img"
                  className="img-fluid object-fit-contain purchases-list-imag sm"
                />
              )}
              <div className="d-flex flex-column">
                <Text
                  display="flex"
                  weight="700"
                  size="16"
                  mrLeft="10"
                  className="mb-2"
                  lineHeight="22"
                  textColor="#042237">
                  {cardListProduct?.title}
                </Text>
                <Text
                  display="flex"
                  weight="700"
                  size="14"
                  mrLeft="10"
                  lineHeight="20"
                  textColor="#435969">
                  <span className="text-truncate" style={{maxWidth: '185px'}}>
                    {orderDetails?.description}
                  </span>
                </Text>
              </div>
            </AthleteProfileImageContainer>
            <MobileLayoutViewView>
              <div
                className={`d-flex justify-content-between align-items-center mt-3 pb-2 ${
                  cardListProduct?.category_id === 4 ? 'mb-3' : ''
                }`}>
                <div className="d-flex align-items-center">
                  <Text
                    display="flex"
                    weight="500"
                    size="13"
                    className="d-flex "
                    lineHeight="18"
                    textColor="#042237">
                    Quantity &nbsp;
                  </Text>
                  <Text
                    display="flex"
                    weight="600"
                    size="16"
                    className="d-flex "
                    lineHeight="32"
                    textColor="#042237">
                    {orderDetails?.quantity}
                    <div className="cross-sign"></div>
                    {currencyFormat(
                      orderDetails?.subtotal / orderDetails?.quantity,
                    )}
                  </Text>
                </div>
                <div className="d-flex align-items-center">
                  <Text
                    display="flex"
                    weight="500"
                    size="13"
                    className="d-flex "
                    lineHeight="18"
                    textColor="#042237">
                    Amount
                  </Text>
                  <Text
                    display="flex"
                    weight="600"
                    size="16"
                    className="d-flex ms-2"
                    lineHeight="32"
                    textColor="#042237">
                    {currencyFormat(orderDetails?.subtotal)}
                  </Text>
                </div>
              </div>
              {type === 'purchaser' && (
                <div className="d-flex justify-content-end mb-3">
                  <div>
                    <div className="d-flex align-items-center">
                      <Text
                        display="flex"
                        weight="500"
                        size="13"
                        className="d-flex "
                        lineHeight="18"
                        textColor="#042237">
                        Sales tax
                      </Text>
                      <Text
                        display="flex"
                        weight="600"
                        size="16"
                        className="d-flex ms-2"
                        lineHeight="32"
                        textColor="#042237">
                        {currencyFormat(orderDetails?.sales_tax)}
                      </Text>
                    </div>
                    <div className="d-flex align-items-center">
                      <Text
                        display="flex"
                        weight="500"
                        size="13"
                        className="d-flex "
                        lineHeight="18"
                        textColor="#042237">
                        Shipping
                      </Text>
                      <Text
                        display="flex"
                        weight="600"
                        size="16"
                        className="d-flex ms-2"
                        lineHeight="32"
                        textColor="#042237">
                        {currencyFormat(orderDetails?.shipping_price)}
                        {/* ${formatPrice(orderDetails?.shipping_tax)} */}
                      </Text>
                    </div>
                  </div>
                </div>
              )}
            </MobileLayoutViewView>
            <PurchaseDetailValue className=" d-flex w-100 flex-row-reverse pb-0">
              <Text
                display="flex"
                weight="800"
                size="18"
                lineHeight="32"
                textColor="#042237">
                Total: {currencyFormat(orderDetails?.total_price)}
              </Text>
            </PurchaseDetailValue>
          </AthleteCard>
          {orderDetails?.inscription &&
            orderDetails?.purchaser_requirements && (
              <CheckoutCard>
                <div className="d-flex flex-column mb-4">
                  <Text
                    display="flex"
                    weight="700"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    {orderDetails?.purchaser_requirements}
                  </Text>
                  <Text
                    display="flex"
                    weight="400"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    {orderDetails?.inscription}
                  </Text>
                </div>

                {orderDetails?.shipping_address &&
                  cardListProduct.category_id === 4 && (
                    <div className="d-flex flex-column">
                      <Text
                        display="flex"
                        weight="700"
                        size="16"
                        lineHeight="26"
                        textColor="#042237">
                        Shipping address
                      </Text>
                      <Text
                        display="flex"
                        weight="400"
                        size="16"
                        lineHeight="26"
                        textColor="#042237">
                        {orderDetails?.purchaser_name && (
                          <>
                            {orderDetails?.purchaser_name} &nbsp;
                            <br />
                          </>
                        )}
                        {orderDetails?.shipping_address?.street1} &nbsp;
                        <br />
                        {orderDetails?.shipping_address?.street2 && (
                          <>
                            {orderDetails?.shipping_address?.street2} &nbsp;
                            <br />
                          </>
                        )}
                        {orderDetails?.shipping_address?.city},&nbsp;
                        {orderDetails?.shipping_address?.state}&nbsp;
                        {orderDetails?.shipping_address?.zip}
                        <br />
                        {orderDetails?.shipping_address?.country}&nbsp;
                      </Text>
                    </div>
                  )}
              </CheckoutCard>
            )}
        </MobileDetailsContainer>
      </MobileLayoutViewView>
      <DeskTopLayoutView>
        <AthleteCard
          className={orderDetails?.status === 'disputed' ? 'mt-0' : ''}>
          <div
            className={`${
              type === `athlete`
                ? 'd-flex align-items-center justify-content-between mb-3'
                : ''
            }`}>
            <div>
              <div className="d-flex align-items-center">
                <Text
                  display="flex"
                  weight="700"
                  size="36"
                  lineHeight="55"
                  smSize="30"
                  smLineHeight="40"
                  textColor="#042237">
                  Order #{orderDetails?.id}
                </Text>
                {type === 'athlete' && orderDetails?.status === 'completed' ? (
                  <></>
                ) : (
                  <>
                    {/* /*for athletes marked complete below condition to handle */}
                    {orderDetails?.athlete_marked_complete &&
                    type === 'athlete' &&
                    orderDetails?.status === 'active' ? (
                      <>
                        <span className="status-success ms-3">Completed</span>
                        <span className="ms-2 offer font-s">
                          Completed by you
                        </span>
                      </>
                    ) : (
                      <span
                        className={` ${statusType(orderDetails?.status)} ms-3 ${
                          orderDetails?.status === 'active'
                            ? ''
                            : 'text-capitalize'
                        } `}>
                        {orderDetails?.status === 'active'
                          ? 'In progress'
                          : orderDetails?.status === 'declined'
                          ? 'Canceled'
                          : orderDetails?.status}
                      </span>
                    )}
                  </>
                )}
              </div>
              <div className="d-flex align-items-center">
                <ProfileImage
                  width={32}
                  imgWidth={32}
                  imgContainerClassName="purchaser profile-sm"
                  height={32}
                  src={
                    type === 'purchaser'
                      ? orderDetailList?.athlete?.image_url
                      : orderDetails?.user?.purchaser?.logo
                      ? orderDetails?.user?.purchaser?.logo
                      : orderDetails?.user?.image_url
                  }
                />

                <Text display="flex" weight="500" size="14" textColor="#042237">
                  <span className="me-2">
                    {type === 'purchaser' ? (
                      <>Purchased from {orderDetailList?.athlete?.name}</>
                    ) : (
                      <>
                        Purchased by &nbsp;
                        {orderDetails?.user?.purchaser?.company
                          ? orderDetails?.user?.purchaser?.company
                          : orderDetails?.user?.name}
                      </>
                    )}
                  </span>
                  |
                  <span className="ms-2">
                    {moment(orderDetails?.createdAt).format('MMM DD,YYYY')}
                  </span>
                </Text>
              </div>
            </div>

            {type === `athlete` && (
              <div>
                {orderDetails?.status === 'active' ? (
                  <>
                    {/* Athelte marks complete then shod this button */}
                    {orderDetails?.athlete_marked_complete ? (
                      <>
                        <CompletedButton>
                          <GreenTick />
                          <span className="ms-3 text-capitalize">
                            Completed
                          </span>
                        </CompletedButton>
                      </>
                    ) : (
                      <>
                        {athleteCompleteButton && (
                          <MarkCompleteButton
                            className="my-3"
                            onClick={() => setCompleteModal(true)}>
                            <Tick />
                            <span className="ms-2">Mark as complete</span>
                          </MarkCompleteButton>
                        )}
                        <button
                          className="cancel-button ms-0 ms-md-2"
                          onClick={() => setCancelModalVisible(true)}>
                          Cancel order
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {orderDetails?.status !== 'declined' &&
                      orderDetails?.status !== 'disputed' && (
                        <CompletedButton>
                          <GreenTick />
                          <span className="ms-3 text-capitalize">
                            {orderDetails?.status}
                          </span>
                        </CompletedButton>
                      )}
                  </>
                )}
              </div>
            )}
          </div>
          {type === `athlete` &&
            orderDetails?.athlete_marked_complete &&
            !orderDetails?.marked_dispute &&
            !orderDetails?.purchaser_marked_complete &&
            !orderCompleteStatus && (
              <div className="mb-4 bg-color-grey text-white text-center p-2 radius-m font-12 mt-3 mt-md-0">
                The Purchaser has 4 days (until {completedLastDate}) to confirm
                or dispute this order.
              </div>
            )}
          {purchaseBanner &&
            !orderDetails?.marked_dispute &&
            orderDetails?.refund_on === null && (
              <ConfirmOrder>
                <OrderContainer>
                  <Text
                    display="flex"
                    weight="600"
                    size="15"
                    lineHeight="26"
                    textColor="#ffffff">
                    Your order has been marked as
                    {cardListProduct?.category_id === 4
                      ? ' delivered'
                      : ' completed'}
                    .
                  </Text>
                  <Text
                    display="flex"
                    weight="400"
                    size="12"
                    lineHeight="20"
                    textColor="#ffffff">
                    You have 4 days (until {completedLastDate}) to confirm or
                    dispute this order.
                  </Text>
                </OrderContainer>
                <ButtonsContainer>
                  {orderDetails?.marked_dispute ? (
                    <DisputeOrder className="cursor-disabled">
                      Dispute order
                    </DisputeOrder>
                  ) : (
                    <DisputeOrder onClick={orderDispute}>
                      Dispute order
                    </DisputeOrder>
                  )}
                  {orderDetails?.marked_dispute ? (
                    <ConfirmDisputeOrder className="cursor-disabled">
                      Confirm order completed
                    </ConfirmDisputeOrder>
                  ) : (
                    <ConfirmDisputeOrder onClick={purchaserOrderComplete}>
                      Confirm order completed
                    </ConfirmDisputeOrder>
                  )}
                </ButtonsContainer>
              </ConfirmOrder>
            )}
          <DeskTopLayoutView>
            <table className="table purchase-order-table">
              <thead className="th" style={{height: '60px'}}>
                <tr>
                  <th style={{paddingLeft: '30px', verticalAlign: 'middle'}}>
                    Item
                  </th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">Price</th>
                  <th className="text-end" width="15%">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="no-border-top">
                  <td
                    width="60%"
                    className="py-3 border-bottom-0"
                    style={{paddingLeft: '30px', verticalAlign: 'middle'}}>
                    <div className="d-xl-flex">
                      {orderDetailList?.attachments[0]?.type === 'video' ? (
                        <div className="video-background">
                          <div className="video-thumbnail-sm">
                            <VideoThumbnail
                              embedId={orderDetailList?.attachments[0]?.url}
                            />
                          </div>
                        </div>
                      ) : (
                        <img
                          src={orderDetailList?.attachments[0]?.url}
                          alt="card-img"
                          className="img-fluid object-fit-contain purchases-list-imag sm"
                        />
                      )}

                      <div className="d-flex flex-column justify-content-center">
                        <Text
                          display="flex"
                          weight="700"
                          size="16"
                          // mrLeft="20"
                          lineHeight="22"
                          className="ms-0 ms-xl-3"
                          textColor="#042237">
                          {orderDetails?.title}
                        </Text>
                        <Text
                          display="flex"
                          weight="500"
                          size="14"
                          mrLeft="20"
                          mrTop="4"
                          lineHeight="20"
                          className="ms-0 ms-xl-3"
                          textColor="#435969">
                          {orderDetails?.description}
                        </Text>
                      </div>
                    </div>
                  </td>
                  <td className="text-center border-bottom-0">
                    <Text weight="600" size="16" lineHeight="32">
                      {orderDetails?.quantity}
                    </Text>
                  </td>
                  <td className="text-center border-bottom-0">
                    <Text weight="600" size="16" lineHeight="32">
                      {currencyFormat(
                        orderDetails?.subtotal / orderDetails?.quantity,
                      )}
                    </Text>
                  </td>
                  <td className="text-end border-bottom-0">
                    <Text weight="600" size="16" lineHeight="32">
                      {currencyFormat(orderDetails?.subtotal)}
                    </Text>
                  </td>
                </tr>
                <tr className="border-top-0 border-bottom-0">
                  <td colSpan="4" className="py-0 border-bottom-0 ">
                    <hr className="m-0 bg-color-border opacity-100" />
                  </td>
                </tr>
                <tr className="no-border-top">
                  <td className="text-end" colSpan="4">
                    {type === 'purchaser' && (
                      <div className=" align-items-center justify-content-end">
                        <div className="d-flex align-items-center justify-content-end">
                          <Text
                            display="flex"
                            weight="500"
                            size="13"
                            className="d-flex "
                            lineHeight="18"
                            textColor="#042237">
                            Sales tax
                          </Text>
                          <Text
                            display="flex"
                            weight="600"
                            size="16"
                            className="d-flex ms-2"
                            lineHeight="32"
                            textColor="#042237">
                            {currencyFormat(orderDetails?.sales_tax)}
                          </Text>
                        </div>
                        {cardListProduct.category_id === 4 && (
                          <div className="d-flex align-items-center justify-content-end">
                            <Text
                              display="flex"
                              weight="500"
                              size="13"
                              className="d-flex "
                              lineHeight="18"
                              textColor="#042237">
                              Shipping
                            </Text>
                            <Text
                              display="flex"
                              weight="600"
                              size="16"
                              className="d-flex ms-2"
                              lineHeight="32"
                              textColor="#042237">
                              {currencyFormat(orderDetails?.shipping_price)}
                            </Text>
                          </div>
                        )}
                      </div>
                    )}
                    <Text
                      display="flex"
                      weight="800"
                      size="18"
                      lineHeight="32"
                      className="justify-content-end"
                      textColor="#042237">
                      {type === `athlete`
                        ? `Total ${currencyFormat(orderDetails?.subtotal)}`
                        : `Total ${currencyFormat(orderDetails?.total_price)}`}
                    </Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </DeskTopLayoutView>
          {(orderDetails?.inscription ||
            cardListProduct?.category_id === 4) && (
            <CheckoutCard>
              {orderDetails?.purchaser_requirements && (
                <div className="d-flex flex-column mb-4">
                  <Text
                    display="flex"
                    weight="700"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    {orderDetails?.purchaser_requirements}
                  </Text>
                  <Text
                    display="flex"
                    weight="400"
                    size="16"
                    lineHeight="26"
                    textColor="#042237">
                    {orderDetails?.inscription}
                  </Text>
                </div>
              )}
              {orderDetails?.shipping_address &&
                cardListProduct?.category_id === 4 && (
                  <div className="d-flex flex-column">
                    <Text
                      display="flex"
                      weight="700"
                      size="16"
                      lineHeight="26"
                      textColor="#042237">
                      Shipping address
                    </Text>
                    <Text
                      display="flex"
                      weight="400"
                      size="16"
                      lineHeight="26"
                      textColor="#042237">
                      {orderDetails?.purchaser_name && (
                        <>
                          {orderDetails?.purchaser_name} &nbsp;
                          <br />
                        </>
                      )}
                      {orderDetails?.shipping_address?.street1} &nbsp;
                      <br />
                      {orderDetails?.shipping_address?.street2 && (
                        <>
                          {orderDetails?.shipping_address?.street2} &nbsp;
                          <br />
                        </>
                      )}
                      {orderDetails?.shipping_address?.city},&nbsp;
                      {orderDetails?.shipping_address?.state}&nbsp;
                      {orderDetails?.shipping_address?.zip}
                      <br />
                      {orderDetails?.shipping_address?.country}&nbsp;
                    </Text>
                  </div>
                )}
            </CheckoutCard>
          )}
        </AthleteCard>
      </DeskTopLayoutView>
      {/* {cardListProduct?.digital_product_urls && (
        <MultiMediaListUI
          digital_product_urls={cardListProduct?.digital_product_urls}
        />
      )} */}
      <div className="px-4 px-md-0">
        {type === 'athlete' && shippingLabel.label !== undefined && (
          <div className="row mt-4">
            {shippingLabel?.qr_code !== null && (
              <div className="col-md-6 col-12">
                <div className="mx-auto my-md-2 my-2">
                  <div
                    onClick={() => setVisible(true)}
                    className="btn btn-tarnparant-purple btn-lg w-100 d-flex align-items-center justify-content-center"
                    style={{
                      fontSize: '18px',
                      fontWeight: 800,
                      height: 'auto',
                      padding: '20px 10px',
                    }}>
                    <span className="icon-qrcode me-3"></span>
                    QR CODE SHIPPING LABEL{' '}
                    <Tooltip
                      placement="top"
                      title={
                        <>
                          For your convenience, you can print your outbound
                          label at participating USPS® Post Offices®. Simply
                          bring your packaged and ready-to-ship item and show
                          the Label Broker code below to the USPS Retail
                          Associate at the Retail Counter. You can find
                          participating locations here:{' '}
                          <a
                            class="text-primary"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.usps.com/business/label-broker.htm"
                            data-title="Download Wonder Plugins">
                            Label Broker | USPS
                          </a>
                        </>
                      }>
                      <span className="price-img ms-1 d-none d-md-block">
                        <img src={cardDetails?.blueHelpIcon} alt="priceimg" />
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
            <div
              className={` col-12 ${
                shippingLabel?.qr_code !== null ? 'col-md-6' : 'mw-570 mx-auto'
              }`}>
              <div className="mx-auto my-md-2 my-2">
                <a
                  href={shippingLabel?.label}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="btn btn-primary btn-lg">
                  <span className="icon-label me-3"></span>
                  get shipping label
                </a>
              </div>
            </div>
          </div>
        )}

        {visible && (
          <>
            <CustomModal
              visible={visible}
              width="710px"
              isLoading={false}
              className="update-payment-modal"
              onClickCancel={() => setVisible(false)}>
              <div className="my-5 text-center">
                <p className="text-start d-block d-md-none">
                  For your convenience, you can print your outbound label at
                  participating USPS® Post Offices®. Simply bring your packaged
                  and ready-to-ship item and show the Label Broker code below to
                  the USPS Retail Associate at the Retail Counter.
                </p>
                <img
                  src={shippingLabel?.qr_code}
                  alt="QR code label"
                  width="350px"
                />
              </div>
            </CustomModal>
          </>
        )}
        <MobileLayoutViewView>
          <MobileDetailsContainerSecond>
            <OrderInfoAlert>
              <CustomInfoCircleOutlined />
              <div>
                <Text
                  display="flex"
                  weight="400"
                  size="13"
                  lineHeight="22"
                  textColor="#435969"
                  className={` ${showMore ? '' : 'line-ellipse-3 h-68'}`}>
                  This message thread is opened for communication between buyer
                  and seller in order to facilitate smooth order fulfillment.
                  Please refrain from communication not related to this order
                  (i.e., the thread is not for general chit-chat or Q&A). This
                  thread will be closed upon order completion
                </Text>
                <span
                  className="text-dark fw-700  font-xs view-more-link"
                  onClick={(e) => {
                    setShowMore(!showMore);
                  }}>
                  {showMore ? '- Show less' : '+ Show more'}
                </span>
              </div>
            </OrderInfoAlert>
          </MobileDetailsContainerSecond>
        </MobileLayoutViewView>
        <DeskTopLayoutView>
          <MobileDetailsContainerSecond>
            <OrderInfoAlert>
              <CustomInfoCircleOutlined />
              <div>
                <Text
                  display="flex"
                  weight="400"
                  size="13"
                  lineHeight="22"
                  textColor="#435969">
                  This message thread is opened for communication between buyer
                  and seller in order to facilitate smooth order fulfillment.
                  Please refrain from communication not related to this order
                  (i.e., the thread is not for general chit-chat or Q&A). This
                  thread will be closed upon order completion
                </Text>
              </div>
            </OrderInfoAlert>
          </MobileDetailsContainerSecond>
        </DeskTopLayoutView>
        <MessageCard>
          <CustomMessageChannel
            sendMessageAction={sendMessageAction}
            messages={messages}
            loader={loader}
            participantsDetails={participantsDetails}
            channelProxy={channelProxy}
            enableAttachMent={enableAttachMent}
            enableVideo={
              (cardListProduct?.category_id === 1 &&
                cardListProduct?.subcategory_id === 2) ||
              (cardListProduct?.category_id === 1 &&
                cardListProduct?.subcategory_id === 3)
            }
            loadingChannel={false}
            startVideoCall={startVideoCall}
            messageStatus={messageStatus}
            type={type}
          />
        </MessageCard>
      </div>
    </>
  );
};

export default OrderDetailsCard;
