import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {loginSection} from '../../../../../utils/staticStrings';
import '../features.scss';
import {useSelector, useDispatch} from 'react-redux';
import {userLogin, handleMessage} from '../../../../../store/users/actions';
import PasswordInput from '../../../../../common/components/PasswordInput';
import {Formik} from 'formik';
import * as yup from 'yup';
import http from '../../../../../helpers/http';
import {
  toastMessage,
  errorToastMessage,
} from '../../../../../helpers/toastMessage';
// import { notification } from "antd";

let schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid Email ID.')
    .required('Email ID is Required'),
  password: yup.string().required('Password is Required'),
});

const Login = (props) => {
  const {push} = useHistory();
  const {state} = useLocation();
  const {message, user} = useSelector((state) => state.UsersReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(handleMessage('ji'));
    if (message.text !== undefined) {
      if (message.text.success === true) {
        toastMessage(message.text.data);
        dispatch(handleMessage());
      }
      if (message.text.success === false) {
        errorToastMessage(message.text.message);
        dispatch(handleMessage());
      }
    }
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (user.status === 'active') {
      // toastMessage('Login successs');
      // localStorage.setItem('athletUp-token', user.token);
      localStorage.setItem('userRole', user?.role);
      let ListingId = localStorage.getItem('listingId');

      if (user?.role === 'brand' || user?.role === 'fan') {
        if (ListingId) {
          push('/purchaser/checkout');
        } else {
          if (props?.state) {
            push(props.state);
          } else {
            push('/purchaser/dashboard');
          }
        }
      } else {
        http.get('/account/account-validity').then((res) => {
          if (res && res?.data && res?.data?.data) {
            // check when account details basic info missing
            http.get(`/athlete/${user?.id}`).then((res) => {
              let athleteDetails = res?.data?.data;
              if (athleteDetails?.bio === null) {
                push('/athlete/profile');
              } else {
                const stripeActValid = res?.data?.data?.stripe_account_valid;
                const subscriptionValid = res?.data?.data?.subscription_valid;

                if (stripeActValid && subscriptionValid) {
                  if (props?.state) {
                    push(props?.state);
                  } else {
                    push('/athlete/dashboard');
                  }
                } else {
                  push('/athlete/my-shop');
                }
              }
            });
          }
        });
      }
    }
  }, [user, push, props.state]);

  const loginHandler = async (values, setSubmitting) => {
    try {
      let payload = {};
      if (state && state.prev) {
        payload = {
          payload: {
            email: values.email.toLowerCase(),
            password: values.password,
          },
          prev: state.prev,
        };
      } else {
        payload = {
          payload: {
            email: values.email.toLowerCase(),
            password: values.password,
          },
        };
      }
      dispatch(userLogin(payload));
      setSubmitting(true);
    } catch (err) {
      setSubmitting(false);
      // console.log(err, "OOOOO")
    }
  };

  return (
    <div className="padding-main">
      <div className="login-main">
        <div className="row login-section">
          <div className="col-12 col-sm-10 col-md-10 col-lg-5 col-xl-5 col-xxl-6 me-lg-3">
            <div className="auth-holder mx-auto">
              <div className="login-title mb-3"> {loginSection.title}</div>
              <div className="login-texts mb-5"> {loginSection.texts}</div>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={schema}
                onSubmit={(values, {setSubmitting}) => {
                  loginHandler(values, setSubmitting);
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="form-floating mb-3">
                        <input
                          className="form-control"
                          value={values.email}
                          type="text"
                          name="email"
                          id="floatingInput"
                          onChange={handleChange}
                          placeholder="name@example.com"
                        />
                        <label htmlFor="floatingInput">
                          {loginSection.inputlabel1}
                        </label>
                        <div className="text-danger text-sm">
                          {touched.email && errors.email && errors.email}
                        </div>
                      </div>
                      <div className="form-floating">
                        <PasswordInput
                          name="password"
                          changeEvent={handleChange}
                          value={values.password}
                          blurEvent={handleBlur}
                          placeHolder="Password"
                        />
                        <label htmlFor="floatingPassword">
                          {loginSection.inputlabel2}
                        </label>
                        <div className="text-danger text-sm">
                          {touched.password &&
                            errors.password &&
                            errors.password}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-1 mb-2">
                        <a
                          href="/athlete/forgot-password"
                          className="cursor-pointer font-m color-muted font-bold mt-3 text-link">
                          Forgot Password?
                        </a>
                      </div>
                      <div className="bottom-pos-btn-container mt-0">
                        <button type="submit" className="btn clickbtn">
                          LOGIN
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
              <div className="no-account mt-5">
                {loginSection.signuptext}
                <a href="/athlete/register" className="color text-link ms-2">
                  Sign up
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-10 col-md-10 col-lg-6 col-xl-6 col-xxl-5 mt-5 mt-sm-0 d-none d-md-block">
            <div className="bg ms-0 ms-xl-5 mt-5 mt-xl-0">
              <img
                className="boy-image"
                src={loginSection.imgrightbg}
                alt="mikebg"
              />
              <div className="info-block">
                <div className="nameage">{loginSection.nameage}</div>
                <div className="playertitle">{loginSection.playertitle}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
