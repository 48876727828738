import MarketingModule from './Marketing';
import AthleteModule from './Athlete';
import PurchaserModule from './Purchaser';
import BrandModule from './Brand';
import UserModule from './UserLogin';

const modules = [
  MarketingModule,
  AthleteModule,
  PurchaserModule,
  BrandModule,
  UserModule,
];

export default modules;
