import React, {useEffect, useState} from 'react';
import './footer.scss';
import {headerSection} from '../../utils/staticStrings';
import {
  FooterFacebookIcon,
  FooterInstaIcon,
  FooterTwitterIcon,
} from '../../utils/SocialIcons';
import {useLocation} from 'react-router-dom';

export default function Footer() {
  const footerLocation = useLocation();

  const [showFooter, setShowFooter] = useState(false);
  const [isPurchaser, setIsPurchaser] = useState(false);

  const renderFooter = () => {
    if (
      footerLocation.pathname === '/' ||
      footerLocation.pathname.indexOf('brands') > -1 ||
      footerLocation.pathname.indexOf('pricing') > -1 ||
      footerLocation.pathname.indexOf('about') > -1 ||
      footerLocation.pathname.indexOf('terms-service') > -1 ||
      footerLocation.pathname.indexOf('privacy-policy') > -1 ||
      footerLocation.pathname === '/purchaser' ||
      footerLocation.pathname === '/purchaser/'
    ) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
    if (
      footerLocation.pathname === '/purchaser' ||
      footerLocation.pathname === '/purchaser/'
    )
      setIsPurchaser(true);
  };

  useEffect(() => {
    renderFooter();
  }, [footerLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  const coreFooter = (
    <div className={`theme-dark ${isPurchaser ? 'mobile-profile-footer' : ''}`}>
      {/*FOOTER */}
      <div className="container">
        <div className="row py-lg-5">
          <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
            <div className="text-left text-lg-left mb-3 logo-align">
              <img src={headerSection.headerLogo} alt="headerLogo" />
            </div>
          </div>

          <div className="col-7 col-sm-5 col-md-4 col-lg-2 col-xl-2 mb-5 footer-text">
            <h5 className="title">Pages</h5>
            <ul className="nav flex-column">
              <li className="nav-item textual">
                <a className="p-0 link-color" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item textual">
                <a className="p-0 link-color" href="/purchaser">
                  Discover Athletes
                </a>
              </li>
              <li className="nav-item textual">
                <a className="p-0 link-color" href="/brands">
                  Brands
                </a>
              </li>
              <li className="nav-item textual">
                <a className="p-0 link-color" href="/pricing">
                  Pricing
                </a>
              </li>
              <li className="nav-item textual">
                <a className="p-0 link-color" href="/login">
                  Log In
                </a>
              </li>
              <li className="nav-item textual">
                <a className="p-0 link-color" href="/athlete/register">
                  Sign Up
                </a>
              </li>
            </ul>
          </div>

          <div className="col-5 col-sm-5 col-md-4 col-lg-2 col-xl-2 mb-5 footer-text">
            <h5 className="title">Company</h5>
            <ul className="nav flex-column ">
              <li className="nav-item textual">
                <a className="p-0 link-color" href="/about">
                  About
                </a>
              </li>

              <li className="nav-item textual">
                <a
                  className="p-0 link-color"
                  href="https://blog.athleteup.com/">
                  Blog
                </a>
              </li>

              <li className="nav-item textual">
                <a className="p-0 link-color" href="/terms-service">
                  Terms of Service
                </a>
              </li>
              <li className="nav-item textual">
                <a className="p-0 link-color" href="/privacy-policy">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>

          <div className="col-6 col-sm-5 col-md-4 col-lg-2 col-xl-2 mb-5">
            <h5 className="title">Follow Us</h5>
            <ul className="nav flex-column ">
              <li className="nav-item textual">
                {/* <img
                  src={backgroundStyle.facebookIcon}
                  alt="Facebook icon"
                  width="17"
                  height="17"
                /> */}
                <FooterFacebookIcon />
                <a
                  target="blank"
                  href="https://www.facebook.com/AthleteUp1"
                  className="p-0 link-color link-space">
                  Facebook
                </a>
              </li>
              <li className="nav-item textual">
                {/* <img
                  src={backgroundStyle.footerTwitterIcon}
                  alt="Twitter icon"
                  width="18"
                  height="16"
                /> */}
                <FooterTwitterIcon />
                <a
                  target="blank"
                  href="https://twitter.com/AthleteUp_"
                  className="p-0 link-color link-space">
                  Twitter
                </a>
              </li>
              <li className="nav-item textual">
                <FooterInstaIcon />
                <a
                  target="blank"
                  href="https://www.instagram.com/athleteup_/"
                  className="p-0 link-color link-space">
                  Instagram
                </a>
              </li>
              <li className="nav-item textual">
                <div className="link-color font-s">
                  For all inquiries contact us at
                  <a
                    target="blank"
                    href="mailto:info@athleteup.com?subject=SendMail&body=Description"
                    className="ms-1 link-color link-space link-mail text-wrap">
                    info@athleteup.com
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  if (showFooter) {
    if (
      footerLocation.pathname.indexOf('brands') > -1 ||
      footerLocation.pathname.indexOf('pricing') > -1 ||
      footerLocation.pathname === '/'
    ) {
      return <div className="marketing-bg">{coreFooter}</div>;
    } else return coreFooter;
  } else {
    return <div></div>;
  }
}
