import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setHeader} from '../../../../../store/athleteflow/action';
import {useHistory} from 'react-router-dom';
import {headerSection} from '../../../../../utils/staticStrings';

import {
  browserBack,
  hanldeError,
} from '../../../../../store/athleteflow/action';
import {getState} from '../../../../../store/brandflow/action';

const LISTING_CREATE_METHOD = {
  EASY: 'easy',
  PROMPT: 'prompt',
};

const ChooseListingMethod = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedCard, setCardType] = useState(LISTING_CREATE_METHOD.EASY);

  const handleNext = useCallback(() => {
    // route here.
    switch(selectedCard){
    case LISTING_CREATE_METHOD.EASY:
      history.push('/athlete/create-template-listing');
      break;
    case LISTING_CREATE_METHOD.PROMPT:
      history.push('/athlete/create-listing');
      break;
    default: // noop
    }
  }, [selectedCard, history]);

  useEffect(() => {
    dispatch(setHeader(false));
    dispatch(browserBack(false));
    dispatch(hanldeError(''));
    dispatch(getState({country: 'US'}));
  }, [dispatch]);

  //only fire on mount.
  useEffect(() => {
    document.querySelector('body').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <div className="list-holder">
        <div className="header-style header-light header-fixed athlete-bg">
          <div className="athlete-page-bg border-bottom">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center py-3">
                <a href="/">
                  <img
                    alt="headerlogo"
                    src={headerSection.headerLogoBlack}
                    className="d-inline-block align-top"
                  />
                </a>
                <div
                  className="text-purple cursor-pointer"
                  onClick={() => history.push(`/athlete/my-shop`)}>
                  Exit <i className="icon-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      
          <div className="steps-content pt-3 pb-5" style={{ margin: '0 5px'}}>
            <div className="text-center">
              <h1 className="fw-bold">Add New Listing</h1>
              <p className="mb-5 color-muted text-center title">
                Choose which method you'd like to use to create this listing:
              </p>
              <div className="mt-5 mb-3">
                <div
                  className={`list-card mb-3 ${
                    selectedCard === 'easy' ? 'active' : ''
                  }`}
                  onClick={() => setCardType('easy')}>
                  <div className="bg-white">
                    <div className="card-bg d-flex">
                      <img
                        src="/images/easy_method.png"
                        alt="Easy Method logo"
                        className="card-img-m d-none d-md-block"
                        style={{ height: 70, width: 70, borderRadius: 10 }}
                      />

                      <img
                        src="/images/easy_method.png"
                        alt="Easy Method logo"
                        className="card-img-xs d-xs-block d-sm-none d-md-none d-lg-none d-xl-none"
                        style={{ height: 60, width: 60, borderRadius: 10 }}
                      />
                      
                      <div className="ms-3">
                        <h3 className="fw-bold d-xs-block d-sm-none d-md-none d-lg-none d-xl-none" style={{ fontSize: 12 }}>Easy Method (Listings ready-to-add)</h3>
                        <h3 className="font-22 fw-bold d-none d-md-block">Easy Method (Listings ready-to-add)</h3>
                        
                        <div className="color-muted fw-500 d-xs-block d-sm-none d-md-none d-lg-none d-xl-none" style={{ fontSize: 10, lineHeight: '14px' }}>
                          Quickly add listings to your Shop from our selection of in-demand Experiences & Products. 
                        </div>

                        <p className="color-muted fw-500 d-none d-md-block">
                          Quickly add listings to your Shop from our selection of in-demand Experiences & Products. Just set your price, modify any details you'd like, and add to your Shop.
                        </p>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`list-card mb-3 ${
                    selectedCard === 'prompt' ? 'active' : ''
                  }`}
                  onClick={() => setCardType('prompt')}>
                  <div className="bg-white">
                    <div className="card-bg d-flex">
                      <img
                        src="/images/guided_prompts.png"
                        alt="Easy Method logo"
                        className="card-img-m d-none d-md-block"
                        style={{ height: 70, width: 70, borderRadius: 10 }}
                      />

                      <img
                        src="/images/guided_prompts.png"
                        alt="Easy Method logo"
                        className="card-img-xs d-xs-block d-sm-none d-md-none d-lg-none d-xl-none"
                        style={{ height: 60, width: 60, borderRadius: 10 }}
                      />
                      <div className="ms-3">
                        <h3 className="fw-bold d-xs-block d-sm-none d-md-none d-lg-none d-xl-none" style={{ fontSize: 12 }}>Create-Your-Own (Guided Prompts)</h3>
                        <h3 className="font-22 fw-bold d-none d-md-block">Create-Your-Own (Guided Prompts)</h3>

                        <div className="color-muted fw-500 d-xs-block d-sm-none d-md-none d-lg-none d-xl-none" style={{ fontSize: 10, lineHeight: '14px' }}>
                          Create a completely customized listing for any Experience or Product that you're interested in selling.
                        </div>

                        <p className="color-muted fw-500 d-none d-md-block">
                          Create a completely customized listing for any Experience or Product that you're interested in selling.
                          Feel free to get creative! Guided prompts make this process a breeze!
                        </p>
                        

                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom mt-5 mw-570 mx-auto d-none d-md-block"></div>
                <div className="steps-action d-flex flex-row">
                  <button
                    className="btn btn-primary listing-btn mt-4 ms-auto"
                    onClick={handleNext}>
                    Next
                    <i className="icon icon-right ms-3"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </>
  );
};

export default ChooseListingMethod;
