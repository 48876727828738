import React, {useEffect} from 'react';
import AppLayout from '../../../../../common/AppLayout';
import {useDispatch, useSelector} from 'react-redux';
import {setHeader} from '../../../../../store/athleteflow/action';
import AppHeader from '../../../../../common/components/AppHeader';
import StatsData from '../../../../../common/components/Stats';
import useApi from '../../../../../hooks/useApi';
import {Select, DatePicker} from 'antd';
import {useState} from 'react';
import http from '../../../../../helpers/http';
// import {CSVLink} from 'react-csv';
import useDeviceSize from '../../../../../hooks/useDeviceSize';
import EarningsTable from './EarningsTable';
import EarningsMobileTable from './EarningsMobileTable';
import CustomModal from '../../../../../common/components/CustomModal';
import WithdrawEarningModal from './WithdrawEarningModal';
import {handleSidebarTab} from '../../../../../store/users/actions';
import moment from 'moment';
import SubScriptionBanner from '../../../../../common/components/SubscriptionBanner';
import {errorToastMessage} from '../../../../../helpers/toastMessage';

const Earnings = () => {
  const dispatch = useDispatch();
  const size = useDeviceSize();
  const [statsData, setStatsData] = useState({});
  const [withDrawAmount] = useApi('/athlete/transaction/withdraw-amount');
  const [stripeAccount] = useApi('/stripe/account');
  const [modalVisible, setModalVisible] = useState(false);
  const [earningPage, setEarningPage] = useState({
    pageSize: 10,
    page: 1,
    month: '',
    year: new Date().getFullYear(),
  });
  const [earningData, setEarningData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderCount, setOrderCount] = useState();
  const [successFundsMsg, setSuccesMessageHolder] = useState(true);

  const {stripeStatus, membeShipExpired} = useSelector(
    (state) => state.AthleteModuleReducer,
  );

  const withdrawBalance = async (data) => {
    try {
      let url = `/athlete/transaction/withdraw-amount`;
      const res = await http.post(url);
      if (res?.data?.success) {
        setSuccesMessageHolder(false);
      }
    } catch (err) {
      errorToastMessage(err?.response?.data?.message);
    }
  };

  const fetchEarnings = async (data) => {
    try {
      setLoading(true);
      let url = `/athlete/transaction?page=${data.page}&pageSize=${data.pageSize}`;
      if (data?.month) {
        url = `${url}&month=${data.month}`;
      }
      if (data?.year) {
        url = `${url}&year=${data.year}`;
      }
      const res = await http.get(url);
      const statResponse = await http.get('athlete/transaction/earnings/stats');
      setEarningData(res.data.data.rows);
      setOrderCount(res.data.data.count);
      // setOrderData(res.data.data.orders.rows);
      setLoading(false);
      setStatsData(statResponse?.data);
    } catch (err) {
      console.log(err);
      // setError(err);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (orderCount > earningPage.pageSize) {
      setEarningPage((prev) => {
        return {
          ...earningPage,
          pageSize: prev.pageSize + 12,
        };
      });
    }
  };

  const pageHandler = (page) => {
    if (page.current) {
      setEarningPage({...earningPage, page: page.current});
    }
  };

  const onChange = (_, dateString) => {
    setEarningPage({...earningPage, year: dateString});
  };

  useEffect(() => {
    dispatch(setHeader(false));
    dispatch(handleSidebarTab('earnings'));
  }, [dispatch]);

  useEffect(() => {
    fetchEarnings(earningPage);
  }, [earningPage]);

  const closeWithdrawModal = () => {
    fetchEarnings(earningPage);
    setModalVisible(false);
  };

  const downloadCSV = async (data, name) => {
    let url = `/athlete/transaction/export?page=${data.page}&pageSize=${data.pageSize}`;
    if (data?.month) {
      url = `${url}&month=${data.month}`;
    }
    if (data?.year) {
      url = `${url}&year=${data.year}`;
    }
    const config = {
      responseType: 'arraybuffer',
    };

    const response = await http.get(url, config);
    const blob = new Blob([response?.data], {
      type: '"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const blobURL = window.URL.createObjectURL(blob);
    // Create new tag for download file
    const anchor = document.createElement('a');
    anchor.download = name;
    anchor.href = blobURL;
    anchor.click();

    // Remove URL.createObjectURL. The browser should not save the reference to the file.
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(blobURL);
    }, 100);
  };

  return (
    <AppLayout>
      <div className="content-wrapper">
        <AppHeader
          title="Earnings"
          subTitle={
            <p className="font-20 color-muted mb-5 fw-600">
              Review and easily export data related to all of your historical
              earnings and withdrawals.
            </p>
          }
        />

        {membeShipExpired && <SubScriptionBanner stripeStatus={stripeStatus} />}

        {statsData?.data && (
          <StatsData
            title="Your earnings"
            type="statsDollor"
            cardLabel1="AthleteUp balance"
            cardLabel2="Pending clearance"
            cardLabel3="Net income (all time)"
            cardLabel4={`Net income (${moment().format('YYYY')})`}
            label1Data={statsData?.data?.available_stripe_balance}
            label2Data={statsData?.data?.pending_stripe_balance}
            label3Data={statsData?.data?.net_income_total}
            label4Data={statsData?.data?.net_income_year}
            tooltipText="Funds from customer payments become available to be paid out approximately 2 days after the initial charge."
          />
        )}
      </div>
      {size?.width < 767 && (
        <div className="d-block">
          <button
            className="btn-withdraw btn-sm w-100 mb-3"
            onClick={() => setModalVisible(true)}>
            Withdraw
          </button>

          <div
            onClick={() => downloadCSV(earningPage, 'earning.xlsx')}
            className="btn text-uppercase color-muted  me-3 btn-sm font-m color-muted fw-700 w-100 mb-3 py-2">
            Export to csv
          </div>
        </div>
      )}
      <div className="d-xl-flex justify-content-between">
        <div className={`mb-3  ${size?.width < 767 ? 'd-flex' : ''}`}>
          <Select
            className="custom-select size-m me-2"
            defaultValue="all-transaction">
            <Select.Option value="all-transaction">
              All transactions
            </Select.Option>
          </Select>
          <Select
            className="custom-select size-m me-2"
            defaultValue="Select month"
            onChange={(value) =>
              setEarningPage({...earningPage, month: value})
            }>
            <Select.Option value="01">January</Select.Option>
            <Select.Option value="02">February</Select.Option>
            <Select.Option value="03">March</Select.Option>
            <Select.Option value="04">April</Select.Option>
            <Select.Option value="05">May</Select.Option>
            <Select.Option value="06">June</Select.Option>
            <Select.Option value="07">July</Select.Option>
            <Select.Option value="08">August</Select.Option>
            <Select.Option value="09">September</Select.Option>
            <Select.Option value="10">October</Select.Option>
            <Select.Option value="11">November</Select.Option>
            <Select.Option value="12">December</Select.Option>
          </Select>

          <DatePicker
            className="custom-datepicker"
            placeholder={new Date().getFullYear()}
            onChange={onChange}
            picker="year"
          />
        </div>
        {size?.width > 767 && (
          <div className="d-flex mb-3 align-items-center">
            <div
              onClick={() => downloadCSV(earningPage, 'earning.xlsx')}
              className="btn btn-s text-uppercase color-muted  me-3 fw-700 py-2 font-s">
              Export to csv
            </div>

            <button
              className="btn-withdraw btn-sm"
              onClick={() => setModalVisible(true)}>
              Withdraw
            </button>
          </div>
        )}
      </div>

      {size?.width > 767 ? (
        <EarningsTable
          loading={loading}
          earningData={earningData}
          count={orderCount}
          onChange={pageHandler}
        />
      ) : (
        <>
          {orderCount !== 0 ? (
            <EarningsMobileTable loading={loading} earningData={earningData} />
          ) : (
            <h5 className="text-muted text-center py-5">No Earnings found</h5>
          )}
          {orderCount > earningPage.pageSize && (
            <button
              type="button"
              className="btn btn-lg btn-primary mt-3 view-more  "
              onClick={loadMore}>
              view more
            </button>
          )}
        </>
      )}

      <CustomModal
        title=""
        visible={modalVisible}
        width="710px"
        isLoading={false}
        onClickCancel={() => setModalVisible(false)}
        className="withdraw-modal">
        <WithdrawEarningModal
          setModalVisible={setModalVisible}
          income={withDrawAmount?.data?.available}
          stripeAccount={stripeAccount}
          withdrawBalance={withdrawBalance}
          successFundsMsg={successFundsMsg}
          closeWithdrawModal={closeWithdrawModal}
        />
      </CustomModal>
    </AppLayout>
  );
};

export default Earnings;
