import React, { useEffect } from 'react';
import { icons } from '../../../../../utils/staticStrings';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../../../../store/athleteflow/action';
import { useHistory } from 'react-router-dom';

const BankDetailSuccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setHeader(true));
  }, [dispatch]);

  const goToDashboard = () => {
    history.push('/athlete/dashboard');
  }

  return (
    <div className="athlete-page-bg pricing-confirmation">
      <div className="profile-container">
        <div className="profile-main px-3 px-md-0 pricing-confirmation">
          <div className="profile-section">
            <div className="container-fluid p-0 text-center">
              <img src={icons.tickColorIcon} alt="tickIcon" className="mt-3 mb-5" />
              <div className="profile-title">Your bank account verification is in progress</div>
              <div className="mw-770 mx-auto">
                {/* <div className="profile-text1 mt-2 mb-5 font-20">
                  You can now start selling custom experiences and products from your personal shop while also landing exclusive sponsorship deals.
                </div> */}
                <div className="card-light card-list-spacing mt-4">
                  <div>
                    <button
                      type="button"
                      className="btn clickbtn text-uppercase fw-800"
                      onClick={goToDashboard}>
                      Back to dashboard
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BankDetailSuccess;