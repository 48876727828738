import React from 'react';
import ProfileImage from './ProfileImage';
import Text from '../../../components/Text/Text';
import {
  ModalTitleContainer,
  AthleteHeaderInfo,
  SportsList,
  LayoutDesktopView,
} from './style';
import moment from 'moment';

const ModalHeader = ({athletProfileDetail}) => {
  return (
    <LayoutDesktopView>
      <ModalTitleContainer>
        {athletProfileDetail.image_url ? (
          <ProfileImage
            className="img-fluid radius-m mt-2"
            mobileImgWidth={60}
            width={60}
            height={60}
            src={athletProfileDetail.image_url}
            desktopImgWidth="62px"
          />
        ) : (
          <div className="placeholder-athlete-icon sm"></div>
        )}

        <AthleteHeaderInfo>
          <Text
            display="flex"
            weight="700"
            lineHeight="32"
            size="24"
            textColor="#042237"></Text>
          <h2 className="color-primary-dark fw-700 font-24 mb-0">
            {athletProfileDetail?.name}
          </h2>
          <p className="fw-700 color-dark font-15 mb-0">
            {athletProfileDetail?.athlete?.headliner}
          </p>

          <SportsList>
            {athletProfileDetail?.gender && (
              <li className="text-capitalize font-15">
                {athletProfileDetail?.gender}
              </li>
            )}
            <li className="font-15">
              Age&nbsp;
              <>
                {athletProfileDetail?.dob && (
                  <>
                    {moment(athletProfileDetail?.dob, 'YYYYMMDD')
                      .fromNow()
                      .split(' ')[0] > 0 ? (
                      <>
                        {
                          moment(athletProfileDetail?.dob, 'YYYYMMDD')
                            .fromNow()
                            .split(' ')[0]
                        }
                      </>
                    ) : (
                      <>
                        {moment(athletProfileDetail?.dob, 'YYYYMMDD').fromNow()}
                      </>
                    )}

                    {/* {
                    moment(athleteDetails?.dob, 'YYYY-DD-MM')
                      .fromNow()
                      .split(' ')[0]
                  } */}
                  </>
                )}
              </>
            </li>
            <li className="font-15">
              {athletProfileDetail?.address && (
                <>
                  📍
                  <Text
                    display="flex"
                    weight="500"
                    size="15"
                    smLineHeight="22"
                    lineHeight="28"
                    textColor="#435969">
                    Currently in &nbsp;
                    {`${athletProfileDetail?.address?.city}, ${athletProfileDetail?.address?.state}`}
                  </Text>
                </>
              )}
            </li>
          </SportsList>
        </AthleteHeaderInfo>
      </ModalTitleContainer>
    </LayoutDesktopView>
  );
};
export default ModalHeader;
