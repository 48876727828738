//Post
export const GET_POSTS = '/posts';
export const GET_POST_DETAILS = '/posts';
export const USER_LOGIN = '/account/login';
export const REGISTER = '/account/athlete-signup';
export const REGISTER_BRAND = '/account/purchaser-signup';
export const RESET_PASSWORD = '/account/reset-password';
export const FORGOT_PASSWORD = '/account/forgot-password';
export const GET_SPORTS_LIST = '/athlete/sports';
export const SET_VERIFICATION_EMAIL = '/account/resend-verification-email';
export const CREATE_CAMPAIGN_URL = '/purchaser/campaign';
export const GET_CAMPAIGNS = '/purchaser/campaigns';
export const GET_CAMPAIGN_DATA = '/purchaser/campaign';
export const EDIT_CAMPAIGN = '/purchaser/campaign';
export const CREATE_OFFER = '/purchaser/campaign/custom_offer';
