import React, {useState} from 'react';
import {Divider, Input, Upload, Tooltip} from 'antd';
import Loader from 'react-loader-spinner';
import useDeviceSize from '../../hooks/useDeviceSize';
import MessageMediaUI from './MessageMediaUI';
const {TextArea} = Input;

// const {connect} = require('twilio-video');

const CustomMessageChannel = (props) => {
  const {
    sendMessageAction,
    messages,
    loader,
    participantsDetails,
    channelProxy,
    enableAttachMent,
    enableVideo,
    loadingChannel,
    commonMessageChannel,
    channelUserName,
    channelDetails,
    messageType,
    startVideoCall,
    scrollDiv,
    messageStatus,
    setMessageRead,
    type,
    channelList,
  } = props;

  const [messageText, setMessageText] = useState('');

  // const scrollDiv = useRef(null);
  const size = useDeviceSize();
  const sendMessageOnClick = () => {
    sendMessageAction(messageText);
    setMessageText('');
  };

  const userRole = localStorage.getItem('userRole');
  let OrderDetailsUrl;
  if (userRole === 'athlete') {
    OrderDetailsUrl = `order/details/${channelDetails?.id}`;
  } else {
    OrderDetailsUrl = `dashboard/order/${channelDetails?.id}`;
  }
  const statusType = (value) => {
    if (value === 'completed') {
      return 'status-success ';
    } else if (value === 'active') {
      return 'status-progress';
    } else if (value === 'disputed') {
      return 'disputed';
    } else if (value === 'declined') {
      return 'cancel';
    }
  };

  const DealStatusType = (value) => {
    if (value === 'completed') {
      return 'status-success ';
    } else if (value === 'in progress') {
      return 'status-progress';
    } else if (value === 'disputed') {
      return 'disputed';
    } else if (value === 'declined') {
      return 'cancel';
    } else {
      return 'offer';
    }
  };

  let channelTittle;
  if (messageType === 'order') {
    channelTittle =
      channelDetails?.listing?.type === 'Experiences'
        ? channelDetails?.listing?.listing_experience?.title
        : channelDetails?.listing?.listing_product?.title;
  } else {
    channelTittle = channelDetails?.title;
  }

  let profileUser = channelList?.users.find((item) => !item.purchaser);

  return (
    <div className="message-wrapper">
      {commonMessageChannel && channelUserName ? (
        <div className="px-0 px-lg-3">
          <h3 className="font-20 m-0 fw-700 mb-2 pt-3 color-dark text-capitalize">
            {type === 'athlete' ? (
              <>
                {channelUserName?.purchaser !== null ? (
                  <>
                    <span className="text-capitalize">
                      {channelUserName?.purchaser?.company}
                    </span>
                    {channelUserName?.purchaser?.company === '' ||
                    channelUserName?.purchaser?.company === null ? (
                      <span className="text-capitalize">
                        {channelUserName?.name}
                      </span>
                    ) : (
                      <span className="text-capitalize">
                        ({channelUserName?.name})
                      </span>
                    )}
                  </>
                ) : (
                  <> {channelUserName?.name}</>
                )}
              </>
            ) : (
              <>{profileUser?.name}</>
            )}
          </h3>

          <div className="d-flex  align-items-center mb-2 chat-header-label">
            <h6 className="fw-600 text-capitalize m-0 ">
              {messageType} #{channelDetails?.id}
            </h6>
            {messageType === 'order' ? (
              <>
                {channelDetails?.athlete_marked_complete &&
                channelDetails?.status === 'active' &&
                type === 'athlete' ? (
                  <span className="status-success">Completed</span>
                ) : (
                  <span
                    className={`${statusType(
                      channelDetails?.status,
                    )} ms-3 me-2 ${
                      channelDetails?.status === 'active'
                        ? ''
                        : 'text-capitalize'
                    }`}>
                    {channelDetails?.status === 'active'
                      ? 'In progress'
                      : channelDetails?.status === 'declined'
                      ? 'Canceled'
                      : channelDetails?.status}
                  </span>
                )}
              </>
            ) : (
              <span
                className={`${DealStatusType(
                  channelDetails?.status,
                )} text-capitalize ms-3 me-2`}>
                {channelDetails?.status === 'completed'
                  ? 'Completed'
                  : channelDetails?.status === 'pending'
                  ? 'Pending'
                  : channelDetails?.status}
              </span>
            )}

            <a
              className="cursor-pointer text-capitalize mx-2 status-purple"
              href={
                messageType === 'order'
                  ? OrderDetailsUrl
                  : `${messageType}s/${channelDetails?.id}`
              }>
              <img
                src="/images/externalLink.svg"
                width="20px"
                alt="external-page"
                className="me-1"
              />
              Details
            </a>
          </div>
          <p className="text-dark font-s fw-500 mb-2 text-truncate">
            {channelTittle}
          </p>
        </div>
      ) : (
        <h3 className="font-22 fw-700 m-0 mb-3 px-0 px-md-4">Messages</h3>
      )}
      <Divider className="my-0 my-md-2" />
      {loader ? (
        <div className="d-flex align-items-center justify-content-center">
          <Loader type="Bars" color="#00BFFF" height={50} width={100} />
        </div>
      ) : (
        <>
          {loadingChannel ? (
            <div className="d-flex align-items-center justify-content-center m-auto">
              <Loader type="Bars" color="#00BFFF" height={50} width={100} />
            </div>
          ) : (
            <div
              className="px-0 px-md-4 message-channel-holder mb-3"
              ref={scrollDiv}>
              {messages &&
                messages.map((message) => {
                  return (
                    <MessageMediaUI
                      messageList={message}
                      key={message.index}
                      participantsDetails={participantsDetails}
                      startVideoCall={startVideoCall}
                      setBrandLogo={true}
                      channelProxy={channelProxy}
                      setMessageRead={setMessageRead}
                    />
                  );
                })}
            </div>
          )}

          <div className="px-0 px-sm-4 mb-3 channel-textarea">
            {size.width > '991' ? (
              <div>
                <TextArea
                  required
                  placeholder="Type your message here..."
                  variant="outlined"
                  rows={2}
                  value={messageText}
                  disabled={messageStatus}
                  onChange={(event) => setMessageText(event.target.value)}
                />
                <div className="message-footer py-2">
                  <div className="d-flex px-4 align-items-center">
                    {enableAttachMent && (
                      <Upload
                        showUploadList={false}
                        customRequest={({file}) => {
                          const formData = new FormData();
                          formData.append('file', file);

                          channelProxy.sendMessage(formData);
                        }}
                        disabled={messageStatus}>
                        <button className="border btn bg-white d-flex align-items-center  me-3">
                          <i className="btn-icon-clip me-2"></i>
                          Attach file
                        </button>
                      </Upload>
                    )}
                    {enableVideo && (
                      <Tooltip
                        title="Join video call meeting"
                        color="#000"
                        overlayClassName="msg-tooltip">
                        <div
                          className="btn-icon-video btn me-3"
                          disabled={messageStatus}
                          onClick={startVideoCall}></div>
                      </Tooltip>
                    )}

                    <button
                      className="ms-auto btn bg-purple"
                      onClick={sendMessageOnClick}
                      disabled={messageStatus}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center message-holder-mobile">
                {enableAttachMent && (
                  <Upload
                    showUploadList={false}
                    customRequest={({file}) => {
                      const formData = new FormData();
                      formData.append('file', file);

                      channelProxy.sendMessage(formData);
                    }}
                    disabled={messageStatus}>
                    <button className="btn  d-flex align-items-center">
                      <i className="btn-icon-clip"></i>
                    </button>
                  </Upload>
                )}
                {enableVideo && (
                  <Tooltip
                    title="Join video call meeting"
                    color="#000"
                    overlayClassName="msg-tooltip">
                    <div
                      className="btn-icon-video btn me-3"
                      disabled={messageStatus}
                      onClick={startVideoCall}></div>
                  </Tooltip>
                )}
                <div className="messagee-text-box d-flex align-items-center">
                  <TextArea
                    required
                    placeholder="Type your message here..."
                    variant="outlined"
                    rows={1}
                    value={messageText}
                    disabled={messageStatus}
                    onChange={(event) => setMessageText(event.target.value)}
                  />
                  <div
                    className="btn-send-message cursor-pointer "
                    disabled={messageStatus}
                    onClick={sendMessageOnClick}></div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomMessageChannel;
