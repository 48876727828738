import React, {useState, useEffect} from 'react';
import {Layout, Drawer} from 'antd';
import Sidebar from './Sidebar';
import {headerSection} from '../../utils/staticStrings';
import MobileMenu from './MobileMenu';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  CheckoutInfoAlert,
  ResendEmailButton,
} from '../../modules/Purchaser/pages/style';
import {InfoCircleOutlined} from '@ant-design/icons';
import Text from '../../components/Text/Text';
import {resendVerificationEmail} from '../../store/athleteflow/action';
import {handleMessage} from '../../store/athleteflow/action';
import {toastMessage} from '../../helpers/toastMessage';
import http from '../../helpers/http';
import AlertFixedTopbar from '../components/AlertFixedTopBar';
import {fetchAthleteData} from '../../store/users/actions';

const AppLayout = (props) => {
  const [visible, setVisible] = useState(false);
  const {purchaserDetails, athleteDetails} = useSelector(
    (state) => state.UsersReducer,
  );
  const {message, resetEmailStatus, stripeStatus} = useSelector(
    (state) => state.AthleteModuleReducer,
  );

  const {Content} = Layout;
  const history = useHistory();
  const dispatch = useDispatch();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (resetEmailStatus === true) {
      if (message.text.success === true) {
        toastMessage(message.text.data);
        dispatch(handleMessage(''));
      }
    }
  }, [message, resetEmailStatus, dispatch]);

  useEffect(() => {
    let id = localStorage.getItem('id');
    let userRole = localStorage.getItem('userRole');
    if (id !== null && userRole === 'athlete') {
      dispatch(fetchAthleteData(id));
    }
  }, [dispatch]);

  const goToHome = () => {
    history.push('/');
  };

  const role = localStorage.getItem('userRole');

  const redirectStripeUrl = async () => {
    await http
      .post('/account/stripe')
      .then((res) => {
        window.location.replace(res?.data?.data?.url);
      })
      .catch((err) => {
        console.log(err.response?.data);
      });
  };
  // history.location.pathname === '/purchaser'
  return (
    <>
      {role === 'athlete' && (
        <>
          {(stripeStatus?.stripe_account_requirements?.currently_due.length >
            0 ||
            stripeStatus?.stripe_account_requirements?.past_due.length > 0) && (
            <AlertFixedTopbar
              title="Stripe requires some additional information to keep your account
        in goodstanding and to keep payouts enabled. View your account
        details now."
              clickAction={redirectStripeUrl}
              buttonText="View Stripe Account"
            />
          )}
        </>
      )}
      {stripeStatus?.subscription_valid === true &&
        stripeStatus.stripe_account_valid === false &&
        role === 'athlete' && (
          <AlertFixedTopbar
            title="Connect your account to Stripe to link your bank account to be able to receive payouts."
            clickAction={() =>
              history.push('/athlete/bank-detail-confirmation')
            }
            buttonText="Link your bank account"
          />
        )}

      {role !== 'athlete' &&
        !purchaserDetails.email_verified &&
        Object.keys(purchaserDetails).length > 0 && (
          <div>
            <CheckoutInfoAlert className="mb-0 mt-5 mt-md-0">
              <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center ">
                    <InfoCircleOutlined
                      style={{fontSize: '22px', color: '#55AF74'}}
                    />
                    <Text
                      weight="500"
                      mrLeft="12"
                      size="16"
                      smSize="13"
                      smLineHeight="18"
                      lineHeight="22"
                      textColor="#435969">
                      {purchaserDetails?.name}, you need to activate your
                      account{' '}
                      <span style={{fontWeight: 700}}>
                        {purchaserDetails?.email}.
                      </span>
                    </Text>
                  </div>
                  <ResendEmailButton
                    onClick={() =>
                      dispatch(resendVerificationEmail(purchaserDetails?.id))
                    }>
                    Resend Email
                  </ResendEmailButton>
                </div>
              </div>
            </CheckoutInfoAlert>
          </div>
        )}

      {role === 'athlete' && athleteDetails.activated_on === null && (
        <div>
          <CheckoutInfoAlert className="mb-0 mt-5 mt-md-0">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center ">
                  <InfoCircleOutlined
                    style={{fontSize: '22px', color: '#55AF74'}}
                  />
                  <Text
                    weight="500"
                    mrLeft="12"
                    size="16"
                    smSize="13"
                    smLineHeight="18"
                    lineHeight="22"
                    textColor="#435969">
                    {athleteDetails?.name}, you need to activate your account{' '}
                    <span style={{fontWeight: 700}}>
                      {athleteDetails?.email}.
                    </span>
                  </Text>
                </div>
                <ResendEmailButton
                  onClick={() =>
                    dispatch(resendVerificationEmail(athleteDetails?.id))
                  }>
                  Resend Email
                </ResendEmailButton>
              </div>
            </div>
          </CheckoutInfoAlert>
        </div>
      )}

      <Layout className="athletup-layout">
        <Sidebar width="270px" className="d-none d-md-block" />
        <Layout className="site-layout">
          <div className="d-flex d-md-none justify-content-between p-2 px-3 bg-sidebar align-items-center">
            <div onClick={goToHome} className="cursor-pointer">
              <img alt="headerlogo" src={headerSection.headerLogoBlack} />
            </div>
            <div className="icon-hamburger" onClick={showDrawer}></div>
          </div>

        <Drawer
          rootClassName="ant-drawer-custom"
          placement="right"
          // width="100%"
          onClose={onClose}
          open={visible}>
          <MobileMenu />
        </Drawer>
        <Content
          className={`app-right-content ${
            props?.contentClassname ? props?.contentClassname : ''
          }`}
          style={{
            minHeight: '100vh',
          }}>
          {props.children}
        </Content>
      </Layout>
    </Layout>
  </>);
};

export default AppLayout;
