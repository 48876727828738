import React from 'react'
import { useHistory } from 'react-router-dom';

const ConfirmModal = (props) => {
  const history = useHistory()
  return (
    <div className="confirm-midal text-center">
      <h1 className="font-l fw-bold mb-3">Are you sure you want to exit?</h1>
      <p className="mb-5">Information entered for this listing will be lost and not saved if you exit prior to completing.</p>
      <div className="row">
        <div className="col-12 col-md-6 mb-3 mb-md-0 order-2 order-md-0">
          <button className="btn btn-light" onClick={props.onCancel}>CANCEL</button>
        </div>
        <div className="col-12 col-md-6 mb-3">
          <button className="btn btn-primary" onClick={() => {
            history.push(props.link);
          }}>Exit</button>
        </div>
      </div>
    </div >
  )
}

export default ConfirmModal;
