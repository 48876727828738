import React, {useState} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {CheckoutTextArea} from './style';
import {Select} from 'antd';
import axios from 'axios';

const BillingFormSerive = ({
  setAddressDetail,
  cardListProduct,
  setInscription,
  countries,
  formRef,
}) => {
  const [stateFromPostalCode, setStatePostalCode] = useState('');

  let schema = yup.object().shape({
    country: yup.string().required('Country required'),
    inscription:
      cardListProduct?.purchaser_requirements &&
      yup.string().required('Please add requirements'),
  });

  let paymentPayload;
  if (paymentPayload) {
    JSON.parse(localStorage.getItem('paymentPayload'));
  }
  // const handleRequirements = ({target: {value}}) => {
  //   setStateRequirements(value);
  //   setInscription(value);
  // };

  const handleChangeZip = async (data) => {
    if (data.length > 2) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${data}&key=${process.env.REACT_APP_GOOGLE_GEOCODE}`,
        )
        .then((res) => {
          let addressDetail = res.data?.results[0]?.address_components;

          if (addressDetail !== undefined && addressDetail.length > 2) {
            let stateFromPostalCode = addressDetail.filter(function (obj) {
              return obj?.types[0] === 'administrative_area_level_1';
            });
            setStatePostalCode(stateFromPostalCode[0]?.short_name);
          }
        })
        .catch((err) => console.log(err));
      // let addressDetail = await addressFromZip;
      // console.log(addressDetail?.data?.results[0].formatted_address);
    }
  };

  return (
    <Formik
      initialValues={{
        zip: '',
        country: 'US',
        inscription: '',
      }}
      innerRef={formRef}
      validationSchema={schema}
      onSubmit={(values) => {
        let billingPayload = Object.fromEntries(
          Object.entries(values).filter(([key, v]) => key !== 'inscription'),
        );

        setInscription(values?.inscription);
        if (stateFromPostalCode === '' && values?.zip !== '') {
          setAddressDetail({...billingPayload});
        } else if (values?.zip === '') {
          setAddressDetail({country: values?.country});
        } else {
          setAddressDetail({...billingPayload, state: stateFromPostalCode});
        }
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit} className="m-0">
            {cardListProduct?.purchaser_requirements && (
              <div className="box-checkout p-3 p-md-4">
                <p className="text-muted font-m">
                  Please provide the following additional information.
                </p>
                <div className="d-flex flex-column mt-4 ">
                  <h6 className="fw-700 mb-3">Additional Order Details</h6>
                </div>

                <div className="d-flex flex-column  mb-4">
                  <span className="form-label d-block">
                    {cardListProduct?.purchaser_requirements
                      ? cardListProduct?.purchaser_requirements
                      : ' Please provide custom text for inscription'}
                    <span className="text-danger"> *</span>
                  </span>
                  <CheckoutTextArea
                    value={values.inscription}
                    onChange={(e) =>
                      setFieldValue('inscription', e.target.value)
                    }
                    placeholder="Add requirements"
                    autoSize={{minRows: 3, maxRows: 5}}
                  />
                  <div className="text-danger text-sm fw-700">
                    {touched.inscription &&
                      errors.inscription &&
                      errors.inscription}
                  </div>
                </div>
              </div>
            )}

            <div className="box-checkout p-3 p-md-4">
              <div className="d-flex flex-column mt-4 ">
                <h6 className="fw-700 mb-1">Billing Details</h6>
                <p className="fw-700 mb-3 text-muted">
                  To comply with US tax regulations, your zip code will be used
                  to calculate sales tax for certain purchased services.
                </p>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <span className="form-label d-block">
                    Country <span className="text-danger">*</span>
                  </span>
                  <Select
                    className="custom-select custom-select-light size-l "
                    name="country"
                    value={values.country}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      setFieldValue('country', value);
                    }}>
                    {countries !== undefined && (
                      <>
                        {countries.map((option) => (
                          <Select.Option
                            value={option.isoCode}
                            key={option.name}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </>
                    )}
                  </Select>

                  <div className="text-danger text-sm fw-700">
                    {touched.country && errors.country && errors.country}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <span className="form-label d-block">
                    Zip code / postal code
                    {/* <span className="text-danger">*</span> */}
                  </span>
                  <input
                    className="form-control input-lg"
                    value={values.zip}
                    type="text"
                    placeholder="Zip code"
                    name="zip"
                    onChange={(e) => {
                      setFieldValue('zip', e.target.value);
                      handleChangeZip(e.target.value);
                    }}
                  />
                </div>

                {/* <div className="col-12 col-md-6 mb-4">
                  <span className="form-label d-block">
                    State <span className="text-danger">*</span>
                  </span>
                  <input
                    className="form-control input-lg"
                    value={stateFromPostalCode}
                    type="text"
                    name="state"
                    placeholder="State"
                    onChange={handleChange}
                  />
                  <div className="text-danger text-sm fw-700">
                    {touched.state && errors.state && errors.state}
                  </div>
                </div> */}
              </div>

              {/* <button className="btn btn-save-var2" type="submit">
                Save
              </button> */}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default BillingFormSerive;
