import http from '../../../helpers/http';
import * as url from '../../../helpers/url_helper';

export const getPurchaserDetails = (payload) => {
  return http.get(`/purchaser/${payload.id}`);
};

export const triggerPurchaserProfile = (payload) => {
  return http.put(`purchaser/details/${payload.id}`, payload.payload);
};

export const triggerCreateCampaign = (payload) => {
  return http.post(url.CREATE_CAMPAIGN_URL, payload);
};

export const triggerGetCampaigns = (payload) => {
  return http.get(
    `${url.CREATE_CAMPAIGN_URL}?page=${payload.page}&pageSize=${payload.pageSize}&status=${payload.status}`,
  );
};

export const triggerGetCampaignData = (payload) => {
  return http.get(`${url.GET_CAMPAIGN_DATA}/${payload.id}`);
};

export const triggerGetShippingCost = (payload) => {
  return http.post(
    `purchaser/listing/${payload.ListingId}/order/price`,
    payload.shppingAdressPaylod,
  );
};
export const triggerEditCampaignData = (payload) => {
  return http.put(`${url.EDIT_CAMPAIGN}/${payload.id}`, payload.apiPayload);
};

export const triggerInviteToCampaign = (payload) => {
  return http.post(
    `/purchaser/campaign/${payload.campaignId}/invite`,
    payload.payload,
  );
};
export const triggerPurchaseOrder = (payload) => {
  return http.post(`stripe/order/payment_intent`, payload.payload);
};

export const getAllOrder = (payload) => {
  let url = `/${payload.user}/order?page=${payload.page}&pageSize=${payload.pageSize}`;
  if (payload?.status) {
    url = `${url}&status=${payload.status}`;
  }
  return http.get(url);
};

export const triggerCreateCustomOffer = (payload) => {
  return http.post(`${url.CREATE_OFFER}`, payload);
};

export const triggerPurchaserGetDeals = (payload) => {
  let url = `/purchaser/campaign/deal?page=${payload.page}&pageSize=${payload.pageSize}`;
  if (payload?.status) {
    url = `${url}&status=${payload.status}`;
  }
  return http.get(url);
};

export const triggerGetDealData = (payload) => {
  return http.get(`/deal/${payload.id}`);
};

export const triggerTagDealToCampaign = (payload) => {
  return http.put(
    `/deal/${payload.dealId}/tag_to_campaign/${payload.campaignId}`,
  );
};

export const triggerGetPaymentMethod = () => {
  return http.get(`/stripe/default-payment-method`);
};

export const triggerDealStatusUpdate = (payload) => {
  return http.put(`/deal/${payload.dealId}/purchaser/status`, payload.payload);
};

export const triggerDealCounterOfferStatusUpdate = (payload) => {
  return http.put(`/deal/${payload.dealId}/offer_status`, payload.payload);
};

export const triggerCreateCounterOffer = (payload) => {
  return http.post(`/deal/${payload.dealId}/counter_offer`, payload.payload);
};

export const triggerMarkDealDispute = (payload) => {
  return http.put(`/deal/${payload.dealId}/dispute`, {});
};

export const triggerGetStateList = (payload) => {
  return http.get(`/assets/states?country_code=${payload.country}`);
};
