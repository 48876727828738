import styled from 'styled-components';

export const DeliverableCard = styled.div`
  background: #f2f5f766;
  border: 1px solid #d3dce1;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 992px) {
    padding: 15px;
    margin-top: 20px;
  }

  .counter-title {
    font-size: 20px !important;
  }

  .counter-label-del {
    font-weight: 600;
    font-size: 16px !important;
  }
`;

export const CompensationInfo = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #435969b3;
  display: flex;
`;

export const AddDeliverable = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #7741fe;
  cursor: pointer;
  margin: 30px 0;
`;

export const TotalAmount = styled.div`
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  background: #f2f5f7;
  border-radius: 8px;
  font-size: 18px;
  color: #042237;
  @media screen and (max-width: 992px) {
    font-size: 17px;
    font-weight: 500;
  }
`;

export const MarkCompleteButton = styled.button`
  width: 203px;
  height: 56px;
  border: 1px solid #d3dce1;
  border-radius: 8px;
  color: #435969;
  font-size: 16px;
  font-weight: 600;
  background-color: #fff;

  @media screen and (max-width: 992px) {
    width: -webkit-fill-available;
    width: -moz-available;
    margin-bottom: 25px;
    svg {
      float: left;
      top: 8px;
      position: relative;
      left: 15px;
    }
  }
`;

export const CompletedButton = styled.button`
  width: 203px;
  height: 56px;
  border-radius: 8px;
  border: none;
  color: #31bf83;
  font-size: 16px;
  font-weight: 600;
  background-color: #55af7419;

  @media screen and (max-width: 992px) {
    width: -webkit-fill-available;
    width: -moz-available;
    margin-bottom: 25px;
  }
`;

export const ConfirmDealInfo = styled.div`
  background: #042237;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  margin-bottom: 30px;

  @media screen and (max-width: 992px) {
    border-radius: 0;
  }
`;
