import React, {useEffect, useState} from 'react';
import Text from '../../../../../components/Text/Text';
import {SendInviteButton} from '../Campaigns/style';
import {
  CheckoutButton,
  DeskTopLayoutView,
  MobileLayoutViewView,
} from '../../../pages/style';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaigns, tagDealToCampaign } from '../../../../../store/brandflow/action';

import Loader from 'react-loader-spinner';
import CampaignCard from '../Athletes/CampaignCard';
import { CustomCampaignInviteCard } from '../Athletes/styled';

const TagtoCampaign = ({dealId, closeModal}) => {
  const [selectedCard, setSelectedCard] = useState('');
  const dispatch = useDispatch();
  const { campaigns, isLoading } = useSelector(
    (state) => state.BrandModuleReducer,
  );

  useEffect(()=> {
    dispatch(getCampaigns({
      page: 1,
      pageSize: 10,
      status: 'active'
    }));
  }, [dispatch]);

  useEffect(()=> {
    if(campaigns?.length > 0) {
      setSelectedCard(campaigns[0].id);
    }
  }, [campaigns]);

  const selectCard = (campaignData) => {
    setSelectedCard(campaignData.id);
  }

  const tagToCampaign = () => {
    const payload = {
      dealId: dealId,
      campaignId: selectedCard
    };
    dispatch(tagDealToCampaign(payload));
    closeModal();
  }

  return (
    <CustomCampaignInviteCard>
      <DeskTopLayoutView>
        {isLoading && <div className="loader-contain">
        <div className="loader">
          <Loader
            type="Bars"
            color="#00BFFF"
            height={50}
            width={50}
          />
        </div></div>}
        {!isLoading && 
          <>
            {campaigns?.length === 0 && 
              <div className="text-center title my-3">No active campaigns available</div>  
            }
            {campaigns.map((campaignData) => (
            <CampaignCard 
              screen='desktop'
              key={campaignData.id}
              campaignData={campaignData} 
              isActive={selectedCard === campaignData.id}
              selectCard={()=> {selectCard(campaignData)}}/>
          ))}
          </>
        }
      </DeskTopLayoutView>
      <MobileLayoutViewView>
        {isLoading && <div className="loader-contain">
        <div className="loader">
          <Loader
            type="Bars"
            color="#00BFFF"
            height={50}
            width={50}
          />
        </div></div>}
        {!isLoading && <>
          {campaigns?.length === 0 &&             
          <div className="text-center title my-3">
            No active campaigns available</div>}
          {campaigns.map((campaignData) => (
            <CampaignCard 
              screen='mobile'
              key={campaignData.id}
              campaignData={campaignData} 
              isActive={selectedCard === campaignData.id}
              selectCard={()=> {selectCard(campaignData)}}/>
          ))}
        </>}
      </MobileLayoutViewView>

      <div className="d-flex justify-content-center">
        <SendInviteButton>
          <CheckoutButton onClick={tagToCampaign} disabled={campaigns?.length === 0}>
            <Text
              weight="800"
              cursor="pointer"
              size="18"
              lineHeight="23"
              textColor="#FFFFFF">
              TAG 
            </Text>
          </CheckoutButton>
        </SendInviteButton>
      </div>

    </CustomCampaignInviteCard>
  );
};

export default TagtoCampaign;
