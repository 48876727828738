import React from 'react';
import Hr from '../../../../../components/Hr/index';
import Text from '../../../../../components/Text/Text';

const ModalHeader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Text
        display="flex"
        weight="800"
        size="24"
        lineHeight="32"
        textColor="#042237">
        Price Summary
      </Text>
      <Hr mrBottom="20px" mrTop="20px" />
    </div>
  );
};

export default ModalHeader;
