import React from 'react';
import styled, {css} from 'styled-components';
import {node, string} from 'prop-types';

const Div = styled.div`
  font-size: ${({size}) => size}px;
  display: ${({display}) => display};
  margin-right: ${({mrRight}) => mrRight}px;
  margin-left: ${({mrLeft}) => mrLeft}px;
  margin-top: ${({mrTop}) => mrTop}px;
  margin-bottom: ${({mrBottom}) => mrBottom}px;
  font-weight: ${({weight}) => weight};
  cursor: ${({cursor}) => cursor};
  color: ${({textColor}) => textColor};
  font-family: ${({fontFamily}) => fontFamily};
  text-transform: ${({textCase}) => textCase};
  opacity: ${({opacity}) => (opacity ? opacity : 1)};
  height: ${({height}) => height}px;
  padding: ${({padding}) => padding}px;
  white-space: normal !important;
  ${({lineHeight}) => (lineHeight ? `line-height:${lineHeight}px;` : '')};
  word-wrap: normal;
  ${({smSize, smHeight}) =>
    (smSize || smHeight) &&
    css`
      @media only screen and (max-width: 576px) {
        font-size: ${smSize}px;
        ${({smLineHeight}) =>
          smLineHeight ? `line-height:${smLineHeight}px;` : ''};
        ${({smHeight}) => (smHeight ? `height:${smHeight}px;` : '')};
      }
    `}
  ${({mdSize, mdHeight}) =>
    (mdSize || mdHeight) &&
    css`
      @media only screen and (max-width: 1200px) {
        font-size: ${mdSize}px;
        ${({mdLineHeight}) =>
          mdLineHeight ? `line-height:${mdLineHeight}px;` : ''};
        ${({mdHeight}) => (mdHeight ? `height:${mdHeight}px;` : '')};
      }
    `}
`;

const Text = ({
  children,
  size,
  display,
  weight,
  cursor,
  textColor,
  className,
  fontFamily,
  smSize,
  textCase,
  lineHeight,
  id,
  mrRight,
  mrLeft,
  mrTop,
  smLineHeight,
  opacity,
  smHeight,
  height,
  mrBottom,
  padding,
  mdHeight,
  mdSize,
  mdLineHeight,
}) => (
  <Div
    size={size}
    display={display}
    weight={weight}
    cursor={cursor}
    textColor={textColor}
    className={className}
    fontFamily={fontFamily}
    smSize={smSize}
    textCase={textCase}
    lineHeight={lineHeight}
    mrRight={mrRight}
    mrLeft={mrLeft}
    mrBottom={mrBottom}
    mrTop={mrTop}
    smLineHeight={smLineHeight}
    opacity={opacity}
    smHeight={smHeight}
    height={height}
    padding={padding}
    mdHeight={mdHeight}
    mdSize={mdSize}
    mdLineHeight={mdLineHeight}
    id={id}>
    {children}
  </Div>
);

Text.propTypes = {
  children: node,
  size: string,
  display: string,
  weight: string,
  cursor: string,
  textColor: string,
  className: string,
  smSize: string,
  fontFamily: string,
  textCase: string,
  mrRight: string,
  mrLeft: string,
  mrTop: string,
  lineHeight: string,
  id: string,
  smLineHeight: string,
  opacity: string,
  smHeight: string,
  height: string,
  padding: string,
  mrBottom: string,
  mdHeight: string,
  mdSize: string,
  mdLineHeight: string,
};

Text.defaultProps = {
  size: '12',
  opacity: '1',
  display: 'block',
  weight: '400',
  cursor: 'auto',
  mrRight: '0',
  mrLeft: '0',
  mrTop: '0',
  mrBottom: '0',
  smLineHeight: '',
  textColor: 'inherit',
  fontFamily: 'inherit',
  textCase: 'inherit',
  smHeight: '',
  height: '',
  padding: '',
  mdHeight: '',
  mdSize: '',
  mdLineHeight: '',
  
};

export default Text;
