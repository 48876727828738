import {Modal} from 'antd';
import styled from 'styled-components';

export const Subheader = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #042237;
  margin-bottom: 20px;
`;

export const RequiredCriteriaOpen = styled.div`
  background: #ffffff99;
  border: 1px solid #d3dce1;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  color: #435969;
  padding: 12px 20px;
  margin-top: 30px;
  @media screen and (max-width: 992px) {
    padding: 0 12px 20px;
  }
  .criteria {
    font-weight: 500;
    margin-left: 10px;
    @media screen and (max-width: 992px) {
      margin-left: 0;
      margin-top: 8px;
      font-size: 12px;
    }
  }
`;

export const ListOfCampaigns = styled.div`
  margin-top: 20px;
`;

export const CamFilters = styled.div`
  margin: 25px 0;

  @media screen and (max-width: 992px) {
    margin: 25px 10px;
  }
`;

export const CamApplyButton = styled.div`
  display: flex;
  height: 40px;
  width: 130px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background: #042237;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  @media screen and (max-width: 992px) {
    width: -webkit-fill-available;
    width: -moz-available;
    font-size: 15px;
    margin-top: 25px;
  }
`;

export const CamViewCriteria = styled.div`
  background: rgba(242, 245, 247, 0.4);
  border: 1px solid rgba(211, 220, 225, 0.4);
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 30px;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 700;

  .criteria {
    font-weight: 500;
    margin-left: 10px;
    @media screen and (max-width: 992px) {
      margin: 0;
    }
  }
`;

export const CompanyCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  padding: 30px 40px;
  margin: 30px 0;
  @media screen and (max-width: 992px) {
    padding: 30px 20px;
  }

  .company-name {
    font-size: 22px;
    font-weight: 700;
  }

  .company-industry {
    font-size: 15px;
    font-weight: 600;
  }

  .company-desc {
    font-weight: 400;
    font-size: 14px;
    margin: 16px 0;
  }

  .company-footer {
    color: #7741fe;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      gap: 18px;
    }
  }
`;

export const EmptyImg = styled.div`
  width: 50px;
  height: 50px;
  background: #f2f5f7;
  border-radius: 7px;
  @media screen and (max-width: 992px) {
    width: 40px;
    height: 40px;
  }
`;

export const EmptyBrandName = styled.div`
  width: 110px;
  height: 26px;
  background-color: #f2f5f7;
  border-radius: 4px;
  margin-left: 10px;
  @media screen and (max-width: 992px) {
    width: 80px;
  }
`;

export const EmptyDeliverables = styled.div`
  width: 550px;
  height: 26px;
  background: #f2f5f7;
  border-radius: 4px;
  @media screen and (max-width: 992px) {
    width: -webkit-fill-available;
    width: -moz-available;
    margin-top: 8px;
  }
  @media screen and (min-width: 1300px) {
    width: 100%;
  }
`;

export const FilterButton = styled.div`
  background: #ffffff;
  border: 1px solid #d3dce1;
  border-radius: 10px;
  cursor: pointer;
  height: 50px;
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  justify-content: center;
  color: #435969;
  opacity: 0.7;
  margin-bottom: 12px;
`;

export const ApplyCampaign = styled(Modal)`
  width: 710px !important;
  max-width: 710px;
  border-radius: 20px;
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .ant-modal-content {
    border-radius: 20px;

    /* background: red; */
  }
  .ant-modal-close {
    top: 18px;
    right: -4px;
  }

  @media screen and (max-width: 767.96px) {
    max-width: 375px;
  }
`;
