import styled, {css} from 'styled-components';
import {InfoCircleOutlined} from '@ant-design/icons';
import {
  Modal,
  Carousel,
  Tabs,
  Card,
  Input,
  Divider,
  Checkbox,
  Radio,
} from 'antd';
import Text from '../../../components/Text/Text';
const {TextArea} = Input;

export const AthleteInfoContainer = styled.div`
  display: flex;

  flex-direction: column;
`;

export const Icon = styled.img`
  display: flex;
  width: ${({width}) => width || 30}px;
  height: ${({height}) => height || 30}px;
  margin-right: ${({mrRight}) => mrRight || 0}px;
  margin-left: ${({mrLeft}) => mrLeft || 0}px;
`;

export const AthleteName = styled.div`
  display: flex;
  align-items: center;
`;

export const ShareDetails = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  @media screen and (max-width: 767.98px) {
    display: none;
  }
`;

export const ShareDetailsMobile = styled.div`
  display: none;
  align-items: center;
  @media screen and (max-width: 767.98px) {
    display: flex;
    width: 20px;
  }
`;

export const AthleteTagsContainer = styled.div`
  display: flex;
  margin-top: 0;
  align-items: center;
  border-top: 1px solid #d9e1e7;
  min-height: 60px;
  padding-top: 5px;
`;

export const AthleteSocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  // align-items: center;
  border-top: 1px solid #d9e1e7;
  border-bottom: 1px solid #d9e1e7;
  height: auto;
  @media screen and (max-width: 767.98px) {
    margin-top: 0px;
    position: relative;
    flex-direction: column;
    height: auto;
    padding: 10px 0px;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  max-width: 640px;
  width: 100%;
  flex-direction: column;
  display: grid;
  padding: 15px 0;
  @media screen and (max-width: 767.98px) {
    padding: 0;
  }
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 767.98px) {
    display: none;
  }
`;

export const LocationMobile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  position: relative;
  @media screen and (min-width: 799px) {
    display: none;
  }
`;

export const SocialInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SocialMedia = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 50%;
  display: grid;
  grid-template-columns: 10fr 10fr 10fr 10fr 10fr;
  @media screen and (max-width: 767.98px) {
    flex-basis: 100%;
  }
`;

export const ImageContainer = styled.div`
  max-width: ${({width}) => width}px;
  max-height: ${({height}) => height}px;

  display: flex;
  justify-content: center;
  z-index: 10;
  // overflow: hidden;
  border-radius: 20px;
  @media screen and (max-width: 767.98px) {
    ${({maxWidth}) =>
      maxWidth ? `max-width:${maxWidth}px;` : `max-width:189px;`}
  }
  ${({smWidth}) =>
    smWidth &&
    css`
      @media only screen and (max-width: 767.98px) {
        width: ${smWidth}px;
      }
    `}
  ${({smMaxHeight}) =>
    smMaxHeight &&
    css`
      @media only screen and (max-width: 767.98px) {
        max-height: ${smMaxHeight}px;
      }
    `}
  ${({smHeight}) =>
    smHeight &&
    css`
      @media only screen and (max-width: 767.98px) {
        height: ${smHeight}px;
      }
    `}
`;

export const CustomText = styled(Text)`
  @media screen and (max-width: 767.98px) {
    font-size: 36px !important;
  }
`;

export const AthleteInfoModal = styled(Modal)`
  .ant-modal-close {
    color: #042237;
    top: 35px;
    right: 30px;
    width: 100px;
  }
  .ant-modal-close-x {
    width: unset;
    @media (max-width: 767.98px) {
      top: -15px;
      position: relative;
      right: -18px;
    }
  }
  .ant-modal-header {
    @media screen and (max-width: 767px) {
      border: none;
      margin-top: 20px;
    }
    margin: 0px;
    padding: 0;
    padding-top: 16px;
    border-bottom: 1px solid #d3dce1;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    border-radius: 20px;
    padding: 12px;
  }
`;

export const ModalTitleContainer = styled.div`
  display: flex;
  // align-items: center;
`;

export const AthleteHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

export const AthleteModalBody = styled.div`
  display: flex;

  .ant-modal-close {
    top: 15px;
    right: -16px;
  }

  .review-holder {
    background-color: transparent;
    box-shadow: none;
    padding: 5px 20px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const AthleteCarousel = styled.div`
  display: flex;
  width: 45%;
`;

export const AthleteModalBodyInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomCarousel = styled(Carousel)`
  width: 100%;
  max-width: 350px;
`;

export const CustomTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    background-color: #042237;
  }

  .ant-tabs-tabpane {
    border-radius: 20px;
    overflow: visible !important;
    /* border: 1px solid red; */
    .active {
      .ant-card-body {
        position: relative;
      }
      .ant-card-body:before {
        border-left: solid 3px #7741fe;
        position: absolute;
        left: -15px;
        height: 100%;
      }
    }
  }

  .ant-tabs-nav {
    margin: 0px 0px 20px 0px;

    .ant-tabs-tab {
      padding: 5px 0px;
      font-weight: 700;
      color: #435969;
      line-height: 30px;
      letter-spacing: 1px;
      font-size: 16px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #042237;
      text-transform: uppercase;
    }

    .ant-tabs-tab-btn {
      text-transform: uppercase;
    }

    .ant-tabs-tab-btn:active {
      color: #042237;
    }
  }
  .ant-tabs-nav::before {
    border-bottom: 0px !important;
  }

  .ant-card:first-child {
    @media screen and (min-width: 768px) {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }
  .ant-card:last-child {
    @media screen and (min-width: 768px) {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    position: relative;
  }
`;

export const LayoutDesktopView = styled.div`
  overflow: visible;
  .ant-tabs.ant-tabs-top.ant-tabs-large {
    overflow: visible;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const LayoutMobileView = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const MyPurchaseCard = styled(Card)`
  width: 100%;
  background: #ffffff;
  border: none;
  padding: 25px 16px;
  border-bottom: 1px solid #d3dce1;
  &:last-child {
    border-bottom: none;
    border-radius: 0;
  }

  .ant-card-body {
    padding: 0px;
  }
`;

export const OrderDetails = styled.div`
  display: flex;
  flex-basis: 20%;
  max-width: 144px;
  flex-shrink: 1;
  @media screen and (max-width: 991.98px) {
    display: flex;
    max-width: 100%;
  }
`;

export const OrderMessage = styled.div`
  border: 1px solid #d9e1e7;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const MessageTextArea = styled(TextArea)`
  border: none;
  padding-top: 10px;
  padding-left: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #435969;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const MessageSend = styled.div`
  background: #f2f5f7;
  padding: 10px 30px;
  border: none;
  border-top: 1px solid #d9e1e7;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: flex-end;
`;

export const SendMessageButton = styled.button`
  background: rgba(119, 65, 254, 0.2);
  display: block;
  border-radius: 8px;
  color: #ffffff;
  width: 130px;
  height: 40px;
  font-weight: 600;
  border: none;

  &:hover {
    background: rgba(119, 65, 254, 0.2);
    display: block;
    border-radius: 8px;
    color: #ffffff;
  }

  &[disabled] {
    opacity: 0.3;
    color: #ffffff;
    background: #7741fe;
  }
`;

export const MessageInput = styled(Input)`
  padding: 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  border-radius: 8px;
`;

// export const OrderInfoAlert = styled.div`
//   margin-top: 24px;
//   display: flex;
//   padding: 18px 40px;
//   background: linear-gradient(90.77deg, #8df49b -10.7%, #589cf9 91.25%);

//   opacity: 0.2;
//   border-radius: 20px;
// `;

export const OrderInfoAlert = styled.div`
  position: relative;
  margin-top: 24px !important;
  display: flex;
  padding: 18px 40px;
  background: linear-gradient(
      90.77deg,
      rgb(141 244 155 / 20%) -10.7%,
      rgb(88 156 249 / 20%) 91.25%
    )
    left top no-repeat;
  background-size: cover;
  min-height: 80px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  border-radius: 20px;
  svg {
    width: 22px;
    height: 22px;
  }
  @media screen and (max-width: 992px) {
    /* min-height: 66px; */
    height: 100%;
    padding: 13px;
  }
`;

export const CustomInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: #55af74;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media screen and (max-width: 767.98) {
    align-items: flex-start;
    margin-top: 5px;
  }
`;

export const ConfirmOrder = styled.div`
  display: flex;
  margin: 16px 0px;
  background: #042237;
  border-radius: 8px;
  padding: 18px 30px;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 18px 20px;
  }
`;

export const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 600px) {
    align-items: center;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 991px) {
    display: block;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const DisputeOrder = styled.button`
  background: #ffffff;
  display: flex;
  border-radius: 8px;
  color: #042237;
  flex: 0 0 210px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  margin-right: 10px;

  @media screen and (max-width: 600px) {
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: 24px;
    width: 100%;

    flex: 0 0 100%;
  }

  &:hover {
    background: #ffffff;
  }

  &:active {
    background: #ffffff;
  }

  &:focus {
    background: #ffffff;
  }
`;

export const ConfirmDisputeOrder = styled.button`
  background: #31bf83;
  display: flex;
  border-radius: 8px;
  color: #ffffff;
  flex: 0 0 210px;
  // width: 50%;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  /* padding: 10px 22px; */

  @media screen and (max-width: 600px) {
    width: 100%;
    flex: 0 0 100%;
  }

  &:hover {
    background: #31bf83;
  }

  &:active {
    background: #31bf83;
  }

  &:focus {
    background: #31bf83;
  }
`;

export const DealsContainer = styled.div`
  margin-top: 30px;
  padding: 20px 0px;
  border: 1px solid #d3dce1;
  box-sizing: border-box;
  border-radius: 8px;

  @media screen and (max-width: 767.98px) {
    padding: 20px;
  }
`;

export const MediaFlex = styled.div`
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767.98px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CustomDivider = styled(Divider)`
  color: #d3dce1;
`;

export const Deliverables = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0px 30px;
  @media screen and (max-width: 992px) {
    padding: 0;
  }
`;

export const OfferDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  @media screen and (max-width: 1060px) {
    flex-direction: column;
  }
  @media screen and (max-width: 992px) {
    padding: 0;
  }
`;

export const CardDetails = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1060px) {
    justify-content: space-between;
    width: 100%;
  }

  @media screen and (max-width: 767.98px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AcceptOrDecline = styled.div`
  display: flex;
  /* flex-basis: 50%; */
  width: 45%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1060px) {
    width: 100%;
    margin-top: 20px;
  }

  @media screen and (max-width: 600px) {
    margin-top: 10px;
  }
`;

export const AcceptOrDeclinePurchaser = styled.div`
  display: flex;
  /* flex-basis: 50%; */
  width: 50%;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 1060px) {
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
  }

  @media screen and (max-width: 600px) {
    margin-top: 20px;
    flex-direction: column;
  }
`;

export const AcceptOffer = styled.button`
  background: #31bf83;
  display: block;
  border-radius: 8px;
  color: #ffffff;
  width: 33.33%;
  max-width: 130px;
  border: none;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  order: 3;

  @media screen and (max-width: 1060px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }

  &:hover {
    background: #31bf83;
  }

  &:active {
    background: #31bf83;
  }

  &:focus {
    background: #31bf83;
  }
`;

export const AcceptOfferMobile = styled.button`
  background: #31bf83;
  display: none;
  border-radius: 8px;
  color: #ffffff;
  width: 100%;
  border: none;
  justify-content: center;
  align-items: center;
  min-height: 40px;

  @media screen and (max-width: 600px) {
    margin-top: 20px;
    display: block;
  }

  &:hover {
    background: #31bf83;
  }

  &:active {
    background: #31bf83;
  }

  &:focus {
    background: #31bf83;
  }
`;

export const Decline = styled.button`
  background: #f2f5f7;
  display: flex;
  border-radius: 8px;
  color: #435969;
  width: 33%;
  max-width: 130px;
  border: none;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  order: 1;

  @media screen and (max-width: 600px) {
    width: 47%;
    max-width: 47%;
  }

  &:hover {
    background: #f2f5f7;
  }

  &:active {
    background: #f2f5f7;
  }

  &:focus {
    background: #f2f5f7;
  }
`;

export const Cancel = styled.button`
  background: #f2f5f7;
  display: flex;
  border-radius: 8px;
  color: #435969;
  width: 33%;
  max-width: 130px;
  border: none;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  margin-right: 20px;
  order: 1;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0px;
    max-width: 100%;
  }

  &:hover {
    background: #f2f5f7;
  }

  &:active {
    background: #f2f5f7;
  }

  &:focus {
    background: #f2f5f7;
  }
`;

export const ModifyOffer = styled.button`
  background: #435969;
  display: flex;
  border-radius: 8px;
  color: #ffffff;
  width: 33.33%;
  max-width: 130px;
  border: none;
  order: 2;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  /* padding: 10px 22px; */

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
    max-width: 100%;
  }

  &:hover {
    background: #435969;
  }

  &:active {
    background: #435969;
  }

  &:focus {
    background: #435969;
  }
`;

export const CounterOffer = styled.button`
  background: #435969;
  display: flex;
  border-radius: 8px;
  color: #ffffff;
  width: 33.33%;
  max-width: 130px;
  border: none;
  order: 2;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  /* padding: 10px 22px; */

  @media screen and (max-width: 600px) {
    width: 47%;
    max-width: 47%;
  }

  &:hover {
    background: #435969;
  }

  &:active {
    background: #435969;
  }

  &:focus {
    background: #435969;
  }
`;

export const PayModal = styled(Modal)`
  width: 420px !important;
  max-width: 420px;
  border-radius: 20px;
  .ant-modal-body {
    padding: 0px 20px;
  }
  .ant-modal-header {
    border-radius: 20px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-close {
    top: 24px;
    right: -16px;
  }

  @media screen and (max-width: 600px) {
    max-width: 335px;
  }
`;

export const CardProcessing = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  border-top: 1px solid #d3dce1;
  padding: 20px 50px;
`;

export const CardProcessingCheckbox = styled(Checkbox)`
  background-color: #ffffff;
  display: flex;
  width: auto;
  height: auto;
`;

export const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f5f7;
  width: 100%;
  border-top: 1px solid #d3dce1;
  border-bottom: 1px solid #d3dce1;
  padding: 20px 30px;
  border-radius: 20px;

  @media screen and (max-width: 767.98px) {
    padding: 20px 20px;
  }
`;

export const CardProcessingRadio = styled(Radio)`
  background-color: #f2f5f7;
  display: flex;
  width: auto;
  height: auto;

  @media screen and (max-width: 600px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
`;

export const PaymentModalFooter = styled.div`
  display: flex;
  padding: 20px 50px;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 992px) {
    padding: 20px;
  }
`;

export const ConfirmAndPay = styled.button`
  background: linear-gradient(89.93deg, #895cf8 -2.49%, #1f27a7 142.33%);
  display: block;
  border-radius: 8px;
  color: #ffffff;
  width: 100%;
  max-width: 310px;
  border: none;
  justify-content: center;
  align-items: center;
  min-height: 40px;

  @media screen and (max-width: 1060px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }

  &:hover {
    background: #31bf83;
  }

  &:active {
    background: #31bf83;
  }

  &:focus {
    background: #31bf83;
  }
`;

export const PriceSummary = styled.div`
  font-weight: 500;
  display: flex;
  font-size: 18px;
  line-height: 25px;
  color: #042237;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 26px;
  }
`;

export const Price = styled.div`
  font-weight: 800;
  display: flex;
  font-size: 18px;
  line-height: 25px;
  color: #042237;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const CardText = styled.div`
  font-weight: 500;
  display: flex;
  font-size: 14px;
  line-height: 19px;
  color: #435969;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 19px;
  }
`;

export const PaymentMethodHeading = styled.div`
  font-weight: 700;
  display: flex;
  font-size: 18px;
  line-height: 25px;
  color: #042237;
  margin-bottom: 16px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 19px;
  }
`;

export const SportsList = styled.ul`
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px 0;

  margin-top: 0px;
  margin-bottom: 15px;
  li {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #435969;
    position: relative;
    @media screen and (max-width: 767.98px) {
      font-size: 14px;
      line-height: 23px;
    }
  }

  @media screen and (max-width: 767.98px) {
    font-size: 14px;
    line-height: 23px;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  li + li:before {
    content: '';
    border-left: solid 1px #435969;
    font-size: 30px;
    height: 20px;
    align-items: center;
    display: flex;
    margin: 0 10px;
    opacity: 0.4;
  }
`;

export const BackSection = styled.span`
  color: #7741fe;
  font-weight: 600;
  cursor: pointer;

  @media screen and (max-width: 767.98px) {
    margin-left: ${({mrLeft}) => mrLeft}px;
  }
`;

export const PurchaseDetailValue = styled.div`
  border-top: 1px solid #d3dce1;
  padding: 16px 0px;
`;

export const SelectDropDown = styled.button`
  /* width: 15em !important; */
  /* height: 100% !important; */

  display: block;
  -webkit-appearance: none;
  appearance: none;
  // border: 1px solid #d3dce1;
  box-sizing: border-box;
  padding: 2px;
  border-radius: 16px !important;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  line-height: 22px;
  color: #435969;
  opacity: 0.7;
  margin-right: 16px;
  white-space: nowrap;
  border: solid 1px #d3dce1;
  .anticon.anticon-down {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS42MjUgMS4zNzVMNiA3TDAuMzc1IDEuMzc1IiBzdHJva2U9IiM0MzU5NjkiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
    display: inline-block;
    width: 12px;
    background-repeat: no-repeat;
    height: 9px;
    background-position: center;
    svg {
      display: none;
    }
  }
  &.active {
    background: linear-gradient(248deg, #589cf9, #8df49b);
    transition: 1s ease;
    border-radius: 16px !important;
    height: auto;
    font-weight: 600;
    opacity: 1;
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(
        180deg,
        rgba(141, 244, 155, 0.1) 0%,
        rgba(88, 156, 249, 0.1) 100%
      );
      width: 100%;
    }
    & > div {
      background: #fff;
      color: #042237;
    }
  }
  & > div {
    overflow: hidden;
    background-color: #fff;
    height: 100%;
    border-radius: 13px;
    padding: 15px 16px;
  }
`;

export const DropDownMenu = styled(Checkbox.Group)`
  border: 1px solid #f3f3f3 !important;
  box-sizing: border-box;
  box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
`;

export const QuestionSpan = styled.span`
  position: absolute;
  top: 0;
  right: -35px;
  width: 35px;
  height: 35px;
`;
