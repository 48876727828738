import React, {useMemo, useState} from 'react';
import {Input, Select} from 'antd';
import {shippingData} from './../../modules/Athlete/components/features/CreateListing/MockData';
import {Tooltip} from 'antd';

const ShippingBox = (props) => {
  const {
    setFieldValue,
    shipping_detail,
    uspsBoxType,
    values,
    errors,
    touched,
    countries: countriesData,
    getStateList,
    stateList,
    template=false
  } = props;
  const [package_type, setPackageType] = useState(
    shipping_detail?.package_type,
  );
  const {Option} = Select;

  const uspsBoxTypeArray = useMemo(() => {
    if(!uspsBoxType) return [];
    let data = uspsBoxType.data ? uspsBoxType.data : uspsBoxType;

    return Object.keys(data).map((key) => {
      return data[key];
    });
  }, [uspsBoxType]);

  const packageFilterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const countries = useMemo(() => {
    if(!countriesData) return [];

    return !countriesData.data ? countriesData : countriesData.data;
  }, [countriesData]);

  return (
    <div className="overview">
      {values !== undefined && (
        <>
          <h3 className="title mt-4">Details for shipping cost estimation</h3>
          <div className="bg-light shipping-cost-holder">
            <div className="color-muted fw-bold mb-2">Package type</div>
            <div className="row mx-n2 mb-3">
              {shippingData.map((data, index) => {
                return (
                  <div
                    className={`col cursor-pointer text-center px-2 ${
                      package_type === data.key ? 'active-card' : ''
                    }`}
                    key={data.key}
                    onClick={() => {
                      if(data.key !== 'USPS Flat Rate Packaging') {
                        setFieldValue('usps_box_type', undefined);
                      }

                      setFieldValue('package_type', data.key);
                      setPackageType(data.key);
                    }}>
                    <div className="default-card mb-2 mh-100-px ">
                      <div className="card-bg text-muted d-flex align-items-center">
                        {data.icon}
                      </div>
                    </div>
                    <div className="px-4 lh-m color-dark fw-500">
                      {data.key}
                    </div>
                  </div>
                );
              })}
            </div>
            {package_type !== 'USPS Flat Rate Packaging' ? (
              <>
                <div className="color-muted fw-bold mb-2">
                  Dimensions <span className="text-danger">*</span>
                </div>
                <div className="row mx-n2">
                  <div className="col-12 col-md-4 mb-4 px-2">
                    <Input
                      addonBefore="L (in)"
                      type="number"
                      name="dimensions.length"
                      className="input-custom-var2"
                      value={values.dimensions.length}
                      onChange={(e) =>
                        setFieldValue('dimensions.length', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-4 px-2">
                    <Input
                      type="number"
                      addonBefore="W (in)"
                      name="dimensions.width"
                      className="input-custom-var2"
                      value={values.dimensions.width}
                      onChange={(e) =>
                        setFieldValue('dimensions.width', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-4 px-2">
                    <Input
                      type="number"
                      addonBefore="H (in)"
                      name="dimensions.height"
                      className="input-custom-var2"
                      value={values.dimensions.height}
                      onChange={(e) =>
                        setFieldValue('dimensions.height', e.target.value)
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="mb-4">
                <div className="color-muted fw-bold mb-2">
                  USPS Flat Rate Packaging
                  <span className="text-danger">*</span>
                </div>
                <Select
                  showSearch={false}
                  
                  className="custom-select "
                  placement="bottomLeft"
                  placeholder="Select a box type"
                  optionFilterProp="children"
                  onChange={(value) => setFieldValue('usps_box_type', value)}
                  filterOption={packageFilterOption}
                  value={values.usps_box_type}
                  options={uspsBoxTypeArray.map((option) => ({ value: option.type, label: `${option.name} ${option.dimensions}` })) }
                />
                
                {/* <Select
                  className="custom-select"
                  name="usps_box_type"
                  value={values.usps_box_type}
                  onChange={(value) => setFieldValue('usps_box_type', value)}>
                  {uspsBoxTypeArray.map((option) => (
                    <Select.Option value={option.type} key={option.type}>
                      {option.name} {option.dimensions}
                    </Select.Option>
                  ))}
                </Select> */}
              </div>
            )}

            <div className="row  mb-3">
              <div className="col-md-6 mb-3">
                <div className="color-muted fw-bold mb-2">
                  Package Weight
                  <span className="text-danger">*</span>
                </div>
                <div className="d-flex custom-input-var3">
                  <Input
                    type="number"
                    name="weight"
                    placeholder="0"
                    className="input-custom-var2 right-border"
                    value={values.weight}
                    onChange={(e) => setFieldValue('weight', e.target.value)}
                  />
                  <Select
                    className="custom-select"
                    name="weight_units"
                    value={values.weight_units}
                    onChange={(value) => setFieldValue('weight_units', value)}>
                    <Option value="lb">LB</Option>
                    <Option value="oz">OZ</Option>
                  </Select>
                </div>
              </div>

              <div className="col-12 mt-4 px-2 mb-3">
                <h6 className="fw-bold d-flex align-items-center">
                  Your ship-from address
                  <Tooltip
                    placement="bottom"
                    title="Your main address where you ship packages from. This is required for accurate shipping cost calculations and so the package has a Return Address in case an item is undeliverable.">
                    <span className="price-img-shadow ms-2">?</span>
                  </Tooltip>
                </h6>
              </div>
              <div className="col-md-12 mb-3">
                <div className="color-muted fw-bold mb-2">
                  Country
                  <span className="text-danger"> *</span>
                </div>
                <Select
                  className="custom-select custom-select-light size-l "
                  name="country"
                  value={values.from_address.country}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    setFieldValue('from_address.country', value);
                    getStateList(value);
                  }}
                  options={countries.map((option) => ({ value: option.isoCode, label: option.name }) )}
                  status={(!values.from_address.country && template) || (errors.country) ? 'error' : ''}
                />
      
                <div className="text-danger text-sm fw-500">
                  {touched.country && errors.country && errors.country}
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="color-muted fw-bold mb-2">
                  Street / Address line 1<span className="text-danger"> *</span>
                </div>
                <input
                  className="input-custom-var2 form-control"
                  name="from_address.street1"
                  id="street1"
                  value={values.from_address.street1}
                  onChange={(e) => {
                    setFieldValue('from_address.street1', e.target.value);
                  }}
                />
                <div className="text-danger text-sm fw-500">
                  {touched.state && errors.state && errors.state}
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="color-muted fw-bold mb-2">Address line 2</div>
                <input
                  className="input-custom-var2 form-control"
                  name="from_address.street2"
                  id="street2"
                  value={values.from_address.street2}
                  onChange={(e) => {
                    setFieldValue('from_address.street2', e.target.value);
                  }}
                />
                <div className="text-danger text-sm fw-500">
                  {touched.street2 && errors.street2 && errors.street2}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="color-muted fw-bold mb-2">
                  City
                  <span className="text-danger"> *</span>
                </div>
                <input
                  className="input-custom-var2 form-control"
                  name="from_address.city"
                  id="city"
                  value={values.from_address.city}
                  onChange={(e) => {
                    setFieldValue('from_address.city', e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 pe-2 mb-3">
                <div className="color-muted fw-bold mb-2">
                  State
                  <span className="text-danger"> *</span>
                </div>
                {values.from_address.country === 'US' ||
                values.from_address.country === 'CA' ? (
                  <Select
                    className="custom-select custom-select-light size-l "
                    name="from_address.state"
                    optionFilterProp="children"
                    value={values.from_address.state}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      setFieldValue('from_address.state', value);
                    }} 
                    options={stateList.map((option, i) => ({ value: option.state, label: option.state }) )} 
                  />
                ) : (
                  <input
                    className="input-custom-var2 form-control"
                    name="from_address.state"
                    id="state"
                    value={values.from_address.state}
                    onChange={(e) => {
                      setFieldValue('state', e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-6 col-12 ">
                <div className="color-muted fw-bold mb-2">
                  Your Zip Code <span className="text-danger">*</span>
                </div>
                <Input
                  name="from_address.zip"
                  type="text"
                  placeholder="0"
                  // maxLength={5}
                  className="input-custom-var2 form-control"
                  value={values.from_address.zip}
                  onChange={(e) => {
                    setFieldValue('from_address.zip', e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShippingBox;
