import {Table, ConfigProvider, Empty} from 'antd';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import ProfileImage from '../../modules/Purchaser/components/ProfileImage';
import {formatPrice} from '../../helpers/user';
import {backgroundStyle} from '../../utils/staticStrings';
import Loader from 'react-loader-spinner';

const OrderTable = (props) => {
  const {loading, orderData, onChange, current, count, item} = props;
  const history = useHistory();

  const statusType = (value) => {
    if (value === 'completed') {
      return 'status-success ';
    } else if (value === 'active') {
      return 'status-progress';
    } else if (value === 'disputed') {
      return 'disputed';
    } else if (value === 'declined') {
      return 'cancel';
    }
  };
  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'id',
      key: 'id',
      width: '120px',
      render: (text) => (
        <span className="fw-700 color-dark font-15">#{text}</span>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <span>{moment(text).format('MMM DD,YYYY')}</span>,
    },
    ...(item !== 'athlete'
      ? [
          {
            title: 'Purchased from',
            dataIndex: 'listing',
            key: 'listing',
            render: (text) => (
              <div className="d-flex align-items-center">
                <ProfileImage
                  width={32}
                  imgWidth={32}
                  desktopImgWidth={32}
                  imgContainerClassName="purchaser radius-m"
                  height={32}
                  src={
                    text?.athlete?.image_url
                      ? text?.athlete?.image_url
                      : backgroundStyle.defaultProfilePic
                  }
                />
                <span className="text-start">{text?.athlete?.name}</span>
              </div>
            ),
          },
        ]
      : [
          {
            title: 'Customer',
            dataIndex: 'user',
            key: 'user',
            render: (text) => {
              return (
                <div className="d-flex align-items-center">
                  <ProfileImage
                    width={32}
                    imgWidth={32}
                    imgContainerClassName="purchaser radius-m"
                    height={32}
                    src={
                      text?.athlete?.image_url
                        ? text?.athlete?.image_url
                        : text?.purchaser?.logo
                        ? text?.purchaser?.logo
                        : text?.image_url
                        ? text?.image_url
                        : backgroundStyle.defaultProfilePic

                      // text?.purchaser?.logo
                      //   ? text?.purchaser?.logo
                      //   : backgroundStyle.defaultProfilePic
                    }
                  />
                  <span className="text-start text-capitalize">
                    {text?.purchaser?.company
                      ? text?.purchaser?.company
                      : text?.name}
                  </span>
                </div>
              );
            },
          },
        ]),
    {
      title: 'Item',
      dataIndex: 'title',
      key: 'title',
      className: 'text-nowrap',
      render: (text) => {
        return <div className="text-start">{text}</div>;
      },
    },
    {
      title: 'Amount',
      // dataIndex: 'total_price',
      key: 'total_price',
      render: (record) => {
        let text = item === 'athlete' ? record?.subtotal : record?.total_price;
        return <div className="ms-1">${formatPrice(text)}</div>;
      },
    },
    {
      title: 'Status',
      width: '112px',
      className: 'text-center',
      key: 'status',
      render: (record) => {
        let text = record?.status;

        return (
          <>
            {record?.athlete_marked_complete &&
            item === 'athlete' &&
            record.status === 'active' ? (
              <span className="status-success">Completed</span>
            ) : (
              <span
                className={`${statusType(text)}  ${
                  text === 'active' ? '' : 'text-capitalize'
                }`}>
                {text === 'active'
                  ? 'In progress'
                  : text === 'declined'
                  ? 'Canceled'
                  : text}
              </span>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {count !== 0 ? (
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No orders to display"
            />
          )}>
          <div className="table-responsive">
            <Table
              className="custom-table border-none"
              columns={columns}
              loading={loading}
              dataSource={orderData}
              onChange={onChange}
              // rowKey={orderData.id}
              rowClassName={(record) => {
                if (item === 'purchaser') {
                  return record.purchaser_read !== true ? 'active' : 'inactive';
                } else {
                  return record.athlete_read !== true ? 'active' : 'inactive';
                }
              }}
              rowKey={(record) => record.id}
              onRow={(record) => ({
                onClick: () => {
                  if (record.id) {
                    if (item === 'purchaser') {
                      history.push(`dashboard/order/${record.id}`);
                    } else {
                      history.push(`order/details/${record.id}`);
                    }
                  }
                },
              })}
              pagination={{
                pageSize: 12,
                total: count,
                hideOnSinglePage: true,
                showSizeChanger: false,
                current: current,
              }}
            />
          </div>
        </ConfigProvider>
      ) : (
        <>
          {!loading ? (
            <h5 className="fw-700 d-flex font-18 justify-content-center my-4 pt-5">
              No orders to display
            </h5>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              <Loader type="Bars" color="#00BFFF" height={50} width={100} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OrderTable;
