import React from 'react';
import '../features.scss';
import {Link} from 'react-router-dom';
import {maximizeEarnings} from '../../../../../utils/staticStrings';

export default function index() {
  return (
    <div className="maximize-earning-bg">
      <div className="padding-main">
        <div className="maximize-earnings">
          <div className="bglight">
            <div className="row title center mb-4">
              {maximizeEarnings.title}
            </div>
            <div className="row center mw-770 mx-auto">
              <div className=" subtext">{maximizeEarnings.text}</div>
            </div>
            <div className="row center">
              <div className="col num-text">{maximizeEarnings.percentage}</div>
            </div>
            <div className="row title center fees-text mb-3">
              {maximizeEarnings.feestext}
            </div>
            <div className=" center">
              <div className="max-earning-sec mx-auto">
                <div className="row">
                  <div className="col-6 col-xs-4 col-md-6 ">
                    <Link to="athlete/register">
                      <button className="btn clickbtnblue">
                        {maximizeEarnings.btnlabelfirst}
                      </button>
                    </Link>
                  </div>
                  <div className="col-6 col-xs-4 col-md-6 ">
                    <Link to="/pricing">
                      <button className="btn clickbtnwhite">
                        {maximizeEarnings.btnlabelsecond}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
