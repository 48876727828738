import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CampaignCard from './CampaignCard';
import {Subheader} from './style';
import Loader from 'react-loader-spinner';
import {getInvitedCampaigns} from '../../../../../store/athleteflow/action';

const InvitedCampaigns = () => {
  const {invitedCampaigns} = useSelector((state) => state.AthleteModuleReducer);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(
      getInvitedCampaigns({
        page: 1,
        pageSize: 10,
      }),
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [dispatch]);

  return (
    <div className="mb-4">
      {isLoading && (
        <div className="loader-contain">
          <div className="loader">
            <Loader type="Bars" color="#00BFFF" height={50} width={50} />
          </div>
        </div>
      )}
      {!isLoading && invitedCampaigns.length > 0 && (
        <>
          <Subheader>Campaigns That Personally Invited You to Apply</Subheader>
          {invitedCampaigns.map((campaign) => (
            <CampaignCard
              campaignDetails={campaign}
              invited={true}
              key={campaign.id}
            />
          ))}
        </>
      )}
      {!isLoading && invitedCampaigns.length === 0 && <></>}
    </div>
  );
};

export default InvitedCampaigns;
