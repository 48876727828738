import styled from 'styled-components';
import {
  Modal,
  Tabs,
  Select,
  Input,
  Radio,
  AutoComplete,
  Card,
  DatePicker,
} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
const {TextArea} = Input;

export const LayoutDesktopView = styled.div`
  @media screen and (max-width: 649px) {
    display: none;
  }
`;

export const LayoutMobileView = styled.div`
  @media screen and (min-width: 650px) {
    display: none;
  }
`;

export const CampaignContainer = styled.div`
  margin-right: 60px;
  flex-direction: column;
  cursor: pointer;
`;

export const CampaignHeading = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    margin-top: 18px;
  }
`;

export const CampaignDetails = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #042237;
  cursor: pointer;
  @media screen and (max-width: 650px) {
    margin-bottom: 20px;
  }
`;

export const CampaignsCountDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: start;
    margin: 24px 0px;
    .count {
      margin-bottom: 6px;
    }
  }
`;

export const CustomButton = styled.button`
  @media screen and (max-width: 650px) {
    padding: 6px 12px !important;
  }
`;

export const CreateCampaign = styled(Modal)`
  width: 710px !important;
  max-width: 710px;
  border-radius: 20px;
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: 768px) {
      padding-left: 48px;
      padding-right: 48px;
    }
  }
  .ant-modal-content {
    padding: 0px;
    border-radius: 20px;

    /* background: red; */
  }
  .ant-modal-close {
    top: 18px;
    // right: -4px;
  }

  @media screen and (max-width: 767.96px) {
    max-width: 375px;
  }
`;

export const CustomTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    background-color: #042237;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-nav {
    margin: 0px 0px 20px 0px;

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      padding: 5px 0px;
      font-weight: 700;
      color: #435969;
      line-height: 30px;
      width: 50%;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #042237;
    }

    .ant-tabs-tab-btn:active {
      color: #042237;
    }
  }
  .ant-tabs-nav::before {
    border-bottom: 0px !important;
  }
`;

export const CampaignDetailContainer = styled.div`
  display: flex;
  padding: 0px 70px 0px 70px;
  flex-direction: column;
  @media screen and (max-width: 1000px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const CampaignDetail = styled.div`
  display: flex;
  padding: 0px 70px;
  flex-direction: column;
  @media screen and (max-width: 767.98px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const CampaignImageDetail = styled.div`
  justify-content: center;
  margin-left: 20px;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    margin-top: 20px;
    margin-left: 50px;
  }
`;

export const CustomInput = styled(Input)`
  padding: 20px 25px;
  border-radius: 8px;
  border: 1px solid #d9e1e7;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #042237;

  /* & > div {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  } */
`;

export const CustomAmount = styled.div`
  padding: 20px 25px;
  border-radius: 8px;
  border: 1px solid #d9e1e7;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  size: 20px;
  line-height: 27px;
  ${({bgColor}) =>
    bgColor ? `background-color:${bgColor};` : `background-color:white`};

  ${({color}) => (color ? `color:${color};` : `color:#042237`)};

  @media screen and (max-width: 767.98px) {
    margin-bottom: 10px;
    padding: 20px 11px;
  }
`;

export const CustomTextArea = styled(TextArea)`
  border-radius: 8px;
  border: 1px solid #d9e1e7;
  padding: 20px 25px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #042237;
`;

export const DeliverablesContainer = styled.div`
  background: #f2f5f7;
  /* opacity: 0.4; */
  padding: 20px;
  border: 1px solid #d3dce1;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
`;

export const CustomSelectDropDown = styled(Select)`
  width: 100%;
  z-index: 100;

  &.ant-select .ant-select-selector {
    height: 64px;
    border-radius: 8px;
    @media screen and (max-width: 992px) {
      height: 56px;
      padding: 13px;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #042237;
      white-space: break-spaces;

      @media screen and (max-width: 992px) {
        font-size: 15px;
        white-space: nowrap;
        word-break: normal;
        overflow: visible;
        overflow-wrap: break-word;
        //text-overflow: ellipsis;
        display: block;
      }
    }
  }
  .ant-select-arrow {
    border: solid #435969;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 0px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 12px;
    top: 25px;
    right: 15px;


    svg {
      display: none;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0 !important;
  }

  @media screen and (max-width: 767.98px) {
    margin-bottom: 10px;
  }
`;

export const CustomSocialSelectDropDown = styled(Select)`
  width: 100%;
  z-index: 100;

  &.ant-select .ant-select-selector {
    height: 64px;
    border-radius: 0px;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #042237;
    }
  }
  .ant-select-arrow {
    border: solid #435969;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    svg {
      display: none;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0 !important;
  }

  @media screen and (max-width: 767.98px) {
    margin-bottom: 10px;
  }
`;

export const CustomAutoComplete = styled(AutoComplete)`
  width: 100%;
  position: relative;
  .ant-select-selector {
    height: 64px !important;
    border-radius: 8px !important;
    padding-top: 15px !important;
    /* padding-left: 21px !important; */
  }
  .ant-select-selection-search {
    padding-top: 15px !important;
    /* padding-left: 21px !important; */
  }

  .ant-select-selection-placeholder {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    /* color: #042237; */
  }
`;

export const Heading = styled.ul`
  padding-left: 18px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #435969;
  opacity: 0.7;
`;

export const CampaignType = styled(Radio.Group)`
  display: flex;

  /* .ant-radio-button-wrapper:not(:first-child)::before {
    content: none;
  } */
  .ant-radio-button-wrapper {
    height: 64px;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked {
    background-color: #fff !important;
  }

  @media screen and (max-width: 1000px) {
    .ant-radio-button-wrapper {
      height: 164px;
    }
  }
`;

export const CampaignTypeRadioButtonLeft = styled(Radio.Button)`
  background: linear-gradient(
    272.22deg,
    rgba(141, 244, 155, 0.3) 5.5%,
    rgba(88, 156, 249, 0.3) 100.65%
  );
  display: flex;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  flex-direction: column;
  justify-content: center;
  .ant-radio-group-solid .ant-radio-button-wrapper-checked {
    background-color: #fff !important;
  }
`;

export const CampaignTypeRadioButtonRight = styled(Radio.Button)`
  background: linear-gradient(
    272.22deg,
    rgba(141, 244, 155, 0.3) 5.5%,
    rgba(88, 156, 249, 0.3) 100.65%
  );
  display: flex;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  flex-direction: column;
  justify-content: center;
  .ant-radio-group-solid .ant-radio-button-wrapper-checked {
    background-color: #fff !important;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  width: 100%;
  /* flex-grow: 1; */
  flex-wrap: wrap;

  .item {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 767.96px) {
    .item {
      /* max-width: 98px;
      margin-bottom: 20px; */
      max-width: 111px;
      margin-bottom: 20px;
      min-width: 111px;
    }
  }
`;

export const CustomEditButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
`;

export const SendInviteButton = styled.div`
  display: flex;
  max-width: 570px;
  width: 570px;
`;

export const CustomSearchOutlined = styled(SearchOutlined)`
  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    width: 20px;
  }
  color: #435969;
  display: block;
  position: absolute;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  top: 21px;
  left: 11px;
`;

export const CriteriaDetail = styled.div`
  background: #f2f5f766;
  margin: 32px 0px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  border: 1px solid #d3dce1;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const CampaignStatsCard = styled(Card)`
  // max-width: 227px;
  max-height: 125px;
  padding: 24px 24px;
  background: #ffffff;
  box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  margin-bottom: 10px;
  .ant-card-body {
    padding: 0px;
  }

  @media screen and (max-width: 767.98px) {
    // width: 160px;
    height: 131px;
  }
`;

export const FulfillmentDatePicker = styled(DatePicker)`
  width: 100%;
  height: 64px;

  .ant-picker-input > input {
    font-size: 18px;
  }
`;
