import React from 'react';
import {Select} from 'antd';
// import {editStatusListing} from '../../../../../store/athleteflow/action';
import VideoThumbnail from '../../../../../common/components/VideoThumbnail';
import {formatPrice} from '../../../../../helpers/user';
import {Button, Tooltip} from 'antd';

const ShopCard = (props) => {
  const {listData, editListing, stripeStatus, deleteListing, membeShipExpired } =
    props;
  const {Option} = Select;

  return (
    <>
      {listData.map((data, i) => {
        let cardListProduct =
          data?.type === 'Experiences'
            ? data?.listing_experience
            : data?.listing_product;

        let isQuanityLimited = cardListProduct?.is_limited;
        let orderLeft;
        if (isQuanityLimited) {
          orderLeft = cardListProduct?.limited_quantity;
        }
        // toastMessage('hh');

        const handleChange = (status, data) => {
          editListing(data.id, status);
          // dispatch(editStatusListing({id: data.id, status: status}));
        };

        return (
          <div key={data.id}>
            <div className="custom-card mb-3">
              <div className="d-xl-flex d-block">
                <div className="d-flex justify-content-between">
                  <div className="card-block me-4">
                    {data?.attachments[0]?.type === 'video' ? (
                      <VideoThumbnail
                        embedId={data?.attachments[0]?.url}
                        size="lg"
                      />
                    ) : (
                      <img
                        src={data?.attachments[0]?.url}
                        alt="card-img"
                        className="img-fluid radius-l"
                      />
                    )}
                  </div>

                  {(stripeStatus?.subscription_valid === false &&
                    stripeStatus.stripe_account_valid === false) ||
                  membeShipExpired ? (
                    <div className="card-button-group d-xl-none align-items-center d-flex ms-auto">
                      <button
                        className="btn-outline w-150 d-flex"
                        onClick={() => {
                          props.showModal(data);
                        }}>
                        <i className="icon-edit me-2"></i> Edit
                      </button>
                      <button
                        className="btn-outline ms-2 cursor-pointer w-auto h-44"
                        onClick={() => {
                          deleteListing(data.id);
                        }}>
                        <i className="icon-trash"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="card-button-group d-xl-flex justify-content-between d-xl-none">
                      <div className="d-flex">
                        {isQuanityLimited && orderLeft <= 0 ? (
                          <>
                            <Tooltip
                              placement="topLeft"
                              title="Unable to change listing to Active until quantity
                          changed to greater than 0">
                              <Button
                                className="inactive-state opacity-100 mb-3"
                                disabled>
                                Paused
                              </Button>
                            </Tooltip>
                          </>
                        ) : (
                          <Select
                            value={data?.status ? data?.status : 'Select'}
                            onChange={(status) => handleChange(status, data)}
                            // To do - Change color of status bar based on status of listing
                            className={`me-2 ${
                              data?.status === 'active'
                                ? 'dropdown-success'
                                : 'dropdown-danger'
                            }`}>
                            <Option value="active">Active</Option>
                            <Option value="inactive">Paused</Option>
                          </Select>
                        )}

                        <button
                          className="btn-outline ms-2 h-44"
                          onClick={() => {
                            props.showModal(data);
                          }}>
                          <i className="icon-edit"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <h2 className="font-22 mt-3 mt-xl-0">
                    {cardListProduct?.title}
                  </h2>
                  <div className="font-l fw-bold d-block d-xl-none mb-3">
                    ${formatPrice(cardListProduct?.price)}
                  </div>
                  <div className="mb-2">
                    <div className="badge badge-light-purple">
                      {cardListProduct?.category?.name}
                    </div>
                    <div className="badge badge-light-purple">
                      {cardListProduct?.subcategory?.name}
                    </div>
                    {cardListProduct?.is_coaching && (
                      <div className="badge badge-light-purple">
                        Coaching/Lessons
                      </div>
                    )}
                  </div>
                  <p
                    className="font-m mb-0 line-ellipse-2 fw-500"
                    title={cardListProduct?.description}>
                    {cardListProduct?.description}
                  </p>
                  <div className="text-end d-block d-xl-none fw-700 mt-3">
                    {cardListProduct?.is_limited ? (
                      <div
                        className={`fw-bold ${
                          orderLeft <= 5 ? 'text-danger' : ''
                        }`}>
                        Quantity: {orderLeft}
                      </div>
                    ) : (
                      <>Quantity: &infin; </>
                    )}
                  </div>
                </div>
                <div className="font-l fw-bold align-items-center px-0 px-md-5 d-none d-xl-flex ms-auto">
                  ${formatPrice(cardListProduct?.price)}
                </div>

                {(stripeStatus?.subscription_valid === false &&
                  stripeStatus.stripe_account_valid === false) ||
                membeShipExpired ? (
                  <>
                    <div className="card-button-group d-xl-block align-items-center d-none  mw-160">
                      <div className="d-flex">
                        <button
                          className="btn-outline"
                          onClick={() => {
                            props.showModal(data);
                          }}>
                          <i className="icon-edit"></i> Edit
                        </button>
                        <button
                          className="btn-outline ms-2 cursor-pointer w-auto h-44"
                          onClick={() => {
                            deleteListing(data.id);
                          }}>
                          <i className="icon-trash"></i>
                        </button>
                      </div>
                      <div className="text-center fw-700 mt-5">
                        {cardListProduct?.is_limited ? (
                          <div
                            className={`fw-bold ${
                              orderLeft <= 5 ? 'text-danger' : ''
                            }`}>
                            Quantity: {orderLeft}
                          </div>
                        ) : (
                          <> Quantity: &infin; </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="card-button-group d-xl-flex align-items-center d-none  mw-160">
                    <div>
                      {isQuanityLimited && orderLeft <= 0 ? (
                        <>
                          <Tooltip
                            placement="topLeft"
                            title="Unable to change listing to Active until quantity
                        changed to greater than 0">
                            <Button
                              className="inactive-state opacity-100 mb-3"
                              disabled>
                              Paused
                            </Button>
                          </Tooltip>
                        </>
                      ) : (
                        <Select
                          // placeholder={}
                          onChange={(status) => handleChange(status, data)}
                          value={data?.status}
                          // To do - Change color of status bar based on status of listing
                          className={`mb-3 me-0 text-capitalize ${
                            data?.status === 'active'
                              ? 'dropdown-success'
                              : 'dropdown-danger'
                          }`}>
                          <Option value="active">Active</Option>
                          <Option value="inactive">Paused</Option>
                        </Select>
                      )}

                      <button
                        className="btn-outline"
                        onClick={() => {
                          props.showModal(data);
                        }}>
                        <i className="icon-edit"></i> Edit
                      </button>
                      <div className="text-center fw-700 mt-3">
                        {cardListProduct?.is_limited ? (
                          <div
                            className={`fw-bold ${
                              orderLeft <= 5 ? 'text-danger' : ''
                            }`}>
                            Quantity: {orderLeft}
                          </div>
                        ) : (
                          <> Quantity: &infin; </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ShopCard;
