import React, {useCallback, useState, useEffect} from 'react';
import {Upload, Input} from 'antd';
import {UploadAssets} from '../../hooks/UploadImage';
import {
  errorToastMessage,
  toastMessage,
} from '../../helpers/toastMessage';
import ImgCrop from 'antd-img-crop';
import Loader from 'react-loader-spinner';

const Gallery = (props) => {
  const {
    media,
    onChange,
    onDelete,
    onEmbedCode = null,
    embedCode
    //setUrlValid,
    // template = false
  } = props;
  const [loadimgImageStatus, setLoadingImageStatus] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [videoUrlText, setVideoText] = useState('');

  const handleUpload = useCallback((data) => onChange([...media, data]), [onChange, media]);

  useEffect(() => {
    if (embedCode) {
      let youTubeEmbed = `https://www.youtube.com/watch?v=${embedCode}`;

      setVideoText(youTubeEmbed);
    }
  }, [embedCode, onEmbedCode]);

  const validateUrl = (url) => {
    if (url !== '') {
      const regExp =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      const match = url.match(regExp);
      // console.log(match)
      if (match) {
        // setUrlValid(true);
        setValidationMessage('');
        onEmbedCode(match[1]);
      } else {
        // setUrlValid(false);
        setValidationMessage('Invalid url');
        onEmbedCode();
      }
    } else {
      onEmbedCode();
      setValidationMessage('');
    }
  };

  const uploadFile = (fileObj) => {
    UploadAssets(fileObj, handleUpload, setLoadingImageStatus)();
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toastMessage('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 12;
    if (!isLt2M) {
      errorToastMessage('Image must smaller than 12MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <div className="overview mx-auto">
      <div className="gallery-upload mb-4">
        <div className="list-array">
          {loadimgImageStatus && (
            <div className="loader zIndex5">
              <Loader type="Bars" color="#00BFFF" height={50} width={100} />
            </div>
          )}
          <div className="d-flex gallery-list mx-0">
            {[...Array(6)].map((key, i) => (
              <div className="gallery-card mx-1 empty" key={i + `i`}></div>
            ))}
          </div>
          <div className="d-flex gallery-list mx-0 img-list">
            {media.map((data, key) => (
              <div className="gallery-card mx-1" key={key}>
                <span
                  onClick={(e) => onDelete(data)}
                  className="text-danger fw-bold delete-icon"></span>{' '}
                <img src={data} alt="Gallery " />
              </div>
            ))}
          </div>
        </div>
        <ImgCrop rotatationSlider>
          <Upload
            listType="none"
            beforeUpload={beforeUpload}
            customRequest={(fileList) => uploadFile(fileList)}
            className="custom-upload"
            showUploadList={false}>
            <div className="badge-light-purple px-3 py-2 text-purple font-s fw-800">
              + UPLOAD FILES
            </div>
          </Upload>
        </ImgCrop>
      </div>
      <div>
        <h5 className="text-dark title mb-0">Video URL</h5>
        <p className="text-muted mb-2">
          Upload your supporting video to Youtube and enter the Youtube URL
        </p>
        <Input
          className="input-custom-var2 form-control"
          onChange={(e) => {
            validateUrl(e.target.value);
            setVideoText(e.target.value);
          }}
          value={videoUrlText}
        />
        <div className="text-danger fw-700 mt-2">{validationMessage}</div>
      </div>
    </div>
  );
};

export default Gallery;
