import styled from 'styled-components';

export const EditProfileButton = styled.button`
  background: #F2F5F7;
  border-radius: 8px;
  font-weight: 800;
  font-size: 17px;
  color: #435969;
  margin-top: 30px;
  border: none;
  display: flex;
  align-items: center;
  padding: 18px 40px;
  justify-content: center;

  @media screen and (max-width: 992px) {
    margin-bottom: 30px;
  }
`;