import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchProfileData} from '../../../../../store/users/actions';
import {useLocation} from 'react-router-dom';
import {setHeader} from '../../../../../store/athleteflow/action';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';
import BrandDetails from './BrandDetails';
import PurchaserAccountDetails from './PurchaserAccountDetails';

const PurchaserProfile = () => {
  const dispatch = useDispatch();
  const {message, profileData} = useSelector((state) => state.UsersReducer);
  const search = useLocation().search;
  let encryptedKey = new URLSearchParams(search).get('encryptedKey');

  // For testing
  // profileData.role = 'brand';

  useEffect(() => {
    dispatch(setHeader(false));
  }, [dispatch]);

  useEffect(() => {
    if (message?.text?.success === true) {
      toastMessage(message.text.data);
    }
    if (message?.text?.success === false) {
      errorToastMessage(message.text.message);
    }
  }, [message]);

  useEffect(() => {
    if (profileData?.name === undefined) {
      dispatch(fetchProfileData(encryptedKey));
    }
  }, [profileData?.name, dispatch, encryptedKey]);

  return (
    <>
      {profileData?.role === 'brand' && (
        <BrandDetails profileData={profileData} mode="create" tabKey="-1" />
      )}
      {profileData?.role === 'fan' && (
        <PurchaserAccountDetails
          profileData={profileData}
          mode="create"
          tabKey="-1"
        />
      )}
      {/* {(profileData?.role === undefined || profileData?.role === null )&&
        <div className="profile-main">
          <div className="profile-text mb-2">
          Sorry for the inconvenience! Could not fetch data
          </div>
        </div>
      } */}
    </>
  );
};

export default PurchaserProfile;
