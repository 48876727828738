import React from "react";
import Text from "../../../../../components/Text/Text";
import { CampaignDetailContainer } from "../../../../Purchaser/components/features/Campaigns/style";
import { CheckoutButton } from "../../../../Purchaser/pages/style";


const ConfirmAthDeclineDeal = ({declineDeal}) => {
  return (
    <CampaignDetailContainer className="my-3">
      <Text
        display="flex"
        weight="500"
        size="18"
        lineHeight="30"
        mrBottom="6"
        className="form-label "
        textColor="#042237">
         Please confirm that you would like to cancel this deal.
      </Text>
      <CheckoutButton onClick={declineDeal} className="mb-20-mob">
        <Text weight="800" size="18" lineHeight="23" textColor="#FFFFFF" cursor="pointer">
          Cancel deal
        </Text>
      </CheckoutButton>
    </CampaignDetailContainer>
  );
}

export default ConfirmAthDeclineDeal;