import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {
  applyToCampaign,
  getAthleteCampaignData,
} from '../../../../../store/athleteflow/action';
import {BackArrow, Clock, Dollar, Tag} from '../../Icons';
import {
  CampaignDetailsCard,
  BackSection,
  ActionButton,
  ActionButtonDisabled,
} from '../../style';
import {Lock, OpenCampaign} from './CampaignIcons';
import {ApplyCampaign, CamViewCriteria, CompanyCard} from './style';
import {CloseOutlined} from '@ant-design/icons';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import useApi from '../../../../../hooks/useApi';
import ApplyToCampaign from './ApplyToCampaign';
import {toastMessage} from '../../../../../helpers/toastMessage';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import {formatPriceCommas} from '../../../../../helpers/user';

const CampaignView = () => {
  const history = useHistory();
  const {athleteCampaignData, isLoading, applyCampaignStatus} = useSelector(
    (state) => state.AthleteModuleReducer,
  );
  const {athleteDetails} = useSelector((state) => state.UsersReducer);
  const dispatch = useDispatch();
  const {id} = useParams();
  const [createdDate, setCreatedDate] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');
  const [industryType, setIndustryType] = useState('');
  const [applied, setApplied] = useState(false);
  const [isApplyModalVisible, setIsApplyModalVisible] = useState(false);
  const [industries] = useApi('/athlete/industries');
  const [states] = useApi('/athlete/states');
  const [collegeDetail] = useApi('/athlete/colleges');

  useEffect(() => {
    dispatch(
      getAthleteCampaignData({
        id: id,
      }),
    );
  }, [id, dispatch]);

  useEffect(() => {
    if (
      industries &&
      athleteCampaignData &&
      industries?.data?.rows.length > 0 &&
      athleteCampaignData?.user?.purchaser
    ) {
      const industryId = athleteCampaignData?.user?.purchaser?.industry_id;
      let industryData = industries.data.rows;
      for (let i = 0; i < industryData.length; i++) {
        if (industryId === industryData[i].id) {
          setIndustryType(industryData[i].name);
          break;
        }
      }
    }
  }, [athleteCampaignData, industries]);

  useEffect(() => {
    let createdAtDate = moment(athleteCampaignData?.createdAt).format(
      'MMM DD, YYYY',
    );
    setCreatedDate(createdAtDate);
    var updated_date = moment(athleteCampaignData?.updatedAt).format(
      'MMM DD, YYYY',
    );
    setUpdatedDate(updated_date);
    setApplied(false);
    if (athleteCampaignData && athleteCampaignData?.deals) {
      const deals = athleteCampaignData?.deals;
      for (let i = 0; i < deals.length; i++) {
        if (deals[i].user_id === athleteDetails.id) {
          setApplied(true);
        }
      }
    }
  }, [athleteCampaignData, athleteDetails?.id]);

  useEffect(() => {
    if (applyCampaignStatus === true) {
      toastMessage('Applied to campaign successfully');
      dispatch(
        getAthleteCampaignData({
          id: id,
        }),
      );
    }
  }, [applyCampaignStatus, dispatch, id]);

  const applytoCampaign = (values) => {
    closeModal();
    dispatch(applyToCampaign(values, athleteCampaignData?.id));
  };

  const deliverOn = (deliverable) => {
    const fulFillType = deliverable.fulfillment_type;
    if (fulFillType === 'Before Date') {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD, YYYY',
      );
      return `Deliver before: ${fulFillDate}`;
    } else if (fulFillType === 'TBD') {
      return 'TBD';
    } else if (fulFillType === 'Specific Date') {
      let fulFillDate = moment(deliverable.fulfillment_date).format(
        'MMM DD YYYY',
      );
      return `Deliver on: ${fulFillDate}`;
    } else if (fulFillType === 'Within X Days after product received') {
      return `Within ${deliverable.fulfillment_days} days after product received`;
    }
  };

  const getSports = (campaignData) => {
    let allowedSports = campaignData.allowed_sports;
    let sportsList = '';
    allowedSports.forEach((sportObj, idx) => {
      if (idx !== allowedSports.length - 1) {
        sportsList = sportsList + ' ' + sportObj?.name + ' ,';
      } else sportsList = sportsList + ' ' + sportObj.name;
    });
    return sportsList;
  };

  const showLocations = (locations) => {
    if (locations.length === 0) {
      return 'Anywhere located';
    } else {
      let locationList = [];
      if (states && states?.data && states?.data.length > 0) {
        for (let i = 0; i < states.data.length; i++) {
          for (let j = 0; j < locations.length; j++) {
            if (locations[j] === states.data[i].key) {
              locationList.push(states.data[i].name + ', ');
            }
          }
          if (locations.length === locationList.length) {
            let lastName = locationList[locationList.length - 1];
            locationList[locationList.length - 1] = lastName.replace(',', '');
            break;
          }
        }
        return locationList;
      }
    }
  };

  const showSocialReach = (social_reach) => {
    return (
      <>
        {social_reach?.total && (
          <>
            <span className="me-2 titlecase">
              Total SM reach: &nbsp;
              {social_reach?.total.map((val, idx) => {
                if (idx !== social_reach.total.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
        {social_reach?.instagram && (
          <>
            <span className="me-2 titlecase">
              Instagram reach: &nbsp;
              {social_reach?.instagram.map((val, idx) => {
                if (idx !== social_reach.instagram.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
        {social_reach?.twitter && (
          <>
            <span className="me-2 titlecase">
              Twitter reach: &nbsp;
              {social_reach?.twitter.map((val, idx) => {
                if (idx !== social_reach.twitter.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
        {social_reach?.tiktok && (
          <>
            <span className="me-2 titlecase">
              Tiktok reach: &nbsp;
              {social_reach?.tiktok.map((val, idx) => {
                if (idx !== social_reach.tiktok.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
        {social_reach?.youtube && (
          <>
            <span className="me-2 titlecase">
              Youtube reach: &nbsp;
              {social_reach?.youtube.map((val, idx) => {
                if (idx !== social_reach.youtube.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
        {social_reach?.facebook && (
          <>
            <span className="me-2 titlecase">
              Facebook reach: &nbsp;
              {social_reach?.tiktok.map((val, idx) => {
                if (idx !== social_reach.facebook.length - 1) {
                  return val.min + '-' + val.max + ' , ';
                } else return val.min + '-' + val.max;
              })}
            </span>
            <span className="me-2">|</span>
          </>
        )}
      </>
    );
  };

  const showColleges = (colleges) => {
    let collegesList = '';
    if (
      collegeDetail &&
      collegeDetail?.data &&
      collegeDetail?.data?.rows &&
      collegeDetail?.data?.rows.length > 0
    ) {
      for (let i = 0; i < collegeDetail?.data?.rows.length; i++) {
        for (let j = 0; j < colleges.length; j++) {
          if (colleges[j].id === collegeDetail?.data?.rows[i].id) {
            collegesList =
              collegesList === ''
                ? collegeDetail?.data?.rows[i]?.name
                : collegesList + ', ' + collegeDetail?.data?.rows[i]?.name;
          }
        }
      }
      return collegesList;
    }
  };

  const showCriteria = (campaignData) => {
    if (
      campaignData?.criteria &&
      Object.keys(campaignData?.criteria).length > 0
    ) {
      let criteria = campaignData.criteria;
      return (
        <>
          <span>
            <span className="me-2 titlecase">
              {criteria?.athlete_type
                ? `${criteria.athlete_type} athletes`
                : 'Any athlete type'}
            </span>
            <span className="me-2">|</span>
            <span className="me-2 titlecase">
              {criteria?.sports &&
              campaignData?.allowed_sports &&
              campaignData?.allowed_sports?.length > 0
                ? getSports(campaignData)
                : 'Any sports'}
            </span>
            <span className="me-2">|</span>
            <span className="me-2 titlecase">
              {criteria?.gender ? `${criteria.gender}` : 'Any gender'}
            </span>
            <span className="me-2">|</span>
            <span className="me-2 titlecase">
              {criteria?.locations
                ? showLocations(criteria?.locations)
                : 'Anywhere located'}
            </span>
            <span className="me-2">|</span>
            <span className="me-2 titlecase">
              {campaignData?.allowed_colleges
                ? showColleges(campaignData?.allowed_colleges)
                : 'Any university'}
            </span>
            <span className="me-2">|</span>
            <span className="me-2 titlecase">
              {criteria?.social_reach
                ? showSocialReach(criteria?.social_reach)
                : 'Any SM reach'}
            </span>
            {!criteria?.social_reach && <span className="me-2">|</span>}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span>
            <span className="me-2 titlecase">Any athlete type</span>
            <span className="me-2">|</span>
            <span className="me-2 titlecase">Any sports</span>
            <span className="me-2">|</span>
            <span className="me-2 titlecase">Any gender</span>
            <span className="me-2">|</span>
            <span className="me-2 titlecase">Anywhere located</span>
            <span className="me-2">|</span>
            <span className="me-2 titlecase">Any SM reach</span>
            <span className="me-2">|</span>
          </span>
        </>
      );
    }
  };

  const openUrl = (urlValue) => {
    if (urlValue !== null) {
      if (urlValue.includes('http:/') || urlValue.includes('https:/')) {
        window.open(urlValue, '_blank');
      } else {
        window.open('https://' + urlValue);
      }
    }
  };

  const openModal = () => {
    setIsApplyModalVisible(true);
  };

  const closeModal = () => {
    setIsApplyModalVisible(false);
  };

  return (
    <>
      {/* Modal to apply to campaign*/}
      <ApplyCampaign
        style={{top: 100}}
        width={1040}
        zIndex={800}
        closeIcon={<CloseOutlined />}
        title={
          <div
            className="d-flex justify-content-center align-items-center text-center my-3"
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '30px',
              color: '#042237',
            }}>
            {`Application to Campaign (#${athleteCampaignData?.id})`}
          </div>
        }
        visible={isApplyModalVisible}
        onCancel={closeModal}
        footer={null}>
        <ApplyToCampaign
          campaignData={athleteCampaignData}
          applytoCampaign={applytoCampaign}
        />
      </ApplyCampaign>
      <BackSection
        onClick={() => {
          history.push('/athlete/campaigns/');
        }}>
        <BackArrow />
        <span className="ms-2">Back</span>
      </BackSection>
      {isLoading && (
        <div className="loader">
          <Loader type="Bars" color="#00BFFF" height={50} width={50} />
        </div>
      )}
      {!isLoading && Object.keys(athleteCampaignData)?.length > 0 && (
        <>
          <CampaignDetailsCard>
            <div className="cam-header">Campaign #{id}</div>
            <div className="cam-sub-title">
              <img
                src={athleteCampaignData.image_url}
                alt="Campaign icon"
                width="32"
                height="32"></img>
              <div className="ms-2">
                {athleteCampaignData?.type === 'open' ? (
                  <OpenCampaign />
                ) : (
                  <Lock />
                )}
              </div>
              <div className="d-none d-lg-flex">
                <div className="mx-2 fw-500 titlecase">{`${athleteCampaignData?.type} Campaign by ${athleteCampaignData?.user?.purchaser?.company} (${athleteCampaignData?.user?.name})`}</div>
                | <div className="mx-2">{createdDate}</div>
              </div>
              <div className="d-block d-lg-none mx-2 font-15">
                <span className="fw-500 me-2 titlecase">
                  {`${athleteCampaignData?.type} Campaign by ${athleteCampaignData?.user?.purchaser?.company} (${athleteCampaignData?.user?.name}))`}
                </span>
                |<span className="mx-2">{createdDate}</span>
              </div>
            </div>
            <div className="cam-desc-card">
              <div className="cam-title">
                {athleteCampaignData?.title}
                <div className="float-right d-none d-lg-block">{`Up to $  ${formatPriceCommas(
                  athleteCampaignData?.total_price,
                )}`}</div>
                <div className="d-flex d-lg-none mt-2">{`Up to $  ${formatPriceCommas(
                  athleteCampaignData?.total_price,
                )}`}</div>
              </div>
              <div className="cam-desc">
                <div className="fw-700 font-m">Description</div>
                <div className="mb-2 white-pre-wrap">
                  {athleteCampaignData.description}
                </div>
                <div className="fw-700 mt-4 mb-1">Deliverables</div>
                {athleteCampaignData?.deliverables &&
                  athleteCampaignData?.deliverables.map((deliverable, idx) => (
                    <div className="d-flex cam-deliverable" key={idx}>
                      <div className="me-4 deliverable-item">
                        <span className="me-2">
                          <Tag />
                        </span>
                        {deliverable.type}
                      </div>
                      <div className="me-4 deliverable-item">
                        <span className="me-2">
                          <Clock />
                        </span>
                        {deliverOn(deliverable)}
                      </div>
                      <div className="me-4 deliverable-item">
                        <span className="me-2">
                          <Dollar />
                        </span>
                        {`Cash ($${formatPriceCommas(
                          deliverable?.compensation_cash,
                        )}) +
                         Products ($${formatPriceCommas(
                           deliverable?.compensation_product,
                         )})`}
                      </div>
                    </div>
                  ))}
                <div className="cam-details-footer">
                  Last update: {updatedDate}
                  {applied && (
                    <div className="float-right d-none d-lg-block">
                      <ActionButtonDisabled>Applied</ActionButtonDisabled>
                    </div>
                  )}
                  {!applied && (
                    <div
                      className="float-right d-none d-lg-block cursor-pointer"
                      onClick={openModal}>
                      <ActionButton>Apply</ActionButton>
                    </div>
                  )}
                </div>
                <CamViewCriteria className="d-block d-lg-none">
                  Required Criteria:
                  <div className="criteria">
                    {athleteCampaignData.type === 'private' &&
                      'Private Campaign - only personally invited Athletes are allowed to apply'}
                    {athleteCampaignData.type === 'open' &&
                      showCriteria(athleteCampaignData)}
                  </div>
                </CamViewCriteria>
                <div className="d-block d-lg-none">
                  {applied && (
                    <ActionButtonDisabled>Applied</ActionButtonDisabled>
                  )}
                  {!applied && (
                    <ActionButton onClick={openModal}> Apply </ActionButton>
                  )}
                </div>
              </div>
            </div>
            <CamViewCriteria className="d-none d-lg-block">
              Required Criteria:
              <span className="criteria">
                {athleteCampaignData?.type === 'private' &&
                  'Private Campaign - only personally invited Athletes are allowed to apply'}
                {athleteCampaignData.type === 'open' &&
                  showCriteria(athleteCampaignData)}
              </span>
            </CamViewCriteria>
          </CampaignDetailsCard>
          <CompanyCard className="color-dark">
            <div className="d-flex">
              <img
                src={
                  athleteCampaignData?.user?.purchaser?.logo
                    ? athleteCampaignData?.user?.purchaser?.logo
                    : backgroundStyle?.defaultProfilePic
                }
                alt="Company icon"
                width="50"
                height="50"></img>
              <div className="ms-3">
                <div className="company-name">
                  {athleteCampaignData?.user?.purchaser?.company}
                </div>
                <div className="company-industry color-muted">{`Industry: ${industryType}`}</div>
              </div>
            </div>
            <div className="company-desc">{athleteCampaignData?.user?.bio}</div>
            <div className="company-footer">
              <span
                className="cursor-pointer"
                onClick={() => {
                  openUrl(athleteCampaignData?.user?.purchaser?.website);
                }}>
                {athleteCampaignData?.user?.purchaser?.website}
              </span>
              <span className="color-dark">
                {athleteCampaignData?.user?.social?.urls?.Facebook && (
                  <>
                    <span
                      className="text-decoration-underline cursor-pointer"
                      onClick={() => {
                        openUrl(
                          athleteCampaignData?.user?.social?.urls?.Facebook,
                        );
                      }}>
                      Facebook
                    </span>
                    <span className="mx-2">|</span>
                  </>
                )}
                {athleteCampaignData?.user?.social?.urls?.Instagram && (
                  <>
                    <span
                      className="text-decoration-underline cursor-pointer"
                      onClick={() => {
                        openUrl(
                          athleteCampaignData?.user?.social?.urls?.Instagram,
                        );
                      }}>
                      Instagram
                    </span>
                    <span className="mx-2">|</span>
                  </>
                )}
                {athleteCampaignData?.user?.social?.urls?.Twitter && (
                  <>
                    <span
                      className="text-decoration-underline cursor-pointer"
                      onClick={() => {
                        openUrl(
                          athleteCampaignData?.user?.social?.urls?.Twitter,
                        );
                      }}>
                      Twitter
                    </span>
                    <span className="mx-2">|</span>
                  </>
                )}
                {athleteCampaignData?.user?.social?.urls?.Linkedin && (
                  <span
                    className="text-decoration-underline cursor-pointer"
                    onClick={() => {
                      openUrl(
                        athleteCampaignData?.user?.social?.urls?.Linkedin,
                      );
                    }}>
                    Linkedin
                  </span>
                )}
              </span>
            </div>
          </CompanyCard>
        </>
      )}
    </>
  );
};

export default CampaignView;
