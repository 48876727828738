import React from 'react';
import {PopupWidget} from 'react-calendly';
import {Link} from 'react-router-dom';
import {
  calendlyLink,
  getStartedSection,
} from '../../../../../utils/staticStrings';

export default function index(props) {
  return !props.brand ? (
    <div className="section-get-started text-center">
      <div className="text-center gy-2 title">{getStartedSection.title}</div>
      {/* <div className="text-center texts">
          <div className='col col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-6 col-xxl-6 mx-auto'>
            {getStartedSection.text}
          </div>
        </div > */}
      <Link to="athlete/register">
        <button type="button" className="btn clickbtn">
          TRY FOR FREE
        </button>
      </Link>
    </div>
  ) : (
    <div className="section-get-started text-center">
      <div className="title text-center"> {getStartedSection.title}</div>
      <div className="demo-contain">
        {/* <div className="col"> */}
        <PopupWidget
          url={`https://calendly.com/${calendlyLink}`}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById('root')}
          text="BOOK A DEMO"
          textColor="#FFF"
          color="#7741fe"
        />
        {/* </div > */}
      </div>
    </div>
  );
}
