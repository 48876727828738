import React from "react";
import Hr from "../../../../../components/Hr";
import Text from "../../../../../components/Text/Text";
import { backgroundStyle } from "../../../../../utils/staticStrings";
import { Lock } from "../../../../Athlete/components/features/Campaigns/CampaignIcons";
import { Person } from "../../../helpers/icons";
import ProfileImage from "../../ProfileImage";

const CampaignCard = ({campaignData, isActive, selectCard, screen}) => {
  const computeCashAndProduct = (campaignData) => {
    let deliverables = campaignData.deliverables;
    let total_cash = 0;
    let total_product = 0;
    if(deliverables.length === 1) {
      total_cash = deliverables[0].compensation_cash;
      total_product = deliverables[0].compensation_product;
    }
    else {
      deliverables.forEach(deliverable => {
        total_cash = total_cash + deliverable.compensation_cash;
        total_product = total_product + deliverable.compensation_product;
      });
    }
    return `Cash ($${total_cash}) + Products ($${total_product})`;
  }

  if(screen === 'desktop') {
    return (
      <div
        key={campaignData.id}
        className={`campaign-invite-card mb-3 ${isActive ? 'active': ''}`}
        onClick={selectCard}>
        <div className="bg-white">
          <div className="invite-card-bg d-flex">
            <div className="d-flex w-100">
              
              <ProfileImage
                desktopImgWidth={50}
                width={50}
                height={50}
                maxWidth={50}
                smWidth={34}
                smMaxHeight={34}
                smHeight={34}
                src={campaignData?.image_url}
              />
              <div className=" d-flex flex-column ms-3 w-100">
                <Text
                  display="flex"
                  weight="700"
                  size="24"
                  lineHeight="32"
                  textColor="#042237">
                  {campaignData?.title}
                </Text>
                <div className="d-flex align-items-center">
                  {campaignData?.type === 'private' && 
                  <Lock/> }
                  {campaignData?.type === 'open' && <div className="me-1"><Person/></div>}
                  <Text
                    display="flex"
                    weight="500"
                    size="15"
                    mrLeft="5"
                    lineHeight="20"
                    textCase="capitalize"
                    textColor="#042237">
                    {`${campaignData?.type} campaign`}
                  </Text>
                </div>
                <Hr
                  mrBottom="16px"
                  mrTop="16px"
                  color="#042237"
                  classNames="w-100"
                />
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <div className="d-flex  align-items-center">
                    <ProfileImage
                      desktopImgWidth={20}
                      width={20}
                      height={20}
                      maxWidth={20}
                      src={backgroundStyle?.roundDollar}
                    />
                    <Text
                      display="flex"
                      weight="400"
                      size="16"
                      mrLeft="5"
                      lineHeight="26"
                      textColor="#042237">
                      {computeCashAndProduct(campaignData)}
                    </Text>
                  </div>
  
                  <Text
                    display="flex"
                    weight="800"
                    size="24"
                    mrLeft="5"
                    lineHeight="33"
                    textColor="#042237">
                    Up to $ {campaignData?.total_price}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else if(screen === 'mobile') {
    return (
      <div
        key={campaignData.id}
        className={`campaign-invite-card mb-3 ${isActive ? 'active': ''}`}
        onClick={selectCard}>
        <div className="bg-white">
          <div className="invite-card-bg d-flex">
            <div className="d-flex flex-column">
              <div className="d-flex w-100">
                <ProfileImage
                  mobileImgWidth={34}
                  width={34}
                  height={34}
                  maxWidth={34}
                  smWidth={34}
                  smMaxHeight={34}
                  smHeight={34}
                  src={campaignData?.image_url}
                />
                <Text
                  display="flex"
                  weight="700"
                  size="17"
                  mrLeft="5"
                  lineHeight="20"
                  textColor="#042237">
                  {campaignData?.title}
                </Text>
              </div>
              <div className="d-flex align-items-center mt-2">
                <Lock/>
                <Text
                  display="flex"
                  weight="500"
                  size="13"
                  mrLeft="5"
                  lineHeight="18"
                  textColor="#042237">
                  {campaignData?.type}
                </Text>
              </div>
              <div className="d-flex w-100 align-items-center justify-content-between mt-2">
                <div className="d-flex  align-items-center">
                  <Text
                    display="flex"
                    weight="400"
                    size="13"
                    lineHeight="18"
                    textColor="#042237">
                    {computeCashAndProduct(campaignData)}
                  </Text>
                </div>

                <Text
                  display="flex"
                  weight="800"
                  size="18"
                  mrLeft="5"
                  lineHeight="25"
                  textColor="#042237">
                  ${campaignData?.total_price}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CampaignCard;