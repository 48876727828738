import React, {useRef, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useIntersectionObserver} from '../../../../../hooks/useIntersectionObserver';
import {Table} from 'antd';

export const TableWithInfiniteScroll = ({
  columns,
  item,
  rows,
  count,
  current,
  onChange,
}) => {
  const ref = useRef(null);
  const interSecting = useIntersectionObserver({ref});
  const history = useHistory();

  useEffect(() => {
    if (interSecting) {
      // loadMore()
    }
  }, [interSecting]);

  return (
    <div className="table-responsive-xl">
      <Table
        className="custom-table border-none"
        columns={columns}
        dataSource={rows}
        onChange={onChange}
        rowKey={(record) => record.id}
        rowClassName={(record) => {
          return record.athlete_read !== true ? 'active' : 'inactive';
        }}
        onRow={(record) => ({
          onClick: () => {
            if (record.id) {
              if (item === 'deal') {
                history.push(`/athlete/deals/${record.id}`);
              }
            }
          },
        })}
        pagination={{
          pageSize: 5,
          total: count,
          hideOnSinglePage: true,
          showSizeChanger: false,
          current: current,
        }}></Table>
    </div>
  );
};
