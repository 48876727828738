import {put, call, takeLatest} from 'redux-saga/effects';
import {
  triggerLogin,
  getProfile,
  getAthletList,
  getAthleteDetails,
  setNewPassword,
  getAthletePublicProfile,
} from '../../modules/Athlete/helpers/apiController';
import {
  LOGIN_ACTION,
  FETCH_PROFILE,
  FETCH_ATHLETE,
  GET_ATHLETE_DATA,
  PASSWORD_RESET,
  LOGOUT,
  GET_PURCHASER_DATA,
  GET_TRANSACTIONS,
  FETCH_NEWLY_JOINED_ATHLETE,
  GET_SIDEBAR_COUNT,
} from './actionTypes';
import {
  handleLoginSuccess,
  handleMessage,
  handleProfileData,
  handleAthleteData,
  loader,
  handleAthleteDetail,
  passwordResetStatus,
  clearLocalData,
  setAthleteMoreLists,
  handlePurchaserDetail,
  appendTransactions,
  setTransactions,
  handleNewlyAddedAthletes,
  changeLoggedOutStatus,
  setSideBarCount,
  createPucrhaseProfile,
  JWtError,
  atheleteNotfound,
} from './actions';
import {getPurchaserDetails} from '../../modules/Purchaser/helpers/apiController';
import {triggerGetTransactions, triggerGetNewCount} from './apiController';

// const { push } = useHistory()

function* onLogin({payload: user}) {
  try {
    const response = yield call(triggerLogin, user.payload);
    localStorage.setItem('athletUp-token', response.data.data.token);
    localStorage.setItem('id', response.data.data.id);
    localStorage.setItem('userRole', response.data.data.role);
    localStorage.setItem(
      'athletup-refresh-token',
      response.data.data.refresh_token,
    );
    const role = response?.data?.data?.role;
    const pattern = /^\/purchaser\/profile\/((\d)*)$/;
    if (
      user?.prev &&
      user?.prev !== null &&
      user?.prev !== undefined &&
      (role === 'brand' || role === 'fan') &&
      user?.prev.search(pattern) > -1
    ) {
      localStorage.setItem('prev', user?.prev);
    }
    yield put(handleLoginSuccess(response.data.data));
  } catch (error) {
    yield put(handleMessage(error.response.data));
  }
}

function* onProfileFetch(payload) {
  try {
    const response = yield call(getProfile, payload.payload);
    localStorage.setItem('athletUp-token', response.data.data.token);
    localStorage.setItem('userRole', response.data.data.role);
    localStorage.setItem('id', response.data.data.id);
    localStorage.setItem('name', response.data.data.name);

    yield put(handleProfileData(response.data.data));
    yield put(createPucrhaseProfile(true));
    yield put(JWtError(false));
  } catch (error) {
    yield put(JWtError(true));
    yield put(handleMessage(error));
    yield put(createPucrhaseProfile(true));
    // yield put(handlerForgotPasswordSuccess(false));
  }
}

function* onAthleteProfileFetch(payload) {
  try {
    yield put(loader(true));
    const response = yield call(getAthletList, payload.payload);
    // console.log(response, "athlete profile")
    if (payload.payload.page > 1) {
      yield put(setAthleteMoreLists(response.data.data));
    } else {
      yield put(handleAthleteData(response.data.data));
    }
    yield put(loader(false));
  } catch (error) {
    console.log(error, 'Fetch athlete profile');
    yield put(handleMessage(error));
  }
}

function* onNewlyJoinedAthlete(payload) {
  try {
    yield put(loader(true));
    const response = yield call(getAthletList, payload.payload);
    yield put(handleNewlyAddedAthletes(response.data.data.rows));
    yield put(loader(false));
  } catch (error) {
    console.log(error, 'Fetch newly joined athlete profile');
    yield put(handleMessage(error));
  }
}
function* onGetAthleteDetail(payload) {
  try {
    yield put(loader(true));
    let id = localStorage.getItem('id');
    let response;
    if (id === null) {
      response = yield call(getAthletePublicProfile, payload.payload);
    } else {
      response = yield call(getAthleteDetails, payload.payload);
    }

    yield put(handleAthleteDetail(response.data.data));
    // console.log(response.data, 'athlete profile');
    yield put(loader(false));
    yield put(atheleteNotfound(false));
  } catch (error) {
    console.log(error?.response?.data, 'Athlete Pofile data');
    yield put(handleMessage(error));
    yield put(loader(false));
    yield put(atheleteNotfound(true));
  }
}

function* onGetPurchaserDetail(payload) {
  try {
    yield put(loader(true));
    const response = yield call(getPurchaserDetails, payload);
    yield put(handlePurchaserDetail(response.data.data));
    yield put(loader(false));
  } catch (error) {
    console.log(error, 'Purchaser details data');
    // yield put(handleMessage(error));
    yield put(loader(false));
  }
}

function* onPasswordReset(payload) {
  try {
    const response = yield call(setNewPassword, payload.payload);
    console.log(response, 'Password reset response');
    yield put(passwordResetStatus(true));
    yield put(handleMessage(response.data));
  } catch (error) {
    yield put(passwordResetStatus(false));
    yield put(handleMessage(error));
  }
}

function* onGetTransactions(payload) {
  try {
    yield put(loader(true));
    const response = yield call(triggerGetTransactions, payload.payload);
    if (payload.payload.page > 1) {
      yield put(appendTransactions(response?.data?.data));
    } else {
      yield put(setTransactions(response?.data?.data));
    }
    yield put(loader(false));
  } catch (error) {
    yield put(handleMessage(error));
    yield put(loader(false));
  }
}

function* onFetchSidebarCount(payload) {
  try {
    const response = yield call(triggerGetNewCount, payload.payload);
    yield put(setSideBarCount(response?.data?.data));
  } catch (error) {
    yield put(handleMessage(error));
    // yield put(loader(false));
  }
}

function* onLogOut() {
  localStorage.clear();
  yield put(changeLoggedOutStatus(true));
  yield put(clearLocalData());
}

function* UserModuleSaga() {
  yield takeLatest(LOGIN_ACTION, onLogin);
  yield takeLatest(FETCH_PROFILE, onProfileFetch);
  yield takeLatest(FETCH_ATHLETE, onAthleteProfileFetch);
  yield takeLatest(GET_ATHLETE_DATA, onGetAthleteDetail);
  yield takeLatest(GET_PURCHASER_DATA, onGetPurchaserDetail);
  yield takeLatest(PASSWORD_RESET, onPasswordReset);
  yield takeLatest(GET_TRANSACTIONS, onGetTransactions);
  yield takeLatest(LOGOUT, onLogOut);
  yield takeLatest(FETCH_NEWLY_JOINED_ATHLETE, onNewlyJoinedAthlete);
  yield takeLatest(GET_SIDEBAR_COUNT, onFetchSidebarCount);
}

export default UserModuleSaga;
