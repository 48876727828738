import React from 'react';
import {Checkbox} from 'antd';
import {Formik} from 'formik';
import * as yup from 'yup';

let schema = yup.object().shape({
  fname: yup.string().required('Name can not be empty'),
  lname: yup.string().required('Last name can not be empty'),
});

const AccountDetails = (props) => {
  const {accountData, editProfileSubmit} = props;
  const CheckboxGroup = Checkbox.Group;
  let name = accountData?.name;
  let fname = name.split(' ')[0];
  let lname = name.substring(fname.length).trim();
  let disableCheckbox = false;

  if (
    accountData?.enabled_notifications &&
    accountData?.enabled_notifications.includes('important_information')
  ) {
    disableCheckbox = true;
  }

  return (
    <div className="mw-770 mx-auto my-md-5 my-3">
      <Formik
        initialValues={{
          fname: accountData?.name ? fname : '',
          lname: accountData?.name ? lname : '',
          email: accountData?.email,
          enabled_notifications: accountData?.enabled_notifications
            ? accountData?.enabled_notifications
            : [],
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          let editPayload = {name: `${values.fname} ${values.lname}`};
          if (values.enabled_notifications.length > 0) {
            editPayload = {
              ...editPayload,
              enabled_notifications: values.enabled_notifications,
            };
          }
          editProfileSubmit(editPayload);
          // upadateActiveKey("2")
        }}>
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="text" htmlFor="First name">
                      First name
                    </label>
                    <input
                      className="pro-input mt-1"
                      name="fname"
                      id="fname"
                      value={values.fname}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-sm fw-500">
                      {touched.fname && errors.fname && errors.fname}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="text" htmlFor="First name">
                      Last name
                    </label>
                    <input
                      className="pro-input mt-1"
                      name="lname"
                      id="lname"
                      value={values.lname}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-sm fw-500">
                      {touched.lname && errors.lname && errors.lname}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-4">
                    <label className="text" htmlFor="First name">
                      Email
                    </label>
                    <input
                      className="pro-input mt-1"
                      name="email"
                      id="email"
                      value={values.email}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="text" htmlFor="First name">
                    Notifications
                  </label>
                  <div className="border-bottom mb-3 mt-2"></div>
                  <p className="text-dark fw-500 title">Email me when:</p>

                  <CheckboxGroup
                    className="athlete-checkbox-group"
                    name="enabled_notifications"
                    value={values.enabled_notifications}
                    onChange={(val) =>
                      setFieldValue('enabled_notifications', val)
                    }>
                    <Checkbox
                      value="important_information"
                      disabled={disableCheckbox}
                      className="line-height-normal mb-3">
                      Important information related to order and deal activity,
                      payments and bank transfers, account security, or changes
                      to my AthleteUp account membership
                    </Checkbox>

                    <Checkbox value="new_im">
                      I receive a new chat message related to an order or deal
                    </Checkbox>
                    <Checkbox value="campaign_invite">
                      I receive a new personal invitation from a brand to apply
                      to their campaign
                    </Checkbox>
                    <Checkbox value="campaign_criteria_match">
                      I match the target criteria for a new campaign
                    </Checkbox>
                    <Checkbox value="newsletters">
                      AthleteUp newsletter providing NIL insights + platform
                      tips & tricks (never sent more than 1x per week){' '}
                    </Checkbox>
                  </CheckboxGroup>
                </div>
                <button
                  className="btn btn-primary mw-570 mx-auto mt-5"
                  type="submit">
                  SAVE
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
export default AccountDetails;
