import React, {Fragment, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Text from '../../../../../components/Text/Text';
import ProfileImage from '../../../../Purchaser/components/ProfileImage';
import {
  MyPurchaseCard,
  OrderDetails,
} from '../../../../Purchaser/components/style';
import moment from 'moment';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import Loader from 'react-loader-spinner';
import {getDeals} from '../../../../../store/athleteflow/action';
import {useDispatch, useSelector} from 'react-redux';
import {formatPriceCommas} from '../../../../../helpers/user';

const DealsMob = ({dealsCount, isLoading, status, page, loadMore}) => {
  const {deals} = useSelector((state) => state.AthleteModuleReducer);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDeals({
        page: page,
        pageSize: 5,
        status: status,
      }),
    );
  }, [dispatch, page, status]);

  const statusType = (value) => {
    if (value === 'completed') {
      return 'status-success ';
    } else if (value === 'in progress') {
      return 'status-progress';
    } else if (value === 'declined') {
      return 'cancel';
    } else if (value === 'application/offer') {
      return 'offer';
    } else if (value === 'disputed') {
      return 'disputed';
    }
  };

  const computeCreatedDate = (createdAt) => {
    let createdAtDate = moment(createdAt).format('MMM DD, YYYY');
    return createdAtDate;
  };

  const handleRowClick = (dealId) => {
    history.push('/athlete/deals/' + dealId);
  };

  return (
    <Fragment>
      {deals.map((item) => (
        <div
          key={item?.id}
          className={`order-card ${
            item.athlete_read !== true ? 'active' : 'inactive'
          }`}>
          <MyPurchaseCard
            onClick={() => {
              handleRowClick(item.id);
            }}>
            <OrderDetails className="d-flex w-100 align-items-center">
              <ProfileImage
                mobileImgWidth={48}
                width={48}
                imgWidth={48}
                height={48}
                className=" radius-m"
                src={
                  item?.purchaser?.purchaser?.logo
                    ? item?.purchaser?.purchaser?.logo
                    : item?.purchaser?.image_url
                    ? item?.purchaser?.image_url
                    : backgroundStyle?.defaultProfilePic
                }
              />
              <div className="d-flex flex-column">
                <Text
                  display="flex"
                  weight="700"
                  size="18"
                  mrLeft="20"
                  className="mb-0"
                  lineHeight="21"
                  textColor="#042237">
                  {item?.purchaser?.purchaser?.company
                    ? item?.purchaser?.purchaser?.company
                    : item?.purchaser?.name}
                </Text>

                <div className="d-flex ">
                  <Text
                    display="flex"
                    weight="400"
                    size="14"
                    mrLeft="20"
                    textColor="#435969">
                    #{item?.id}
                    <span className="mx-2">|</span>
                    {computeCreatedDate(item?.createdAt)}
                  </Text>
                </div>
              </div>
            </OrderDetails>
            <Text
              display="flex"
              weight="500"
              size="15"
              className="mt-3 mb-3"
              lineHeight="20"
              textColor="#435969">
              {item?.title}
            </Text>
            <div className="d-flex justify-content-between align-items-center">
              <span className={`${statusType(item?.status)} titlecase`}>
                {item?.status}
              </span>
              <Text
                display="flex"
                weight="700"
                size="18"
                className="d-flex"
                lineHeight="24"
                textColor="#042237">
                {`$${
                  item?.counter_offers && item?.counter_offers.length > 0
                    ? formatPriceCommas(item?.counter_offers[0]?.total_price)
                    : formatPriceCommas(item?.total_price)
                }`}
              </Text>
            </div>
          </MyPurchaseCard>
        </div>
      ))}
      {dealsCount > 5 * page && (
        <div className="row center">
          <div className="col">
            <button
              type="button"
              className="btn clickbtnblue view-more"
              onClick={loadMore}>
              {isLoading && (
                <Loader
                  type="Bars"
                  color="#00BFFF"
                  width={20}
                  className="me-3"
                />
              )}
              view more
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default DealsMob;
