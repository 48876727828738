import styled from 'styled-components';
import {Modal} from 'antd';

export const CustomCampaign = styled(Modal)`
  width: 830px !important;
  max-width: 830px;
  padding-bottom: 0px;

  background: #ffffff;
  border-radius: 20px;

  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-header {
    border-radius: 20px;
    /* padding-left: 48px;
    padding-right: 48px; */
    margin-bottom: 25px;
    padding: 28px 48px;
  }
  .ant-modal-content {
    border-radius: 20px;

    /* background: red; */
  }
  .ant-modal-close {
    top: 27px;
    right: -4px;
  }

  @media screen and (max-width: 767.96px) {
    max-width: 375px;
  }
`;

export const CustomCampaignInviteCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 60px 30px 60px;
  @media screen and (max-width: 767.96px) {
    padding: 25px 20px 0px 20px;
  }
  .campaign-invite-card {
    border-radius: 20px;
    border: solid 1px #d3dce1;
    /* max-width: 570px; */
    text-align: left;
    /* margin: 0 auto; */
    line-height: 24px;
    overflow: hidden;
    padding: 1px;
    font-weight: 500;
    box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07);
    cursor: pointer;
    .bg-white {
      border-radius: 20px;
      overflow: hidden;
      height: 100%;
    }
    .invite-card-bg {
      padding: 28px 24px;
      border-radius: 20px;
      height: 100%;
      display: block;

      @media screen and (max-width: 767.96px) {
        padding: 16px 18px;
      }
    }
    &.active {
      background: linear-gradient(to right, #589cf9, #8df49b);
      transition: 1s ease;
      .invite-card-bg {
        background: linear-gradient(
          265.02deg,
          rgba(141, 244, 155, 0.3) 29.32%,
          rgba(88, 156, 249, 0.3) 93.34%
        );
        // border-image: linear-gradient(242.02deg, #8df49b, #589cf9) 2;
        // background: linear-gradient(
        //   265.02deg,
        //   rgba(141, 244, 155, 0.3) 29.32%,
        //   rgba(88, 156, 249, 0.3) 93.34%
        // );
      }
    }
  }
`;
