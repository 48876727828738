import { useCallback, useMemo } from "react";


const TemplateCard = (props) => {
  const { selection, onClick, listing } = props;

  const handleClick = useCallback(() => {
    onClick(listing);
  }, [onClick, listing]);

  const imageHref = useMemo(() => {
    if(!listing.attachments?.length || !listing.attachments[0]?.type === 'image')
      return '/images/VirtualMeetSmall.png';

    return listing.attachments[0]?.url;
  }, [listing]);

  const data = useMemo(() => {
    return listing.listing_product ?? listing.listing_experience;
  }, [listing]);

  const category = useMemo(() => {
    return listing?.listing_product ? listing.listing_product.category.name : listing.listing_experience.category.name;
  }, [listing]);

  const body = useMemo(() => {

    return (<div className="bg-white" style={{ borderRadius: 10 }}>
      <div className="card-bg d-flex" style={{ borderRadius: 10 }}>
        {/* <img
          src={imageHref}
          alt="Experience logo"
          className="card-img-m d-block d-md-none"
        /> */}
        <img
          src={imageHref}
          alt="Experience logo"
          className="card-img-m d-none d-md-block"
          style={{ height: 70, width: 70, borderRadius: 10 }}
        />

        <img
          src={imageHref}
          alt="Experience logo"
          className="card-img-xs d-xs-block d-sm-none d-md-none d-lg-none d-xl-none"
          style={{ height: 55, width: 55, borderRadius: 10 }}
        />
        <div className="ms-3">
          <h3 className="fw-bold d-xs-block d-sm-none d-md-none d-lg-none d-xl-none" style={{ fontSize: 12, maxHeight: 25, minHeight: 25, wordWrap: "break-word", textOverflow: 'ellipsis' }}>{data.title}</h3>
          <h3 className="fw-bold d-none d-md-block" style={{ fontSize: 16, maxHeight: 50, minHeight: 50, wordWrap: "break-word", textOverflow: 'ellipsis' }}>{data.title}</h3>
          
          <div className="d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
            <div className="badge badge-light-purple fw-bold" style={{ fontSize: 10 }}>
              {category}
            </div>
          </div>

          <div className="d-none d-md-block">
            <div className="badge badge-light-purple" style={{ fontSize: 12 }}>
              {category}
            </div>
          </div>
        </div>
      </div>
    </div>);
  }, [category, data.title, imageHref]);

  return (<div
    style={{ border: 'none', borderRadius: 10 }}
    className={`list-card list-card-small ${
      listing.id === selection?.id ? 'active-off' : ''
    }`}
    onClick={handleClick}>
    <div className="d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
      {body}
    </div>
    <div className="d-none d-md-block mb-3">
      {body}
    </div>
  </div>);
};

export default TemplateCard;