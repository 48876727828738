import React from 'react';
import {useSelector} from 'react-redux';
import MessageContainer from '../../../../../common/Messages';

const Messages = () => {
  const {athleteDetails} = useSelector((state) => state.UsersReducer);

  return (
    <>
      {Object.keys(athleteDetails).length > 0 && (
        <MessageContainer messageInfo={athleteDetails} type="athlete" />
      )}
    </>
  );
};

export default Messages;
