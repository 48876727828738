import React, {useState, useEffect} from 'react';
import CustomModal from '../../../../common/components/CustomModal';
import ChangeBillingPeriod from './ChangeBillingPeriod';
import useApi from '../../../../hooks/useApi';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {
  createStripeAccount,
  createStripeAccountRedirect,
  setLoader,
} from '../../../../store/athleteflow/action';
import Loader from 'react-loader-spinner';
import UpdatePaymentWrapper from './UpdatePayment';
import moment from 'moment';
import {getTransactions} from '../../../../store/users/actions';
import {TableWithInfiniteScroll} from '../../../Athlete/components/features/Deals/TableWithInfiniteScroll';
import {
  currencyFormat,
  formatPrice,
  toTitleCase,
} from '../../../../helpers/user';
import https from '../../../../helpers/http';
import {useHistory} from 'react-router-dom';
import http from '../../../../helpers/http';

const Billing = (props) => {
  const [visible, setVisible] = useState(false);
  const [visibleBillingModal, setBillingModal] = useState(false);
  const [role, setRole] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState({});
  // const [stripeSubscription] = useApi('/stripe/membership');
  const [stripeAccount] = useApi('/stripe/account');
  const [defPM, setDefPM] = useState(null);
  const {createStripeAccountUrl} = useSelector(
    (state) => state.AthleteModuleReducer,
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const {transactions, transactionsCount} = useSelector(
    (state) => state.UsersReducer,
  );
  const [page, setPage] = useState(1);
  let nodDaysLeft;
  if (props.subscription?.data?.subscription?.billing_cycle_anchor) {
    const start = moment();
    const end = moment.unix(
      props.subscription?.data?.subscription?.billing_cycle_anchor,
    );

    nodDaysLeft = end.diff(start, 'days');
  }

  useEffect(() => {
    if (paymentMethods?.data && paymentMethods?.data[0]) {
      setDefPM(paymentMethods?.data[0]);
    }
  }, [paymentMethods]);

  useEffect(() => {
    const userRole = localStorage.getItem('userRole');
    if (userRole === 'brand' || userRole === 'fan') {
      setRole('purchaser');
    } else {
      setRole(userRole);
    }
  }, []);

  useEffect(() => {
    if (role !== '') {
      dispatch(
        getTransactions({
          page: page,
          pageSize: 5,
          role: role,
          is_subscription_related: true,
        }),
      );
    }
  }, [dispatch, role, page]);

  const fetchPaymentMethod = async () => {
    try {
      const res = await https.get('/stripe/payment-methods');
      setPaymentMethods(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let paymentToken = true;
    if (paymentToken) {
      fetchPaymentMethod(); // run it, run it
    }
    return () => {
      paymentToken = false;
    };
  }, []);

  useEffect(() => {
    if (
      createStripeAccountUrl !== 'null' &&
      createStripeAccountUrl !== undefined
    ) {
      console.log('createStripeAccountUrl ', createStripeAccountUrl);
      const redirectUrl = createStripeAccountUrl;
      dispatch(createStripeAccountRedirect('null'));
      window.location.replace(redirectUrl);
    }
  }, [createStripeAccountUrl, dispatch]);

  const showPaymentModal = () => {
    setVisible(true);
  };

  const pageHandler = (pageData) => {
    if (pageData.current) {
      setPage(pageData.current);
    }
  };

  const updated = () => {
    setTimeout(() => {
      setIsLoading(false);
      props.fetchMembership();
    }, 2000);
  };

  const changeBillingModal = () => {
    setBillingModal(true);
  };

  const editAccount = () => {
    setIsLoading(true);
    dispatch(createStripeAccount());
  };

  const gerenerateReciept = (transaction_id) => {
    setLoader(true);
    http.get(`/stripe/invoice-url/${transaction_id}`).then((res) => {
      setLoader(false);
      window.open(res?.data?.data?.invoice_url, '_blank').focus();
    });
  };

  const transColumns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (
        <span className="text-nowrap">
          {moment(text).format('MMM DD, YYYY')}
        </span>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'action',
      key: 'action',
      render: (text, data) => {
        // let type = text === 'Order payment' ? 'Order' : 'Deal';
        // let id = data?.order_id ? data?.order_id : data?.deal_id;
        return (
          <span className="titlecase">
            {/* {type} #{id} */} {text}
          </span>
        );
      },
    },
    {
      title: <span>{' '}</span>,
      key: 'receipt_url',
      render: (data) => {
        return (
          <>
            {data?.receiptUrl !== null && (
              <div
                title={data?.receiptUrl}
                onClick={() => gerenerateReciept(data?.transaction_id)}
                className="text-decoration-underline color-purple hover-inherit d-inline-block text-truncate"
                style={{maxWidth: 150}}>
                Receipt
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'price',
      key: 'price',
      render: (text) => (
        <>
          <span>
            {text === 0 ? '$0.00' : `$${formatPrice(parseFloat(text))}`}
          </span>
        </>
      ),
    },
  ];

  console.log(props.subscription)

  return (
    <>
      {isLoading && (
        <div className="loader">
          <Loader type="Bars" color="#00BFFF" height={50} width={100} />
        </div>
      )}
      {!isLoading && (
        <div className="mw-770 mx-auto my-5">
          <div className="card border radius-m p-4 mb-3">
            <div className="d-md-flex justify-content-between align-items-center">
              <h6 className="font-18 m-0 fw-700">Payment method</h6>
              <div className="d-md-flex align-items-center">
                <div className="font-18 color-muted">
                  {defPM?.id !== null ? (
                    <>
                      {defPM?.card?.brand &&
                        `${toTitleCase(defPM?.card?.brand)} ending in ${
                          defPM?.card?.last4
                        } 
                expiring ${defPM?.card?.exp_month}/${defPM?.card?.exp_year}`}
                    </>
                  ) : (
                    <>No payment method found</>
                  )}
                  {/* Visa ending in 2248 expiring 6/2022 */}
                </div>
                <div
                  className="text-purple fw-500 font-m ms-md-4 cursor-pointer"
                  onClick={showPaymentModal}>
                  Update
                </div>
              </div>
            </div>
          </div>

          {role === 'athlete' &&
            props.accountApiLoad &&
            !props.subscriptionLoad && (
              <>
                <div className="card border radius-m p-4 mb-3">
                  <div className="d-md-flex justify-content-between align-items-center">
                    <h6 className="font-18 m-0 fw-600">Membership plan</h6>
                    {(Object.keys(props.subscription).length > 0 ||
                      props.memberDeactivated) && (
                      <div className="text-md-center">
                        <h6 className="font-m m-0 fw-700">Renewal Date</h6>
                        {Object.keys(props.subscription).length > 0 ? (
                          <div>
                            {moment
                              .unix(
                                props.subscription?.data?.subscription
                                  ?.billing_cycle_anchor,
                              )
                              .format('MMM DD, YYYY')}
                          </div>
                        ) : (
                          <>
                            {props.memberDeactivated && (
                              <h6 className="text-danger mb-0 mt-2">
                                Account Deactivated
                              </h6>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <div className="d-md-flex align-items-center">
                      {Object.keys(props.subscription).length > 0 ? (
                        <>
                          <div className="font-14 color-muted">
                            {props.subscription?.data?.subscription?.items
                              ?.data[0]?.plan?.interval === 'month'
                              ? 'Monthly'
                              : 'Yearly'}
                            (
                            {currencyFormat(
                              props.subscription?.data?.subscription?.items
                                ?.data[0]?.plan?.amount * 0.01,
                            )}
                            /
                            {
                              props.subscription?.data?.subscription?.items
                                ?.data[0]?.plan?.interval
                            }
                            )
                          </div>
                          <div
                            className="text-purple fw-500 font-m ms-md-4 cursor-pointer"
                            onClick={changeBillingModal}>
                            Change
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() =>
                              history.push('/athlete/price-details')
                            }>
                            {props.memberDeactivated
                              ? ' Renew membership'
                              : 'Start free trial'}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {Object.keys(props.subscription).length > 0 && (
                  <div className="card border radius-m p-4 mb-5">
                    <div className="d-md-flex justify-content-between align-items-center">
                      <h6 className="font-18 m-0 fw-700">
                        Free trial / promotional credits
                      </h6>
                      <div className="d-md-flex align-items-center font-14 color-muted">
                        New customer free trial (
                        {moment
                          .unix(props.subscription?.data?.last_trial_end)
                          .diff(
                            moment.unix(
                              props.subscription?.data?.last_trial_start,
                            ),
                            'days',
                          )}
                        &nbsp;days), ending &nbsp;
                        {moment
                          .unix(props.subscription?.data?.last_trial_end)
                          .format('MM/DD/YYYY')}
                      </div>
                    </div>
                  </div>
                )}
                <div className="card border radius-l p-4 mb-5">
                  <div className="d-md-flex justify-content-between align-items-center">
                    <h6 className="font-18 m-0 fw-700">Linked bank account</h6>
                    <div className="d-md-flex align-items-center">
                      {!Array.isArray(stripeAccount) &&
                        Object.keys(stripeAccount).length > 0 && (
                          <div className="font-18 color-muted">
                            *********{stripeAccount?.data[0]?.last4} on{' '}
                            {stripeAccount?.data[0]?.bank_name}
                          </div>
                        )}
                      <div
                        className="text-purple fw-500 font-m ms-md-4 cursor-pointer"
                        onClick={editAccount}>
                        Change
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          <h3 className="title mt-4">Billing History</h3>
          <div className="billing-table">
            <TableWithInfiniteScroll
              item="transaction"
              columns={transColumns}
              rows={transactions}
              // loading={isLoading}
              count={transactionsCount}
              current={page}
              onChange={pageHandler}
            />
          </div>
          {visible && (
            <>
              <CustomModal
                title="Update payment method"
                visible={visible}
                width="710px"
                isLoading={false}
                className=""
                onClickCancel={() => setVisible(false)}>
                <UpdatePaymentWrapper
                  closeEditPM={() => {
                    setVisible(false);
                    fetchPaymentMethod();
                  }}
                />
              </CustomModal>
            </>
          )}
          {visibleBillingModal && (
            <>
              <CustomModal
                title="Make a change to your membership"
                visible={visibleBillingModal}
                width="710px"
                isLoading={false}
                className=""
                onClickCancel={() => setBillingModal(false)}>
                <ChangeBillingPeriod
                  subscriptionPlan={props.subscription?.data?.subscription}
                  updated={updated}
                  setBillingModal={setBillingModal}
                  setIsLoading={setIsLoading}
                  nodDaysLeft={nodDaysLeft}
                  planType={props.plan}
                  setMemberReload={props.setMemberReload}
                  upComingPlan={props.upComingPlan}
                />
              </CustomModal>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default Billing;
