import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  headerSection,
  icons,
  profileSection,
} from '../../../../../utils/staticStrings';
import {setHeader} from '../../../../../store/athleteflow/action';
import * as yup from 'yup';
import {
  toastMessage,
  errorToastMessage,
} from '../../../../../helpers/toastMessage';
import {Formik} from 'formik';
import ImgCrop from 'antd-img-crop';
import {Checkbox, Upload} from 'antd';
import Loader from 'react-loader-spinner';
import {
  purchaserProfileStatus,
  purchaserProfileSubmit,
} from '../../../../../store/brandflow/action';
import {
  fetchPurchaserData,
  logoutData,
} from '../../../../../store/users/actions';
import {UploadAssets} from '../../../../../hooks/UploadImage';

let schema = yup.object().shape({
  f_name: yup.string().required('First name is required'),
  l_name: yup.string().required('Last name is required'),
});

const PurchaserAccountDetails = (props) => {
  let {profileData, tabKey, mode} = props;
  const [loadimgImageStatus, setLoadingImageStatus] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  const {profileSubmitStatus} = useSelector(
    (state) => state.BrandModuleReducer,
  );
  const history = useHistory();
  const CheckboxGroup = Checkbox.Group;

  let fName = '';
  let lName = '';
  if (profileData?.name) {
    let namesArr = profileData?.name.split(' ');
    fName = namesArr[0];
    if (namesArr.length > 1) {
      lName = namesArr[1];
    }
  }

  let initialValues = {
    f_name: fName,
    l_name: lName,
    email: profileData?.email ? profileData?.email : '',
    image_url: profileData?.image_url ? profileData?.image_url : '',
    enabled_notifications: profileData?.enabled_notifications
      ? profileData?.enabled_notifications
      : [],
  };
  let disableCheckbox = false;
  if (
    profileData?.enabled_notifications &&
    profileData?.enabled_notifications.includes('important_information')
  ) {
    disableCheckbox = true;
  }

  useEffect(() => {
    dispatch(setHeader(false));
  }, [dispatch]);

  useEffect(() => {
    const userRole = localStorage.getItem('userRole');
    setRole(userRole);
  }, []);

  useEffect(() => {
    if (profileSubmitStatus === true) {
      if (mode === 'create' && tabKey === '-1') {
        showToastAndResetStatus();
        setIsLoading(true);
        setTimeout(() => {
          history.push('/purchaser/dashboard');
        }, 3000);
      }
      if (mode === 'edit' && tabKey === '2') {
        showToastAndResetStatus();
        dispatch(fetchPurchaserData(profileData.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSubmitStatus, history, mode, dispatch]);

  const submitProfile = (values) => {
    let payload = {
      name:
        values.l_name.trim() !== ''
          ? `${values.f_name.trim()} ${values.l_name.trim()}`
          : values.f_name.trim(),
      email: values.email,
    };
    if (initialValues.image_url !== values.image_url) {
      payload.image_url = values.image_url;
    }
    if (values.enabled_notifications.length > 0) {
      payload = {
        ...payload,
        enabled_notifications: values.enabled_notifications,
      };
    }

    dispatch(purchaserProfileSubmit(payload, profileData.id));
  };

  const goToHome = () => {
    // const token = localStorage.getItem("athletUp-token");
    // const role = localStorage.getItem("userRole");
    // if(token!== undefined && token !==null) {
    //   if(role === 'brand' || role === 'fan') {
    //     history.push("/purchaser/dashboard");
    //   }
    //   else if(role === 'athlete') {
    //     history.push("/athlete/dashboard");
    //   }
    // }
    // else {
    //   history.push("/");
    // }
    history.push('/');
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toastMessage('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 12;
    if (!isLt2M) {
      errorToastMessage('Image must smaller than 12MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadFile = (fileObj, setValue) => {
    const handleUpload = (data) => {
      setValue('image_url', data);
    };
    UploadAssets(fileObj, handleUpload, setLoadingImageStatus)();
  };

  const logout = () => {
    dispatch(logoutData());
    history.push('/login');
  };

  const showToastAndResetStatus = () => {
    if (mode === 'create') {
      toastMessage('Profile uploaded successfully');
    } else {
      toastMessage('Profile saved successfully');
    }

    setShowSuccess(true);
    dispatch(purchaserProfileStatus(false));
  };

  return (
    <div className="athlete-page-bg">
      <div className="profile-container">
        {isLoading && (
          <div className="loader text-center">
            <Loader type="Bars" color="#00BFFF" height={50} width={100} />
          </div>
        )}
        {!isLoading && (
          <>
            <div className="header-style header-light header-fixed athlete-bg">
              <div className="container-fluid px-4 page-contain athlete-page-bg">
                <div className="d-flex justify-content-between align-items-center py-3 profile-wrapper">
                  <div onClick={goToHome} className="cursor-pointer">
                    <img
                      alt="headerlogo"
                      src={headerSection.headerLogoBlack}
                      className="d-inline-block align-top"
                    />
                  </div>
                  {!showSuccess && mode === 'create' && (
                    <div className="text-dark d-flex fw-600 d-none d-md-flex">
                      Signed in as {profileData?.name}.
                      <div
                        onClick={logout}
                        className=" ms-2 text-purple cursor-pointer text-link">
                        Sign out
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={mode === 'create' ? 'profile-main' : ''}>
              {mode === 'create' && (
                <>
                  <div className="profile-text mb-2">
                    {profileSection.text1}
                  </div>
                  <div className="profile-title">{profileSection.title}</div>
                </>
              )}
              <div className="profilepart-1 mt-5 mb-5">
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    submitProfile(values);
                  }}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isValid,
                    isSubmitting,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="container-fluid">
                          <div className="d-md-flex align-items-center">
                            <div className="profile-pic-upload flex-shrink-0 mb-5  mx-auto m-none">
                              <div style={{position: 'relative'}}>
                                <ImgCrop rotate>
                                  <Upload
                                    className={`profile-input  ${
                                      values.image_url
                                        ? 'profile-image-holder'
                                        : ''
                                    }`}
                                    beforeUpload={beforeUpload}
                                    customRequest={(fileList) =>
                                      uploadFile(fileList, setFieldValue)
                                    }
                                    showUploadList={false}
                                    name="image_url"
                                    maxCount={1}>
                                    <div className="">
                                      {values.image_url ? (
                                        <div className="position-relative">
                                          <img
                                            src={values.image_url}
                                            alt="profilePic"
                                            className="profile-img-holder"
                                          />
                                          <p className="text-purple mt-3 fw-bold text-center change-image-text">
                                            Change photo
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="d-flex align-items-center  flex-column w-100 h-100 ">
                                          <img
                                            src={icons.cameraIcon}
                                            alt="camera Icon"
                                          />
                                          <p className="text-purple mt-3 fw-bold">
                                            Choose Image
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </Upload>
                                </ImgCrop>
                                {loadimgImageStatus && (
                                  <div className="loader text-center">
                                    <Loader
                                      type="Bars"
                                      color="#00BFFF"
                                      height={50}
                                      width={100}
                                    />
                                  </div>
                                )}
                                <div className="text-danger fw-500 text-sm text-center mt-1">
                                  {touched.image_url &&
                                    errors.image_url &&
                                    errors.image_url}
                                </div>
                              </div>
                            </div>
                            <div className="ms-0 ms-md-4 flex-grow-1 mb-3 md-md-5">
                              <div className="mb-4">
                                <label className="text" htmlFor="f_name">
                                  First name
                                </label>
                                <input
                                  className="pro-input mt-1"
                                  name="f_name"
                                  id="f_name"
                                  placeholder="First Name"
                                  onChange={(e) => {
                                    setFieldValue('f_name', e.target.value);
                                  }}
                                  value={values.f_name}
                                />
                                <div className="text-danger text-sm fw-500">
                                  {touched.f_name &&
                                    errors.f_name &&
                                    errors.f_name}
                                </div>
                              </div>
                              <div className="mb-4">
                                <label className="text" htmlFor="l_name">
                                  Last name
                                </label>
                                <input
                                  className="pro-input mt-1"
                                  name="l_name"
                                  id="l_name"
                                  placeholder="Last Name"
                                  onChange={(e) => {
                                    setFieldValue('l_name', e.target.value);
                                  }}
                                  value={values.l_name}
                                />
                                <div className="text-danger text-sm fw-500">
                                  {touched.f_name &&
                                    errors.f_name &&
                                    errors.f_name}
                                </div>
                              </div>
                              <div className="mb-4">
                                <label className="text" htmlFor="company">
                                  Email
                                </label>
                                <input
                                  className="pro-input mt-1"
                                  name="email"
                                  id="email"
                                  placeholder="Email"
                                  readOnly={true}
                                  onChange={(e) => {
                                    setFieldValue('email', e.target.value);
                                  }}
                                  value={values.email}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Notifications */}
                          {mode === 'edit' && (
                            <div className="col-md-12">
                              <label className="text" htmlFor="First name">
                                Notifications
                              </label>
                              <div className="border-bottom mb-3 mt-2"></div>
                              <p className="text-dark fw-500 title">
                                Email me when:
                              </p>

                              <CheckboxGroup
                                name="enabled_notifications"
                                value={values.enabled_notifications}
                                onChange={(val) => {
                                  setFieldValue('enabled_notifications', val);
                                }}>
                                <Checkbox
                                  value="important_information"
                                  disabled={disableCheckbox}>
                                  Important information related to order and
                                  deal activity, payments, and account security
                                </Checkbox>
                                {role === 'brand' && (
                                  <Checkbox value="new_application_or_offer">
                                    I receive a new campaign application or deal
                                    counter offer
                                  </Checkbox>
                                )}
                                <Checkbox value="new_im">
                                  I receive a new chat message related to an
                                  order or deal
                                </Checkbox>
                                <Checkbox value="newsletters">
                                  AthleteUp newsletter providing NIL insights +
                                  platform tips & tricks (never sent more than
                                  1x per week)
                                </Checkbox>
                                {role === 'fan' && (
                                  <Checkbox value="promotions_offers">
                                    Promotions and special offers from AthleteUp
                                  </Checkbox>
                                )}
                              </CheckboxGroup>
                            </div>
                          )}
                          <div
                            className={
                              mode === 'edit'
                                ? 'text-center mt-5'
                                : 'text-center'
                            }>
                            <button
                              type="submit"
                              className="btn btn-primary mw-570">
                              SAVE
                            </button>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PurchaserAccountDetails;
