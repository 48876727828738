import React from 'react';
import {Carousel} from 'antd';

const CustomCarousel = (props) => {
  const SampleNextArrow = (props) => {
    const {onClick} = props;
    return <div className="arrow-right" onClick={onClick} />;
  };

  const SamplePrevArrow = (props) => {
    const {onClick} = props;
    return <div className="arrow-left" onClick={onClick} />;
  };

  const settings = {
    infinite: true,
    dots: false,
    loop: true,
    nextArrow: props.prevArrow ? <SampleNextArrow /> : '',
    prevArrow: props.nextArrow ? <SamplePrevArrow /> : '',
    variableWidth: props.variableWidth,
  };

  return (
    <>
      <Carousel
        {...settings}
        slidesToShow={props.slidesToShow}
        arrows={true}
        className="custom-carousel"
        beforeChange={(currentSlide) => {
          if (props.setCurrentSlide) {
            props.setCurrentSlide(currentSlide);
          }
        }}>
        {props.children}
      </Carousel>
    </>
  );
};

export default CustomCarousel;
