import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import AppLayout from '../../../../../common/AppLayout';
import AppHeader from '../../../../../common/components/AppHeader';
import ShopCard from './../MyShop/ShopCard';
import {setHeader} from '../../../../../store/athleteflow/action';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import {CardTheme} from '../../style';

const MyShopUnlisted = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === '/athlete/my-shop-unlisted') {
      dispatch(setHeader(false));
    }
  }, [location.pathname, dispatch]);

  return (
    <AppLayout>
      <div className="bubble-bg"></div>
      <div className="content-wrapper">
        <AppHeader
          title="My shop"
          subTitle={
            <p className="font-20 color-muted mb-5 fw-600">
              View and edit all of your active shop listings.
            </p>
          }
        />
        <CardTheme
          bgUrl={backgroundStyle.themeBg}
          bgUrlMob={backgroundStyle.themeBgMob}>
          <div className="ct-left">
            <div className="ct-title">Publish your listings</div>
            <div className="ct-content">
              Your shop is not published to the public yet. Start your free
              trial today to publish your listings and start engaging with fans
              and getting paid!
            </div>
          </div>
          <div className="ct-btn-contain">
            <button
              className="btn btn-primary ms-auto ct-button"
              type="primary">
              Publish & Start Trial
            </button>
          </div>
        </CardTheme>

        <button
          className="btn btn-tarnparant-purple d-xl-none d-block fw-800 mobile-add-listing"
          onClick={() => {
            history.push('/athlete/choose-listing-method');
          }}>
          + ADD LISTING
        </button>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div
            className="color-muted fw-700 font-m"
            style={{letterSpacing: '1px'}}>
            YOUR LISTINGS
          </div>
          <div>
            <button
              className="btn btn-tarnparant-purple d-none d-lg-block btn-wide fw-800"
              onClick={() => {
                history.push('/athlete/choose-listing-method');
              }}>
              + ADD NEW LISTING
            </button>
          </div>
        </div>
        <div className="mt-3">
          <ShopCard />
        </div>
      </div>
    </AppLayout>
  );
};

export default MyShopUnlisted;
