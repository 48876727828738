import React, {useEffect} from 'react';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import Home from './pages/Home';
import Brands from './pages/Brands';
import Pricing from './pages/Pricing';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsService from './pages/TermsService';
import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-201242941-1';
ReactGA.initialize(TRACKING_ID);

const Marketing = () => {
  let {path} = useRouteMatch();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="marketing-bg">
      <div className="theme-dark">
        <Switch>
          <Route exact path={path} component={() => <Home />} />
          <Route path="/brands" exact component={() => <Brands />} />
          <Route path="/pricing" exact component={() => <Pricing />} />
          <Route
            path="/terms-service"
            exact
            component={() => <TermsService />}
          />
          <Route path="/about" exact component={() => <About />} />
          <Route
            path="/privacy-policy"
            exact
            component={() => <PrivacyPolicy />}
          />
          {/* <Route component={() =><PageNotFound/>}/> */}
        </Switch>
      </div>
    </div>
  );
};

const MarketingModule = {
  routeProps: {
    path: '',
    exact: true,
    component: Marketing,
  },
  name: 'Marketing',
};

export default MarketingModule;
