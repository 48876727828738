import React, {useState, useRef} from 'react';
import Text from '../../../components/Text/Text';
import ModalContainer from './ModalContainr';
import ModalHeader from './ModalHeader';
import {
  AthleteCard,
  AthleteProductDetailContainer,
  AthleteProductPrice,
  CustomAthleteTag,
  AthleteCardContainer,
  AthleteProductMobilePrice,
  TagContainer,
} from '../pages/style';
import {AthleteInfoModal} from './style';
import {CloseIcon} from '../../../utils/SocialIcons';
import {formatPrice} from '../../../helpers/user';
import useDeviceSize from '../../../hooks/useDeviceSize';
import {Avatar, Badge} from 'antd';
import {PlayCircleOutlined} from '@ant-design/icons';

const AthleteInfoCard = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {athleteListDetail, athletProfileDetail} = props;
  const size = useDeviceSize();
  const descriptionRef = useRef(null);
  let cardListProduct =
    athleteListDetail?.type === 'Experiences'
      ? athleteListDetail?.listing_experience
      : athleteListDetail?.listing_product;

  let orderLeft;
  let isQuanityLimited = cardListProduct?.is_limited;
  if (isQuanityLimited) {
    orderLeft = cardListProduct?.limited_quantity;
  }

  const showModal = () => {
    // localStorage.setItem('listingId', athleteListDetail.id);
    const userRole = localStorage.getItem('userRole');
    if (userRole === 'athlete') {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    let videoId =
      athleteListDetail?.attachments[0]?.type === 'video'
        ? athleteListDetail?.attachments[0]?.url
        : '';
    let iframes = document.getElementById(videoId);
    if (iframes) {
      var iframeSrc = iframes.src;
      iframes.src = iframeSrc;
    }
  };

  return (
    <AthleteCard
      style={{cursor: 'pointer', border: 'none'}}
      className="p-0 box-shadow-tab">
      <AthleteInfoModal
        style={{top: 0}}
        width={1040}
        zIndex={2500}
        closeIcon={
          <div className="d-flex align-items-center">
            <CloseIcon />
            <span style={{marginLeft: '8px'}}>CLOSE</span>
          </div>
        }
        title={<ModalHeader athletProfileDetail={athletProfileDetail} />}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}>
        <ModalContainer
          athleteModalDetails={athleteListDetail}
          orderLeft={orderLeft}
        />
      </AthleteInfoModal>
      <AthleteCardContainer onClick={showModal} className="custom-card">
        {size?.width > 991 ? (
          <div className="card-block ">
            {athleteListDetail?.attachments[0]?.type === 'video' ? (
              <div className="video-background">
                <div className="video-foreground">
                  <Badge count={<PlayCircleOutlined />} className="badge-play">
                    <Avatar
                      src={`https://img.youtube.com/vi/${athleteListDetail?.attachments[0]?.url}/hqdefault.jpg`}
                      alt="card-img"
                      className={`img-fluid object-fit-contain purchases-list-imag lg `}
                    />
                  </Badge>
                </div>
              </div>
            ) : (
              <img
                src={athleteListDetail?.attachments[0]?.url}
                alt="card-img"
                className="img-fluid object-fit-contain purchases-list-imag"
              />
            )}
          </div>
        ) : (
          <div className="d-flex text-nowrap overflow-y mobile-dashboard-attachement">
            {athleteListDetail?.attachments.map((data, i) => (
              <React.Fragment key={i}>
                {data.type === 'video' ? (
                  <iframe
                    src={`https://www.youtube.com/embed/${data?.url}?controls=0`}
                    width="100%"
                    frameBorder="0"
                    allowFullScreen
                    title="Video"
                  />
                ) : (
                  <>
                    <img
                      src={data?.url}
                      width="60vw"
                      alt="card-img"
                      className="img-fluid object-fit-contain purchases-list-imag"
                    />
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
        <AthleteProductDetailContainer>
          <div className="d-xl-flex justify-content-between ">
            <h3
              className="list-title line-ellipse-2"
              title={cardListProduct.title}>
              {cardListProduct.title}
            </h3>
            <AthleteProductPrice className="ms-0 ms-xl-3">
              <Text
                display="flex"
                weight="700"
                size="28"
                lineHeight="32"
                textColor="#042237"
                cursor="pointer">
                ${formatPrice(cardListProduct?.price)}
              </Text>
            </AthleteProductPrice>
          </div>
          {orderLeft <= 5 && (
            <div className="fw-bold text-danger">
              {orderLeft} {orderLeft === 1 ? 'order' : 'orders'} left
            </div>
          )}

          <AthleteProductMobilePrice>
            <Text
              display="flex"
              weight="700"
              size="28"
              lineHeight="32"
              textColor="#042237"
              cursor="pointer">
              ${formatPrice(cardListProduct?.price)}
            </Text>
          </AthleteProductMobilePrice>
          <div className="scroll-custom-tag">
            <TagContainer>
              <CustomAthleteTag>
                {cardListProduct?.category.name}
              </CustomAthleteTag>
              <CustomAthleteTag>
                {cardListProduct?.subcategory.name}
              </CustomAthleteTag>
              {cardListProduct?.is_coaching && (
                <CustomAthleteTag>Coaching/Lessons</CustomAthleteTag>
              )}
            </TagContainer>
          </div>
          <p
            className="font-m fw-500 position-relative pe-2 mb-0 line-ellipse-2"
            ref={descriptionRef}
            title={cardListProduct?.description}>
            {cardListProduct?.description}
          </p>
        </AthleteProductDetailContainer>
      </AthleteCardContainer>
    </AthleteCard>
  );
};

export default AthleteInfoCard;
