import React, {Fragment, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setHeader} from '../../../../../store/athleteflow/action';
import Text from '../../../../../components/Text/Text';
import ProfileImage from '../../../components/ProfileImage';
import {handleSidebarTab} from '../../../../../store/users/actions';
import {MyPurchaseCard, OrderDetails} from '../../style';
import moment from 'moment';
import {formatPrice} from '../../../../../helpers/user';
import {getPurchaserDeals} from '../../../../../store/brandflow/action';

const Orders = ({item, orderData, status}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {deals} = useSelector((state) => state.BrandModuleReducer);

  useEffect(() => {
    if (item === 'deals') {
      if (deals.length === 0) {
        dispatch(
          getPurchaserDeals({
            page: 1,
            pageSize: 5,
            status: status,
          }),
        );
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const statusType = (value) => {
    if (value === 'completed') {
      return 'status-success ';
    } else if (value === 'active') {
      return 'status-progress'; //status for inprogress organge button
    } else if (value === 'disputed') {
      return 'disputed';
    } else if (value === 'declined') {
      return 'cancel';
    }
  };

  const dealStatusType = (value) => {
    if (value === 'completed') {
      return 'status-success ';
    } else if (value === 'in progress') {
      return 'status-progress';
    } else if (value === 'declined') {
      return 'cancel';
    } else if (value === 'disputed') {
      return 'disputed';
    } else {
      return 'offer';
    }
  };

  const formatDate = (dateObj) => {
    return moment(dateObj).format('MMM DD, YYYY');
  };

  const handleRowClick = (id) => {
    if (item === 'myPurchase') {
      history.push(`dashboard/order/${id}`);
    }
    if (item === 'deals' || item === 'campaign-deals') {
      history.push(`/purchaser/deals/${id}`);
    }
    if (item === 'athlete') {
      history.push(`order/details/${id}`);
    }
  };

  useEffect(() => {
    if (
      history.location.pathname === '/purchaser/order/details' ||
      history.location.pathname === `/purchaser/deal`
    ) {
      dispatch(setHeader(false));
      dispatch(handleSidebarTab('dashboard'));
      // triggerModal('success', 'Login success');
    }
  }, [history.location.pathname, dispatch]);

  const role = localStorage.getItem('userRole');
  let roleRead = role === 'athlete' ? 'athlete_read' : 'purchaser_read';
  return (
    <Fragment>
      {item === 'deals' || item === 'campaign-deals' || item === 'invites' ? (
        <>
          {deals && deals.length > 0 ? (
            <>
              {deals.map((data) => (
                <div
                  key={data.id}
                  className={`order-card ${
                    data[roleRead] !== true ? 'active' : 'inactive'
                  }`}>
                  <MyPurchaseCard
                    key={data?.id}
                    onClick={() => handleRowClick(data?.id)}>
                    <OrderDetails className="mb-2">
                      <ProfileImage
                        width={48}
                        imgWidth={48}
                        height={48}
                        className="radius-m"
                        src={data?.athlete?.image_url}
                      />
                      <div className="d-flex flex-column">
                        <Text
                          display="flex"
                          weight="700"
                          size="18"
                          mrLeft="20"
                          className="mb-1"
                          lineHeight="21"
                          textColor="#042237">
                          {data?.athlete?.name}
                        </Text>

                        <div className="d-flex ">
                          <Text
                            display="flex"
                            weight="400"
                            size="14"
                            mrLeft="20"
                            textColor="#435969">
                            #{data?.id}
                            <span className="mx-3">|</span>
                            {formatDate(data?.createdAt)}
                          </Text>
                        </div>
                      </div>
                    </OrderDetails>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <Text
                        display="flex"
                        weight="500"
                        size="15"
                        className="d-flex"
                        lineHeight="24"
                        textColor="#042237">
                        {data?.title}
                      </Text>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      {(item === 'deals' || item === 'campaign-deals') && (
                        <span
                          className={`${dealStatusType(
                            data?.status,
                          )} titlecase`}>
                          {data?.status === 'application/offer' &&
                          role === 'fan'
                            ? 'offer'
                            : data?.status}
                        </span>
                      )}
                      {item === 'deals' && (
                        <Text
                          display="flex"
                          weight="600"
                          size="16"
                          className="d-flex"
                          lineHeight="24"
                          textColor="#042237">
                          $
                          {data?.counter_offers &&
                          data?.counter_offers.length > 0
                            ? formatPrice(data?.counter_offers[0]?.total_price)
                            : formatPrice(data?.total_price)}
                        </Text>
                      )}
                    </div>
                  </MyPurchaseCard>
                </div>
              ))}
            </>
          ) : (
            <div className="fw-700 d-flex font-18 justify-content-center my-4 pt-5">
              No deals to display
            </div>
          )}
        </>
      ) : (
        <>
          {orderData !== undefined && (
            <>
              {orderData.map((data) => {
                return (
                  <div
                    key={data.id}
                    className={`order-card ${
                      data[roleRead] !== true ? 'active' : 'inactive'
                    }`}
                    onClick={() => handleRowClick(data?.id)}>
                    <MyPurchaseCard key={data?.orderID}>
                      <OrderDetails>
                        <ProfileImage
                          mobileImgWidth={48}
                          width={48}
                          imgWidth={48}
                          height={48}
                          className="rounded-s"
                          src={
                            item !== 'athlete'
                              ? data?.listing?.athlete?.image_url
                              : data?.user?.purchaser?.logo
                              ? data?.user?.purchaser?.logo
                              : data?.user?.image_url
                          }
                        />
                        <div className="d-flex flex-column">
                          <Text
                            display="flex"
                            weight="700"
                            size="16"
                            mrLeft="20"
                            className="mb-1 text-capitalize"
                            lineHeight="21"
                            textColor="#042237">
                            {item !== 'athlete'
                              ? data?.listing?.athlete?.name
                              : data?.user?.purchaser?.company
                              ? data?.user?.purchaser?.company
                              : data?.user?.name}
                          </Text>

                          <div className="d-flex ">
                            <Text
                              display="flex"
                              weight="400"
                              size="14"
                              mrLeft="20"
                              textColor="#435969">
                              #{data?.id} &nbsp; | &nbsp;
                              {moment(data?.createdAt).format('MMM DD,YYYY')}
                            </Text>
                          </div>
                        </div>
                      </OrderDetails>
                      <Text
                        display="flex"
                        weight="500"
                        size="15"
                        className="mt-2"
                        lineHeight="24"
                        textColor="#435969">
                        {data?.title}
                      </Text>
                      <div className="d-flex justify-content-between align-items-center mt-4">
                        {/* title case not required for in progess label this if check rrquired */}
                        {data?.athlete_marked_complete &&
                        item === 'athlete' &&
                        data?.status === 'active' ? (
                          <span className="status-success">Completed</span>
                        ) : (
                          <span
                            className={`${statusType(data?.status)}  ${
                              data?.status === 'active' ? '' : 'titlecase'
                            }`}>
                            {data?.status === 'active'
                              ? 'In progress'
                              : data?.status === 'declined'
                              ? 'Canceled'
                              : data?.status}
                          </span>
                        )}

                        <Text
                          display="flex"
                          weight="600"
                          size="16"
                          className="d-flex"
                          lineHeight="24"
                          textColor="#042237">
                          {item === 'athlete'
                            ? `$${formatPrice(data?.subtotal)}`
                            : `$${formatPrice(data?.total_price)}`}
                        </Text>
                      </div>
                    </MyPurchaseCard>
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </Fragment>
  );
};

export default Orders;
