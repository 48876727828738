import React from 'react';
import Text from '../../../../../components/Text/Text';
import {CampaignDetailContainer} from '../../../../Purchaser/components/features/Campaigns/style';
import {CheckoutButton} from '../../../../Purchaser/pages/style';

const CompleteDeal = ({markDealComplete}) => {
  return (
    <CampaignDetailContainer className="my-3 pb-3">
      <Text
        display="flex"
        weight="500"
        size="18"
        lineHeight="30"
        mrBottom="6"
        className="form-label "
        textColor="#042237">
        Please confirm that you would like to mark this deal as complete.
        Marking complete indicates that all the deliverables have been
        fulfilled.
      </Text>
      <CheckoutButton onClick={markDealComplete} className="mb-3">
        <Text
          weight="800"
          size="18"
          lineHeight="23"
          textColor="#FFFFFF"
          cursor="pointer">
          Mark complete
        </Text>
      </CheckoutButton>
    </CampaignDetailContainer>
  );
};

export default CompleteDeal;
