export const GET_MODAL = 'GET_MODAL';
export const LOGIN_ACTION = 'LOGIN_ACTION';
export const HANDLE_MESSAGE = 'HANDLE_MESSAGE';
export const HANDLE_SUCCESS_RESPONSE = 'HANDLE_SUCCESS_RESPONSE';
export const LOADER = 'LOADER';
export const GET_PROFILE = 'GET_PROFILE';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const SIDE_BAR_TAB = `SIDE_BAR_TAB`;
export const FETCH_ATHLETE = 'FETCH_ATHLETE';
export const SET_ATHLETE_DATA = 'SET_ATHLETE_DATA';
export const GET_ATHLETE_DATA = 'GET_ATHLETE_DATA';
export const SET_ATHLETE_DETAILS = 'SET_ATHLETE_DETAILS';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_STATUS = 'PASSWORD_RESET_STATUS';
export const LOGOUT = 'LOGOUT';
export const LOGGED_OUT_STATUS = 'LOGGED_OUT_STATUS';
export const CLEAR_LOCAL_DATA = 'CLEAR_LOCAL_DATA';
export const SET_ATHLETE_INFINITE_DATA = 'SET_ATHLETE_INFINITE_DATA';
export const SET_PURCHASER_DATA = 'SET_PURCHASER_DATA';
export const GET_PURCHASER_DATA = 'GET_PURCHASER_DATA';
export const SET_PURCHASER_DETAILS = 'SET_PURCHASER_DETAILS';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const APPEND_TRANSACTIONS = 'APPEND_TRANSACTIONS';
export const FETCH_NEWLY_JOINED_ATHLETE = 'FETCH_NEWLY_JOINED_ATHLETE';
export const SET_NEWLYJOINED_ATHLETE_DETAILS =
  'SET_NEWLYJOINED_ATHLETE_DETAILS';
export const SET_NEW_COUNT = 'SET_NEW_COUNT';
export const GET_SIDEBAR_COUNT = 'GET_SIDEBAR_COUNT';
export const SET_PROFILE_CREATION = 'SET_PROFIEL_CREATION';
export const SET_TOKEN_ERROR = 'SET_TOKEN_ERROR';
export const SET_NO_ATHLETE = 'SET_NO_ATHLETE';
