import React, {useEffect, useCallback} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import '../../../../Purchaser/pages/pages.scss';
import AppLayout from '../../../../../common/AppLayout';
import {BackArrow} from '../../../../Athlete/components/Icons';
import {toastMessage} from '../../../../../helpers/toastMessage';
import {
  setHeader,
  orderComplete,
  fetchOrderDetails,
  orderCancel,
  twiliotoken,
} from '../../../../../store/athleteflow/action';
import OrderDetailsCard from '../../../../../common/components/OrderDetailsCard';
import {BackSection} from './../../../../Purchaser/components/style';
import Loader from 'react-loader-spinner';
import {useState} from 'react';
import http from '../../../../../helpers/http';
import CancelOrderModal from './CancelOrderModal';
import MarkCompleteModal from './MarkCompleteModal';
import {handleSidebarTab} from '../../../../../store/users/actions';
import moment from 'moment';

const Chat = require('twilio-chat');

const OrderDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {orderId} = useParams();
  const [shippingLabel, setShippingLabel] = useState({});
  const [tokenTwilio, setToken] = useState('');
  const [completeModalVisible, setCompleteModal] = useState(false);
  const [cancelOrderModalVisible, setCancelModalVisible] = useState(false);
  const [messagesList, setMessage] = useState([]);
  // const [updateToken, setUpdateToken] = useState(true);
  const [channelPrivate, setChanel] = useState(null);
  const [loader, setLoader] = useState(false);
  const [participantsDetails, setParticipantDetails] = useState([]);
  const [orderCompleteStatus, setOrderComplete] = useState(false);
  const {athleteDetails} = useSelector((state) => state.UsersReducer);
  const {orderCompleted, orderDetails, isLoading, orderCancelStatus, message} =
    useSelector((state) => state.AthleteModuleReducer);

  const getShippingLabel = useCallback(async () => {
    try {
      let url = `/athlete/order/${orderId}/shipping_label`;
      const res = await http.get(url);
      setShippingLabel(res.data.data);
    } catch (err) {
      console.log('Error in shipping label');
    }
  }, [orderId]);

  const markReadOrder = useCallback(
    async (data) => {
      try {
        let url = `/athlete/order/${orderId}/mark-read`;
        await http.put(url);
      } catch (err) {
        console.log(err);
      }
    },
    [orderId],
  );
  const sendMessageAction = (text) => {
    if (text && String(text).trim()) {
      channelPrivate && channelPrivate.sendMessage(text);
    }
  };

  const getToken = async () => {
    const response = await http.get(
      `/twilio/token?username=user${athleteDetails?.id}`,
    );
    return response.data.data.token;
  };
  const joinChannel = async (channel) => {
    if (channel.channelState.status !== 'joined') {
      await channel.join();
    }
  };

  const createChannelFn = async () => {
    let token = '';
    setLoader(true);
    try {
      token = await getToken();
      setToken(token);
      dispatch(twiliotoken(token));
    } catch {
      throw new Error('unable to get token, please reload this page');
    }
    const client = await Chat.Client.create(token);
    // setUpdateToken(false);

    client.on('tokenAboutToExpire', async () => {
      console.log('tokken about to expire');
      // setUpdateToken(true);
      const token = await getToken();
      client.updateToken(token);
    });

    client.on('tokenExpired', async () => {
      console.log('token expired');
      // setUpdateToken(true);
      const token = await getToken();
      client.updateToken(token);
    });

    try {
      const channel = await client.getChannelBySid(orderDetails?.channel_id);
      await http.put(
        `twilio/conversation/${orderDetails?.channel_id}/mark-read`,
      );

      setMessage();
      await joinChannel(channel);

      if (participantsDetails.length === 0) {
        let particpatantChannel = await http.get(
          `/twilio/conversation/${orderDetails?.channel_id}/participants`,
        );
        setParticipantDetails(particpatantChannel?.data?.data);
      }
      setChanel(channel);
      getMessages(channel);
      channel.on('messageAdded', function (message) {
        getMessages(channel);
      });
      setLoader(false);
    } catch {
      throw new Error('unable to create channel, please reload this page');
    }
  };

  const getMessages = async (channel) => {
    const messages = await channel.getMessages();
    setMessage(messages.items);
  };
  useEffect(() => {
    let initiateToken = true;
    if (
      initiateToken &&
      Object.keys(orderDetails).length > 0 &&
      athleteDetails?.id !== undefined &&
      orderDetails?.channel_id !== undefined
    ) {
      createChannelFn(); // run it, run it
    }
    return () => {
      initiateToken = false;
    };
    /* eslint-disable-next-line */
  }, [athleteDetails, orderDetails]);

  useEffect(() => {
    if (message.text) {
      toastMessage(message.text);
    }
  }, [message]);

  useEffect(() => {
    if (orderCancelStatus) {
      toastMessage('Order cancelled succesfully');
      dispatch(fetchOrderDetails({type: 'athlete', id: orderId}));
    }
  }, [orderCancelStatus, dispatch, orderId]);

  useEffect(() => {
    if (history.location.pathname === `/athlete/order/details/${orderId}`) {
      dispatch(setHeader(false));
      dispatch(handleSidebarTab('orders'));
    }
  }, [history.location.pathname, dispatch, orderId]);

  useEffect(() => {
    if (orderCompleted === true) {
      toastMessage('Order Completed Succesfully');
      dispatch(fetchOrderDetails({type: 'athlete', id: orderId}));
    } else {
    }
  }, [orderCompleted, dispatch, orderId]);

  useEffect(() => {
    dispatch(fetchOrderDetails({type: 'athlete', id: orderId}));
  }, [dispatch, orderId]);

  useEffect(() => {
    if (orderDetails?.listing?.type === 'Physical or digital products') {
      getShippingLabel(orderId);
    }
  }, [orderId, getShippingLabel, orderDetails]);

  useEffect(() => {
    markReadOrder(orderId);
  }, [orderId, markReadOrder]);

  const markComplete = () => {
    setCompleteModal(false);
    dispatch(orderComplete({type: 'athlete', id: orderId}));
    dispatch(fetchOrderDetails({type: 'athlete', id: orderId}));
  };

  const cancelOrder = () => {
    setCancelModalVisible(false);
    dispatch(orderCancel({type: 'athlete', id: orderId}));
  };

  useEffect(() => {
    if (orderDetails?.athlete_marked_complete) {
      let bannerShowTillDate = moment(orderDetails?.completed_on).add(
        4,
        'days',
      );

      if (
        !moment().isBetween(
          moment(orderDetails?.completed_on),
          bannerShowTillDate,
        )
      ) {
        setOrderComplete(true);
      }
    } else {
      setOrderComplete(false);
    }
  }, [orderDetails]);

  const startVideoCall = () => {
    if (tokenTwilio === '') {
      return;
    }
    channelPrivate.sendMessage(
      'Please join video call',
      `/athlete/video-call/${orderDetails?.channel_id}`,
    );

    window.open(`/athlete/video-call/${orderDetails?.channel_id}`);
  };
  return (
    <AppLayout contentClassname="applayout-mobile-view theme-bubble-bg">
      <div className="position-relative z-index-2">
        <BackSection
          mrLeft="18"
          onClick={() => {
            if (history.length > 2) {
              history.goBack();
            } else {
              history.push(`/athlete/orders`);
            }
          }}>
          <BackArrow className="ms-3" />
          <span className="ms-2">Back</span>
        </BackSection>
        {isLoading ? (
          <div className="mh-100-vh d-flex align-items-center justify-content-center w-100">
            <Loader type="Bars" color="#00BFFF" height={50} width={50} />
          </div>
        ) : (
          <>
            {orderDetails !== undefined &&
              orderDetails?.channel_id !== null && (
                <OrderDetailsCard
                  orderDetails={orderDetails}
                  type="athlete"
                  shippingLabel={shippingLabel}
                  // markComplete={markComplete}
                  setCompleteModal={setCompleteModal}
                  setCancelModalVisible={setCancelModalVisible}
                  loader={loader}
                  messages={messagesList}
                  sendMessageAction={sendMessageAction}
                  participantsDetails={participantsDetails}
                  channelProxy={channelPrivate}
                  conversationProxy={orderDetails?.channel_id}
                  enableAttachMent={true}
                  startVideoCall={startVideoCall}
                  orderCompleteStatus={orderCompleteStatus}
                  messageStatus={
                    orderDetails?.status === 'declined' ||
                    orderDetails?.is_closed ||
                    orderDetails?.purchaser_marked_complete
                  }
                />
              )}
          </>
        )}
      </div>
      {cancelOrderModalVisible && (
        <CancelOrderModal
          cancelOrderModalVisible={cancelOrderModalVisible}
          setCancelModalVisible={setCancelModalVisible}
          cancelOrder={cancelOrder}
          orderDetails={orderDetails}
        />
      )}
      {completeModalVisible && (
        <MarkCompleteModal
          completeModalVisible={completeModalVisible}
          setCompleteModal={setCompleteModal}
          markComplete={markComplete}
          orderDetails={orderDetails}
        />
      )}
    </AppLayout>
  );
};

export default OrderDetails;
