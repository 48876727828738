import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import MessageContainer from '../../../common/Messages';
import {setHeader} from '../../../store/athleteflow/action';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

const Messages = () => {
  const {purchaserDetails} = useSelector((state) => state.UsersReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  let token = localStorage.getItem('athletUp-token');

  useEffect(() => {
    if (
      history.location.pathname === '/purchaser/messages' ||
      history.location.pathname === '/athlete/messages'
    ) {
      dispatch(setHeader(false));
    }
  }, [history.location.pathname, dispatch]);

  return (
    <>
      {Object.keys(purchaserDetails).length > 0 && (
        <MessageContainer
          messageInfo={purchaserDetails}
          token={token}
          type="purchaser"
        />
      )}
    </>
  );
};

export default Messages;
