import React, {useEffect, useState} from 'react';
import '../features.scss';
import CreateProfile from './CreateProfile';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  profileSubmit,
  profileSubmitSuccess,
  handleMessage,
} from '../../../../../store/athleteflow/action';
import {fetchProfileData, logoutData} from '../../../../../store/users/actions';
import {headerSection} from '../../../../../utils/staticStrings';
import {useLocation} from 'react-router-dom';
import {setHeader} from '../../../../../store/athleteflow/action';
import {toastMessage} from '../../../../../helpers/toastMessage';
import useApi from '../../../../../hooks/useApi';
import http from '../../../../../helpers/http';

const Profile = () => {
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {profileSubmitStatus, message} = useSelector(
    (state) => state.AthleteModuleReducer,
  );
  const {profileData, jwtError} = useSelector((state) => state.UsersReducer);
  const search = useLocation().search;
  const history = useHistory();
  let encryptedKey = new URLSearchParams(search).get('encryptedKey');
  // let athleteId = localStorage.getItem("id")
  const [sportsData] = useApi('/athlete/sports');
  const [collegeDetail] = useApi('/athlete/colleges');
  const [team] = useApi('/athlete/teams');
  const [interests] = useApi('/athlete/lifestyle');
  const [athleteTypes] = useApi('/athlete/athlete-types');
  const [profileAthleteData, setProfileData] = useState({});
  // const [athleteAccont] = useApi(`/athlete/${athleteId}`);

  useEffect(() => {
    dispatch(setHeader(false));
  }, [dispatch]);

  useEffect(() => {
    if (profileSubmitStatus === true) {
      toastMessage('Profile created successfully');
      history.push('/athlete/add-social-account');
      dispatch(profileSubmitSuccess(false));
    }
  }, [profileSubmitStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (profileSubmitStatus === false) {
      if (message?.text?.success === false) {
        if (message?.text?.message[0]?.text) {
          toastMessage(message?.text?.message[0]?.text);
        } else {
          toastMessage(message?.text?.message);
        }
      }
    }
  }, [profileSubmitStatus, message]);

  useEffect(() => {
    // function to check if basic details aleready filled then redirect to dashboard
    if (jwtError) {
      let id = localStorage.getItem('id');
      if (jwtError && id !== null) {
        http.get(`/athlete/${id}`).then((res) => {
          setProfileData(res?.data?.data);
          localStorage.setItem('name', res?.data?.data?.name);
          dispatch(handleMessage(''));
          if (res?.data?.data?.bio !== null) {
            history.push('/athlete/my-shop');
          }
        });
      }
    }
  }, [jwtError, history, dispatch]);

  useEffect(() => {
    if (profileAthleteData?.name === undefined) {
      dispatch(fetchProfileData(encryptedKey));
      setProfileData(profileData);
    }
  }, [profileAthleteData?.name, dispatch, encryptedKey, profileData]);

  useEffect(() => {
    let id = localStorage.getItem('id');
    if (encryptedKey === null && id !== null) {
      http.get(`/athlete/${id}`).then((res) => {
        setProfileData(res?.data?.data);
        localStorage.setItem('name', res?.data?.data?.name);
      });
    }
  }, [dispatch, encryptedKey]);

  const profileFormSubmit = (value) => {
    let payload = {...value, name: profileAthleteData?.name};
    dispatch(profileSubmit(payload, profileAthleteData?.id));
  };

  const logout = () => {
    dispatch(logoutData());
  };

  const goToHome = () => {
    history.push('/');
  };

  return (
    <div className="athlete-page-bg">
      <div className="profile-container">
        <div className="header-style header-light header-fixed athlete-bg">
          <div className="container-fluid px-4 page-contain athlete-page-bg">
            <div className="d-flex justify-content-between align-items-center py-3 profile-wrapper">
              <div onClick={goToHome} className="cursor-pointer">
                <img
                  alt="headerlogo"
                  src={headerSection.headerLogoBlack}
                  className="d-inline-block align-top"
                />
              </div>
              <div className="text-dark d-flex fw-600 d-none d-md-flex">
                Signed in as &nbsp;
                {profileData?.name
                  ? profileData?.name
                  : profileAthleteData?.name}
                <a
                  href="/login"
                  onClick={logout}
                  className=" ms-2 text-purple cursor-pointer text-link">
                  Sign out
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-main">
          <div className="profile-section">
            <CreateProfile
              profileFormSubmit={profileFormSubmit}
              profileAthleteData={profileAthleteData}
              sportsData={sportsData}
              collegeDetail={collegeDetail}
              team={team}
              interests={interests}
              athleteTypes={athleteTypes}
              mode="create"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
