import React, {useState, useEffect, useRef} from 'react';
import {Steps, Progress} from 'antd';
import CampaignDetails from './CampaignDetails';
import ApplicantCriteria from './ApplicantCreteria';
import {useDispatch} from 'react-redux';
import {
  createCampaign,
  editCampaign,
  handleCreateCampaignStatus,
  handleEditCampaignStatus,
} from '../../../../../store/brandflow/action';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import {useHistory} from 'react-router-dom';

const CampaignModalContainer = ({
  closeModal,
  mode,
  campaignData,
  modalView,
}) => {
  const [current, setCurrent] = useState(0);
  const [percent, setPercent] = useState(100 / 2);
  const [isCampaignLoading, setIsCampaignLoading] = useState(false);
  const [campaignForm1, setCampaignForm1] = useState({});
  const [campaignForm2, setCampaignForm2] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();

  useEffect(() => {
    document.querySelector('body').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [current]);

  useEffect(() => {
    if (mode === 'create') {
      dispatch(handleCreateCampaignStatus(false));
      setIsCampaignLoading(false);
    } else if (mode === 'edit') {
      dispatch(handleEditCampaignStatus(false));
      setIsCampaignLoading(true);
    }
  }, [dispatch, mode]);

  useEffect(() => {
    if (mode === 'edit' && Object.keys(campaignData)?.length > 0) {
      campaignData?.deliverables.forEach((deliverableData) => {
        if (deliverableData?.fulfillment_date) {
          deliverableData.fulfillment_date = moment(
            deliverableData.fulfillment_date,
          ).format('MM-DD-YYYY');
        }
        if (deliverableData?.fulfillment_days === null) {
          delete deliverableData.fulfillment_days;
        }
      });
      let campaignFormData1 = {
        title: campaignData?.title,
        description: campaignData?.description,
        image_url: campaignData?.image_url,
        total_price: campaignData?.total_price,
        deliverables: campaignData?.deliverables,
      };
      let campaignFormData2 = {
        criteria: campaignData?.criteria,
        type: campaignData?.type,
        allowed_colleges: campaignData?.allowed_colleges,
      };
      setCampaignForm1(campaignFormData1);
      setCampaignForm2(campaignFormData2);
      setTimeout(() => {
        setIsCampaignLoading(false);
      }, 1000);
    }
  }, [campaignData, mode]);

  const next = (campaignForm1) => {
    campaignForm1.expiration_period = '30';
    campaignForm1.expiration_unit = 'day';
    setCampaignForm1(campaignForm1);
    setCurrent(current + 1);
    setPercent((100 / 2) * (current + 2));
  };

  const submitCriteria = (campaignForm2) => {
    let campaignPayload = {
      ...campaignForm1,
      ...campaignForm2,
    };
    if (mode === 'create') {
      dispatch(createCampaign(campaignPayload));
    } else if (mode === 'edit') {
      const payload = {
        apiPayload: campaignPayload,
        id: campaignData.id,
      };
      dispatch(editCampaign(payload));
    }
    if (modalView) {
      closeModal();
    } else {
      history.push(`/purchaser/campaigns`);
    }
  };

  const prev = (payload) => {
    setCampaignForm2(payload);
    setCurrent(current - 1);
    setPercent((100 / 2) * current);
  };

  const {Step} = Steps;

  const steps = [
    {
      title: 'CAMPAIGN DETAILS',
      content: (
        <CampaignDetails
          next={next}
          campaignForm1={campaignForm1}
          formRef={formRef}
        />
      ),
    },
    {
      title: 'APPLICANT CRITERIA',
      content: (
        <ApplicantCriteria
          prev={prev}
          mode={mode}
          campaignForm2={campaignForm2}
          submitCriteria={submitCriteria}
        />
      ),
    },
  ];

  return (
    <div>
      {isCampaignLoading && (
        <div className="loader-contain text-center">
          <Loader type="Bars" color="#00BFFF" height={50} width={100} />
        </div>
      )}
      {!isCampaignLoading && (
        <>
          <Progress
            percent={percent}
            className="campaignProgressWizard"
            style={{top: '100px important'}}
          />
          <Steps
            current={current}
            className="createListing listing-campaign"
            type="navigation">
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="wizard-holder">
            <div className="steps-content">{steps[current].content}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default CampaignModalContainer;
