import {Link} from 'react-router-dom';
import {backgroundStyle} from '../../../utils/staticStrings';
import Loader from 'react-loader-spinner';
import {formatSocialCount} from '../../../helpers/user';

const AthleteCardLayout = (props) => {
  const {athletsProfile} = props;

  return (
    <>
      {athletsProfile === undefined ? (
        <>
          <div className="justify-content-center w-100 mt-5 py-5 d-flex align-items-center">
            <Loader type="Bars" color="#00BFFF" height={50} width={100} />
          </div>
        </>
      ) : (
        <>
          {athletsProfile !== undefined && (
            <>
              {athletsProfile.length > 0 ? (
                <>
                  {athletsProfile?.map((ele) => {
                    let socialCount =
                      ele?.social?.facebook_followers +
                      ele?.social?.instagram_followers +
                      ele?.social?.tiktok_followers +
                      ele?.social?.twitter_followers +
                      ele?.social?.youtube_followers;

                    return (
                      <Link
                        to={`/purchaser/profile/${ele.id}`}
                        key={ele.id}
                        className="col-6 col-sm-6 col-lg-4 col-xl-3 athlete-card col">
                        <div className="card">
                          <div className="newly-joined-card flex-column d-flex">
                            {ele.image_url ? (
                              <img
                                src={ele.image_url}
                                alt="profileImages"
                                className="image_holder_card"
                              />
                            ) : (
                              <div className="placeholder-athlete-icon"></div>
                            )}
                            <div className="card-body">
                              <h5
                                className="card-title athlete-name"
                                title={ele.name}>
                                {ele.name}
                              </h5>
                              <div
                                className="card-text text-card-list mb-3 line-ellipse-2"
                                style={{maxWidth: '230px'}}>
                                {ele?.athlete?.headliner && (
                                  <span title={ele?.athlete?.headliner}>
                                    {ele?.athlete?.headliner}
                                  </span>
                                )}
                              </div>
                              <div className="d-flex font-resize-m justify-content-between align-items-center mt-auto">
                                <p className="card-text mb-0 mb-md-0">
                                  <img
                                    src={backgroundStyle.meetStarsListIcon}
                                    alt="starIcon"
                                    className="me-md-2 me-1"
                                  />
                                  <span className="ms-0">
                                    {ele?.listing_count}&nbsp;
                                    {ele?.listing_count > 1
                                      ? 'listings'
                                      : 'listing'}
                                  </span>
                                </p>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={backgroundStyle.eyeIconBlue}
                                    alt="eyeIconBlue"
                                    className="me-md-2 me-1 d-block d-md-none"
                                    style={{width: 15}}
                                  />
                                  <img
                                    src={backgroundStyle.eyeIconBlue}
                                    alt="eyeIconBlue"
                                    className="me-2 d-none d-md-flex"
                                    height="20"
                                  />
                                  <span className="color-muted">
                                    {socialCount > 0
                                      ? formatSocialCount(socialCount)
                                      : 'n/a'}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="mobile-card card-text align-items-center">
                                <div className=" align-items-center">
                                  <img
                                    src={backgroundStyle.instaIcon}
                                    alt="instaIcon"
                                  />
                                  <span>98k</span>
                                </div>
                                <div className=" align-items-center ">
                                  <img
                                    src={backgroundStyle.twitterIcon}
                                    alt="twitterIcon"
                                  />

                                  <span>20k</span>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </>
              ) : (
                <h6 className="w-100 d-flex justify-content-center align-items-center my-5 text-muted">
                  No results found
                </h6>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default AthleteCardLayout;
