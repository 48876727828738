import React from 'react';
import {CloseOutlined} from '@ant-design/icons';
import {CreateCampaign} from './../../../../Purchaser/components/features/Campaigns/style';

const CancelOrderModal = (props) => {
  const {
    cancelOrderModalVisible,
    setCancelModalVisible,
    cancelOrder,
    orderDetails,
  } = props;
  return (
    <CreateCampaign
      style={{top: 100}}
      width={800}
      zIndex={800}
      closeIcon={<CloseOutlined />}
      title={
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '30px',
            color: '#042237',
            padding: '20px 0',
          }}>
          <span style={{textTransform: 'capitalize'}}>
            Cancel order #{orderDetails?.id}?
          </span>
        </div>
      }
      visible={cancelOrderModalVisible}
      onCancel={() => setCancelModalVisible(false)}
      footer={null}>
      <div className="px-3 px-md-5 py-3">
        <p className="font-18">
          Cancelling an order cannot be undone. Once cancelled, the message
          thread for the order will be immediately closed, and the collected
          payment will be automatically refunded back to the purchaser.
        </p>
        <p className="font-18">
          It's not required, but we recommend that prior to cancelling an order,
          you send a brief personal message to the customer in the order chat
          thread to let them know your reason for needing to cancel .
        </p>
        <div className="row mt-5 btn-modal-set">
          <div className="col me-3">
            <button
              className="btn btn-block btn-outline text-dark "
              onClick={() => {
                setCancelModalVisible(false);
              }}>
              Go Back
            </button>
          </div>
          <div className="col">
            <button className="btn btn-block btn-primary" onClick={cancelOrder}>
              Cancel Now
            </button>
          </div>
        </div>
      </div>
      {/* <CompleteDeal markDealComplete={markDealComplete} /> */}
    </CreateCampaign>
  );
};

export default CancelOrderModal;
