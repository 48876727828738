export const HANDLE_BRAND_REGISTER = 'HANDLE_BRAND_REGISTER';
export const HANDLE_BRAND_ERROR = 'HANDLE_BRAND_ERROR';
export const HANDLE_BRAND_SUCCESS_RESPONSE = 'HANDLE_BRAND_SUCCESS_RESPONSE';
export const HANDLE_BRAND_REGISTER_SUCCESS = 'HANDLE_BRAND_REGISTER_SUCCESS';
export const HANDLE_PURCHASER_PROFILE_SUBMIT =
  'HANDLE_PURCHASER_PROFILE_SUBMIT';
export const HANDLE_PURCHASER_PROFILE_STATUS =
  'HANDLE_PURCHASER_PROFILE_STATUS';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_STATUS = 'CREATE_CAMPAIGN_STATUS';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const APPEND_CAMPAIGNS = 'APPEND_CAMPAIGNS';
export const GET_CAMPAIGN_DATA = 'GET_CAMPAIGN_DATA';
export const SET_CAMPAIGN_DATA = 'SET_CAMPAIGN_DATA';
export const GET_SHIPPING_COST = 'GET_SHIPPING_COST';
export const SET_SHIPPING_COST = 'SET_SHIPPING_COST';
export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN';
export const EDIT_CAMPAIGN_STATUS = 'EDIT_CAMPAIGN_STATUS';
export const INVITE_ATHLETE_TO_CAMPAIGN = 'INVITE_ATHLETE_TO_CAMPAIGN';
export const INVITE_CAMPAIGN_STATUS = 'INVITE_CAMPAIGN_STATUS';
export const CAMPAIGN_STATUS_CHANGED = 'CAMPAIGN_STATUS_CHANGED';
export const ACTIVATE_OR_PAUSE_CAMPAIGN = 'ACTIVATE_OR_PAUSE_CAMPAIGN';
export const LOADER = 'LOADER';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const SET_ORDERS = 'SET_ORDERS';
export const HANDLE_BRAND_MESSAGE = 'HANDLE_BRAND_MESSAGE';
export const CREATE_CUSTOM_OFFER = 'CREATE_CUSTOM_OFFER';
export const CREATE_OFFER_STATUS = 'CREATE_OFFER_STATUS';
export const GET_DEALS = 'GET_DEALS';
export const SET_DEALS = 'SET_DEALS';
export const APPEND_DEALS = 'APPEND_DEALS';
export const GET_DEAL_DATA = 'GET_DEAL_DATA';
export const SET_DEAL_DATA = 'SET_DEAL_DATA';
export const TAG_DEAL_TO_CAMPAIGN = 'TAG_DEAL_TO_CAMPAIGN';
export const TAG_TO_CAMPAIGN_STATUS = 'TAG_TO_CAMPAIGN_STATUS';
export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const UPDATE_DEAL_STATUS_BRAND = 'UPDATE_DEAL_STATUS_BRAND';
export const UPDATE_DEAL_COUNTER_OFFER_STATUS_BRAND =
  'UPDATE_DEAL_COUNTER_OFFER_STATUS_BRAND';
export const DEAL_STATUS_CHANGED_BRAND = 'DEAL_STATUS_CHANGED_BRAND';
export const COUNTER_OFFER_BRAND = 'COUNTER_OFFER_BRAND';
export const COUNTER_OFFER_CREATED_BRAND = 'COUNTER_OFFER_CREATED_BRAND';
export const MARK_DEAL_DISPUTE = 'MARK_DEAL_DISPUTE';
export const ADRRESS_VALIDATION = ' ADRRESS_VALIDATION';
export const STATE_LIST = 'STATE_LIST';
export const SET_STATE_LIST = 'SET_STATE_LIST';
export const DEALS_LOADER = 'DEALS_LOADER';
