import React, { useState } from 'react';
import PasswordInput from './PasswordInput';

const PasswordStrength = (props) => {
  const { label, blurEvent, onChange } = props;
  const [passwordLength, setPasswordLength] = useState(0);
  const [strength, setStrength] = useState(0);
  const [passwordVal, SetPasswordValue] = useState('');

  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"); // eslint-disable-line 
  const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");


  const strengthClass = [
    'strength-meter mt-2',
    passwordLength > 0 ? 'visible' : 'invisible',
  ]
    .join(' ')
    .trim();
  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    onChange(value)

    if (strongRegex.test(event.target.value)) {
      setStrength(3)
    } else if (mediumRegex.test(event.target.value)) {
      setStrength(2)
    } else if (event.target.value.length > 4) {
      setStrength(1)
    } else {
      setStrength(0)
    }

    SetPasswordValue(value);
    // setStrength(zxcvbn(value).score);
    setPasswordLength(value.length);
    // getPassword(value, zxcvbn(value).score);
  };

  return (
    <React.Fragment>
      <div className="form-floating brand-form">
        <PasswordInput
          name="password"
          changeEvent={handleChange}
          value={passwordVal}
          blurEvent={blurEvent}
          placeHolder="Confirm Password"
        />
        <label htmlFor="floatingPassword">{label}</label>
      </div>
      <div>
        {/* <span className="d-block form-hint mt-2">
          To conform with our Strong Password policy, you are required to use a
          sufficiently strong password. Password must be more than 8 characters.
        </span> */}
        <div className={strengthClass}>
          <div className="strength-meter-fill" data-strength={strength}></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PasswordStrength;
