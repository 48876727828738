import React, {useState, useEffect} from 'react';
import {Select} from 'antd';
import Text from '../../../../../components/Text/Text';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import {Formik} from 'formik';
import useApi from '../../../../../hooks/useApi';
import './styles.scss';
import {CampaignDetail, CustomSelectDropDown} from './style';
import {Icon} from '../../style';
import {CheckoutButton} from '../../../pages/style';

const invertMapSMValues = (SMValues) => {
  return SMValues.map((range) => {
    return {
      heading: `${range.min} ${range.max ? ` - ${range.max}` : ' +'}`,
      realValue: range,
      value: `${range.min} ${range.max ? ` - ${range.max}` : ' +'}`,
    };
  });
};

const ApplicantCriteria = ({prev, submitCriteria, mode, campaignForm2}) => {
  const [selectedCard, setSelectedCard] = useState('open');
  const [socialRangeOptions, setSocialRangeOptions] = useState([]);
  const [athleteTypes] = useApi('/athlete/athlete-types');
  const [sportsData] = useApi('/athlete/sports');
  const [states] = useApi('/athlete/states');
  const [collegeDetail] = useApi('/athlete/colleges');
  // const [athleteData] = useApi('/athlete?page=1&pageSize=1000');
  const [socialReachRanges] = useApi('purchaser/campaign/social-range');

  let initialValues = {
    criteria: {
      athlete_type: campaignForm2?.criteria?.athlete_type
        ? campaignForm2?.criteria?.athlete_type
        : [],
      sports: campaignForm2?.criteria?.sports
        ? campaignForm2?.criteria?.sports
        : [],
      locations: campaignForm2?.criteria?.locations
        ? campaignForm2?.criteria?.locations
        : [],
      gender: campaignForm2?.criteria?.gender
        ? campaignForm2?.criteria?.gender
        : null,
      social_reach: {
        total: campaignForm2?.criteria?.social_reach?.total
          ? invertMapSMValues(campaignForm2?.criteria?.social_reach?.total)
          : [],
        instagram: campaignForm2?.criteria?.social_reach?.instagram
          ? invertMapSMValues(campaignForm2.criteria.social_reach.instagram)
          : [],
        tiktok: campaignForm2?.criteria?.social_reach?.tiktok
          ? invertMapSMValues(campaignForm2.criteria.social_reach.tiktok)
          : [],
        facebook: campaignForm2?.criteria?.social_reach?.facebook
          ? invertMapSMValues(campaignForm2.criteria.social_reach.facebook)
          : [],
        youtube: campaignForm2?.criteria?.social_reach?.youtube
          ? invertMapSMValues(campaignForm2.criteria.social_reach.youtube)
          : [],
        twitter: campaignForm2?.criteria?.social_reach?.twitter
          ? invertMapSMValues(campaignForm2.criteria.social_reach.twitter)
          : [],
      },
    },
    allowed_colleges: campaignForm2?.allowed_colleges
      ? campaignForm2?.allowed_colleges
      : [],
  };

  useEffect(() => {
    if (socialReachRanges?.data?.length > 0) {
      let rangeOps = socialReachRanges?.data.map((range) => {
        return {
          heading: `${range.min} ${range.max ? ` - ${range.max}` : ' +'}`,
          realValue: range,
          value: `${range.min} ${range.max !== '' ? ` - ${range.max}` : ' +'}`,
        };
      });
      setSocialRangeOptions(rangeOps);
    }
  }, [socialReachRanges]);

  useEffect(() => {
    if (mode === 'edit') {
      setSelectedCard(campaignForm2?.type);
      // setSocialReach(campaignForm2?.criteria?.social_reach);
    }
  }, [campaignForm2?.type, mode, campaignForm2?.criteria?.social_reach]);

  const getChangedValues = (values, initialValues) => {
    return Object.entries(values).reduce((acc, [key, value]) => {
      if (!Array.isArray(value)) {
        const hasChanged = initialValues[key] !== value;
        if (hasChanged) {
          acc[key] = value;
        }
      } else if (value.length > 0) {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const mapSMvalues = (SMValues) => {
    let newArr = [];
    SMValues.forEach((val) => {
      if (val?.realValue?.max === null) {
        let numArr = val.value.replace(' ', '').split('+');
        newArr.push({
          min: Number(numArr[0]),
          max: null,
        });
      } else {
        let numArr = val.replace(' ', '').split('-');
        newArr.push({
          min: Number(numArr[0]),
          max: Number(numArr[1]),
        });
      }
    });
    return newArr;
  };

  const editSMSchema = (SMValues) => {
    let newArr = [];

    SMValues.forEach((val) => {
      if (val?.realValue?.max === null || val === '1000001  +') {
        let numArr = val?.value
          ? val.value.replace(' ', '').split('+')
          : val.replace(' ', '').split('+');

        newArr.push({
          min: Number(numArr[0]),
          max: null,
        });
      } else {
        let numArr = val?.value
          ? val.value.replace(' ', '').split('-')
          : val.replace(' ', '').split('-');

        newArr.push({
          min: Number(numArr[0]),
          max: Number(numArr[1]),
        });
      }
    });

    return newArr;
  };

  const goBack = (values) => {
    let payload = {type: selectedCard};
    if (selectedCard === 'open') {
      if (mode === 'create') {
        let changedCriteria = getChangedValues(
          values.criteria,
          initialValues.criteria,
        );
        if (Object.keys(changedCriteria).length > 0) {
          payload.criteria = changedCriteria;
        }
        prev(payload);
      } else if (mode === 'edit') {
        payload.criteria = values.criteria;
        if (payload.criteria.athlete_type.length === 0) {
          payload.criteria.athlete_type = null;
        }
        if (payload.criteria.gender === '') {
          payload.criteria.gender = null;
        }
        if (payload.criteria.locations.length === 0) {
          payload.criteria.locations = null;
        }
        if (payload.criteria.sports.length === 0) {
          payload.criteria.sports = null;
        }
        prev(payload);
      }
    } else if (selectedCard === 'private') {
      // if(values.athlete_user_ids.length > 0) {
      //   payload.athlete_user_ids = values.athlete_user_ids;
      // }
      prev(payload);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        let payload = {type: selectedCard};
        if (selectedCard === 'open') {
          if (mode === 'create') {
            let changedCriteria = getChangedValues(
              values.criteria,
              initialValues.criteria,
            );
            if (Object.keys(changedCriteria).length > 0) {
              payload.criteria = changedCriteria;
            }
            if (
              values?.criteria?.social_reach?.total &&
              values?.criteria?.social_reach?.total.length > 0
            ) {
              values.criteria.social_reach.total = mapSMvalues(
                values?.criteria?.social_reach?.total,
              );
            }
            if (
              values?.criteria?.social_reach?.instagram &&
              values?.criteria?.social_reach?.instagram.length > 0
            ) {
              values.criteria.social_reach.instagram = mapSMvalues(
                values?.criteria?.social_reach?.instagram,
              );
            }
            if (
              values?.criteria?.social_reach?.twitter &&
              values?.criteria?.social_reach?.twitter.length > 0
            ) {
              values.criteria.social_reach.twitter = mapSMvalues(
                values?.criteria?.social_reach?.twitter,
              );
            }
            if (
              values?.criteria?.social_reach?.tiktok &&
              values?.criteria?.social_reach?.tiktok.length > 0
            ) {
              values.criteria.social_reach.tiktok = mapSMvalues(
                values?.criteria?.social_reach?.tiktok,
              );
            }
            if (
              values?.criteria?.social_reach?.facebook &&
              values?.criteria?.social_reach?.facebook.length > 0
            ) {
              values.criteria.social_reach.facebook = mapSMvalues(
                values?.criteria?.social_reach?.facebook,
              );
            }
            if (
              values?.criteria?.social_reach?.youtube &&
              values?.criteria?.social_reach?.youtube.length > 0
            ) {
              values.criteria.social_reach.youtube = mapSMvalues(
                values?.criteria?.social_reach?.youtube,
              );
            }
            if (values.allowed_colleges) {
              if (values.allowed_colleges.length > 0) {
                payload.allowed_colleges = values.allowed_colleges;
              } else if (values?.allowed_colleges.length === 0) {
                delete payload.allowed_colleges;
              }
            }
            submitCriteria(payload);
          } else if (mode === 'edit') {
            // debugger;
            payload.criteria = values.criteria;
            if (
              payload.criteria.athlete_type &&
              payload.criteria.athlete_type.length === 0
            ) {
              payload.criteria.athlete_type = null;
            }
            if (payload.criteria.gender && payload.criteria.gender === '') {
              payload.criteria.gender = null;
            }
            if (
              payload.criteria.locations &&
              payload.criteria.locations.length === 0
            ) {
              payload.criteria.locations = null;
            }
            if (
              payload.criteria.sports &&
              payload.criteria.sports.length === 0
            ) {
              payload.criteria.sports = null;
            }
            if (values.allowed_colleges) {
              if (values.allowed_colleges.length > 0) {
                payload.allowed_colleges = values.allowed_colleges;
              } else if (values?.allowed_colleges.length === 0) {
                payload.allowed_colleges = null;
              }
            }
            if (
              values?.criteria?.social_reach?.total &&
              values?.criteria?.social_reach?.total.length > 0
            ) {
              values.criteria.social_reach.total = editSMSchema(
                values?.criteria?.social_reach?.total,
              );
            }
            if (
              values?.criteria?.social_reach?.instagram &&
              values?.criteria?.social_reach?.instagram.length > 0
            ) {
              values.criteria.social_reach.instagram = editSMSchema(
                values?.criteria?.social_reach?.instagram,
              );
            }
            if (
              values?.criteria?.social_reach?.twitter &&
              values?.criteria?.social_reach?.twitter.length > 0
            ) {
              values.criteria.social_reach.twitter = editSMSchema(
                values?.criteria?.social_reach?.twitter,
              );
            }
            if (
              values?.criteria?.social_reach?.tiktok &&
              values?.criteria?.social_reach?.tiktok.length > 0
            ) {
              values.criteria.social_reach.tiktok = editSMSchema(
                values?.criteria?.social_reach?.tiktok,
              );
            }
            if (
              values?.criteria?.social_reach?.facebook &&
              values?.criteria?.social_reach?.facebook.length > 0
            ) {
              values.criteria.social_reach.facebook = editSMSchema(
                values?.criteria?.social_reach?.facebook,
              );
            }
            if (
              values?.criteria?.social_reach?.youtube &&
              values?.criteria?.social_reach?.youtube.length > 0
            ) {
              values.criteria.social_reach.youtube = editSMSchema(
                values?.criteria?.social_reach?.youtube,
              );
            }
            submitCriteria(payload);
          }
        } else if (selectedCard === 'private') {
          // if(values.athlete_user_ids.length > 0) {
          //   payload.athlete_user_ids = values.athlete_user_ids;
          // }
          submitCriteria(payload);
        }
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isValid,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <CampaignDetail className="my-3">
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="25"
                textColor="#042237">
                Campaign type
              </Text>

              <div className="mt-3 mb-3 d-flex w-100 campaign-list-card-left-right">
                <div
                  className={`campaign-list-card mb-3 w-50 ${
                    selectedCard === 'open' ? 'active' : ''
                  }`}
                  onClick={() => setSelectedCard('open')}>
                  <div className="bg-white">
                    <div className="card-bg d-flex" style={{cursor: 'pointer'}}>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <Text
                          display="flex"
                          weight="700"
                          size="18"
                          lineHeight="24"
                          cursor="pointer"
                          textColor="#042237">
                          Open
                        </Text>
                        <Text
                          display="flex"
                          weight="500"
                          size="15"
                          lineHeight="24"
                          cursor="pointer"
                          textColor="#042237">
                          (any eligible athlete can apply)
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`campaign-list-card w-50 mb-3 ${
                    selectedCard === 'private' ? 'active' : ''
                  }`}
                  onClick={() => setSelectedCard('private')}>
                  <div className="bg-white">
                    <div className="card-bg d-flex" style={{cursor: 'pointer'}}>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <Text
                          display="flex"
                          weight="700"
                          size="18"
                          lineHeight="24"
                          cursor="pointer"
                          textColor="#042237">
                          Private
                        </Text>
                        <Text
                          display="flex"
                          weight="500"
                          size="15"
                          lineHeight="24"
                          cursor="pointer"
                          textColor="#042237">
                          (only invited athletes can apply)
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom mt-5 mw-570 mx-auto d-none d-md-block"></div>
              </div>

              {selectedCard === 'open' && (
                <>
                  <Text
                    display="flex"
                    weight="800"
                    size="22"
                    lineHeight="30"
                    className="w-100 my-3"
                    textColor="#042237">
                    Required Applicant Criteria
                  </Text>
                  <div className="d-flex mb-3 flex-column profile-main p-0">
                    <div className="d-flex flex-column mb-3">
                      <Text
                        display="flex"
                        weight="700"
                        size="18"
                        lineHeight="24"
                        className="mb-3"
                        textColor="#042237">
                        Athlete type
                      </Text>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Any"
                        value={values.criteria?.athlete_type}
                        onChange={(value) =>
                          setFieldValue('criteria.athlete_type', value)
                        }
                        name="criteria.athlete_type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {athleteTypes?.data?.map((option) => (
                          <Select.Option
                            value={option}
                            key={option}
                            style={{textTransform: 'capitalize'}}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="d-flex flex-column mb-3">
                      <Text
                        display="flex"
                        weight="700"
                        size="18"
                        lineHeight="24"
                        className="mb-3"
                        textColor="#042237">
                        Sports
                      </Text>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Any"
                        value={values.criteria?.sports}
                        onChange={(value) =>
                          setFieldValue('criteria.sports', value)
                        }
                        name="criteria.sports"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {sportsData?.data?.rows.map((option) => (
                          <Select.Option value={option.id} key={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="d-flex flex-column mb-3">
                      <Text
                        display="flex"
                        weight="700"
                        size="18"
                        lineHeight="24"
                        className="mb-3"
                        textColor="#042237">
                        College / University
                      </Text>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Any"
                        value={values.allowed_colleges}
                        onChange={(value) =>
                          setFieldValue('allowed_colleges', value)
                        }
                        name="allowed_colleges"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {collegeDetail?.data?.rows?.map((option) => (
                          <Select.Option value={option.id} key={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="d-flex flex-column mb-3">
                      <Text
                        display="flex"
                        weight="700"
                        size="18"
                        lineHeight="24"
                        className="mb-3"
                        textColor="#042237">
                        Gender
                      </Text>
                      <CustomSelectDropDown
                        className="custom-select custom-select-light"
                        value={values.criteria?.gender || undefined}
                        placeholder="Any"
                        onChange={(value) =>
                          setFieldValue('criteria.gender', value)
                        }
                        name="criteria.gender">
                        <Select.Option value={null} key={'any'}>
                          Any
                        </Select.Option>
                        <Select.Option value="male">Male</Select.Option>
                        <Select.Option value="female">Female</Select.Option>
                      </CustomSelectDropDown>
                    </div>
                    <div className="d-flex flex-column mb-4">
                      <Text
                        display="flex"
                        weight="700"
                        size="18"
                        lineHeight="24"
                        className="mb-3"
                        textColor="#042237">
                        Location (States)
                      </Text>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Any"
                        value={values.criteria?.locations}
                        onChange={(value) =>
                          setFieldValue('criteria.locations', value)
                        }
                        name="criteria.locations"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {states?.data?.map((option) => (
                          <Select.Option value={option.key} key={option.key}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="d-flex flex-column mb-3">
                      <Text
                        display="flex"
                        weight="700"
                        size="18"
                        lineHeight="24"
                        className="mb-3"
                        textColor="#042237">
                        Total Social Media reach
                      </Text>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Any"
                        value={values.criteria?.social_reach?.total}
                        onChange={(value) =>
                          setFieldValue('criteria.social_reach.total', value)
                        }
                        name="criteria.social_reach.total">
                        {socialRangeOptions?.map((option) => (
                          <Select.Option
                            value={option.value}
                            key={option.value}>
                            {option.heading}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="d-flex flex-column mb-3">
                      <div className="d-flex">
                        <Text
                          display="flex"
                          weight="700"
                          size="18"
                          lineHeight="24"
                          className="mb-3"
                          textColor="#042237">
                          Instagram reach
                        </Text>
                        <Icon
                          width="16"
                          height="16"
                          mrRight="5"
                          mrLeft="10"
                          style={{
                            marginTop: '4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          src={backgroundStyle.instaIcon}
                          alt="locations"
                        />
                      </div>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Any"
                        value={values.criteria?.social_reach?.instagram}
                        onChange={(value) =>
                          setFieldValue(
                            'criteria.social_reach.instagram',
                            value,
                          )
                        }
                        name="criteria.social_reach.instagram">
                        {socialRangeOptions?.map((option) => (
                          <Select.Option
                            value={option.value}
                            key={option.value}>
                            {option.heading}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="d-flex flex-column mb-3">
                      <div className="d-flex">
                        <Text
                          display="flex"
                          weight="700"
                          size="18"
                          lineHeight="24"
                          className="mb-3"
                          textColor="#042237">
                          Twitter reach
                        </Text>
                        <Icon
                          width="16"
                          height="16"
                          mrRight="5"
                          mrLeft="10"
                          style={{
                            marginTop: '4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          src={backgroundStyle.twitterIcon}
                          alt="locations"
                        />
                      </div>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Any"
                        value={values.criteria?.social_reach?.twitter}
                        onChange={(value) =>
                          setFieldValue('criteria.social_reach.twitter', value)
                        }
                        name="criteria.social_reach.twitter">
                        {socialRangeOptions?.map((option) => (
                          <Select.Option
                            value={option.value}
                            key={option.value}>
                            {option.heading}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="d-flex flex-column mb-3">
                      <div className="d-flex">
                        <Text
                          display="flex"
                          weight="700"
                          size="18"
                          lineHeight="24"
                          className="mb-3"
                          textColor="#042237">
                          Tiktok reach
                        </Text>
                        <Icon
                          width="16"
                          height="16"
                          mrRight="5"
                          mrLeft="10"
                          style={{
                            marginTop: '4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          src={backgroundStyle.tiktokIcon}
                          alt="locations"
                        />
                      </div>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Any"
                        value={values.criteria?.social_reach?.tiktok}
                        onChange={(value) =>
                          setFieldValue('criteria.social_reach.tiktok', value)
                        }
                        name="criteria.social_reach.tiktok">
                        {socialRangeOptions?.map((option) => (
                          <Select.Option
                            value={option.value}
                            key={option.value}>
                            {option.heading}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="d-flex flex-column mb-3">
                      <div className="d-flex">
                        <Text
                          display="flex"
                          weight="700"
                          size="18"
                          lineHeight="24"
                          className="mb-3"
                          textColor="#042237">
                          Youtube reach
                        </Text>
                        <Icon
                          width="16"
                          height="16"
                          mrRight="5"
                          mrLeft="10"
                          style={{
                            marginTop: '4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          src={backgroundStyle.youtubeIcon}
                          alt="locations"
                        />
                      </div>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Any"
                        value={values.criteria?.social_reach?.youtube}
                        onChange={(value) =>
                          setFieldValue('criteria.social_reach.youtube', value)
                        }
                        name="criteria.social_reach.youtube">
                        {socialRangeOptions?.map((option) => (
                          <Select.Option
                            value={option.value}
                            key={option.value}>
                            {option.heading}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="d-flex flex-column mb-3">
                      <div className="d-flex">
                        <Text
                          display="flex"
                          weight="700"
                          size="18"
                          lineHeight="24"
                          className="mb-3"
                          textColor="#042237">
                          Facebook reach
                        </Text>
                        <Icon
                          width="16"
                          height="16"
                          mrRight="5"
                          mrLeft="10"
                          style={{
                            marginTop: '4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          src={backgroundStyle.facebook}
                          alt="locations"
                        />
                      </div>
                      <Select
                        className="custom-select custom-multi-select"
                        mode="multiple"
                        placeholder="Any"
                        value={values.criteria?.social_reach?.facebook}
                        onChange={(value) =>
                          setFieldValue('criteria.social_reach.facebook', value)
                        }
                        name="criteria.social_reach.facebook">
                        {socialRangeOptions?.map((option) => (
                          <Select.Option
                            value={option.value}
                            key={option.value}>
                            {option.heading}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </>
              )}

              {selectedCard === 'private' && (
                // <div className="d-flex flex-column mb-3">
                //   <Text
                //     display="flex"
                //     weight="700"
                //     size="18"
                //     lineHeight="24"
                //     className="mb-3"
                //     textColor="#042237">
                //     Select athletes to invite
                //   </Text>
                //   <Select
                //     className="custom-select custom-multi-select"
                //     mode="multiple"
                //     value={values.athlete_user_ids}
                //     onChange={(value) => setFieldValue('athlete_user_ids', value)}
                //     name="athlete_user_ids"
                //     optionFilterProp="children"
                //     filterOption={(input, option) =>
                //       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                //     }
                //   >
                //     {athleteData?.data?.rows.map((option) => (
                //       <Select.Option value={option.id} key={option.id}>
                //         {option.name}
                //       </Select.Option>
                //     ))}
                //   </Select>
                // </div>
                <div className="text-center title mx-3 my-5">
                  Head to the Athletes tab to send deal offers or invitations to
                  apply to this campaign
                </div>
              )}

              <CheckoutButton type="submit">
                <Text
                  weight="800"
                  size="18"
                  lineHeight="23"
                  textColor="#FFFFFF"
                  cursor="pointer">
                  <span
                    style={{
                      textTransform: 'uppercase',
                    }}>{`${mode} CAMPAIGN`}</span>
                </Text>
              </CheckoutButton>
              <div
                className="fw-800 font-18 mt-3 mb-5 cursor-pointer d-flex justify-content-center color-purple"
                onClick={() => goBack(values)}>
                Back
              </div>
            </CampaignDetail>
          </form>
        );
      }}
    </Formik>
  );
};

export default ApplicantCriteria;
