import React, {useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import * as yup from 'yup';
import {Formik} from 'formik';
import useApi from '../../../../../hooks/useApi';
import {Col, Popover, Row, Select} from 'antd';
import Text from '../../../../../components/Text/Text';
import {
  CampaignDetail,
  CustomAmount,
  CustomSelectDropDown,
  CustomTextArea,
  DeliverablesContainer,
  FulfillmentDatePicker,
  Heading,
} from '../../../../Purchaser/components/features/Campaigns/style';
import {CheckoutButton, CustomInput} from '../../../../Purchaser/pages/style';
import {PaymentModalFooter} from '../../../../Purchaser/components/style';
import {personalMsgPopover1, personalMsgPopover2} from './constants';
import {formatPriceCommas} from '../../../../../helpers/user';

let schema = yup.object().shape({
  title: yup.string().required('Please enter title'),
  message: yup.string().required('Please enter message'),
  total_price: yup
    .number()
    .min(1)
    .max(999999999)
    .positive('Must be more than 0'),
  deliverables: yup.array().of(
    yup.object().shape({
      type: yup.string().required('Deliverable type required'),
      //   compensation_cash: yup
      //     .number()
      //     // .test(
      //     //   'is-decimal',
      //     //   'The amount should be a decimal with maximum two digits after comma',
      //     //   (val) => {
      //     //     if (val !== undefined) {
      //     //       return patternTwoDigisAfterComma.test(val);
      //     //     }
      //     //     return true;
      //     //   },
      //     // )
      //     .min(0.5)
      //     .max(999999999)
      //     // .positive('Must be more than 0')
      //     .required('Price is required'),
    }),
  ),
});

const ApplyToCampaign = ({campaignData, applytoCampaign}) => {
  const {Option} = Select;
  const [totalCashCompensation, setTotalCashCompensation] = useState(0);
  const [totalProductCompensation, setTotalProductCompensation] = useState(0);
  const [compensationErrors, setCompensationErrors] = useState(['']);
  const [expirationDays] = useApi('/purchaser/campaign/deal/expiration-days');
  const formRef = useRef();
  const {athleteDetails} = useSelector((state) => state.UsersReducer);

  useEffect(() => {
    let totalCashComp = 0;
    let totalProductComp = 0;
    campaignData?.deliverables.forEach((deliverable) => {
      totalCashComp = totalCashComp + deliverable?.compensation_cash;
      totalProductComp = totalProductComp + deliverable?.compensation_product;
    });
    setTotalCashCompensation(totalCashComp);
    setTotalProductCompensation(totalProductComp);
  }, [campaignData?.deliverables]);

  const generateDealTitle = () => {
    return `${athleteDetails?.name} + ${campaignData?.user?.purchaser?.company} : ${campaignData?.title}`;
  };

  const getDeliverables = (deliverables) => {
    let delData = [];
    deliverables.forEach((deliverable) => {
      let delItem = {};
      delItem.type = deliverable.type;
      delItem.compensation_cash = 0;
      delItem.compensation_product = 0;
      if (deliverable?.fulfillment_date) {
        delItem.fulfillment_date = deliverable.fulfillment_date;
      }
      if (deliverable?.fulfillment_type) {
        delItem.fulfillment_type = deliverable.fulfillment_type;
      }
      if (deliverable?.fulfillment_days) {
        delItem.fulfillment_days = deliverable.fulfillment_days;
      }
      delData.push(delItem);
    });
    return delData;
  };

  let initialValues = {
    title: generateDealTitle(),
    deliverables: getDeliverables(campaignData?.deliverables),
    total_price: 0,
    expiration_period: 30,
    message: '',
  };

  // const handlePaymentMethod = (e) => {
  //   setPaymentMethod(e.target.value);
  // };

  const submitForm = (values) => {
    validateCompensation(formRef);
    let compensationValid = compensationErrors.every((error) => {
      return error === '';
    });
    if (compensationValid) {
      values.deliverables.forEach((deliverable) => {
        if (deliverable.compensation_cash === '') {
          deliverable.compensation_cash = 0;
        } else {
          deliverable.compensation_cash = Number(deliverable.compensation_cash);
        }
        if (deliverable.compensation_product === '') {
          deliverable.compensation_product = 0;
        } else {
          deliverable.compensation_product = Number(
            deliverable.compensation_product,
          );
        }
      });
      applytoCampaign({...values, expiration_unit: 'day'});
    }
  };

  const content = (para1, para2) => {
    return (
      <div style={{width: '350px'}}>
        <div className="my-1">{para1}</div>
        <div className="mt-1">{para2}</div>
      </div>
    );
  };

  const validateEachCompensation = (deliverable, idx) => {
    let cash = Number(deliverable.compensation_cash);
    let product = Number(deliverable.compensation_product);
    if (cash === '' && product === '') {
      let compErrors = compensationErrors;
      compErrors[idx] = 'Either cash or product budget is mandatory';
      setCompensationErrors(compErrors);
    } else if (
      (cash === 0 || cash === '') &&
      (product === 0 || product === '')
    ) {
      let compErrors = compensationErrors;
      compErrors[idx] =
        'Either cash or product budget should be greater than zero';
      setCompensationErrors(compErrors);
    } else if (cash < 0 || product < 0) {
      let compErrors = compensationErrors;
      compErrors[idx] = 'Budget should be positive';
      setCompensationErrors(compErrors);
    } else {
      let compErrors = compensationErrors;
      compErrors[idx] = '';
      setCompensationErrors(compErrors);
    }
  };

  const validateCompensation = (formRef, idx = -1) => {
    let deliverables = formRef.current.values.deliverables;
    if (idx === -1) {
      deliverables.forEach((deliverable, idx) => {
        validateEachCompensation(deliverable, idx);
      });
    } else {
      validateEachCompensation(deliverables[idx], idx);
    }
  };

  const computeTotal = (formRef, setFieldValue) => {
    let deliverables = formRef.current.values.deliverables;
    let total = 0;
    deliverables.forEach((deliverable) => {
      if (deliverable?.compensation_cash) {
        total = total + Number(deliverable.compensation_cash);
      }
      if (deliverable?.compensation_product) {
        total = total + Number(deliverable.compensation_product);
      }
    });
    setFieldValue('total_price', total);
  };

  const onCashCompensationChange = (value, idx, setFieldValue, formRef) => {
    setFieldValue(`deliverables[${idx}].compensation_cash`, value).then(
      (res) => {
        console.log('ooooooo:');
        // validateCompensation(formRef, idx);
        computeTotal(formRef, setFieldValue);
      },
    );
  };

  const onProductCompensationChange = (value, idx, setFieldValue, formRef) => {
    setFieldValue(`deliverables[${idx}].compensation_product`, value).then(
      (res) => {
        validateCompensation(formRef, idx);
        computeTotal(formRef, setFieldValue);
      },
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={(values) => {
        submitForm(values);
      }}
      innerRef={formRef}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isValid,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <CampaignDetail className="my-4">
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="24"
                mrBottom="6"
                className="form-label "
                textColor="#042237">
                Deal title<span className="text-danger ms-1"> *</span>
                <Popover
                  content={content(
                    'A generic title has been created, but you can modify this title if desired.',
                  )}
                  trigger="hover"
                  placement="right"
                  className="info-icon ms-2 mt-1 cursor-pointer">
                  <span>?</span>
                </Popover>
              </Text>
              <CustomInput
                type="text"
                name="title"
                className="highlighted"
                value={values.title}
                onChange={(e) => {
                  setFieldValue('title', e.target.value);
                }}
              />
              <div className="text-danger text-sm fw-500">
                {touched.title && errors.title && errors.title}
              </div>
            </CampaignDetail>
            <CampaignDetail className="my-4">
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="24"
                mrBottom="6"
                className="form-label "
                textColor="#042237">
                Campaign title
              </Text>
              <CustomInput
                type="text"
                name="title"
                value={`${campaignData?.title} (#${campaignData?.id})`}
                disabled
              />
            </CampaignDetail>
            <CampaignDetail className="mb-4">
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="24"
                className="form-label "
                mrBottom="6"
                textColor="#042237">
                Description
              </Text>
              <CustomTextArea
                disabled
                rows={5}
                value={campaignData?.description}
                onChange={(e) => setFieldValue('description', e.target.value)}
              />
            </CampaignDetail>
            <CampaignDetail>
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="24"
                className="form-label "
                mrBottom="6"
                textColor="#042237">
                Deliverable(s)
              </Text>
              {values.deliverables.map((deliverable, idx, deliverables) => (
                <DeliverablesContainer key={idx}>
                  <div className="d-flex flex-column mb-3">
                    <Text
                      display="flex"
                      weight="600"
                      size="16"
                      lineHeight="22"
                      className="form-label "
                      textColor="#435969">
                      Deliverable type
                    </Text>
                    <div className="d-lg-block d-none">
                      <CustomSelectDropDown
                        value={deliverable.type}
                        name={`deliverables[${idx}].type`}
                        onChange={(value) =>
                          setFieldValue(`deliverables[${idx}].type`, value)
                        }
                        disabled></CustomSelectDropDown>
                    </div>
                    <div className="d-block d-lg-none">
                      <CustomSelectDropDown
                        value={deliverable.type}
                        name={`deliverables[${idx}].type`}
                        disabled
                        onChange={(value) =>
                          setFieldValue(`deliverables[${idx}].type`, value)
                        }></CustomSelectDropDown>
                    </div>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <Text
                      display="flex"
                      weight="600"
                      size="16"
                      lineHeight="22"
                      className="form-label "
                      textColor="#435969">
                      Fulfillment date
                    </Text>
                    <Row gutter={[16]}>
                      <Col xs={{span: 24}} md={{span: 24}} lg={{span: 24}}>
                        <CustomSelectDropDown
                          value={deliverable.fulfillment_type}
                          disabled
                          name={`deliverables[${idx}].fulfillment_type`}
                          onChange={(value) =>
                            setFieldValue(
                              `deliverables[${idx}].fulfillment_type`,
                              value,
                            )
                          }></CustomSelectDropDown>
                      </Col>
                      <Col
                        xs={{span: 24}}
                        md={{span: 24}}
                        lg={{span: 12}}
                        className="mt-2">
                        {(deliverable.fulfillment_type === 'Specific Date' ||
                          deliverable.fulfillment_type === 'Before Date') && (
                          <>
                            <FulfillmentDatePicker
                              name={`deliverables[${idx}].fulfillment_date`}
                              format="MM/DD/YYYY"
                              disabled
                              value={moment(
                                deliverable.fulfillment_date,
                                'YYYY-MM-DD',
                              )}
                              onChange={(date) => {
                                if (date) {
                                  setFieldValue(
                                    `deliverables[${idx}].fulfillment_date`,
                                    date.format('MM/DD/YYYY'),
                                  );
                                }
                              }}></FulfillmentDatePicker>
                          </>
                        )}
                        {deliverable.fulfillment_type ===
                          'Within X Days after product received' && (
                          <>
                            <CustomSelectDropDown
                              value={deliverable.fulfillment_days}
                              disabled
                              name={`deliverables[${idx}].fulfillment_days`}
                              onChange={(value) =>
                                setFieldValue(
                                  `deliverables[${idx}].fulfillment_days`,
                                  value,
                                )
                              }></CustomSelectDropDown>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <Text
                      display="flex"
                      weight="600"
                      size="16"
                      lineHeight="22"
                      className="form-label "
                      textColor="#435969">
                      Compensation
                      <span className="text-danger ms-1"> *</span>
                    </Text>
                    <Row gutter={[16]}>
                      <Col xs={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                        <CustomAmount className="highlighted">
                          <Text
                            display="flex"
                            weight="500"
                            size="15"
                            lineHeight="25"
                            opacity="0.7"
                            textColor="#435969">
                            Cash
                          </Text>
                          <Text
                            display="flex"
                            weight="500"
                            size="18"
                            lineHeight="25"
                            textColor="#042237">
                            $
                            <input
                              min="0"
                              max="1000000000"
                              className="compensation_input"
                              type="number"
                              value={deliverable.compensation_cash}
                              name={`deliverables[${idx}].compensation_cash`}
                              onChange={(e) =>
                                onCashCompensationChange(
                                  e.target.value.replace(/^0+/, ''),
                                  idx,
                                  setFieldValue,
                                  formRef,
                                )
                              }
                            />
                            <div className="text-danger text-sm mt-3 fw-bold"></div>
                          </Text>
                        </CustomAmount>
                      </Col>
                      <Col xs={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                        <CustomAmount className="highlighted">
                          <Text
                            display="flex"
                            weight="500"
                            size="15"
                            lineHeight="25"
                            opacity="0.7"
                            textColor="#435969">
                            Products
                          </Text>
                          <Text
                            display="flex"
                            weight="500"
                            size="18"
                            lineHeight="25"
                            textColor="#042237">
                            ${' '}
                            <input
                              type="number"
                              min="0"
                              max="1000000000"
                              className="compensation_input"
                              value={deliverable.compensation_product}
                              name={`deliverables[${idx}].compensation_product`}
                              onChange={(e) =>
                                onProductCompensationChange(
                                  e.target.value.replace(/^0+/, ''),
                                  idx,
                                  setFieldValue,
                                  formRef,
                                )
                              }
                            />
                          </Text>
                        </CustomAmount>
                      </Col>
                    </Row>
                    <div className="text-danger text-sm fw-500">
                      {compensationErrors[idx]}
                    </div>
                  </div>
                  <Heading>
                    {/* <li>
                      Compensation can be in Cash, Products or Both. For product
                      compensation add the estimated value of the product
                    </li> */}
                    <li>
                      {campaignData?.user?.purchaser?.company} listed their
                      maximum budget for this deliverable to be{' '}
                      <span className="color-purple">
                        {' '}
                        ${formatPriceCommas(totalCashCompensation)}
                      </span>{' '}
                      in cash compensation and{' '}
                      <span className="color-purple">
                        ${formatPriceCommas(totalProductCompensation)}
                      </span>{' '}
                      in product compensation.
                    </li>
                    <li>
                      Products compensation relates to being paid "in-kind" with
                      free products or free services (rather than being paid in
                      cash). The dollar value listed is the brand's estimated
                      market value of the products or services being provided.
                    </li>
                  </Heading>
                </DeliverablesContainer>
              ))}
            </CampaignDetail>
            <CampaignDetail className="my-5">
              <CustomAmount bgColor="#F2F5F7">
                <Text
                  display="flex"
                  weight="500"
                  size="18"
                  lineHeight="25"
                  opacity="0.7"
                  textColor="#042237">
                  Total Compensation
                </Text>
                <Text
                  display="flex"
                  weight="500"
                  size="18"
                  lineHeight="25"
                  textColor="#042237">
                  ${' '}
                  <input
                    min="0"
                    max="1000000000"
                    className="compensation_input"
                    type="number"
                    value={values.total_price}
                    name="total_price"
                    disabled
                  />
                </Text>
              </CustomAmount>
              <div className="text-danger text-sm fw-500">
                {touched.total_price &&
                  errors.total_price &&
                  errors.total_price}
              </div>
            </CampaignDetail>

            <CampaignDetail className="mb-4">
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="24"
                className="form-label "
                mrBottom="6"
                textColor="#042237">
                Personal message
                <span className="text-danger ms-1"> *</span>
                <Popover
                  content={content(personalMsgPopover1, personalMsgPopover2)}
                  trigger="hover"
                  placement="right"
                  className="info-icon ms-2 mt-1 cursor-pointer">
                  <span>?</span>
                </Popover>
              </Text>
              <CustomTextArea
                rows={5}
                name="message"
                value={values.message}
                className="highlighted"
                onChange={(e) => setFieldValue('message', e.target.value)}
              />
              <div className="text-danger text-sm fw-500">
                {touched.message && errors.message && errors.message}
              </div>
            </CampaignDetail>
            <CampaignDetail className="mb-5">
              <Text
                display="flex"
                weight="700"
                size="18"
                lineHeight="24"
                mrBottom="6"
                className="form-label "
                textColor="#042237">
                Application expiration
                <span className="text-danger ms-1"> *</span>
                <Popover
                  content={content(
                    'The maximum number of days the brand has to respond to an application before it is automatically cancelled.',
                  )}
                  trigger="hover"
                  placement="right"
                  className="info-icon ms-2 mt-1 cursor-pointer">
                  <span>?</span>
                </Popover>
              </Text>
              <CustomSelectDropDown
                value={values.expiration_period}
                name="expiration_period"
                disabled
                onChange={(value) => setFieldValue('expiration_period', value)}>
                {expirationDays?.data?.map((option) => (
                  <Option value={option} key={option}>
                    {`${option} days`}
                  </Option>
                ))}
              </CustomSelectDropDown>
            </CampaignDetail>
            {/* <PaymentMethod>
              <PaymentMethodHeading>Payment method</PaymentMethodHeading>
              <Radio.Group onChange={handlePaymentMethod} value={paymentMethod}>
                <Space direction="vertical">
                  <CardProcessingRadio value={1}>
                    Visa ending in 4668 expiring 6/2022
                  </CardProcessingRadio>
                  <CardProcessingRadio value={2}>Add new card</CardProcessingRadio>
                </Space>
              </Radio.Group>
            </PaymentMethod> */}
            <PaymentModalFooter>
              {/* <div
                className="d-flex justify-content-center align-items-center text-center w-100"
                style={{padding: '0px 30px'}}>
                <Text
                  opacity="0.85"
                  weight="500"
                  size="15"
                  lineHeight="20"
                  textColor="#042237">
                  Your credit card will not be charged until the offer is officially
                  accepted. Payments are safeguarded in escrow and not released to
                  athletes until deals have been fully completed.
                </Text>
              </div> */}
              <CheckoutButton type="submit">
                <Text
                  weight="800"
                  size="18"
                  lineHeight="23"
                  textColor="#FFFFFF"
                  cursor="pointer">
                  SUBMIT APPLICATION
                </Text>
              </CheckoutButton>
            </PaymentModalFooter>
          </form>
        );
      }}
    </Formik>
  );
};

export default ApplyToCampaign;
