import React, {useEffect} from 'react';
import GetStartedSection from '../components/features/get-started';

function About() {
  useEffect(() => {
    document.querySelector('body').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="about-us-marketing">
      <div className="container pricing-section main-content">
        <div className="container center title mx-auto px-md-5 w-xl-80 mb-5 py-md-0 pb-md-5 py-md-5">
          <h2 className="py-3 pt-md-0 fw-700 header mb-0">
            We're here to supercharge the era of Athlete Empowerment
          </h2>
        </div>
      </div>
      <div className="bg-white p-md-5 p-0">
        <div className="container">
          <div className="container center title mx-auto px-3 px-md-5 mw-80 pb-5">
            <h1 className="text-dark fw-700 text-cenetr mb-5 font-xxl">
              About Us
            </h1>
            <p className="font-18 color-muted text-start mb-3">
              Founded in 2021, AthleteUp is a technology company that seeks to
              empower every athlete with all the tools necessary to take charge
              of their personal brand and business and to fully maximize the
              value of their name, image, and likeness (NIL). The company
              accomplishes this with a single platform where athletes can easily
              engage commercially with both brands and fans in a variety of
              diverse ways, while providing an unmatched value proposition to
              all parties: zero commission taken on any transaction.
            </p>
            <p className="font-18 color-muted text-start mb-3">
              By returning the financial upside to athletes while also
              consolidating a highly siloed and fragmented industry, AthleteUp
              is positioned to not simply play a role, but to completely
              supercharge the era of athlete empowerment. We invite all
              athletes, businesses, and those that follow and support them to
              sign up today and join us for the ride.
            </p>
          </div>
        </div>
      </div>
      <GetStartedSection />
    </div>
  );
}
export default About;
