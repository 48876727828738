import React, { useState, useEffect } from "react";
import { Tabs } from 'antd';
import { FilterButton } from "./style";
import Filters from "./Filters";
import CampaignCard from "./CampaignCard";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "../../../../../store/athleteflow/action";
import Loader from 'react-loader-spinner';
import { CreateCampaign } from "../../../../Purchaser/components/features/Campaigns/style";
import {CloseOutlined} from '@ant-design/icons';
import CampaignCardUnregistered from "./CampaignCardUnregistered";

const CampaignsList = ({registered}) => {
  const { campaigns, isLoading, campaignsCount } = useSelector((state) => state.AthleteModuleReducer);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const { TabPane } = Tabs;
  const [status, setStatus] = useState('open');
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch(getCampaigns({
      page: page,
      pageSize: 10,
      status: status,
      ...filters
    }));
  }, [dispatch, status, filters, page]);

  useEffect(()=> {
    setPage(1);
  },[status, filters]);

  const updateFilters = (value, key) => {
    setFilters({
      ...filters,
      [key] : value
    });
  }

  const filtersChangedMobile = (currentFilters) => {
    closeFilterModal();
    setFilters(currentFilters);
  }

  const loadMore = () => {
    if (campaignsCount > 10) {
      setPage((prev) => {
        return (prev + 1)
      });
    }
  };

  const openFilterModal = () => {
    setIsFilterModalVisible(true);
  }

  const closeFilterModal = () => {
    setIsFilterModalVisible(false);
  }

  if(registered) {
    return (
      <>
        {isFilterModalVisible && 
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '18px',
                color: '#042237',
                padding: '20px 0'
              }}>
              <span style={{textTransform: 'capitalize'}}>Filters</span>
            </div>
          }
          visible={isFilterModalVisible}
          onCancel={closeFilterModal}
          footer={null}>
          <Filters filtersChangedMobile={filtersChangedMobile} filters={filters}/>
        </CreateCampaign>
        }
        <div>
          <Tabs
          onChange={(key)=> {setStatus(key)}}
          defaultActiveKey="open"
          className="custom-tabs">
          <TabPane tab="OPEN CAMPAIGNS" key="open">
            <div className="d-none d-lg-flex">
              <Filters filtersChanged={updateFilters}/> 
            </div>
            <div className="d-flex d-lg-none">
              <FilterButton onClick={openFilterModal}>Filters</FilterButton>
            </div>
            {/* {isLoading && <div className="loader-contain">
              <div className="loader">
                <Loader
                  type="Bars"
                  color="#00BFFF"
                  height={50}
                  width={50}
                />
              </div></div>} */}
              {/* {(campaigns.length > 0) &&  */}
                <>
                  {campaigns.map(campaign => (
                  <CampaignCard campaignDetails={campaign}
                    applied={false}
                    invited={false}
                    key={campaign.id}/>))}
                  {campaignsCount > 10*page && (
                  <div className="row center">
                    <div className="col">
                      <button
                        type="button"
                        className="btn clickbtnblue view-more d-flex mx-auto align-items-center text-center  justify-content-center"
                        onClick={loadMore}>
                        {isLoading && (
                          <Loader
                            type="Bars"
                            color="#00BFFF"
                            width={20}
                            className="me-3"
                          />
                        )}
                        view more
                      </button>
                    </div>
                  </div>
                  )}
                </>
              {/* } */}
              {(!isLoading && campaigns.length === 0) && <div className="text-center title my-5">
              No open campaigns available </div>}
          </TabPane>
          <TabPane tab="APPLIED TO" key="applied">
            <div className="d-none d-lg-flex">
              <Filters filtersChanged={updateFilters}/> 
            </div>
            <div className="d-flex d-lg-none">
              <FilterButton onClick={openFilterModal}>Filters</FilterButton>
            </div>
            {/* {isLoading && <div className="loader-contain">
              <div className="loader">
                <Loader
                  type="Bars"
                  color="#00BFFF"
                  height={50}
                  width={50}
                />
              </div></div>} */}
            {/* {(!isLoading && campaigns.length > 0) &&  */}
              <>
                {campaigns.map(campaign => (
                <CampaignCard campaignDetails={campaign} 
                  applied={true}
                  invited={false}
                  key={campaign.id}/>))}
                {(campaignsCount > page * 10) && (
                  <div className="row center">
                    <div className="col">
                      <button
                        type="button"
                        className="btn clickbtnblue view-more"
                        onClick={loadMore}>
                        {isLoading && (
                          <Loader
                            type="Bars"
                            color="#00BFFF"
                            width={20}
                            className="me-3"
                          />
                        )}
                        view more
                      </button>
                    </div>
                  </div>
                )}
              </>
            {/* } */}
            {(!isLoading && campaigns.length === 0) && <div className="text-center title my-5">
              No campaigns applied to </div>}
          </TabPane>
          </Tabs>
        </div>
      </>
    );
  }
  else {
    return (<div>
      <Tabs
      defaultActiveKey="open"
      className="custom-tabs">
      <TabPane tab="OPEN CAMPAIGNS" key="open">
        {isLoading && <div className="loader-contain">
          <div className="loader">
            <Loader
              type="Bars"
              color="#00BFFF"
              height={50}
              width={50}
            />
          </div></div>}
          {(!isLoading && campaigns.length > 0) && 
            <>
              {campaigns.map(campaign => (
              <CampaignCardUnregistered campaignDetails={campaign}
                applied={false}
                invited={false}
                key={campaign.id}/>))}
            </>
          }
          {(!isLoading && campaigns.length === 0) && <div className="text-center title my-5">
          No open campaigns available </div>}
      </TabPane>
      </Tabs>
    </div>);
  }
}

export default CampaignsList;