import React, {useState, useEffect} from 'react';
import {Select} from 'antd';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import {CustomAthleteSearchOutlined} from '../../../pages/style';
import {genderOptions} from '../../../pages/constants';
import useApi from '../../../../../hooks/useApi';
import ProfileSettings from '../../../../../common/components/ProfileSettings';
import Text from '../../../../../components/Text/Text';
import {setHeader} from '../../../../../store/athleteflow/action';
import {
  handleSidebarTab,
  fetchAthleteProfile,
  fetchNewlyJoineAthletes,
} from '../../../../../store/users/actions';
import {NewlyJoinedCards} from '../../../../Purchaser/pages/style';
import {CheckboxDropDown} from '../../../components/CheckBoxDropDown';
import AppLayout from '../../../../../common/AppLayout';
import AthleteCardLayout from '../../../pages/AthleteCardLayout';
import Loader from 'react-loader-spinner';
import useDeviceSize from '../../../../../hooks/useDeviceSize';
import {RadioDropdown} from '../../../../Purchaser/components/RadioDropdown';

const Athletes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [athleteTypeOptions, setAthleteTypeOptions] = useState([]);
  const [listTypeOption, setListingTypeOptions] = useState([]);
  // const [sportsOptions, setSportsOptions] = useState([]);
  const [recommendedOption, setRecommendedOption] = useState(true);
  const [stateOptions, setStateOptions] = useState([]);
  const [lifestyleOptions, setLifestyleOptions] = useState([]);
  const [socialRangeOptions, setSocialRangeOptions] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [sportsActiveId, setSportsActiveId] = useState('all');
  const [sportsName, setSportsActiveName] = useState('All');
  const [showNewlyJoinedCard, setNewlyJoinedCards] = useState(true);
  // const [allHeader, setAllHeader] = useState(true);
  const {athletsProfile, athletesCount, isLoading, newlyJoinedCardList} =
    useSelector((state) => state.UsersReducer);
  const [athleteTypes] = useApi('/athlete/athlete-types');
  const [athleteFilters] = useApi('/athlete/athlete-filters');

  const [sportsData] = useApi('/athlete/sports?sport_count_order=true');
  const [states] = useApi('/athlete/states');
  const [lifestyles] = useApi('/athlete/lifestyle');
  const [socialReachRanges] = useApi('purchaser/campaign/social-range');
  // const [newlyJoinedCardList] = useApi(
  //   `/athlete?is_newly_joined=true&page=1&pageSize=100${
  //     sportsActiveId !== 'all' ? `&sport_ids=${sportsActiveId}` : ''
  //   }`,
  // );

  // console.log(newlyJoinedCardList, 'newlyJoinedCardList');

  const [pageValue, setPagevalue] = useState({
    pageSize: 12,
    page: 1,
    search: '',
    sort: 'recommended',
    athlete_type: [],
  });

  const size = useDeviceSize();
  // console.log(athletesCount, athletsProfile, 'athletesCount');
  const handleSearch = (e) => {
    const {value} = e.target;
    setSearch(value);
    setPagevalue({...pageValue, page: 1, search: value});
  };

  useEffect(() => {
    dispatch(fetchAthleteProfile({...pageValue}));
  }, [pageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showNewlyJoinedCard) {
      dispatch(
        fetchNewlyJoineAthletes({...pageValue, newlyJoioned: true, sort: ''}),
      );
    }
  }, [showNewlyJoinedCard, pageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (history.location.pathname === '/purchaser/athletes') {
      dispatch(setHeader(false));
      dispatch(handleSidebarTab('athletes'));
    }
  }, [history.location.pathname, dispatch]);

  useEffect(() => {
    if (history.location.pathname === '/purchaser/athletes') {
      dispatch(setHeader(false));
    }
  }, [history.location.pathname, dispatch]);

  useEffect(() => {
    if (athleteTypes?.data?.length > 0) {
      let athleteOptions = [];
      athleteTypes.data.forEach((athType) => {
        let athTypeVal = athType;
        if (athType.includes('&')) {
          /* eslint-disable-next-line */
          athTypeVal = athType.replace('&', '%26');
        }
        let athOption = {
          value: athTypeVal,
          heading: athType,
        };
        athleteOptions.push(athOption);
      });
      setAthleteTypeOptions(athleteOptions);
    }
  }, [athleteTypes]);

  // useEffect(() => {
  //   if (sportsData?.data?.rows?.length > 0) {
  //     let sportOptions = [];
  //     sportsData?.data?.rows.forEach((sport) => {
  //       sportOptions.push({
  //         value: sport?.id,
  //         heading: sport?.name,
  //       });
  //     });
  //     setSportsOptions(sportOptions);
  //   }
  // }, [sportsData]);

  useEffect(() => {
    if (athleteFilters?.data?.sort?.length > 0) {
      let ops = [];
      athleteFilters?.data?.sort.forEach((ele) => {
        ops.push({
          value: ele,
          heading: ele,
        });
      });
      setSortOptions(ops);
    }
    if (
      athleteFilters?.data?.listing_type &&
      Object.keys(athleteFilters?.data?.listing_type).length > 0
    ) {
      let ops = [];
      const filters = athleteFilters?.data?.listing_type;
      Object.keys(filters).forEach((ListHeader) => {
        let ListTypeVal = ListHeader;
        if (ListHeader.includes('%')) {
          /* eslint-disable-next-line */
          ListTypeVal = ListHeader.replace('%26', '&');
        }
        ops.push({
          value: ListHeader,
          heading: ListTypeVal,
        });
      });
      setListingTypeOptions(ops);
    }
  }, [athleteFilters]);

  useEffect(() => {
    if (socialReachRanges?.data?.length > 0) {
      let reachOptions = [];
      socialReachRanges?.data.forEach((range) => {
        let rangeVal = range.min + '-' + range.max;
        let rangeLabel = rangeVal;
        if (range.max === null) {
          rangeLabel = range.min + '+';
        }
        reachOptions.push({
          value: rangeVal,
          heading: rangeLabel,
        });
      });
      setSocialRangeOptions(reachOptions);
    }
  }, [socialReachRanges]);

  useEffect(() => {
    if (lifestyles?.data?.rows?.length > 0) {
      let styleOptions = [];
      lifestyles?.data?.rows.forEach((ele) => {
        styleOptions.push({
          value: ele?.id,
          heading: ele?.name,
        });
      });
      setLifestyleOptions(styleOptions);
    }
  }, [lifestyles]);

  useEffect(() => {
    if (states?.data?.length > 0) {
      let locOps = [];
      states?.data?.forEach((state) => {
        locOps.push({
          value: state?.key,
          heading: state?.name,
        });
      });
      setStateOptions(locOps);
    }
  }, [states]);

  const setSportsId = (sportsId) => {
    setSportsActiveId(sportsId);
    setNewlyJoinedCards(true);

    if (sportsId !== 'all') {
      setPagevalue({...pageValue, sport_ids: [sportsId]});
    } else {
      setPagevalue({...pageValue, sport_ids: 'none'});
    }
  };

  const loadMore = () => {
    if (athletesCount > pageValue.pageSize)
      setPagevalue((prev) => {
        return {
          ...pageValue,
          page: prev.page + 1,
        };
      });
  };
  const newlyJoinedCardSHow = (checkedData) => {
    // setAllHeader(false);
    if (checkedData.length > 0) {
      setNewlyJoinedCards(true);
      setRecommendedOption(true);
    }
  };
  const selectedAthleteFilter = (checkedValues) => {
    newlyJoinedCardSHow(checkedValues);
    setPagevalue({...pageValue, page: 1, athlete_type: checkedValues});
  };
  const selectedListFilter = (checkedValues) => {
    newlyJoinedCardSHow(checkedValues);
    setPagevalue({...pageValue, page: 1, listing_type: checkedValues});
  };

  // const selectedSportFilter = (checkedValues) => {
  //   setNewlyJoinedCards(false);
  //   setPagevalue({...pageValue, page: 1, sport_ids: checkedValues});
  // };

  const selectedStateFilter = (checkedValues) => {
    newlyJoinedCardSHow(checkedValues);
    setPagevalue({...pageValue, page: 1, locations: checkedValues});
  };

  const selectedGenderFilter = (checkedValues) => {
    newlyJoinedCardSHow(checkedValues);
    setPagevalue({...pageValue, page: 1, genders: checkedValues});
  };

  const selectedLifestyleFilter = (checkedValues) => {
    newlyJoinedCardSHow(checkedValues);
    setPagevalue({...pageValue, page: 1, lifestyle_ids: checkedValues});
  };

  const selectedReachFilter = (checkedValues) => {
    newlyJoinedCardSHow(checkedValues);
    setPagevalue({...pageValue, page: 1, total_social_reach: checkedValues});
  };

  const selectedSort = (selectedValue) => {
    if (selectedValue === 'recommended') {
      setNewlyJoinedCards(true);
      setRecommendedOption(true);
    } else {
      setNewlyJoinedCards(false);
      setRecommendedOption(false);
    }
    setPagevalue({...pageValue, page: 1, sort: selectedValue});
  };

  // console.log(showNewlyJoinedCard, recommendedOption, search.length);

  return (
    <AppLayout>
      {/* <div className="bubble-bg athlete-discover-bg"></div> */}
      <div className=" content-wrapper">
        <div className="d-flex align-items-center justify-content-between ">
          <div className="athletesHeading">
            <h1 className="font-xl fw-700">Athletes</h1>
            <Text
              display="flex"
              weight="600"
              size="20"
              lineHeight="28"
              smSize="16"
              smLineHeight="26"
              textColor="#435969">
              Discover the perfect athletes for your campaign. View profiles and
              send a formal offer or invite them apply to your campaign with
              just a couple clicks.
            </Text>
          </div>
          <div className="d-none d-md-block">
            <ProfileSettings myPurchase={true} />
          </div>
        </div>
        <div className="searchContainer w-100 d-flex justify-content-between">
          <div className="d-flex sort-search">
            <Select
              onChange={selectedSort}
              placeholder="Sort"
              className="select-dropdown"
              style={{marginRight: 'mr-2', backgroundColor: '#ffffff'}}>
              {sortOptions.map((option) => (
                <Select.Option value={option.value} key={option.value}>
                  {option.heading}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="d-flex search-container position-relative">
            <input
              placeholder="E.g., Name, school, team, etc."
              className="search "
              type="text"
              value={search}
              onChange={handleSearch}
            />
            <CustomAthleteSearchOutlined style={{fontSize: '20px'}} />
            {size.width < '768' && (
              <div className="d-flex ms-2 sort-checkbox">
                <RadioDropdown
                  defaultValue="recommended"
                  dropdownOptions={sortOptions}
                  optionSelected={selectedSort}
                />
              </div>
            )}
          </div>
        </div>

        {/* <div className="w-100">
          <h2>Newly Joined</h2>
          <NewlyJoinedCards>{renderNewJoinedStarCards()}</NewlyJoinedCards>
        </div> */}

        <div className="filterSort overflow-visiblehide-scrollbar">
          <div className="filter mb-3">
            <div className="filterIcon">
              <img src={backgroundStyle.filterIcon} alt="filter-Icon" />
            </div>
            <CheckboxDropDown
              defaultValue="Athlete Type"
              marginRight="mr-2"
              dropdownOptions={athleteTypeOptions}
              optionSelected={selectedAthleteFilter}
            />
            <CheckboxDropDown
              defaultValue="Listing type"
              marginRight="mr-2"
              dropdownOptions={listTypeOption}
              optionSelected={selectedListFilter}
            />
            <CheckboxDropDown
              defaultValue="Location"
              marginRight="mr-2"
              dropdownOptions={stateOptions}
              optionSelected={selectedStateFilter}
            />
            <CheckboxDropDown
              defaultValue="Gender"
              marginRight="mr-2"
              height="sm"
              dropdownOptions={genderOptions}
              optionSelected={selectedGenderFilter}
            />
            <CheckboxDropDown
              marginRight="mr-2"
              defaultValue="Lifestyle & Interests"
              dropdownOptions={lifestyleOptions}
              optionSelected={selectedLifestyleFilter}
            />

            <CheckboxDropDown
              marginRight="mr-2"
              defaultValue="Social Media Reach"
              dropdownOptions={socialRangeOptions}
              optionSelected={selectedReachFilter}
            />
          </div>
        </div>

        <div className="w-100 mt-4">
          <h2 className="font-l fw-600">Sports</h2>
          <div className="d-flex">
            <div className="sportBarContainer scroll-custom d-flex">
              <div
                className="sportTextContainer"
                key="all"
                onClick={() => {
                  setSportsId('all');
                  setSportsActiveName('All');
                }}>
                <div
                  className={`sportText  ${
                    sportsActiveId === 'all' ? 'active' : ''
                  }`}>
                  <div>All Sports</div>
                </div>
              </div>

              {sportsData?.data?.rows?.map((ele) => {
                return (
                  <div
                    className="sportTextContainer"
                    key={ele.id}
                    onClick={() => {
                      setSportsId(ele.id);
                      setSportsActiveName(ele.name);
                    }}>
                    <div
                      className={`sportText ${
                        sportsActiveId === ele.id ? 'active' : ''
                      }`}>
                      <div>{ele.name}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="d-md-flex products justify-content-start mx-auto">
          {showNewlyJoinedCard && recommendedOption && search.length === 0 && (
            <>
              {newlyJoinedCardList.length > 4 && (
                <div className="mb-5">
                  <h2 className="font-l fw-600">
                    Newly Joined - {sportsName} Sports
                  </h2>
                  <NewlyJoinedCards className="scroll-custom row flex-nowrap newly-joined-section">
                    {newlyJoinedCardList !== undefined && (
                      <AthleteCardLayout athletsProfile={newlyJoinedCardList} />
                    )}
                  </NewlyJoinedCards>
                </div>
              )}
            </>
          )}
        </div>

        <div className="products d-flex mx-auto">
          {search.length > 0 ? (
            <h2 className="mt-4 mt-md-0 fw-600">Results for "{search}"</h2>
          ) : (
            <>
              <h2 className="mt-4 mt-md-0 font-l fw-600">
                All - {sportsName === 'All' ? 'All Sports' : sportsName}
              </h2>
            </>
          )}
          <div className="row row-cols-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-2  productList">
            {/* {renderStarCards()} */}
            <AthleteCardLayout
              athletsProfile={athletsProfile}
              loading={isLoading}
            />
          </div>
        </div>
        {athletesCount > pageValue.page * pageValue.pageSize && (
          <div className="row center">
            <div className="col">
              <button
                type="button"
                className="btn clickbtnblue d-flex view-more mx-auto align-items-center text-center  justify-content-center"
                onClick={loadMore}>
                {isLoading && (
                  <Loader
                    type="Bars"
                    color="#00BFFF"
                    width={20}
                    className="me-3"
                  />
                )}
                view more
              </button>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default Athletes;
