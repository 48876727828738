import React from "react";
import Text from "../../../../../components/Text/Text";
import { CampaignDetailContainer } from "../../../../Purchaser/components/features/Campaigns/style";
import { CheckoutButton } from "../../../../Purchaser/pages/style";


const ConfirmCancelCounterOffer = ({cancelCounterOffer, dealData}) => {
  return (
    <CampaignDetailContainer className="my-3">
      <Text
        display="flex"
        weight="500"
        size="18"
        lineHeight="30"
        mrBottom="6"
        className="form-label "
        textColor="#042237">
        {(dealData?.campaign_id !== null && dealData?.counter_offers && dealData?.counter_offers.length === 1 
          && dealData?.counter_offers[0].athlete_status === 'accepted')? 
          'Please confirm that you would like to decline this application.': 
          'Please confirm that you would like to cancel this counter offer.'}
      </Text>
      <CheckoutButton onClick={cancelCounterOffer} className="mb-20-mob">
        <Text weight="800" size="18" lineHeight="23" textColor="#FFFFFF" cursor="pointer">
        {(dealData?.campaign_id !== null && dealData?.counter_offers && dealData?.counter_offers.length === 1 
          && dealData?.counter_offers[0].athlete_status === 'accepted')? 
          'Decline application': 
          'Cancel counter offer'}
        </Text>
      </CheckoutButton>
    </CampaignDetailContainer>
  );
}

export default ConfirmCancelCounterOffer;