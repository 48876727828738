export const NormailBox = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="40" viewBox="0 0 44 40" fill="none">
    <path d="M22 1.4375V38.5625" stroke="#435969" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M37.8125 12.4375V31.6875L22 38.5625L6.1875 31.6875V12.4375" stroke="#435969" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M22 1.4375L26.125 12.4375H41.9375L37.8125 4.1875L22 1.4375ZM22 1.4375L6.1875 4.1875L2.0625 12.4375H17.875L22 1.4375Z" stroke="#435969" strokeWidth="2" strokeMiterlimit="10" />
  </svg>
);
export const FlatBox = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="49" viewBox="0 0 62 49" fill="none">
    <path d="M15 10L47 28.6667" stroke="#042237" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M60.3337 20.6667L33.667 36.6667L1.66699 18" stroke="#042237" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M33.667 36.6666V47.3333" stroke="#042237" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M60.3337 20.6667V31.3333L33.667 47.3333L1.66699 28.6667V18L28.3337 2L60.3337 20.6667Z" stroke="#042237" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
);
export const Envelop = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="54" viewBox="0 0 66 54" fill="none">
    <path d="M6.24902 23.0947L31.1601 25.581L34.0575 6.05366" stroke="#435969" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M3.87358 24.7968L28.7264 49.6496C29.74 50.6632 31.045 51.4313 32.3544 51.7848C33.6637 52.1384 34.8701 52.0485 35.7082 51.5349L62.2527 35.2684C63.0908 34.7549 63.4919 33.8596 63.3678 32.7798C63.2437 31.6999 62.6045 30.5237 61.5909 29.5101L36.7382 4.65736C35.7245 3.64375 34.4195 2.87568 33.1102 2.52211C31.8008 2.16854 30.5944 2.25844 29.7563 2.77202L3.21181 19.0385C2.37371 19.5521 1.9726 20.4473 2.0967 21.5272C2.22081 22.6071 2.85997 23.7832 3.87358 24.7968Z" stroke="#435969" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
);