import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Tabs} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {setHeader} from '../../../../../store/athleteflow/action';
import ShopCard from './ShopCard';
import AppLayout from '../../../../../common/AppLayout';
import ProfileSettings from '../../../../../common/components/ProfileSettings';
import {handleSidebarTab} from '../../../../../store/users/actions';
import CampaignModalContainer from './CampaignModalContainer';
import {CustomButton, CreateCampaign} from './style';
import Text from '../../../../../components/Text/Text';
import {toastMessage} from '../../../../../helpers/toastMessage';
import {
  getCampaigns,
  handleActivateOrPauseCampaign,
  handleCreateCampaignStatus,
  handleEditCampaignStatus,
} from '../../../../../store/brandflow/action';
import Loader from 'react-loader-spinner';
import ArchiveCampaign from './ArchiveCampaign';
import useApi from '../../../../../hooks/useApi';
import StatsData from '../../../../../common/components/Stats';

const Campaigns = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('active_inactive');
  const {TabPane} = Tabs;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentCampaignData, setCampaignData] = useState({});
  const [page, setPage] = useState(1);
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [mode, setMode] = useState('create');
  const [statsData] = useApi('purchaser/campaign/deal/stats');
  const {
    createCampaignStatus,
    campaigns,
    editCampaignStatus,
    isLoading,
    campaignStatusChanged,
    campaignsCount,
  } = useSelector((state) => state.BrandModuleReducer);

  useEffect(() => {
    if (history.location.pathname === '/purchaser/campaigns') {
      dispatch(setHeader(false));
      dispatch(handleSidebarTab('campaigns'));
      dispatch(handleCreateCampaignStatus(false));
      dispatch(handleEditCampaignStatus(false));
    }
  }, [history.location.pathname, dispatch]);

  // useEffect(() => {
  //   dispatch(
  //     getCampaigns({
  //       page: page,
  //       pageSize: 10,
  //       status: 'active_inactive',
  //     }),
  //   );
  // }, [dispatch, page]);

  useEffect(() => {
    dispatch(
      getCampaigns({
        page: 1,
        pageSize: 10,
        status: filter,
      }),
    );
  }, [filter, dispatch]);

  useEffect(() => {
    if (createCampaignStatus === true) {
      toastMessage('Campaign created successfully');
      dispatch(
        getCampaigns({
          page: 1,
          pageSize: 10,
          status: filter,
        }),
      );
    }
  }, [createCampaignStatus, dispatch, filter]);

  useEffect(() => {
    if (editCampaignStatus === true) {
      toastMessage('Campaign edited successfully');
      dispatch(handleEditCampaignStatus(false));
      dispatch(
        getCampaigns({
          page: 1,
          pageSize: 10,
          status: filter,
        }),
      );
    }
  }, [editCampaignStatus, dispatch, filter]);

  useEffect(() => {
    if (campaignStatusChanged === true) {
      toastMessage('Campaign status changed');
      dispatch(handleActivateOrPauseCampaign(false));
      dispatch(
        getCampaigns({
          page: 1,
          pageSize: 10,
          status: filter,
        }),
      );
    }
  }, [campaignStatusChanged, dispatch, filter]);

  const moveToArchive = () => {
    setIsArchiveModalVisible(false);
    const payload = {
      apiPayload: {
        status: 'archived',
      },
      id: currentCampaignData.id,
    };
    dispatch(handleActivateOrPauseCampaign(payload));
  };

  const callback = (key) => {
    setFilter(key);
  };

  const openCreateCampaign = () => {
    setMode('create');
    showModal();
  };

  const openEditCampaign = (campaignData) => {
    setMode('edit');
    showModal();
    setCampaignData(campaignData);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOpenArchive = (campaignData) => {
    setIsArchiveModalVisible(true);
    setCampaignData(campaignData);
  };

  const handleCancelArchive = () => {
    setIsArchiveModalVisible(false);
  };

  const goToCampaignDetails = (campaignId) => {
    history.push(`/purchaser/campaigns/details`, {id: campaignId});
  };

  const loadMore = () => {
    if (campaignsCount > 10)
      setPage((prev) => {
        return prev + 1;
      });
  };

  const showCampaignsList = () => {
    return (
      <>
        {campaigns.length > 0 ? (
          <div className="mt-3">
            {campaigns.map((campaign) => (
              <ShopCard
                campaignData={campaign}
                key={campaign.id}
                handleOpenArchive={handleOpenArchive}
                openEditCampaign={() => {
                  openEditCampaign(campaign);
                }}
                campaignCardClick={() => {
                  goToCampaignDetails(campaign?.id);
                }}
              />
            ))}
            {campaignsCount > page * 10 && (
              <div className="row center">
                <div className="col">
                  <button
                    type="button"
                    className="btn clickbtnblue view-more  d-flex mx-auto align-items-center text-center  justify-content-center"
                    onClick={loadMore}>
                    {isLoading && (
                      <Loader
                        type="Bars"
                        color="#00BFFF"
                        width={20}
                        className="me-3"
                      />
                    )}
                    view more
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="fw-700 d-flex font-18 justify-content-center my-4 pt-5">
            No campaign to display
          </div>
        )}
      </>
    );
  };

  return (
    <AppLayout>
      {isModalVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '18px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span
                style={{
                  textTransform: 'capitalize',
                }}>{`${mode} Campaign`}</span>
            </div>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}>
          <CampaignModalContainer
            closeModal={handleCancel}
            modalView={true}
            mode={mode}
            campaignData={currentCampaignData}
          />
        </CreateCampaign>
      )}
      {isArchiveModalVisible && (
        <CreateCampaign
          style={{top: 100}}
          width={1040}
          zIndex={800}
          closeIcon={<CloseOutlined />}
          title={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '30px',
                color: '#042237',
                padding: '20px 0',
              }}>
              <span style={{textTransform: 'capitalize'}}>
                Move campaign #{currentCampaignData?.id} to archive
              </span>
            </div>
          }
          visible={isArchiveModalVisible}
          onCancel={handleCancelArchive}
          footer={null}>
          <ArchiveCampaign moveToArchive={moveToArchive} />
        </CreateCampaign>
      )}

      <div className="bubble-bg"></div>
      <div className="content-wrapper">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div>
            <div className="d-flex align-items-center">
              <Text
                display="flex"
                weight="700"
                size="55"
                lineHeight="64"
                mrRight="15"
                smSize="36"
                smLineHeight="46"
                textColor="#042237">
                Campaigns
              </Text>
              <CustomButton
                className=" btn btn-tarnparant-purple d-md-none d-block fw-800 mobile-add-listing"
                onClick={() => {
                  history.push(`/purchaser/campaigns/create`);
                }}>
                + CREATE NEW
              </CustomButton>
            </div>
            <Text
              display="flex"
              weight="600"
              size="20"
              lineHeight="28"
              smSize="16"
              smLineHeight="26"
              textColor="#435969">
              Track and manage your live campaigns
            </Text>
          </div>
          <div className="d-none d-md-block">
            <ProfileSettings myPurchase={true} />
          </div>
        </div>
        <StatsData
          {...{
            cardLabel1: 'Historical campaigns',
            cardLabel2: 'Historical deals',
            cardLabel3: 'Campaign deal payments this month',
            cardLabel4: 'Campaign deal payments all time',
            label1Data:
              Number(statsData?.data?.archived_campaigns) +
              Number(statsData?.data?.active_campaigns) +
              Number(statsData?.data?.paused_campaigns),
            label2Data: Number(statsData?.data?.historical_deals),
            labelNewCount1: statsData?.data?.active_campaigns,
            labelNewCount2: statsData?.data?.active_deals,
            label3Data: statsData?.data?.spent_this_month,
            label4Data: statsData?.data?.spent_total,
            title: 'YOUR STATS',
            type: 'campaign',
          }}
        />

        <div className="tab-shop">
          <button
            className="btn btn-tarnparant-purple d-none d-md-block btn-wide fw-800"
            onClick={openCreateCampaign}>
            + CREATE NEW CAMPAIGN
          </button>

          <Tabs
            className="custom-tabs"
            defaultActiveKey="listing"
            onChange={callback}>
            <TabPane tab="ALL CAMPAIGNS" key="active_inactive">
              {showCampaignsList()}
            </TabPane>
            <TabPane tab="PRIVATE" key="private">
              {showCampaignsList()}
            </TabPane>
            <TabPane tab="OPEN" key="open">
              {showCampaignsList()}
            </TabPane>
            <TabPane tab="HISTORY" key="history">
              {showCampaignsList()}
            </TabPane>
          </Tabs>
        </div>
      </div>
    </AppLayout>
  );
};

export default Campaigns;
