import React from 'react';
import ProfileSettings from './ProfileSettings';

const AppHeader = (props) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        <h1 className={`header ${props?.headerClass ? props.headerClass : ''}`}>
          {props.title}
        </h1>
        {props.subTitle}
      </div>
      <div className="d-none d-md-block">
        <ProfileSettings />
      </div>
    </div>
  );
};

export default AppHeader;
