import React from 'react';

const PaymnetSuccess = (props) => {
  const {title, subText, btnText, icons, redirectionLink} = props;
  return (
    <div className="register-main mh-100-vh d-flex align-items-center justify-content-center">
      <div className="row msg-section">
        <div className="col text-center">
          <div className="bg-circle mx-auto">
            <div>
              <img
                className="msgicon"
                alt="msgicon"
                src={icons.tickColorIcon}
              />
            </div>
            {/* <div className="smallcircle text-center">1</div> */}
          </div>
          <div className="title">{title}</div>
          <div className="mw-687 mx-auto">
            <div className="texts mt-4 px-3 px-md-0">{subText}</div>
          </div>
          <div className="col-11 col-sm-7 col-md-7 col-lg-7 col-xl-5 col-xxl-4 mt-5 mx-auto">
            <a
              type="button"
              className="btn clickbtn text-uppercase d-flex align-items-center justify-content-center"
              href={redirectionLink}
              // onClick={() => emailSuccessMessage()}
              // onClick={() => setsuccessMain(!successMain)}
            >
              {btnText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymnetSuccess;
