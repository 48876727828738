import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {Switch} from 'react-router-dom';

import {PrivateRoute} from '../../..';
import AppLayout from '../../../../../common/AppLayout';
import {setHeader} from '../../../../../store/athleteflow/action';
import ManageDeals from './ManageDeals';
import '../../../../../assets/scss/_deals.scss';
import DealDetails from './DealDetails';
import {handleSidebarTab} from '../../../../../store/users/actions';

const Deals = () => {
  const dispatch = useDispatch();
  let {path} = useRouteMatch();

  useEffect(() => {
    dispatch(setHeader(false));
    dispatch(handleSidebarTab('deals'));
  }, [dispatch]);

  return (
    <AppLayout contentClassname="campaign-layout">
      <Switch>
        <PrivateRoute exact path={path} component={() => <ManageDeals />} />
        <PrivateRoute
          exact
          path={`${path}/:id`}
          component={() => <DealDetails />}
        />
      </Switch>
    </AppLayout>
  );
};

export default Deals;
