import styled from 'styled-components';
import {Tabs} from 'antd';

export const CustomTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #7740fe;
    height: 6px;
  }
  .ant-tabs-nav-list {
    justify-content: space-between;
    font-weight: 500;
    @media screen and (min-width: 992px) {
      width: 100%;
    }
  }
  .ant-tabs-nav {
    // margin: 0px 35px 20px 0px;
    margin: 0;
    border-bottom: solid 1px #c4c4c4;
    .ant-tabs-tab {
      // padding: 10px 0px;
      color: #435969;
      line-height: 30px;
      // font-size:20px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #042237;
      font-weight: 700;
    }

    .ant-tabs-tab-btn:active {
      color: #042237;
    }
  }
  .ant-tabs-nav::before {
    border-bottom: 0px !important;
  }
`;
