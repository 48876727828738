import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import AppHeader from '../../../../../common/components/AppHeader';
import {getStripeStatus} from '../../../../../store/athleteflow/action';
import {backgroundStyle} from '../../../../../utils/staticStrings';
import {CardTheme} from '../../style';
import CampaignsList from './CampaignsList';
import InvitedCampaigns from './InvitedCampaigns';

const DiscoverCampaigns = () => {
  const {invitedCampaigns, campaignsCount, stripeStatus, membeShipExpired} =
    useSelector((state) => state.AthleteModuleReducer);
  const [registered, setRegistered] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getStripeStatus());
  }, [dispatch]);

  useEffect(() => {
    if (campaignsCount && counter === 0) {
      const cc = campaignsCount;
      setTotalCount(cc);
      setCounter(1);
    }
  }, [campaignsCount, counter]);

  useEffect(() => {
    if (Object.keys(stripeStatus).length > 0) {
      if (stripeStatus?.subscription_valid === true) {
        setRegistered(true);
      } else {
        setRegistered(false);
      }
    }
  }, [stripeStatus]);

  return (
    <div className="content-wrapper">
      {Object.keys(stripeStatus).length > 0 && (
        <>
          {registered && (
            <>
              <AppHeader
                title="Campaign Discovery"
                subTitle={
                  <>
                    <div className="font-20 color-muted mb-5 mw-570 fw-600 d-block d-lg-none">
                      You have{' '}
                      <span className="text-purple">
                        {' '}
                        {invitedCampaigns.length}{' '}
                      </span>{' '}
                      new{' '}
                      {invitedCampaigns.length === 1 ? 'campaign' : 'campaigns'}{' '}
                      that personally invited you to apply and
                      <span className="text-purple"> {totalCount} </span>
                      new {totalCount.length === 1
                        ? 'campaign'
                        : 'campaigns'}{' '}
                      that you're eligible to apply for.
                    </div>
                    <div className="font-20 color-muted mb-5 fw-600 d-none d-lg-block">
                      You have{' '}
                      <span className="text-purple">
                        {' '}
                        {invitedCampaigns.length}
                      </span>{' '}
                      new{' '}
                      {invitedCampaigns.length === 1 ? 'campaign' : 'campaigns'}{' '}
                      that personally invited you to apply and
                      <div>
                        <span className="text-purple"> {totalCount} </span>
                        new {totalCount.length === 1
                          ? 'campaign'
                          : 'campaigns'}{' '}
                        that you're eligible to apply for.
                      </div>
                    </div>
                  </>
                }
              />
              <InvitedCampaigns />
              <img
                src={'/images/ellipse.svg'}
                alt="Decorative"
                width="270"
                height="970"
                className="cam-ellipse-img d-block d-lg-none"
              />
              <CampaignsList registered={registered} />
            </>
          )}
          {!registered && (
            <>
              <AppHeader
                title="Campaign Discovery"
                subTitle={
                  <>
                    <div className="font-20 color-muted mb-5 mw-570 fw-600 d-block d-lg-none">
                      Discover exclusive sponsorship campaigns that you can
                      apply to with just a few clicks.
                    </div>
                    <div className="font-20 color-muted mb-5 fw-600 d-none d-lg-block">
                      Discover exclusive sponsorship campaigns that you can
                      apply to with just a few clicks.
                    </div>
                  </>
                }
              />
              {(membeShipExpired ||
                (stripeStatus?.subscription_valid === false &&
                  stripeStatus?.stripe_account_valid === false)) && (
                <CardTheme
                  bgUrl={backgroundStyle.themeBg}
                  bgUrlMob={backgroundStyle.themeBgMob}>
                  <div className="ct-left">
                    <div className="ct-title">
                      {membeShipExpired
                        ? 'Reactivate your membership'
                        : 'Start your free trial'}
                    </div>
                    <div className="ct-content">
                      You meet the target criteria to apply to
                      <span className="text-purple">
                        <strong> {campaignsCount} </strong>
                      </span>
                      open campaigns exclusive to AthleteUp.
                      {membeShipExpired
                        ? ' Reactivate your membership today to publish your Shop and start landing deals'
                        : ' Start your trial today to apply and start landing deals'}
                      .
                    </div>
                  </div>
                  <div className="ct-btn-contain">
                    <button
                      className={`btn btn-primary ms-auto ct-button ${
                        membeShipExpired ? 'text-nowrap' : ''
                      }`}
                      onClick={() => {
                        history.push('/athlete/price-details');
                      }}
                      type="primary">
                      {membeShipExpired
                        ? 'REACTIVATE MEMBERSHIP'
                        : 'Start Trial'}
                    </button>
                  </div>
                </CardTheme>
              )}

              <img
                src={'/images/ellipse.svg'}
                alt="Decorative"
                width="270"
                height="970"
                className="cam-ellipse-img d-block d-lg-none"
              />
              <CampaignsList registered={registered} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DiscoverCampaigns;
