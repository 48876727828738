import React, {useEffect, useState} from 'react';
import Text from '../../../../../components/Text/Text';
import {CustomCampaignInviteCard} from './styled';
import {SendInviteButton} from '../Campaigns/style';
import {
  CheckoutButton,
  DeskTopLayoutView,
  MobileLayoutViewView,
} from '../../../pages/style';
import {useDispatch, useSelector} from 'react-redux';
import {
  getCampaigns,
  inviteAthleteToCampaign,
} from '../../../../../store/brandflow/action';
import CampaignCard from './CampaignCard';
import Loader from 'react-loader-spinner';

const CampaignInvite = ({
  type = '',
  showSubmitOfferModal,
  athleteId,
  closeCampaignInvite,
  changeTaggedStatus,
}) => {
  const [selectedCard, setSelectedCard] = useState('');
  const [currentCampaignData, setCurrentCampaignData] = useState({});
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const {campaigns, isLoading, campaignsCount} = useSelector(
    (state) => state.BrandModuleReducer,
  );

  useEffect(() => {
    dispatch(
      getCampaigns({
        page: page,
        pageSize: 10,
        status: 'active',
      }),
    );
  }, [dispatch, page]);

  useEffect(() => {
    if (type === 'invite' && campaigns?.length > 0) {
      setSelectedCard(campaigns[0].id);
    }
  }, [campaigns, type]);

  const sendCampaignInvite = () => {
    const payload = {
      athlete_user_id: athleteId,
    };
    dispatch(inviteAthleteToCampaign(payload, selectedCard));
    closeCampaignInvite();
  };

  const createUnTaggedOffer = () => {
    changeTaggedStatus(false);
    showSubmitOfferModal();
  };

  const selectCard = (campaignData) => {
    if (type === 'invite') {
      setSelectedCard(campaignData.id);
    } else if (type === 'offer') {
      setSelectedCard(campaignData.id);
      setCurrentCampaignData(campaignData);
      changeTaggedStatus(true);
    }
  };

  const loadMore = () => {
    if (page * 10 < campaignsCount) {
      setPage((prev) => {
        return prev + 1;
      });
    }
  };

  return (
    <>
      <DeskTopLayoutView>
        {type === 'offer' && (
          <div className="d-flex align-items-center justify-content-center mb-3">
            <Text
              display="flex"
              weight="500"
              size="18"
              smSize="16"
              smLineHeight="22"
              lineHeight="25"
              textColor="#042237">
              Select and customize a deal offer related to an existing active
              campaign
            </Text>
          </div>
        )}
      </DeskTopLayoutView>

      <CustomCampaignInviteCard>
        <DeskTopLayoutView>
          {isLoading && (
            <div className="loader-contain">
              <div className="loader">
                <Loader type="Bars" color="#00BFFF" height={50} width={50} />
              </div>
            </div>
          )}
          {!isLoading && (
            <>
              {campaigns?.length === 0 && (
                <div className="text-center title my-3">
                  No active campaigns available
                </div>
              )}
              {campaigns.map((campaignData) => (
                <CampaignCard
                  screen="desktop"
                  key={campaignData.id}
                  campaignData={campaignData}
                  isActive={selectedCard === campaignData.id}
                  selectCard={() => {
                    selectCard(campaignData);
                  }}
                />
              ))}
              {page * 10 < campaignsCount && (
                <div className="row center">
                  <div className="col">
                    <button
                      type="button"
                      className="btn clickbtnblue view-more"
                      onClick={loadMore}>
                      view more
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </DeskTopLayoutView>
        <MobileLayoutViewView>
          {type === 'offer' && (
            <div className="d-flex align-items-center justify-content-center mb-3">
              <Text
                display="flex"
                weight="500"
                size="18"
                smSize="16"
                smLineHeight="22"
                lineHeight="25"
                textColor="#042237">
                <span className="text-center">
                  Select and customize a deal offer related to an existing
                  active campaign
                </span>
              </Text>
            </div>
          )}
          {isLoading && (
            <div className="loader-contain">
              <div className="loader">
                <Loader type="Bars" color="#00BFFF" height={50} width={50} />
              </div>
            </div>
          )}
          {!isLoading && (
            <>
              {campaigns?.length === 0 && (
                <div className="text-center title my-3">
                  No active campaigns available
                </div>
              )}
              {campaigns.map((campaignData) => (
                <CampaignCard
                  screen="mobile"
                  key={campaignData.id}
                  campaignData={campaignData}
                  isActive={selectedCard === campaignData.id}
                  selectCard={() => {
                    selectCard(campaignData);
                  }}
                />
              ))}
              {page * 10 < campaignsCount && (
                <div className="row center">
                  <div className="col">
                    <button
                      type="button"
                      className="btn clickbtnblue view-more"
                      onClick={loadMore}>
                      view more
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </MobileLayoutViewView>

        {type === 'invite' && (
          <div className="d-flex justify-content-center">
            <SendInviteButton>
              <CheckoutButton
                disabled={campaigns?.length === 0}
                onClick={sendCampaignInvite}>
                <Text
                  weight="800"
                  cursor="pointer"
                  size="18"
                  lineHeight="23"
                  textColor="#FFFFFF">
                  INVITE TO APPLY
                </Text>
              </CheckoutButton>
            </SendInviteButton>
          </div>
        )}
        {type === 'offer' && (
          <>
            <div
              className="d-flex justify-content-center"
              onClick={() => {
                showSubmitOfferModal(currentCampaignData);
              }}>
              <SendInviteButton>
                <CheckoutButton>
                  <Text
                    weight="800"
                    size="18"
                    lineHeight="23"
                    cursor="pointer"
                    textColor="#FFFFFF">
                    NEXT
                  </Text>
                </CheckoutButton>
              </SendInviteButton>
            </div>
            <div
              className="d-flex align-items-center justify-content-center my-3"
              onClick={createUnTaggedOffer}>
              <Text
                display="flex"
                weight="500"
                size="18"
                smSize="16"
                cursor="pointer"
                smLineHeight="22"
                lineHeight="25"
                textColor="#7741fe">
                <u>
                  or create a custom deal offer not currently tagged to an
                  active campaign
                </u>
              </Text>
            </div>
          </>
        )}
      </CustomCampaignInviteCard>
    </>
  );
};

export default CampaignInvite;
