import React from 'react';
import {useRouteMatch, Switch, Route, Redirect} from 'react-router-dom';
// import Login from './pages/login';
// import Register from './components/features/Register';
// import Profile from './components/features/Profile';
// import ForgotPassword from './components/features/ForgotPassword';
// import ResetPassword from './components/features/ResetPassword';
import Dashboard from './components/features/Dashboard';

const isAuthenticated = () => {
  if (localStorage.getItem('athletUp-token') !== null) {
    return true;
  } else {
    localStorage.clear();
    return false;
  }
};

const PrivateRoute = ({component: Component, ...rest}) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

// const PublicRoute = ({ component: Component, restricted, ...rest }) => {
//   return (
//     // restricted = false meaning public route
//     // restricted = true meaning restricted route
//     <Route {...rest} render={props => (
//       isAuthenticated() && restricted ?
//         <Redirect to="/brand/dashboard" />
//         : <Component {...props} />
//     )} />
//   );
// };

const Brand = () => {
  let {path} = useRouteMatch();

  return (
    <div>
      <Switch>
        {/* <PublicRoute exact path={path} component={() => <Login />} />
        <PublicRoute path={`${path}/register`} component={() => <Register />} /> */}
        <PrivateRoute
          path={`${path}/dashboard`}
          component={() => <Dashboard />}
        />
        <Route exact path="/">
          <Redirect to="/brand" />
        </Route>
        <Redirect path="*" to="/" />
      </Switch>
    </div>
  );
};

const BrandModule = {
  routeProps: {
    path: '/brand',
    exact: false,
    component: Brand,
  },
  name: 'Brand',
};

export default BrandModule;
