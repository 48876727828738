/**
 *
 * Show
 *
 */

// {
/* <Show show={loading}>
<Center className="mt-2">
  <Spinner />
</Center>
</Show>
<Show show={!loading}>
<P className="mb-2">
  <IntlMessage
	messageKey="hiredOrUnsuited"
	values={{
	  candidateCount:
		candidateCount > 1 ? (
		  <CandidateCount {...{ candidateCount }} />
		) : (
		  <strong>{`${candidateName}: ${candidateId}`}</strong>
		),
	  suitedOrUnsuited: unsuited ? 'unsuited' : 'hired',
	}}
  />
</P>
<ModalContainer
  {...{
	radioSelectOption,
	candidateCount,
	unsuited,
	emailTemplates,
	currentTemplate,
	isEmailTemplateSent,
	setState,
	showDoNotSendMailButton,
  }}
/>
</Show> */
// }

import {node, any} from 'prop-types';

export const Show = ({show, children}) => Boolean(show) && children;

Show.propTypes = {
  show: any,
  children: node,
};

export default Show;
