import React, {useEffect, useState} from 'react';
import {NavLink, useLocation, useHistory} from 'react-router-dom';
import './header.scss';
import './whiteHeader.scss';
import {Hr} from '../../components/Hr';
import {CloseOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {headerSection, backgroundStyle} from '../../utils/staticStrings';
import Text from '../../components/Text/Text';
import {logoutData} from '../../store/users/actions';
import http from '../../helpers/http';

const Header = () => {
  const headerLocation = useLocation();
  const [showHeader, setShowHeader] = useState(false);
  const [showWhiteHeader, setWhiteShowHeader] = useState(true);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [current, setCurrent] = useState('fan');
  const [loggedIn, setLoggedIn] = useState(false);
  const {header} = useSelector((state) => state.AthleteModuleReducer);
  const {purchaserDetails} = useSelector((state) => state.UsersReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem('athletUp-token');

  useEffect(() => {
    if (token !== undefined && token !== null) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    const userRole = localStorage.getItem('userRole');
    setCurrent(userRole);
  }, [token]);

  const logout = () => {
    dispatch(logoutData());
  };

  const goToDashboard = () => {
    const token = localStorage.getItem('athletUp-token');

    if (token !== undefined && token !== null) {
      if (current === 'brand' || current === 'fan') {
        history.push('/purchaser/dashboard');
      } else if (current === 'athlete') {
        http.get('/account/account-validity').then((res) => {
          if (res && res?.data && res?.data?.data) {
            const stripeActValid = res?.data?.data?.stripe_account_valid;
            const subscriptionValid = res?.data?.data?.subscription_valid;
            if (stripeActValid && subscriptionValid) {
              history.push('/athlete/dashboard');
            } else {
              history.push('/athlete/my-shop');
            }
          }
          // console.log("Res", res);
        });
      }
    } else {
      history.push('/');
    }
  };

  const renderHeader = () => {
    let pathArray = headerLocation.pathname.split('/').filter((item) => {
      return item.length > 0;
    });

    if (
      pathArray[0] === undefined ||
      pathArray[0] === 'brands' ||
      pathArray[0] === 'pricing' ||
      pathArray[0] === 'about' ||
      pathArray[0] === 'terms-service' ||
      pathArray[0] === 'privacy-policy'
    ) {
      setWhiteShowHeader(false);
      setShowHeader(true);
    } else {
      if (pathArray[1] === 'account-details') {
        setWhiteShowHeader(false);
        setShowHeader(false);
      } else {
        setWhiteShowHeader(true);
        setShowHeader(true);
      }
    }
    if (
      headerLocation.pathname.indexOf('/athlete') > -1 ||
      headerLocation.pathname.indexOf('/login') > -1 ||
      headerLocation.pathname.indexOf('/checkout') > -1 ||
      headerLocation.pathname.indexOf('/payment') > -1 ||
      headerLocation.pathname.indexOf('/email-verification') > -1 ||
      headerLocation.pathname.indexOf('/purchaser/register') > -1
    ) {
      setShowHeader(false);
    }
  };

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const userNavigate = () => {
    setNavbarOpen(false);
  };

  useEffect(() => {
    renderHeader();
  }, [headerLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  const goToHome = () => {
    history.push('/');
  };

  if (!header) {
    return null;
  }

  if (showHeader) {
    return (
      <div
        className={`${
          showWhiteHeader ? 'white-header-style' : 'header-style'
        }`}>
        <nav className="navbar navbar-expand-lg navbar-dark navbar-wider">
          <div className="container">
            <span className="navbar-brand" onClick={userNavigate}>
              <div onClick={goToHome} className="cursor-pointer">
                {showWhiteHeader ? (
                  <img
                    alt="headerlogo"
                    src={headerSection.headerLogoBlack}
                    className="d-inline-block align-top"
                  />
                ) : (
                  <img
                    alt="headerlogo"
                    src="/images/headerlogo.svg"
                    className="d-inline-block align-top"
                  />
                )}
              </div>
            </span>
            <button
              aria-controls="basic-navbar-nav"
              type="button"
              aria-label="Toggle navigation"
              onClick={handleToggle}
              className="navbar-toggler collapsed">
              {navbarOpen ? (
                <>
                  {showWhiteHeader ? (
                    <CloseOutlined />
                  ) : (
                    <img src={backgroundStyle.closeWhite} alt="Close" />
                  )}
                </>
              ) : (
                <>
                  {showWhiteHeader ? (
                    <img src={backgroundStyle.customMobileMenu} alt="Menu" />
                  ) : (
                    <img src={backgroundStyle.customNav} alt="Menu" />
                  )}
                </>
              )}
            </button>
            <div
              className={`justify-content-end navbar-collapse collapse ${
                navbarOpen ? ' show' : ''
              } `}
              id="basic-navbar-nav">
              <div className="navbar-nav">
                <NavLink
                  className={`${
                    showWhiteHeader
                      ? 'white-nav-texts  white-nav-link '
                      : 'nav-texts nav-link'
                  }`}
                  to="/purchaser"
                  activeClassName="active"
                  onClick={userNavigate}>
                  {headerSection.headerNavText1}
                  <div className="highlight-link"></div>
                </NavLink>
                <NavLink
                  className={`${
                    showWhiteHeader
                      ? 'white-nav-texts  white-nav-link '
                      : 'nav-texts nav-link'
                  }`}
                  to="/brands"
                  activeClassName="active"
                  onClick={userNavigate}>
                  {headerSection.headerNavText2}
                  <div className="highlight-link"></div>
                </NavLink>
                <NavLink
                  className={`${
                    showWhiteHeader
                      ? 'white-nav-texts  white-nav-link '
                      : 'nav-texts nav-link'
                  }`}
                  to="/pricing"
                  activeClassName="active"
                  onClick={userNavigate}>
                  {headerSection.headerNavText3}
                  <div className="highlight-link"></div>
                </NavLink>
                <a
                  role="button"
                  className={`${
                    showWhiteHeader
                      ? 'white-nav-texts  white-nav-link '
                      : 'nav-texts nav-link'
                  }`}
                  target="_self"
                  href="https://blog.athleteup.com">
                  BLOG
                </a>
                {loggedIn ? (
                  <div
                    onClick={logout}
                    className={`${
                      showWhiteHeader
                        ? 'white-nav-texts  log-in-text'
                        : 'nav-texts log-in-text'
                    } cursor-pointer`}>
                    LOG OUT
                  </div>
                ) : (
                  <NavLink
                    className={`${
                      showWhiteHeader
                        ? 'white-nav-texts  log-in-text'
                        : 'nav-texts log-in-text'
                    }`}
                    to="/login">
                    {headerSection.headerNavText5}
                  </NavLink>
                )}
              </div>
              {loggedIn ? (
                <div onClick={goToDashboard}>
                  <button className="signbtn">MY DASHBOARD</button>
                </div>
              ) : (
                <NavLink to="/athlete/register">
                  {' '}
                  <button className="signbtn">SIGN UP</button>
                </NavLink>
              )}
            </div>
          </div>
        </nav>
      </div>
    );
  } else {
    return (
      <div className="header-style header-light header-fixed">
        <nav className="navbar navbar-expand-md navbar-light bg-white">
          <span className="ms-3 ms-md-5 navbar-brand w-100">
            {!(
              headerLocation?.pathname.includes('/checkout') ||
              headerLocation?.pathname.includes('/payment')
            ) && (
              <div onClick={goToHome} className="cursor-pointer">
                <img
                  alt="headerlogo"
                  src={headerSection.headerLogoBlack}
                  className="d-inline-block align-top saras"
                />
              </div>
            )}

            {(headerLocation?.pathname.includes('/checkout') ||
              headerLocation?.pathname.includes('/payment')) && (
              <>
                <div className="d-flex justify-content-between align-items-center container p-0">
                  <div className="d-flex">
                    <div onClick={goToHome}>
                      <img
                        alt="headerlogo"
                        src={headerSection.headerLogoBlack}
                        className="d-inline-block align-top athlete-logo"
                      />
                    </div>
                  </div>
                  <Text
                    weight="600"
                    size="16"
                    lineHeight="21"
                    display="flex"
                    textColor="#042237">
                    Logged in as {purchaserDetails?.name}
                  </Text>
                </div>
              </>
            )}
          </span>
        </nav>
        {(headerLocation?.pathname.includes('/checkout') ||
          headerLocation?.pathname.includes('/payment')) && (
          <Hr classNames="d-none d-md-block" style={{marginBottom: '10px'}} />
        )}
      </div>
    );
  }
};

export default Header;
