import React, {useEffect} from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {loginSection} from '../../../../../utils/staticStrings';
import {
  submitResetPassword,
  handleMessage,
} from '../../../../../store/athleteflow/action';
import PasswordInput from '../../../../../common/components/PasswordInput';
import * as yup from 'yup';
import '../features.scss';
import {
  errorToastMessage,
  toastMessage,
} from '../../../../../helpers/toastMessage';

let schema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    )
    .required('New Password is Required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ForgotPassword = () => {
  const {push} = useHistory();
  const dispatch = useDispatch();
  const search = useLocation().search;
  let encryptedKey = new URLSearchParams(search).get('encryptedKey');
  const {message} = useSelector((state) => state.AthleteModuleReducer);

  useEffect(() => {
    if (message.text.success === true) {
      toastMessage(message.text.data);
      dispatch(handleMessage());
      push('/login');
    }
    if (message.text.success === false) {
      errorToastMessage(message.text.message.message);
    }
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  const forgotPassword = async (values) => {
    try {
      const payload = {
        encrypted_key: encryptedKey,
        password: values.password,
      };
      dispatch(submitResetPassword(payload));
    } catch (err) {
      console.log(err, 'OOOOO');
    }
  };

  return (
    <div className="login-main-bg">
      <div className="padding-main bg-white">
        <div className="login-main">
          <div className="row login-section">
            <div className="col-12 col-sm-10 col-md-10 col-lg-5 col-xl-5 col-xxl-6 me-lg-3">
              <div className="auth-holder mx-auto">
                <div className="login-title mb-5">Reset password?</div>
                <Formik
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                  }}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    console.log(values);
                    forgotPassword(values);
                  }}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                          <PasswordInput
                            name="password"
                            changeEvent={handleChange}
                            value={values.password}
                            blurEvent={handleBlur}
                            placeHolder="New Password"
                          />
                          {/* <input type="email" className="form-control" value={values.email} type="text" name="email" id="floatingInput" onChange={handleChange} placeholder="name@example.com" /> */}
                          <label htmlFor="floatingInput">New Password</label>
                          <div className="text-danger text-sm">
                            {touched.password &&
                              errors.password &&
                              errors.password}
                          </div>
                        </div>
                        <div className="form-floating mb-3">
                          <PasswordInput
                            name="confirmPassword"
                            changeEvent={handleChange}
                            value={values.confirmPassword}
                            blurEvent={handleBlur}
                            placeHolder="Confirm Password"
                          />
                          <label htmlFor="floatingInput">
                            Confirm Password
                          </label>
                          <div className="text-danger text-sm">
                            {touched.confirmPassword &&
                              errors.confirmPassword &&
                              errors.confirmPassword}
                          </div>
                        </div>
                        <div className="bottom-pos-btn-container mt-5">
                          <button type="submit" className="btn clickbtn">
                            RESET PASSWORD
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
                <div className="py-4">
                  <Link
                    to="/athlete"
                    className="d-flex text-muted text-center justify-content-center no-underline ms-3">
                    Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-10 col-lg-6 col-xl-6 col-xxl-5 mt-5 mt-sm-0 d-none d-md-block">
              <div className="bg ms-0 ms-xl-5 mt-5 mt-xl-0">
                <img
                  className="boy-image"
                  src={loginSection.imgrightbg}
                  alt="mikebg"
                />
                <div className="info-block">
                  <div className="nameage">{loginSection.nameage}</div>
                  <div className="playertitle">{loginSection.playertitle}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
