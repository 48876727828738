import React, { useEffect } from 'react';
import ImageButtonLinkSection from '../components/features/banner-details-section';
import HowItWorksSection from '../components/features/how-it-works'
import GetStartedSection from '../components/features/get-started'
import RightImgDetailSection from '../components/features/right-img-details-section'
import LeftImageDetailSection from '../components/features/left-img-details-section'
import { discoverMatchSection , campaignEnableSection,howItWorksSectionBrands} from '../../../utils/staticStrings';
import BrandMarketingSection from '../components/features/brand-marketing'

function Brands() {
  const onClickLearnMore = () => {
    const id = 'how-it-works';
    const yOffset = -100; 
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    document.querySelector('body').scrollTo({top: y, behavior: 'smooth'});
  }

  useEffect(()=> {
    document.querySelector('body').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className='main-content'>
      <div className="page-contain">
        <div  className="padding-main">
          <ImageButtonLinkSection showBrands={true} onLearnMoreClick={onClickLearnMore}/>
        </div>
        <div  className="padding-main">
          <HowItWorksSection data={howItWorksSectionBrands}/>
        </div>
        <div  className="padding-main">
          <RightImgDetailSection data={discoverMatchSection}/>
        </div>
        <div  className="padding-main">
          <LeftImageDetailSection data={campaignEnableSection} noBg={true}/>
        </div>
        <div  className="padding-main">
          <BrandMarketingSection/> 
        </div>
      </div>
      <GetStartedSection brand={true}/>
    </div>
  );
}
export default Brands;
