export const Tag = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8677 17.845L17.8443 11.8683C17.9992 11.7135 18.122 11.5298 18.2058 11.3275C18.2896 11.1253 18.3327 10.9085 18.3327 10.6895C18.3327 10.4706 18.2896 10.2538 18.2058 10.0516C18.122 9.84933 17.9992 9.66557 17.8443 9.51079L9.99935 1.66663H1.66602V9.99996L9.51018 17.8458C9.66501 18.0006 9.84882 18.1233 10.0511 18.2071C10.2534 18.2908 10.4702 18.3338 10.6891 18.3338C10.908 18.3337 11.1248 18.2905 11.327 18.2066C11.5292 18.1227 11.713 17.9999 11.8677 17.845V17.845Z"
      stroke="#042237"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66667 8.33333C7.58714 8.33333 8.33333 7.58714 8.33333 6.66667C8.33333 5.74619 7.58714 5 6.66667 5C5.74619 5 5 5.74619 5 6.66667C5 7.58714 5.74619 8.33333 6.66667 8.33333Z"
      stroke="#042237"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BackArrow = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.00039 11.4L7.40039 10L3.40039 6.00001L7.40039 2.00001L6.00039 0.600006L0.600391 6.00001L6.00039 11.4Z"
      fill="#7741FE"
    />
  </svg>
);

export const Clock = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.75 18.5C14.5825 18.5 18.5 14.5825 18.5 9.75C18.5 4.91751 14.5825 1 9.75 1C4.91751 1 1 4.91751 1 9.75C1 14.5825 4.91751 18.5 9.75 18.5Z"
      stroke="#042237"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 4.75V9.75H14.75"
      stroke="#042237"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Dollar = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0007 19.1666C15.0633 19.1666 19.1673 15.0626 19.1673 9.99998C19.1673 4.93737 15.0633 0.833313 10.0007 0.833313C4.93804 0.833313 0.833984 4.93737 0.833984 9.99998C0.833984 15.0626 4.93804 19.1666 10.0007 19.1666Z"
      stroke="#042237"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 4.16663V15.8333"
      stroke="#042237"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8138 6.0158C11.463 5.2733 7.20797 4.71913 7.20797 7.27663C7.20797 10.3383 12.6113 9.25746 12.6113 11.9591C12.6113 14.6608 9.09964 14.4575 6.66797 13.58"
      stroke="#042237"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Tick = () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 5L5 9L13 1"
      stroke="#435969"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const GreenTick = () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 5L5 9L13 1"
      stroke="#31BF83"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
