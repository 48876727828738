import React from 'react';
import {Input} from 'antd';
import {Formik} from 'formik';

const Requirements = (props) => {
  const {setListingData, prev, next, physicalProduct, listingData} = props;
  const {TextArea} = Input;

  return (
    <div className="overview">
      <h1 className="fw-bold text-center">Requirements</h1>
      <p className="mb-5 color-muted text-center title mx-auto mw-570">
        Request any additional information that you will need from the purchaser
        so that you can fulfill the order.
      </p>
      <div className="mt-5 mb-3 overview mw-570 mx-auto">
        <Formik
          initialValues={{
            purchaser_requirements: listingData?.purchaser_requirements
              ? listingData?.purchaser_requirements
              : '',
          }}
          onSubmit={(values) => {
            setListingData(values);
            next();
          }}>
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            errors,
            touched,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="form-group text-start mb-4">
                  <h6 className="form-label color-dark">
                    What do you need to get started?
                  </h6>
                  <TextArea
                    className="form-control"
                    name="purchaser_requirements"
                    rows="3"
                    maxLength={300}
                    value={values.purchaser_requirements}
                    onChange={handleChange}
                    placeholder=""
                    // placeholder="E.g., request the purchaser provide a few dates and times that would work for your video call"
                  />
                </div>
                {listingData.category_id !== 4 && (
                  <>
                    <ul className="text-muted text-sm opacity-50 ps-3 lh-m">
                      <li className="mb-2">
                        The requirements will be displayed on the purchase page
                        and will allow the buyer to fill this information out
                        when buying the product.
                      </li>
                      {listingData.category_id !== 5 && (
                        <>
                          <li className="mb-2">
                            For example, for a virtual or in-person experience
                            such as lessons or a meet & greet, request purchaser
                            provide multiple day/time options that would work
                            best for them to coordinate schedules.
                          </li>
                          <li className="mb-2">
                            For example, for a listing with a deliverable of a
                            personalized video message or training tips, request
                            additional details to help you successfully fulfill
                            the request (e.g.,"Who is the video message for and
                            What is the occasion?" or "What skills are you most
                            interested in improving?").
                          </li>
                        </>
                      )}
                      <li>
                        If you do not require any additional information to
                        fulfill this order, leave this field blank.
                      </li>
                    </ul>
                    <div className="h-120"></div>
                  </>
                )}
                {physicalProduct && (
                  <>
                    <ul className="text-muted text-sm opacity-50 ps-3 lh-m">
                      <li className="mb-2">
                        The requirements will be displayed on the purchase page
                        and will allow the buyer to fill this information out
                        when buying the product.{' '}
                      </li>
                      <li className="mb-2">
                        For example, with custom inscription, you can specify,
                        here, that the customer should provide detail on what
                        they would like that inscription to say.
                      </li>
                      <li>
                        If you do not require any additional information to
                        fulfill this order, leave this field blank.
                      </li>
                    </ul>
                    <div className="h-120"></div>
                  </>
                )}

                <div className="border-bottom mt-5 mw-570 mx-auto d-none d-md-block"></div>
                <div className="steps-action d-flex flex-row pt-4">
                  <div
                    className="text-muted fw-bold align-items-center d-flex cursor-pointer mx-md-0 mx-3 t-buttom"
                    onClick={prev}>
                    BACK
                  </div>
                  {physicalProduct ? (
                    <button
                      // className={`btn btn-primary ms-auto
                      //  ${
                      //   values.is_shipaddress_needed === true ? '' : 'disabled'
                      // }`}
                      className="btn btn-primary ms-auto"
                      type="submit">
                      Next
                      <i className="icon icon-right ms-3"></i>
                    </button>
                  ) : (
                    <button className="btn btn-primary ms-auto" type="submit">
                      Next
                      <i className="icon icon-right ms-3"></i>
                    </button>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      <div className="pt-3 mb-3 overview mw-570 mx-auto"></div>
    </div>
  );
};

export default Requirements;
