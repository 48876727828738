import React from 'react';

const SwitchButton = (props) => {
  const { switchTab, toggleTab } = props

  return (
    <div className="row center pad0">
      <div className="col-sm-23 col-md-10 col-lg-10 col-xl-8 col-xxl-6">
        <div className="row center pad0">
          <div className="toggle-switch">
            <input
              type="checkbox"
              id="switch"
              checked={switchTab}
              onChange={() => toggleTab()}
            />
            <div className="app">
              <div className="contentC">
                <label htmlFor="switch">
                  <div className="toggle"></div>
                  <div className="names">
                    <p className={"light " + (!switchTab ? 'current' : '')} >Monthly</p>
                    <p className={"dark " + (switchTab ? 'current' : '')}>Yearly</p>
                  </div>
                </label>
                {switchTab && (
                  <div className="discountTab mb-4">
                    <div className="discountTabContent">
                      35% discount
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SwitchButton