import {Modal} from 'antd';

const CustomModal = ({
  visible,
  onClickOk,
  isLoading,
  onClickCancel,
  title,
  children,
  width,
  className,
}) => (
  <Modal
    className={`athlete-modal ${className}`}
    title={title}
    centered
    footer={null}
    open={visible}
    onOk={onClickOk}
    okText="Update"
    cancelText="Discard"
    confirmLoading={isLoading}
    onCancel={onClickCancel}
    width={width}
    keyboard={false}
    maskClosable={false}
    destroyOnClose={true}>
    {children}
  </Modal>
);

export default CustomModal;
