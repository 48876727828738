export const interimLaunchUpdates = {
  stayUpdateLink: 'https://signup.athleteup.com/',
  launchBtnLable: ' STAY UPDATED',
  launchBtnLabelText: 'CLAIM EXTENDED FREE TRIAL',
  launchBtnLabelText2: 'STAY UPDATED ON OUR LAUNCH',
  pricingSectionAthleteText: 'LAUNCHING SOON, CLAIM AN EXTENDED FREE TRIAL NOW',
  pricingSectionBrandText: 'COMING SOON , STAY UPDATED ON OUR LAUNCH',
};

export const backgroundStyle = {
  brandDetailsBackground:
    process.env.REACT_APP_IMAGEPATH + '/brandBannerBg.svg',
  brandDetailsEllipseCard:
    process.env.REACT_APP_IMAGEPATH + '/brandsBannerCard.svg',
  brandDetailsEllipse:
    process.env.REACT_APP_IMAGEPATH + '/bannerEllipseImg.svg',
  customNav: process.env.REACT_APP_IMAGEPATH + '/custom-nav.png',
  customMobileMenu: process.env.REACT_APP_IMAGEPATH + '/blackMobileMenu.png',
  meetStarsListIcon: process.env.REACT_APP_IMAGEPATH + '/listIcon.svg',
  eyeIconBlue:
    process.env.REACT_APP_IMAGEPATH +
    '/Logo_Options_For_Social_Media_Total_Reach.png',
  bannerDetailsBackground:
    process.env.REACT_APP_IMAGEPATH + '/bannerDetailsbg.svg',
  bannerBaseBg: process.env.REACT_APP_IMAGEPATH + '/base.svg',
  bannerBaseLight: process.env.REACT_APP_IMAGEPATH + '/baseArrowImg.svg',
  maximizeEarnBg: process.env.REACT_APP_IMAGEPATH + '/maximizaearnbg.svg',
  getStartedBg: process.env.REACT_APP_IMAGEPATH + '/getstartedbg.svg',
  pricingCardBg: process.env.REACT_APP_IMAGEPATH + '/pricingbase.svg',
  pricingCardTick: process.env.REACT_APP_IMAGEPATH + '/d-check.svg',
  loginCardBoyBg: process.env.REACT_APP_IMAGEPATH + '/loginrightathlete.png',
  coverBg: process.env.REACT_APP_IMAGEPATH + '/coverBg.png',
  coverImg: process.env.REACT_APP_IMAGEPATH + './coverImg.png',
  instaIcon: process.env.REACT_APP_IMAGEPATH + '/instagram.png',
  footerInstagramIcon:
    process.env.REACT_APP_IMAGEPATH + '/footerInstagramIcon.png',
  facebookIcon: process.env.REACT_APP_IMAGEPATH + '/icon-facebook.png',
  twitterIcon: process.env.REACT_APP_IMAGEPATH + '/twitter.png',
  footerTwitterIcon: process.env.REACT_APP_IMAGEPATH + '/footerTwitterIcon.png',
  sortIcon: process.env.REACT_APP_IMAGEPATH + '/sort-icon.png',
  brandsBannerCardMob:
    process.env.REACT_APP_IMAGEPATH + '/brandsBannerCardMob.png',

  filterIcon: process.env.REACT_APP_IMAGEPATH + '/filter-icon.png',
  verifiedIcon: process.env.REACT_APP_IMAGEPATH + '/verified.png',
  dotsIcon: process.env.REACT_APP_IMAGEPATH + '/dots.png',
  shareIcon: process.env.REACT_APP_IMAGEPATH + '/share.png',
  linkIcon: process.env.REACT_APP_IMAGEPATH + '/linkIcon.png',
  closeIcon: process.env.REACT_APP_IMAGEPATH + '/close.svg',
  closeWhite: process.env.REACT_APP_IMAGEPATH + '/closeWhite.svg',
  messageIcon: process.env.REACT_APP_IMAGEPATH + '/message-icon.svg',
  mark: process.env.REACT_APP_IMAGEPATH + '/deals-tag-1.svg',

  creditCard: process.env.REACT_APP_IMAGEPATH + '/credit-card.svg',
  clock: process.env.REACT_APP_IMAGEPATH + '/clock.svg',
  privacy: process.env.REACT_APP_IMAGEPATH + '/privacy.svg',
  privacyIcon: process.env.REACT_APP_IMAGEPATH + '/privacy.png',
  bgPic: process.env.REACT_APP_IMAGEPATH + '/pic-bg.png',
  locationIcon: process.env.REACT_APP_IMAGEPATH + '/location-icon.png',
  socialPostIcon: process.env.REACT_APP_IMAGEPATH + '/social-post.png',
  signatureIcon: process.env.REACT_APP_IMAGEPATH + '/signature-1.png',
  voiceRecordIcon: process.env.REACT_APP_IMAGEPATH + '/voice-record-1.png',
  podcastIcon: process.env.REACT_APP_IMAGEPATH + '/podcast.png',
  photosIcon: process.env.REACT_APP_IMAGEPATH + '/photos.png',
  youtubeIcon: process.env.REACT_APP_IMAGEPATH + '/youtube.png',
  tiktokIcon: process.env.REACT_APP_IMAGEPATH + '/tiktok.png',
  facebook: process.env.REACT_APP_IMAGEPATH + '/facebook.png',
  greyEllipse: process.env.REACT_APP_IMAGEPATH + '/greyEllipse.png',
  resendRectangle: process.env.REACT_APP_IMAGEPATH + '/resendRectangle.png',
  lock: process.env.REACT_APP_IMAGEPATH + '/lock.png',
  sendMessage: process.env.REACT_APP_IMAGEPATH + '/send-message.png',
  themeBg: process.env.REACT_APP_IMAGEPATH + '/themebg.png',
  themeBgMob: process.env.REACT_APP_IMAGEPATH + '/themeBgMob.png',
  roundDollar: process.env.REACT_APP_IMAGEPATH + '/round-dollar.png',
  productIcon: process.env.REACT_APP_IMAGEPATH + '/products_Icon.png',
  cashIcon: process.env.REACT_APP_IMAGEPATH + '/cash_icon.png',
  AthleteIcon: process.env.REACT_APP_IMAGEPATH + '/athleteIcon.png',
  defaultProfilePic:
    process.env.REACT_APP_IMAGEPATH + '/NoProfile_Gray_Logo.png',
};

export const headerSection = {
  headerLogo: process.env.REACT_APP_IMAGEPATH + '/headerlogo.svg',
  headerLogoBlack: process.env.REACT_APP_IMAGEPATH + '/headerlogoBlack.svg',
  headerNavText1: `DISCOVER  ATHLETES`,
  headerNavText2: `BRANDS`,
  headerNavText3: 'PRICING',
  headerNavText4: 'BLOG',
  headerNavText5: 'LOG IN',
  headerDealLogo:
    process.env.REACT_APP_IMAGEPATH + '/AthleteUp_Deals_Logo_NoGlyph.png',
  headerShopLogo:
    process.env.REACT_APP_IMAGEPATH + '/AthleteUp_Shop_Logo_NoGlyph.png',
};

export const howItWorksSectionHome = {
  img1: process.env.REACT_APP_IMAGEPATH + '/userHomeIcon.svg',
  title1: 'Add your Shop listings',
  text1:
    'Experiences & products. Virtual or in-person. Sell almost anything you can imagine. Guided steps make the process of deciding what to sell easy & fun.',
  img2: process.env.REACT_APP_IMAGEPATH + '/bellIcon.svg',
  title2: 'Get notified of deal opportunities',
  text2:
    "Automatic notifications when you're eligible for a new sponsorship campaign or you receive a formal deal offer. It's like having an agent in your pocket.",
  img3: process.env.REACT_APP_IMAGEPATH + '/memoryIcon.svg',
  title3: 'Manage your orders & deal flow',
  text3:
    'Apply to and land deals with just a few clicks. Fulfill your Shop orders and NIL Deals by communicating directly via built-in messaging.',
  img4: process.env.REACT_APP_IMAGEPATH + '/funnelDollarIcon.svg',
  title4: 'Get paid',
  text4:
    'Earn more with AthleteUp than anywhere else as we take zero commission from any transaction. Realize the full financial upside of your NIL!',
};

export const howItWorksSectionBrands = {
  img1: process.env.REACT_APP_IMAGEPATH + '/addfileicon.svg',
  title1: 'Create a campaign',
  text1:
    "Create a campaign where you provide details on the opportunity you're presenting and your goals for a partnership. Athletes who match your target criteria are automatically notified to review your campaign.",
  img2: process.env.REACT_APP_IMAGEPATH + '/sendicon.svg',
  title2: 'Send campaign invites or offers in seconds',
  text2:
    'Using advanced filtering and analytics, identify the perfect athletes to meet the goals of your campaign. Invite specific athletes to apply to the campaign or take it a step further by sending a formal deal offer.',
  img3: process.env.REACT_APP_IMAGEPATH + '/menuicon.svg',
  title3: 'Review applications, close deals quickly',
  text3:
    "In no time, your campaign will fill up with applications from talented athletes who are interested and meet your campaign eligibility criteria. Decide which athletes you'd like to work with and negotiate a deal.",
  img4: process.env.REACT_APP_IMAGEPATH + '/assessmenticon.svg',
  title4: 'Manage all your deals with ease',
  text4:
    'Streamlined ability to manage your entire deal workflow with ease: track tasks, approve deliverables, and stay in direct communication with your athlete partners from start through deal completion.',
};

export const getStartedSection = {
  title: 'Ready to get started?',
  text: 'PRE-LAUNCH OFFER: Sign up now to stay updated on our launch and receive an extended 3-month free trial!',
  btnlabelhome: 'TRY FOR FREE',
  btnlabelbrands: 'SIGN UP',
};

export const discoverMatchSection = {
  img: process.env.REACT_APP_IMAGEPATH + '/discoverMatchSectionImg.png',
  title: 'Discover & Match With Talented Athletes',
  text: 'With powerful search and filtering capabilities, brands can quickly identify the perfect athlete-influencers that resonate with their target audience. Filter by athlete type (college, pro, retired, other), gender, sport, school, location, social statistics, or lifestyle facts & interests. Skip the spreadsheets by streamlining your research all on-platform.',
  page: 'brands',
};

export const campaignEnableSection = {
  img: process.env.REACT_APP_IMAGEPATH + '/campaignSectionIcon.png',
  title: 'Campaigns Enable NIL Deals At Scale',
  text: 'Campaigns provide an easy way for brands to introduce themselves to a wide array of athlete-influencers. Brands simply post details of their NIL opportunity to our Campaign Discovery Board, and matching athletes will be automatically notified to check it out. In just a few clicks, interested athletes can apply with their asking price. Brands review applications, negotiate directly with in-platform messaging, and sign deals with the athletes that best match their goals and budget.',
  page: 'brands',
};

export const brandMarketingSection = {
  title: "Brands' Marketing Budgets Go Further, And 100% to the Athlete",
  texts:
    "AthleteUp was created to provide athletes with the best technology to maximize the value of their NIL. In doing so, we built the only platform that takes zero commission from athletes or from athletes' deal counterparties - brands. Other platforms charge 20% to 30% commission for facilitating NIL deals. Athletes take home significantly more cash on our platform, and brands' marketing budgets go much further.",
};

export const runYourShopSection = {
  img: process.env.REACT_APP_IMAGEPATH + '/runShopSectionImg.png',
  title: 'Run Your Shop With Ease',
  text: 'It takes only minutes to setup your Shop and start adding customized listings to sell. Unsure what to sell? Creating listings for your Shop is quick and simple with intuitive guided steps and example listings for inspiration. Once you make a sale, communicate directly with the buyer via in-platform messaging to ensure smooth order fulfillment.',
};

export const likeAnAgentSection = {
  img: process.env.REACT_APP_IMAGEPATH + '/likeAnAgentSectionImg.png',
  title: 'Like an Agent in Your Pocket ',
  text: "With AthleteUp Deals, you don't have to just passively wait for deals to come to you. You'll have access to the Campaign Discovery Board where you can actively search and discover exclusive new deal opportunities that are created by Brands on AthleteUp every day. And when you find a campaign that you're interested in, apply with just a few clicks. Better yet - you can easily set your profile to be automatically notified the moment any new campaign that you're eligible for hits the marketplace. It's that easy, and you'll never miss a deal.",
};

export const athleteUpShopSection = {
  img: process.env.REACT_APP_IMAGEPATH + '/shopImg.png',
  titleImg: process.env.REACT_APP_IMAGEPATH + '/athleteUpShopLogo.png',
  text: 'With AthleteUp Shop, sell virtually anything you can imagine to your audience from your own personal digital storefront. Custom merch. Private lessons & mentorships. Autographs. Exclusive gear. Video shoutouts. Social media interactions. Live video calls. Camps & clinics. Live videogames. Digital downloads. All this and more can be listed to sell from your own Shop page in just minutes, at the price you set.',
};

export const athleteUpDealsSection = {
  img: process.env.REACT_APP_IMAGEPATH + '/atheleteDealUpSectionImg.png',
  titleImg: process.env.REACT_APP_IMAGEPATH + '/athleteUpDealsLogo.png',
  text: 'AthleteUp Deals is your unfair advantage for connecting with both national brands and local businesses to land exclusive NIL deals. After setting up your profile, brands and organizations will know exactly where to contact you about a wide range of business opportunities: social media posts, brand ambassadorships, virtual or in-person appearances, photo/video shoots, podcast interviews, autograph signings, and much more. Going from an initial offer to a finalized agreement is simple and streamlined via in-platform messaging and contracts. Get paid compensation in cash, products, or both.',
};

export const drivingGrowthSection = {
  img: process.env.REACT_APP_IMAGEPATH + '/drivingGrowthSectionImg.png',
  title: 'Driving Growth For Brands ',
  text: "Whether you're a national brand looking to perform deals at scale or you’re a local small business interested in trying out athlete marketing for the first time, we have the solution for you. AthleteUp is your connection to a diverse group of athletes eager to partner with you to shine a spotlight on your business or products. And access to the entire platform and all its powerful tools is always 100% free for brands. The result - your marketing budget goes much further with AthleteUp than anywhere else.",
  button: 'LEARN MORE',
};

export const whyAthleteUpSection = {
  title: 'Why AthleteUp?',
  lists: [
    {
      icon: process.env.REACT_APP_IMAGEPATH + '/userShopIcon.svg',
      title: 'Unrivaled Shop customization',
      text: "This is your business, so you're fully in charge. From coaching sessions to your own merch, sell virtually anything you can imagine to your audience from your own personal Shop page, which serves as a public-facing profile for all your NIL business opportunities.",
    },
    {
      icon: process.env.REACT_APP_IMAGEPATH + '/coffeeIcon.svg',
      title: 'Deals delivered to your inbox',
      text: 'National and local businesses alike are rapidly signing up, eager to work with our athletes. Receive automatic notifications every time you match with a new NIL sponsorship opportunity. Review and apply with just a few clicks. Nail down all the details with built-in messaging.',
    },
    {
      icon: process.env.REACT_APP_IMAGEPATH + '/linedcreditcard.svg',
      title: 'Zero commissions, ever',
      text: 'While every other NIL marketplace charges a hefty percentage of your revenue, we believe if you wanted a middleman, you would find one. At AthleteUp, we never take a commission. Instead, just pay a reasonable monthly fee, and you keep 100% of your profits.',
    },
    {
      icon: process.env.REACT_APP_IMAGEPATH + '/flowIcon.svg',
      title: 'Streamline all your NIL activities',
      text: 'Your time is valuable and your NIL possibilities are vast. AthleteUp is the only company to provide a single platform for you to effectively capitalize on both direct-to-fan sales and NIL sponsorship deals with brands. All delivered via a simple, streamlined user experience that makes managing all your NIL activities a breeze.',
    },
    {
      icon: process.env.REACT_APP_IMAGEPATH + '/userIcon.svg',
      title: 'Get discovered by fans & brands',
      text: "We're exclusively athlete-dedicated & our goal is to shine a brighter spotlight on each of our athletes. Advanced search & filters facilitate easy discovery from both brands & fans. Your page is public-facing – not hidden behind a login – and discoverable directly from our website (goodbye sketchy business-related DMs on IG).",
    },
    {
      icon: process.env.REACT_APP_IMAGEPATH + '/starIcon.svg',
      title: 'Manage your business with ease',
      text: 'Running a business brings potential pitfalls & headaches. Things like contracting, invoicing, payment collections, and tax reporting. We handle and track all this and provide each athlete with a single 1099 at year-end for simple tax filing.',
    },
  ],
};

export const maximizeEarnings = {
  title: 'Maximize your earnings',
  text: 'Other NIL-focused platforms require athletes to dig through fine print to learn they are giving up 20% to 30%+ on every transaction. We’re transparent, and we offer a better solution: we take zero commission (from either you or your counterparty) and instead charge a very reasonable monthly fee which then provides every athlete with complete access to our powerful platform to realize the full financial upside of their NIL.',
  percentage: '0%',
  feestext: 'Platform Commission Fees. Ever.',
  btnlabelfirst: 'Try for free',
  btnlabelsecond: 'See pricing',
};

export const meetOurStars = {
  title: 'Meet our stars',
  subtitle: 'Illustrative Examples (Real athlete profiles coming soon)',
  starsLists: [
    {
      img: process.env.REACT_APP_IMAGEPATH + '/edSmith.svg',
      name: 'Ed Smith',
      sport: 'Track & Field',
      place: '• Purdue Univ.',
      listingCount: '12',
    },
    {
      img: process.env.REACT_APP_IMAGEPATH + '/ruddHedwig.png',
      name: 'Ruud Hedwig',
      sport: 'Basketball',
      place: '• Univ. of Arizona',
      listingCount: '7',
    },
    {
      img: process.env.REACT_APP_IMAGEPATH + '/steveJhonson.png',
      name: 'Steve Johnson',
      sport: 'Cycling',
      place: '• Colorado Mesa Univ.',
      listingCount: '3',
    },
    {
      img: process.env.REACT_APP_IMAGEPATH + '/janeWilliams.svg',
      name: 'Jade Delsill',
      sport: 'Tennis',
      place: '• Univ. of Southern California',
      listingCount: '1',
    },

    {
      img: process.env.REACT_APP_IMAGEPATH + '/jamesStevenson.svg',
      name: 'James Stevenson',
      sport: 'MMA',
      place: '• West Coast Academy',
      listingCount: '12',
    },
    {
      img: process.env.REACT_APP_IMAGEPATH + '/sarahSablenka.png',
      name: 'Sarah Sabalenka',
      sport: 'Soccer',
      place: '• NYU',
      listingCount: '7',
    },
    {
      img: process.env.REACT_APP_IMAGEPATH + '/mickJackson.svg',
      name: 'Mick Jackson',
      sport: 'Pro Skater',
      place: '• Team Adidas',
      listingCount: '3',
    },
    {
      img: process.env.REACT_APP_IMAGEPATH + '/judySteven.png',
      name: 'Judy Williams',
      sport: 'Boxing',
      place: '• Team USA',
      listingCount: '1',
    },
  ],
};

export const bannerDetailsSection = {
  title: 'It’s NIL SZN. Ready to win?',
  heading: 'Monetize your athlete experience',
  img: process.env.REACT_APP_IMAGEPATH + '/bannerGirl.png',
  baseHeading: 'Our mission',
  basetexts:
    'At AthleteUp, our mission is to provide every athlete with the technology to maximize their name, image, and likeness (NIL) in a variety of diverse and fun ways. We empower athletes to do this by providing them with their own customizable digital storefront where they can activate two incredibly powerful monetization tools: AthleteUp Deals and AthleteUp Shop.',
};

export const bannerDetailsSectionBrands = {
  title: 'Elevating Your Brand1,1',
  heading:
    'Whether you’re a national brand looking to perform deals at scale or you’re a local small business interested in trying out athlete marketing for the first time, we have the solution for you.',
  baseHeading: 'Athlete Marketing Made Easy',
  basetexts:
    'AthleteUp is your connection to a diverse group of athletes eager to partner with you to shine a spotlight on your business or products. And access to the entire platform and all its powerful tools is always 100% free for brands. That’s right - zero fees, zero commissions, unlimited messages, offers, and campaigns with top athletes. The result - your marketing budget goes further with AthleteUp than anywhere else. Sign up today.',
};

export const pricingSection = {
  title: 'Pricing',
  subtext1:
    'It\'s time to realize the full upside of your NIL. Say No to the middle-man and enjoy the perks of zero revenue sharing (from you OR your counterparty).',
  subtext2:
    "Get started for free for your first two months. No credit card required.",
  subtext3: 'Sign up today with a 60-day free trial.',
  pricingSectionBase: {
    title1: 'Athlete pricing',
    title2: 'Brand pricing',
  },
  pricingSectionAthleteListCard: {
    topbadge: true,
    yearlySection1text1: 'Yearly membership',
    monthlySection1text1: 'Monthly membership',
    yearlySection1text2: '(12 months upfront)',
    monthlySection1text2: '(cancel anytime)',
    yearlySection1text3: '$19',
    monthlySection1text3: '$29',
    section1text4: '/month',
    section2text1: 'Commission % on your Shop Sales',
    section2text2: '0%',
    section3text1: 'Commission % on your Deals with Brands',
    section3text2: '0%',
    section4text1: 'Plus credit card payment processing',
    section4texticon: process.env.REACT_APP_IMAGEPATH + '/cardhelpicon.svg',
    blueHelpIcon: process.env.REACT_APP_IMAGEPATH + '/cardBlueIcon.svg',
  },

  pricingListAthleteApp: [
    'Sell fan experiences ',
    'Sell physical or digital products',
    'Book deals with brands – for cash or products',
    'Notifications to apply directly to matching marketing campaigns',
    'Easy deal negotiation via in-platform messaging',
    'Contracts simplified and handled in-platform',
    'Track and manage all of your active deals and past transactions',
    'Ensure compliance with streamlined deal reporting',
    'Deal payments collected upfront –  no invoicing or counterparty risk',
    'All tax documentation tracked & athletes provided with year-end 1099',
    'A single digital storefront to send all fans & brands – no more sorting through DMs',
    'The only platform that is truly zero commission - for athletes and everyone they transact with',
  ],
  pricingSectionBrandsList: [
    'Access to a diverse group of athletes eager to shine a spotlight on your brand or product',
    'Deals curated for any budget, big or small',
    'Campaigns facilitate NIL deals at scale where athletes apply to work with you',
    'Do cash deals, product deals, or cash + product deals',
    'Technology that streamlines every aspect of managing a deal through completion',
    'Powerful search and filtering to discover the perfect athletes to achieve your marketing goals',
    'Notifications automatically sent to target athletes to prompt them to consider your campaign',
    'Unlimited campaigns',
    'Unlimited offers to athletes',
    'Unlimited direct messages to athletes',
    'All contracts handled internally',
    'No payment risk as cash is held in escrow until deliverables are confirmed',
    'Easy tax compliance as we automatically issue athletes with a single 1099 for tax filing',
    'Marketing budgets go further - only platform that takes zero commission from athletes or brands',
  ],

  pricingSectionAthleteList: [
    'A single digital storefront to send your audience - no more sorting through DMs',
    'Sell fan experiences',
    'Sell physical or digital products',
    'Book NIL deals with brands - for cash or products',
    'Automatic notifications the moment you match with a new campaign',
    'Apply to campaigns with just a few clicks',
    'Easy deal negotiation via in-platform messaging',
    'Contracts simplified and handled in-platform',
    'Track and manage all of your active deals and past transactions',
    'Ensure compliance with streamlined deal reporting',
    'Deal payments collected upfront - no invoicing or counterparty risk',
    'Fast payouts (can request instant payouts 24/7)',
    'All tax documentation tracked; single 1099 provided at year-end',
    'Only platform that is truly zero commission - for athletes and everyone they transact with',
  ],
  pricingSectionBrandsListCard: {
    topbadge: false,
    section1text1: 'Monthly membership ',
    section1text2: '(cancel anytime)',
    section1text3: 'FREE',
    section2text1: 'Commission % on your Deals with Athletes',
    section2text2: '0%',
  },
};

export const brandsSection = {
  title: 'Elevating Your Brand',
  img: process.env.REACT_APP_IMAGEPATH + '/brandsBannerCard.png',
  subtext:
    "Whether you're a national brand looking to conduct deals at scale or local small business interested in trying out athlete marketing for the first time, we have the solution for you.",
  basetitle: 'Athlete Marketing Made Easy',
  basetexts:
    'AthleteUp is your connection to a diverse group of athlete-influencers eager to partner with you to shine a spotlight on your business or product. And access to the entire platform and all its powerful tools is always 100% free for brands. That’s zero fees and zero commission to go with unlimited messages, unlimited offers, and unlimited campaigns with top athletes. The result is your marketing budget goes much further with AthleteUp than anywhere else.',
};

export const loginSection = {
  title: 'Login to AthleteUp',
  texts: 'Enter your email and password',
  inputlabel1: 'Email',
  inputlabel2: 'Enter your password',
  forget: 'Forgot password?',
  btnlabel: 'LOGIN',
  signuptext: "Don't have an account?",
  imgrightbg: process.env.REACT_APP_IMAGEPATH + '/loginrightathletemike.svg',
  nameage: 'Mike, 24',
  playertitle: 'Track Athlete',
};

export const CardDetails = {
  inputlabel1: 'Name',
  inputlabel2: 'Card Number',
  inputlabel3: 'Date',
  inputlabel4: 'Cvc',
};

export const registerSection = {
  title: 'Register now',
  texts: 'Select your account type',
  btnlabel: 'NEXT',
  signintext: 'Already have an account?',
  img1: process.env.REACT_APP_IMAGEPATH + '/registerathlete.png',
  title1: 'Athlete',
  img2: process.env.REACT_APP_IMAGEPATH + '/registerbrand.png',
  title2: 'Brand',
  img3: process.env.REACT_APP_IMAGEPATH + '/registerfan.png',
  title3: 'Fan',
  imgrightbg: {
    Athlete: process.env.REACT_APP_IMAGEPATH + '/registerrightathletejane.png',
    Brand: process.env.REACT_APP_IMAGEPATH + '/register-brand.png',
    Fan: process.env.REACT_APP_IMAGEPATH + '/fan_image.png',
  },

  nameage: 'Jane, 19',
  playertitle: 'Tennis Player',
};

export const purchaserSection = {
  title: 'Meet our stars',
  subtext:
    'The list of talented athletes joining AthleteUp is growing every day. Use the search or filters to discover the perfect athlete to match your goals.',
  sportList: [
    'All Athletes',
    'Basketball',
    'American football',
    'Cycling',
    'Baseball',
    'Tennis',
    'Ice hockey',
    'Soccer',
  ],
};
export const registerAsAthlete = {
  title: {
    Athlete: 'Register as Athlete',
    Brand: 'Register as Brand',
    Fan: 'Register as Fan',
  },
  texts: 'Provide a few details to get started',
  inputfirst: 'First name',
  inputlast: 'Last name',
  inputemail: {
    // Athlete: 'Email (.edu if current college athlete)',
    Athlete: 'Email',
    Brand: 'Email',
    Fan: 'Email',
  },
  inputpass: 'Choose a password',
  inputtype: 'Your athlete type',
  inputsport: 'What sport do you play?',
  inputposition: 'What position do you play?',
  inputteam: 'What school or team do you play for?',
  btnlabel: 'Register',
  companyName: 'Company name',
};

export const cardDetails = {
  nameOnCard: 'Name on card',
  cardNumber: 'Card number',
};

export const msgSection = {
  title: 'Verify your email to proceed',
  text: `We just sent a link to your email address. Please check your email and click on the link provided to verify your address. If you don't see the email, please check your spam folder. If you still don't receive, please email help@athleteup.com.`,
  btnlabel: 'RESEND VERIFICATION EMAIL',
  msgicon: process.env.REACT_APP_IMAGEPATH + '/msgicon.png',
};

export const modalSection = {
  title: 'Successfull',
  text: 'Login Successfull',
  imgthumb: process.env.REACT_APP_IMAGEPATH + '/thumb.png',
};

export const profileSection = {
  text1: "Let's create your profile",
  title: 'Let us know you better',
  text2: 'step 1 of 2',
  pictext: 'Profile picture',
  profilegender: 'Gender',
  profilegentype1: 'Male',
  profilegentype2: 'Female',
  profilegentype3: 'Others',
  profileindustry: 'Industry',
  profileindustrytype1: 'Finance',
  profileindustrytype2: 'It',
  profileindustrytype3: 'Others',
  date: 'Date of birth',
  monthtype1: 'January',
  monthtype2: 'February',
  monthtype3: 'March',
  daytype1: '1',
  daytype2: '2',
  daytype3: '3',
  yeartype1: '2019',
  yeartype2: '2020',
  yeartype3: '2021',
  location: 'Current location',
  home: 'Hometown',
  hschool: 'High school',
  college: 'College',
  Gradyear: 'Graduation year',
  bio: 'Bio',
  biop: 'Add short bio',
  life: 'Lifestyle & Interests',
  lifep: 'Eg. camping, running, dog owner, etc',
  acco: 'Accolades',
  accop: 'Add accolades',
  deal: 'Are you willing to field custom deals?',
  dealy: 'Yes',
  dealn: 'No',
  btnlabel: 'Next',
};

export const profileSection2 = {
  title: 'Add your social media accounts',
  text1: "Let's create your profile",
  text2: 'step 2 of 2',
  fb: 'Facebook',
  fbp: 'URL to your Facebook account',
  fbfollower: 'Number of Followers',
  ttok: 'TikTok',
  ttokp: 'URL to your TikTok account',
  ttfollower: 'Number of Followers',
  ytube: 'Youtube',
  ytubep: 'URL to your Youtube account',
  ytfollower: 'Number of Followers',
  btnlabel: 'DONE',
  btnlabel1: 'Connect to Instagram',
  btnlabel2: 'Connect to Twitter',
  insta: 'Instagram',
  instap: "We'll automatically pull your follower count from Instagram.",
  twit: 'Twitter',
  twitp: "We'll automatically pull your follower count from Twitter.",
};

export const icons = {
  eyeIcon: process.env.REACT_APP_IMAGEPATH + '/showEye.svg',
  hideIcon: process.env.REACT_APP_IMAGEPATH + '/hide-eye.svg',
  cameraIcon: process.env.REACT_APP_IMAGEPATH + '/camera-icon.svg',
  instaColorIcon: process.env.REACT_APP_IMAGEPATH + '/icon-insta-color.svg',
  twitterColorIcon: process.env.REACT_APP_IMAGEPATH + '/icon-twitter-color.svg',
  tickColorIcon: process.env.REACT_APP_IMAGEPATH + '/icon-tick-color.svg',
};

export const DemoImages = {
  demoImage1: process.env.REACT_APP_IMAGEPATH + '/athlete-demo-img.png',
  demoImage2: process.env.REACT_APP_IMAGEPATH + '/demo-picture-2.png',
  demoImage3: process.env.REACT_APP_IMAGEPATH + '/demoImage3.png',
};

export const campaignImages = {
  campaignIcon1: process.env.REACT_APP_IMAGEPATH + '/campaign_icon.png',
  campaignIcon2: process.env.REACT_APP_IMAGEPATH + '/campaign_icon_2.png',
  campaignIcon3: process.env.REACT_APP_IMAGEPATH + '/campaign_icon_3.png',
};

export const calendlyLink = 'athleteup/20min';
