export const OpenCampaign = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6Z" fill="#042237"/>
    <path d="M6 7C2.625 7 1 9.106 1 10.333V11C1 11.2652 1.10536 11.5196 1.29289 11.7071C1.48043 11.8946 1.73478 12 2 12H10C10.2652 12 10.5196 11.8946 10.7071 11.7071C10.8946 11.5196 11 11.2652 11 11V10.333C11 9.106 9.375 7 6 7Z" fill="#042237"/>
  </svg>
)

export const Lock = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2C6.26522 2 6.51957 2.10536 6.70711 2.29289C6.89464 2.48043 7 2.73478 7 3V5H9V3C9 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.31607 6.79565 0 6 0C5.20435 0 4.44129 0.31607 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3V5H5V3Z" fill="#042237"/>
    <path d="M10 6H2C1.44772 6 1 6.44772 1 7V11C1 11.5523 1.44772 12 2 12H10C10.5523 12 11 11.5523 11 11V7C11 6.44772 10.5523 6 10 6Z" fill="#042237"/>
  </svg>
)