import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

http.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem('athletUp-token');
    if (access_token !== 'undefined') {
      const token = access_token ? access_token : '';
      // const headers = { headers: { 'Authorization': `Bearer ${token}` } }
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

function refresh_token() {
  const token = localStorage.getItem('athletup-refresh-token');
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}auth/access-token?refresh_token=${token}`,
  );
}

let refreshing_token = null;

http.interceptors.response.use(
  (response) => {
    // if (document.getElementById('loader-indicator')) {
    //   document.getElementById('loader-indicator').classList.remove('d-block');
    // }
    return response;
  },
  async (err) => {
    const config = err.config;
    // console.log(err, 'Response error');
    if (
      (err?.response?.data?.name === 'TokenExpiredError' ||
        err?.response?.data?.message === 'You are not signed in') &&
      !config._retry
    ) {
      try {
        refreshing_token = refreshing_token
          ? refreshing_token
          : refresh_token();

        let res = await refreshing_token;

        refreshing_token = null;
        if (res.data.data) {
          localStorage.setItem('athletUp-token', res.data.data.access_token);
          localStorage.setItem(
            'athletup-refresh-token',
            res.data.data.refresh_token,
          );
        }
        return http(config);
      } catch (err) {
        localStorage.clear();
        window.location.href = '/';
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  },
);

export default http;
