import React from 'react';
import {Select} from 'antd';
import './styles.scss';

export const CustomSelect = ({
  defaultValue,
  marginRight,
  handleSelect,
  dropdownOptions,
  className,
  modify,
}) => {
  const {Option} = Select;
  function handleChange(value) {
    handleSelect(value);
  }

  // const getSplitOption = (option) => {
  //   let limit = 40;
  //   let indexToSplit = 0;
  //   while (limit > -1) {
  //     if (option[limit] === ' ') {
  //       indexToSplit = limit;
  //       break;
  //     }
  //     limit = limit - 1;
  //   }
  //   let optionFirst = option.substr(0, indexToSplit);
  //   let optionSecond = option.substr(indexToSplit);
  //   return (
  //     <>
  //       {optionFirst} <br />
  //       {optionSecond}
  //     </>
  //   );
  // };

  return (
    <Select
      defaultValue={defaultValue}
      onChange={handleChange}
      className={`select-dropdown ${className ? className : ''}`}
      style={{marginRight: marginRight, backgroundColor: '#ffffff'}}>
      <Option value={null} key={null}>
        Any
      </Option>
      {dropdownOptions?.map((ele) => {
        if (typeof ele === 'object') {
          return (
            <Option value={ele?.id} key={ele?.id}>
              {ele?.name}
            </Option>
          );
        } else if (modify && modify === 'comp_level') {
          return (
            <Option value={ele} key={ele}>
              {`$ ${ele}+`}
            </Option>
          );
        } else {
          return (
            <Option value={ele} key={ele} className="line-ellipse-1">
              {ele}
              {/* {ele.length > 40 ? 
              getSplitOption(ele)
            : ele} */}
            </Option>
          );
        }
      })}
    </Select>
  );
};
