import React from 'react';
import { OpenCampaign } from './CampaignIcons';
import '../../../../../assets/scss/_campaign.scss';
import { EmptyBrandName, EmptyDeliverables, EmptyImg, RequiredCriteriaOpen } from './style';

const CampaignCardUnregistered = (props) => {
  const {campaignDetails} = props;

  const getSports = (campaignData) => {
    let allowedSports = campaignData.allowed_sports;
    let sportsList = "";
    allowedSports.forEach((sportObj, idx) => {
      if(idx !== allowedSports.length - 1) {
        sportsList = sportsList + " " +sportObj?.name + " ,";
      }
      else 
        sportsList = sportsList + " " + sportObj.name;
    });
    return sportsList;
  }

  const showCriteria = (campaignData) => {
    if(campaignData?.criteria && Object.keys(campaignData?.criteria).length >0) {
      let criteria = campaignData.criteria;
      return (<>
        <span>
          <span className='me-2 titlecase'>Required Criteria: </span>
          <span className='me-2 titlecase'>{criteria?.athlete_type ? `${criteria.athlete_type} athletes` : "Any athlete type"}</span>
          <span className="me-2">|</span>
          <span className='me-2 titlecase'>{criteria?.sports && campaignData?.allowed_sports && campaignData?.allowed_sports?.length> 0 ? getSports(campaignData) : "Any sports"}</span>
          <span className="me-2">|</span>
          <span className='me-2 titlecase'>{criteria?.gender ? `${criteria.gender}` : "Any gender"}</span>
          <span className="me-2">|</span>
          <span className='me-2 titlecase'>{criteria?.locations ? `${criteria.locations}` : "Anywhere located"}</span>
          <span className="me-2">|</span>

          {/* Social Reach - to do */}
          {/* <span className='me-2 titlecase'>{criteria?.social_reach ? `To do ` : "Any SM reach"}</span>
          <span className="me-2">|</span> */}
        </span>
      </>);
    }
    else {
      return (<>
        <span>
          <span className='me-2 titlecase'>Any athlete type</span>
          <span className="me-2">|</span>
          <span className='me-2 titlecase'>Any sports</span>
          <span className="me-2">|</span>
          <span className='me-2 titlecase'>Any gender</span>
          <span className="me-2">|</span>
          <span className='me-2 titlecase'>Anywhere located</span>
          <span className="me-2">|</span>
          <span className='me-2 titlecase'>Any SM reach</span>
          <span className="me-2">|</span>
        </span>
      </>);
    }
  }
 
  return (
    <div className={`campaign-card campaign-open`}>
      <div className="d-xl-flex">
        <div className="d-lg-block d-none">
          <EmptyImg/>
        </div>
        <div className="d-block ms-4 color-dark campaign-content">
          <div className="d-flex d-lg-none mb-2 align-items-center">
            <EmptyImg/>
            <div className="ms-2 d-flex align-items-center">
              <div><OpenCampaign/></div>
              <div className="fw-500 font-15 ms-2">{`Open Campaign by `}</div>
              <EmptyBrandName/>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <div className="fw-700 campaign-title mb-lg-1">{campaignDetails.title}</div>
              <div className="d-none d-lg-flex align-items-center">
                <div><OpenCampaign/></div>
                <div className="fw-500 font-15 ms-2">{`Open Campaign by `}</div>
                <EmptyBrandName/>
              </div>
            </div>
            <div className="d-none d-lg-block cam-unregistered-amt">
              {`Up to $${campaignDetails?.total_price}`}
            </div>
          </div>
          <div className="d-block d-lg-none cam-unregistered-amt">{`Up to $${campaignDetails?.total_price}`}</div>
          <div className="campaign-divider mt-4"></div>
          <div className="mt-3 font-m">
            <div className="fw-700 mb-2">Deliverables</div>
            <EmptyDeliverables/>
            <div className="d-block d-lg-none">
              <EmptyDeliverables/><EmptyDeliverables/>
            </div>
          </div>
          <RequiredCriteriaOpen>
            <br className="d-block d-lg-none"/>
            {showCriteria(campaignDetails)}
          </RequiredCriteriaOpen> 
        </div>
      </div>  
    </div>
  );
}

export default CampaignCardUnregistered;