import {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setHeader, twiliotoken} from '../../store/athleteflow/action';
import {useState} from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import useTwiioToken from '../../hooks/useTwillioToken';
import {Tooltip} from 'antd';
import Draggable from 'react-draggable';
import useDeviceSize from '../../hooks/useDeviceSize';

const VideoCall = () => {
  // const {videoToken} = useSelector((state) => state.AthleteModuleReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const {channelId} = useParams();
  const [activeRoom, setRoom] = useState(false);
  const [videoEnable, setVideoState] = useState(true);
  const [audioEnable, setAudioEnable] = useState(true);
  const [participants, setParticipants] = useState([]);
  let userId = localStorage.getItem('id');
  const {participantsDetails, token} = useTwiioToken(channelId, userId);
  const size = useDeviceSize();

  const remoteParticipants = participants.map((participant) => (
    <Participant
      key={participant.sid}
      participant={participant}
      participantsDetails={participantsDetails}
    />
  ));

  useEffect(() => {
    if (
      history.location.pathname === `/athlete/video-call/${channelId}` ||
      history.location.pathname === `/purchaser/video-call/${channelId}`
    ) {
      dispatch(setHeader(false));
    }
  }, [history.location.pathname, dispatch, channelId]);

  const handleLogout = () => {
    dispatch(twiliotoken(''));
    setParticipants([]);

    window.open('', '_self', '');
    window.close();
  };

  const disableTrack = (track) => {
    track.disable();
  };
  const enableTrack = (track) => {
    track.enable();
  };

  useEffect(() => {
    const participantConnected = (participant) => {
      // setParticipants((prevParticipants) => [participant]);
      participant.tracks.forEach((track) => {
        track.on('disabled', () => disableTrack(track));
        track.on('enabled', () => enableTrack(track));
      });
      // setParticipants((prevParticipants) => [participant]);
      // setParticipants((prevParticipants) => [...prevParticipants, participant]);
      setParticipants((prevParticipants) => [participant]);
    };
    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant),
      );
    };

    if (token) {
      Video.connect(token, {
        name: channelId,
      })
        .then((room) => {
          setRoom(room);
          room.on('participantConnected', participantConnected);
          room.on('participantDisconnected', participantDisconnected);
          room.on('trackAdded', (track) => {
            track.on('disabled', () => disableTrack(track));
            track.on('enabled', () => enableTrack(track));
          });

          room.participants.forEach(participantConnected);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
      return () => {
        setRoom((currentRoom) => {
          if (
            currentRoom &&
            currentRoom.localParticipant.state === 'connected'
          ) {
            currentRoom.localParticipant.tracks.forEach(function (
              trackPublication,
            ) {
              trackPublication.track.stop();
            });
            currentRoom.disconnect();
            return null;
          } else {
            return currentRoom;
          }
        });
      };
    }
  }, [token, channelId]);

  const videoDisabel = () => {
    setVideoState(!videoEnable);
    activeRoom.localParticipant.videoTracks.forEach((publication) => {
      publication.track.disable();
    });
  };

  const videoEnableFn = () => {
    setVideoState(!videoEnable);
    activeRoom.localParticipant.videoTracks.forEach((publication) => {
      publication.track.enable();
    });
  };

  const audioDisable = () => {
    setAudioEnable(!audioEnable);
    activeRoom.localParticipant.audioTracks.forEach((publication) => {
      publication.track.disable();
    });
  };

  const audioEnableFn = () => {
    setAudioEnable(!audioEnable);
    activeRoom.localParticipant.audioTracks.forEach((publication) => {
      publication.track.enable();
    });
  };

  return (
    <div className="container-sm align-items-center">
      {/* <div className="header-style header-light header-fixed athlete-bg">
        <div className="athlete-page-bg ">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center py-3">
              <a href="/">
                <img
                  alt="headerlogo"
                  src={headerSection.headerLogoBlack}
                  className="d-inline-block align-top"
                />
              </a>

          </div>
        </div>
      </div> */}

      <div
        className={`video-container d-block d-md-flex align-items-center container ${
          participants.length === 0
            ? 'participant-not-joined'
            : 'participant-joined'
        }`}>
        <div>
          <div className="d-md-flex w-100 align-items-center justify-content-center">
            <div className="local-participant px-1 px-md-3">
              {activeRoom ? (
                <>
                  {size.width > 768 ? (
                    <Participant
                      key={activeRoom.localParticipant.sid}
                      participantsDetails={participantsDetails}
                      participant={activeRoom.localParticipant}
                      // participantsDetails={participantsDetails}
                    />
                  ) : (
                    <Draggable bounds="parent">
                      <div className="box">
                        <Participant
                          key={activeRoom.localParticipant.sid}
                          participantsDetails={participantsDetails}
                          participant={activeRoom.localParticipant}
                          // participantsDetails={participantsDetails}
                        />
                      </div>
                    </Draggable>
                  )}
                </>
              ) : (
                ''
              )}
            </div>
            {participants.length > 0 && (
              <div className="remote-participants px-1 px-md-3">
                {remoteParticipants}
              </div>
            )}
          </div>
          {activeRoom && (
            <div className="d-flex justify-content-center message-btn mt-5 text-center">
              <div
                className="btn"
                onClick={videoEnable ? videoDisabel : videoEnableFn}>
                <Tooltip
                  title={videoEnable ? 'Turn off video' : 'Turn on video'}
                  color="#000"
                  overlayClassName="msg-tooltip">
                  {videoEnable ? (
                    <svg
                      focusable="false"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#fff">
                      <path d="M18 10.48V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-4.48l4 3.98v-11l-4 3.98zm-2-.79V18H4V6h12v3.69z"></path>
                    </svg>
                  ) : (
                    <svg
                      focusable="false"
                      width="24"
                      height="24"
                      fill="#fff"
                      viewBox="0 0 24 24">
                      <path d="M18 10.48V6c0-1.1-.9-2-2-2H6.83l2 2H16v7.17l2 2v-1.65l4 3.98v-11l-4 3.98zM16 16L6 6 4 4 2.81 2.81 1.39 4.22l.85.85C2.09 5.35 2 5.66 2 6v12c0 1.1.9 2 2 2h12c.34 0 .65-.09.93-.24l2.85 2.85 1.41-1.41L18 18l-2-2zM4 18V6.83L15.17 18H4z"></path>
                    </svg>
                  )}
                </Tooltip>
              </div>
              <div
                className="btn cursor-pointer"
                onClick={audioEnable ? audioDisable : audioEnableFn}>
                <Tooltip
                  title={audioEnable ? 'Turn off audio' : 'Turn on audio'}
                  color="#000"
                  overlayClassName="msg-tooltip">
                  {audioEnable ? (
                    <svg
                      focusable="false"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#fff">
                      <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z"></path>
                      <path d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"></path>
                    </svg>
                  ) : (
                    <svg
                      focusable="false"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#fff">
                      <path d="M11 5c0-.55.45-1 1-1s1 .45 1 1v5.17l1.82 1.82c.11-.31.18-.64.18-.99V5c0-1.66-1.34-3-3-3S9 3.34 9 5v1.17l2 2V5zM2.81 2.81L1.39 4.22l11.65 11.65c-.33.08-.68.13-1.04.13-2.76 0-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c.57-.08 1.12-.24 1.64-.46l5.14 5.14 1.41-1.41L2.81 2.81zM19 11h-2c0 .91-.26 1.75-.69 2.48l1.46 1.46A6.921 6.921 0 0 0 19 11z"></path>
                    </svg>
                  )}
                </Tooltip>
              </div>

              <div
                className="btn cursor-pointer btn-red"
                onClick={handleLogout}>
                <Tooltip
                  title="Leave call"
                  color="#000"
                  overlayClassName="msg-tooltip">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#fff">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M4.51 15.48l2-1.59c.48-.38.76-.96.76-1.57v-2.6c3.02-.98 6.29-.99 9.32 0v2.61c0 .61.28 1.19.76 1.57l1.99 1.58c.8.63 1.94.57 2.66-.15l1.22-1.22c.8-.8.8-2.13-.05-2.88-6.41-5.66-16.07-5.66-22.48 0-.85.75-.85 2.08-.05 2.88l1.22 1.22c.71.72 1.85.78 2.65.15z" />
                  </svg>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default VideoCall;
